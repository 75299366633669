import clsx from 'clsx';

import { Avatar, Typography } from '@/ui';
import { formatShortDayMonthDate, toDateTimeRangeString } from '@/utils';

import ChemistryCallInfo from './ChemistryCallInfo';
import JoinButton from './JoinButton';
import MenuOptions from './Menu';

import type { Session, User } from '@/types';
import type { FC } from 'react';

interface HeaderProps {
  hideMenu?: boolean;
  isCoachViewing: boolean;
  loading?: boolean;
  onCancelSession: (session?: Session) => void;
  onReschedule: (session?: Session) => void;
  onViewProfile?: () => void;
  session: Session;
  user: User;
}

const Header: FC<HeaderProps> = ({
  loading = false,
  isCoachViewing,
  session = {} as Session,
  user = {} as User,
  hideMenu = false,
  onReschedule,
  onCancelSession,
  onViewProfile = undefined,
}) => {
  const { startedAt, endedAt } = session;
  const { firstName, lastName, avatarUrl } = user;

  const handleViewProfile = (e: React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
    if (onViewProfile) {
      onViewProfile();
    }
  };
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const hourRange = toDateTimeRangeString(
    new Date(startedAt),
    new Date(endedAt),
    timeZone,
    true,
  );
  const startedAtDate = formatShortDayMonthDate(startedAt);

  // Handle cases where lastName is an empty string without leaving an extra space before
  // the comma
  const nameTitle =
    firstName || lastName ? `${[firstName, lastName].join(' ').trim()}` : '';

  return (
    <div className="flex w-full flex-col justify-between md:flex-row">
      <div className="flex gap-6">
        <div
          className={clsx('hidden md:block', {
            'cursor-pointer': !!onViewProfile,
          })}
        >
          <Avatar
            firstName={firstName}
            lastName={lastName}
            size="l"
            src={avatarUrl || ''}
            onClick={handleViewProfile}
          />
        </div>
        <div className="mb-2 flex flex-col justify-center md:mb-0">
          <Typography className="my-0 text-wrap" variant="h5">
            {session.title}
          </Typography>
          <Typography className="my-0 text-cement-400" variant="body2">
            {nameTitle} &middot; {startedAtDate} &middot; {hourRange}
          </Typography>
        </div>
      </div>
      <div className="flex shrink-0 items-center gap-2 pt-2 md:pt-0">
        <ChemistryCallInfo
          isCoachViewing={isCoachViewing}
          session={session}
          user={user}
        />
        <JoinButton session={session} />
        {!hideMenu && (
          <MenuOptions
            loading={loading}
            onCancelSession={onCancelSession}
            onReschedule={onReschedule}
          />
        )}
      </div>
    </div>
  );
};

export default Header;
