import { Search } from '@/ui';

import type { FC } from 'react';

import Create from '@/components/Document/Create';

interface HeaderProps {
  isSuperAdmin: boolean;
  search: string;
  setSearch: (value: string) => void;
}

// Need to destructure due to linting rules
const Header: FC<HeaderProps> = ({ isSuperAdmin, ...rest }) => (
  <div className="flex flex-col justify-between gap-4 md:flex-row">
    <Search timeout={0} {...rest} size="small" />
    {isSuperAdmin && (
      <div className="flex w-full shrink-0 gap-4 md:w-fit">
        <Create draft isTemplate />
      </div>
    )}
  </div>
);

export default Header;
