import { type FC, Fragment } from 'react';

import { ONE } from '@/constants';
import {
  Card,
  CardContent,
  Divider,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  Typography,
} from '@/ui';

import type { Team } from '@/types/teams';

interface FilledGoalsCardProps {
  openTeamGoals: () => void;
  team: Team;
}

const FilledGoalsCard: FC<FilledGoalsCardProps> = ({ team, openTeamGoals }) => (
  <Card className="basis-96 self-baseline">
    <CardContent>
      <div className="flex items-center gap-2">
        <Icon className="!text-2xl">emoji_flags</Icon>

        <Typography variant="h6">Team goals</Typography>

        <IconButton
          className="ml-auto"
          variant="outlined"
          onClick={openTeamGoals}
        >
          <Icon>edit</Icon>
        </IconButton>
      </div>
      <List>
        {team.goals.map((item, index) => (
          /* eslint-disable-next-line react/no-array-index-key -- Use index as key since these are plain strings */
          <Fragment key={`${index}-company-goals-list`}>
            <ListItem className="gap-1.5 px-0">
              <ListItemIcon className="flex min-w-4 justify-center align-middle">
                <Typography
                  align="center"
                  className="w-full rounded-full bg-cement-500 text-white"
                  variant="caption"
                >
                  {index + ONE}
                </Typography>
              </ListItemIcon>
              <Typography variant="body2">{item}</Typography>
            </ListItem>
            {index + ONE !== team.goals.length && <Divider />}
          </Fragment>
        ))}
      </List>
    </CardContent>
  </Card>
);

export default FilledGoalsCard;
