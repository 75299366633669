import { SessionListType } from '@/types';
import { Typography } from '@/ui';

import { SessionCard } from '../Card';
import SessionCardSkeleton from '../Card/Skeleton';

import type { Session } from '@/types';
import type { FC } from 'react';

interface Props {
  hideIfEmpty?: boolean;
  loading?: boolean;
  onCancel?: (session: Session) => void;
  onFeedback?: (session: Session) => void;
  onReschedule?: (session: Session) => void;
  sessions: Record<string, Session[]>;
  type: SessionListType;
}

const SessionList: FC<Props> = ({
  hideIfEmpty = false,
  loading = false,
  onCancel = undefined,
  onFeedback = undefined,
  onReschedule = undefined,
  sessions,
  type,
}) => {
  if (loading) {
    return (
      <>
        <Typography
          className="mt-8 select-none px-2 text-cement-200 md:px-0"
          variant="subtitle1"
        >
          {SessionListType[type]} sessions
        </Typography>
        <div className="pt-4">
          <SessionCardSkeleton />
          <SessionCardSkeleton />
          <SessionCardSkeleton />
          <SessionCardSkeleton />
        </div>
      </>
    );
  }

  if (!Object.keys(sessions).length) {
    if (hideIfEmpty) return null;

    return (
      <div className="mt-20 flex flex-1 flex-col items-center justify-center">
        <img
          alt=""
          className=""
          height="147"
          src="https://mento-space.nyc3.digitaloceanspaces.com/assets/sessions-meet.svg"
          width="127"
        />
        <Typography className="headline-sm mt-4 text-cement-400" variant="h6">
          No {SessionListType[type]} sessions
        </Typography>
        <Typography className="mt-4 text-cement-400">
          Sessions scheduled via Mento will show up here
        </Typography>
      </div>
    );
  }

  return (
    <>
      {Object.entries(sessions).map(([name = '', values = []]) => {
        if (!values.length) return null;

        return (
          <div key={name} className="pt-8">
            <Typography
              className="px-2 pb-4 text-cement-400 md:px-0"
              variant="subtitle1"
            >
              {name}
            </Typography>
            <div className="flex flex-col gap-3">
              {values.map((session) => (
                <SessionCard
                  key={session.id}
                  session={session}
                  type={type}
                  onCancel={onCancel}
                  onFeedback={onFeedback}
                  onReschedule={onReschedule}
                />
              ))}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default SessionList;
