import { useState } from 'react';

import { removeCoachAssignment } from '@/api';
import { ONE } from '@/constants';
import { Grid, Typography } from '@/ui';

import type { User } from '@/types';
import type { FC } from 'react';

import Member from '@/components/Profile/Coach/Edit/Admin/Members/member';
import RemoveDialog from '@/components/Profile/Coach/Edit/Admin/Members/removeDialog';

interface MembersProps {
  coach: User;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- we don't actually use the result
  refetchCoach: () => Promise<any>;
}

const Members: FC<MembersProps> = ({ coach, refetchCoach }) => {
  const [pending, setPending] = useState<User | null>(null);

  const onConfirmRemoval = async (isRemoving: boolean) => {
    if (isRemoving && pending) {
      const coachAssignment = coach.coacheeAssignments?.find(
        (assignment) => assignment.coacheeId === pending.id,
      );
      if (coachAssignment) {
        await removeCoachAssignment(coachAssignment.id);
      }
      await refetchCoach();
    }
    setPending(null);
  };

  let title = `${coach.coachees.length} Member`;
  if (coach.coachees.length !== ONE) {
    title += 's';
  }

  return (
    <Grid spacing={4} container>
      <Grid md={5} xs={12} item>
        <Typography className="mb-2" variant="h4">
          {title}
        </Typography>
      </Grid>
      <Grid className="mt-1 flex flex-col gap-3" md={7} xs={12} item>
        {coach.coachees.map((coachee: User) => (
          <Member
            key={`coach-member-${coachee.id}`}
            coachId={coach.id}
            member={coachee}
            setPending={setPending}
          />
        ))}
      </Grid>
      {pending && (
        <RemoveDialog pendingMember={pending} onConfirm={onConfirmRemoval} />
      )}
    </Grid>
  );
};

export default Members;
