import { captureException } from '@sentry/react';
import { useContext, useState } from 'react';
import { useController, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';

import { deleteAssistantFunction, updateAssistant } from '@/api';
import { AppContext } from '@/context';
import { useGetAssistant } from '@/hooks';
import { AppMessageSeverities, AssistantModels } from '@/types';
import {
  Button,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  PageHeaderLayout,
  Skeleton,
  TextField,
  Typography,
} from '@/ui';

import type { Assistant as AssistantType } from '@/types';

const Assistant = () => {
  const { assistantId = '' } = useParams();
  const [loading, setLoading] = useState(false);
  const { setAppMessage } = useContext(AppContext);
  const {
    data = {} as AssistantType,
    isLoading,
    refetch,
  } = useGetAssistant(assistantId);
  const {
    control,
    formState: { isValid },
    handleSubmit,
  } = useForm({
    defaultValues: {
      assistantName: data.name || '',
      model: data.model || '',
      prompt: data.prompt || '',
    },
    values: { ...data, assistantName: data.name },
  });

  const { field: assistantName, fieldState: assistantNameState } =
    useController({
      control,
      name: 'assistantName',
      rules: { required: true },
    });
  const { field: model, fieldState: modelState } = useController({
    control,
    name: 'model',
    rules: { required: true },
  });
  const { field: prompt, fieldState: promptState } = useController({
    control,
    name: 'prompt',
    rules: { required: true },
  });

  const navigate = useNavigate();
  const onBack = () => navigate('/coach/manage/assistants');
  const onFunctionClick = (id: string) => () => {
    navigate(`/coach/manage/assistants/${assistantId}/function/${id}`);
  };
  const onFunctionAddClick = () => {
    navigate(`/coach/manage/assistants/${assistantId}/function`);
  };
  const onFunctionDeleteClick = (id: string) => async () => {
    try {
      await deleteAssistantFunction(id);
      refetch();
    } catch (e) {
      captureException(e);
      setAppMessage({
        message: 'Error deleting function',
        open: true,
        severity: AppMessageSeverities.Error,
      });
    }
  };
  const onSave = () => {
    handleSubmit(async (formData) => {
      setLoading(true);
      try {
        await updateAssistant({
          id: assistantId,
          model: formData.model,
          prompt: formData.prompt,
          name: formData.assistantName,
        });
        refetch();
        setAppMessage({
          message: 'Changes saved',
          open: true,
          severity: AppMessageSeverities.Success,
        });
      } catch (e) {
        captureException(e);
        setAppMessage({
          message: 'Error updating assistant',
          open: true,
          severity: AppMessageSeverities.Error,
        });
      } finally {
        setLoading(false);
      }
    })();
  };

  if (isLoading) {
    return (
      <div className="max-w-screen-sm p-6">
        <PageHeaderLayout title="Assistant" />
        <div className="flex flex-col gap-2 ">
          <Skeleton height={50} />
          <Skeleton height={50} />
          <Skeleton height={50} />
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-screen-sm p-6">
      <div className="flex flex-col gap-4">
        <Typography variant="h4">Assistant</Typography>

        <TextField
          error={assistantNameState.invalid}
          helperText="Required"
          label="Name"
          variant="outlined"
          {...assistantName}
        />
        <TextField
          error={promptState.invalid}
          helperText="Required"
          label="Prompt"
          maxRows={5}
          variant="outlined"
          multiline
          {...prompt}
        />
        <TextField
          error={modelState.invalid}
          helperText="Required"
          label="Model"
          variant="outlined"
          select
          {...model}
        >
          {Object.keys(AssistantModels).map((mod: string) => (
            <MenuItem key={mod} value={mod}>
              {mod}
            </MenuItem>
          ))}
        </TextField>
        <div className="flex gap-2">
          <Button
            className="self-baseline"
            color="primary"
            disabled={loading}
            variant="outlined"
            onClick={onBack}
          >
            Back
          </Button>
          <Button
            className="self-baseline"
            color="primary"
            disabled={!isValid}
            loading={loading}
            variant="contained"
            onClick={onSave}
          >
            Save
          </Button>
        </div>
      </div>
      <div className="mt-6 flex flex-col gap-4">
        <Typography variant="h6">Functions</Typography>

        <List className="p-0">
          {(data.functions ?? []).map((fun) => (
            <ListItem
              key={fun.id}
              secondaryAction={
                <IconButton onClick={onFunctionDeleteClick(fun.id)}>
                  <Icon>trash</Icon>
                </IconButton>
              }
              disableGutters
            >
              <ListItemButton onClick={onFunctionClick(fun.id)}>
                <ListItemText>{fun.name}</ListItemText>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <div className="flex gap-2">
          <Button
            className="self-baseline"
            color="primary"
            variant="contained"
            onClick={onFunctionAddClick}
          >
            Add function
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Assistant;
