import CalEmbed, { getCalApi } from '@calcom/embed-react';
import { useEffect } from 'react';

import type { FC } from 'react';

interface CalEvent {
  detail: {
    type: string;
  };
}

interface CalProps {
  calLink: string;
  callback?: () => void;
  email?: string;
  name?: string;
}

const Cal: FC<CalProps> = ({
  calLink,
  callback = () => {},
  email = '',
  name = '',
}) => {
  let isLoaded = false;

  const onCallback = (e: CalEvent) => {
    if (e.detail.type === '__windowLoadComplete') {
      isLoaded = true;
    } else if (isLoaded && e.detail.type === 'bookingSuccessful') {
      callback();
    }
  };

  useEffect(() => {
    const handler = async () => {
      const api = await getCalApi();
      api('on', {
        action: '*',
        callback: onCallback,
      });
    };

    const clearHandler = async () => {
      const api = await getCalApi();
      api('off', {
        action: '*',
        callback: onCallback,
      });
    };

    handler();

    return () => {
      clearHandler();
    };
  }, []);

  return import.meta.env.VITE_CAL_URL ? (
    <CalEmbed
      calLink={calLink}
      calOrigin={import.meta.env.VITE_CAL_URL}
      className="z-0 h-screen bg-sunny-100"
      config={{
        email,
        name,
        shouldDisableInput: 'true',
      }}
    />
  ) : (
    <div className="text-red-500">Cal.com url not set</div>
  );
};

export default Cal;
