import { clsx } from 'clsx';
import { formatDistanceToNow } from 'date-fns';

import { ONE } from '@/constants';
import { useSessions } from '@/hooks';
import { type Session, SessionStatus, type User } from '@/types';
import { Card, CardContent, Divider } from '@/ui';

import SessionCard from '../Card';
import Empty from '../Empty';
import SessionsLoading from '../SessionsLoading';

import type { FC } from 'react';

type SessionsOverviewProps = {
  user: User;
};

const SessionsOverview: FC<SessionsOverviewProps> = ({ user }) => {
  const {
    data: nextSessionData = {
      items: {} as Record<string, Session[]>,
    },
    isLoading: nextSessionLoading,
  } = useSessions(
    {
      limit: ONE,
      page: ONE,
      statuses: [SessionStatus.ACTIVE, SessionStatus.RESCHEDULED],
      userId: user.id,
    },
    'always',
  );

  const {
    data: previousSessionData = {
      items: {} as Record<string, Session[]>,
    },
    isLoading: previousSessionLoading,
  } = useSessions(
    {
      limit: ONE,
      order: 'desc',
      page: ONE,
      statuses: [SessionStatus.DONE],
      userId: user.id,
    },
    'always',
  );

  const nextSessions = Object.values<Session[]>(nextSessionData.items)[0];
  const topNextSession = nextSessions ? nextSessions[0] : null;

  const previousSessions = Object.values<Session[]>(
    previousSessionData.items,
  )[0];
  const topPreviousSession = previousSessions ? previousSessions[0] : null;

  if (nextSessionLoading || previousSessionLoading) return <SessionsLoading />;

  return (
    <Card>
      <CardContent>
        {topNextSession ? (
          <>
            <SessionCard
              session={topNextSession}
              title={`Next session ${formatDistanceToNow(
                new Date(topNextSession.startedAt),
                {
                  addSuffix: true,
                },
              )}`}
            />
            <Divider
              className={clsx('-mx-6', 'my-2', {
                '-mb-5': !!topPreviousSession,
              })}
            />
          </>
        ) : (
          <Empty client={user} />
        )}
      </CardContent>

      {!!topPreviousSession && (
        <CardContent>
          <SessionCard
            session={topPreviousSession}
            title="Previous session"
            hideCalendarOptions
          />
        </CardContent>
      )}
    </Card>
  );
};

export default SessionsOverview;
