import { TextareaAutosize } from '@/ui';

import type { ChangeEvent, FC } from 'react';

interface EmailTextAreaProps {
  setTemplate: (e: ChangeEvent) => void;
  template: string;
}

const EmailTextArea: FC<EmailTextAreaProps> = ({ setTemplate, template }) => (
  <div className="w-full pb-10 lg:min-w-150 lg:pl-14">
    <div className="">
      <TextareaAutosize
        className="w-full rounded-2xl border border-cement-300 bg-white p-4 font-sans"
        minRows={15}
        value={template}
        onChange={setTemplate}
      />
    </div>
  </div>
);

export default EmailTextArea;
