import { useQuery } from '@tanstack/react-query';

import { getSessions, getSessionsDashboard } from '@/api';
import { QUERY_KEY_SESSIONS } from '@/constants';
import { type GetUserSessionsOptions, mapSessionQueryParams } from '@/utils';

export const useSessions = (
  params: GetUserSessionsOptions,
  refetchOnMount: boolean | 'always' = false,
) => {
  const { data, isLoading, refetch } = useQuery(
    [QUERY_KEY_SESSIONS, mapSessionQueryParams(params)],
    () => getSessions(params),
    { refetchOnMount },
  );
  return { data, isLoading, refetch };
};

export const useSessionsDashboard = () =>
  useQuery({
    queryFn: () => getSessionsDashboard(),
    queryKey: [QUERY_KEY_SESSIONS, 'dashboard'],
  });
