import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';

import {
  confirmMessageThreadMessage,
  deleteMessageThread,
  getMessageThread,
  getMessageThreadMessages,
  postMessageThread,
  postMessageThreadMessage,
  putMessageThreadMessage,
} from '@/api';
import {
  MILLISECONDS_IN_SECOND,
  ONE,
  QUERY_KEY_MESSAGE_THREADS,
} from '@/constants';
import {
  MessageRole,
  type MessageThread,
  type MessageThreadMessage,
  type PaginatedResponse,
} from '@/types';

import type { MessageThreadApiOptions } from '@/api';

export const useGetMessageThreads = (params?: MessageThreadApiOptions) =>
  useQuery<PaginatedResponse<Record<string, MessageThread[]>>, Error>({
    queryFn: () => getMessageThread(params),
    queryKey: [QUERY_KEY_MESSAGE_THREADS, params],
  });

/**
 * Returns the actual messages in chronological ascending order for displaying and ease of manipulation
 * @param data
 */
export const getOrderedMessages = (
  data: PaginatedResponse<MessageThreadMessage>[],
): MessageThreadMessage[] =>
  data.reduce(
    (acc: MessageThreadMessage[], page) =>
      [...acc, ...page.items]
        .sort(
          // the sort_key is returned as the created_at unix epoch
          //
          // However, see RFC for cursor-based pagination: https://github.com/team-mento/docs/pull/6
          (a, b) => a.sortKey - b.sortKey,
        )
        .filter(
          // remove duplicate messages in case of overlapping pagination
          (item, index, self) =>
            self.findIndex((t) => t.id === item.id) === index,
        ),
    [],
  );

export const useGeMessageThreadMessages = (
  params: { limit?: number; messageThreadId: string },
  options?: {
    onSettled: () => void;
  },
) =>
  useInfiniteQuery<PaginatedResponse<MessageThreadMessage>, Error>({
    getNextPageParam: ({ hasNextPage, next }) =>
      hasNextPage ? next : undefined,
    onSettled: options?.onSettled,
    queryFn: ({ pageParam }) =>
      getMessageThreadMessages({ ...params, page: pageParam }),
    queryKey: [QUERY_KEY_MESSAGE_THREADS, params],
    refetchInterval: (data) => {
      if (!data) {
        return false;
      }
      const messages = getOrderedMessages(data.pages);
      const lastMessage = messages.at(-ONE);
      if (lastMessage && lastMessage.role === MessageRole.ASSISTANT) {
        return false;
      }
      return MILLISECONDS_IN_SECOND;
    },
  });

// Mutators.
export const useCreateMessageThread = () =>
  useMutation({ mutationFn: postMessageThread });

export const useCreateMessageThreadMessage = () =>
  useMutation({ mutationFn: postMessageThreadMessage });

export const useEditMessageThreadMessage = () =>
  useMutation({ mutationFn: putMessageThreadMessage });

export const useDeleteMessageThread = () =>
  useMutation({ mutationFn: deleteMessageThread });

export const useConfirmMessageThreadMessage = () =>
  useMutation({ mutationFn: confirmMessageThreadMessage });
