// @ts-nocheck -- TODO: Update Boulder page to use new endpoints and APIs.
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ONE } from '@/constants';
import { useGetMilestone } from '@/hooks';
import { Icon, IconButton } from '@/ui';
import { track } from '@/utils';

import Steps from './Steps';

import BoulderImage from '@/components/Growth/BoulderImage';
import Data from '@/components/Growth/Data';

const Milestone = () => {
  const navigate = useNavigate();
  const { milestoneId = '' } = useParams();
  const { data: milestone, loading } = useGetMilestone({
    id: milestoneId,
  });

  useEffect(() => {
    if (milestone.isComplete) {
      track('View Completed Milestone');
    } else {
      track('View Milestone');
    }
  }, [milestone, loading]);

  if (loading || !milestone.id) return null;

  const onBack = () => navigate(-ONE);

  return (
    <div className="relative flex w-full flex-col-reverse lg:flex-row">
      <div className="flex h-full flex-col justify-between space-y-1 bg-white p-4 md:w-full lg:w-96 lg:px-8 lg:py-12">
        <div className="hidden h-full flex-col gap-2 sm:flex">
          <IconButton className="mr-auto" onClick={onBack}>
            <Icon>chevron_left</Icon>
          </IconButton>
          <div className="m-auto pt-6 md:mt-0 md:w-96 md:py-0 lg:w-full">
            <BoulderImage milestone={milestone} />
          </div>
        </div>
        <div>
          <Data />
        </div>
      </div>
      <Steps milestone={milestone} />
    </div>
  );
};

export default Milestone;
