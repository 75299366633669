import { captureException } from '@sentry/react';
import { useContext, useEffect } from 'react';

import { AppContext } from '@/context';
import { AppMessageSeverities } from '@/types';

import type { Dispatch, FC, SetStateAction } from 'react';

interface IframeProps {
  setErrorLoadingDocument: Dispatch<SetStateAction<boolean>>;
  src: string;
  title: string;
}

const Iframe: FC<IframeProps> = ({ title, src, setErrorLoadingDocument }) => {
  const { setAppMessage } = useContext(AppContext);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- we don't actually use the result,consistent-return
  const fetchData = async (url: string): Promise<any> => {
    try {
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      return await response.text();
    } catch (e) {
      captureException(e);
      setErrorLoadingDocument(true);

      setAppMessage({
        message: "Sorry, we couldn't find the document you were looking for!",
        open: true,
        severity: AppMessageSeverities.Error,
      });

      return null;
    }
  };

  useEffect(() => {
    fetchData(src);
  }, []);

  return (
    <>
      {/* eslint-disable-next-line react/iframe-missing-sandbox -- Limitation on our use of Google docs. Will hopefully be removed in the future in favor of opening the document in a new tab. */}
      <iframe className="flex flex-1 border-0" src={src} title={title} />
    </>
  );
};

export default Iframe;
