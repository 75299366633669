import { Typography } from '@/ui';

const Empty = () => (
  <div className="mb-10 mt-8 flex w-full flex-col items-center md:pt-0">
    <img
      alt="empty"
      className="mx-auto w-full sm:w-134"
      height="auto"
      src="https://mento-space.nyc3.cdn.digitaloceanspaces.com/assets/empty-documents.svg"
    />
    <Typography className="my-6 text-center" variant="h4">
      Get started by creating a document
    </Typography>
    <Typography
      className="mb-5 text-center"
      color="text.secondary"
      variant="body1"
    >
      Use documents to keep notes on your personal journey, and create a shared
      space with your coach.
    </Typography>
  </div>
);

export default Empty;
