import { useMutation, useQuery } from '@tanstack/react-query';

import {
  createClientEvent,
  deleteEvent,
  getClientEvents,
  getEvents,
  patchEvent,
  postEvent,
  updateClientEvent,
} from '@/api';
import { QUERY_KEY_CLIENT_EVENTS, QUERY_KEY_EVENTS } from '@/constants';

import type { Event } from '@/types';

export const useGetAllEvents = () =>
  useQuery<Event[], Error>({
    queryFn: () => getEvents(true),
    queryKey: [QUERY_KEY_EVENTS, 'all'],
  });

export const useGetEvents = () =>
  useQuery<Event[], Error>({
    queryFn: () => getEvents(false),
    queryKey: [QUERY_KEY_EVENTS],
  });

export const useCreateEvent = () => useMutation({ mutationFn: postEvent });
export const useUpdateEvent = () => useMutation({ mutationFn: patchEvent });
export const useDeleteEvent = () => useMutation({ mutationFn: deleteEvent });

export const useUpdateClientEvent = () =>
  useMutation({ mutationFn: updateClientEvent });

export const useCreateClientEvent = () =>
  useMutation({ mutationFn: createClientEvent });

export const useGetClientEvents = () =>
  useQuery({ queryFn: getClientEvents, queryKey: [QUERY_KEY_CLIENT_EVENTS] });
