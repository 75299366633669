import MuiIconButton from '@mui/material/IconButton';
import { alpha, styled } from '@mui/material/styles';
import clsx from 'clsx';

import { capitalize } from '@/utils';

import type { IconButtonProps as MuiIconButtonProps } from '@mui/material/IconButton';
import type { FC } from 'react';

declare module '@mui/material' {
  interface IconButtonPropsColorOverrides {
    gray: true;
    white: true;
  }
}

interface IconButtonProps extends MuiIconButtonProps {
  light?: boolean;
  variant?: 'contained' | 'outlined' | 'text';
}

/* eslint-disable sort-keys,@typescript-eslint/no-magic-numbers -- CSS selectors shouldn't be alphabetically sorted. CSS values make little sense as constants. */
const StyledIconButton = styled(MuiIconButton)<IconButtonProps>(
  ({ theme }) => ({
    // Overall Styles
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.text.primary,

    '.MuiSvgIcon-root': {
      fontSize: theme.typography.pxToRem(24),
    },

    /**
     * Sizes
     */
    '&.MuiIconButton-sizeSmall': {
      padding: theme.spacing(0.625),

      '.MuiSvgIcon-root': {
        fontSize: theme.typography.pxToRem(20),
      },
    },

    '&.MuiIconButton-sizeMedium': {
      padding: theme.spacing(0.75),

      '.MuiSvgIcon-root': {
        fontSize: theme.typography.pxToRem(24),
      },
    },

    '&.MuiIconButton-sizeLarge': {
      padding: theme.spacing(1.125),

      '.MuiSvgIcon-root': {
        fontSize: theme.typography.pxToRem(24),
      },
    },

    /**
     * Text General Styles
     */
    '&.MuiIconButton-text': {
      '.MuiTouchRipple-child': {
        backgroundColor: 'transparent !important',
      },
    },

    /**
     * Contained General Styles
     */
    '&.MuiIconButton-contained': {
      '&.Mui-disabled': {
        backgroundColor: theme.palette.background.disabled,
        color: theme.palette.text.disabled,
      },
    },

    /**
     * Outlined General Styles
     * -----------------------
     */
    '&.MuiIconButton-outlined': {
      borderWidth: '1px',
      borderColor: theme.extendedColors.cement[300],
      borderStyle: 'solid',
      boxSizing: 'border-box',
      color: theme.palette.text.primary,
      ':hover': {
        borderColor: theme.extendedColors.cement[300],
      },
      '&.Mui-disabled': {
        borderColor: theme.palette.background.disabled,
        color: theme.palette.text.disabled,
      },
      '&.MuiIconButton-sizeSmall': {
        padding: theme.spacing(0.5),
      },
      '&.MuiIconButton-sizeMedium': {
        padding: theme.spacing(0.625),
      },
      '&.MuiIconButton-sizeLarge': {
        padding: theme.spacing(1),
      },
    },

    /**
     * Primary IconButton Styles
     * -------------------------
     * */

    // Text Primary
    '&.MuiIconButton-textPrimary': {
      ':hover': {
        background: theme.extendedColors.sage[300],
      },
      '&.Mui-focusVisible': {
        backgroundColor: theme.palette.primary.light,
      },
    },

    // Outlined Primary
    '&.MuiIconButton-outlinedPrimary': {
      ':hover': {
        background: theme.extendedColors.sage[300],
      },
      '&.Mui-focusVisible': {
        backgroundColor: alpha(theme.palette.primary.main, 0.75),
      },
      '.MuiTouchRipple-child': {
        backgroundColor: theme.palette.common.white,
      },
    },

    // Contained Primary
    '&.MuiIconButton-containedPrimary': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      ':hover': {
        backgroundColor: theme.palette.primary.dark,
      },
      '&.Mui-focusVisible': {
        backgroundColor: theme.palette.primary.dark,
      },
    },

    // Contained Primary Light
    '&.MuiIconButton-containedPrimary.--light': {
      backgroundColor: theme.palette.primary.tonal,
      color: theme.palette.text.primary,
      ':hover': {
        backgroundColor: theme.palette.primary.light,
      },
      '&.Mui-focusVisible': {
        backgroundColor: theme.palette.primary.light,
      },
      '&.Mui-disabled': {
        backgroundColor: theme.palette.background.disabled,
        color: theme.palette.text.disabled,
      },
    },

    /**
     * Secondary IconButton Styles
     * ---------------------------
     * */

    // Text Secondary
    '&.MuiIconButton-textSecondary': {
      ':hover': {
        background: theme.extendedColors.sunny[300],
      },
      '&.Mui-focusVisible': {
        backgroundColor: theme.palette.secondary.light,
      },
    },

    // Outlined Secondary
    '&.MuiIconButton-outlinedSecondary': {
      ':hover': {
        background: theme.extendedColors.sunny[300],
      },
      '&.Mui-focusVisible': {
        backgroundColor: alpha(theme.palette.secondary.main, 0.75),
      },
    },

    // Contained Secondary
    '&.MuiIconButton-containedSecondary': {
      backgroundColor: theme.palette.secondary.main,
      ':hover': {
        backgroundColor: theme.palette.secondary.dark,
      },
      '&.Mui-focusVisible': {
        backgroundColor: theme.palette.secondary.dark,
      },
    },

    // Contained Secondary Light
    '&.MuiIconButton-containedSecondary.--light': {
      backgroundColor: theme.palette.secondary.tonal,
      color: theme.palette.text.primary,
      ':hover': {
        backgroundColor: theme.palette.secondary.light,
      },
      '&.Mui-focusVisible': {
        backgroundColor: theme.palette.secondary.light,
      },
      '&.Mui-disabled': {
        backgroundColor: theme.palette.background.disabled,
        color: theme.palette.text.disabled,
      },
    },

    /**
     * Error IconButton Styles
     * -----------------------
     * */

    // Text Error
    '&.MuiIconButton-textError': {
      color: theme.palette.error.main,
      ':hover': {
        background: theme.extendedColors.fire[300],
      },
      '&.Mui-focusVisible': {
        backgroundColor: theme.palette.error.light,
      },
    },

    // Outlined Error
    '&.MuiIconButton-outlinedError': {
      borderColor: theme.palette.error.main,
      color: theme.palette.error.main,
      ':hover': {
        background: theme.extendedColors.fire[300],
        borderColor: theme.palette.error.main,
      },
      '&.Mui-focusVisible': {
        backgroundColor: alpha(theme.palette.error.main, 0.75),
        color: theme.palette.common.white,

        '.MuiTouchRipple-child': {
          backgroundColor: theme.palette.common.white,
        },
      },
    },

    // Contained Error
    '&.MuiIconButton-containedError': {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.common.white,
      ':hover': {
        backgroundColor: theme.palette.error.dark,
      },
      '&.Mui-focusVisible': {
        backgroundColor: theme.palette.error.dark,
      },
    },

    /**
     * Warning IconButton Styles
     * -------------------------
     * */

    // Text Warning
    '&.MuiIconButton-textWarning': {
      color: theme.palette.warning.main,
      ':hover': {
        background: theme.extendedColors.fire[300],
      },
      '&.Mui-focusVisible': {
        backgroundColor: theme.palette.warning.light,
      },
    },

    // Outlined Warning
    '&.MuiIconButton-outlinedWarning': {
      borderColor: theme.palette.warning.main,
      color: theme.palette.warning.main,
      ':hover': {
        background: theme.extendedColors.fire[300],
        borderColor: theme.palette.warning.main,
      },
      '&.Mui-focusVisible': {
        backgroundColor: alpha(theme.palette.warning.main, 0.75),
        color: theme.palette.common.white,

        '.MuiTouchRipple-child': {
          backgroundColor: theme.palette.common.white,
        },
      },
    },

    // Contained Warning
    '&.MuiIconButton-containedWarning': {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.common.white,
      ':hover': {
        backgroundColor: theme.palette.warning.dark,
      },
      '&.Mui-focusVisible': {
        backgroundColor: theme.palette.warning.dark,
      },
    },

    /**
     * Info IconButton Styles
     * ----------------------
     * */

    // Text Info
    '&.MuiIconButton-textInfo': {
      ':hover': {
        backgroundColor: theme.extendedColors.bloom[300],
      },
      '&.Mui-focusVisible': {
        backgroundColor: theme.palette.info.light,
      },
    },

    // Outlined Info
    '&.MuiIconButton-outlinedInfo': {
      ':hover': {
        backgroundColor: theme.extendedColors.bloom[300],
      },
      '&.Mui-focusVisible': {
        backgroundColor: alpha(theme.palette.info.main, 0.75),
      },
    },

    // Contained Info
    '&.MuiIconButton-containedInfo': {
      backgroundColor: theme.palette.info.main,
      color: theme.palette.common.white,
      ':hover': {
        backgroundColor: theme.palette.info.dark,
      },
      '&.Mui-focusVisible': {
        backgroundColor: theme.palette.info.dark,
      },
    },

    // Contained Info Light
    '&.MuiIconButton-containedInfo.--light': {
      backgroundColor: theme.palette.info.tonal,
      color: theme.palette.text.primary,
      ':hover': {
        backgroundColor: theme.palette.info.light,
      },
      '&.Mui-focusVisible': {
        backgroundColor: theme.palette.info.light,
      },
      '&.Mui-disabled': {
        backgroundColor: theme.palette.background.disabled,
        color: theme.palette.text.disabled,
      },
    },

    /**
     * Success IconButton Styles
     * -------------------------
     * */

    // Text Success
    '&.MuiIconButton-textSuccess': {
      ':hover': {
        background: theme.extendedColors.sage[300],
      },
      '&.Mui-focusVisible': {
        backgroundColor: theme.palette.success.light,
      },
    },

    // Outlined Success
    '&.MuiIconButton-outlinedSuccess': {
      ':hover': {
        background: theme.extendedColors.sage[300],
      },
      '&.Mui-focusVisible': {
        backgroundColor: alpha(theme.palette.success.main, 0.75),
      },
      '.MuiTouchRipple-child': {
        backgroundColor: theme.palette.common.white,
      },
    },

    // Contained Success
    '&.MuiIconButton-containedSuccess': {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.common.white,
      ':hover': {
        backgroundColor: theme.palette.success.dark,
      },
      '&.Mui-focusVisible': {
        backgroundColor: theme.palette.success.dark,
      },
    },

    /**
     * White IconButton Styles
     * -----------------------
     * */

    // Text White
    '&.MuiIconButton-textWhite': {
      color: theme.palette.common.white,
      '&.Mui-focusVisible': {
        backgroundColor: alpha(
          theme.palette.white.main,
          theme.palette.action.hoverOpacity,
        ),
      },
      '&.Mui-disabled': {
        color: theme.extendedColors.cement[400],
      },
    },

    // Outlined White
    '&.MuiIconButton-outlinedWhite': {
      backgroundColor: 'transparent',
      borderColor: theme.palette.common.white,
      color: theme.palette.common.white,
      '&.Mui-focusVisible': {
        backgroundColor: alpha(
          theme.palette.white.main,
          theme.palette.action.hoverOpacity,
        ),
      },
      '&.Mui-disabled': {
        borderColor: theme.extendedColors.cement[400],
        color: theme.extendedColors.cement[400],
      },
    },

    // Contained White
    '&.MuiIconButton-containedWhite': {
      backgroundColor: theme.palette.common.white,
      ':hover': {
        backgroundColor: alpha(
          theme.palette.common.white,
          theme.palette.action.hoverOpacity,
        ),
      },
      '&.Mui-focusVisible': {
        backgroundColor: alpha(
          theme.palette.common.white,
          theme.palette.action.hoverOpacity,
        ),
      },
    },

    /**
     * Gray IconButton Styles
     * ----------------------
     * */

    // Text Gray
    '&.MuiIconButton-textGray': {
      ':hover': {
        backgroundColor: alpha(
          theme.palette.gray.main,
          theme.palette.action.hoverOpacity,
        ),
      },
      '&.Mui-focusVisible': {
        backgroundColor: alpha(
          theme.palette.gray.main,
          theme.palette.action.hoverOpacity,
        ),
      },
    },

    // Outlined Gray
    '&.MuiIconButton-outlinedGray': {
      ':hover': {
        backgroundColor: theme.palette.gray.light,
      },
      '&.Mui-focusVisible': {
        backgroundColor: theme.palette.gray.light,
      },
    },

    // Contained Gray
    '&.MuiIconButton-containedGray': {
      backgroundColor: theme.palette.common.white,
      ':hover': {
        backgroundColor: alpha(
          theme.palette.common.white,
          theme.palette.action.hoverOpacity,
        ),
      },
      '&.Mui-focusVisible': {
        backgroundColor: alpha(
          theme.palette.common.white,
          theme.palette.action.hoverOpacity,
        ),
      },
    },
  }),
);
/* eslint-enable sort-keys,@typescript-eslint/no-magic-numbers */

const IconButton: FC<IconButtonProps> = ({
  className,
  variant = 'text',
  color = 'primary',
  size = 'large',
  light = false,
  ...rest
}) => {
  const classes = clsx(
    'mnt-icon-button',
    `MuiIconButton-${variant}`,
    {
      [`MuiIconButton-${variant}${capitalize(color as string)}`]: color,
      '--light': light,
    },
    className,
  );

  return (
    <StyledIconButton className={classes} color={color} size={size} {...rest} />
  );
};
IconButton.displayName = 'IconButton';

export default IconButton;
export type { IconButtonProps };
