import { DocumentViewState } from '@/types';
import { Paper, Table, TableContainer } from '@/ui';

import Grid from './Grid';
import Header from './Header';
import Rows from './Rows';

import type { Document } from '@/types';
import type { FC } from 'react';

interface ListProps {
  documents: Document[];
  view: DocumentViewState;
}

const List: FC<ListProps> = ({ documents, view }) => {
  if (view === DocumentViewState.GRID) return <Grid documents={documents} />;

  return (
    <>
      {/* @ts-ignore https://github.com/mui/material-ui/issues/29875 */}
      <TableContainer component={Paper}>
        <Table aria-label="Templates list">
          <Header />
          <Rows documents={documents} />
        </Table>
      </TableContainer>
    </>
  );
};

export default List;
