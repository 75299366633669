import { useNavigate } from 'react-router';

import useSharedCardActions from './useSharedCardActions';

import type { GeneratorFunction } from './types';

const usePeerInsightAwarenessActions = (): GeneratorFunction => {
  const { handleClick } = useSharedCardActions();
  const navigate = useNavigate();

  return ({ card }) => {
    const label = 'Start';
    return [
      {
        buttonProps: { color: 'white' },
        label,
        onClick: () => {
          handleClick({ card, label, shouldUpdate: false });
          navigate('/m/insights/');
        },
      },
    ];
  };
};

export default usePeerInsightAwarenessActions;
