import { captureException } from '@sentry/react';
import { useState } from 'react';

import { deleteMessageThreadMessage } from '@/api';
import { useGeMessageThreadMessages, useModal } from '@/hooks';
import { Button, ConfirmModal, Icon, Menu, MenuItem } from '@/ui';

import Edit from './Edit';

import type { MessageThreadMessage } from '@/types';
import type { FC } from 'react';

interface MenuOptionsProps {
  message: Partial<MessageThreadMessage>;
}

const MenuOptions: FC<MenuOptionsProps> = ({ message }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { refetch } = useGeMessageThreadMessages({
    messageThreadId: message.messageThreadId ?? '',
  });
  const {
    isOpen: isDeleteModalOpen,
    closeModal: closeDeleteModal,
    openModal: openDeleteModal,
  } = useModal();
  const {
    isOpen: isEditModalOpen,
    closeModal: closeEditModal,
    openModal: openEditModal,
  } = useModal();

  const handleMenuClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    setAnchorEl(e.currentTarget);
  };

  const onDeleteMessage = async () => {
    try {
      await deleteMessageThreadMessage(message.id ?? '');
      refetch();
    } catch (e) {
      captureException(e);
    }
    closeDeleteModal();
  };

  if (!message.id && !message.messageThreadId) {
    return null;
  }

  return (
    <>
      <ConfirmModal
        maxWidth="sm"
        open={isDeleteModalOpen}
        title="Delete"
        hideClose
        onClose={closeDeleteModal}
        onConfirm={onDeleteMessage}
      >
        Are you sure you want to delete this message?
      </ConfirmModal>

      <Edit
        close={closeEditModal}
        isOpen={isEditModalOpen}
        message={message}
        refetchMessages={refetch}
      />

      <Button onClick={handleMenuClick}>
        <Icon>more_horiz</Icon>
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={openDeleteModal}>
          <div className="flex items-center gap-2">
            <Icon>trash</Icon>
            Delete
          </div>
        </MenuItem>
        <MenuItem className="flex gap-2" onClick={openEditModal}>
          <Icon>edit</Icon>
          Edit message
        </MenuItem>
      </Menu>
    </>
  );
};

export default MenuOptions;
