import { useContext, useEffect } from 'react';

import { notifySlack, validateSubscription } from '@/api';
import { ONE, PREVIOUS_PAGE } from '@/constants';
import { useCurrentUser } from '@/hooks';
import { UserPaymentStatus } from '@/types';
import { track } from '@/utils';

import { GetStartedContext } from '@/components/GetStarted/context';
import { ZapTypes } from '@/types/zapier';

const MembershipPaid = () => {
  const { user, refetchUser } = useCurrentUser();
  const { nextStep } = useContext(GetStartedContext);

  useEffect(() => {
    track('ob_selectplan_cont');

    if (user.email) {
      (async () => {
        const userPayment = await validateSubscription(user);

        if (userPayment.status !== UserPaymentStatus.active) {
          nextStep(PREVIOUS_PAGE, true);
        } else {
          const paidMembershipUrl = `/silent/?email=${user.email}&plan=${
            userPayment.plan?.toLowerCase() ?? 'a'
          } plan`;
          await notifySlack(ZapTypes.USER_PAID_MEMBERSHIP, paidMembershipUrl);
          await refetchUser();
          nextStep(ONE, true);
        }
      })();
    }
  }, [user.email]);

  return <div className="flex w-screen flex-1 flex-col bg-sunny-200" />;
};

export default MembershipPaid;
