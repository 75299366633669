import { ZERO } from '@/constants';
import { Chip, Typography } from '@/ui';

import type { User } from '@/types';
import type { FC } from 'react';

import Company from '@/components/Profile/Coach/Experience/Company';

interface ExperienceProps {
  coach: User;
  industries?: string[];
}

const Experience: FC<ExperienceProps> = ({ coach, industries = [] }) => (
  <section className="max-w-2xl">
    <Typography className="mb-4" variant="h5">
      Professional experience
    </Typography>
    {coach.userEmployments && coach.userEmployments.length > ZERO && (
      <div className="mb-4 mt-2 flex flex-wrap gap-4">
        {coach.userEmployments.map((company) => (
          <Company key={company.id} company={company} />
        ))}
      </div>
    )}
    {!!industries.length && (
      <div className="flex flex-wrap gap-4">
        {industries.sort().map((industry) => (
          <Chip
            key={industry}
            color="default"
            label={industry}
            variant="filled"
          />
        ))}
      </div>
    )}
  </section>
);

export default Experience;
