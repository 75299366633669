import { Checkbox, TextField, Typography } from '@/ui';

import type { ChangeEvent, FC } from 'react';

interface RenameProps {
  isDraft: boolean;
  isTemplate: boolean;
  name: string;
  setIsDraft: (v: boolean) => void;
  setName: (v: string) => void;
}

const Rename: FC<RenameProps> = ({
  isDraft,
  isTemplate,
  setIsDraft,
  setName,
  name,
}) => {
  const onNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const onDraftClick = (event: ChangeEvent<HTMLInputElement>) => {
    setIsDraft(event.target.checked);
  };

  return (
    <div className="mt-1">
      <TextField
        className="w-full"
        label="Name"
        value={name}
        onChange={onNameChange}
      />

      {isTemplate && (
        <div className="mt-4">
          <Typography variant="body2">Properties</Typography>
          <div className="pt-1">
            <Checkbox checked={isDraft} onChange={onDraftClick} />
            Draft?
          </div>
        </div>
      )}
    </div>
  );
};

export default Rename;
