import { StyledEngineProvider } from '@mui/material/styles';

import type { FC, ReactNode } from 'react';

type GlobalCssPriorityProps = {
  children?: ReactNode;
};

const GlobalCssPriority: FC<GlobalCssPriorityProps> = ({ ...rest }) => (
  <StyledEngineProvider injectFirst {...rest} />
);

export default GlobalCssPriority;
export type { GlobalCssPriorityProps };
