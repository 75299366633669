import { useMutation, useQuery } from '@tanstack/react-query';

import {
  deleteMilestone,
  getMilestone,
  getMilestones,
  patchMilestone,
  postMilestone,
} from '@/api';
import { QUERY_KEY_MILESTONES } from '@/constants';

import type { GetMilestoneParams } from '@/api';
import type { Milestone, MilestoneSteps } from '@/types';

const mockMilestone = { steps: [] } as unknown as Milestone;

export const useGetMilestones = () =>
  useQuery<MilestoneSteps, Error>({
    placeholderData: { other: [], own: {} } as unknown as MilestoneSteps,
    queryFn: () => getMilestones(),
    queryKey: [QUERY_KEY_MILESTONES],
  });

export const useGetMilestone = (params: GetMilestoneParams) =>
  useQuery<Milestone, Error>({
    placeholderData: mockMilestone,
    queryFn: () => getMilestone(params),
    queryKey: [QUERY_KEY_MILESTONES, params.id],
  });

export const useUpdateMilestone = () =>
  useMutation({ mutationFn: patchMilestone });
export const useCreateMilestone = () =>
  useMutation({ mutationFn: postMilestone });
export const useDeleteMilestone = () =>
  useMutation({ mutationFn: deleteMilestone });
