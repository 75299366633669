import { createContext } from 'react';

import type { AppMessage } from '@/types';

type ContextProps = {
  chatId: string;
  selectedTeamId: string;
  selectedUserId: string;
  setAppMessage: (val: AppMessage) => void;
  setChatId: (val: string) => void;
};

const AppContext = createContext<ContextProps>({
  chatId: '',
  selectedTeamId: '',
  selectedUserId: '',
  setAppMessage: () => {},
  setChatId: () => {},
});

export { AppContext };
