import { Avatar, Card, CardContent, Chip, Typography } from '@/ui';

import type { User } from '@/types';
import type { FC } from 'react';

interface BusinessPlanProps {
  client: User;
}
const BusinessPlan: FC<BusinessPlanProps> = ({ client }) => (
  <Card>
    <CardContent>
      <Typography className="mb-2 flex justify-between" variant="subtitle1">
        Business Plan
        {!client.currentCompany?.isActive && (
          <Chip color="error" label="Inactive" />
        )}
      </Typography>
      <div className="flex items-center gap-3">
        <Avatar
          className="bg-sage-200 text-sage-600"
          icon="domain"
          iconClassName="text-2xl"
          src={client.currentCompany?.avatarUrl ?? ''}
        />

        <div>
          <Typography variant="subtitle1">
            {client.currentCompany?.name ?? ''}
          </Typography>
        </div>
      </div>
    </CardContent>
  </Card>
);

export default BusinessPlan;
