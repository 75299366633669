import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { deleteCoachProfile, updateCoachProfile, updateUser } from '@/api';
import { ONE, ZERO } from '@/constants';
import { Button, Dialog, DialogActions, DialogContent, Divider } from '@/ui';

import Settings from './Settings';

import type { User } from '@/types';
import type { FC } from 'react';

import Members from '@/components/Profile/Coach/Edit/Admin/Members';
import RemoveCoachModal from '@/pages/Member/Coaches/Remove';

interface EditCoachAdminProps {
  coach: User;
  onClose: (updated?: Partial<User>) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- we don't actually use the result
  refetchCoach: () => Promise<any>;
}

const EditCoachAdmin: FC<EditCoachAdminProps> = ({
  coach,
  refetchCoach,
  onClose,
}) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [isSaving, setIsSaving] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [pendingRemove, setPendingRemove] = useState(false);

  const [isActive, setIsActive] = useState(coach.isActive);
  const [capacity, setCapacity] = useState(
    coach.coachProfile?.capacity || ZERO,
  );

  const onActiveChange = (value: boolean) => setIsActive(value);
  const onCapacityChange = (value = ZERO) => setCapacity(value);

  const onSave = async () => {
    setIsSaving(true);
    await Promise.allSettled([
      updateUser(coach.id, { isActive }),
      updateCoachProfile(coach.id, { capacity }),
    ]);
    await refetchCoach();
    setIsSaving(false);
    onClose();
  };
  const onRemove = async () => {
    setIsRemoving(true);
    await deleteCoachProfile(coach.id);

    await queryClient.invalidateQueries(['coaches', '', ONE]);
    setIsRemoving(false);

    navigate('/coach/manage/coaches');
  };

  const onCancel = () => onClose();
  const onPendingRemove = () => setPendingRemove(true);
  const onConfirmRemove = async () => {
    await onRemove();
    setPendingRemove(false);
  };
  const onCancelRemove = () => setPendingRemove(false);

  return (
    <Dialog maxWidth="md" fullWidth open>
      <DialogContent>
        <Settings
          calendarUrl={coach.coachProfile?.calendarUrl}
          capacity={capacity}
          email={coach.email}
          isActive={!!isActive}
          onActivateChange={onActiveChange}
          onCapacityChange={onCapacityChange}
          onRemove={onPendingRemove}
        />
        <Divider className="my-8" />
        <Members coach={coach} refetchCoach={refetchCoach} />
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          color="primary"
          loading={isSaving}
          variant="contained"
          onClick={onSave}
        >
          Save
        </Button>
      </DialogActions>
      <RemoveCoachModal
        close={onCancelRemove}
        isOpen={pendingRemove}
        processing={isRemoving}
        onRemove={onConfirmRemove}
      />
    </Dialog>
  );
};

export default EditCoachAdmin;
