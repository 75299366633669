import clsx from 'clsx';
import { isToday } from 'date-fns';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { THREE, ZERO } from '@/constants';
import { AppContext } from '@/context';
import { useCurrentUser } from '@/hooks';
import { SessionListType, SessionStatus } from '@/types';
import { Avatar, Button, Icon, Paper } from '@/ui';
import { track } from '@/utils';

import Feedback from '../Feedback';

import SessionCardInfo from './Info';
import SessionCardMenu from './Menu';

import type { Session } from '@/types';
import type { FC } from 'react';

const SessionCard: FC<{
  isEmbedded?: boolean; // if true, its on overview
  onCancel?: (session: Session) => void;
  onClickPath?: string;
  onFeedback?: (session: Session) => void;
  onReschedule?: (session: Session) => void;
  session: Session;
  type: SessionListType;
}> = ({
  isEmbedded = false,
  onCancel = undefined,
  onFeedback = undefined,
  onReschedule = undefined,
  session,
  type,
  onClickPath = '',
}) => {
  const { setChatId } = useContext(AppContext);

  const { user } = useCurrentUser();
  const isUserSessionCoach = user.id === session.coachId;
  const concerningUser = isUserSessionCoach ? session.user : session.coach;
  const navigate = useNavigate();

  const handleOpenMenuOptions = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    track('Clicked Session Card Menu');
  };

  const handleViewProfile = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (!isEmbedded) {
      track('Clicked Profile Session Card', {
        clientId: session.userId,
        coachId: session.coachId,
        isCurrentUserCoach: isUserSessionCoach,
        sessionId: session.id,
      });
      if (!isUserSessionCoach) {
        navigate(`/m/profiles-coach/${concerningUser.id}`);
      } else {
        navigate(`/coach/members/${concerningUser.id}`);
      }
    }
  };

  const handleOnMessage = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    track('Clicked Message Participant', {
      clientId: session.userId,
      coachId: session.coachId,
      isCurrentUserCoach: isUserSessionCoach,
    });
    if (!isUserSessionCoach) {
      setChatId(session.coach.cometChatId);
    } else {
      setChatId(session.user.cometChatId);
    }
  };
  const onSessionClick = () => {
    navigate(onClickPath || `./../${session.id}`);
  };

  const handleOnFeedback = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (onFeedback) {
      onFeedback(session);
    }
  };

  const isDoneOrCancelled =
    session.status === SessionStatus.DONE ||
    session.status === SessionStatus.CANCELLED;
  const hasLocation =
    !!session.location?.startsWith('https:') && !isDoneOrCancelled;
  const happeningToday = isToday(new Date(session.startedAt));
  const showJoinNow = hasLocation && happeningToday;
  const missingFeedback = !session.feedback?.id;
  const showfeedbackCta =
    user.id === session.userId &&
    missingFeedback &&
    type === SessionListType.Past;

  return (
    <Paper
      className={clsx('cursor-pointer p-6', {
        '!p-0 border-none': isEmbedded,
      })}
      // eslint-disable-next-line react/forbid-component-props -- TODO: Evaluate if we can refactor this. This component may be a Card instead of Paper. Maybe.
      sx={{
        ':hover': {
          boxShadow: !isEmbedded ? THREE : ZERO,
        },
      }}
      variant="outlined"
      onClick={onSessionClick}
    >
      <div className="flex w-full flex-col ">
        <div className="flex gap-6">
          <div className="hidden cursor-default md:block">
            <Avatar
              src={concerningUser.avatarUrl}
              onClick={handleViewProfile}
            />
          </div>
          <div className="relative flex flex-1 flex-col justify-between p-0 md:flex-row">
            <SessionCardInfo name={concerningUser.fullName} session={session} />
            <div className="flex shrink-0 items-start gap-1">
              {showJoinNow && (
                <Button
                  className="w-full md:w-auto"
                  startIcon={<Icon>videocam</Icon>}
                  variant="contained"
                  onClick={() => window.open(session.location as string)}
                >
                  Join session
                </Button>
              )}
              {showfeedbackCta && (
                <Button variant="contained" light onClick={handleOnFeedback}>
                  {`How was your ${session.isChemistryCall ? 'chemistry call' : 'session'}?`}
                </Button>
              )}
              <Feedback handleOnFeedback={handleOnFeedback} session={session} />
              <div className="absolute right-0 top-0 md:static">
                <SessionCardMenu
                  handleOpenMenuOptions={handleOpenMenuOptions}
                  session={session}
                  type={type}
                  onCancel={onCancel}
                  onFeedback={onFeedback}
                  onMessage={handleOnMessage}
                  onReschedule={onReschedule}
                  onViewProfile={isEmbedded ? undefined : handleViewProfile}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Paper>
  );
};

export { SessionCard };
