import { useQueryClient } from '@tanstack/react-query';
import { clsx } from 'clsx';
import { formatDistance } from 'date-fns';
import { useState } from 'react';

import {
  BOULDER_COLORS,
  BOULDER_LIGHT_COLOR,
  QUERY_KEY_GROWTH_PLANS,
  QUERY_KEY_MILESTONE_STEPS,
  QUERY_KEY_MILESTONES,
} from '@/constants';
import { useDeleteMilestoneStep, useUpdateMilestoneStep } from '@/hooks';
import {
  Avatar,
  Checkbox,
  Chip,
  Icon,
  IconButton,
  ListItem,
  Typography,
} from '@/ui';
import { getBoulderColor } from '@/utils';

import type { MilestoneStep } from '@/types';
import type { ChangeEvent, FC } from 'react';

import StepDialog from '@/components/Growth/StepDialog';

interface StepProps {
  step?: MilestoneStep;
}

const Step: FC<StepProps> = ({ step }) => {
  const queryClient = useQueryClient();
  const updateMilestoneStep = useUpdateMilestoneStep();
  const deleteMilestoneStep = useDeleteMilestoneStep();
  const [isOpen, setIsOpen] = useState(false);

  if (!step) {
    return null;
  }

  const color = step.milestoneId ? getBoulderColor(step.milestoneId) : '';
  const textColor =
    color === 'sunny' ? `text-${color}-600` : `text-${color}-500`;
  const invalidateQueries = () => {
    queryClient.invalidateQueries([QUERY_KEY_GROWTH_PLANS]);
    queryClient.invalidateQueries([QUERY_KEY_MILESTONES, step.milestoneId]);
    queryClient.invalidateQueries([QUERY_KEY_MILESTONE_STEPS]);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    updateMilestoneStep.mutate(
      {
        id: step.id,
        isComplete: event.target.checked,
      },
      {
        onSuccess: invalidateQueries,
      },
    );
  };

  const handleChipClick = () => {
    setIsOpen(true);
  };

  const handleDelete = () => {
    deleteMilestoneStep.mutate(
      { id: step.id },
      { onSuccess: invalidateQueries },
    );
  };

  return (
    <ListItem key={step.id} className="group m-0 mb-3 p-0">
      <Checkbox
        className="-m-3 mr-1"
        defaultChecked={!!step.completedAt}
        onChange={handleChange}
      />
      <Chip
        className="h-fit min-h-8 max-w-11/12"
        label={
          <span className="flex items-center justify-start gap-2">
            <Typography
              className={clsx('whitespace-pre-wrap break-all', {
                'line-through': !!step.completedAt,
              })}
              color="text.primary"
              component="span"
              variant="body1"
            >
              {step.title}
            </Typography>

            {!!step.suggested && (
              <Icon className={`${textColor}`}>magic_button</Icon>
            )}

            {step.author ? (
              <Avatar
                firstName={step.author.firstName}
                lastName={step.author.lastName}
                size="xs"
                src={step.author.avatarUrl}
              />
            ) : null}

            {step.dueDate ? (
              <Typography
                color="text.secondary"
                component="span"
                variant="caption"
              >
                {formatDistance(Date.parse(step.dueDate), new Date(), {
                  addSuffix: true,
                })}
              </Typography>
            ) : null}
          </span>
        }
        style={{
          backgroundColor: step.milestoneId
            ? BOULDER_COLORS[BOULDER_LIGHT_COLOR]?.[color]
            : 'transparent',
        }}
        variant={step.milestoneId ? undefined : 'outlined'}
        onClick={step.completedAt ? undefined : handleChipClick}
      />
      {!step.completedAt ? (
        <IconButton
          className="ml-2 hidden group-hover:inline-flex"
          disabled={deleteMilestoneStep.isLoading}
          size="small"
          onClick={handleDelete}
        >
          <Icon>trash</Icon>
        </IconButton>
      ) : null}
      {isOpen && <StepDialog isOpen={isOpen} setOpen={setIsOpen} step={step} />}
    </ListItem>
  );
};
export default Step;
