import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

import { useGetGrowthPlans, useView } from '@/hooks';
import { Avatar, Button, Icon, Typography } from '@/ui';

import { useTeam } from '@/hooks/useTeam';

const TeamMember = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { teamId = '' } = useParams();

  const { team } = useTeam(teamId);
  const { selectedUser, isViewLoading } = useView();
  const { data: growthPlan = [] } = useGetGrowthPlans(
    { teamId, userId: selectedUser.id },
    { enabled: !!selectedUser.id || isViewLoading },
  );
  const teamGrowthPlan = growthPlan[0];

  const handleGoBack = () => {
    navigate(`/teams/${teamId}`);
  };

  const handleNavigateClick = (path: string) => () => {
    navigate(`./${path}`);
  };

  const getVariant = (path: string) => {
    if (location.pathname.includes(path)) {
      return 'contained';
    }
    return 'text';
  };

  const getClass = (path: string) => {
    let baseClass = 'justify-start';
    if (location.pathname.includes(path)) {
      baseClass += ' pl-3';
    }
    return baseClass;
  };

  return (
    <div className="flex-1 lg:flex">
      <div className="flex w-full flex-col items-center bg-sunny-200 px-6 py-4 lg:w-72 lg:min-w-72">
        <div className="headline flex items-center self-start py-5">
          <Button
            startIcon={<Icon>chevron_left</Icon>}
            variant="text"
            onClick={handleGoBack}
          >
            <Typography>{team.name}</Typography>
          </Button>
        </div>

        <Avatar
          firstName={selectedUser.firstName}
          lastName={selectedUser.lastName}
          loading={isViewLoading}
          size="xl"
          src={selectedUser.avatarUrl}
        />

        <Typography className="mt-4" variant="h5">
          {selectedUser.firstName}
        </Typography>
        {selectedUser.profile?.pronouns && (
          <Typography className="mt-2" variant="caption">
            {selectedUser.profile.pronouns}
          </Typography>
        )}
        {selectedUser.profile?.title && (
          <Typography className="mt-2" variant="caption">
            {selectedUser.profile.title}
          </Typography>
        )}

        <div className="mt-4 flex w-full flex-col gap-2">
          <Button
            className={getClass('overview')}
            startIcon={<Icon>home</Icon>}
            variant={getVariant('overview')}
            fullWidth
            onClick={handleNavigateClick('overview')}
          >
            Overview
          </Button>
          <Button
            className={getClass('growth')}
            startIcon={<Icon>landscape</Icon>}
            variant={getVariant('growth')}
            fullWidth
            onClick={handleNavigateClick(`growth/${teamGrowthPlan?.id}`)}
          >
            Growth plan
          </Button>
          <Button
            className={getClass('notes')}
            startIcon={<Icon>add_notes</Icon>}
            variant={getVariant('notes')}
            fullWidth
            onClick={handleNavigateClick('notes')}
          >
            Private notes
          </Button>
        </div>
      </div>
      <div className="w-full">
        <Outlet />
      </div>
    </div>
  );
};

export default TeamMember;
