import { useState } from 'react';

import { JOB_EXPERIENCE, PRONOUNS } from '@/constants';
import { useCurrentUser } from '@/hooks';
import { UploadType } from '@/types';
import {
  Avatar,
  Button,
  CreatableSelect,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@/ui';

import type { User, UserProfile } from '@/types';
import type { ChangeEvent, FC } from 'react';

import UploadImage from '@/components/Upload';

interface ClientProfileProps {
  client: User;
  onChange: (updated: User) => void;
  onProfileChange: (updated: UserProfile) => void;
  profile: UserProfile;
}

const ClientProfile: FC<ClientProfileProps> = ({
  client,
  onChange,
  onProfileChange,
  profile,
}) => {
  const { user: currentUser } = useCurrentUser();
  const [uploadingPhoto, setUploadingPhoto] = useState(false);

  const onUpdate = (changes: Partial<User>) =>
    onChange({ ...client, ...changes });

  const onProfileUpdate = (changes: Partial<UserProfile>) =>
    onProfileChange({ ...profile, ...changes });

  const onNameChange = (e: ChangeEvent<HTMLInputElement>) =>
    onUpdate({ fullName: e.target.value || '' });

  // We can trim since emails should never have whitespace anyways
  const onEmailChange = (e: ChangeEvent<HTMLInputElement>) =>
    onUpdate({ email: e.target.value.trim() || '' });

  const onPronounChange = (value: string) =>
    onProfileUpdate({ pronouns: value });

  const onLocationChange = (e: ChangeEvent<HTMLInputElement>) =>
    onProfileUpdate({ location: e.target.value });
  const onCompanyChange = (e: ChangeEvent<HTMLInputElement>) =>
    onProfileUpdate({ company: e.target.value });

  const onRoleChange = (e: ChangeEvent<HTMLInputElement>) =>
    onProfileUpdate({ title: e.target.value || '' });

  const onExperienceChange = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => onProfileUpdate({ experience: e.target.value || '' });

  const onUpload = () => setUploadingPhoto(true);
  const onUploadClose = (url?: string) => {
    if (url) {
      onUpdate({ avatarUrl: url });
    }
    setUploadingPhoto(false);
  };
  return (
    <Grid spacing={4} container>
      <Grid md={5} xs={12} item>
        <Typography className="mb-2" variant="h4">
          Name & role
        </Typography>
        <Avatar
          className="my-4"
          firstName={client.firstName}
          iconClassName="text-5xl"
          lastName={client.lastName}
          size="xl"
          src={client.avatarUrl}
        />
        <div className="mt-8">
          <Button variant="contained" onClick={onUpload}>
            Upload photo
          </Button>
        </div>
      </Grid>
      <Grid className="mt-4 flex flex-col gap-3" md={7} xs={12} item>
        <TextField
          label="Name"
          value={client.fullName}
          onChange={onNameChange}
        />
        {currentUser.superAdmin && (
          <TextField
            label="Email"
            value={client.email}
            onChange={onEmailChange}
          />
        )}
        <CreatableSelect
          label="Pronouns"
          options={PRONOUNS}
          value={profile.pronouns ?? ''}
          onChange={onPronounChange}
        />
        <TextField
          label="Location"
          value={profile.location}
          onChange={onLocationChange}
        />
        <TextField
          label="Job title"
          value={profile.title}
          onChange={onRoleChange}
        />
        <TextField
          label="Company"
          value={profile.company}
          onChange={onCompanyChange}
        />
        <TextField
          label="Years of experience"
          placeholder="Years of experience"
          value={profile.experience}
          select
          onChange={onExperienceChange}
        >
          {JOB_EXPERIENCE.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      {uploadingPhoto ? (
        <UploadImage
          filename={client.id}
          type={UploadType.PROFILE}
          onClose={onUploadClose}
        />
      ) : null}
    </Grid>
  );
};

export default ClientProfile;
