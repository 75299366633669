import { useState } from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input';

import { updateUserNotifications, updateUserProfile } from '@/api';
import { ZERO } from '@/constants';
import { useCurrentUser } from '@/hooks';
import { type User, type UserNotifications } from '@/types';
import { Button, Dialog, DialogActions, DialogContent, Divider } from '@/ui';

import Details from './Details';
import Hours from './Hours';
import Notifications from './Notifications';

import type { FC } from 'react';

interface EditAccountProps {
  coach: User;
  onClose: (updated?: Partial<User>) => void;
}

const EditCoachAccount: FC<EditAccountProps> = ({ coach, onClose }) => {
  const { refetchUser } = useCurrentUser();
  const [isSaving, setIsSaving] = useState(false);
  const [timezone, setTimezone] = useState(coach.profile?.timezone || '');
  const [phone, setPhone] = useState(coach.profile?.phone || '');
  const [preferences, setPreferences] = useState(coach.userNotifications ?? {});
  const onPhoneChange = (value?: string) => setPhone(value || '');
  const onTimezoneChange = (value: string) => setTimezone(value);

  const onSave = async () => {
    try {
      setIsSaving(true);
      await updateUserProfile(coach.id, {
        phone,
        timezone,
      });
      await updateUserNotifications(coach.id, preferences);
      await refetchUser();
      setIsSaving(false);
      onClose();
    } catch (error) {
      console.error('Error saving coach profile', error);
    } finally {
      setIsSaving(false);
    }
  };

  const onPreferencesChange = (value: UserNotifications) =>
    setPreferences(value);

  const onCancel = () => onClose();

  const isPhoneInvalid =
    !isValidPhoneNumber(phone || '', 'US') && (phone || '').length > ZERO;

  return (
    <Dialog maxWidth="md" fullWidth open>
      <DialogContent>
        <Details
          capacity={coach.coachProfile?.capacity ?? ZERO}
          email={coach.email}
          phone={phone}
          timezone={timezone}
          onPhoneChange={onPhoneChange}
          onTimezoneChange={onTimezoneChange}
        />
        <Divider className="my-8" />
        <Hours />
        <Divider className="my-8" />
        <Notifications
          preferences={preferences}
          onPreferencesChange={onPreferencesChange}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={isPhoneInvalid}
          loading={isSaving}
          variant="contained"
          onClick={onSave}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditCoachAccount;
