import { formatDistance } from 'date-fns';

import { useCurrentUser } from '@/hooks';
import { SessionWarningAction } from '@/types';
import {
  Avatar,
  AvatarGroup,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Icon,
  Typography,
} from '@/ui';
import { track } from '@/utils';

import type { Session } from '@/types';
import type { FC } from 'react';

interface ReschedulePromptProps {
  action: SessionWarningAction;
  close: () => void;
  onCancel?: (session: Session) => void;
  onReschedule: (session: Session) => void;
  session: Session;
}
const ReschedulePrompt: FC<ReschedulePromptProps> = ({
  action,
  session,
  close,
  onCancel,
  onReschedule,
}) => {
  const { user } = useCurrentUser();
  const isCoachViewing = user.id === session.coachId;
  const coachName = session.coach.firstName;
  const clientName = session.user.firstName;
  const name = !isCoachViewing ? coachName : clientName;
  const otherName = !isCoachViewing ? clientName : coachName;

  const isCancel = action === SessionWarningAction.CANCEL;
  const buttonTitle = isCancel ? 'Cancel' : 'Reschedule';
  const buttonColor = isCancel ? 'warning' : 'primary';

  const handleClick = () => {
    if (isCancel) {
      track('Clicked Cancel Session', {
        bookId: session.bookId,
        sessionId: session.id,
      });
      onCancel?.(session);
    } else {
      track('Clicked Reschedule Session', {
        bookId: session.bookId,
        sessionId: session.id,
      });
      onReschedule(session);
    }
    close();
  };

  const handleReschedule = () => {
    track('Clicked Reschedule Session', {
      bookId: session.bookId,
      sessionId: session.id,
    });
    onReschedule(session);
    close();
  };

  const distance = formatDistance(new Date(session.startedAt), new Date(), {
    addSuffix: false,
  });

  return (
    <Dialog maxWidth="md" open>
      <DialogContent>
        <Grid spacing={4} container>
          <Grid className="flex flex-col self-baseline" md={5} xs={12} item>
            <AvatarGroup className="mb-2 self-baseline" size="m">
              <Avatar
                alt="coach profile image"
                className="rounded-full"
                src={session.coach.avatarUrl}
              />
              <Avatar
                alt="coachee profile image"
                className="rounded-full"
                src={session.user.avatarUrl}
              />
            </AvatarGroup>
            <Typography variant="h5">
              Are you sure you want to {buttonTitle.toLowerCase()} this session?
            </Typography>
          </Grid>
          <Grid className="space-y-6" md={7} xs={12} item>
            <Typography variant="subtitle1">
              Your session with {name}
              <span className="text-red-700"> is within {distance}</span>.
            </Typography>
            <Typography variant="body1">Hi {otherName},</Typography>
            {!isCoachViewing ? (
              <Typography variant="body1">
                We understand life happens – your coach does, too! Make sure you
                are always communicating with {name} about events that can lead
                to rescheduled or cancelled sessions. <br />
                <br /> Remember, changes affect not only your schedule, but your
                coach's too.
              </Typography>
            ) : (
              <Typography variant="body1">
                We understand. Things come up and sometimes it's just not
                possible to stick to the original plan.
              </Typography>
            )}
            {isCancel && !isCoachViewing && (
              <Typography variant="body1">
                Keep up your growth momentum with {name} by rescheduling the
                session instead. Don't let today's cancellation become a road
                block in your journey.
              </Typography>
            )}
            {isCoachViewing && (
              <Typography variant="body1">
                On the rare occasion, you need to reschedule a coaching session,
                be sure to let your member know at least 48 hours in advance of
                your session. If you can’t, then give Mento a shout.
              </Typography>
            )}
            {isCancel && (
              <Button
                endIcon={<Icon>chevron_right</Icon>}
                variant="contained"
                light
                onClick={handleReschedule}
              >
                Reschedule session
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="gray" variant="outlined" onClick={close}>
          Nevermind
        </Button>
        <Button color={buttonColor} variant="contained" onClick={handleClick}>
          {buttonTitle} session
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReschedulePrompt;
