import MuiPaper from '@mui/material/Paper';
import { clsx } from 'clsx';

import type { PaperProps } from '@mui/material/Paper';
import type { FC } from 'react';

const Paper: FC<PaperProps> = ({ className, ...rest }) => {
  const classes = clsx('mento-paper', className);

  return <MuiPaper {...rest} className={classes} />;
};
Paper.displayName = 'Paper';

export default Paper;
export type { PaperProps };
