import { SMALL_BREAKPOINT } from '@/constants';
import { useCurrentUser } from '@/hooks';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useMediaQuery,
} from '@/ui';

import { NotAssociatedSteps } from '../types';

import type { FC } from 'react';

interface WelcomeModalProps {
  setStep: (val: NotAssociatedSteps) => void;
}

const WelcomeModal: FC<WelcomeModalProps> = ({ setStep }) => {
  const { user, isUserLoading } = useCurrentUser();

  const isSmall = useMediaQuery(SMALL_BREAKPOINT);

  const onClick = () => {
    setStep(NotAssociatedSteps.Timezone);
  };

  if (isUserLoading) return null;

  return (
    <Dialog open>
      <DialogTitle className="bg-sage-100 px-6 pb-0 pt-4 md:px-12 md:pt-8">
        <Typography variant="overline">Mento coaching</Typography>
      </DialogTitle>
      <DialogContent className="flex flex-col bg-sage-100 p-0">
        <div className="flex flex-col px-6 md:px-12">
          <Typography variant={isSmall ? 'h4' : 'h3'}>
            Welcome, {user.firstName}!
          </Typography>
          <Typography className="mb-2 mt-4" variant="subtitle1">
            We’re thrilled to match you with just the right coach.
          </Typography>
          <Typography className="mb-4">
            Let’s get you set up for success with a couple housekeeping items.
            This should only take a few minutes to complete.
          </Typography>
        </div>
        <img
          alt="timezone-hero"
          src="https://mento-space.nyc3.cdn.digitaloceanspaces.com/assets/new_welcome_tz_select.png"
        />
      </DialogContent>
      <DialogActions className="border-y-0 bg-sage-100 pb-6 pr-6 pt-4 md:pb-12 md:pr-12">
        <Button color="primary" variant="contained" onClick={onClick}>
          Let's get started
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WelcomeModal;
