import clsx from 'clsx';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { Icon, IconButton } from '@/ui';

import About from './About';
import CompanyCulture from './Culture';
import EditCompanyDialog from './Edit';
import CompanyGoals from './Goals';
import MissionAndVision from './Vision';

import type { Company } from '@/types';
import type { FC } from 'react';

const Info: FC<{
  company: Company;
}> = ({ company }) => {
  const [isEditing, setIsEditing] = useState(false);

  const onEditing = () => setIsEditing(true);
  const onCloseEditing = () => setIsEditing(false);

  return (
    <div className="w-full bg-sage-100 px-8 py-4 lg:min-w-94 lg:max-w-94">
      <div className="mb-3 flex justify-end">
        <IconButton
          aria-label="edit"
          color="primary"
          size="large"
          onClick={onEditing}
        >
          <Icon>edit</Icon>
        </IconButton>
      </div>
      <About company={company} />
      <div
        className={clsx('flex flex-col gap-6', {
          'mt-16':
            !!company.vision || !!company.culture || !!company.goals.length,
        })}
      >
        {!!company.vision && <MissionAndVision vision={company.vision} />}
        {!!company.culture && <CompanyCulture culture={company.culture} />}
        {!!company.goals.length && <CompanyGoals goals={company.goals} />}
      </div>
      <div className="mt-4 flex gap-2">
        {!!company.linkedin && (
          <Link rel="noopener noreferrer" target="_blank" to={company.linkedin}>
            <IconButton color="primary" variant="outlined">
              <Icon>linkedin</Icon>
            </IconButton>
          </Link>
        )}
        {!!company.website && (
          <Link rel="noopener noreferrer" target="_blank" to={company.website}>
            <IconButton color="primary" variant="outlined">
              <Icon>language</Icon>
            </IconButton>
          </Link>
        )}
      </div>
      {isEditing ? (
        <EditCompanyDialog company={company} onClose={onCloseEditing} />
      ) : null}
    </div>
  );
};

export default Info;
