import { useFlagBag } from '@happykit/flags/context';
import { useNavigate } from 'react-router-dom';

import type { FeatureFlags } from '@/types';

import CoachAISession from '@/components/CoachAI/CoachAISession';

const CoachAISessionPage = () => {
  const { flags } = useFlagBag<FeatureFlags>();
  const navigate = useNavigate();

  if (!flags?.ai_workflows) {
    navigate('/');
    return null;
  }

  return <CoachAISession />;
};

export default CoachAISessionPage;
