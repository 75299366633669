import { Avatar, Chip, Icon, IconButton, Typography } from '@/ui';

import type { FC } from 'react';

import { type TeamMember, TeamPermission } from '@/types/teams';

interface EditTeamMemberProps {
  canRemove: boolean;
  member: TeamMember;
  memberToDelete: (val: TeamMember) => () => void;
}

const EditTeamMember: FC<EditTeamMemberProps> = ({
  member,
  memberToDelete,
  canRemove,
}) => (
  <div className="flex items-center justify-between">
    <div className="flex items-center gap-5">
      <Avatar
        firstName={member.user.firstName}
        lastName={member.user.lastName}
        size="m"
        src={member.user.avatarUrl}
      />
      <div>
        <Typography>{member.user.fullName}</Typography>
        <Typography variant="caption">{member.status}</Typography>
      </div>
    </div>
    <div>
      {member.permission === TeamPermission.admin && (
        <Chip className="ml-auto" label="Team admin" />
      )}
      {canRemove && (
        <IconButton onClick={memberToDelete(member)}>
          <Icon>trash</Icon>
        </IconButton>
      )}
    </div>
  </div>
);

export default EditTeamMember;
