import Item from './Item';

import type { Document } from '@/types';
import type { FC } from 'react';

interface GridProps {
  documents: Document[];
}

const Grid: FC<GridProps> = ({ documents }) => (
  <section className="flex-1" role="navigation">
    <ul className="grid grid-cols-1 gap-4 pl-0 sm:grid-cols-2 md:grid-cols-3">
      {documents.map((current) => (
        <Item key={`grid-view-${current.id}`} current={current} />
      ))}
    </ul>
  </section>
);

export default Grid;
