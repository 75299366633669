import { useState } from 'react';

import { useCurrentUser } from '@/hooks';
import { DocMimeType } from '@/types';
import { Button, Icon, Menu } from '@/ui';

import CreateDialog from './Dialog';
import Item from './Item';

import type { FC, MouseEvent } from 'react';

interface CreateProps {
  draft?: boolean;
  isOutlineButton?: boolean;
  isTemplate?: boolean;
  maxDocumentsReached?: boolean;
}

const Create: FC<CreateProps> = ({
  draft = false,
  isOutlineButton = false,
  isTemplate = false,
  maxDocumentsReached = false,
}) => {
  const { user } = useCurrentUser();

  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [currentMime, setCurrentMime] = useState<DocMimeType>();
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);

  const onCloseMenu = () => {
    setAnchor(null);
  };

  const onCreate = (mime: DocMimeType) => () => {
    setIsCreateOpen(true);
    setCurrentMime(mime);
    onCloseMenu();
  };
  const onCloseCreate = () => setIsCreateOpen(false);
  const onClick = (event: MouseEvent<HTMLElement>) => {
    setAnchor(event.currentTarget);
  };

  let buttonText = 'Create';
  let variant: 'contained' | 'outlined' = 'contained';

  if (isTemplate) {
    buttonText += ' new template';
  }

  if (isTemplate || isOutlineButton) {
    variant = 'outlined';
  }

  if (!user.superAdmin && isTemplate) return null;

  return (
    <>
      <Button
        className="w-full md:w-fit"
        disabled={maxDocumentsReached}
        endIcon={<Icon>expand_more</Icon>}
        variant={variant}
        onClick={onClick}
      >
        {buttonText}
      </Button>
      <Menu anchorEl={anchor} open={!!anchor} onClose={onCloseMenu}>
        <Item
          mimeType={DocMimeType.DOCUMENT}
          onCreate={onCreate(DocMimeType.DOCUMENT)}
        />
        <Item
          mimeType={DocMimeType.SPREADSHEET}
          onCreate={onCreate(DocMimeType.SPREADSHEET)}
        />
      </Menu>

      <CreateDialog
        close={onCloseCreate}
        draft={draft}
        isOpen={isCreateOpen}
        isTemplate={isTemplate}
        mimeType={currentMime}
      />
    </>
  );
};

export default Create;
