import Typography from '../Typography';

import type { FC } from 'react';

interface PageHeaderLayoutProps {
  children?: React.ReactNode;
  subtitle?: string;
  title: string;
}

const PageHeaderLayout: FC<PageHeaderLayoutProps> = ({
  children = null,
  title = '',
  subtitle = '',
}) => (
  <div className="w-full md:flex md:items-center md:justify-between">
    <div className="flex items-center">
      {/* Hack to Ensure if button is present all titles are size */}
      <div className="min-h-10 py-8">
        <Typography variant="h4">{title}</Typography>
        <Typography variant="subtitle1">{subtitle}</Typography>
      </div>
    </div>
    <div className="mt-2 flex flex-col-reverse justify-stretch space-y-3 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
      {children}
    </div>
  </div>
);

export default PageHeaderLayout;
export type { PageHeaderLayoutProps };
