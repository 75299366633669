import clsx from 'clsx';

import { ONE, ZERO } from '@/constants';
import { Typography } from '@/ui';

import CoachCard from '../../Coach/Card';

import type { User } from '@/types';
import type { FC } from 'react';

interface Props {
  coaches: User[];
  isLoading?: boolean;
  refetch: () => void;
  user: User;
}

const ClientCoaches: FC<Props> = ({
  isLoading = false,
  coaches = [],
  user,
  refetch,
}) => {
  if (isLoading || coaches.length === ZERO) return null;

  return (
    <>
      <Typography
        className="mt-8 select-none px-2 text-cement-400 md:px-0"
        variant="subtitle1"
      >
        Your coach{coaches.length > ONE ? 'es' : ''}
      </Typography>
      <div
        className={clsx('gap-2 pt-4', {
          'grid md:grid-cols-2': coaches.length > ONE,
        })}
      >
        {coaches.map((coach) => (
          <CoachCard
            key={coach.id}
            coach={coach}
            refetch={refetch}
            user={user}
          />
        ))}
      </div>
    </>
  );
};

export default ClientCoaches;
