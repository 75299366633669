import mentoApi from './mentoApi';

export const createAiChatThread = async (params: {
  assistantId?: string;
  userId?: string;
}) => (await mentoApi.post('/ai/threads/', params)).data;

export const getAiChatThread = async (threadId?: string) =>
  (await mentoApi.get(`/ai/threads/${threadId}`)).data;

export const addMessageToThread = async (threadId: string, content: string) =>
  (await mentoApi.post(`/ai/threads/${threadId}/post_message`, { content }))
    .data;

export const getResponsesFromThread = async (threadId: string) =>
  (await mentoApi.get(`/ai/threads/${threadId}/get_responses`)).data;
