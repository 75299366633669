import { Switch, Typography } from '@/ui';

import type { FC } from 'react';

interface Props {
  isAnnual: boolean;
  toggle: () => void;
}
const Frequency: FC<Props> = ({ isAnnual, toggle }) => (
  <div className="align-center grid-rows-auto grid auto-cols-auto items-center text-center">
    <Typography variant="subtitle1">Monthly</Typography>
    <Switch checked={isAnnual} onChange={toggle} />
    <Typography variant="subtitle1">Annually</Typography>
    <Typography
      className="col-start-3 font-bold text-sage-500"
      variant="caption"
    >
      Save up to $1500
    </Typography>
  </div>
);
export default Frequency;
