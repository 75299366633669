import mentoApi from './mentoApi';

import type { UserEmployment } from '@/types';

export const getUserEmployment = async (id: string) => {
  const result = await mentoApi.get(`/user_employments?user_id=${id}`);
  return result.data;
};

export const createUserEmployment = async (
  userEmployment: Partial<UserEmployment>,
) => {
  const result = await mentoApi.post('/user_employments', { userEmployment });
  return result.data;
};

export const updateUserEmployment = async (
  id: string,
  userEmployment: Partial<UserEmployment>,
) => {
  const result = await mentoApi.put(`/user_employments/${id}`, {
    userEmployment,
  });
  return result.data;
};

export const bulkUpdateUserEmployment = async (
  employments: Partial<UserEmployment[]>,
) => {
  const result = await mentoApi.post('/user_employments/bulk', {
    userEmployment: { employments },
  });
  return result.data;
};
