import clsx from 'clsx';

import { LOGOS } from '@/constants';
import { Typography } from '@/ui';

import type { FC } from 'react';

const Logos: FC = () => (
  <div className="flex flex-col items-center bg-white pt-10">
    <div className="flex w-full max-w-260 flex-wrap justify-center gap-4 lg:items-center">
      {LOGOS.map(({ alt, hidden, src }) => (
        <img
          key={`membership-logos-${alt}`}
          alt={alt}
          className={clsx('w-24 py-4 md:flex lg:w-32', { hidden })}
          src={src}
        />
      ))}
    </div>
    <Typography className="my-10" variant="body2">
      Trusted by employees from the best companies
    </Typography>
  </div>
);

export default Logos;
