import { Switch } from '@/ui';

import type { FC } from 'react';

// TODO: Fix this, it is a children importing a Parent
// if it is a variant, structure should be different
import Card from '@/components/Profile/Card';

interface ToggleCardProps {
  icon?: string;
  isDisabled?: boolean;
  isSelected?: boolean;
  onClick: (e: unknown) => void;
  title: string;
}

const ToggleCard: FC<ToggleCardProps> = ({
  title,
  icon = '',
  isSelected = false,
  isDisabled = false,
  onClick,
}) => (
  <Card icon={icon} title={title}>
    <Switch
      checked={isSelected}
      color="primary"
      disabled={isDisabled}
      onClick={onClick}
    />
  </Card>
);

export default ToggleCard;
