import { useEffect, useState } from 'react';

import { createSurvey, sendSurvey, updateParticipants } from '@/api';
import {
  ONE,
  PEER_STEP_DATA,
  SURVEY_ADD_RECIPIENTS_STEP,
  SURVEY_EMAIL_STEP,
  SURVEY_INITIAL_STEP,
  THREE,
  TWO,
  ZERO,
} from '@/constants';
import { SurveyStatus } from '@/types';
import { isValidEmail, track } from '@/utils';

import Email from './Email';
import InProgress from './InProgress';
import Instructions from './Instructions';
import Layout from './Layout';
import Recipients from './Recipients';

import type { Recipient, Survey, SurveyTemplate } from '@/types';
import type { ChangeEvent, FC } from 'react';

interface SurveyDataProps {
  survey: Survey;
  template: SurveyTemplate;
}

const SurveyBuilder: FC<SurveyDataProps> = ({ template, survey }) => {
  const [emailTemplate, setEmailTemplate] = useState(template.emailTemplate);
  const [id, setId] = useState(survey.id);

  const [step, setStep] = useState(
    survey.id ? SURVEY_EMAIL_STEP : SURVEY_INITIAL_STEP,
  );

  const [recipients, setRecipients] = useState<Recipient[]>([
    ...(survey.responses ?? []),
    { email: '', name: '', relationship: '' },
  ]);

  const onAddRecipients = async () => {
    // Remove empty "rows"
    const scrubbedRecipients = recipients.filter(
      ({ name, email, relationship }) => name && email && relationship,
    );
    setRecipients([
      ...scrubbedRecipients,
      { email: '', name: '', relationship: '' },
    ]);

    if (id) {
      await updateParticipants(id, scrubbedRecipients);
    } else {
      const newSurvey = await createSurvey(template.id, scrubbedRecipients);
      setId(newSurvey.id);
    }
  };

  const isValid = () => {
    if (step === SURVEY_ADD_RECIPIENTS_STEP) {
      const scrubbedRecipients = recipients.filter(
        ({ name, email, relationship }) => name && email && relationship,
      );

      return (
        scrubbedRecipients.every(({ email }) => !isValidEmail(email ?? '')) &&
        scrubbedRecipients.length >= template.minimumRecipients
      );
    }
    return true;
  };
  const onChangeTemplate = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setEmailTemplate(value);
  };

  useEffect(() => {
    if (!id) {
      const newRecipients = Array(template.minimumRecipients).fill({
        email: '',
        name: '',
        relationship: '',
      });
      setRecipients(newRecipients);
    }
  }, []);

  PEER_STEP_DATA[1].callback = onAddRecipients;
  PEER_STEP_DATA[2].callback = () => {
    track('Clicked send survey');
    return sendSurvey(id, emailTemplate);
  };
  PEER_STEP_DATA[2].button = `Send to ${recipients.length - ONE} recipients`;

  if (survey.status === SurveyStatus.IN_PROGRESS || step === THREE) {
    return <InProgress />;
  }

  return (
    <Layout
      isValid={isValid}
      setStep={setStep}
      step={step}
      stepData={PEER_STEP_DATA}
    >
      {step === ZERO && <Instructions steps={template.steps ?? []} />}
      {step === ONE && (
        <Recipients
          maximum={template.maximumRecipients}
          recipients={recipients}
          setRecipients={setRecipients}
        />
      )}
      {step === TWO && (
        <Email setTemplate={onChangeTemplate} template={emailTemplate} />
      )}
    </Layout>
  );
};

export default SurveyBuilder;
