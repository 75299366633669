import { BASE_DOCS_URL, BASE_DRIVE_URL, DocumentDisplay } from '@/constants';
import { DocMimeType } from '@/types';

export const toBase64 = (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export const fileToMimeType = (fileNameWithExtension: string): string => {
  if (
    fileNameWithExtension.endsWith('.doc') ||
    fileNameWithExtension.endsWith('.docx') ||
    fileNameWithExtension.endsWith('.txt')
  ) {
    return DocMimeType.DOCUMENT;
  }
  if (fileNameWithExtension.endsWith('.pdf')) {
    return ''; // Return empty on this case so google does its thing
  }
  if (
    fileNameWithExtension.endsWith('.xlsx') ||
    fileNameWithExtension.endsWith('.xls')
  ) {
    return DocMimeType.SPREADSHEET;
  }
  if (
    fileNameWithExtension.endsWith('.ppt') ||
    fileNameWithExtension.endsWith('.pptx')
  ) {
    return DocMimeType.PRESENTATION;
  }
  return DocMimeType.UNKNOWN;
};

export const mimeTypeUrl = (mimeType: DocMimeType) => {
  switch (mimeType) {
    case DocMimeType.SPREADSHEET:
      return `${BASE_DOCS_URL}/spreadsheets/d`;
    case DocMimeType.DOCUMENT:
      return `${BASE_DOCS_URL}/document/d`;
    case DocMimeType.PRESENTATION:
      return `${BASE_DOCS_URL}/presentation/d`;
    default:
      return `${BASE_DRIVE_URL}`;
  }
};

export const toDocumentUrl = (
  mimeType: DocMimeType,
  id: string,
  isPreview = false,
) => {
  const base = mimeTypeUrl(mimeType);

  switch (mimeType) {
    case DocMimeType.DOCUMENT:
    case DocMimeType.SPREADSHEET:
    case DocMimeType.PRESENTATION:
      return `${base}/${id}/${isPreview ? 'preview' : 'edit'}`;
    default:
      return `${base}/file/d/${id}/preview`;
  }
};

export const getDownloadLink = (referenceId: string, mimeType: DocMimeType) => {
  const base = mimeTypeUrl(mimeType);
  const reference = `/${referenceId}/export?format=`;
  const url = `${base}/${reference}`;

  switch (mimeType) {
    case DocMimeType.SPREADSHEET:
      return `${url}xlsx`;
    case DocMimeType.DOCUMENT:
      return `${url}pdf`;
    default:
      return `${base}/uc?id=${referenceId}&export=download`;
  }
};

export const getIconSrcFromMimeType = (referenceMimeType: DocMimeType) => {
  const { icon = 'doc' } = DocumentDisplay[referenceMimeType] ?? {};
  return `https://mento-space.nyc3.cdn.digitaloceanspaces.com/icons/${icon}.svg`;
};
