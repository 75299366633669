import { useCurrentUser, useHomeCardActions } from '@/hooks';
import { ActionCard } from '@/ui';
import { oldCoachToNew } from '@/utils';

import HomeCardContent from './HomeCardContent';
import HomeCardMedia from './HomeCardMedia';

import type { Coach, HomeCardProps } from '@/types';
import type { FC } from 'react';

import ScheduleSession from '@/components/Cal/scheduleSession';

const HomeCard: FC<HomeCardProps> = ({ onDismiss, refetch, ...card }) => {
  const { actions, isScheduleOpen, onCloseSchedule } = useHomeCardActions({
    card,
  });
  const { user } = useCurrentUser();

  const handleDismiss = () => {
    onDismiss(card.id);
  };
  let coach;
  if (card.coach) {
    coach = oldCoachToNew(card.coach as Coach, user.coaches);
  }

  return (
    <>
      <ActionCard
        key={card.id}
        {...card}
        actions={actions}
        cardMedia={<HomeCardMedia card={card} />}
        onClose={card.isDismissable ? handleDismiss : undefined}
      >
        <HomeCardContent card={card} />
      </ActionCard>
      {isScheduleOpen && coach && (
        <ScheduleSession
          callback={refetch}
          client={user}
          close={onCloseSchedule}
          coach={coach}
        />
      )}
    </>
  );
};

export default HomeCard;
