import { useContext, useEffect, useState } from 'react';

import { notifySlack, updateUserProfile } from '@/api';
import { ONE, TWO } from '@/constants';
import { useCurrentUser } from '@/hooks';
import { Typography } from '@/ui';
import { track } from '@/utils';

import OptionCard from '@/components/GetStarted/Matching/OptionsCard';
import { GetStartedContext } from '@/components/GetStarted/context';
import { ZapTypes } from '@/types/zapier';

const GetCoach = () => {
  const { user, refetchUser } = useCurrentUser();
  const { nextStep } = useContext(GetStartedContext);

  const [saving, setSaving] = useState(false);

  useEffect(() => {
    track('ob_match');
  }, []);

  const onNext = async (option: string) => {
    try {
      setSaving(true);
      await updateUserProfile(user.id, { matchPreference: option });
      await refetchUser();

      track('ob_match_cont');

      const dataUrl = `/silent/?email=${user.email}&matching-preference=${option}`;
      await notifySlack(ZapTypes.USER_MATCHING_PREFERENCE, dataUrl);

      nextStep(option === 'email' ? TWO : ONE);
    } catch (e) {
      console.error(e);
    } finally {
      setSaving(false);
    }
  };

  const onEmail = async () => {
    await onNext('email');
    track('ob_match_email');
  };
  const onCall = async () => {
    await onNext('call');
    track('ob_match_call');
  };

  return (
    <main className="bg-gradient-dark-sunny flex flex-1 flex-col overflow-auto">
      <div className="flex flex-col">
        <div className="container mx-auto p-4 text-center md:mt-4">
          <Typography className="mb-5" variant="h4">
            Welcome, let&apos;s get you a coach!
          </Typography>
          <Typography className="mx-auto mb-8 max-w-140" variant="body1">
            We put a lot of care into this process and hand-match you with the
            perfect coach using the information you provided.
          </Typography>
          <Typography className="mx-auto mb-6 max-w-100" variant="subtitle1">
            Choose how you want to get matched:
          </Typography>
        </div>
        <div className="container mx-auto justify-center space-y-4 px-4 md:flex md:gap-4 md:space-y-0">
          <OptionCard
            buttonText="Schedule"
            icon="videocam"
            subtitle="We'll get to know you personally and review coach options."
            title="Matching call"
            recommended
            onClick={onCall}
          />
          <OptionCard
            buttonText="Email me"
            disabled={saving}
            icon="mail"
            subtitle="We will email you coach options in 2-3 days."
            title="Over email"
            onClick={onEmail}
          />
        </div>
      </div>
    </main>
  );
};

export default GetCoach;
