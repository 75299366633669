import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { duplicateDocument } from '@/api';
import { QUERY_KEY_DOCUMENTS, QUERY_KEY_TEMPLATES } from '@/constants';
import {
  useDeleteDocument,
  useDocument,
  usePatchDocument,
  useView,
} from '@/hooks';
import { DocumentActions } from '@/types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@/ui';
import { toDocumentUrl } from '@/utils';

import Delete from './Delete';
import Duplicate from './Duplicate';
import Rename from './Rename';

import type { Document } from '@/types';
import type { SelectChangeEvent } from '@/ui';
import type { FC, MouseEvent } from 'react';

import ShareInput from '@/components/Document/Share/Input';

interface ModalsProps {
  action: DocumentActions;
  documentId?: string;
  isTemplate?: boolean;
  setAction: (value: DocumentActions) => void;
}

const Modals: FC<ModalsProps> = ({
  action,
  isTemplate = false,
  documentId,
  setAction,
}) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { clientId } = useView();
  const { currentDocument, refetchDocument } = useDocument(
    isTemplate,
    documentId,
  );
  const deleteDocument = useDeleteDocument();
  const patchDocument = usePatchDocument();
  const originalName = currentDocument.name;
  const [name, setName] = useState(currentDocument.name);
  const [draft, setDraft] = useState(!!currentDocument.draft);
  const [newSharedWith, setNewSharedWith] = useState<string[]>([]);

  const processing = deleteDocument.isLoading || patchDocument.isLoading;

  const onClose = () => setAction(DocumentActions.None);

  const halt = (event: MouseEvent<HTMLElement>) => event.stopPropagation();
  const showFooter = action !== DocumentActions.Duplicate;
  const isOpen =
    action !== DocumentActions.None && action !== DocumentActions.Download;

  const invalidate = () => {
    queryClient.invalidateQueries([QUERY_KEY_DOCUMENTS]);
  };
  const invalidateTemplates = () => {
    queryClient.invalidateQueries([QUERY_KEY_TEMPLATES]);
  };

  const redirect = (id?: string) => {
    let path = '';

    if (clientId) path = `/coach/members/${clientId}/documents`;
    else path = '/documents/templates';

    if (id) navigate(`${path}/${id}`, { replace: true });
    else navigate(path);
  };

  const handleShareChange = ({
    target: { value },
  }: SelectChangeEvent<unknown>) => {
    setNewSharedWith(value as string[]);
  };

  const onClick = () => {
    try {
      switch (action) {
        case DocumentActions.Rename:
          patchDocument.mutate(
            { draft, id: currentDocument.id, name },
            {
              onSuccess: () => {
                refetchDocument();
                invalidate();
              },
            },
          );
          break;
        case DocumentActions.Delete:
          deleteDocument.mutate(
            { id: currentDocument.id },
            {
              onSuccess: () => {
                queryClient.invalidateQueries(['selectedUser']);
                queryClient.invalidateQueries(['current-user-client']);
                queryClient.invalidateQueries(['current-user-coach']);
                invalidate();
              },
            },
          );
          if (isTemplate) redirect();
          break;
        case DocumentActions.ShareSettings:
          patchDocument.mutate(
            { id: currentDocument.id, sharedWith: newSharedWith },
            {
              onSuccess: () => {
                refetchDocument();
                invalidate();
              },
            },
          );
          break;
        case DocumentActions.Share:
          navigator.clipboard.writeText(
            toDocumentUrl(currentDocument.mimeType, currentDocument.reference),
          );
          break;
        default:
          break;
      }
    } catch (e) {
      console.error(e);
    } finally {
      onClose();
    }
  };

  useEffect(() => {
    if (action === DocumentActions.Duplicate) {
      (async () => {
        const data: Document = await duplicateDocument({
          id: currentDocument.id,
          name: isTemplate ? `${name} (Copy)` : undefined,
          userId: clientId || undefined,
        });
        onClose();
        if (isTemplate) {
          await invalidateTemplates();
          redirect(data.id);
        } else {
          await invalidate();
          const url = toDocumentUrl(data.mimeType, data.reference);
          window.open(url, '_blank');
        }
      })();
    }
  }, [action]);

  useEffect(() => {
    setDraft(!!currentDocument.draft);
    setName(currentDocument.name);
  }, [currentDocument.id]);

  if (action === DocumentActions.Private) return null;

  return (
    <Dialog
      maxWidth={action === DocumentActions.Share ? 'xs' : 'sm'}
      open={isOpen}
      fullWidth
      onClick={halt}
      onClose={onClose}
    >
      <DialogTitle>
        <Typography variant="h4">
          {action} "{originalName}"
        </Typography>
      </DialogTitle>
      <DialogContent className="pt-1">
        {action === DocumentActions.Rename && (
          <Rename
            isDraft={!!draft}
            isTemplate={isTemplate}
            name={name}
            setIsDraft={setDraft}
            setName={setName}
          />
        )}
        {action === DocumentActions.Duplicate && <Duplicate />}
        {action === DocumentActions.Delete && <Delete />}
        {action === DocumentActions.ShareSettings && (
          <ShareInput
            sharedWith={currentDocument.sharedDocuments}
            onChange={handleShareChange}
          />
        )}
      </DialogContent>
      {showFooter && (
        <DialogActions>
          <Button disabled={processing} variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button
            className="ml-3"
            disabled={processing}
            variant="contained"
            onClick={onClick}
          >
            {action === DocumentActions.Share && 'Copy URL to clipboard'}
            {action === DocumentActions.ShareSettings && 'Save'}
            {action !== DocumentActions.Share &&
              action !== DocumentActions.ShareSettings &&
              action}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default Modals;
