import { formatDistanceToNow } from 'date-fns';

import { NUM_RECENTLY_UPDATED_DOCUMENTS_TO_SHOW, ZERO } from '@/constants';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@/ui';
import { getIconSrcFromMimeType, toDocumentUrl } from '@/utils';

import type { DocMimeType, Document } from '@/types';
import type { FC } from 'react';

type RecentlyUpdatedDocumentsProps = {
  documents: Document[];
};

const RecentlyUpdatedDocuments: FC<RecentlyUpdatedDocumentsProps> = ({
  documents,
}) => {
  const onViewDocuments = (document: Document) => () => {
    const url = toDocumentUrl(document.mimeType, document.reference);
    window.open(url, '_blank');
  };

  return (
    <>
      {/* @ts-ignore https://github.com/mui/material-ui/issues/29875 */}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right">Updated</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {documents
              .slice(ZERO, NUM_RECENTLY_UPDATED_DOCUMENTS_TO_SHOW)
              .map((row) => (
                <TableRow
                  key={row.id}
                  className="cursor-pointer hover:bg-gray-100"
                  onClick={onViewDocuments(row)}
                >
                  <TableCell component="th" scope="row">
                    <img
                      alt={document.title}
                      className="mr-2 align-middle"
                      height={20}
                      src={getIconSrcFromMimeType(row.mimeType as DocMimeType)}
                      width={20}
                    />
                    {row.name}
                  </TableCell>
                  <TableCell align="right">
                    {formatDistanceToNow(new Date(row.updatedAt), {
                      addSuffix: true,
                    })}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default RecentlyUpdatedDocuments;
