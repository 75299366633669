import clsx from 'clsx';
import { useEffect, useState } from 'react';

import { useSessions } from '@/hooks';
import { SessionStatus } from '@/types';
import { Icon, Typography } from '@/ui';
import { toDateTimeString } from '@/utils';

import type { User } from '@/types';
import type { FC } from 'react';

interface MemberProps {
  coachId: string;
  member: User;
}

const MemberSession: FC<MemberProps> = ({ member, coachId }) => {
  const [title, setTitle] = useState('');
  const [icon, setIcon] = useState('');

  const { data, isLoading } = useSessions({
    coachId,
    limit: 1,
    statuses: [SessionStatus.ACTIVE, SessionStatus.RESCHEDULED],
    userId: member.id,
  });

  useEffect(() => {
    if (isLoading) return;

    let pendingTitle = 'No upcoming sessions';
    let pendingIcon = 'event_busy';

    const keys = Object.keys(data.items);
    // Data comes grouped by month, so we get first record, and first record of the month
    const firstSession = data.items[keys[0] ?? '']?.[0];

    if (firstSession) {
      const dateOfNextSession = toDateTimeString(
        new Date(firstSession.startedAt),
        member.profile?.timezone ?? 'America/Los_Angeles',
      );

      pendingTitle = `${dateOfNextSession} • ${data.total} upcoming`;
      pendingIcon = 'event_available';
    }

    setTitle(pendingTitle);
    setIcon(pendingIcon);
  }, [member.id, data, isLoading]);

  if (!title) {
    return null;
  }

  return (
    <Typography
      className={clsx('flex items-center justify-between gap-2', {
        'text-cement-400': !!data?.total,
        'text-poppy-600': !data?.total,
      })}
      variant="body2"
    >
      {!!icon && <Icon>icon</Icon>}
      {title}
    </Typography>
  );
};

export default MemberSession;
