import mentoApi from './mentoApi';

import type { Function } from '@/types';

export const createAssistantFunction = async (data: Partial<Function>) =>
  (await mentoApi.post('/function', data)).data;

export const updateAssistantFunction = async (data: Partial<Function>) => {
  const { id, ...rest } = data;
  return (await mentoApi.put(`/function/${id}`, { ...rest })).data;
};

export const getAssistantFunction = async (id: string) =>
  (await mentoApi.get(`/function/${id}`)).data;

export const deleteAssistantFunction = async (id: string) =>
  (await mentoApi.delete(`/function/${id}`)).data;
