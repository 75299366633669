import { useState } from 'react';

import { JOB_EXPERIENCE, PRONOUNS } from '@/constants';
import { useCurrentUser } from '@/hooks';
import { UploadType } from '@/types';
import {
  Button,
  CreatableSelect,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@/ui';

import ProfileCard from './Card';

import type { CoachProfile, User, UserProfile } from '@/types';
import type { ChangeEvent, FC } from 'react';

import UploadImage from '@/components/Upload';

interface CoachProfileProps {
  coach: User;
  coachProfile: CoachProfile;
  onChange: (updated: User) => void;
  onCoachProfileChange: (updated: CoachProfile) => void;
  onProfileChange: (updated: UserProfile) => void;
  profile: UserProfile;
}

const CoachProfileSection: FC<CoachProfileProps> = ({
  coach,
  coachProfile,
  onChange,
  onCoachProfileChange,
  onProfileChange,
  profile,
}) => {
  const { user: currentUser } = useCurrentUser();
  const [uploadingPhoto, setUploadingPhoto] = useState(false);

  const onUpdate = (changes: Partial<User>) =>
    onChange({ ...coach, ...changes });

  const onProfileUpdate = (changes: Partial<UserProfile>) =>
    onProfileChange({ ...profile, ...changes });

  const onCoachProfileUpdate = (changes: Partial<CoachProfile>) =>
    onCoachProfileChange({ ...coachProfile, ...changes });

  const onNameChange = (e: ChangeEvent<HTMLInputElement>) =>
    onUpdate({ fullName: e.target.value || '' });

  const onEmailChange = (e: ChangeEvent<HTMLInputElement>) =>
    onUpdate({ email: e.target.value.trim() || '' });

  const onPronounChange = (value: string) =>
    onProfileUpdate({ pronouns: value });

  const onCompanyChange = (e: ChangeEvent<HTMLInputElement>) =>
    onProfileUpdate({ company: e.target.value });

  const onFocusChange = (e: ChangeEvent<HTMLInputElement>) =>
    onCoachProfileUpdate({ focus: e.target.value || '' });

  const onRoleChange = (e: ChangeEvent<HTMLInputElement>) =>
    onProfileUpdate({ title: e.target.value || '' });

  const onExperienceChange = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => onProfileUpdate({ experience: e.target.value || '' });

  const onUpload = () => setUploadingPhoto(true);
  const onUploadClose = (url?: string) => {
    if (url) {
      onUpdate({ avatarUrl: url });
    }
    setUploadingPhoto(false);
  };

  return (
    <Grid spacing={4} container>
      <Grid md={5} xs={12} item>
        <Typography className="mb-2" variant="h4">
          Name & profile card
        </Typography>
        <Typography className="mb-1" variant="body1">
          Members will see this information and card when discovering Coaches
        </Typography>
        <div className="-mx-2 flex items-center justify-center md:block">
          <ProfileCard coach={coach} />
        </div>
      </Grid>
      <Grid className="mt-4 flex flex-col gap-3" md={7} xs={12} item>
        <TextField
          label="Name"
          value={coach.fullName}
          onChange={onNameChange}
        />
        {currentUser.superAdmin && (
          <TextField
            label="Email"
            value={coach.email}
            onChange={onEmailChange}
          />
        )}
        <CreatableSelect
          label="Pronouns"
          options={PRONOUNS}
          value={profile.pronouns ?? ''}
          onChange={onPronounChange}
        />
        <TextField
          label="Coaching description"
          value={coachProfile.focus}
          onChange={onFocusChange}
        />
        <TextField
          label="Current or past role"
          value={profile.title}
          onChange={onRoleChange}
        />
        <TextField
          label="Company"
          value={profile.company ?? ''}
          onChange={onCompanyChange}
        />
        <TextField
          label="Years of experience"
          placeholder="Years of experience"
          value={profile.experience}
          select
          onChange={onExperienceChange}
        >
          {JOB_EXPERIENCE.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
        <div className="mt-16">
          <Button variant="contained" onClick={onUpload}>
            Upload photo
          </Button>
        </div>
      </Grid>

      {uploadingPhoto ? (
        <UploadImage
          filename={coach.id}
          type={UploadType.PROFILE}
          onClose={onUploadClose}
        />
      ) : null}
    </Grid>
  );
};

export default CoachProfileSection;
