import { SessionFeedbackType } from '@/types';

import type { IDictionary, SessionFeedbackParams } from '@/types';

export const RELOAD_CAL_MODAL = 1500;

export const RECURRING_SESSION_COUNT = 1500;

export const SESSION_FEEDBACK_GRAPH_SIZE = 30;

export const SESSION_FEEDBACK_TYPES: IDictionary<SessionFeedbackParams> = {
  mixed: {
    background: 'bg-sunny-100 hover:bg-sunny-200',
    border: 'border-solid border-2 border-sunny-500',
    img: 'https://mento-space.nyc3.cdn.digitaloceanspaces.com/assets/okay-face.png',
    name: 'Mixed',
    statsGraphColor: 'bg-sunny-500',
    subtitle: 'How can we improve your experience?',
    title: 'Thank you for sharing.',
    type: SessionFeedbackType.MIXED,
  },
  negative: {
    background: 'bg-poppy-100 hover:bg-poppy-200',
    border: 'border-solid border-2 border-poppy-500',
    img: 'https://mento-space.nyc3.digitaloceanspaces.com/assets/icon-vote-negative.svg',
    name: 'Negative',
    statsGraphColor: 'bg-poppy-500',
    subtitle: 'How can we improve your experience?',
    title: 'We are sorry to hear that.',
    type: SessionFeedbackType.NEGATIVE,
  },
  positive: {
    background: 'bg-sage-100 hover:bg-sage-200',
    border: 'border-solid border-2 border-sage-500',
    img: 'https://mento-space.nyc3.digitaloceanspaces.com/assets/icon-vote-positive.svg',
    name: 'Positive',
    statsGraphColor: 'bg-[#88d38c]',
    subtitle: 'What is one way your coach had a positive impact on you today?',
    title: 'Awesome to hear!',
    type: SessionFeedbackType.POSITIVE,
  },
};

export enum SessionCancelChoice {
  PENDING = 'PENDING',
  RECURRING = 'RECURRING',
  RESCHEDULE = 'RESCHEDULE',
  RESCHEDULE_PROMPT = 'RESCHEDULE_PROMPT',
  SINGLE = 'SINGLE',
}

export enum SessionAction {
  CANCEL = 'cancel',
  GROWTH = 'growth',
  RESCHEDULE = 'reschedule',
  VIEW = 'view',
}
