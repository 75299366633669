import { SAUCES } from '@/constants';
import { Divider, Typography } from '@/ui';

import Row from './Row';

import type { FC } from 'react';

const SpecialSauce: FC = () => (
  <div className="mb-6 mt-12 flex w-full flex-col items-center md:mb-2">
    <Typography className="mt-4 text-center" variant="h3">
      Mento&apos;s special sauce
    </Typography>
    <div className="md:w-9/10 px-2 sm:w-full md:py-6 lg:w-3/4 lg:max-w-4xl">
      {SAUCES.map((sauce) => (
        <div key={`membership-sauce-${sauce.header}`}>
          <Row {...sauce} />
          <Divider className="border-sage-400" />
        </div>
      ))}
    </div>
  </div>
);

export default SpecialSauce;
