import { useNavigate } from 'react-router';

import { useCurrentUser } from '@/hooks';
import { Avatar, Chip, Icon } from '@/ui';

import type { User } from '@/types';
import type { MouseEvent } from 'react';

const CoachChip = ({ coach }: { coach: User }) => {
  const { user: currentUser } = useCurrentUser();
  const navigate = useNavigate();

  const onClick = (event: MouseEvent) => {
    event.stopPropagation();
    navigate(`/coach/manage/coaches/${coach.id}`);
  };
  return (
    <Chip
      avatar={
        <Avatar
          firstName={coach.firstName}
          lastName={coach.lastName}
          src={coach.avatarUrl ?? ''}
        />
      }
      deleteIcon={<Icon>chevron_right</Icon>}
      label={coach.firstName}
      onClick={currentUser.superAdmin ? onClick : undefined}
      onDelete={currentUser.superAdmin ? onClick : undefined}
    />
  );
};

export default CoachChip;
