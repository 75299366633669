import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import { Button, Typography } from '@/ui';

import type { User } from '@/types';
import type { FC } from 'react';

interface RemoveMemberDialogProps {
  onConfirm: (isRemoving: boolean) => void;
  pendingMember: User;
}

const RemoveDialog: FC<RemoveMemberDialogProps> = ({
  pendingMember,
  onConfirm,
}) => {
  const onCancel = () => onConfirm(false);
  const onSave = () => onConfirm(true);

  return (
    <Dialog maxWidth="sm" fullWidth open>
      <DialogTitle>Confirm</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          Are you sure you want to remove {pendingMember.fullName}?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button color="primary" variant="contained" onClick={onSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveDialog;
