import { useFlagBag } from '@happykit/flags/context';
import clsx from 'clsx';

import { ZERO } from '@/constants';
import { BoulderColor, type FeatureFlags, type Milestone } from '@/types';
import { Icon, Typography } from '@/ui';
import { getBoulderColor } from '@/utils';

import type { FC } from 'react';

interface GrowthPlanCardDescriptionProps {
  milestone: Milestone;
}
const GrowthPlanCardDescription: FC<GrowthPlanCardDescriptionProps> = ({
  milestone,
}) => {
  // TODO: Need to fix whatever underlying issue is causing tests to break when constants are not being used in this file
  ZERO; // eslint-disable-line @typescript-eslint/no-unused-expressions -- Needed for tests to not break, probably a circular dependency issue
  const { flags } = useFlagBag<FeatureFlags>();

  const color = getBoulderColor(milestone.id);
  const textColor = clsx({
    'text-bloom-500': color === BoulderColor.BLOOM,
    'text-ocean-500': color === BoulderColor.OCEAN,
    'text-poppy-500': color === BoulderColor.POPPY,
    'text-sage-500': color === BoulderColor.SAGE,
    'text-sky-500': color === BoulderColor.SKY,
    'text-sunny-500': color === BoulderColor.SUNNY,
  });
  const noSteps = !milestone.totalSteps;

  if (noSteps && flags?.growth_suggestions)
    return (
      <Typography
        className={`mb-0.5 ${textColor} flex items-center`}
        variant="caption"
      >
        <Icon className="mr-1">magic_button</Icon>3 suggested steps
      </Typography>
    );

  if (noSteps)
    return (
      <Typography className={`mb-0.5 ${textColor}`} variant="caption">
        No steps added
      </Typography>
    );

  if (milestone.completeSteps === milestone.totalSteps)
    return (
      <Typography className={`mb-0.5 ${textColor}`} variant="caption">
        All steps completed
      </Typography>
    );

  if (milestone.completeSteps < milestone.totalSteps)
    return (
      <Typography className={`mb-0.5 ${textColor}`} variant="caption">
        {milestone.completeSteps} of {milestone.totalSteps} steps complete
      </Typography>
    );

  return null;
};
export default GrowthPlanCardDescription;
