import { useNavigate } from 'react-router';

import { oldCoachToNew } from '@/utils';

import useSharedCardActions from './useSharedCardActions';

import type { GeneratorFunction } from './types';
import type { Coach } from '@/types';

import { useCurrentUser } from '@/hooks/useCurrentUser';

const useMeetCoachActions = (): GeneratorFunction => {
  const navigate = useNavigate();
  const { handleClick } = useSharedCardActions();
  const { user } = useCurrentUser();

  return ({ card, onOpenSchedule }) => {
    const label = 'Profile';
    const label2 = 'Schedule';

    const coach = card.coach
      ? oldCoachToNew(card.coach as Coach, user.coaches)
      : undefined;
    return [
      {
        label,
        onClick: () => {
          handleClick({ card, label, shouldUpdate: false });
          navigate(`/m/profiles-coach/${coach?.coachId}`);
        },
      },
      {
        buttonProps: { color: 'secondary' },
        label: label2,
        onClick: () => {
          handleClick({
            card,
            label: label2,
            shouldUpdate: false,
            type: 'secondary',
          });
          onOpenSchedule?.();
        },
      },
    ];
  };
};

export default useMeetCoachActions;
