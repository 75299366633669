import { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { JOB_TITLES, TWO, ZERO } from '@/constants';
import { useCurrentUser } from '@/hooks';
import { Button, CreatableSelect, Typography } from '@/ui';
import { track } from '@/utils';

import type { UserAssesment } from '@/types';
import type { ControllerRenderProps } from 'react-hook-form';

import { updateUserAssesment } from '@/api/userAssesment';
import { GetStartedContext } from '@/components/GetStarted/context';

const DreamJob = () => {
  const { user, refetchUser } = useCurrentUser();
  const { nextStep } = useContext(GetStartedContext);

  const {
    handleSubmit,
    control,
    formState: { errors } = {},
    reset,
  } = useForm<Partial<UserAssesment>>({
    defaultValues: {
      dreamJobs: [],
    },
    mode: 'onChange',
  });
  const [saving, setSaving] = useState(false);
  const [isValidField, setIsValidField] = useState(
    (user.userAssesment?.dreamJobs.length || ZERO) > ZERO,
  );

  useEffect(() => {
    track('ob_dreamjob');
  }, []);

  useEffect(() => {
    reset({
      dreamJobs: user.userAssesment?.dreamJobs ?? [],
    });
    setIsValidField(
      ((user.userAssesment?.dreamJobs ?? []).length || ZERO) > ZERO,
    );
  }, [user]);

  const onTitleChange =
    (field: ControllerRenderProps<Partial<UserAssesment>, 'dreamJobs'>) =>
    (value: string[]) => {
      let updated = value;
      if (updated.length > TWO) {
        updated = updated.slice(ZERO, TWO);
      }

      field.onChange(updated);
      setIsValidField(!!updated.length);
    };

  const onNext = async (data: Partial<UserAssesment>) => {
    try {
      setSaving(true);
      track('ob_dreamjob_cont');

      await updateUserAssesment(user.id, {
        dreamJobs: data.dreamJobs ?? [],
      });
      await refetchUser();

      nextStep();
    } catch (e) {
      console.error(e);
    } finally {
      setSaving(false);
    }
  };

  return (
    <main className="bg-gradient-dark-sunny flex flex-1 flex-col overflow-auto">
      <div className="flex flex-col pb-20 md:pb-0">
        <div className="container mx-auto p-4 text-center md:mt-4">
          <Typography className="mb-5" variant="h4">
            What is your&nbsp;
            <span className="bg-gradient-to-br from-poppy-500 to-bloom-500 bg-clip-text text-transparent">
              dream job!
            </span>
          </Typography>
          <Typography className="mx-auto mb-8 max-w-100" variant="body1">
            Please select up to 2 options
          </Typography>
          <form
            className="mx-auto flex max-w-screen-md items-center justify-center"
            id="dream-job"
            onSubmit={handleSubmit(onNext)}
          >
            <div className="flex w-full max-w-118 flex-col gap-6">
              <Controller
                control={control}
                name="dreamJobs"
                render={({ field }) => (
                  <CreatableSelect
                    helperText={errors?.dreamJobs && 'Dream job is required'}
                    label="Dream job"
                    options={JOB_TITLES}
                    value={field.value ?? []}
                    multiple
                    onChange={onTitleChange(field)}
                  />
                )}
                rules={{ required: true }}
              />
            </div>
          </form>
        </div>
      </div>
      <div className="fixed bottom-0 mt-auto w-full px-6 pb-4 text-center text-sm md:static md:mb-6 md:mt-0 md:py-6">
        <Button
          className="w-full min-w-72 md:w-72"
          color="primary"
          disabled={!isValidField}
          form="dream-job"
          loading={saving}
          type="submit"
          variant="contained"
        >
          Continue
        </Button>
      </div>
    </main>
  );
};

export default DreamJob;
