import { Card, CardContent, Skeleton } from '@/ui';

const InformationLoading = () => (
  <Card>
    <CardContent>
      <Skeleton className="mb-2" width="38%" />
      <div className="mb-2 flex align-middle">
        <Skeleton
          className="mr-3 shrink-0"
          height={16}
          variant="circular"
          width={16}
        />
        <Skeleton height={16} width="42%" />
      </div>
      <div className="mb-2 flex align-middle">
        <Skeleton
          className="mr-3 shrink-0"
          height={16}
          variant="circular"
          width={16}
        />
        <Skeleton height={16} width="44%" />
      </div>
      <div className="mb-2 flex align-middle">
        <Skeleton
          className="mr-3 shrink-0"
          height={16}
          variant="circular"
          width={16}
        />
        <Skeleton height={16} width="32%" />
      </div>
      <div className="mb-2 flex align-middle">
        <Skeleton
          className="mr-3 shrink-0"
          height={16}
          variant="circular"
          width={16}
        />
        <Skeleton height={16} width="48%" />
      </div>
      <div className="mb-2 flex align-middle">
        <Skeleton
          className="mr-3 shrink-0"
          height={16}
          variant="circular"
          width={16}
        />
        <Skeleton height={16} width="62%" />
      </div>
    </CardContent>
  </Card>
);

export default InformationLoading;
