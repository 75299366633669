import { Typography } from '@/ui';

import type { UserProfile } from '@/types';
import type { FC } from 'react';

const Location: FC<UserProfile> = ({ location }) => (
  <Typography className="text-cement-400" variant="caption">
    {!!location && location}
  </Typography>
);

export default Location;
