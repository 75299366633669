import { Typography } from '@/ui';

import type { FC } from 'react';

interface RowProps {
  job: string;
  name: string;
  quote: string;
  src: string;
}

const Row: FC<RowProps> = ({ job, name, quote, src }) => (
  <div className="z-10 mb-2 flex w-full items-center justify-center rounded-lg border bg-white px-6 pb-6 text-black shadow-md lg:w-86">
    <div className="mt-6 flex flex-col">
      <div className="flex justify-between">
        <div className="flex">
          <img alt={name} className="h-12 w-12 shrink-0" src={src} />
          <div className="ml-2">
            <Typography variant="subtitle1">{name}</Typography>
            <Typography variant="body2">{job}</Typography>
          </div>
        </div>

        <img
          alt={job}
          className="h-6 w-6 shrink-0"
          src="https://mento-space.nyc3.digitaloceanspaces.com/assets/ic_linkedin.svg"
        />
      </div>

      <Typography className="mt-4" variant="body2">
        {quote}
      </Typography>
    </div>
  </div>
);

export default Row;
