import clsx from 'clsx';
import { useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { logout } from '@/api';
import { MINDSET_IMAGE_URL } from '@/constants';
import { useCurrentUser, useLayout, useView } from '@/hooks';
import { BottomBar, SideBar } from '@/ui';
import { clearCache } from '@/utils';

import LoadingCover from './Loading';

import EditClientAccount from '@/components/Profile/Client/Edit/Account';
import EditCoachAccount from '@/components/Profile/Coach/Edit/Account';
import Feedback from '@/components/Profile/Feedback';

// TODO: Refactor into actual index instead of god layout
// TODO: Separate from components or move components related elsewhere
const Layout = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { user, isUserLoading } = useCurrentUser();
  const { isClient } = useView();
  const { menu, homePath, bottomMenu, moreBottomMenu } = useLayout();
  const [isEditingDetails, setIsEditingDetails] = useState(false);
  const [isFeedback, setIsFeedback] = useState(false);

  const isOnHomePath = pathname === homePath || pathname === `${homePath}/`;

  // check if the route doesn't need the sidebar
  const hiddenPaths = [/workflow.*/iu];
  const hideSideBar = hiddenPaths.some((value) => pathname.match(value));
  const containerClassnames =
    'mb-16 flex flex-1 flex-col overflow-x-hidden sm:mb-0';

  const onSignOut = async () => {
    await logout();
    clearCache();
    navigate('/signin');
  };

  const onOpenFeedBack = () => setIsFeedback(true);
  const onCloseFeedBack = () => setIsFeedback(false);
  const onEditAccountSettings = () => setIsEditingDetails(true);
  const onCloseAccountSettings = () => setIsEditingDetails(false);

  if (isUserLoading) {
    return <LoadingCover />;
  }

  let backgroundImage;

  if (isOnHomePath) {
    backgroundImage = {
      backgroundImage: `url("${MINDSET_IMAGE_URL}${user.mindset}.jpg")`,
    };
  }

  return (
    <div
      className={clsx('absolute inset-0 flex overflow-auto', {
        'bg-sage-100': !isOnHomePath && !isClient,
        'bg-sage-600 bg-center bg-no-repeat bg-cover ': isOnHomePath,
        'bg-sunny-100': !isOnHomePath && isClient,
      })}
      style={backgroundImage}
    >
      <SideBar
        hideSideBar={hideSideBar}
        isClient={isClient}
        isOnHomepage={isOnHomePath}
        menu={menu}
        user={user}
        onAccountSettings={onEditAccountSettings}
        onFeedBack={onOpenFeedBack}
        onSignOut={onSignOut}
      />
      <main
        className={`${containerClassnames} ${hideSideBar ? '' : 'sm:ml-18'}`}
      >
        <Outlet />
      </main>
      <BottomBar
        menu={bottomMenu}
        moreMenu={moreBottomMenu}
        user={user}
        onAccountSettings={onEditAccountSettings}
        onFeedBack={onOpenFeedBack}
        onSignOut={onSignOut}
      />
      {isOnHomePath && (
        <div className="absolute inset-0 h-2/3 bg-gradient-to-b from-black/60 to-transparent" />
      )}

      {isEditingDetails && isClient && (
        <EditClientAccount client={user} onClose={onCloseAccountSettings} />
      )}
      {isEditingDetails && !isClient && (
        <EditCoachAccount coach={user} onClose={onCloseAccountSettings} />
      )}
      {isFeedback && <Feedback onClose={onCloseFeedBack} />}
    </div>
  );
};

export default Layout;
