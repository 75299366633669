import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@/ui';

import type { Team, TeamMember } from '@/types';
import type { FC } from 'react';

interface ConfirmRemoveTeamMemberProps {
  member: TeamMember;
  onClose: () => void;
  onRemove: () => void;
  saving: boolean;
  team: Team;
}
const ConfirmRemoveTeamMember: FC<ConfirmRemoveTeamMemberProps> = ({
  member,
  team,
  onRemove,
  onClose,
  saving,
}) => (
  <Dialog open onClose={onClose}>
    <DialogTitle>Please confirm</DialogTitle>
    <DialogContent>
      <Typography>
        Are you sure you want to remove{' '}
        <Typography component="span" fontWeight="bold">
          {member.user.fullName}
        </Typography>{' '}
        from{' '}
        <Typography component="span" fontWeight="bold">
          {team.name}
        </Typography>
        ?
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button disabled={saving} variant="outlined" onClick={onClose}>
        Cancel
      </Button>
      <Button
        color="warning"
        loading={saving}
        variant="contained"
        onClick={onRemove}
      >
        Remove
      </Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmRemoveTeamMember;
