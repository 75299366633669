import { useParams } from 'react-router-dom';

import {
  useGetGrowthPlan,
  useGetGrowthPlans,
  useSelectedUserId,
} from '@/hooks';

import type { GrowthPlan } from '@/types';

export const useGrowthPlans = () => {
  const userId = useSelectedUserId();
  const params = userId ? { userId } : {};
  const { data, isLoading, refetch } = useGetGrowthPlans(params);

  return {
    isPlansLoading: isLoading,
    plans: data,
    refetch,
  };
};

export const useGrowthPlan = () => {
  const { growthPlanId: id = '' } = useParams();
  const userId = useSelectedUserId();
  const {
    data: plan,
    isLoading: isPlanLoading,
    refetch: refetchPlan,
  } = useGetGrowthPlan({ id }, { enabled: !!id });
  const params = userId ? { userId } : {};
  const {
    data: plans,
    isLoading: isPlansLoading,
    refetch: refetchPlans,
  } = useGetGrowthPlans(params, { enabled: !id });

  return {
    isPlanLoading: id ? isPlanLoading : isPlansLoading,
    plan: id ? (plan as GrowthPlan) : (plans?.[0] as GrowthPlan),
    refetch: id ? refetchPlan : refetchPlans,
  };
};
