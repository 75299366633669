import { useCurrentUser } from '@/hooks';
import { Divider, Icon, ListItemIcon, ListItemText, MenuItem } from '@/ui';

import type { Session } from '@/types';
import type { FC } from 'react';

interface UpcomingMenuItemsProps {
  handleOnClose: (e: Event | React.SyntheticEvent) => void;
  onCancel?: (val: Session) => void;
  onMessage?: (e: React.MouseEvent<HTMLElement>) => void;
  onReschedule?: (val: Session) => void;
  onViewProfile?: (e: React.MouseEvent<HTMLElement>) => void;
  session: Session;
}

const UpcomingMenuItems: FC<UpcomingMenuItemsProps> = ({
  session,
  handleOnClose,
  onViewProfile = undefined,
  onMessage = undefined,
  onReschedule = undefined,
  onCancel = undefined,
}) => {
  const { user } = useCurrentUser();
  const concerningUser =
    session.coachId === user.id ? session.user : session.coach;
  return (
    <div>
      {onReschedule ? (
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            handleOnClose(e);
            onReschedule(session);
          }}
        >
          <ListItemIcon>
            <Icon>edit</Icon>
          </ListItemIcon>
          <ListItemText>Reschedule</ListItemText>
        </MenuItem>
      ) : null}
      {onCancel ? (
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            handleOnClose(e);
            onCancel(session);
          }}
        >
          <ListItemIcon>
            <Icon>trash</Icon>
          </ListItemIcon>
          <ListItemText>Cancel</ListItemText>
        </MenuItem>
      ) : null}
      {(!!onViewProfile || !!onMessage) && <Divider className="mx-3" />}
      {!!onViewProfile && (
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            handleOnClose(e);
            onViewProfile(e);
          }}
        >
          <ListItemIcon>
            <Icon>account_circle</Icon>
          </ListItemIcon>
          <ListItemText>View profile</ListItemText>
        </MenuItem>
      )}
      {!!onMessage && (
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            handleOnClose(e);
            onMessage(e);
          }}
        >
          <ListItemIcon>
            <Icon>chat_bubble</Icon>
          </ListItemIcon>
          <ListItemText>Message {concerningUser.fullName}</ListItemText>
        </MenuItem>
      )}
    </div>
  );
};

export default UpcomingMenuItems;
