import { useContext } from 'react';

import { AppContext } from '@/context';
import { oldCoachToNew } from '@/utils';

import useSharedCardActions from './useSharedCardActions';

import type { GeneratorFunction } from './types';
import type { Coach } from '@/types';

import { useCurrentUser } from '@/hooks/useCurrentUser';

const usePreviousSessionActions = (): GeneratorFunction => {
  const { handleClick } = useSharedCardActions();
  const { setChatId } = useContext(AppContext);
  const { user } = useCurrentUser();

  return ({ card, onOpenSchedule }) => {
    const label = 'Message';
    const label2 = 'Schedule';

    const coach = card.coach
      ? oldCoachToNew(card.coach as Coach, user.coaches)
      : undefined;
    return [
      {
        label,
        onClick: () => {
          handleClick({ card, label, shouldUpdate: false });
          setChatId(coach?.cometChatId ?? '');
        },
      },
      {
        label: label2,
        onClick: () => {
          handleClick({ card, label: label2, shouldUpdate: false });
          onOpenSchedule?.();
        },
      },
    ];
  };
};

export default usePreviousSessionActions;
