import { Icon, ListItemIcon, ListItemText, MenuItem } from '@/ui';

import type { Session } from '@/types';
import type { FC } from 'react';

interface NextMenuItemsProps {
  handleOnClose: (e: Event | React.SyntheticEvent) => void;
  onCancel?: (val: Session) => void;
  onReschedule?: (val: Session) => void;
  session: Session;
}

const NextMenuItems: FC<NextMenuItemsProps> = ({
  session,
  handleOnClose,
  onReschedule = undefined,
  onCancel = undefined,
}) => (
  <>
    {onReschedule ? (
      <MenuItem
        onClick={(e) => {
          handleOnClose(e);
          e.stopPropagation();
          onReschedule(session);
        }}
      >
        <ListItemIcon>
          <Icon>edit</Icon>
        </ListItemIcon>
        <ListItemText>Reschedule</ListItemText>
      </MenuItem>
    ) : null}
    {onCancel ? (
      <MenuItem
        onClick={(e) => {
          handleOnClose(e);
          e.stopPropagation();
          onCancel(session);
        }}
      >
        <ListItemIcon>
          <Icon>trash</Icon>
        </ListItemIcon>
        <ListItemText>Cancel</ListItemText>
      </MenuItem>
    ) : null}
  </>
);

export default NextMenuItems;
