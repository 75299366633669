import clsx from 'clsx';

import { useCurrentUser, useView } from '@/hooks';

import Cards from './Cards';
import Coachees from './Coachees';
import Coaches from './Coaches';
import HeroBanner from './HeroBanner';
import Mindset from './Mindset';
import OnboardingFlow from './OnboardingFlow';
import RequireUserInfo from './RequireUserInfo';
import Session from './Session';
import WelcomeMessage from './WelcomeMessage';
import Workflows from './Workflows';

const Home = () => {
  // Client layout is full screen, coach is not
  // If design can make these consistent, we can remove this
  const { isClient } = useView();
  const { user } = useCurrentUser();
  const isUserInfoIncomplete =
    isClient &&
    user.hasTeams &&
    (user.firstName === '' || user.firstName === user.email);

  return (
    <>
      <div className="z-10 flex flex-1 flex-col py-4 pl-4 md:py-6 md:pl-6">
        <div className="-ml-4 -mt-4 pb-8 pl-4 pt-4 md:-ml-6 md:-mt-6 md:pl-6 md:pt-6">
          <div className="flex justify-between">
            <div className="flex flex-row gap-2">
              <Mindset />
              <Coaches />
            </div>
          </div>
        </div>
        <div
          className={clsx(
            'container z-10 max-w-full flex-1 flex-col justify-center md:flex',
            {
              'mx-auto max-w-screen-lg pr-4': !isClient,
            },
          )}
        >
          <WelcomeMessage />
          <Workflows />
          {!!isClient && <Cards />}
          <HeroBanner />
          <div>
            <Session />
            <Coachees />
          </div>
        </div>
      </div>
      <OnboardingFlow />
      <RequireUserInfo isOpen={isUserInfoIncomplete} />
    </>
  );
};

export default Home;
