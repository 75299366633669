import { ONE, ZERO } from '@/constants';

export const clearCookies = () => {
  const cookies = document.cookie;
  cookies.split(';').forEach((cookie) => {
    const position = cookie.indexOf('=');
    const updated = position > -ONE ? cookie.substring(ZERO, position) : cookie;
    document.cookie = `${updated}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  });
};

export const setCookie = (
  name: string,
  value: string,
  expiration: Date | null,
) => {
  let cookieString = `${name}=${value}`;
  if (expiration) {
    cookieString = `${cookieString};expires=${expiration.toUTCString()}`;
  }
  document.cookie = cookieString;
};

export const clearCache = () => {
  localStorage.clear();
  sessionStorage.clear();

  clearCookies();
};
