import { FIRST_SLASH_AFTER_PROTOCOL } from '@/constants';

const urlSafeEmail = (email = '') =>
  // Not full url encode on email sine that causes weirdness but will change + to correct symbol
  email.replace(/\+/gu, '%2B');

export { urlSafeEmail };

export const buildPath = () =>
  `/${window.location.href
    .split('/')
    .slice(FIRST_SLASH_AFTER_PROTOCOL)
    .join('/')}`;

export const getPath = () => {
  let path = buildPath();

  if (
    path.includes('signin') ||
    path.includes('reset') ||
    path.includes('authenticate') ||
    path === '/' ||
    path === '/m' ||
    path === '/coach'
  )
    path = '';

  return path;
};

export const isValidLinkedin = (link: string) => {
  if (
    link.startsWith('https://www.linkedin.com/in/') ||
    link.startsWith('https://linkedin.com/in/')
  ) {
    return true;
  }

  return false;
};

function validateUrl(value: string): boolean {
  // there is no perfect url checker. The builtin URI is too loose, but we probably don't need a full library at this
  // point... this one should be good enough for our usecases for now.
  // from https://stackoverflow.com/questions/8667070/javascript-regular-expression-to-validate-url
  return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/iu.test(
    value,
  );
}

export const isValidWebsite = (
  website?: string,
  httpsOnly: boolean = false,
) => {
  let isValid = true;

  if (!website) {
    return false;
  }

  if (httpsOnly) {
    isValid = website.startsWith('https://');
  } else {
    isValid = website.startsWith('https://') || website.startsWith('http://');
  }

  return isValid && validateUrl(website);
};

export const generateParamsFromObject = (
  params: Record<string, string | number | boolean | undefined | null>,
) => {
  const sanitizedParams = Object.keys(params).reduce((aggr, key) => {
    const value = params[key];

    // If string, just return string
    if (typeof value === 'string') {
      return { ...aggr, [key]: value };
    }
    // If number or boolean, cast value to string
    if (typeof value === 'number' || typeof value === 'boolean') {
      return { ...aggr, [key]: `${value}` };
    }

    return aggr;
  }, {});

  return new URLSearchParams(sanitizedParams).toString();
};
