import { ZERO } from '@/constants';

import EmptyGoalsCard from '../EmptyGoalsCard';
import FilledGoalsCard from '../FilledGoalsCard';

import type { Team } from '@/types/teams';
import type { FC } from 'react';

interface GoalsCardProps {
  openTeamGoals: () => void;
  team: Team;
}
const GoalsCard: FC<GoalsCardProps> = ({ team, openTeamGoals }) => {
  if (team.goals.length === ZERO)
    return <EmptyGoalsCard openTeamGoals={openTeamGoals} />;

  return <FilledGoalsCard openTeamGoals={openTeamGoals} team={team} />;
};
export default GoalsCard;
