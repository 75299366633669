export const ZERO = 0;
export const ONE = 1;
export const TWO = 2;
export const THREE = 3;
export const FOUR = 4;
export const FIVE = 5;
export const SIX = 6;
export const SEVEN = 7;
export const EIGHT = 8;
export const NINE = 9;
export const TEN = 10;
export const EIGHTEEN = 18;
export const ONE_HUNDRED = 100;
export const THOUSAND = 1000;

export const PREVIOUS_PAGE = -1;
export const DEFAULT_PAGE_SIZE = 20;

export const MEDIUM_BREAKPOINT = '(min-width:900px)';
export const SMALL_BREAKPOINT = '(max-width:600px)';
