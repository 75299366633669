import { getTimeZones } from '@vvo/tzdb';

export const formatTimezone = (timezone: string | null) => {
  if (timezone) {
    const t = getTimeZones().find(
      (tz) => timezone === tz.name || tz.group.includes(timezone),
    );

    if (t) {
      const [time] = t.currentTimeFormat.split(' ');
      return `(GMT${time}) ${t.mainCities[0]}`;
    }
  }

  return '';
};
