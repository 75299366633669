import clsx from 'clsx';

import { Button, Icon, Typography } from '@/ui';

import type { User } from '@/types';
import type { FC } from 'react';

interface BioProps {
  user: User;
}

const Bio: FC<BioProps> = ({ user }) => {
  const onLinkedIn = () => window.open(user.profile?.linkedIn, '_blank');

  return (
    <section className="max-w-2xl space-y-4">
      <Typography variant="h5">Bio</Typography>
      <Typography className="whitespace-pre-wrap" variant="body1">
        {user.profile?.bio}
      </Typography>

      <div>
        {!!user.profile?.location && (
          <Typography variant="body1">
            <b>Location:</b> {user.profile.location}
          </Typography>
        )}
        {!!user.profile?.experience && (
          <Typography variant="body1">
            <b>Years of experience:</b> {user.profile.experience}
          </Typography>
        )}
      </div>
      <div
        className={clsx('flex gap-4', {
          'mt-4': user.profile?.linkedIn,
        })}
      >
        {!!user.profile?.linkedIn && (
          <Button
            className="flex gap-2"
            variant="outlined"
            onClick={onLinkedIn}
          >
            <Icon>linkedin</Icon>
            LinkedIn Profile
          </Button>
        )}
      </div>
    </section>
  );
};

export default Bio;
