import { Icon } from '@/ui';

import type { FC } from 'react';

// TODO: Fix this, it is a children importing a Parent
// if it is a variant, structure should be different
import Card from '@/components/Profile/Card';

interface LockCardProps {
  icon?: string;
  title: string;
}

const LockCard: FC<LockCardProps> = ({ title, icon = '' }) => (
  <Card icon={icon} title={title}>
    <Icon>lock</Icon>
  </Card>
);

export default LockCard;
