import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@/ui';

import type { Assistant } from '@/types';
import type { FC } from 'react';

interface Props {
  onClose: () => void;
  onDelete: () => void;
  toDelete: Assistant;
}

const DeleteModal: FC<Props> = ({ toDelete, onClose, onDelete }) => (
  <Dialog
    aria-describedby="modal-description"
    aria-labelledby="modal-title"
    maxWidth="md"
    fullWidth
    open
    onClose={onClose}
  >
    <DialogTitle>Are you sure?</DialogTitle>
    <DialogContent>
      Confirm you want to delete assitant: {toDelete.name}
    </DialogContent>
    <DialogActions>
      <Button color="secondary" variant="outlined" onClick={onClose}>
        Cancel
      </Button>
      <Button color="primary" variant="contained" onClick={onDelete}>
        Confirm
      </Button>
    </DialogActions>
  </Dialog>
);

export default DeleteModal;
