import { BottomNavigation } from '@mui/material';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import {
  Avatar,
  BottomBarMenuItem,
  Button,
  Icon,
  Menu,
  Typography,
} from '@/ui';

import type { User } from '@/types';
import type { BottomNavigationProps } from '@mui/material/BottomNavigation';
import type { FC, ReactNode } from 'react';

type Props = {
  menu: ReactNode;
  moreMenu: ReactNode;
  onAccountSettings: () => void;
  onFeedBack: () => void;
  onSignOut: () => void;
  user?: User;
} & BottomNavigationProps;

const BottomBar: FC<Props> = ({
  user = null,
  menu,
  moreMenu,
  onAccountSettings,
  onSignOut,
  onFeedBack,

  ...rest
}) => {
  const location = useLocation();
  const navRef = useRef<HTMLDivElement | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const isClient = !location.pathname.includes('coach');
  const path = isClient ? '/m' : '/coach';

  const onOpen = () => {
    setAnchorEl(navRef.current);
  };
  const onClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <BottomNavigation
        ref={navRef}
        className="fixed inset-x-0 bottom-0 flex h-16 w-full bg-sage-200 sm:hidden"
        {...rest}
      >
        {menu}
        <BottomNavigationAction
          icon={<Icon>more_horiz</Icon>}
          onClick={onOpen}
        />
      </BottomNavigation>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'top',
        }}
        id="bottom-more-menu"
        open={!!anchorEl}
        transformOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
        onClick={onClose}
        onClose={onClose}
      >
        <div className="-mx-4 flex w-screen flex-col items-center border-0 border-b border-solid border-cement-100 pb-4 pt-2">
          <Avatar
            firstName={user?.firstName ?? ''}
            lastName={user?.lastName ?? ''}
            size="l"
            src={user?.avatarUrl ?? ''}
          />
          <Button
            className="absolute right-4"
            color="inherit"
            variant="text"
            onClick={onSignOut}
          >
            Sign out
          </Button>
          <Typography variant="subtitle1">{user?.firstName}</Typography>
          <Typography variant="body2">{user?.email}</Typography>
          <Link className="mt-4" to="profile">
            <Button color="secondary" variant="contained" onClick={onClose}>
              View or edit profile
            </Button>
          </Link>
        </div>
        <div className="grid grid-cols-3 gap-4">
          {moreMenu}
          <BottomBarMenuItem
            icon="settings"
            label="Account"
            onClick={onAccountSettings}
          />
          <BottomBarMenuItem
            icon="feedback"
            label="Share feedback"
            onClick={onFeedBack}
          />
          <BottomBarMenuItem
            icon="assignment"
            label="Welcome guide"
            route={`${path}/welcome`}
          />
          {!isClient && (
            <BottomBarMenuItem
              icon="home_repair_service"
              label="Coach toolkit"
              route={`${path}/toolkit`}
            />
          )}
          {isClient && (
            <BottomBarMenuItem
              icon="support"
              label="FAQs"
              route={`${path}/support`}
            />
          )}
        </div>
      </Menu>
    </>
  );
};

export default BottomBar;
export type { Props };
