import MuiSwitch from '@mui/material/Switch';
import { alpha, styled } from '@mui/material/styles';
import { clsx } from 'clsx';
import { forwardRef } from 'react';

import type { Palette, PaletteColor } from '@mui/material';
import type { SwitchProps } from '@mui/material/Switch';

/* eslint-disable @typescript-eslint/no-magic-numbers -- CSS values make little sense as constants. */
const StyledSwitch = styled(MuiSwitch)<SwitchProps>(({ theme, color }) => ({
  '.MuiSwitch-switchBase:hover': {
    backgroundColor: alpha(
      color
        ? (theme.palette[color as keyof Palette] as PaletteColor).main
        : theme.palette.primary.main,
      0.4,
    ),
  },
}));
/* eslint-enable @typescript-eslint/no-magic-numbers */

const Switch = forwardRef<HTMLInputElement, SwitchProps>(
  ({ className, ...rest }, ref) => {
    const classes = clsx('mnt-switch', className);
    // @ts-ignore -- MUI types are going bananas over the ref here.
    return <StyledSwitch ref={ref} {...rest} className={classes} />;
  },
);
Switch.displayName = 'Switch';

export default Switch;
export type { SwitchProps };
