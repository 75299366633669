import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { getSurveys } from '@/api';
import { useCurrentUser, useView } from '@/hooks';

import type { Survey } from '@/types';

export const useSurvey = () => {
  const { templateId = '' } = useParams();
  const { isUserLoading } = useCurrentUser();
  const { selectedUser } = useView();

  const {
    data: surveys = [] as Survey[],
    isLoading: isSurveyLoading,
    refetch: refetchSurvey,
  } = useQuery(
    ['survey', templateId, selectedUser.sub],
    () => getSurveys(templateId, selectedUser.sub),
    {
      enabled: !isUserLoading && !!templateId,
    },
  );

  const survey = surveys[0] || ({} as Survey);

  return {
    isSurveyLoading,
    refetchSurvey,
    survey,
  };
};

export const useSurveys = () => {
  const { isUserLoading } = useCurrentUser();
  const { selectedUser } = useView();

  const { data, isLoading, refetch } = useQuery(
    ['surveys', selectedUser.sub],
    () => getSurveys(undefined, selectedUser.sub),
    {
      enabled: !isUserLoading,
    },
  );

  return {
    data,
    isLoading,
    refetch,
  };
};
