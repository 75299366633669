import { track } from '@/utils';

import mentoApi from './mentoApi';

import type { GrowthPlan } from '@/types';

export interface GrowthPlansApiOptions {
  teamId?: string;
  userId?: string;
}

export const getGrowthPlans = async (
  params?: GrowthPlansApiOptions,
): Promise<Awaited<GrowthPlan[]>> =>
  (await mentoApi.get('/growth_plans', { params })).data;

export interface GetGrowthPlanParams {
  id: string;
}
export const getGrowthPlan = async (
  params: GetGrowthPlanParams,
): Promise<Awaited<GrowthPlan>> =>
  (await mentoApi.get(`/growth_plans/${params.id}`)).data;

export const patchGrowthPlan = async ({
  id,
  ...growthPlan
}: Partial<GrowthPlan>): Promise<GrowthPlan> => {
  if (growthPlan.northStar) {
    track('Change North Star');
  }
  if (growthPlan.oneYearVision) {
    track('Change One Year Vision');
  }
  if (growthPlan.started) {
    track('Growth Plan Started');
  }
  return (await mentoApi.patch(`/growth_plans/${id}`, growthPlan)).data;
};
