import { Avatar, Card, CardContent, Typography } from '@/ui';
import { getTimeSinceCoaching } from '@/utils';

import type { User } from '@/types';
import type { CoachAssignment } from '@/types/coachAssignment';
import type { FC } from 'react';

interface CoachInfoProps {
  coaches: User[];
  relationships?: CoachAssignment[];
}

const CoachInfo: FC<CoachInfoProps> = ({
  relationships = [],
  coaches = [],
}) => {
  if (!coaches.length) return null;

  return (
    <Card>
      <CardContent>
        <Typography className="mb-2" variant="subtitle1">
          Coaches
        </Typography>

        {coaches.map((sc) => (
          <div key={sc.id} className="mb-2 flex w-full items-center">
            <Avatar
              firstName={sc.firstName}
              lastName={sc.lastName}
              src={sc.avatarUrl}
            />
            <div className="ml-4">
              <Typography className="break-all" variant="subtitle1">
                {sc.fullName}
              </Typography>
              <Typography
                className="break-all"
                color="text.secondary"
                variant="body2"
              >
                {getTimeSinceCoaching(sc, relationships)}
              </Typography>
            </div>
          </div>
        ))}
      </CardContent>
    </Card>
  );
};

export default CoachInfo;
