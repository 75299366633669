import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const MemberRedirect = () => {
  const { id = '' } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) navigate(`/coach/members/${id}`);
  }, [id]);

  return null;
};

export default MemberRedirect;
