import Actions from './Actions';

import type { ActionProps } from '..';
import type { FC } from 'react';

const DesktopActions: FC<ActionProps> = ({ coaches }) => (
  <div className="hidden flex-wrap gap-1 lg:flex">
    {coaches.map((coach) => (
      <Actions key={`home-coach-button-${coach.id}`} {...coach} />
    ))}
  </div>
);

export default DesktopActions;
