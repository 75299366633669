import { useEffect, useState } from 'react';

import { updateCoachProfile } from '@/api';
import {
  HERO_BANNER_CAMPAIGN,
  HERO_BANNER_LINK,
  MAX_DATE,
  SEEN_DISMISS_DAYS,
} from '@/constants';
import { useCurrentUser, useView } from '@/hooks';
import { Button, Icon, Typography } from '@/ui';
import { track } from '@/utils';

const HeroBanner = () => {
  const { isClient } = useView();
  const { user, refetchUser } = useCurrentUser();
  const [visible, setVisible] = useState(false);

  const onClick = () => {
    track('Clicked banner primary cta', {
      campaign: HERO_BANNER_CAMPAIGN,
    });
    window.open(HERO_BANNER_LINK, '_blank');
  };

  const dismiss = async () => {
    // Set to highest possible date so it won't show again
    await updateCoachProfile(user.id, {
      bannerDismissedUntil: new Date(MAX_DATE).toUTCString(),
    });
    setVisible(false);
    refetchUser();
  };

  useEffect(() => {
    const { bannerDismissedUntil } = user.coachProfile ?? {};

    if (bannerDismissedUntil) {
      const dismissedUntil = new Date(bannerDismissedUntil);

      // TS thinks you cannot compare Date to Date.now() even though coercion makes it work perfectly fine
      setVisible(dismissedUntil <= new Date(Date.now()));
    }
  }, [user]);

  useEffect(() => {
    if (visible) {
      const seenUntil = new Date();
      seenUntil.setDate(seenUntil.getDate() + SEEN_DISMISS_DAYS);

      // We do not do anything with the return value from this, so no need to await it
      updateCoachProfile(user.id, {
        bannerDismissedUntil: seenUntil.toUTCString(),
      });
    }
  }, [visible]);

  if (isClient || !visible) return null;

  return (
    <div className="relative flex w-full flex-col rounded-xl bg-bloom-600 text-white shadow-lg md:flex-row">
      <div className="p-6 md:w-1/3">
        <div className="py-6 lg:py-10">
          <Typography
            className="headline select-none pb-4 font-bold"
            variant="h4"
          >
            Supercharge your clients&apos; growth with Peer Insights
          </Typography>
          <Typography className="body select-none pb-4 md:pb-8">
            New! Coach better by seeing feedback from your clients&apos;
            coworkers.
          </Typography>
          <Button
            className="w-full md:mx-0 md:w-40"
            color="secondary"
            variant="contained"
            onClick={onClick}
          >
            Learn more
          </Button>
        </div>
      </div>
      <div className="hidden rounded-r-xl bg-hero bg-cover bg-no-repeat md:block md:w-2/3" />
      <Icon
        className="absolute right-5 top-5 ml-auto cursor-pointer self-start text-black"
        onClick={dismiss}
      >
        close
      </Icon>
    </div>
  );
};

export default HeroBanner;
