import clsx from 'clsx';

import { ENTER, ONE, RELATIONSHIP_OPTIONS } from '@/constants';
import { formatMonthAndYear } from '@/utils';

import type { SurveyResponse } from '@/types';
import type { FC, KeyboardEvent } from 'react';

interface ListProps {
  responses: SurveyResponse[];
  select: (index: number) => () => void;
  selected: number;
  show: boolean;
}

const List: FC<ListProps> = ({ responses, select, selected, show }) => {
  const onKeyDown = (index: number) => (event: KeyboardEvent) => {
    if (event.key === ENTER) {
      select(index)();
    }
  };

  return (
    <div className="mt-5 flex flex-col lg:w-72">
      {responses.map(({ id, name, relationship, respondedAt }, index) => (
        <div
          key={`responses-${id}`}
          className={clsx(
            'flex cursor-pointer flex-col border-b border-cement-300 bg-sage-100 p-4',
            { 'bg-sage-200 cursor-default': selected === index },
          )}
          role="button"
          tabIndex={0}
          onClick={select(index)}
          onKeyDown={onKeyDown(index)}
        >
          <div className="body text-cement-600">
            {show ? name : `Respondent ${index + ONE}`}
          </div>
          <div
            className={clsx('body-sm flex text-cement-400', {
              'text-cement-600': selected === index,
            })}
          >
            {show ? (
              <div className="capitalize">
                {
                  RELATIONSHIP_OPTIONS.find(
                    ({ value }) => value === relationship,
                  )?.label
                }
                &nbsp;&bull;&nbsp;
              </div>
            ) : null}
            {respondedAt
              ? formatMonthAndYear(respondedAt as Date)
              : 'No Response'}
          </div>
        </div>
      ))}
    </div>
  );
};

export default List;
