import { useState } from 'react';

import { Button, Icon, Menu, MenuItem } from '@/ui';

import type { Session } from '@/types';
import type { FC } from 'react';

interface MenuOptionsProps {
  loading?: boolean;
  onCancelSession: (session?: Session) => void;
  onReschedule: (session?: Session) => void;
}

const MenuOptions: FC<MenuOptionsProps> = ({
  loading = false,
  onReschedule,
  onCancelSession,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenuClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  if (loading) return null;

  return (
    <>
      <Button onClick={handleMenuClick}>
        <Icon>more_horiz</Icon>
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={() => onReschedule()}>
          <div className="flex items-center gap-2">
            <Icon>edit</Icon>
            Reschedule
          </div>
        </MenuItem>
        <MenuItem onClick={() => onCancelSession()}>
          <div className="flex items-center gap-2">
            <Icon>trash</Icon>
            Cancel
          </div>
        </MenuItem>
      </Menu>
    </>
  );
};

export default MenuOptions;
