import { Button } from '@/ui';

import type { HomeCardPropsWithoutCallbacks } from '@/types';
import type { FC } from 'react';

interface ChemistryCallContentProps {
  card: HomeCardPropsWithoutCallbacks;
}

const ChemistryCallContent: FC<ChemistryCallContentProps> = ({ card }) => {
  if (!card.session?.isChemistryCall) return null;
  return (
    <div className="-mb-2 flex gap-2 pt-4">
      {!!card.coach?.id && (
        <Button href={`/m/profiles-coach/${card.coach.id}`} variant="outlined">
          Profile
        </Button>
      )}
      <Button
        href="https://mentoteam.notion.site/Mento-Chemistry-Call-FAQ-02720c0978814c43bc2f0d2f1e77e21c"
        rel="noopener noreferrer"
        target="_blank"
        variant="outlined"
      >
        Learn more
      </Button>
    </div>
  );
};
export default ChemistryCallContent;
