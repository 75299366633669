import { ONE, ZERO } from '@/constants';
import { useGrowthPlan } from '@/hooks';
import { Avatar, AvatarGroup, Typography } from '@/ui';

import type { FC } from 'react';

const Authors: FC = () => {
  const { plan } = useGrowthPlan();
  const AUTHORS_TO_DISPLAY = 2;

  let avatars: JSX.Element;

  // Team
  if (plan.teamId) {
    avatars = (
      <AvatarGroup max={3} size="s">
        {plan.authors.map((author) => (
          <Avatar
            key={author.email}
            firstName={author.firstName}
            lastName={author.lastName}
            src={author.avatarUrl}
          />
        ))}
      </AvatarGroup>
    );
  }

  // Coach plan, coach not assigned
  if (plan.authors.length === ONE) {
    avatars = (
      <AvatarGroup max={3} size="s">
        <Avatar className="bg-cement-200" />
        <Avatar
          firstName={plan.user.firstName}
          lastName={plan.user.lastName}
          src={plan.user.avatarUrl}
        />
      </AvatarGroup>
    );
  } else {
    // Coach plan, coach assigned
    avatars = (
      <AvatarGroup max={3} size="s">
        {plan.authors.map((author) => (
          <Avatar
            key={author.email}
            firstName={author.firstName}
            lastName={author.lastName}
            src={author.avatarUrl}
          />
        ))}
      </AvatarGroup>
    );
  }

  let description = '';
  if (plan.authors.length === ONE) {
    description =
      'Once matched, use this plan with your coach to keep track of your progress. Feel free to get started on your own!';
  }

  if (plan.teamId) {
    if (plan.authors.length > AUTHORS_TO_DISPLAY) {
      description = `${plan.authors
        .slice(ZERO, AUTHORS_TO_DISPLAY)
        .map((author) =>
          author.id === plan.user.id ? 'You' : author.firstName,
        )
        .join(', ')} and ${plan.authors.length - AUTHORS_TO_DISPLAY} more`;
    }
  } else if (plan.authors.length > AUTHORS_TO_DISPLAY) {
    description = `${plan.authors
      .slice(ZERO, AUTHORS_TO_DISPLAY)
      .map((author) => (author.id === plan.user.id ? 'You' : author.firstName))
      .join(', ')} and ${plan.authors.length - AUTHORS_TO_DISPLAY} more`;
  } else if (plan.authors.length === AUTHORS_TO_DISPLAY) {
    description = `You and ${plan.authors
      .filter((author) => author.id !== plan.user.id)
      .map((author) => author.firstName)}`;
  }

  return (
    <div className="mb-6 flex justify-start align-middle">
      {avatars}
      <Typography className="ml-2" color="text.secondary" variant="body2">
        {description}
      </Typography>
    </div>
  );
};

export default Authors;
