import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import { getSession } from '@/api';
import { RELOAD_CAL_MODAL, SessionCancelChoice } from '@/constants';
import { useCurrentUser } from '@/hooks';

import CancelRecurringSession from './cancelRecurringSession';
import CancelSingleSession from './cancelSingleSession';
import ScheduleSession from './scheduleSession';

import type { Session } from '@/types';
import type { FC } from 'react';

import CancelPrompt from '@/components/Cal/cancelPrompt';
import ReschedulePrompt from '@/components/Cal/reschedulePrompt';

interface CancelSessionProps {
  close?: () => void;
  session: Session;
}

const CancelSession: FC<CancelSessionProps> = ({
  session: currentSession,
  close,
}) => {
  const [session, setSession] = useState<Session>();
  const [sessionCancelChoice, setSessionCancelChoice] =
    useState<SessionCancelChoice>(SessionCancelChoice.PENDING);
  const { user } = useCurrentUser();

  const { data } = useQuery(
    ['cancel-session', currentSession.id],
    () => getSession(currentSession.id),
    {
      enabled: !!currentSession.id,
    },
  );

  useEffect(() => {
    if (data) {
      setSession({
        ...data,
        startedAt: data.startedAt,
      });
      setSessionCancelChoice(
        data.recurringId
          ? SessionCancelChoice.PENDING
          : SessionCancelChoice.SINGLE,
      );
    }
  }, [data]);

  const onContinue = (choice: SessionCancelChoice) =>
    setSessionCancelChoice(choice);
  const onRecurringClose = () => {
    setSessionCancelChoice(SessionCancelChoice.RESCHEDULE_PROMPT);
  };
  const onRecurringContinue = () =>
    setSessionCancelChoice(SessionCancelChoice.RESCHEDULE);

  const onClose = () => {
    if (!close) {
      setTimeout(() => window.location.reload(), RELOAD_CAL_MODAL);
    } else {
      close();
    }
  };

  if (!session) {
    return null;
  }

  switch (sessionCancelChoice) {
    case SessionCancelChoice.PENDING:
      return (
        <CancelPrompt
          session={session}
          onClose={onClose}
          onContinue={onContinue}
        />
      );
    case SessionCancelChoice.RECURRING:
      return (
        <CancelRecurringSession close={onRecurringClose} session={session} />
      );
    case SessionCancelChoice.SINGLE:
      return <CancelSingleSession close={onClose} session={session} />;
    case SessionCancelChoice.RESCHEDULE_PROMPT:
      return (
        <ReschedulePrompt
          session={session}
          onClose={onClose}
          onContinue={onRecurringContinue}
        />
      );
    case SessionCancelChoice.RESCHEDULE:
      return (
        <ScheduleSession
          client={user}
          close={onClose}
          coach={session.coach}
          eventTypeSlug="bi-weekly-coaching-session"
        />
      );
    default:
      return null;
  }
};

export default CancelSession;
