import mentoApi from './mentoApi';

import type { Document } from '@/types';

export interface DocumentsApiOptions {
  limit?: number;
  page?: number;
  search?: string;
  sharedWith?: string[];
  sharedWithMe?: boolean;
  template?: boolean;
  userId?: string;
}

export const getDocuments = async (
  params?: DocumentsApiOptions,
): Promise<Awaited<{ items: Document[]; pages: number; total: number }>> =>
  (await mentoApi.get('/documents', { params })).data;

export const getDocument = async (params: {
  id: string;
}): Promise<Awaited<Document>> =>
  (await mentoApi.get(`/documents/${params.id}`)).data;

export const postDocument = async (
  document: Partial<Document> & { sharedWith?: string[]; template?: boolean },
): Promise<Document> => (await mentoApi.post('/documents', document)).data;

export const patchDocument = async ({
  id,
  sharedWith,
  ...document
}: Partial<Document> & {
  sharedWith?: string[];
}): Promise<Document> =>
  (
    await mentoApi.patch(`/documents/${id}`, {
      document: { id, ...document },
      sharedWith,
    })
  ).data;

export const deleteDocument = async (params: { id: string }): Promise<void> =>
  (await mentoApi.delete(`/documents/${params.id}`)).data;

export const duplicateDocument = async (params: {
  id: string;
  name?: string;
  userId?: string;
}): Promise<Awaited<Document>> =>
  (await mentoApi.post('/documents/duplicate', params)).data;

export const uploadDocument = async (params: {
  file: File;
  userId: string;
}): Promise<Document> => {
  const formData = new FormData();
  formData.append('file', params.file);
  formData.append('userId', params.userId);

  return (await mentoApi.post('/documents/upload', formData)).data;
};
