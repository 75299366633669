import clsx from 'clsx';

import { Card, CardContent, Icon, Typography } from '@/ui';

import type { FC, ReactNode } from 'react';

interface ProfileCardProps {
  children?: ReactNode;
  className?: string;
  icon?: string;
  onClick?: (e: unknown) => void;
  title: string;
}

const ProfileCard: FC<ProfileCardProps> = ({
  title,
  icon = '',
  children = undefined,
  onClick = undefined,
  className = '',
}) => (
  <Card
    className={clsx('w-full bg-sage-100', className, {
      'cursor-pointer': !!onClick,
    })}
    elevation={0}
  >
    <CardContent className="flex min-h-14 items-center justify-between px-3 py-2">
      <div className="flex gap-2">
        {!!icon && <Icon>{icon}</Icon>}
        <Typography variant="subtitle2">{title}</Typography>
      </div>
      <div className="text-sage-400">{children}</div>
    </CardContent>
  </Card>
);

export default ProfileCard;
