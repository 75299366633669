import { useFlagBag } from '@happykit/flags/context';
import { useNavigate } from 'react-router';

import useSharedCardActions from './useSharedCardActions';

import type { GeneratorFunction } from './types';
import type { FeatureFlags } from '@/types';

const useNextSessionActions = (): GeneratorFunction => {
  const { handleClick } = useSharedCardActions();
  const navigate = useNavigate();
  const { flags } = useFlagBag<FeatureFlags>();

  return ({ card }) => {
    if (card.isSessionToday && card.session?.location) {
      const isChemistryCall =
        card.session.slug === 'chemistry-call' && !!flags?.session_types;

      return [
        {
          buttonProps: isChemistryCall
            ? { color: 'primary', variant: 'contained' }
            : {
                color: 'info',
              },
          label: 'Join session',
          onClick: () => {
            handleClick({ card, label: 'Join session', shouldUpdate: false });
            window.open(card.session?.location as string);
          },
        },
      ];
    }

    const buttonLabel = card.isSessionToday ? 'View session' : 'Post a note';
    return [
      {
        buttonProps: { color: 'info' },
        label: buttonLabel,
        onClick: () => {
          handleClick({ card, label: buttonLabel, shouldUpdate: false });
          navigate(`/sessions/${card.session?.id}`);
        },
      },
    ];
  };
};

export default useNextSessionActions;
