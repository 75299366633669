import MuiLoadingButton from '@mui/lab/LoadingButton';
import { alpha, styled } from '@mui/material/styles';
import { clsx } from 'clsx';

import type { LoadingButtonProps } from '@mui/lab/LoadingButton';
import type { FC } from 'react';

declare module '@mui/material' {
  interface ButtonPropsColorOverrides {
    gray: true;
    white: true;
  }
}

interface ButtonProps extends LoadingButtonProps {
  light?: boolean;
  target?: '_blank' | '_self';
}

/* eslint-disable sort-keys, @typescript-eslint/no-magic-numbers -- CSS selectors shouldn't be alphabetically sorted. CSS values make little sense as constants. */
const StyledButton = styled(MuiLoadingButton)<ButtonProps>(({ theme }) => ({
  // Overall Styles
  fontWeight: 700,

  /**
   * Text General Styles
   */
  '&.MuiButton-text': {
    color: theme.palette.text.primary,
    '&.Mui-disabled': {
      color: theme.palette.text.disabled,
    },
    '&.MuiLoadingButton-loading': {
      color: 'transparent',
    },
  },

  /**
   * Outlined General Styles
   * -----------------------
   */
  '&.MuiButton-outlined': {
    borderColor: theme.extendedColors.cement[300],
    color: theme.palette.text.primary,
    ':hover': {
      borderColor: theme.extendedColors.cement[300],
    },
    '&.Mui-disabled': {
      borderColor: theme.palette.background.disabled,
      color: theme.palette.text.disabled,
    },
    '&.MuiLoadingButton-loading': {
      color: 'transparent',
    },
  },

  /**
   * Primary Button Styles
   * ---------------------
   * */

  // Text Primary
  '&.MuiButton-textPrimary': {
    '&.Mui-focusVisible': {
      backgroundColor: theme.palette.primary.light,
    },
  },

  // Outlined Primary
  '&.MuiButton-outlinedPrimary': {
    '&.Mui-focusVisible': {
      backgroundColor: alpha(theme.palette.primary.main, 0.75),
    },
  },

  // Contained Primary Light
  '&.MuiButton-containedPrimary.--light': {
    backgroundColor: theme.palette.primary.tonal,
    color: theme.palette.text.primary,
    ':hover': {
      backgroundColor: theme.palette.primary.light,
    },
    '&.Mui-focusVisible': {
      backgroundColor: theme.palette.primary.light,
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.background.disabled,
      color: theme.palette.text.disabled,
    },
    '&.MuiLoadingButton-loading': {
      color: 'transparent',
    },
  },

  /**
   * Secondary Button Styles
   * ---------------------
   * */

  // Text Secondary
  '&.MuiButton-textSecondary': {
    '&.Mui-focusVisible': {
      backgroundColor: theme.palette.secondary.light,
    },
  },

  // Outlined Secondary
  '&.MuiButton-outlinedSecondary': {
    '&.Mui-focusVisible': {
      backgroundColor: alpha(theme.palette.secondary.main, 0.75),
    },
  },

  // Contained Secondary Light
  '&.MuiButton-containedSecondary.--light': {
    backgroundColor: theme.palette.secondary.tonal,
    color: theme.palette.text.primary,
    ':hover': {
      backgroundColor: theme.palette.secondary.light,
    },
    '&.Mui-focusVisible': {
      backgroundColor: theme.palette.secondary.light,
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.background.disabled,
      color: theme.palette.text.disabled,
    },
    '&.MuiLoadingButton-loading': {
      color: 'transparent',
    },
  },

  /**
   * Error Button Styles
   * ---------------------
   * */

  // Text Error
  '&.MuiButton-textError': {
    color: theme.palette.error.main,
    '&.Mui-focusVisible': {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.common.white,
    },
  },

  // Outlined Error
  '&.MuiButton-outlinedError': {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
    ':hover': {
      borderColor: theme.palette.error.main,
    },
    '&.Mui-disabled': {
      borderColor: theme.palette.background.disabled,
      color: theme.palette.text.disabled,
    },
    '&.MuiLoadingButton-loading': {
      color: 'transparent',
    },
    '&.Mui-focusVisible': {
      backgroundColor: alpha(theme.palette.error.main, 0.75),
      color: theme.palette.common.white,

      '.MuiTouchRipple-child': {
        backgroundColor: theme.palette.common.white,
      },
    },
  },

  /**
   * Warning Button Styles
   * ---------------------
   * */

  // Text Warning
  '&.MuiButton-textWarning': {
    color: theme.palette.warning.main,
    '&.Mui-focusVisible': {
      backgroundColor: theme.palette.warning.light,
      color: theme.palette.common.white,
    },
  },

  // Outlined Warning
  '&.MuiButton-outlinedWarning': {
    borderColor: theme.palette.warning.main,
    color: theme.palette.warning.main,
    ':hover': {
      borderColor: theme.palette.warning.main,
    },
    '&.Mui-disabled': {
      borderColor: theme.palette.background.disabled,
      color: theme.palette.text.disabled,
    },
    '&.MuiLoadingButton-loading': {
      color: 'transparent',
    },
    '&.Mui-focusVisible': {
      backgroundColor: alpha(theme.palette.warning.main, 0.75),
      color: theme.palette.common.white,

      '.MuiTouchRipple-child': {
        backgroundColor: theme.palette.common.white,
      },
    },
  },

  /**
   * Info Button Styles
   * ---------------------
   * */

  // Text Info
  '&.MuiButton-textInfo': {
    '&.Mui-focusVisible': {
      backgroundColor: theme.palette.info.light,
    },
  },

  // Outlined Info
  '&.MuiButton-outlinedInfo': {
    '&.Mui-focusVisible': {
      backgroundColor: alpha(theme.palette.info.main, 0.75),
    },
  },

  // Contained Info Light
  '&.MuiButton-containedInfo.--light': {
    backgroundColor: theme.palette.info.tonal,
    color: theme.palette.text.primary,
    ':hover': {
      backgroundColor: theme.palette.info.light,
    },
    '&.Mui-focusVisible': {
      backgroundColor: theme.palette.info.light,
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.background.disabled,
      color: theme.palette.text.disabled,
    },
    '&.MuiLoadingButton-loading': {
      color: 'transparent',
    },
  },

  /**
   * Success Button Styles
   * ---------------------
   * */

  // Text Success
  '&.MuiButton-textSuccess': {
    '&.Mui-focusVisible': {
      backgroundColor: theme.palette.success.light,
    },
  },

  // Outlined Success
  '&.MuiButton-outlinedSuccess': {
    '&.Mui-focusVisible': {
      backgroundColor: alpha(theme.palette.success.main, 0.75),
    },
  },

  /**
   * White Button Styles
   * ---------------------
   * */

  // Text White
  '&.MuiButton-textWhite': {
    color: theme.palette.common.white,
    '&.Mui-focusVisible': {
      backgroundColor: alpha(
        theme.palette.white.main,
        theme.palette.action.hoverOpacity,
      ),
    },
    '&.Mui-disabled': {
      color: theme.extendedColors.cement[400],
    },
    '&.MuiLoadingButton-loading': {
      color: 'transparent',
    },
    '.MuiLoadingButton-loadingIndicator': {
      color: theme.extendedColors.cement[400],
    },
  },

  // Outlined White
  '&.MuiButton-outlinedWhite': {
    backgroundColor: 'transparent',
    borderColor: theme.palette.common.white,
    color: theme.palette.common.white,
    '&.Mui-focusVisible': {
      backgroundColor: alpha(
        theme.palette.white.main,
        theme.palette.action.hoverOpacity,
      ),
    },
    '&.Mui-disabled': {
      borderColor: theme.extendedColors.cement[400],
      color: theme.extendedColors.cement[400],
    },
    '&.MuiLoadingButton-loading': {
      color: 'transparent',
    },
    '.MuiLoadingButton-loadingIndicator': {
      color: theme.extendedColors.cement[400],
    },
  },

  // Contained White
  '&.MuiButton-containedWhite': {
    backgroundColor: theme.palette.common.white,
    ':hover': {
      backgroundColor: alpha(
        theme.palette.common.white,
        theme.palette.action.hoverOpacity,
      ),
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.background.disabled,
      color: theme.palette.text.disabled,
    },
    '&.MuiLoadingButton-loading': {
      color: 'transparent',
    },
    '.MuiLoadingButton-loadingIndicator': {
      color: theme.palette.text.disabled,
    },
  },

  /**
   * Gray Button Styles
   * ---------------------
   * */

  // Text Gray
  '&.MuiButton-textGray': {
    background: 'transparent',
    ':hover': {
      backgroundColor: alpha(
        theme.palette.gray.main,
        theme.palette.action.hoverOpacity,
      ),
    },
    '&.Mui-focusVisible': {
      backgroundColor: alpha(
        theme.palette.gray.main,
        theme.palette.action.hoverOpacity,
      ),
    },
    '&.Mui-disabled': {
      color: theme.palette.text.disabled,
    },
    '&.MuiLoadingButton-loading': {
      color: 'transparent',
    },
    '.MuiLoadingButton-loadingIndicator': {
      color: theme.palette.text.disabled,
    },
  },

  // Outlined Gray
  '&.MuiButton-outlinedGray': {
    ':hover': {
      backgroundColor: alpha(
        theme.palette.gray.main,
        theme.palette.action.hoverOpacity,
      ),
    },
    '&.Mui-focusVisible': {
      backgroundColor: alpha(
        theme.palette.gray.main,
        theme.palette.action.hoverOpacity,
      ),
    },
  },

  // Contained Gray
  '&.MuiButton-containedGray': {
    ':hover': {
      backgroundColor: theme.palette.gray.light,
    },
  },
}));
/* eslint-enable sort-keys, @typescript-eslint/no-magic-numbers */

const Button: FC<ButtonProps> = ({
  className,
  size = 'large',
  light = false,
  ...rest
}) => {
  const classes = clsx('mnt-button', { '--light': light }, className);

  return (
    <StyledButton {...rest} className={classes} size={size} disableElevation />
  );
};
Button.displayName = 'Button';

export default Button;
export type { ButtonProps };
