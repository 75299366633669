import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { getSession } from '@/api';
import { SessionAction } from '@/constants';
import { useCurrentUser } from '@/hooks';

const ExternalSessionRedirect = () => {
  const { externalSessionId = '', action } = useParams();
  const { user: currentUser, isUserLoading } = useCurrentUser();

  const navigate = useNavigate();

  const { data: session } = useQuery(
    ['session-by-external', externalSessionId],
    () => getSession(externalSessionId),
  );

  useEffect(() => {
    if (session && session.userId && !isUserLoading) {
      if (currentUser.isCoach && currentUser.id !== session.userId) {
        const prefix =
          session.coachId === currentUser.id
            ? '/sessions'
            : `/coach/members/${session.userId}/sessions`;
        switch (action) {
          case SessionAction.GROWTH:
            return navigate(`/coach/members/${session.userId}/growth`);
          case SessionAction.RESCHEDULE:
            return navigate(
              `${prefix}/${session.id}?action=${SessionAction.RESCHEDULE}`,
            );
          case SessionAction.CANCEL:
            return navigate(
              `${prefix}/${session.id}?action=${SessionAction.CANCEL}`,
            );
          case SessionAction.VIEW:
          default:
            return navigate(`${prefix}/${session.id}`);
        }
      } else {
        switch (action) {
          case SessionAction.GROWTH:
            return navigate('/m/growth');
          case SessionAction.RESCHEDULE:
            return navigate(
              `/sessions/${session.id}?action=${SessionAction.RESCHEDULE}`,
            );
          case SessionAction.CANCEL:
            return navigate(
              `/sessions/${session.id}?action=${SessionAction.CANCEL}`,
            );
          case SessionAction.VIEW:
          default:
            return navigate(`/sessions/${session.id}`);
        }
      }
    }
    // not sure why we need this but the linter is yelling at me for a return at the end of the arrow function
    return undefined;
  }, [session, isUserLoading, currentUser]);
  return null;
};
export default ExternalSessionRedirect;
