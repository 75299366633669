import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import { getUsers } from '@/api';
import { ONE, ZERO } from '@/constants';
import { useCurrentUser } from '@/hooks';
import { SortValues } from '@/types';
import { PageHeaderLayout, Pagination } from '@/ui';

import Header from './Header';
import List from './List';
import MembersLoading from './MembersLoading';

import type { SortDirection } from '@/types';
import type { SelectChangeEvent } from '@/ui';

// TODO: page should be simple, layout should take care of a good chunk, can just focus on loading components
// TODO: Components should fetch their data for the most part
// TODO: Page should only have Page components, the rest should live in `@/components`
const Members = () => {
  const [filter, setFilter] = useState('');
  const [page, setPage] = useState(ONE);
  const [search, setSearch] = useState('');

  const [sort, setSort] = useState(SortValues.MOST_RECENT);
  const { user, isUserLoading } = useCurrentUser();

  const { data, isLoading } = useQuery(
    ['clients', filter, page, search, sort],
    () => {
      let sortBy = '';
      let sortDirection: SortDirection | undefined;

      switch (sort) {
        case SortValues.NAME_ASC:
          sortBy = 'first_name';
          sortDirection = 'asc';
          break;
        case SortValues.NAME_DESC:
          sortBy = 'first_name';
          sortDirection = 'desc';
          break;
        default:
          sortBy = 'created_at';
          sortDirection = 'desc';
      }

      return getUsers({
        filter,
        page,
        search,
        sortBy,
        sortDirection,
        view: 'list',
      });
    },
  );

  useEffect(() => {
    setPage(ONE);
  }, [search, filter]);

  // This is all TS does, cause pain and suffering
  // also absolutely unnecessary code :)
  const setSortTyped = (event: SelectChangeEvent<SortValues>) => {
    setSort(event.target.value as SortValues);
  };

  const handlePageChange = (_: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const headerProps = {
    filter,
    search,
    setFilter,
    setSearch,
    setSort: setSortTyped,
    sort,
  };

  if (isUserLoading) {
    return null;
  }

  return (
    <div className="container mx-auto max-w-screen-lg bg-sage-100 p-4">
      <PageHeaderLayout title={`Members (${data?.total ?? '...'})`} />
      <Header isAdmin={!!user.superAdmin} {...headerProps} />
      {isLoading ? <MembersLoading /> : <List clients={data?.items ?? []} />}
      <Pagination
        count={data?.pages ?? ZERO}
        page={page}
        onChange={handlePageChange}
      />
    </div>
  );
};

export default Members;
