import { formatDateDDMMYYYY, identify } from '@/utils';

import mentoApi from './mentoApi';

import type {
  PaginatedResponse,
  SortDirection,
  User,
  UsersEngagement,
} from '@/types';

// if nothing is found returns null, return obj instead.
// TODO: Remove identify from here, we agreed API calls would only be API calls
// Also, this should probably be run as part of the AppContext set up, not on every
// api call to `/me`
export const getCurrentUser = async (): Promise<Awaited<User>> => {
  const result = await mentoApi.get('/users/me');
  if (result.data) {
    identify(result.data);
  }
  return result.data ?? {};
};

export interface UserApiOptions {
  coach?: boolean;
  filter?: string;
  limit?: Number;
  page?: Number;
  search?: string;
  sortBy?: string;
  sortDirection?: SortDirection;
  userId?: string; // TODO: Change param name to be more intuitive. Right now this is passed to limit coaches to coaches for a user
  view?: string;
}
export const getUsers = async (
  params: UserApiOptions,
): Promise<PaginatedResponse<User>> =>
  (await mentoApi.get('/users', { params })).data;

export const getUser = async (id: string) => {
  const result = await mentoApi.get(`/users/${id}`);
  return result.data;
};

export const updateUser = async (id: string, user: Partial<User>) => {
  const result = await mentoApi.put(`/users/${id}`, { user });
  return result.data;
};

export const getUsersEngagement = async (
  companyId: string,
  start?: Date,
): Promise<UsersEngagement> => {
  const startDate = start ? `${formatDateDDMMYYYY(start)}` : '';
  return (
    await mentoApi.get(
      `/users/stats?company_id=${companyId}${
        startDate ? '&start=startDate' : ''
      }`,
    )
  ).data;
};
