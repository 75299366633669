import { useNavigate } from 'react-router';

import { ONE } from '@/constants';
import { useGrowthPlans } from '@/hooks';

import useSharedCardActions from './useSharedCardActions';

import type { GeneratorFunction } from './types';

const useGrowthPlanActions = (): GeneratorFunction => {
  const { handleClick } = useSharedCardActions();
  const navigate = useNavigate();
  const { plans, isPlansLoading } = useGrowthPlans();
  let label = '';

  if (!isPlansLoading && plans) {
    if (plans.length === ONE && !plans[0]?.started) {
      label = 'Get started';
    } else {
      label = plans.length > ONE ? 'See plans' : 'See plan';
    }
  }

  return ({ card }) => [
    {
      label,
      onClick: () => {
        handleClick({ card, label });
        navigate('/m/growth');
      },
    },
  ];
};

export default useGrowthPlanActions;
