import { useView } from './useView';

import type { User } from '@/types';

export const useFeatureLocked = (user: User) => {
  const { isClient } = useView();
  const isFeatureLocked = isClient && !user.isPaid;

  return {
    isFeatureLocked,
  };
};
