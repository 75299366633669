import { useCurrentUser } from '@/hooks';
import { Icon, ListItemIcon, ListItemText, MenuItem } from '@/ui';

import type { Session } from '@/types';
import type { FC } from 'react';

interface PastMenuItemsProps {
  handleOnClose: (e: Event | React.SyntheticEvent) => void;
  onFeedback?: (session: Session) => void;
  onMessage?: (e: React.MouseEvent<HTMLElement>) => void;
  onViewProfile?: (e: React.MouseEvent<HTMLElement>) => void;
  session: Session;
}

const PastMenuItems: FC<PastMenuItemsProps> = ({
  session,
  handleOnClose,
  onFeedback,
  onViewProfile,
  onMessage,
}) => {
  const { user } = useCurrentUser();
  const isCoachViewing = session.coachId === user.id;
  const concerningUser = isCoachViewing ? session.user : session.coach;

  const canProvideFeedback =
    !isCoachViewing && !!onFeedback && !session.feedback?.id;

  return (
    <div>
      {canProvideFeedback ? (
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            handleOnClose(e);
            onFeedback(session);
          }}
        >
          <ListItemIcon>
            <Icon>add</Icon>
          </ListItemIcon>
          <ListItemText>Provide feedback</ListItemText>
        </MenuItem>
      ) : null}
      {!!onViewProfile && (
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            handleOnClose(e);
            onViewProfile(e);
          }}
        >
          <ListItemIcon>
            <Icon>account_circle</Icon>
          </ListItemIcon>
          <ListItemText>View profile</ListItemText>
        </MenuItem>
      )}
      {!!onMessage && (
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            handleOnClose(e);
            onMessage(e);
          }}
        >
          <ListItemIcon>
            <Icon>chat_bubble</Icon>
          </ListItemIcon>
          <ListItemText>Message {concerningUser.fullName}</ListItemText>
        </MenuItem>
      )}
    </div>
  );
};

export default PastMenuItems;
