import { SurveyResponseStatus } from '@/types';
import { Typography } from '@/ui';

import Info from './Info';

import type { SurveyResponse } from '@/types';
import type { FC } from 'react';

interface HeaderProps {
  responses: SurveyResponse[];
  show: boolean;
  toggleShow: () => void;
}

const Header: FC<HeaderProps> = ({ responses, show, toggleShow }) => {
  const rolesText = show ? 'Showing names and roles' : 'Names and roles hidden';
  const rolesButton = !show ? 'Show' : 'Hide';

  const responded = responses.filter(
    ({ status }) => status === SurveyResponseStatus.COMPLETED,
  );

  return (
    <div className="pl-4 pt-2">
      <Typography className="text-cement-600" variant="body1">
        {responded.length} out of {responses.length} responded
      </Typography>
      <div className="body-sm flex text-cement-400">
        <Typography className="my-auto">{rolesText}&nbsp;</Typography>
        <Typography
          className="my-auto cursor-pointer underline"
          onClick={toggleShow}
        >
          {rolesButton}
        </Typography>
        <Info />
      </div>
    </div>
  );
};

export default Header;
