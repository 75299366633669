import type { FC } from 'react';

interface PillsProps {
  choices: string[];
  color: string;
}

const Pills: FC<PillsProps> = ({ choices, color }) => (
  <div className="flex flex-wrap">
    {choices.map((choice) => (
      <div
        key={`pills-${choice}`}
        className={`body-sm bg-${color}-300 mb-5 mr-2 whitespace-nowrap rounded px-4 py-2`}
      >
        {choice}
      </div>
    ))}
  </div>
);

export default Pills;
