import { Typography } from '@/ui';

import type { FC } from 'react';

interface TextProps {
  text: string;
}

const Text: FC<TextProps> = ({ text }) => (
  <div className="relative flex w-full">
    {/* eslint-disable-next-line tailwindcss/no-arbitrary-value -- One-off typography stylings for design purposes. */}
    <div className="absolute left-4 text-[215px] leading-[140px] text-bloom-200 md:top-6">
      “
    </div>
    <Typography
      className="mx-auto mb-24 mt-12 max-w-4xl text-center md:px-12 xl:px-0"
      color="text.primary"
      variant="h4"
    >
      {text}
    </Typography>
  </div>
);
export default Text;
