import { useState } from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input';

import { updateUserNotifications, updateUserProfile } from '@/api';
import { ZERO } from '@/constants';
import { useCurrentUser } from '@/hooks';
import { type User, type UserNotifications } from '@/types';
import { Button, Dialog, DialogActions, DialogContent, Divider } from '@/ui';

import Details from './Details';
import Notifications from './Notifications';

import type { FC } from 'react';

interface EditClientAccountProps {
  client: User;
  onClose: (updated?: Partial<User>) => void;
}

const EditClientAccount: FC<EditClientAccountProps> = ({ client, onClose }) => {
  const { refetchUser } = useCurrentUser();
  const [isSaving, setIsSaving] = useState(false);
  const [timezone, setTimezone] = useState(client.profile?.timezone || '');
  const [phone, setPhone] = useState(client.profile?.phone || '');
  const [preferences, setPreferences] = useState(
    client.userNotifications ?? {},
  );
  const onPhoneChange = (value?: string) => setPhone(value || '');
  const onTimezoneChange = (value: string) => setTimezone(value);
  const onPreferencesChange = (value: UserNotifications) =>
    setPreferences(value);

  const onSave = async () => {
    setIsSaving(true);
    await updateUserProfile(client.id, {
      phone,
      timezone,
    });
    await updateUserNotifications(client.id, preferences);
    setIsSaving(false);
    await refetchUser();
    onClose();
  };
  const onCancel = () => onClose();

  const isPhoneInvalid =
    !isValidPhoneNumber(phone || '', 'US') && (phone || '').length > ZERO;

  return (
    <Dialog maxWidth="md" fullWidth open>
      <DialogContent>
        <Details
          email={client.email}
          phone={phone}
          timezone={timezone}
          onPhoneChange={onPhoneChange}
          onTimezoneChange={onTimezoneChange}
        />
        <Divider className="my-8" />
        <Notifications
          preferences={preferences}
          onPreferencesChange={onPreferencesChange}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={isPhoneInvalid}
          loading={isSaving}
          variant="contained"
          onClick={onSave}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditClientAccount;
