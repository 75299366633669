import { useState } from 'react';

import { NORTH_STAR_DIALOG, ONE_YEAR_DIALOG } from '@/constants';
import { useGrowthPlan, useUpdateGrowthPlan } from '@/hooks';
import { Button, Typography } from '@/ui';

import SidebarCard from '../SidebarCard';

import type { FC } from 'react';

import Dialog from '@/components/Growth/Dialog';
import VerticalLine from '@/components/Growth/VerticalLine';

const Data: FC = () => {
  const [northStarOpen, setNorthStarOpen] = useState(false);
  const [oneYearOpen, setOneYearOpen] = useState(false);

  const { plan, refetch: refetchPlan } = useGrowthPlan();

  const updateGrowthPlan = useUpdateGrowthPlan();

  const openNorthStar = () => setNorthStarOpen(true);
  const openOneYear = () => setOneYearOpen(true);

  const onCloseNorthStar = (value: string) => {
    if (value) {
      updateGrowthPlan.mutate(
        { id: plan.id, northStar: value },
        { onSuccess: () => refetchPlan() },
      );
    }
    setNorthStarOpen(false);
  };

  const onCloseOneYear = (value: string) => {
    if (value) {
      updateGrowthPlan.mutate(
        { id: plan.id, oneYearVision: value },
        { onSuccess: () => refetchPlan() },
      );
    }
    setOneYearOpen(false);
  };

  return (
    <>
      <SidebarCard
        icon="auto_awesome"
        isClickable={!!plan.northStar}
        title="North star"
        onChange={openNorthStar}
      >
        {plan.northStar ? (
          <Typography
            className="line-clamp-4 whitespace-pre-wrap px-2"
            variant="caption"
          >
            {plan.northStar}
          </Typography>
        ) : (
          <>
            <Typography
              className="line-clamp-4 whitespace-pre-wrap px-2"
              color="text.secondary"
              variant="caption"
            >
              Your dream goal. What gives you a sense or purpose and meaning?
            </Typography>
            <Button
              className="mt-4"
              color="primary"
              variant="contained"
              fullWidth
              light
              onClick={openNorthStar}
            >
              Start dreaming
            </Button>
          </>
        )}
      </SidebarCard>

      <VerticalLine className="h-4" />

      <SidebarCard
        icon="insights"
        isClickable={!!plan.oneYearVision}
        title="One year vision"
        onChange={openOneYear}
      >
        {plan.oneYearVision ? (
          <Typography
            className="line-clamp-4 whitespace-pre-wrap px-2"
            variant="caption"
          >
            {plan.oneYearVision}
          </Typography>
        ) : (
          <>
            <Typography
              className="line-clamp-4 whitespace-pre-wrap px-2"
              color="text.secondary"
              variant="caption"
            >
              Where do you envision you'll be within a year? How would that
              align with your North Star?
            </Typography>
            <Button
              className="mt-4"
              color="primary"
              variant="contained"
              fullWidth
              light
              onClick={openOneYear}
            >
              Craft my vision
            </Button>
          </>
        )}
      </SidebarCard>

      <Dialog
        callback={onCloseNorthStar}
        initialValue={plan.northStar}
        isOpen={northStarOpen}
        setOpen={setNorthStarOpen}
        {...NORTH_STAR_DIALOG}
      />

      <Dialog
        callback={onCloseOneYear}
        initialValue={plan.oneYearVision}
        isOpen={oneYearOpen}
        setOpen={setOneYearOpen}
        {...ONE_YEAR_DIALOG}
      />
    </>
  );
};

export default Data;
