import { useCallback, useState } from 'react';

export interface UseModalProps {
  readonly defaultIsOpen?: boolean;
  readonly onClose?: () => void;
}

export const useModal = ({
  onClose,
  defaultIsOpen = false,
}: UseModalProps = {}) => {
  const [isOpen, setIsOpen] = useState(defaultIsOpen);

  const closeModal = useCallback(() => {
    if (onClose) {
      onClose();
    }
    setIsOpen(false);
  }, [onClose]);

  const openModal = useCallback(() => {
    setIsOpen(true);
  }, []);

  return {
    closeModal,
    isOpen,
    openModal,
  };
};
