import mentoApi from './mentoApi';

import type { Team, TeamsApiOptions } from '@/types/teams';

export const getTeams = async (params: TeamsApiOptions) => {
  try {
    const result = await mentoApi.get('/teams', { params });
    return result.data;
  } catch (error) {
    console.error('Error getting teams!!!', error);
    throw error;
  }
};

export const getTeam = async (id: string) => {
  const result = await mentoApi.get(`/teams/${id}`, {});
  return result.data;
};

export const createTeam = async (name: string, isActive: boolean) => {
  const result = await mentoApi.post('/teams', {
    team: {
      isActive,
      name,
    },
  });
  return result.data;
};

export const updateTeam = async (id: string, team: Partial<Team>) => {
  const result = await mentoApi.put(`/teams/${id}`, {
    team,
  });
  return result.data;
};

export const joinTeam = async (id: string) => {
  const result = await mentoApi.patch(`/teams/${id}/accept`);
  return result.data;
};
