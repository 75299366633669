import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { useContext, useEffect, useState } from 'react';

import { deleteAssistant, getAssistants } from '@/api';
import { DEFAULT_PAGE_SIZE, ONE, ZERO } from '@/constants';
import { AppContext } from '@/context';
import { AppMessageSeverities, type Assistant as AssistantType } from '@/types';
import {
  Button,
  PageHeaderLayout,
  Pagination,
  Search,
  Table,
  TableContainer,
} from '@/ui';

import type { ChangeEvent } from 'react';

import DeleteModal from '@/components/Assistant/DeleteModal';
import List from '@/components/Assistant/List';
import Header from '@/components/Assistant/List/Header';
import Modal from '@/components/Assistant/View/Modal';
import { ASSISTANT_HEADERS } from '@/constants/assistant';

const Assistant = () => {
  const [page, setPage] = useState<number>(ONE);
  const [search, setSearch] = useState('');
  const [assistant, setAssistant] = useState<AssistantType | null>(null);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const { setAppMessage } = useContext(AppContext);
  const { data, isLoading, refetch } = useQuery(
    ['assistants', search, page],
    () =>
      getAssistants({
        limit: DEFAULT_PAGE_SIZE,
        page,
        search,
      }),
    { refetchOnMount: 'always' },
  );

  useEffect(() => {
    setPage(ONE);
  }, [search]);

  const onAddAssistant = () => setAssistant({} as AssistantType);
  const onCloseAddAssistant = () => setAssistant(null);
  const onPageChange = (_: ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const onDeleteClick = (toDelete: AssistantType) => {
    setAssistant(toDelete);
    setShowConfirm(true);
  };

  const onDeleteModalClose = () => {
    setAssistant(null);
    setShowConfirm(false);
  };
  const onDelete = async () => {
    if (assistant) {
      try {
        await deleteAssistant(assistant.id);
        onDeleteModalClose();
        refetch();
      } catch (e) {
        captureException(e);
        setAppMessage({
          message: 'Error deleting assistant',
          open: true,
          severity: AppMessageSeverities.Error,
        });
      }
    }
  };
  return (
    <div className="w-full p-4">
      <PageHeaderLayout title="Assistants">
        <Button variant="contained" onClick={onAddAssistant}>
          Add assistant
        </Button>
      </PageHeaderLayout>

      <Search
        className="mt-4 w-full md:w-1/3"
        search={search}
        setSearch={setSearch}
      />

      <div className="flex flex-1 flex-col pt-4">
        <TableContainer>
          <Table aria-label="coaches table">
            <Header headers={ASSISTANT_HEADERS} />
            <List
              assistants={data?.items || []}
              headers={ASSISTANT_HEADERS}
              isLoading={isLoading}
              onDeleteClick={onDeleteClick}
            />
          </Table>
        </TableContainer>
        <Pagination
          count={data?.pages ?? ZERO}
          page={page}
          onChange={onPageChange}
        />
      </div>
      {!showConfirm && !!assistant && (
        <Modal
          assistant={assistant}
          refetch={refetch}
          onClose={onCloseAddAssistant}
        />
      )}
      {showConfirm && !!assistant && (
        <DeleteModal
          toDelete={assistant}
          onClose={onDeleteModalClose}
          onDelete={onDelete}
        />
      )}
    </div>
  );
};

export default Assistant;
