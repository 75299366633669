import { DocumentDisplay } from '@/constants';
import { MenuItem } from '@/ui';

import type { DocMimeType } from '@/types';
import type { FC } from 'react';

interface ItemProps {
  mimeType: DocMimeType;
  onCreate: (type: DocMimeType) => void;
}

const Item: FC<ItemProps> = ({ mimeType, onCreate }) => {
  const onClick = () => onCreate(mimeType);
  const { label = '', icon = '' } = DocumentDisplay[mimeType] ?? {};

  return (
    <MenuItem onClick={onClick}>
      <img
        alt={`New ${label}`}
        src={`https://mento-space.nyc3.cdn.digitaloceanspaces.com/icons/${icon}.svg`}
        width={24}
      />
      &nbsp;{label}
    </MenuItem>
  );
};

export default Item;
