import { Typography } from '@/ui';

import type { FC } from 'react';

import Style from '@/components/Profile/Coach/Styles/Style';

interface StylesProps {
  styles: string[];
}

const Index: FC<StylesProps> = ({ styles = [] }) => (
  <section className="max-w-2xl">
    <Typography className="mb-4" variant="h5">
      Coaching style
    </Typography>
    <div className="mt-2 grid grid-cols-2 gap-4">
      {styles.map((key) => (
        <Style key={key} styleKey={key} />
      ))}
    </div>
  </section>
);

export default Index;
