import { styled } from '@mui/material';
import MuiCardActions from '@mui/material/CardActions';
import { clsx } from 'clsx';

import type { CardActionsProps } from '@mui/material/CardActions';
import type { FC } from 'react';

/* eslint-disable @typescript-eslint/no-magic-numbers -- CSS values make little sense as constants. */
const StyledCardActions = styled(MuiCardActions)<CardActionsProps>(
  ({ theme }) => ({
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  }),
);
/* eslint-enable @typescript-eslint/no-magic-numbers */

const CardActions: FC<CardActionsProps> = ({ className, ...rest }) => {
  const classes = clsx('mnt-card-actions', className);

  return <StyledCardActions {...rest} className={classes} />;
};
CardActions.displayName = 'CardActions';

export default CardActions;
export type { CardActionsProps };
