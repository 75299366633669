import { useMutation, useQuery } from '@tanstack/react-query';

import {
  deleteMilestoneStep,
  getMilestoneStep,
  getMilestoneSteps,
  patchMilestoneStep,
  postMilestoneStep,
  refreshMilestoneSuggestedStep,
} from '@/api';
import { QUERY_KEY_MILESTONE_STEPS } from '@/constants';

import type { GetMilestoneStepParams, GetMilestoneStepsParams } from '@/api';
import type { MilestoneStep, MilestoneSteps } from '@/types';
import type { UseQueryOptions } from '@tanstack/react-query';

export const useGetMilestoneSteps = (
  params: GetMilestoneStepsParams,
  options?: UseQueryOptions,
) =>
  useQuery<MilestoneSteps, Error>({
    enabled: options ? options.enabled : true,
    placeholderData: {
      other: [],
      own: null,
    } as unknown as MilestoneSteps,
    queryFn: () => getMilestoneSteps(params),
    queryKey: [
      QUERY_KEY_MILESTONE_STEPS,
      params.growthPlanId,
      params.page,
      params.complete,
    ],
  });

export const useGetMilestoneStep = (params: GetMilestoneStepParams) =>
  useQuery<MilestoneStep, Error>({
    placeholderData: {} as MilestoneStep,
    queryFn: () => getMilestoneStep(params),
    queryKey: [QUERY_KEY_MILESTONE_STEPS, params.id],
  });

export const useCreateMilestoneStep = () =>
  useMutation({ mutationFn: postMilestoneStep });
export const useUpdateMilestoneStep = () =>
  useMutation({ mutationFn: patchMilestoneStep });
export const useDeleteMilestoneStep = () =>
  useMutation({ mutationFn: deleteMilestoneStep });
export const useRefreshSuggestedMilestoneStep = () =>
  useMutation({ mutationFn: refreshMilestoneSuggestedStep });
