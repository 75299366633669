import { Typography } from '@mui/material';
import { useParams } from 'react-router';

import { ONE_HUNDRED } from '@/constants';
import { useCompanyStats } from '@/hooks';
import { SessionFeedbackType } from '@/types';
import { Skeleton } from '@/ui';

import Graph from './Graph';

const Feedback = () => {
  const { companyId = '' } = useParams();
  const { companyStats, iscompanyStatsLoading } = useCompanyStats(companyId);
  const {
    positive = 0,
    negative = 0,
    mixed = 0,
  } = companyStats?.feedback || {};
  if (iscompanyStatsLoading) {
    return (
      <Skeleton
        animation="wave"
        className="mt-8 h-50 w-full rounded-xl"
        variant="rectangular"
      />
    );
  }
  if ([positive, negative, mixed].every((value) => !value)) return null;

  const feedbackPercentage = Math.round(
    (positive / (positive + negative + mixed)) * ONE_HUNDRED,
  );
  return (
    <div className="flex flex-col space-y-3 pt-6 md:pt-0">
      <Typography className="text-black" variant="h5">
        Sessions
      </Typography>
      <Typography className="text-black" variant="body1">
        {feedbackPercentage}% positive feedback
      </Typography>
      <Graph feedbackType={SessionFeedbackType.POSITIVE} value={positive} />
      <Graph feedbackType={SessionFeedbackType.MIXED} value={mixed} />
      <Graph feedbackType={SessionFeedbackType.NEGATIVE} value={negative} />
      {/* TODO: Add session feedback details link when design is ready */}
    </div>
  );
};

export default Feedback;
