import CardHeader from '@mui/material/CardHeader';
import { useState } from 'react';

import Chip from '../Chip';
import Icon from '../Icon';
import IconButton from '../IconButton';
import { Menu, MenuItem } from '../Menu';

import type { ChipProps } from '../Chip';
import type { EventStatus } from '@/types';
import type { CardHeaderProps } from '@mui/material/CardHeader';
import type { FC, MouseEvent } from 'react';

interface ActionCardHeaderProps extends CardHeaderProps {
  isDarkTheme?: boolean;
  label: string;
  onClose?: () => void;
  onDelete?: () => void;
  onEdit?: () => void;
  onPublish?: () => void;
  onUnpublish?: () => void;
  status?: EventStatus;
}

const ActionCardHeader: FC<ActionCardHeaderProps> = ({
  label,
  isDarkTheme,
  onClose,
  onDelete,
  onEdit,
  onPublish,
  onUnpublish,
  status,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setIsMenuOpen(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setIsMenuOpen(false);
  };
  const handleAction = (callback: () => void) => () => {
    handleClose();
    callback();
  };

  const statusPropsMap: Record<EventStatus, Partial<ChipProps>> = {
    draft: { color: 'default', label: 'Draft' },
    expired: { color: 'primary', disabled: true, label: 'Expired' },
    published: { color: 'info', label: 'Published' },
  };

  return (
    <CardHeader
      action={
        <>
          {status && (
            <Chip
              size="small"
              variant="filled"
              {...statusPropsMap[status]}
              sx={{ mr: 1 }}
            />
          )}
          {(!!onDelete || !!onEdit || !!onPublish) && (
            <>
              <IconButton
                color={isDarkTheme ? 'white' : 'primary'}
                size="small"
                onClick={handleOpen}
              >
                <Icon>more_vert</Icon>
              </IconButton>
              <Menu anchorEl={anchorEl} open={isMenuOpen} onClose={handleClose}>
                {!!onEdit && (
                  <MenuItem onClick={handleAction(onEdit)}>
                    <Icon sx={{ mr: 1 }}>edit</Icon> Edit card
                  </MenuItem>
                )}
                {!!onPublish && (
                  <MenuItem onClick={handleAction(onPublish)}>
                    <Icon sx={{ mr: 1 }}>publish</Icon> Publish
                  </MenuItem>
                )}
                {!!onUnpublish && (
                  <MenuItem onClick={handleAction(onUnpublish)}>
                    <Icon sx={{ mr: 1 }}>font_download_off</Icon> Unpublish
                  </MenuItem>
                )}
                {!!onDelete && (
                  <MenuItem onClick={handleAction(onDelete)}>
                    <Icon sx={{ mr: 1 }}>trash</Icon> Delete
                  </MenuItem>
                )}
              </Menu>
            </>
          )}
          {!!onClose && (
            <IconButton
              color={isDarkTheme ? 'white' : 'primary'}
              size="small"
              onClick={onClose}
            >
              <Icon>close</Icon>
            </IconButton>
          )}
        </>
      }
      subheader={label}
      subheaderTypographyProps={{
        ...(isDarkTheme ? { color: 'white' } : {}),
        variant: 'caption',
      }}
      sx={{ height: '54px' }}
    />
  );
};
ActionCardHeader.displayName = 'ActionCardHeader';

export default ActionCardHeader;
export type { ActionCardHeaderProps };
