import { RELOAD_CAL_MODAL } from '@/constants';
import { Button, Dialog, Icon } from '@/ui';

import Cal from './embed';

import type { User } from '@/types';
import type { FC } from 'react';

interface ScheduleSessionProps {
  callback?: () => void;
  client: User;
  close?: () => void;
  coach: User;
  eventTypeSlug?: string;
}

const ScheduleSession: FC<ScheduleSessionProps> = ({
  callback,
  client,
  coach,
  eventTypeSlug = '',
  close = undefined,
}) => {
  const { calendarUrl = '' } = coach.coachProfile ?? {};
  const onClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();

    if (!close) {
      setTimeout(() => window.location.reload(), RELOAD_CAL_MODAL);
    } else {
      close();
    }
  };

  return (
    <Dialog fullScreen open>
      <Button className="absolute right-4 top-4 z-10" onClick={onClose}>
        <Icon>close</Icon>
      </Button>
      <Cal
        callback={callback}
        calLink={`${calendarUrl}${eventTypeSlug ? `/${eventTypeSlug}` : ''}`}
        email={client.email}
        name={client.fullName}
      />
    </Dialog>
  );
};

export default ScheduleSession;
