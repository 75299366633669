import type { MilestoneStep } from './growth';
import type { MessageRole } from './threads';

export enum WORKFLOW_CATEGORY {
  GROW = 'grow',
}

export enum MESSAGE_TYPES {
  LIST = 'list',
  MESSAGE = 'message',
  TEXT = 'text',
}

export interface Workflow {
  category: WORKFLOW_CATEGORY;
  content: string;
  createdAt: string;
  id: string;
  threadId?: string;
  title: string;
  userId: string;
}

export interface WorkflowMessage {
  color?: string;
  confirmed?: boolean | null;
  content: string;
  description?: string;
  extendable: MilestoneStep | Record<string, never>;
  functionId?: string;
  icon?: string;
  id: string;
  items?: string;
  role: MessageRole;
  sortKey: number;
  title?: string;
  workflowEnded?: string;
  workflowId: string;
}

export interface WorkflowMessageItem {
  option: string;
}
