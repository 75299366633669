import clsx from 'clsx';

import { useCurrentUser } from '@/hooks';
import { PageHeaderLayout, Typography } from '@/ui';

import TemplateCards from '@/components/Insights/TemplateCards';
import LockedFeature from '@/components/LockedStates';
import { useFeatureLocked } from '@/hooks/useFeatureLocked';

const Insights = () => {
  const { user } = useCurrentUser();
  const { isFeatureLocked } = useFeatureLocked(user);
  return (
    <div
      className={clsx('p-4 lg:px-32 lg:pt-8', {
        container: !isFeatureLocked,
        'w-full': isFeatureLocked,
      })}
    >
      <PageHeaderLayout title="Insights" />
      {isFeatureLocked ? (
        <LockedFeature title="insights" />
      ) : (
        <>
          <Typography className="pb-3 text-cement-400">
            Build awareness through self-assessments and insights from your
            peers and team to accelerate your growth.
          </Typography>
          <TemplateCards />
        </>
      )}
    </div>
  );
};

export default Insights;
