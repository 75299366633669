import mentoApi from './mentoApi';

import type { UserNotifications } from '@/types';

export const updateUserNotifications = async (
  id: string,
  notifications: Partial<UserNotifications>,
) => {
  const result = await mentoApi.patch(`/user_notifications/${id}`, {
    notifications,
  });
  return result.data;
};
