import { clsx } from 'clsx';

import type { SvgIconProps } from '@mui/material';
import type { ElementType, FC } from 'react';

interface IconProps extends SvgIconProps {
  children: string;
}

const icons = import.meta.glob<Record<string, ElementType>>('./icons/*.tsx', {
  eager: true,
});

const Icon: FC<IconProps> = ({ className, children = '', ...rest }) => {
  if (typeof children !== 'string' || !icons[`./icons/${children}.tsx`]) {
    return <>&nbsp;</>;
  }
  const classes = clsx('mnt-icon', className);

  const SelectedIcon = icons[`./icons/${children}.tsx`]?.default;

  if (!SelectedIcon) {
    return null;
  }

  return <SelectedIcon {...rest} className={classes} />;
};
Icon.displayName = 'Icon';

export default Icon;
export type { IconProps };
