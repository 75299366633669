import clsx from 'clsx';

import { Button, Card, CardContent, Icon, Typography } from '@/ui';

import type { User } from '@/types';
import type { FC } from 'react';

interface ProfileCardProps {
  coach: User;
  isSelected?: boolean;
  onEdit?: (coach: User) => void;
  onSelect?: (coach: User) => void;
}

const ProfileCard: FC<ProfileCardProps> = ({
  coach,
  isSelected = false,
  onSelect = undefined,
  onEdit = undefined,
}) => {
  const onEditClick = () => () => onEdit?.(coach);

  return (
    <Card
      className={clsx('inline-block min-w-70 rounded-2xl bg-transparent', {
        'bg-gradient-to-br from-poppy-500 to-bloom-500': isSelected,
        'cursor-pointer': onSelect,
      })}
      onClick={() => {
        if (onSelect) {
          onSelect(coach);
        }
      }}
    >
      <CardContent className="h-full w-full bg-black p-0">
        <div
          className="relative rounded-t-xl"
          style={{
            backgroundImage: `url(${coach.avatarUrl})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            height: '14rem',
          }}
        >
          <div className="absolute bottom-0 h-16 w-full bg-gradient-to-b from-transparent to-black" />
          {onEdit ? (
            <div className="absolute top-0 flex w-full justify-end p-2">
              <Button onClick={onEditClick}>
                <Icon>edit</Icon>
              </Button>
            </div>
          ) : null}
        </div>
        <div className="relative -mt-10 flex flex-col rounded-b-xl p-4 text-left text-white">
          <Typography className="text-sunny-500" variant="subtitle1">
            {coach.fullName}
          </Typography>
          <Typography className="h-26 overflow-hidden" variant="h5">
            {coach.coachProfile?.focus}
          </Typography>
          <div className="flex h-10 items-end overflow-hidden pt-1">
            <Typography variant="caption">
              {/* {`${coach.role || ''}${coach.role && coach.company ? ', ' : ''}${coach.company || ''
                }`} */}
            </Typography>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default ProfileCard;
