import { Avatar, Button, Typography } from '@/ui';

import type { User } from '@/types';
import type { FC } from 'react';

interface RowProps {
  coach: User;
  processing: boolean;
  removeClick: () => void;
}

const Row: FC<RowProps> = ({ coach, processing, removeClick }) => (
  <div className="flex items-center justify-between px-4 py-5 sm:px-6">
    <div className="shrink-0">
      <Avatar
        firstName={coach.firstName}
        lastName={coach.lastName}
        src={coach.avatarUrl ?? ''}
      />
    </div>
    <Typography className="ml-4 w-full text-sm font-medium text-gray-500">
      {coach.fullName}
    </Typography>
    <div className="flex shrink-0">
      <Button
        color="warning"
        loading={processing}
        variant="contained"
        onClick={removeClick}
      >
        Remove
      </Button>
    </div>
  </div>
);

export default Row;
