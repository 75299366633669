import { DocumentActionIcons } from '@/constants';
import { Icon, ListItemIcon, MenuItem } from '@/ui';

import type { DocumentActions } from '@/types';
import type { FC, MouseEvent } from 'react';

interface ItemProps {
  action: DocumentActions;
  href?: string;
  onClick: (value: DocumentActions) => (event: MouseEvent<HTMLElement>) => void;
}

const Item: FC<ItemProps> = ({ action, href = '', onClick }) => {
  const icon = DocumentActionIcons[action] ?? '';

  return (
    <MenuItem onClick={onClick(action)}>
      <ListItemIcon>
        <a
          className="flex justify-center gap-2 text-cement-500 no-underline"
          href={href}
        >
          <Icon>{icon}</Icon>
          &nbsp;{action}
        </a>
      </ListItemIcon>
    </MenuItem>
  );
};

export default Item;
