import { useQuery } from '@tanstack/react-query';

import { getAssistant } from '@/api';

import type { Assistant } from '@/types';

export const useGetAssistant = (assistantId: string) =>
  useQuery<Assistant>(['assistant', assistantId], () =>
    getAssistant(assistantId),
  );
