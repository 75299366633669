import { useNavigate } from 'react-router';

import { Icon, IconButton, TableCell, TableRow } from '@/ui';
import { toDateTimeString } from '@/utils';

import type { Assistant } from '@/types';
import type { FC } from 'react';

interface Props {
  assistant: Assistant;
  onDeleteClick: (data: Assistant) => void;
}
const Row: FC<Props> = ({ assistant, onDeleteClick }) => {
  const navigate = useNavigate();

  const onClick = () => navigate(`/coach/manage/assistants/${assistant.id}`);

  const handleDeleteClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    onDeleteClick(assistant);
  };
  return (
    <TableRow className="hover:bg-sage-100" onClick={onClick}>
      <TableCell>{assistant.name}</TableCell>
      <TableCell>{assistant.prompt}</TableCell>
      <TableCell>{assistant.model}</TableCell>
      <TableCell className="hidden text-black md:table-cell">
        {toDateTimeString(new Date(assistant.updatedAt))}
      </TableCell>
      <TableCell className="hidden text-black md:table-cell">
        <IconButton onClick={handleDeleteClick}>
          <Icon>trash</Icon>
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default Row;
