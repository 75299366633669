import { track } from '@/utils';

import mentoApi from './mentoApi';

import type { CoachAssignment } from '@/types';

export const createCoachAssignment = async (
  coachAssignment: Partial<CoachAssignment & { sendEmail?: boolean }>,
) => {
  track('Assign coach to member');
  return (await mentoApi.post('/coach_assignments', { coachAssignment })).data;
};

export const removeCoachAssignment = async (id: string) => {
  track('Remove coach from member');
  return (await mentoApi.delete(`/coach_assignments/${id}`)).data;
};
