import { useLocation, useNavigate } from 'react-router-dom';

import { SurveyStatus } from '@/types';
import { Icon, Typography } from '@/ui';
import { track } from '@/utils';

import type { SurveyResponse } from '@/types';
import type { FC } from 'react';

interface StartedProps {
  coach: string;
  icon: string;
  id: string;
  name: string;
  participants: SurveyResponse[];
  status: SurveyStatus;
}

const Started: FC<StartedProps> = ({
  coach,
  participants,
  status,
  icon,
  name,
  id,
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const responded = participants.filter(
    (response: SurveyResponse) => response.status === 'COMPLETED',
  ).length;
  const respondedBy = responded
    ? `${responded} out of ${participants.length} coworkers responded`
    : 'No responses yet';

  let surveyReview = `We'll let you know once your peers respond. Your coach ${coach} will walk you through a summary on a future session.`;

  if (
    status === SurveyStatus.COACH_REVIEWING ||
    status === SurveyStatus.SHARED
  ) {
    surveyReview = `Your coach ${coach} will walk you through a summary on a future session.`;
  }

  const onCardClick = () => {
    track('Clicked Survey In Progress', {
      surveyTemplate: name,
    });
    navigate(`${pathname}/${id}`);
  };

  return (
    <div
      className="cursor-pointer space-y-4"
      role="button"
      tabIndex={0}
      onClick={onCardClick}
      onKeyDown={onCardClick}
    >
      <div className="flex h-16 w-16 self-start rounded-full bg-bloom-500">
        <Icon className="m-auto text-white" fontSize="large">
          {icon}
        </Icon>
      </div>
      <div className="flex flex-col space-y-2">
        <Typography className="text-cement-600" variant="h5">
          {name}
        </Typography>
      </div>
      <div className="flex w-3/4 flex-col">
        <div className="flex items-center space-x-2 pb-3">
          <Icon>mark_email_read</Icon>
          <Typography className="text-cement-400" variant="body1">
            Survey sent &bull; {respondedBy}
          </Typography>
        </div>
        <div className="flex space-x-2">
          <Icon>tips_and_updates</Icon>
          <Typography className="text-cement-400" variant="body1">
            {surveyReview}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default Started;
