import { TableBody, TableCell, TableRow } from '@/ui';

import type { FC } from 'react';

interface LoadingProps {
  columns: number;
}

const Loading: FC<LoadingProps> = ({ columns }) => (
  <TableBody>
    <TableRow>
      <TableCell className="h-48 text-center" colSpan={columns}>
        Loading ...
      </TableCell>
    </TableRow>
  </TableBody>
);

export default Loading;
