import { useMediaQuery } from '@mui/material';
import { formatDistanceToNow } from 'date-fns';

import { DocumentDisplay } from '@/constants';
import { useCurrentUser, useGetUser } from '@/hooks';
import { Avatar, Paper, Typography } from '@/ui';
import { toDocumentUrl } from '@/utils';

import type { Document } from '@/types';
import type { FC, MouseEvent } from 'react';

import Ellipsis from '@/components/Document/Ellipsis';

interface ItemProps {
  current: Document;
}

const Item: FC<ItemProps> = ({ current }) => {
  const { user } = useCurrentUser();
  const isOwnedBySomeoneElse = current.authorId !== user.id;
  const { data: author } = useGetUser(current.authorId, {
    enabled: isOwnedBySomeoneElse,
  });
  const mobile = useMediaQuery('(max-width:600px)');

  const { mimeType, thumbnail, name, updatedAt, reference } = current;

  const { icon = 'doc' } = DocumentDisplay[mimeType] ?? {};

  const onClick = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const url = toDocumentUrl(mimeType, reference);
    window.open(url, '_blank');
  };

  return (
    <Paper
      className="h-49 group col-span-1 flex cursor-pointer flex-col rounded-lg bg-white hover:bg-ocean-200"
      onClick={onClick}
    >
      <span className="relative m-1 inline-block h-40 shrink-0 rounded-lg border border-solid border-cement-200 bg-white">
        <div className="flex h-full w-full items-center text-clip p-2">
          {thumbnail && (
            <div
              className="h-full w-full rounded bg-cover bg-no-repeat"
              style={{ backgroundImage: `url(${thumbnail})` }}
            />
          )}
        </div>
      </span>
      <div className="flex h-full flex-col justify-between  p-3">
        <div>
          <div className="flex items-center">
            <img
              alt={name}
              className="mr-2"
              height={24}
              src={`https://mento-space.nyc3.cdn.digitaloceanspaces.com/icons/${icon}.svg`}
              width={24}
            />
            <Typography
              className="overflow-hidden text-ellipsis"
              variant={mobile ? 'subtitle2' : 'subtitle1'}
            >
              {name}
            </Typography>
          </div>
        </div>
        <div className="mt-4 flex flex-row items-center justify-between text-black">
          <Ellipsis document={current} />
          <div className="flex items-center">
            {author && isOwnedBySomeoneElse && (
              <Avatar
                className="mr-1"
                firstName={author.firstName}
                lastName={author.lastName}
                size="s"
                src={author.avatarUrl}
              />
            )}
            <Typography className="text-cement-400" variant="caption">
              {formatDistanceToNow(new Date(updatedAt), { addSuffix: true })}
            </Typography>
          </div>
        </div>
      </div>
    </Paper>
  );
};

export default Item;
