import { useSurveyTemplate } from '@/hooks';
import { Typography } from '@/ui';

import Row from './Row';

import type { SurveyResult } from '@/types';
import type { FC } from 'react';

interface DataProps {
  results: SurveyResult;
}

const Data: FC<DataProps> = ({ results }) => {
  const { surveyTemplate, isSurveyTemplateLoading } = useSurveyTemplate();

  if (isSurveyTemplateLoading) return null;

  const data = Object.keys(results).map((key) => ({
    name: key,
    ...results[key],
    subtext:
      surveyTemplate.surveyQuestions.find(({ name }) => name === key)
        ?.subtext ?? '',
  }));

  if (!data.length) {
    return (
      <div className="flex h-full flex-col items-center justify-center rounded-lg bg-white p-10 lg:min-h-150 lg:min-w-224">
        <img
          alt="No Response"
          src="https://mento-space.nyc3.cdn.digitaloceanspaces.com/assets%2Fsurvey-no-response.svg"
          width="111px"
        />
        <Typography className="mt-7 text-cement-400" variant="body2">
          No Response
        </Typography>
      </div>
    );
  }

  return (
    <div className="flex min-h-150 min-w-224 flex-col rounded-lg bg-white p-10">
      {data.map((row, index) => (
        <Row
          key={`responses-${row.name}-${row.subtext}`}
          index={index}
          {...row}
        />
      ))}
    </div>
  );
};

export default Data;
