import { useQueryClient } from '@tanstack/react-query';
import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';

import { updateCompany } from '@/api';
import { AppContext } from '@/context';
import { AppMessageSeverities } from '@/types';
import { Button, Dialog, DialogActions, DialogContent, Divider } from '@/ui';
import { trimList } from '@/utils';

import CompanyAccess from './Access';
import VisionAndGoals from './Goals';
import CompanyProfile from './Profile';

import type { Company, CompanyForm, IDictionary } from '@/types';
import type { AxiosError } from 'axios';
import type { FC } from 'react';

const EditCompanyDialog: FC<{
  company: Company;
  onClose: () => void;
}> = ({ company: currentCompany, onClose }) => {
  const { setAppMessage } = useContext(AppContext);
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState(currentCompany);

  const onCompanyChange = (updated: Company) => setCompany(updated);

  const defaultValues: CompanyForm = {
    avatarUrl: '',
    culture: '',
    disallowedEmails: '',
    domains: '',
    emails: '',
    industry: '',
    linkedin: '',
    location: '',
    name: '',
    pocEmail: '',
    pocName: '',
    vision: '',
    website: '',
  };
  const { control, handleSubmit, setError } = useForm({
    defaultValues,
    mode: 'onBlur',
    values: {
      ...company,
      disallowedEmails: currentCompany.disallowedEmails.join(','),
      domains: currentCompany.domains.join(','),
      emails: currentCompany.emails.join(','),
    },
  });

  const onSave = () => {
    handleSubmit(async (data) => {
      setLoading(true);
      try {
        await updateCompany(company.id, {
          ...data,
          disallowedEmails: trimList(data.disallowedEmails),
          domains: trimList(data.domains),
          emails: trimList(data.emails),
          goals: company.goals.filter((g) => !!g.trim()),
        });
      } catch (e) {
        const error = e as AxiosError;
        if (error.response?.data) {
          const errors = error.response.data as IDictionary<string[]>;
          type FormKeys = keyof CompanyForm;
          Object.keys(defaultValues).forEach((field) => {
            if (errors[field]) {
              const errorValues = errors[field] || [];
              setError(field as FormKeys, {
                message: errorValues.join(','),
                type: 'custom',
              });
            }
          });
        } else {
          setAppMessage({
            message: 'Something went wrong',
            open: true,
            severity: AppMessageSeverities.Error,
          });
        }
        return;
      } finally {
        setLoading(false);
      }

      queryClient.invalidateQueries(['company', company.id]);
      onClose();
    })();
  };

  return (
    <Dialog maxWidth="md" fullWidth open>
      <DialogContent>
        <CompanyProfile companyId={company.id} control={control} />
        <Divider className="my-8" />
        <VisionAndGoals
          company={company}
          control={control}
          onCompanyChange={onCompanyChange}
        />
        <Divider className="my-8" />
        <CompanyAccess control={control} />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button loading={loading} variant="contained" onClick={onSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditCompanyDialog;
