import { useFlagBag } from '@happykit/flags/context';
import { useMediaQuery } from '@mui/material';
import clsx from 'clsx';

import { MEDIUM_BREAKPOINT } from '@/constants';
import { useCurrentUser, useView } from '@/hooks';
import { Skeleton, Typography } from '@/ui';
import { getWelcomeMessage } from '@/utils';

import type { FeatureFlags } from '@/types';

const WelcomeMessage = () => {
  const { isClient } = useView();
  const { user, isUserLoading } = useCurrentUser();
  const { flags } = useFlagBag<FeatureFlags>();
  // TODO: useTheme or change so we can do with only classnames
  const isMedium = useMediaQuery(MEDIUM_BREAKPOINT);

  if (isUserLoading || !user.id) {
    return (
      <Skeleton
        className={clsx('mb-10 h-14 w-80', {
          'md:mt-18': isClient,
        })}
      />
    );
  }

  return (
    <Typography
      className={clsx('md:display-sm mb-10 text-white', {
        'md:mt-18': isClient,
      })}
      variant={isMedium ? 'h3' : 'h4'}
    >
      {getWelcomeMessage(user, flags?.teams)}
    </Typography>
  );
};

export default WelcomeMessage;
