import { useNavigate } from 'react-router-dom';

import { Avatar, TableCell, TableRow } from '@/ui';
import { toDateTimeString } from '@/utils';

import type { Company } from '@/types';
import type { FC } from 'react';

import Menu from '@/components/Companies/List/Menu';

const Row: FC<Company> = ({
  avatarUrl,
  domains = [],
  id,
  isActive,
  usersCount,
  name = '',
  updatedAt,
}) => {
  const navigate = useNavigate();
  const onClick = () => navigate(`/coach/manage/companies/${id}`);

  return (
    <TableRow className="cursor-pointer hover:bg-sage-100">
      <TableCell className="flex items-center gap-2" onClick={onClick}>
        <Avatar icon="domain" src={avatarUrl} />
        {name}
      </TableCell>
      <TableCell onClick={onClick}>{domains.join(', ')}</TableCell>
      <TableCell onClick={onClick}>
        {isActive ? 'Active' : 'Inactive'}
      </TableCell>
      <TableCell className="hidden md:table-cell" onClick={onClick}>
        {usersCount} Members
      </TableCell>
      <TableCell className="hidden text-black md:table-cell" onClick={onClick}>
        {toDateTimeString(new Date(updatedAt))}
      </TableCell>
      <TableCell>
        <Menu companyId={id} isActive={isActive} usersCount={usersCount} />
      </TableCell>
    </TableRow>
  );
};

export default Row;
