import { Typography } from '@/ui';

import Choice from './Choice';
import Text from './Text';

import type { SummarySlide, SurveyResult } from '@/types';
import type { FC } from 'react';

interface SlidesProps {
  page: number;
  results: SurveyResult[];
  slides: SummarySlide[];
}

const Slides: FC<SlidesProps> = ({ page, results, slides }) => {
  const { name, subtext, options, text = '' } = slides[page] ?? {};
  return (
    <>
      <Typography color="text.primary" variant="h6">
        {name}
      </Typography>
      <Typography className="mb-16" color="text.secondary" variant="body2">
        {subtext}
      </Typography>
      {options?.length ? (
        <Choice question={slides[page] as SummarySlide} results={results} />
      ) : (
        <Text text={text} />
      )}
    </>
  );
};

export default Slides;
