import { clsx } from 'clsx';

import {
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@/ui';

import SummaryBase from './_SummaryBase';

import type { SummaryBaseProps } from './_SummaryBase';
import type { MessageThreadMessageItem } from '@/types';
import type { FC } from 'react';

type TextSummaryProps = SummaryBaseProps;

const TextSummary: FC<TextSummaryProps> = ({
  className,
  negativeLean,
  onConfirm,
  message,
}) => {
  const items = JSON.parse(message.items ?? '[]') as MessageThreadMessageItem[];

  if (!items.length) {
    return null;
  }

  const messageDeclined = message.confirmed === false; // message.confirmed can also be null, which means not answered

  const classes = clsx('list-summary', className);
  const titleWrapperClasses = clsx(
    'flex flex-col items-start justify-start gap-6 md:flex-row',
    {
      'text-cement-300': messageDeclined,
    },
  );
  const iconClasses = clsx(
    'inline-flex items-center justify-center rounded-full p-2 text-white',
    {
      [`bg-${message.color}-500`]: !messageDeclined,
      'bg-cement-300': messageDeclined,
    },
  );
  const listItemClasses = clsx(
    'border-0 border-b border-solid border-b-sage-200 py-3 last:border-b-0',
  );
  const listItemIconClasses = clsx(
    'mr-5 min-w-0 rounded bg-sage-200 p-0.5 text-base text-sage-400',
  );
  const listItemTextClasses = clsx('line-clamp-3 text-ellipsis');

  return (
    <SummaryBase
      className={classes}
      message={message}
      negativeLean={negativeLean}
      onConfirm={onConfirm}
    >
      <div className={titleWrapperClasses}>
        <span className={iconClasses}>
          <Icon fontSize="medium">{message.icon as string}</Icon>
        </span>
        <Typography className="hidden md:block" variant="h5">
          {message.description}
        </Typography>
        <Typography className="block md:hidden" variant="subtitle1">
          {message.description}
        </Typography>
      </div>
      <List className="md:ml-16">
        {items.map(({ option }) => (
          <ListItem key={option} className={listItemClasses}>
            <ListItemIcon className={listItemIconClasses}>
              <Icon fontSize="inherit">magic_button</Icon>
            </ListItemIcon>
            <Typography
              className={listItemTextClasses}
              component={ListItemText}
              variant="body1"
            >
              {option}
            </Typography>
          </ListItem>
        ))}
      </List>
    </SummaryBase>
  );
};

export default TextSummary;
