import MuiChip from '@mui/material/Chip';
import { alpha, styled } from '@mui/material/styles';
import { clsx } from 'clsx';

import type { ChipProps } from '@mui/material/Chip';
import type { FC } from 'react';

/* eslint-disable sort-keys,@typescript-eslint/no-magic-numbers -- CSS selectors shouldn't be alphabetically sorted. CSS values make little sense as constants. */
const StyledChip = styled(MuiChip)<ChipProps>(({ theme }) => ({
  /**
   * Default Chip Styles
   * -------------------
   */
  '&.MuiChip-colorDefault': {
    color: theme.palette.primary.dark,

    '.MuiAvatar-root': {
      backgroundColor: theme.extendedColors.cement[300],
      color: theme.palette.common.white,
    },

    '.MuiChip-deleteIcon': {
      color: alpha(theme.extendedColors.sage[600] as string, 0.26),

      '&:hover': {
        color: alpha(theme.extendedColors.sage[600] as string, 0.6),
      },
    },
  },

  // Filled Default
  '&.MuiChip-filledDefault': {
    backgroundColor: theme.palette.primary.tonal,

    '&.MuiChip-clickable:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },

  // Outlined Default
  '&.MuiChip-outlinedDefault': {
    borderColor: theme.palette.primary.light,

    '&.MuiChip-clickable:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.4),
    },
  },

  /**
   * Primary Chip Styles
   * -------------------
   */

  /**
   * Secondary Chip Styles
   * -------------------
   */
  '&.MuiChip-colorSecondary': {
    '.MuiAvatar-root': {
      color: theme.palette.common.white,
    },
  },

  // Outlined Secondary
  '&.MuiChip-outlinedSecondary': {
    borderColor: theme.palette.secondary.dark,
    color: theme.palette.secondary.dark,
  },

  /**
   * Error Chip Styles
   * -------------------
   */
  '&.MuiChip-colorError': {
    '.MuiAvatar-root': {
      backgroundColor: theme.palette.error.dark,
      color: theme.palette.common.white,
    },
  },

  /**
   * Warning Chip Styles
   * -------------------
   */
  '&.MuiChip-colorWarning': {
    '.MuiAvatar-root': {
      backgroundColor: theme.palette.warning.dark,
      color: theme.palette.common.white,
    },
  },

  /**
   * Info Chip Styles
   * -------------------
   */
  '&.MuiChip-colorInfo': {
    color: theme.palette.info.dark,

    '.MuiAvatar-root': {
      backgroundColor: theme.palette.info.main,
      color: theme.palette.common.white,
    },
  },

  // Filled Default
  '&.MuiChip-filledInfo': {
    backgroundColor: theme.palette.info.tonal,

    '&.MuiChip-clickable:hover': {
      backgroundColor: theme.extendedColors.bloom[400],
    },

    '.MuiChip-deleteIcon': {
      color: theme.palette.info.main,

      '&:hover': {
        color: theme.palette.info.dark,
      },
    },
  },

  /**
   * Success Chip Styles
   * -------------------
   */
  '&.MuiChip-colorSuccess': {
    '.MuiAvatar-root': {
      backgroundColor: theme.palette.success.dark,
      color: theme.palette.common.white,
    },
  },
}));
/* eslint-enable */

const Chip: FC<ChipProps> = ({ className, ...rest }) => {
  const classes = clsx('mnt-chip', className);

  return <StyledChip {...rest} className={classes} />;
};
Chip.displayName = 'Chip';

export default Chip;
export type { ChipProps };
