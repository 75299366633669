import { InputAdornment } from '@mui/material';
import { clsx } from 'clsx';
import { useState } from 'react';

import { useDebounce } from '../../../hooks/useDebounce';
import Icon from '../Icon';
import TextField from '../TextField';

import type { TextFieldProps } from '../TextField';
import type { FC } from 'react';

type SearchProps = TextFieldProps & {
  search: string;
  setSearch: (value: string) => void;
  timeout?: number;
};

export const Search: FC<SearchProps> = ({
  className = '',
  search,
  setSearch,
  timeout = 500,
  ...rest
}) => {
  const classes = clsx('mnt-search', className);
  const [value, setValue] = useState(search);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  useDebounce(value, setSearch, timeout);

  return (
    <TextField
      {...rest}
      className={classes}
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            <Icon>search</Icon>
          </InputAdornment>
        ),
      }}
      label="Search..."
      onChange={onChange}
    />
  );
};

export default Search;
export type { SearchProps };
