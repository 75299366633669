import { formatDistance } from 'date-fns';

import ConversationalMilestone from './ConversationalMilestone';

import type { MessageThreadMessage, User } from '@/types';
import type { FC } from 'react';

type MessageExtendableProps = {
  extendable: MessageThreadMessage['extendable'];
};

const MessageExtendable: FC<MessageExtendableProps> = ({ extendable }) => (
  <ConversationalMilestone
    author={extendable.author as User}
    dueDate={formatDistance(
      Date.parse(extendable.dueDate as string),
      new Date(),
      {
        addSuffix: true,
      },
    )}
    growthPlanId={extendable.growthPlanId}
    title={extendable.title}
  />
);

export default MessageExtendable;
