import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';

import { getUser } from '@/api';
import { AppContext } from '@/context';
import { useCurrentUser } from '@/hooks';

import type { User } from '@/types';

import Bio from '@/components/Profile/Bio';
import Details from '@/components/Profile/Client/Details';
import Growth from '@/components/Profile/Client/Growth';

// TODO Combine with src/pages/profile
const Profile = () => {
  const { user, isUserLoading } = useCurrentUser();
  const { selectedUserId } = useContext(AppContext);

  const { data: client, isLoading } = useQuery<User>(
    ['client', selectedUserId],
    () => getUser(selectedUserId),
    {
      enabled: !!selectedUserId && !!user.id,
    },
  );

  if (isLoading || isUserLoading || !client) {
    return null;
  }

  return (
    <div className="flex-1 flex-col bg-sage-100 md:flex">
      <div className="container mx-auto flex max-w-screen-lg">
        <div className="w-full p-4 lg:container lg:flex lg:flex-1 lg:flex-row-reverse">
          <div className="space-y-8 px-0 pb-4 lg:flex-1 lg:px-10">
            <Bio user={client} />
            <Growth client={client} />
          </div>
          <div className="-mx-8 -mb-4 bg-sage-200 px-8 pb-4 pt-2 lg:mx-0 lg:h-64 lg:w-64 lg:bg-transparent lg:px-0 lg:pb-6">
            <Details client={client} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
