import { ONE } from '@/constants';
import { SessionFeedbackType } from '@/types';

import type { Session, SessionStatus } from '@/types';

export const sessionNotesText = (
  current: string[] | string,
  other: string[] | string,
  name: string,
) => {
  let title = '';
  if (current.length) {
    title += 'A note from you';
  }
  if (other.length) {
    if (title) {
      title += ` & ${name}`;
    } else {
      title += `A note from ${name}`;
    }
  }
  return title;
};

export const sessionNotesInfo = (
  otherUserNotes: string,
  currentUserNotes: string,
  name: string,
) => sessionNotesText(currentUserNotes, otherUserNotes, name);

export const getFeedbackStyles = (feedBackStatus: string) => {
  switch (feedBackStatus) {
    case SessionFeedbackType.NEGATIVE:
      return {
        feedbackBg: '!bg-poppy-100',
        feedbackIcon:
          'https://mento-space.nyc3.digitaloceanspaces.com/assets/icon-vote-negative.svg',
      };
    case SessionFeedbackType.MIXED:
      return {
        feedbackBg: '!bg-sunny-100',
        feedbackIcon:
          'https://mento-space.nyc3.cdn.digitaloceanspaces.com/assets/okay-face.png',
      };
    case SessionFeedbackType.POSITIVE:
      return {
        feedbackBg: '!bg-sage-100',
        feedbackIcon:
          'https://mento-space.nyc3.digitaloceanspaces.com/assets/icon-vote-positive.svg',
      };
    default:
      return {
        feedbackBg: '',
        feedbackIcon: '',
      };
  }
};

export const groupByDate = (sessions: Record<string, Session[]>) => {
  const newData: Record<string, Session[]> = {};
  for (const key of Object.keys(sessions)) {
    const [month, year] = key.split('_');
    if (month && year) {
      const newKey = new Date(
        parseInt(year, 10),
        parseInt(month, 10) - ONE,
        ONE,
      ).toLocaleString('en-us', { month: 'long', year: 'numeric' });
      newData[newKey] = sessions[key] as Session[];
    }
  }
  return newData;
};

export const groupForUpcoming = (
  sessions: Record<string, Session[]>,
  page: number,
) => {
  let nextSession = {};
  const upcomingSessions = { ...sessions };

  if (!Object.keys(sessions).length || page !== ONE) {
    return { nextSession, upcomingSessions };
  }
  const keys = Object.keys(sessions);

  const firstDateKey = keys[0];
  if (!firstDateKey) return { nextSession, upcomingSessions };

  // Remove the first row of data from the first key
  const session = upcomingSessions[firstDateKey]?.slice(1);
  nextSession = { 'Next session': [upcomingSessions[firstDateKey]?.[0]] };
  upcomingSessions[firstDateKey] = session || [];
  return { nextSession, upcomingSessions };
};

export interface GetUserSessionsOptions {
  coachId?: string;
  limit?: number;
  maxDate?: string;
  minDate?: string;
  order?: string;
  page?: number;
  statuses?: Array<SessionStatus>;
  userId: string;
}

export const mapSessionQueryParams = (params: GetUserSessionsOptions) => {
  const {
    coachId,
    userId,
    maxDate,
    minDate,
    order,
    limit,
    page = 1,
    statuses = [],
  } = params;

  const args = statuses.map((status) => ['status[]', status]);
  args.push(['user_id', userId]);
  args.push(['page', page.toString()]);
  if (coachId) args.push(['coach_id', coachId]);
  if (maxDate) args.push(['max_date', maxDate]);
  if (minDate) args.push(['min_date', minDate]);
  if (order) args.push(['order', order]);
  if (limit) args.push(['limit', limit.toString()]);
  return new URLSearchParams(args).toString();
};
