import { Card, CardContent, Divider, Skeleton } from '@/ui';

const SessionsLoading = () => (
  <Card>
    <CardContent>
      <Skeleton className="mb-2" width="20%" />
      <div className="flex">
        <Skeleton
          className="shrink-0"
          height={48}
          variant="circular"
          width={48}
        />
        <div className="ml-5 w-full">
          <Skeleton width="50%" />
          <Skeleton width="30%" />
        </div>
      </div>
      <Divider className="-mx-6 my-6" />
      <Skeleton className="mb-2" width="20%" />
      <div className="flex">
        <Skeleton
          className="shrink-0"
          height={48}
          variant="circular"
          width={48}
        />
        <div className="ml-5 w-full">
          <Skeleton width="50%" />
          <Skeleton width="30%" />
        </div>
      </div>
    </CardContent>
  </Card>
);

export default SessionsLoading;
