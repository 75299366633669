import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { getDocument } from '@/api';
import { QUERY_KEY_DOCUMENT } from '@/constants';

import { useView } from './useView';

import type { Document } from '@/types';

export const useDocument = (isTemplate: boolean, id?: string) => {
  const navigate = useNavigate();
  const { documentId = '', templateId = '' } = useParams();
  const { clientId } = useView();
  const idToUse = id || (isTemplate ? templateId : documentId);
  const onSuccess = (data: Document) => {
    let path = '/documents';
    if (clientId) {
      path = `/members/${clientId}/documents`;
    }

    if (!data.id) {
      navigate(path);
    }
  };

  const {
    data: currentDocument = {} as Document,
    isLoading: isDocumentLoading,
    refetch: refetchDocument,
  } = useQuery({
    enabled: !!idToUse,
    onSuccess,
    queryFn: () => getDocument({ id: idToUse }),
    queryKey: [QUERY_KEY_DOCUMENT, idToUse],
  });

  return { currentDocument, isDocumentLoading, refetchDocument };
};
