import { captureException } from '@sentry/react';
import { getTimeZones } from '@vvo/tzdb';
import { useState } from 'react';

import { updateUserProfile } from '@/api';
import { SMALL_BREAKPOINT } from '@/constants';
import { useCurrentUser } from '@/hooks';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
} from '@/ui';

import type { ChangeEvent } from 'react';

const TimezoneModal = () => {
  const { user, isUserLoading, refetchUser } = useCurrentUser();
  const [saving, setSaving] = useState(false);
  const [timezone, setTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone || '',
  );
  const isSmall = useMediaQuery(SMALL_BREAKPOINT);

  const onClick = async () => {
    setSaving(true);
    try {
      await updateUserProfile(user.id, { timezone });
      await refetchUser();
    } catch (error) {
      captureException(error);
    } finally {
      setSaving(false);
    }
  };

  const onTimezoneChange = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => setTimezone(e.target.value);

  const options = getTimeZones().map((t) => {
    const [time] = t.currentTimeFormat.split(' ');

    return {
      label: `(GMT${time}) ${t.mainCities[0]}`,
      value: t.name,
    };
  });

  if (isUserLoading) return null;

  return (
    <Dialog open>
      <DialogTitle className="bg-sage-100 px-6 pb-0 pt-4 md:px-12 md:pt-8">
        <Typography variant="overline">Mento coaching</Typography>
      </DialogTitle>
      <DialogContent className="flex flex-col bg-sage-100 p-0">
        <div className="flex flex-col px-6 md:px-12">
          <Typography variant={isSmall ? 'h4' : 'h3'}>
            Welcome, {user.firstName}!
          </Typography>
          <Typography className="mb-2 mt-4" variant="subtitle1">
            We’re thrilled to match you with just the right coach.
          </Typography>
        </div>
        <img
          alt="timezone-hero"
          src="https://mento-space.nyc3.cdn.digitaloceanspaces.com/assets/new_welcome_tz_select.png"
        />
        <div className="flex flex-col gap-4 px-6 md:px-12">
          <Typography>
            Before we dive in, please set your default timezone. Mento will use
            this to communicate times correctly for sessions, community events,
            and support scheduling requests.
          </Typography>
          <TextField
            label="Default timezone"
            placeholder="Default timezone"
            value={timezone}
            fullWidth
            select
            onChange={onTimezoneChange}
          >
            {options.map(({ label, value }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </DialogContent>
      <DialogActions className="border-y-0 bg-sage-100 pb-6 pr-6 pt-4 md:pb-12 md:pr-12">
        <Button
          color="primary"
          disabled={!timezone}
          loading={saving}
          variant="contained"
          onClick={onClick}
        >
          Set timezone
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TimezoneModal;
