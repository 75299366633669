import { useNavigate } from 'react-router-dom';

import { useView } from '@/hooks';
import { Button, Typography } from '@/ui';
import { track } from '@/utils';

const NotFound = () => {
  const { isCoach } = useView();
  const navigate = useNavigate();
  const homePath = isCoach ? '/coach' : '/m';
  track('Viewed /404');

  return (
    <main className="flex h-screen w-full flex-1 flex-col items-center justify-center">
      <div className="p-8">
        <div className="flex shrink-0 justify-center">
          <img
            alt="logo"
            height="48"
            src="https://mento-space.nyc3.digitaloceanspaces.com/logo.svg"
            width="180"
          />
        </div>
        <div className="py-8">
          <div className="text-center">
            <Typography
              className="font-semibold uppercase tracking-wide text-sage-500"
              variant="caption"
            >
              404 error
            </Typography>
            <Typography
              className="mt-2 font-extrabold tracking-tight text-gray-900"
              variant="h4"
            >
              Page not found.
            </Typography>
            <Typography className="mt-2 text-gray-500">
              Sorry, we couldn't find the page you're looking for.
            </Typography>
            <div className="mt-6">
              <Button
                className="text-base font-medium text-sage-500 hover:text-sage-500"
                onClick={() => navigate(homePath, { replace: true })}
              >
                Go back to dashboard
                <span aria-hidden="true"> &nbsp;&rarr;</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default NotFound;
