import { captureException } from '@sentry/react';
import { useQueryClient } from '@tanstack/react-query';
import { useContext, useState } from 'react';

import { uploadDocument } from '@/api';
import { AppContext } from '@/context';
import { useCurrentUser, useView } from '@/hooks';
import { AppMessageSeverities } from '@/types';
import { Dialog, DialogContent, Typography } from '@/ui';
import { toDocumentUrl, track } from '@/utils';

import Drop from './Drop';
import Footer from './Footer';

import type { FC } from 'react';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const Modal: FC<ModalProps> = ({ isOpen, onClose }) => {
  const { user, isUserLoading } = useCurrentUser();
  const { isOverview, selectedUser, isViewLoading } = useView();
  const viewUser = isOverview ? selectedUser : user;

  const { setAppMessage } = useContext(AppContext);
  const queryClient = useQueryClient();

  const [processing, setProcessing] = useState(false);
  const [preparedFiles, setPreparedFiles] = useState<File[]>([]);

  const onClick = async () => {
    try {
      setProcessing(true);
      const [file] = preparedFiles;
      if (!file) {
        setProcessing(false);
        return;
      }

      if (!viewUser.folderId) {
        setAppMessage({
          message: 'Cannot upload files to user. Please contact support',
          open: true,
          severity: AppMessageSeverities.Error,
        });

        captureException(
          new Error(
            `Failed to upload document for user: ${viewUser.id} due to not having folderId`,
          ),
        );

        return;
      }

      const { mimeType, reference } = await uploadDocument({
        file,
        userId: viewUser.id,
      });
      await queryClient.invalidateQueries(['documents']);
      track('Uploaded File');

      const url = toDocumentUrl(mimeType, reference);
      window.open(url, '_blank');
      setPreparedFiles([]);
    } catch (e) {
      setAppMessage({
        message: 'We’re sorry, there was an issue uploading the file',
        open: true,
        severity: AppMessageSeverities.Error,
      });
      captureException(e);
    } finally {
      setProcessing(false);
      onClose();
    }
  };

  // TODO: Loading state
  if (isUserLoading || isViewLoading) return null;

  return (
    <Dialog maxWidth="md" open={isOpen} fullWidth onClose={onClose}>
      <DialogContent className="min-h-50 p-12" dividers>
        <Typography variant="h4">Upload</Typography>
        <Drop
          preparedFile={preparedFiles[0] ?? ({} as File)}
          processing={processing}
          setPreparedFile={setPreparedFiles}
        />
      </DialogContent>
      <Footer
        noFiles={!preparedFiles.length}
        processing={processing}
        onClick={onClick}
        onClose={onClose}
      />
    </Dialog>
  );
};

export default Modal;
