import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { getUser } from '@/api';
import {
  useAddThreadMessage,
  useCurrentUser,
  useGetThread,
  useGetThreadResponses,
} from '@/hooks';
import { MessageRole } from '@/types';
import { Button, ChatBubble, Paper, TextField, Typography } from '@/ui';

import type { User } from '@/types';
import type { ChangeEvent } from 'react';

/*
 * Very very prototype API for admins only to interact with an AI-based chat bot thread that layers in
 * Mento-specific context.
 *
 * Treat this as throwaway code -- it is a very thin shell over an API just to give product/design and
 * some coaching stakeholders something to play with and start experimenting.
 *
 * It displays the context being used by the assistant, and the progress of a single chat. There is no history
 * beyond this chat, or anything else.
 *
 * We will probably iterate on even this prototype to allow more fine-grained experimentation with context, but
 * this is just a first stab to get brains moving and us able to tinker.
 *
 * Note: This is left deliberately ugly and sparse so that the fidelity of the UI does not fool our stakeholders
 * into thinking the backend is more mature than it is.
 */

const CoachAiThread = () => {
  const { threadId = '' } = useParams();
  const [currentMessage, setCurrentMessage] = useState('');
  const [loadingReply, setLoadingReply] = useState(false);
  const navigate = useNavigate();
  const { user } = useCurrentUser();

  const onCurrentMessageChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCurrentMessage(e.target.value || '');
  };

  const sendMessageToThread = useAddThreadMessage(threadId);
  const getThreadResponses = useGetThreadResponses(threadId);

  useEffect(() => {
    if (user.id && !user.superAdmin) {
      navigate('/');
    }
  }, [user.id, user.superAdmin]);

  const sendMessage = async (event: React.FormEvent) => {
    event.preventDefault();
    const message = currentMessage;
    setCurrentMessage('');
    await sendMessageToThread.mutateAsync(message);
    setLoadingReply(true);
    await getThreadResponses.mutateAsync();
    setLoadingReply(false);
  };

  const { data: thread, isLoading } = useGetThread(threadId);

  const { data: selectedUser, isLoading: isLoadingUser } = useQuery<User>(
    ['selectedUser', thread?.metadata.userId],
    () => getUser(thread?.metadata.userId || ''),
    {
      enabled: !!thread,
    },
  );

  if (isLoading || isLoadingUser || !thread || !selectedUser) {
    return <div>Loading</div>;
  }

  return (
    <div className="px-4">
      <div>
        <h3>Context and settings</h3>
        <table>
          <tr>
            <th>User ID</th>
            <td>
              <Typography variant="caption">{selectedUser.id}</Typography>
            </td>
          </tr>
          <tr>
            <th>Assistant level instructions</th>
            <td>
              <Typography variant="caption">{thread.instructions}</Typography>
            </td>
          </tr>
          <tr>
            <th>User specific instructions</th>
            <td>
              <Typography variant="caption">
                {thread.additionalInstructions}
              </Typography>
            </td>
          </tr>
        </table>
      </div>
      <div className="container mx-auto">
        <Paper className="m-4 p-4">
          <h3>Conversation</h3>
          {thread.messages.map((message) => (
            <div key={message.id}>
              {message.content
                .filter((c) => !!c.text)
                .map((content) => (
                  <ChatBubble
                    key={content.text?.value}
                    avatarUrl={
                      message.role === MessageRole.USER
                        ? selectedUser.avatarUrl
                        : null
                    }
                    className="my-4"
                    message={content.text?.value || 'UNHANDLED MESSAGE'}
                  />
                ))}
            </div>
          ))}
          {loadingReply && (
            <div>
              <ChatBubble className="my-4" message="..." />
            </div>
          )}
          <form onSubmit={sendMessage}>
            <TextField
              className="mb-4"
              label="Message"
              value={currentMessage}
              fullWidth
              onChange={onCurrentMessageChange}
            />
            <Button type="submit" variant="outlined">
              Send Message
            </Button>
          </form>
        </Paper>
      </div>
    </div>
  );
};

export default CoachAiThread;
