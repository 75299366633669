import { JOB_INDUSTRIES } from '@/constants';
import { CreatableSelect, Grid, Typography } from '@/ui';

import type { CoachProfile, UserEmployment } from '@/types';
import type { FC } from 'react';

import PastCompanies from '@/components/Profile/Coach/Edit/Coach/Experience/PastCompanies';

interface ProfessionalExperienceProps {
  coachProfile: CoachProfile;
  employments: UserEmployment[];
  onCoachProfileChange: (updated: CoachProfile) => void;
  onCompanyChange: (updated: UserEmployment[]) => void;
}

const ProfessionalExperience: FC<ProfessionalExperienceProps> = ({
  coachProfile,
  employments,
  onCompanyChange,
  onCoachProfileChange,
}) => {
  const onUpdate = (changes: Partial<CoachProfile>) =>
    onCoachProfileChange({ ...coachProfile, ...changes });

  const onPastCompaniesChange = (updated: UserEmployment[]) =>
    onCompanyChange([...updated]);
  const onIndustryChange = (value: string[]) => onUpdate({ industries: value });

  return (
    <Grid spacing={4} container>
      <Grid md={5} xs={12} item>
        <Typography className="mb-2" variant="h4">
          Professional experience
        </Typography>
        <Typography className="mb-1" variant="body1">
          Please add your 3-5 most recent roles and professional fields that
          apply based on your experience.
        </Typography>
      </Grid>
      <Grid className="mt-1 flex flex-col gap-1" md={7} xs={12} item>
        <div className="mb-8">
          <PastCompanies
            pastCompanies={employments}
            onChange={onPastCompaniesChange}
          />
        </div>
        <CreatableSelect
          helperText="Choose all that apply"
          label="Professional fields"
          options={JOB_INDUSTRIES}
          placeholder="Professional fields"
          value={coachProfile.industries}
          multiple
          onChange={onIndustryChange}
        />
      </Grid>
    </Grid>
  );
};

export default ProfessionalExperience;
