import { Card, CardActionArea, CardContent, Icon, Typography } from '@/ui';

import type { FC, ReactNode } from 'react';

interface SidebarCardProps {
  children: ReactNode;
  className?: string;
  icon: string;
  isClickable: boolean;
  onChange: () => void;
  title: string;
}

const SidebarCard: FC<SidebarCardProps> = ({
  children,
  className,
  icon,
  isClickable,
  onChange,
  title,
}) => {
  const content = (
    <CardContent className={`group px-3 py-4 ${className}`}>
      <div className="mb-2 flex min-h-10 items-center">
        <Icon>{icon}</Icon>
        <Typography className="ml-2" variant="subtitle1">
          {title}
        </Typography>
      </div>

      {children}
    </CardContent>
  );

  return (
    <Card>
      {isClickable ? (
        <CardActionArea onClick={onChange}>{content}</CardActionArea>
      ) : (
        content
      )}
    </Card>
  );
};

export default SidebarCard;
