import { useState } from 'react';

import { SessionCancelChoice } from '@/constants';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@/ui';

import type { Session } from '@/types';
import type { ChangeEvent, FC } from 'react';

interface CancelPromptProps {
  onClose: () => void;
  onContinue: (choice: SessionCancelChoice) => void;
  session: Session;
}
const CancelPrompt: FC<CancelPromptProps> = ({
  session,
  onContinue,
  onClose,
}) => {
  const [pendingSessionCancelChoice, setPendingSessionCancelChoice] =
    useState<SessionCancelChoice>(SessionCancelChoice.SINGLE);

  const onChange = (_e: ChangeEvent, value: string) =>
    setPendingSessionCancelChoice(value as unknown as SessionCancelChoice);

  const coachName = session.coach.fullName;
  const withName = coachName ? `with ${coachName}` : '';

  const onPendingContinue = () => onContinue(pendingSessionCancelChoice);

  return (
    <Dialog open>
      <DialogContent>
        <Typography className="mb-1.5" variant="h5">
          Cancel your Bi-Weekly coaching session {withName}
        </Typography>
        <Typography className="text-cement-400">
          Every other{' '}
          {new Intl.DateTimeFormat('en-US', {
            weekday: 'long',
            hour: 'numeric',
            minute: '2-digit',
          }).format(new Date(session.startedAt))}
          {` - ${new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: '2-digit' }).format(new Date(session.endedAt))}`}
        </Typography>

        <RadioGroup
          className="mt-4"
          value={pendingSessionCancelChoice}
          onChange={onChange}
        >
          <FormControlLabel
            control={<Radio />}
            label="Only this session"
            value={SessionCancelChoice.SINGLE}
          />
          <FormControlLabel
            control={<Radio />}
            label="All remaining sessions"
            value={SessionCancelChoice.RECURRING}
          />
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onPendingContinue}>
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CancelPrompt;
