const BoulderPaths = [
  {
    fullPath: '',
    paths: [
      'M3.74756 42.2295C3.85041 41.5003 4.52708 40.9922 5.25892 41.0947L38.4855 45.7478C39.2173 45.8502 39.7272 46.5245 39.6244 47.2537C39.5215 47.983 38.8448 48.4911 38.113 48.3886L4.88645 43.7355C4.15461 43.633 3.64471 42.9588 3.74756 42.2295Z',
      'M38.7707 45.8207C39.4627 46.0793 39.8133 46.8479 39.5537 47.5374L30.0036 72.913C29.7441 73.6023 28.9727 73.9519 28.2808 73.6934C27.5888 73.4349 27.2382 72.6663 27.4977 71.9765L37.0479 46.601C37.3074 45.9115 38.0787 45.5622 38.7707 45.8207Z',
      'M4.14497 67.9761C4.27751 67.2515 4.97435 66.7712 5.70141 66.9032L28.9895 71.1336C29.7165 71.2656 30.1985 71.9598 30.0659 72.6844C29.9333 73.409 29.2365 73.8893 28.5095 73.7573L5.22145 69.5269C4.49439 69.3949 4.01244 68.7007 4.14497 67.9761Z',
      'M5.05246 41.0821C5.79141 41.071 6.39942 41.659 6.41055 42.3953L6.7989 68.1936C6.80997 68.93 6.21992 69.5362 5.48096 69.5474C4.74201 69.5579 4.13398 68.9703 4.1229 68.2339L3.73453 42.4353C3.72345 41.699 4.3135 41.0932 5.05246 41.0821Z',
      'M37.3058 47.044L5.47852 42.9888V68.0479L29.193 71.5835L37.3058 47.044Z',
      'M11.1249 0.82422L5.79883 19.5475L12.1642 38.0143H24.5052L33.9883 36.0906L38.1453 10.3141L32.5594 0.824219L11.1249 0.82422Z',
      'M45.1785 16.9549C45.4881 16.3111 46.2628 16.0393 46.9089 16.3478L57.5535 21.4308C58.1996 21.7393 58.4724 22.5113 58.1628 23.1551C57.8532 23.7988 57.0784 24.0706 56.4323 23.7621L45.7877 18.6792C45.1416 18.3707 44.8689 17.5987 45.1785 16.9549Z',
      'M68.2296 10.0875C68.7633 10.5637 68.8087 11.3809 68.331 11.9128L57.9591 23.4584C57.4813 23.9904 56.6612 24.0356 56.1274 23.5595C55.5935 23.0833 55.5482 22.2661 56.026 21.7342L66.3977 10.1886C66.8754 9.65663 67.6959 9.61145 68.2296 10.0875Z',
      'M66.1971 10.4857C66.51 9.84354 67.2863 9.57596 67.9307 9.88799L77.863 14.6971C78.5074 15.0091 78.7761 15.7826 78.4626 16.4247C78.1497 17.0668 77.3734 17.3344 76.729 17.0223L66.7968 12.2132C66.1523 11.9012 65.8842 11.1278 66.1971 10.4857Z',
      'M77.5477 14.5914C78.2506 14.7284 78.7091 15.4074 78.5716 16.108L74.8399 35.1271C74.7024 35.8277 74.0213 36.2847 73.3184 36.1477C72.6149 36.0107 72.1564 35.3317 72.2939 34.6311L76.0255 15.612C76.163 14.9114 76.8442 14.4544 77.5477 14.5914Z',
      'M61.0516 33.1472C61.1501 32.4401 61.8052 31.9465 62.5148 32.0447L73.744 33.5985C74.4538 33.6967 74.9489 34.3495 74.85 35.0566C74.7517 35.7637 74.0967 36.2573 73.3869 36.1591L62.158 34.6053C61.4484 34.5071 60.953 33.8543 61.0516 33.1472Z',
      'M63.2239 32.3814C63.747 32.8692 63.7738 33.6872 63.2842 34.2084L55.3791 42.6241C54.8895 43.1453 54.0686 43.1723 53.5455 42.6844C53.0225 42.1966 52.9953 41.3786 53.4849 40.8574L61.3902 32.4418C61.8798 31.9206 62.7008 31.8935 63.2239 32.3814Z',
      'M39.6436 34.2959C39.9667 33.6588 40.747 33.4032 41.3864 33.7251L55.0168 40.587C55.6563 40.9088 55.9128 41.6864 55.5897 42.3235C55.2667 42.9607 54.4864 43.2164 53.8469 42.8944L40.2165 36.0325C39.577 35.7107 39.3205 34.9331 39.6436 34.2959Z',
      'M46.7427 16.2816C47.4254 16.4982 47.8026 17.2252 47.5853 17.9054L42.0376 35.2709C41.8203 35.9512 41.0907 36.3271 40.408 36.1105C39.7253 35.894 39.3481 35.167 39.5654 34.4868L45.113 17.1212C45.3304 16.441 46.06 16.065 46.7427 16.2816Z',
      'M77.1604 16.1579L67.7718 11.7922L56.9232 22.3946L45.8656 17.821L41.4844 35.0759L54.6282 41.7283L63.1824 34.2443H74.0309L77.1604 16.1579Z',
      'M55.8185 71.9409C62.4644 71.9409 67.8519 66.5725 67.8519 59.9503C67.8519 53.3281 62.4644 47.9597 55.8185 47.9597C49.1727 47.9597 43.7852 53.3281 43.7852 59.9503C43.7852 66.5725 49.1727 71.9409 55.8185 71.9409Z',
    ],
    viewBox: '0 0 79 75',
  },
  {
    fullPath: 'M0,24a24,24 0 1,0 48,0a24,24 0 1,0 -48,0',
    paths: [
      'M24.5 4C13.1782 4 4 13.1782 4 24.5C4 35.8218 13.1782 45 24.5 45C35.8218 45 45 35.8218 45 24.5C45 13.1782 35.8218 4 24.5 4ZM0 24.5C0 10.969 10.969 0 24.5 0C38.031 0 49 10.969 49 24.5C49 38.031 38.031 49 24.5 49C10.969 49 0 38.031 0 24.5Z',
    ],
    viewBox: '0 0 49 49',
  },
  {
    fullPath:
      'M9.8446 2.94711C1.0887 12.5574 1.00939 27.4263 10.002 37.1279C19.0049 46.8326 33.835 47.8797 44.079 39.8662L9.8446 2.94711Z',
    paths: [
      'M46.1984 40.868C45.3895 41.6229 44.1199 41.5655 43.365 40.7566L8.70676 3.36984C7.9519 2.56097 7.99969 1.28837 8.80856 0.533498C9.61748 -0.221366 10.8871 -0.164022 11.642 0.644848L46.3002 38.0317C47.055 38.8405 47.0073 40.1131 46.1984 40.868Z',
      'M11.6333 0.64219C12.3882 1.45106 12.3308 2.72071 11.5249 3.46603C2.00614 12.2894 1.44269 27.1555 10.2661 36.6742C19.0894 46.193 33.9555 46.7564 43.4743 37.9331C44.2832 37.1782 45.5528 37.2356 46.2981 38.0415C47.053 38.8504 46.9956 40.12 46.1897 40.8653C35.0562 51.1888 17.6544 50.5328 7.32129 39.3963C-2.98972 28.2562 -2.33364 10.8544 8.80944 0.53379C9.61836 -0.221075 10.8784 -0.166683 11.6333 0.64219Z',
    ],
    viewBox: '0 0 47 49',
  },
  {
    fullPath:
      'M36.9451 3.95001C30.5851 4.98001 22.6051 7.49001 15.8251 13.38C8.97508 19.33 5.83508 25.52 4.38508 30.18C3.65508 32.51 3.35508 34.47 3.23508 35.83C3.22508 35.95 3.21508 36.06 3.20508 36.17L35.5751 39.79L45.3351 3.29001C45.1651 3.29001 44.9851 3.29001 44.7951 3.29001C42.8551 3.30001 40.1151 3.44001 36.9451 3.95001Z',
    paths: [
      'M47.6053 0.179994C48.6753 0.469994 49.3053 1.55999 49.0253 2.62999L38.5653 41.78C38.2753 42.85 37.1853 43.48 36.1153 43.2C35.0453 42.92 34.4153 41.82 34.6953 40.75L45.1553 1.59999C45.4453 0.529994 46.5353 -0.100006 47.6053 0.179994V0.179994Z',
      'M47.2839 0.12C48.3839 0.23 49.1839 1.21 49.0739 2.31C48.9639 3.41 47.9839 4.21 46.8839 4.1H46.8539C46.8539 4.1 46.7839 4.1 46.7239 4.09C46.6039 4.09 46.4139 4.07 46.1639 4.05C45.6639 4.02 44.9339 4 44.0039 4C42.1439 4.01 39.5039 4.14 36.4639 4.64C30.3539 5.63 22.6839 8.05 16.1639 13.71C9.58391 19.42 6.56391 25.38 5.17391 29.86C4.47391 32.1 4.18391 33.99 4.07391 35.29C4.01391 35.94 4.00391 36.44 4.00391 36.78C4.00391 36.95 4.00391 37.23 4.00391 37.23C4.07391 38.33 3.25391 39.28 2.15391 39.36C1.05391 39.44 0.0939062 38.61 0.0139062 37.51V37.44C0.0139062 37.44 0.0139062 37.35 0.00390625 37.29C0.00390625 37.17 0.00390625 36.99 0.00390625 36.77C0.00390625 36.33 0.0239063 35.71 0.0939063 34.94C0.233906 33.4 0.573906 31.23 1.36391 28.68C2.95391 23.57 6.35391 16.95 13.5539 10.7C20.7939 4.38 29.2539 1.75 35.8139 0.69C39.1039 0.16 41.9439 0.01 43.9739 0C44.9939 0 45.8039 0.02 46.3739 0.05C46.6539 0.06 46.8839 0.08 47.0339 0.09C47.1139 0.09 47.1739 0.1 47.2139 0.11H47.2839',
      'M0.00400024 37.16C0.124 36.06 1.114 35.27 2.214 35.4L36.864 39.28C37.964 39.4 38.754 40.39 38.624 41.49C38.504 42.59 37.514 43.38 36.414 43.25L1.774 39.37C0.674 39.25 -0.116 38.26 0.0140002 37.16H0.00400024Z',
    ],
    viewBox: '0 0 56 43',
  },
  {
    fullPath:
      'M48.4701 8.71034L1.83008 2.00034L2.29008 40.8003L34.2401 47.2503L48.4701 8.71034Z',
    paths: [
      'M0.610127 40.3403C0.790127 39.2503 1.75013 38.5303 2.75013 38.7303L34.4201 45.0803C35.4201 45.2803 36.2601 46.3503 36.0701 47.4303C35.8901 48.5203 34.7601 49.2103 33.7601 49.0103L2.10013 42.6603C1.10013 42.4603 0.430127 41.4203 0.620127 40.3303L0.610127 40.3403Z',
      'M49.1092 7.11034C50.1392 7.50034 50.6692 8.65034 50.2792 9.68034L36.0092 47.7403C35.6192 48.7703 34.4692 49.3003 33.4292 48.9103C32.3992 48.5203 31.8692 47.3703 32.2592 46.3303L46.5292 8.28034C46.9192 7.25034 48.0692 6.72034 49.0992 7.11034H49.1092Z',
      'M0.0197711 1.72035C0.159771 0.630347 1.10977 -0.129653 2.13977 0.020347L48.7898 7.00035C49.8198 7.15035 50.5298 8.16035 50.3898 9.26035C50.2498 10.3503 49.2998 11.1203 48.2698 10.9603L1.61977 3.98035C0.589771 3.83035 -0.130229 2.82035 0.0197711 1.72035Z',
      'M1.97008 0.000330605C3.07008 -0.0196694 3.98008 0.870331 4.00008 1.97033L4.58008 40.6703C4.60008 41.7703 3.71008 42.6803 2.61008 42.7003C1.51008 42.7203 0.600084 41.8303 0.580084 40.7303L8.43888e-05 2.03033C-0.00991561 0.930331 0.870084 0.0203306 1.97008 0.000330605Z',
    ],
    viewBox: '0 0 51 50',
  },
  {
    fullPath:
      'M58.9695 19.48L10.6395 3.10001L19.7495 31.24L3.26953 55.44L45.9295 47.14L58.9595 19.49L58.9695 19.48Z',
    paths: [
      'M61.0495 20.15C61.4995 19.14 61.0395 17.96 60.0295 17.51L11.1995 1.17C10.1895 0.720003 9.0095 1.18 8.5595 2.19C8.1095 3.2 8.5695 4.38 9.5795 4.83L58.4095 21.17C59.4195 21.62 60.5995 21.16 61.0495 20.15V20.15Z',
      'M44.6608 49.18C45.6008 49.77 46.8308 49.48 47.4208 48.54L60.9308 20.43C61.4808 19.48 61.2408 18.24 60.3008 17.66C59.3608 17.07 58.1208 17.38 57.5308 18.31L44.0208 46.42C43.4308 47.36 43.7208 48.59 44.6608 49.18V49.18Z',
      'M1.03063 56.75C1.23063 57.83 2.17063 58.46 3.22063 58.33L46.1306 49.44C47.1406 49.23 47.8306 48.29 47.6106 47.1C47.4106 46.02 46.4406 45.31 45.4206 45.52L2.51063 54.4C1.50063 54.61 0.840635 55.66 1.03063 56.74V56.75Z',
      'M1.90005 58.06C2.82005 58.67 4.11005 58.22 4.67005 57.49L21.09 32.54C21.7 31.62 21.44 30.38 20.52 29.77C19.6 29.16 18.36 29.42 17.75 30.34L1.29005 55.29C0.750045 56.21 0.970045 57.45 1.90005 58.06V58.06Z',
      'M20.0308 33.32C21.0808 32.99 21.6708 31.86 21.3308 30.81L12.3008 2.39999C11.9708 1.34999 10.8408 0.759993 9.79085 1.09999C8.74085 1.42999 8.15085 2.55999 8.49085 3.60999L17.5108 32.02C17.8408 33.07 18.9708 33.66 20.0208 33.32H20.0308Z',
    ],
    viewBox: '0 0 62 59',
  },
  {
    fullPath:
      'M27.5167 2.00003L2.99609 11.1935V41.2564L39.8257 49L55.9961 41.6189V11.2704L27.5167 2.00003Z',
    paths: [
      'M57.8296 40.6731C58.2796 41.6831 57.8296 42.8631 56.8296 43.3131L40.1096 50.8231C39.0996 51.2731 37.9196 50.8231 37.4696 49.8231C37.0196 48.8231 37.4696 47.6331 38.4696 47.1831L55.1896 39.6731C56.1996 39.2231 57.3796 39.6731 57.8296 40.6731Z',
      'M56 9.10305C57.1 9.10305 58 10.0031 58 11.1031V41.4931C58 42.5931 57.1 43.4931 56 43.4931C54.9 43.4931 54 42.5931 54 41.4931V11.1031C54 10.0031 54.9 9.10305 56 9.10305Z',
      'M25.7203 1.39305C26.0603 0.343054 27.1803 -0.236946 28.2403 0.103054L56.6203 9.20305C57.6703 9.54305 58.2503 10.6631 57.9103 11.7231C57.5703 12.7731 56.4503 13.3531 55.3903 13.0131L27.0103 3.91305C25.9603 3.57305 25.3803 2.45305 25.7203 1.39305V1.39305Z',
      'M29.4991 1.31306C29.8791 2.35306 29.3491 3.50306 28.3191 3.88306L3.68913 12.9731C2.64913 13.3531 1.49913 12.8231 1.11913 11.7931C0.739128 10.7531 1.26913 9.60306 2.29913 9.22306L26.9291 0.123061C27.9691 -0.256939 29.1191 0.273061 29.4991 1.30306V1.31306Z',
      'M3 9.10305C4.1 9.10305 5 10.0031 5 11.1031V41.4931C5 42.5931 4.1 43.4931 3 43.4931C1.9 43.4931 1 42.5931 1 41.4931V11.1031C1 9.99305 1.9 9.10305 3 9.10305Z',
      'M1.03986 41.083C1.25986 40.003 2.31986 39.303 3.39986 39.533L39.6799 47.043C40.7599 47.263 41.4599 48.323 41.2299 49.403C41.0099 50.483 39.9499 51.183 38.8699 50.953L2.58986 43.453C1.50986 43.233 0.809857 42.173 1.03986 41.093V41.083Z',
    ],
    viewBox: '0 0 59 51',
  },
  {
    fullPath: 'M32.5 20L17.5 2.5L3 14.3656V41.5L26 55.5L56 46.5V20H32.5Z',
    paths: [
      'M57.9085 46.1068C58.2388 47.1608 57.6522 48.2831 56.5982 48.6134L26.9425 57.9085C25.8884 58.2389 24.7662 57.6523 24.4358 56.5982C24.1055 55.5442 24.6921 54.422 25.7461 54.0916L55.4019 44.7965C56.4559 44.4662 57.5781 45.0528 57.9085 46.1068Z',
      'M56 18.1476C57.1046 18.1476 58 19.043 58 20.1476V46.705C58 47.8096 57.1046 48.705 56 48.705C54.8954 48.705 54 47.8096 54 46.705V20.1476C54 19.043 54.8954 18.1476 56 18.1476Z',
      'M30.541 20.1476C30.541 19.043 31.4364 18.1476 32.541 18.1476H56C57.1046 18.1476 58 19.043 58 20.1476C58 21.2522 57.1046 22.1476 56 22.1476H32.541C31.4364 22.1476 30.541 21.2522 30.541 20.1476Z',
      'M16.2156 0.46056C17.0658 -0.244527 18.3267 -0.126847 19.0318 0.723404L34.0809 18.8709C34.786 19.7212 34.6683 20.982 33.8181 21.6871C32.9678 22.3922 31.707 22.2745 31.0019 21.4243L15.9527 3.27675C15.2476 2.4265 15.3653 1.16565 16.2156 0.46056Z',
      'M19.0536 0.750683C19.7436 1.61321 19.6038 2.87179 18.7413 3.56181L3.24946 15.9553C2.38694 16.6453 1.12836 16.5054 0.438336 15.6429C-0.251683 14.7804 -0.11184 13.5218 0.750683 12.8318L16.2425 0.438336C17.105 -0.251683 18.3636 -0.11184 19.0536 0.750683Z',
      'M2 12.3935C3.10457 12.3935 4 13.289 4 14.3935V41.8361C4 42.9407 3.10457 43.8361 2 43.8361C0.89543 43.8361 0 42.9407 0 41.8361V14.3935C0 13.289 0.89543 12.3935 2 12.3935Z',
      'M0.271631 40.8304C0.827112 39.8756 2.05138 39.552 3.00611 40.1074L27.3504 54.2714C28.3051 54.8269 28.6288 56.0511 28.0733 57.0059C27.5178 57.9606 26.2935 58.2843 25.3388 57.7288L0.994541 43.5648C0.0398077 43.0094 -0.28385 41.7851 0.271631 40.8304Z',
    ],
    viewBox: '0 0 58 58',
  },
  {
    fullPath:
      'M50.938 6.94427L35.7254 3.2587L22.5496 23.6161L5.98727 19.7511L3.39062 45.3186L24.3459 50.7827L33.6005 35.7044L50.972 35.924L50.938 6.94427Z',
    paths: [
      'M35.1649 35.6159C36.1023 36.2002 36.3885 37.4338 35.8042 38.3711L26.3694 53.5064C25.7851 54.4438 24.5515 54.73 23.6141 54.1456C22.6768 53.5613 22.3906 52.3278 22.9749 51.3904L32.4097 36.2551C32.994 35.3178 34.2276 35.0316 35.1649 35.6159Z',
      'M32.1107 37.4272C32.0478 36.3245 32.8908 35.3795 33.9936 35.3166L51.4435 34.3218C52.5463 34.2589 53.4912 35.1019 53.5541 36.2047C53.617 37.3075 52.774 38.2524 51.6712 38.3153L34.2212 39.3102C33.1185 39.373 32.1735 38.53 32.1107 37.4272Z',
      'M51.4982 4.33411C52.6028 4.33205 53.4999 5.2258 53.5019 6.33037L53.558 36.3146C53.5601 37.4192 52.6663 38.3163 51.5618 38.3184C50.4572 38.3204 49.5601 37.4267 49.558 36.3221L49.502 6.33785C49.4999 5.23328 50.3936 4.33618 51.4982 4.33411Z',
      'M33.1035 1.49138C33.3847 0.423201 34.4785 -0.214798 35.5467 0.0663729L52.011 4.40014C53.0792 4.68131 53.7172 5.77518 53.436 6.84337C53.1548 7.91155 52.0609 8.54955 50.9928 8.26838L34.5285 3.93461C33.4603 3.65344 32.8223 2.55957 33.1035 1.49138Z',
      'M36.0561 0.280079C37.0062 0.8434 37.3198 2.0703 36.7565 3.02042L24.5292 23.6433C23.9659 24.5935 22.739 24.907 21.7889 24.3437C20.8388 23.7804 20.5252 22.5535 21.0885 21.6034L33.3158 0.98044C33.8791 0.030319 35.106 -0.283245 36.0561 0.280079Z',
      'M3.25113 17.588C3.52649 16.5183 4.61687 15.8744 5.68657 16.1498L23.3104 20.6864C24.3801 20.9618 25.024 22.0521 24.7486 23.1218C24.4733 24.1915 23.3829 24.8354 22.3132 24.56L4.68941 20.0234C3.61971 19.748 2.97577 18.6577 3.25113 17.588Z',
      'M5.41271 16.0989C6.51021 16.2237 7.29871 17.2146 7.17389 18.3121L3.98737 46.3287C3.86255 47.4262 2.87166 48.2147 1.77417 48.0898C0.67667 47.965 -0.11183 46.9741 0.0129947 45.8766L3.19951 17.8601C3.32434 16.7626 4.31522 15.9741 5.41271 16.0989Z',
      'M0.0745107 45.5641C0.372196 44.5004 1.47582 43.8794 2.53952 44.1771L25.2126 50.5224C26.2763 50.82 26.8973 51.9237 26.5996 52.9874C26.3019 54.0511 25.1983 54.672 24.1346 54.3743L1.46151 48.0291C0.397803 47.7314 -0.223175 46.6278 0.0745107 45.5641Z',
    ],
    viewBox: '0 0 54 55',
  },
];

export default BoulderPaths;
