import clsx from 'clsx';

import { clientStatusData } from '@/constants';

import type { FC } from 'react';

interface FiltersProps {
  filter: string;
  isAdmin: boolean;
  setFilter: (filter: string) => void;
}

const Filters: FC<FiltersProps> = ({ filter, isAdmin, setFilter }) => {
  const onClick = (value: string) => () => setFilter(value);

  if (!isAdmin) return null;

  return (
    <div className="my-6 flex flex-wrap justify-center gap-4 md:mt-0 md:justify-start">
      {clientStatusData.map(({ color, label, value }) => (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions -- TODO: Improve accesibility. This may be a Chip component instead?
        <span
          key={`member-filters-${value}`}
          className={clsx(
            'body-sm -mt-1 cursor-pointer rounded-md px-4 py-2 text-cement-500',
            {
              'border-b-2 border-black': value === filter,
              [color as string]: !!color,
            },
          )}
          onClick={onClick(value)}
        >
          {label}
        </span>
      ))}
    </div>
  );
};

export default Filters;
