import { ZERO } from '@/constants';
import { NotificationOptions } from '@/types';
import { Button, Icon, Typography } from '@/ui';
import { formatPhoneNumber } from '@/utils';

import type { User } from '@/types';
import type { FC } from 'react';

interface CoachDetailsProps {
  coach: User;
  onEditAccount?: () => void;
  onEditAdmin?: () => void;
}

const CoachDetails: FC<CoachDetailsProps> = ({
  coach,
  onEditAccount = undefined,
  onEditAdmin = undefined,
}) => {
  const notifyEmail =
    coach.userNotifications?.[NotificationOptions.Email] ?? false;
  const notifySms = coach.userNotifications?.[NotificationOptions.Sms] ?? false;

  let notifications = 'Off';
  if (notifyEmail && !notifySms) {
    notifications = 'Email';
  } else if (notifySms && notifyEmail) {
    notifications = 'SMS & Email';
  } else if (notifySms) {
    notifications = 'SMS';
  }

  const title = onEditAdmin ? 'Admin Settings' : 'Account Details';

  return (
    <section className="space-y-4">
      <div className="mb-4 flex items-center justify-between">
        <Typography variant="subtitle1">{title}</Typography>
        {!!onEditAccount && (
          <Button
            color="primary"
            size="small"
            variant="contained"
            onClick={onEditAccount}
          >
            Manage
          </Button>
        )}
        {!!onEditAdmin && (
          <Button
            color="error"
            size="small"
            variant="contained"
            onClick={onEditAdmin}
          >
            Manage
          </Button>
        )}
      </div>
      <Typography
        className="flex items-center gap-2 text-sage-600"
        variant="subtitle2"
      >
        <Icon className="text-sage-600">sports</Icon>
        {coach.isActive ? 'Active Coach' : 'Inactive Coach'}
      </Typography>
      <Typography
        className="flex items-center gap-2 text-sage-600"
        variant="subtitle2"
      >
        <Icon className="text-sage-600">mail</Icon>
        {coach.email}
      </Typography>
      {!!coach.profile?.phone && (
        <Typography
          className="flex items-center gap-2 text-sage-600"
          variant="subtitle2"
        >
          <Icon className="text-sage-600">call</Icon>
          {formatPhoneNumber(coach.profile.phone)}
        </Typography>
      )}
      <Typography
        className="flex items-center gap-2 text-sage-600"
        variant="subtitle2"
      >
        <Icon className="text-sage-600">notifications</Icon>
        {notifications}
      </Typography>
      <Typography
        className="flex items-center gap-2 text-sage-600"
        variant="subtitle2"
      >
        <Icon className="text-sage-600">account_circle</Icon>
        Members: {coach.coachees.length}
      </Typography>
      {(!!onEditAccount || !!onEditAdmin) && (
        <Typography
          className="flex items-center gap-2 text-sage-600"
          variant="subtitle2"
        >
          <Icon className="text-sage-600">communities</Icon>
          Capacity: {coach.coachProfile?.capacity ?? ZERO}
        </Typography>
      )}
    </section>
  );
};

export default CoachDetails;
