import { useQuery } from '@tanstack/react-query';

import { getAssistantFunction } from '@/api';

import type { Function } from '@/types';

export const useGetFunction = (id: string) =>
  useQuery<Function>(['function', id], () => getAssistantFunction(id), {
    enabled: !!id,
  });
