import clsx from 'clsx';
import { useState } from 'react';

import { Icon, Typography } from '@/ui';

import type { FC } from 'react';

interface RowProps {
  body: string;
  header: string;
}

const Row: FC<RowProps> = ({ header, body }) => {
  const [closed, setClosed] = useState(true);
  const toggle = () => setClosed(!closed);
  const icon = closed ? 'expand_more' : 'expand_less';

  return (
    <div className="border-b border-cement-300 p-6 last:border-none">
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions -- TODO: Improve this accessibility */}
      <div className="flex justify-between" onClick={toggle}>
        <Typography className="w-10/12 cursor-pointer" variant="h5">
          {header}
        </Typography>
        <div
          className={clsx(
            'flex h-8 w-8 cursor-pointer select-none justify-center rounded-full bg-sage-500 py-1 text-white',
            { 'bg-sage-300 text-sage-500': !closed },
          )}
        >
          <Icon>{icon}</Icon>
        </div>
      </div>
      <Typography
        className={clsx('mt-2', {
          hidden: closed,
        })}
        dangerouslySetInnerHTML={{ __html: body }}
      />
    </div>
  );
};

export default Row;
