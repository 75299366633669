import { useState } from 'react';

import { ONE, TWO } from '@/constants';
import { useCurrentUser, useSessions, useView } from '@/hooks';
import { SessionListType, SessionStatus, SessionWarningAction } from '@/types';
import { Pagination } from '@/ui';
import {
  groupByDate,
  groupForUpcoming,
  isInNumberOfDays,
  track,
} from '@/utils';

import ClientCoaches from '../ClientCoaches';
import SessionList from '../List';
import MatchingCoach from '../MatchingCoach';
import MeetCoach from '../MeetCoach';

import type { Session } from '@/types';
import type { ChangeEvent, FC } from 'react';

import CancelSession from '@/components/Cal/cancelSession';
import RescheduleSession from '@/components/Cal/rescheduleSession';
import SessionWarning from '@/components/Session/SessionWarning';

const Upcoming: FC = () => {
  const { user } = useCurrentUser();
  const { isOverview, selectedUser } = useView();
  const [page, setPage] = useState<number>(ONE);

  const concerningUserId = selectedUser.id || user.id;
  const {
    data: sessions = {
      items: {} as Record<string, Session[]>,
      pages: ONE,
      total: ONE,
    },
    isLoading,
    refetch,
  } = useSessions(
    {
      page,
      statuses: [SessionStatus.ACTIVE, SessionStatus.RESCHEDULED],
      userId: concerningUserId,
    },
    'always',
  );
  const { nextSession, upcomingSessions } = groupForUpcoming(
    groupByDate(sessions.items),
    page,
  );
  const [openCancel, setOpenCancel] = useState<Session | null>();
  const [warningAction, setWarningAction] = useState(
    SessionWarningAction.CANCEL,
  );
  const [selectedSession, setSelectedSession] = useState<Session | null>();
  const [openReschedule, setOpenReschedule] = useState<Session | null>();

  const onCloseWarning = () => setSelectedSession(null);

  const handleOnReschedule = (session: Session) => {
    if (isInNumberOfDays(session.startedAt, TWO)) {
      track('Shown Reschedule Speedbump', {
        bookId: session.bookId,
        sessionId: session.id,
      });
      setWarningAction(SessionWarningAction.RESCHEDULE);
      setSelectedSession(session);
      return;
    }

    track('Clicked Reschedule Session', {
      bookId: session.bookId,
      sessionId: session.id,
    });

    setOpenReschedule(session);
  };

  const handleOnCancel = (session: Session) => {
    if (isInNumberOfDays(session.startedAt, TWO)) {
      track('Shown Cancel Speedbump', {
        bookId: session.bookId,
        sessionId: session.id,
      });
      setWarningAction(SessionWarningAction.CANCEL);
      setSelectedSession(session);
      return;
    }

    track('Clicked Cancel Session', {
      bookId: session.bookId,
      sessionId: session.id,
    });

    setOpenCancel(session);
  };

  const onCloseModal = () => {
    setOpenCancel(null);
    setOpenReschedule(null);
    refetch();
  };

  const onPageChange = (_: ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  if (!isLoading && !isOverview && !user.isCoach && user.coaches.length === 0)
    return <MatchingCoach />;

  return (
    <>
      <>
        {!isOverview && (
          <>
            <MeetCoach />
            <ClientCoaches
              coaches={user.coaches}
              isLoading={isLoading}
              refetch={refetch}
              user={user}
            />
          </>
        )}

        {page === ONE && (
          <SessionList
            sessions={nextSession}
            type={SessionListType.Next}
            hideIfEmpty
            onCancel={handleOnCancel}
            onReschedule={handleOnReschedule}
          />
        )}
      </>

      <SessionList
        loading={isLoading}
        sessions={upcomingSessions}
        type={SessionListType.Upcoming}
        onCancel={handleOnCancel}
        onReschedule={handleOnReschedule}
      />

      <Pagination count={sessions.pages} page={page} onChange={onPageChange} />

      {!!selectedSession && (
        <SessionWarning
          action={warningAction}
          close={onCloseWarning}
          session={selectedSession}
          onCancel={setOpenCancel}
          onReschedule={setOpenReschedule}
        />
      )}

      {!!openCancel && (
        <CancelSession close={onCloseModal} session={openCancel} />
      )}
      {!!openReschedule && (
        <RescheduleSession close={onCloseModal} session={openReschedule} />
      )}
    </>
  );
};

export default Upcoming;
