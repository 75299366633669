import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import { getUsers } from '@/api';
import { COACH_HEADERS, DEFAULT_PAGE_SIZE, ONE, ZERO } from '@/constants';
import {
  Button,
  PageHeaderLayout,
  Pagination,
  Search,
  Table,
  TableContainer,
} from '@/ui';

import type { ChangeEvent } from 'react';

import List from '@/components/Coaches/List';
import Header from '@/components/Coaches/List/Header';
import Modal from '@/components/Coaches/New/modal';

const Coaches = () => {
  const [page, setPage] = useState<number>(ONE);
  const [search, setSearch] = useState('');
  const [newCoach, setNewCoach] = useState(false);

  const { data, isLoading } = useQuery(['coaches', search, page], () =>
    getUsers({
      coach: true,
      limit: DEFAULT_PAGE_SIZE,
      page,
      search,
      sortBy: 'first_name',
      sortDirection: 'asc',
      view: 'manage_coaches',
    }),
  );

  useEffect(() => {
    setPage(ONE);
  }, [search]);

  const onAddCoach = () => setNewCoach(true);
  const onCloseAddCoach = () => setNewCoach(false);
  const onPageChange = (_: ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return (
    <div className="w-full p-4">
      <PageHeaderLayout title="Coaches">
        <Button variant="contained" onClick={onAddCoach}>
          Add coach
        </Button>
      </PageHeaderLayout>

      <Search
        className="mt-4 w-full md:w-1/3"
        search={search}
        setSearch={setSearch}
      />

      <div className="flex flex-1 flex-col pt-4">
        <TableContainer>
          <Table aria-label="coaches table">
            <Header headers={COACH_HEADERS} />
            <List
              coaches={data?.items || []}
              headers={COACH_HEADERS}
              isLoading={isLoading}
            />
          </Table>
        </TableContainer>
        <Pagination
          count={data?.pages ?? ZERO}
          page={page}
          onChange={onPageChange}
        />
      </div>
      {newCoach && <Modal onClose={onCloseAddCoach} />}
    </div>
  );
};

export default Coaches;
