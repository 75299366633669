import clsx from 'clsx';

import {
  SESSION_FEEDBACK_GRAPH_SIZE,
  SESSION_FEEDBACK_TYPES,
} from '@/constants';
import { Typography } from '@/ui';

import type { SessionFeedbackParams, SessionFeedbackType } from '@/types';
import type { FC } from 'react';

interface GraphProps {
  feedbackType: SessionFeedbackType;
  value: number;
}

const Graph: FC<GraphProps> = ({ value, feedbackType }) => {
  const { img = '', statsGraphColor = '' } =
    SESSION_FEEDBACK_TYPES[feedbackType] ?? ({} as SessionFeedbackParams);
  return (
    <div className="flex max-w-76 space-x-2">
      <img alt="feedback icon" className="h-6 w-6" src={img} />
      <div
        className={clsx('flex flex-row rounded-full pl-8', statsGraphColor)}
        style={{ width: `${value * SESSION_FEEDBACK_GRAPH_SIZE}px` }}
      />
      <Typography variant="subtitle1">{value}</Typography>
    </div>
  );
};

export default Graph;
