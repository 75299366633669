import { ONE, THREE, TWO, ZERO } from '@/constants';
import { useGrowthPlans } from '@/hooks';
import { Paper, Typography } from '@/ui';

import GrowthPlanCardDescription from './GrowthPlanCardDescription';

import BoulderImage from '@/components/Growth/BoulderImage';
import {
  OneMilestone,
  ThreeMilestones,
  TwoMilestones,
  ZeroMilestones,
} from '@/components/Home/Cards/GrowthPlanCard/MilestoneIcons';

const GrowthPlanCard = () => {
  const { plans, isPlansLoading } = useGrowthPlans();

  if (isPlansLoading || !plans || !plans[0]?.id) {
    return null;
  }

  if (plans.length === ONE && !plans[0]?.started) {
    return (
      <>
        <Typography className="pb-2" variant="h4">
          Build your personal growth plan
        </Typography>
        <Typography variant="body2">
          Partner with your Mento Coach to create a tailored career growth plan.
        </Typography>
      </>
    );
  }

  // only one plan and is has been started
  if (plans.length === ONE && plans[0]?.started) {
    // started and no milestones
    if (plans[0]?.milestones.length === ZERO) {
      return (
        <>
          <Typography className="pb-2" variant="h4">
            No milestones in the works
          </Typography>
          <Typography variant="body2">
            You don’t have any active milestones in your Growth Plan. What’s
            next on your horizon?
          </Typography>
        </>
      );
    }

    // started and has milestones
    return (
      <>
        <Typography className="pb-2" variant="h4">
          {plans[0]?.milestones.length}{' '}
          {plans[0]?.milestones.length > ONE ? 'milestones' : 'milestone'}
        </Typography>

        {plans[0]?.milestones.slice(ZERO, THREE).map((milestone) => (
          <Paper
            key={milestone.id}
            className="my-1.5 border-2 border-solid border-sage-300 p-2"
            elevation={0}
          >
            <div className="flex">
              <div className="my-auto mr-5 h-8 shrink-0">
                <BoulderImage milestone={milestone} />
              </div>
              <div>
                <Typography className="mb-0.5" variant="subtitle2">
                  {milestone.title}
                </Typography>
                <GrowthPlanCardDescription milestone={milestone} />
              </div>
            </div>
          </Paper>
        ))}
      </>
    );
  }

  return (
    <>
      <Typography variant="h4">{plans.length} Plans</Typography>
      {plans.slice(ZERO, THREE).map((plan) => (
        <Paper
          key={plan.id}
          className="my-1.5 border-2 border-solid border-sage-300 p-2"
          elevation={0}
        >
          <div className="flex">
            <div className="my-auto mr-1 h-11">
              {plan.milestones.length === ZERO && <ZeroMilestones />}
              {plan.milestones.length === ONE && <OneMilestone />}
              {plan.milestones.length === TWO && <TwoMilestones />}
              {plan.milestones.length === THREE && <ThreeMilestones />}
            </div>
            <div>
              <Typography className="mb-0.5" variant="subtitle2">
                {plan.milestones.length === ZERO && <>No milestones</>}
                {plan.milestones.length === ONE && <>One milestone</>}
                {plan.milestones.length > ONE && (
                  <>{plan.milestones.length} milestones</>
                )}
              </Typography>
              <Typography className="mb-0.5" variant="caption">
                {plan.team ? plan.team.name : 'Coaching'}
              </Typography>
            </div>
          </div>
        </Paper>
      ))}
    </>
  );
};

export default GrowthPlanCard;
