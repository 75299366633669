import { Card, CardContent, Icon, Typography } from '@/ui';

import type { FC } from 'react';

interface FocusProps {
  icon: string;
  subtitle: string;
  title: string;
}

const Focus: FC<FocusProps> = ({ icon, subtitle, title }) => (
  <Card className="w-full bg-sage-500 text-white" elevation={0}>
    <CardContent className="flex w-full flex-row gap-4 p-4">
      <div className="flex h-12 min-w-12 items-center justify-center rounded-full bg-sage-600">
        <Icon>{icon}</Icon>
      </div>
      <div className="flex flex-col">
        <Typography color="white" variant="subtitle1">
          {title}
        </Typography>
        <Typography color="white" variant="caption">
          {subtitle}
        </Typography>
      </div>
    </CardContent>
  </Card>
);

export default Focus;
