import clsx from 'clsx';

import { Icon, Paper, Typography } from '@/ui';

import Footer from './Footer';
import Navbar from './Navbar';

import type { StepData } from '@/types';
import type { FC, ReactNode } from 'react';

interface LayoutProps {
  children?: ReactNode;
  isValid: () => boolean;
  setStep: (step: number) => void;
  step: number;
  stepData: StepData[];
}

const Layout: FC<LayoutProps> = ({
  children = null,
  isValid,
  setStep,
  step,
  stepData,
}) => {
  const {
    layout = 'flex-col',
    subtitle,
    title,
    titleIcon,
  } = stepData[step] ?? {};

  if (!title) {
    return null;
  }

  return (
    <Paper className="my-6 flex flex-col rounded-2xl border border-cement-300 bg-white px-6 py-10 lg:pl-16 lg:pr-10">
      <Navbar current={step} stepData={stepData} />
      <div className="pt-9">
        <div className="flex flex-col">
          <div className={clsx('flex', layout)}>
            {titleIcon ? (
              <div className="pr-6">
                <div className="grid place-items-center rounded-full bg-bloom-500 p-2">
                  <Icon className="text-white">{titleIcon}</Icon>
                </div>
              </div>
            ) : null}
            <div className="flex flex-col">
              <div className={clsx('flex', layout)}>
                <div className="max-w-2xl">
                  <Typography className="text-cement-00" variant="h4">
                    {title}
                  </Typography>
                  <Typography className="py-6 text-cement-500" variant="body1">
                    {subtitle}
                  </Typography>
                </div>
                {children}
              </div>
              <Footer
                setStep={setStep}
                step={step}
                stepData={stepData}
                valid={isValid()}
              />
            </div>
          </div>
        </div>
      </div>
    </Paper>
  );
};

export default Layout;
