import type { Session } from './session';
import type { User } from './user';

export enum NoteOwnerType {
  SESSION = 'Session',
  USER = 'User',
}

export interface Note {
  author: User;
  authorId: string;
  createdAt: Date;
  id: string;
  owneableId: string;
  owneableType: NoteOwnerType;
  owner: User | Session;
  updatedAt: Date;
  value: string;
  // TODO: add teams stuff once done in BE
}
