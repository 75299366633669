import { setUserVars } from '@fullstory/browser';
import mixpanel from 'mixpanel-browser';

import type { IDictionary, User } from '@/types';

const setPeopleData = (user: User) => {
  const { email, fullName, isCoach, superAdmin, isBusinessPaid } = user;
  let role;
  if (superAdmin) {
    role = 'admin';
  } else if (isCoach) {
    role = 'coach';
  } else {
    role = 'member';
  }

  const data: IDictionary = {
    $email: email,
    $name: fullName,
    $role: role,
  };

  if (role === 'member') {
    if (isBusinessPaid) {
      data.CustomerType = 'b2b';
    } else {
      data.CustomerType = 'd2c';
    }
  }

  if (import.meta.env.VITE_MIX_PANEL_ID) {
    mixpanel.people.set(data);
  } else {
    console.debug(`MIXPANEL_SET_PEOPLE ${email}`, data); // eslint-disable-line no-console -- Debugging when env is not set to check this is being called
  }
};

export const identify = (user: User) => {
  const { email, fullName } = user;

  if (import.meta.env.VITE_MIX_PANEL_ID) {
    mixpanel.identify(email);
    setPeopleData(user);
  } else {
    console.debug(`MIXPANEL_IDENTITY ${email}`); // eslint-disable-line no-console -- Debugging when env is not set to check this is being called
  }
  // HUBSPOT
  window._hsq = window._hsq || []; // eslint-disable-line no-underscore-dangle -- Hubspot naming rules
  const { _hsq } = window; // eslint-disable-line @typescript-eslint/naming-convention -- Hubspot naming rules
  _hsq.push(['identify', { email }]);

  if (import.meta.env.VITE_FULLSTORY_ORG) {
    setUserVars({
      displayName: fullName,
      email,
    });
  } else {
    // eslint-disable-next-line no-console -- Debugging when env is not set to check this is being called
    console.debug(`FULLSTORY USER VARS ${email}`, {
      displayName: fullName,
      email,
    });
  }
};

export const trackingInit = () => {
  if (import.meta.env.VITE_MIX_PANEL_ID) {
    mixpanel.init(import.meta.env.VITE_MIX_PANEL_ID);
  } else {
    console.debug('MIXPANEL_INIT'); // eslint-disable-line no-console -- Debugging when env is not set to check this is being called
  }
};

export const track = (eventName: string, data?: object) => {
  if (import.meta.env.VITE_MIX_PANEL_ID) {
    mixpanel.track(eventName, data);
  } else {
    console.debug(`MIXPANEL_TRACKING ${eventName}`, data); // eslint-disable-line no-console -- Debugging when env is not set to check this is being called
  }
};

export const trackView = (path: string, data?: object) => {
  // HUBSPOT
  window._hsq = window._hsq || []; // eslint-disable-line no-underscore-dangle -- Hubspot naming rules
  const { _hsq } = window; // eslint-disable-line @typescript-eslint/naming-convention -- Hubspot naming rules
  _hsq.push(['setPath', path]);
  _hsq.push(['trackPageView']);

  track(`Viewed ${path}`, data);
};

export const alias = (email: string) => {
  if (import.meta.env.VITE_MIX_PANEL_ID) {
    mixpanel.alias(email);
  } else {
    console.debug(`MIXPANEL_ALIAS ${email}`); // eslint-disable-line no-console -- Debugging when env is not set to check this is being called
  }
};
