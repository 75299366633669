import { Card, CardContent, Icon, Typography } from '@/ui';

import type { FC } from 'react';

interface CompanyCultureProps {
  culture: string;
}

const CompanyCulture: FC<CompanyCultureProps> = ({ culture = '' }) => (
  <Card className="w-68" elevation={0}>
    <CardContent className="border-2 border-b-sage-300 bg-sage-200">
      <Typography
        className="mb-2 flex items-center gap-2 text-cement-500"
        variant="subtitle1"
      >
        <Icon>domain</Icon>
        Additional information
      </Typography>
      <Typography className="text-cement-500" variant="body2">
        {culture}
      </Typography>
    </CardContent>
  </Card>
);

export default CompanyCulture;
