import { useEffect } from 'react';

import { useCurrentUser } from '@/hooks';
import { track } from '@/utils';

import Faqs from '@/components/GetStarted/MembershipSelect/Faqs';
import HowItWorks from '@/components/GetStarted/MembershipSelect/HowItWorks';
import Logos from '@/components/GetStarted/MembershipSelect/Logos';
import Prices from '@/components/GetStarted/MembershipSelect/Prices';
import Quotes from '@/components/GetStarted/MembershipSelect/Quotes';
import SpecialSauce from '@/components/GetStarted/MembershipSelect/SpecialSauce';

const MembershipSelect = () => {
  const { user } = useCurrentUser();

  useEffect(() => {
    track('ob_selectplan');
  }, []);

  return (
    <div className="flex w-full flex-1 flex-col overflow-x-hidden bg-sunny-200 pt-20 text-white">
      <Prices user={user} />
      <div className="bg-gradient-to-b from-black to-sage-500 pt-80">
        <HowItWorks />
        <SpecialSauce />
        <Quotes />
      </div>

      <div className="flex flex-col items-center bg-white pt-32 text-black">
        <Faqs />
        <Logos />
      </div>
    </div>
  );
};

export default MembershipSelect;
