import { getTimeZones } from '@vvo/tzdb';
import Case from 'case';
import {
  format,
  formatDistance,
  getHours,
  getYear,
  isToday,
  isTomorrow,
  isYesterday,
  millisecondsToMinutes,
  startOfDay,
} from 'date-fns';

import {
  DAYS_IN_MONTH,
  DAYS_IN_YEAR,
  HOURS_IN_AM_PM,
  HOURS_IN_DAY,
  MINUTES_IN_HOUR,
  MONTHS_IN_YEAR,
  ONE,
  SECONDS_IN_MINUTE,
  THOUSAND,
  TWO,
  ZERO,
} from '@/constants';

import type { CoachAssignment, User } from '@/types';

// TODO: move to types
export enum DATE_FORMATS {
  DAY_HOUR_AM_PM = 'EEEE, h:mmaaa',
  DAY_MONTH_NUMBER = 'EEEE, MMMM do',
  HOUR_MINUTE = 'h:mm',
  HOUR_MINUTE_AM_PM = 'h:mmaaa',
}

export const isValidDate = (date: Date) => !Number.isNaN(date.getTime());

export const findTimezone = (tz: string) =>
  getTimeZones().find(({ name, group }) => tz === name || group.includes(tz));

export const getGMTTimeZone = (tz: string, abreviated?: boolean) => {
  const data = findTimezone(tz);

  if (data) {
    if (abreviated) {
      return data.abbreviation;
    }
    return `(${data.alternativeName})`;
  }

  return '';
};

// April 22
export const toMonthString = (date: Date) => {
  if (!isValidDate(date)) return '';
  const locale = 'en';

  return date.toLocaleDateString(locale, {
    day: 'numeric',
    month: 'long',
  });
};

// Tuesday, April 22 (GMT-7:00)
export const toDateString = (date: Date, timeZone?: string) => {
  if (!isValidDate(date)) return '';
  const gmtTimeZone = timeZone ? getGMTTimeZone(timeZone) : '';
  const locale = 'en';

  const dateString = date.toLocaleDateString(locale, {
    day: 'numeric',
    month: 'long',
    timeZone,
    weekday: 'long',
  });

  return `${dateString} ${gmtTimeZone}`;
};

// 01-01-2023
export const formatDateDDMMYYYY = (date: Date) => {
  if (!isValidDate(date)) return '';
  const toLocaleDate = date.toLocaleDateString('en', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
  const dashedDate = toLocaleDate.replaceAll('/', '-');
  return dashedDate;
};

// Tuesday, April 22 1:05 pm (GMT-7:00)
export const toDateTimeString = (
  value: Date | string,
  timeZone?: string,
  month: 'long' | 'short' = 'long',
  weekday: 'long' | 'short' = 'long',
) => {
  const date = new Date(value);
  if (!isValidDate(date)) return '';
  let gmtTimeZone = '';

  if (timeZone) {
    gmtTimeZone = getGMTTimeZone(timeZone);
  }

  const locale = 'en';

  const dateString = date.toLocaleDateString(locale, {
    day: 'numeric',
    month,
    timeZone,
    weekday,
  });
  const timeString = date.toLocaleTimeString(locale, {
    hour: 'numeric',
    hour12: true,
    minute: '2-digit',
    timeZone,
  });

  return `${dateString} ${timeString} ${gmtTimeZone}`;
};

// April 22 1:05 pm-2:05pm (GMT-7:00) or 1:05 pm-2:05pm (GMT-7:00)
export const toDateTimeRangeString = (
  start: Date,
  end: Date,
  timeZone: string,
  skipDay?: boolean,
) => {
  const gmtTimeZone = getGMTTimeZone(timeZone, true);
  const locale = 'en';

  const dateOptions: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'long',
    timeZone,
  };
  const dateString = start.toLocaleDateString(locale, dateOptions);
  const startTimeString = start.toLocaleTimeString(locale, {
    hour: 'numeric',
    hour12: true,
    minute: '2-digit',
    timeZone,
  });

  const endTimeString = end.toLocaleTimeString(locale, {
    hour: 'numeric',
    hour12: true,
    minute: '2-digit',
    timeZone,
  });

  if (skipDay) return `${startTimeString}-${endTimeString} ${gmtTimeZone}`;

  return `${dateString} ${startTimeString}-${endTimeString} ${gmtTimeZone}`;
};

const shortDistanceFrom = (date: Date, includeMinutes = false) => {
  const today = isToday(date);
  const tomorrow = isTomorrow(date);

  const minutesFormat = includeMinutes ? " 'at' h:mm aaa" : "'";
  let dateText = '';
  if (today) {
    dateText = format(date, `'Today'${minutesFormat}`);
  } else if (tomorrow) {
    dateText = format(date, `'Tomorrow'${minutesFormat}`);
  } else {
    dateText = format(date, `M/dd/yy${minutesFormat}`);
  }

  return dateText;
};

const distanceFrom = (date: Date, includeMinutes = false) => {
  const distance = formatDistance(
    date,
    !includeMinutes ? startOfDay(new Date()) : new Date(),
    {
      addSuffix: false,
    },
  );

  if (
    !includeMinutes &&
    (distance === 'less than a minute' || distance === '1 minute')
  ) {
    return 'Just now';
  }
  if (distance === '1 day') {
    return 'Yesterday';
  }
  if (
    !includeMinutes &&
    (distance.endsWith('minute') ||
      distance.endsWith('minutes') ||
      distance.endsWith('hour') ||
      distance.endsWith('hours'))
  ) {
    return 'Today';
  }
  return `${Case.sentence(distance)} ago`;
};

const formatDay = (
  day: { end: string; start: string },
  currentMonth: number,
  selectedDay: number,
) => {
  const now = new Date().getTime();
  const currentYear = getYear(now);
  const startHour = parseInt(day.start.split(':')[0] ?? '', 10);
  const startMinute = parseInt(
    day.start.split(':')[1]?.slice(ZERO, TWO) ?? '',
    10,
  );
  const endHour = parseInt(day.end.split(':')[0] ?? '', 10);
  const endMinute = parseInt(day.end.split(':')[1]?.slice(ZERO, TWO) ?? '', 10);
  const isPMStart = day.start.includes('PM');
  const isPMEnd = day.end.includes('PM');
  return {
    end: new Date(
      currentYear,
      currentMonth,
      selectedDay,
      isPMEnd ? endHour + HOURS_IN_AM_PM : endHour,
      endMinute,
    ),
    start: new Date(
      currentYear,
      currentMonth,
      selectedDay,
      isPMStart ? startHour + HOURS_IN_AM_PM : startHour,
      startMinute,
    ),
  };
};

const generateTimes = () => {
  const x = 15;
  const times = [];
  let tt = ZERO;
  const ap = ['AM', 'PM'];
  for (let i = ZERO; tt < HOURS_IN_DAY * MINUTES_IN_HOUR; i += ONE) {
    const hh = Math.floor(tt / MINUTES_IN_HOUR);
    const mm = tt % SECONDS_IN_MINUTE;
    const hour = `${`0${
      hh === HOURS_IN_AM_PM ? HOURS_IN_AM_PM : hh % HOURS_IN_AM_PM
      /* eslint-disable-next-line @typescript-eslint/no-magic-numbers -- No point in creating a variable for `-2`. */
    }`.slice(-2)}:${`0${mm}`.slice(-2)}${ap[Math.floor(hh / HOURS_IN_AM_PM)]}`;
    times[i] = { label: hour, value: hour };
    tt += x;
  }
  return times;
};

// format minutes to be two digits
const formatDate = (date: Date) => {
  const isPM = getHours(date) >= HOURS_IN_AM_PM;
  const is12 = getHours(date) === HOURS_IN_AM_PM;
  const hour = is12 ? HOURS_IN_AM_PM : getHours(date) % HOURS_IN_AM_PM;
  return `${hour}:${date.getMinutes() === ZERO ? '00' : date.getMinutes()} ${
    isPM ? 'PM' : 'AM'
  }`;
};
const returnMinuteRange = (start: number, end: number) =>
  parseInt(
    (millisecondsToMinutes(end - start) / MINUTES_IN_HOUR)
      .toFixed(TWO)
      .toString()
      .split('.')[1] ?? '',
    10,
    /* eslint-disable-next-line @typescript-eslint/no-magic-numbers -- To be honest, no idea what 75 is. */
  ) >= 75
    ? Math.ceil(millisecondsToMinutes(end - start) / SECONDS_IN_MINUTE)
    : Math.floor(
        millisecondsToMinutes(Math.floor((end - start) / SECONDS_IN_MINUTE)),
      );

const formatToday = (
  date: string | Date | undefined,
  config?: {
    format?: string;
    todayFormat?: string;
    tomorrowFormat?: string;
    yesterdayFormat?: string;
  },
) => {
  const {
    todayFormat = "'Today at' h:mm aaa",
    yesterdayFormat = "'Yesterday at' h:mm aaa",
    tomorrowFormat = "'Tomorrow at' h:mm aaa",
    format: customFormat = "M/dd/yy 'at' h:mm aaa",
  } = config || {};

  const d = date ? new Date(date) : undefined;

  if (!d) {
    return '';
  }

  if (isToday(d)) {
    return format(d, todayFormat);
  }

  if (isYesterday(d)) {
    return format(d, yesterdayFormat);
  }

  if (isTomorrow(d)) {
    return format(d, tomorrowFormat);
  }

  return format(d, customFormat);
};

// Rename to isToday once we get rid of date-fns
const isDateToday = (date?: Date | string) => {
  if (!date) return false;
  return (
    new Intl.DateTimeFormat('en-US').format(new Date(date)) ===
    new Intl.DateTimeFormat('en-US').format(new Date())
  );
};

// 'Fri, Dec 22'
const formatShortDayMonthDate = (date: Date | string) =>
  new Date(date).toLocaleDateString('en', {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
  });

const formatMonthAndYear = (date: Date | string) =>
  new Date(date).toLocaleDateString('en', {
    month: 'long',
    year: 'numeric',
  });

const getTimeSinceCoaching = (
  coach: User,
  relationships: CoachAssignment[],
) => {
  const fromCoach = relationships.filter((r) => r.coachId === coach.id);

  // get the time elapsed from createdAt and endDate OR now (if endDate is null)
  const sum = fromCoach.reduce((_, currentValue, acc) => {
    const createdAt = new Date(currentValue.createdAt).getTime();
    if (currentValue.endedAt) {
      const endAt = new Date(currentValue.endedAt).getTime();
      return acc + (endAt - createdAt);
    }

    return acc + (new Date().getTime() - createdAt);
  }, ZERO);

  const seconds = Math.floor(sum / THOUSAND);
  const minutes = Math.floor(seconds / SECONDS_IN_MINUTE);
  const hours = Math.floor(minutes / MINUTES_IN_HOUR);
  const days = Math.floor(hours / HOURS_IN_DAY);
  const years = Math.floor(days / DAYS_IN_YEAR);
  let months = Math.floor(days / DAYS_IN_MONTH);

  months %= MONTHS_IN_YEAR;
  let text = '';

  if (months > ZERO) {
    text += `Coaching for ${months} ${months === ONE ? 'month' : 'months'}`;
  }

  // if the relationship is longer than a year, we just show the earliest date
  if (years >= ONE) {
    const earliest = new Date(
      Math.min(
        ...fromCoach.map(({ createdAt }) => new Date(createdAt).getTime()),
      ),
    );

    text = `Coaching since ${format(earliest, 'MMM yyyy')}`;
  }

  return text;
};

// TODO: simply type when when we migrate session data to our new backend and get
// consistent types
const isInNumberOfDays = (date: string | Date, numberOfDays: number) => {
  const today = new Date();

  const daysFromToday = new Date(today);
  daysFromToday.setDate(today.getDate() + numberOfDays);

  return new Date(date) < daysFromToday;
};

export {
  shortDistanceFrom,
  distanceFrom,
  formatDay,
  formatDate,
  returnMinuteRange,
  generateTimes,
  formatToday,
  isDateToday,
  formatMonthAndYear,
  formatShortDayMonthDate,
  getTimeSinceCoaching,
  isInNumberOfDays,
};
