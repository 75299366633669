import { Button } from '@/ui';
import { getIconSrcFromMimeType } from '@/utils';

import type { DocMimeType } from '@/types';
import type { FC } from 'react';

interface EditProps {
  referenceMimeType: DocMimeType;
  url: string;
}

const Edit: FC<EditProps> = ({ referenceMimeType, url }) => (
  <Button color="primary" href={url} target="_blank" variant="contained" light>
    <img
      alt={document.title}
      className="mr-2 align-middle"
      height={20}
      src={getIconSrcFromMimeType(referenceMimeType)}
      width={20}
    />
    Edit
  </Button>
);

export default Edit;
