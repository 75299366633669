import { styled } from '@mui/material';
import MuiTableContainer from '@mui/material/TableContainer';
import { clsx } from 'clsx';

import type { TableContainerProps } from '@mui/material/TableContainer';
import type { FC } from 'react';

/* eslint-disable sort-keys -- CSS selectors shouldn't be alphabetically sorted. */
const StyledTableContainer = styled(MuiTableContainer)<TableContainerProps>(
  ({ theme }) => ({
    borderColor: theme.palette.divider,
    borderRadius: theme.shape.borderRadius,
    borderStyle: 'solid',
    borderWidth: 1,

    '.MuiTable-root': {
      border: 0,
    },
  }),
);
/* eslint-enable sort-keys */

const TableContainer: FC<TableContainerProps> = ({ className, ...rest }) => {
  const classes = clsx('mnt-table-container', className);

  return <StyledTableContainer {...rest} className={classes} />;
};
TableContainer.displayName = 'TableContainer';

export default TableContainer;
export type { TableContainerProps };
