import type { CoachStyle, IDictionary } from '@/types';

export const VISIBLE_COACH_HEADERS = 2;

export const COACH_HEADERS = [
  'Name',
  'Focus',
  'Emails',
  'Status',
  'Capacity',
  'Scheduling',
  'Updated',
];

export const STYLE_OPTIONS: IDictionary<CoachStyle> = {
  ENTREPRENEUR: {
    description:
      "As an entrepreneur and founder, you're uniquely positioned to help Members start and grow their own companies.",
    title: 'Entrepreneurship & Founder',
  },
  FUNCTIONAL: {
    description:
      'You are an expert at your craft, and help guide your Members along your journey.',
    title: 'Functional Expert',
  },
  MANAGEMENT: {
    description:
      'You understand career progression, help Members unlock their leadership potential, and grow into phenomenal managers.',
    title: 'Leadership & Management',
  },
  WELLBEING: {
    description:
      'You bring a holistic approach to your Members, focusing on mindset and wellbeing.',
    title: 'Mindset & Wellbeing',
  },
};
