import type { GeneratorFunction } from './types';

const useEventCardActions =
  (): GeneratorFunction =>
  ({ card }) =>
    card.actions
      ? [
          {
            buttonProps: card.actions[0]?.buttonProps || {},
            label: card.actions[0]?.label || '',
          },
        ]
      : [];

export default useEventCardActions;
