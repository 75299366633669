import clsx from 'clsx';

import { ENTER, INSIGHTS_TABS } from '@/constants';
import { Typography } from '@/ui';

import type { FC, KeyboardEvent } from 'react';

interface TabsProps {
  setTab: (index: number) => void;
  tab: number;
}

const Tabs: FC<TabsProps> = ({ setTab, tab }) => {
  const onSelect = (index: number) => () => {
    setTab(index);
  };

  const onKeyDown = (index: number) => (event: KeyboardEvent) => {
    if (event.key === ENTER) {
      onSelect(index)();
    }
  };

  return (
    <div className="flex flex-col pt-8 md:flex-row">
      {INSIGHTS_TABS.map(({ title, description }, index) => (
        <div
          key={`survey-tab-${title}`}
          className={clsx(
            'mr-1 flex min-w-66 cursor-pointer flex-col rounded-2xl p-4',
            {
              'bg-sunny-700': tab === index,
            },
          )}
          role="button"
          tabIndex={0}
          onClick={onSelect(index)}
          onKeyDown={onKeyDown(index)}
        >
          <Typography color="text.primary" variant="subtitle1">
            {title}
          </Typography>
          <Typography color="text.secondary" variant="caption">
            {description}
          </Typography>
        </div>
      ))}
    </div>
  );
};
export default Tabs;
