import Loading from '../Loading';

import Item from './Item';

import type { Document } from '@/types';
import type { FC } from 'react';

interface GridProps {
  documents: Document[];
  isLoading: boolean;
}

const Grid: FC<GridProps> = ({ documents, isLoading }) => {
  if (isLoading) return <Loading />;

  return (
    <section className="flex-1" role="navigation">
      {/* eslint-disable-next-line tailwindcss/no-arbitrary-value -- Need auto rows to 1. */}
      <ul className="grid list-none auto-rows-[1] grid-cols-2 justify-between gap-4 overflow-y-hidden pl-0 sm:grid-cols-4 lg:grid-cols-templates">
        {documents.map((document) => (
          <Item key={`grid-view-${document.id}`} {...document} />
        ))}
      </ul>
    </section>
  );
};

export default Grid;
