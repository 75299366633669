import { styled } from '@mui/material';
import MuiTableCell from '@mui/material/TableCell';
import { clsx } from 'clsx';

import type { TableCellProps } from '@mui/material/TableCell';
import type { FC } from 'react';

const StyledTableCell = styled(MuiTableCell)<TableCellProps>(({ theme }) => ({
  borderColor: theme.palette.divider,
}));

const TableCell: FC<TableCellProps> = ({ className, ...rest }) => {
  const classes = clsx('mnt-table-cell', className);

  return <StyledTableCell {...rest} className={classes} />;
};
TableCell.displayName = 'TableCell';

export default TableCell;
export type { TableCellProps };
