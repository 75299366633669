import { clsx } from 'clsx';

import { Icon, Typography } from '@/ui';

import SummaryBase from './_SummaryBase';

import type { SummaryBaseProps } from './_SummaryBase';
import type { FC } from 'react';

type TextSummaryProps = SummaryBaseProps;

const TextSummary: FC<TextSummaryProps> = ({
  className,
  onConfirm,
  message,
}) => {
  if (!message.description) {
    return null;
  }
  const messageDeclined = message.confirmed === false; // message.confirmed can also be null, which means not answered

  const classes = clsx('text-summary', className);
  const wrapperClasses = clsx(
    'flex flex-col items-start justify-start gap-6 md:flex-row',
    {
      'text-cement-300': messageDeclined,
    },
  );
  const iconClasses = clsx(
    'inline-flex items-center justify-center rounded-full p-2 text-white',
    {
      [`bg-${message.color}-500`]: !messageDeclined,
      'bg-cement-300': messageDeclined,
    },
  );
  // Generate a random boolean based no the ID.
  const generateLean = (id: string): boolean =>
    id
      .split('')
      .map((c) => {
        const res = parseInt(c, 10);
        return Number.isNaN(res) ? 0 : res;
      })
      .reduce((aggr, i) => aggr + i, 0) %
      2 ===
    1;

  return (
    <SummaryBase
      className={classes}
      message={message}
      negativeLean={generateLean(message.id)}
      onConfirm={onConfirm}
    >
      <div className={wrapperClasses}>
        {' '}
        <span className={iconClasses}>
          <Icon fontSize="medium">{message.icon as string}</Icon>
        </span>
        <Typography className="hidden md:block" variant="h5">
          {message.description}
        </Typography>
        <Typography className="block md:hidden" variant="subtitle1">
          {message.description}
        </Typography>
      </div>
    </SummaryBase>
  );
};

export default TextSummary;
