import { SurveyChoiceText } from '@/types';
import { Chip } from '@/ui';

import type { FC } from 'react';

interface PillProps {
  text: string;
}

const Pill: FC<PillProps> = ({ text }) => {
  let background = 'bg-white';
  let color = 'text-white';
  let border = 'border-bloom-500';

  switch (text) {
    case SurveyChoiceText.EVERYONE:
      background = 'bg-bloom-600';
      break;
    case SurveyChoiceText.MOST:
      background = 'bg-bloom-500';
      break;
    case SurveyChoiceText.SOME:
      color = 'text-bloom-600';
      break;
    default:
      color = 'text-cement-350';
      border = 'border-cement-350';
      break;
  }

  return (
    <Chip
      className={`body-sm mr-8 h-fit w-24 min-w-24 rounded border py-2 text-center ${background} ${border} ${color}`}
      label={text}
      variant="outlined"
    />
  );
};

export default Pill;
