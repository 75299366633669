import { ONE } from '@/constants';
import { Divider, ListItem, ListItemIcon, Typography } from '@/ui';

import type { FC } from 'react';

interface GoalProps {
  goal: string;
  index: number;
  total: number;
}

const Goal: FC<GoalProps> = ({ goal, index, total = 0 }) => (
  <>
    <ListItem key={goal} className="gap-1.5 px-0">
      <ListItemIcon className="flex min-w-4 justify-center align-middle">
        <Typography
          align="center"
          className="w-full rounded-full bg-cement-500 text-white"
          variant="caption"
        >
          {index + ONE}
        </Typography>
      </ListItemIcon>
      <Typography variant="body2">{goal}</Typography>
    </ListItem>
    {index + ONE !== total && <Divider />}
  </>
);

export default Goal;
