import { captureException } from '@sentry/react';
import { type FC, useState } from 'react';

import { updateUser } from '@/api';
import { SMALL_BREAKPOINT } from '@/constants';
import { useCurrentUser } from '@/hooks';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  useMediaQuery,
} from '@/ui';

import { EmailUpdatedSteps } from '../types';

interface EmailUpdatedModalProps {
  setStep: (step: EmailUpdatedSteps) => void;
}

const EmailUpdatedModal: FC<EmailUpdatedModalProps> = ({ setStep }) => {
  const { user, refetchUser, isUserLoading } = useCurrentUser();
  const [saving, setSaving] = useState(false);
  const isSmall = useMediaQuery(SMALL_BREAKPOINT);

  const handleDontUpdateClick = async () => {
    try {
      setSaving(true);
      await updateUser(user.id, { emailUpdated: false });
      await refetchUser();
      setStep(EmailUpdatedSteps.Done);
    } catch (error) {
      captureException(error);
    } finally {
      setSaving(false);
    }
  };

  const handleUpdateClick = () => {
    setStep(EmailUpdatedSteps.LinkGoogleAccount);
  };

  if (isUserLoading) {
    return null;
  }

  return (
    <Dialog PaperProps={{ className: 'max-w-2xl' }} open>
      <DialogContent className="px-8 pb-8 md:px-12 md:pb-12">
        <Typography className="mb-4" variant="h5">
          Align your new Mento email address with Documents
        </Typography>
        <Typography className="mb-4">
          You recently updated your Mento login email address to&nbsp;
          <Typography className="inline" variant="subtitle1">
            {user.email}.
          </Typography>
        </Typography>
        <Typography>
          You currently have&nbsp;
          <Typography className="inline" variant="subtitle1">
            {user.documentsEmail}
          </Typography>
          &nbsp;as your Google-associated email address for use with Documents.
        </Typography>
        <hr className="my-6 opacity-30" />
        <Typography className="mb-4" variant="subtitle1">
          Do you wish to update your Documents email address to {user.email}?
        </Typography>
        <Typography>
          If so, all of your existing documents will be migrated to your
          newly-linked Google Drive.
        </Typography>
      </DialogContent>
      <DialogActions className="flex-col gap-2 px-8 sm:flex-row md:px-12">
        <Button
          fullWidth={isSmall}
          loading={saving}
          variant="outlined"
          onClick={handleDontUpdateClick}
        >
          No, don’t update it
        </Button>
        <Button
          color="primary"
          disabled={saving}
          fullWidth={isSmall}
          variant="contained"
          onClick={handleUpdateClick}
        >
          Yes, update my email address
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EmailUpdatedModal;
