import { ONE, ZERO } from '@/constants';
import { Typography } from '@/ui';
import { getChoiceText } from '@/utils';

import Pill from './Pill';

import type { SurveyQuestion, SurveyResult } from '@/types';
import type { FC } from 'react';

interface ChoiceProps {
  question: SurveyQuestion;
  results: SurveyResult[];
}

const Choice: FC<ChoiceProps> = ({ question, results }) => {
  const { name = '', options } = question;

  const data = options
    ?.map((option) => {
      const total = results.reduce((totalResult, result) => {
        const hasChoice = result[name]?.choices?.find(
          (choice: string) => choice === option,
        );
        if (hasChoice) return totalResult + ONE;
        return totalResult;
      }, ZERO);
      return { option, text: getChoiceText(results.length, total), total };
    })
    .sort((a, b) => b.total - a.total);

  if (!options || !options.length) return null;

  return (
    <div className="grid lg:grid-cols-2">
      {data?.map(({ option, text }) => (
        <div
          key={`choices-${name}-${option}`}
          className="mb-6 mr-8 flex items-center"
        >
          <Pill text={text} />
          <Typography
            className=" overflow-x-hidden text-ellipsis whitespace-nowrap text-bloom-600"
            variant="h6"
          >
            {option}
          </Typography>
        </div>
      ))}
    </div>
  );
};

export default Choice;
