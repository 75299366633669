import { type FC, useContext, useState } from 'react';
import { useNavigate } from 'react-router';

import { AppContext } from '@/context';
import { Avatar, Button, Icon, IconButton, Paper, Typography } from '@/ui';
import { track } from '@/utils';

import type { User } from '@/types';

import ScheduleSession from '@/components/Cal/scheduleSession';

interface Props {
  coach: User;
  refetch: () => void;
  user: User;
}

const CoachCard: FC<Props> = ({ coach, user, refetch }) => {
  const navigate = useNavigate();
  const { setChatId } = useContext(AppContext);
  const [openSchedule, setOpenSchedule] = useState<User | null>(null);

  const showScheduleButton = !!coach.coachProfile?.calendarUrl;

  const onProfile = () => {
    track('Clicked Coach Profile', {
      coachId: coach.id,
    });
    navigate(`/m/profiles-coach/${coach.id}`);
  };
  const onMessage = () => {
    track('Clicked Message Coach', {
      coachId: coach.id,
    });
    setChatId(coach.cometChatId);
  };
  const onSchedule = () => {
    track('Clicked Schedule Button', {
      coachId: coach.id,
    });
    setOpenSchedule(coach);
  };

  const onCloseModal = () => {
    refetch();
    setOpenSchedule(null);
  };

  return (
    <>
      <Paper className="flex w-full rounded-xl p-6" variant="outlined">
        <div className="hidden md:block">
          <Avatar
            firstName={coach.firstName}
            lastName={coach.lastName}
            size="l"
            src={coach.avatarUrl ?? ''}
          />
        </div>
        <div className="flex flex-col p-0 px-2">
          <Typography className="mx-4 select-none" variant="h6">
            {coach.firstName} {coach.lastName}
          </Typography>
          <Typography
            className="mx-4 select-none text-cement-400"
            variant="body2"
          >
            {coach.coachProfile?.role.length
              ? `${coach.coachProfile.role}, `
              : ''}
            {coach.profile?.company}
            {coach.profile?.pronouns ? (
              <Typography variant="caption">
                {' '}
                ({coach.profile.pronouns})
              </Typography>
            ) : null}
          </Typography>
          <Typography
            className="mx-4 select-none text-cement-400"
            variant="body2"
          >
            {coach.coachProfile?.focus}
          </Typography>
          <div className="mt-4 flex gap-2 px-4">
            {showScheduleButton && (
              <Button title="Schedule" variant="contained" onClick={onSchedule}>
                Schedule
              </Button>
            )}

            <div className="group">
              <IconButton
                title="View profile"
                variant="contained"
                onClick={onProfile}
              >
                <Icon>account_circle</Icon>
              </IconButton>
            </div>

            <div className="group">
              <IconButton
                title="Send message"
                variant="outlined"
                onClick={onMessage}
              >
                <Icon>chat_bubble</Icon>
              </IconButton>
            </div>
          </div>
        </div>
      </Paper>

      {openSchedule && (
        <ScheduleSession
          client={user}
          close={onCloseModal}
          coach={openSchedule}
        />
      )}
    </>
  );
};

export default CoachCard;
