import { useState } from 'react';

import { track } from '@/utils';

import Desktop from './Desktop';
import Mobile from './Mobile';
import Modal from './Modal';

import type { FC } from 'react';

interface CallCardProps {
  desktop?: boolean;
  email: string;
  name: string;
}

const CallCard: FC<CallCardProps> = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const close = () => setIsOpen(false);

  const open = () => {
    track('Click Schedule Call Onboarding');
    setIsOpen(true);
  };

  const { desktop } = props;
  return (
    <>
      {desktop && <Desktop open={open} />}
      {!desktop && <Mobile open={open} />}
      <Modal close={close} open={isOpen} {...props} />
    </>
  );
};

export default CallCard;
