import Documents from '@/components/Documents';

const DocumentsPage = () => (
  <div className="-mx-4 -mb-4 flex flex-1 flex-col p-4 md:p-4">
    <div className="-my-4 flex flex-1 flex-col p-2 md:p-4">
      <Documents />
    </div>
  </div>
);

export default DocumentsPage;
