import { useMutation, useQuery } from '@tanstack/react-query';

import {
  deleteDocument,
  getDocuments,
  patchDocument,
  postDocument,
} from '@/api';
import { QUERY_KEY_DOCUMENTS } from '@/constants';

import type { DocumentsApiOptions } from '@/api';
import type { Document } from '@/types';
import type { UseQueryOptions } from '@tanstack/react-query';

export const useGetDocuments = (
  params: DocumentsApiOptions,
  options?: UseQueryOptions,
) =>
  useQuery<{ items: Document[]; pages: number; total: number }, Error>({
    enabled: options ? options.enabled : true,
    placeholderData: { items: [], pages: 0, total: 0 },
    queryFn: () => getDocuments(params),
    queryKey: [QUERY_KEY_DOCUMENTS, params],
  });

export const useCreateDocument = () =>
  useMutation({ mutationFn: postDocument });
export const useDeleteDocument = () =>
  useMutation({ mutationFn: deleteDocument });
export const usePatchDocument = () =>
  useMutation({ mutationFn: patchDocument });
