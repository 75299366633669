import { useView } from '@/hooks';
import { Icon, Link } from '@/ui';

import Header from './Header';
import Summary from './Summary';
import Survey from './Survey';

const Insights = () => {
  const { isClient } = useView();

  if (isClient) {
    return (
      <div className="container flex flex-col p-4 lg:px-32">
        <Link
          className="flex no-underline"
          color="text.primary"
          href="/m/insights"
        >
          <Icon>chevron_left</Icon>Insights
        </Link>
        <Survey />
      </div>
    );
  }

  return (
    <div className="m-6 flex flex-col lg:mx-auto lg:w-280 ">
      <Header />
      <Summary />
    </div>
  );
};

export default Insights;
