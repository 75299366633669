import { Button, Dialog, DialogActions, DialogContent, Typography } from '@/ui';

import type { Session } from '@/types';
import type { FC } from 'react';

interface ReschedulePromptProps {
  onClose: () => void;
  onContinue: () => void;
  session: Session;
}
const ReschedulePrompt: FC<ReschedulePromptProps> = ({
  session,
  onContinue,
  onClose,
}) => {
  const coachName = session.coach.fullName;
  const withName = coachName ? `with ${coachName}` : '';

  return (
    <Dialog open>
      <DialogContent>
        <Typography className="mb-1.5" variant="h5">
          Do you want to reschedule a new Bi-Weekly Session {withName}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          No thanks
        </Button>
        <Button variant="contained" onClick={onContinue}>
          Yes, continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReschedulePrompt;
