import MuiDialogActions from '@mui/material/DialogActions';
import { styled } from '@mui/material/styles';

import type { DialogActionsProps } from '@mui/material/DialogActions';
import type { FC } from 'react';

const StyledDialogActions = styled(MuiDialogActions)<DialogActionsProps>(
  ({ theme }) => ({
    borderTop: 'solid',
    borderTopColor: theme.extendedColors.cement[200],
    borderWidth: 1,
    padding: '1.25rem 3rem',
  }),
);

const DialogActions: FC<DialogActionsProps> = ({ className, ...rest }) => (
  <StyledDialogActions {...rest} className={className} />
);
DialogActions.displayName = 'DialogActions';

export default DialogActions;
export type { DialogActionsProps };
