export enum MessageRole {
  ASSISTANT = 'assistant',
  SYSTEM = 'system',
  USER = 'user',
}

export interface ThreadMessageContentText {
  value: string;
  // annotations
}

// TODO: Handle inserting files
export interface ThreadMessageContentFile {}

export interface ThreadMessageContent {
  imageFile?: ThreadMessageContentFile;
  text?: ThreadMessageContentText;
  type: 'text' | 'file';
}

export interface ThreadMessage {
  assistantId: string;
  content: ThreadMessageContent[];
  created_at: Number;
  id: string;
  // TODO handle fileIds
  // fileIds
  //
  // TODO: Do we care about these on the client?
  // assistantId: string | null,
  // runId: string | null
  role: MessageRole;
  threadId: string;
}

export interface Thread {
  additionalInstructions: string; // used for admins understanding what prompt is
  id: string;
  instructions: string; // used for admins understanding what prompt is
  messages: ThreadMessage[];
  metadata: {
    userId: string;
  };
}
