import { Card, CardContent, Skeleton } from '@/ui';

const CoachInfoLoading = () => (
  <Card>
    <CardContent>
      <Skeleton className="mb-2" width="23%" />
      <div className="flex items-center">
        <Skeleton
          className="shrink-0"
          height={48}
          variant="circular"
          width={48}
        />
        <div className="ml-5 w-full">
          <Skeleton width="36%" />
        </div>
      </div>
    </CardContent>
  </Card>
);

export default CoachInfoLoading;
