import { Typography } from '@/ui';

import type { FC } from 'react';

const MatchingCoach: FC = () => (
  <div className="flex w-full flex-1 items-center justify-center py-4">
    <div className="flex items-center justify-center gap-2 rounded-xl border border-solid border-cement-200 bg-white px-6 py-8">
      <div className="flex flex-col p-0">
        <Typography className="mb-2 select-none px-4" variant="h4">
          Hang tight, we're matching you with just the right coach
        </Typography>
        <Typography className="select-none px-4 py-2 text-cement-400">
          Matching takes 2-3 business days as we put a lot of care into this
          process—thanks for your patience!
        </Typography>
        <Typography className="mb-2 select-none px-4 py-2 text-cement-400">
          Once matched, you'll see your coach here, and you'll be able to
          schedule your first session.&nbsp;
        </Typography>
      </div>
      <img
        alt="matching"
        className="hidden select-none md:block"
        src="https://mento-space.nyc3.digitaloceanspaces.com/assets/sessions-matching.svg"
      />
    </div>
  </div>
);

export default MatchingCoach;
