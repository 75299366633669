import { useEffect, useState } from 'react';

import { ZERO } from '@/constants';
import { useSurvey } from '@/hooks';

import Data from './Data';
import Header from './Header';
import List from './List';

import type { SurveyResponse, SurveyResult } from '@/types';

const Responses = () => {
  const [selected, setSelected] = useState(ZERO);
  const [show, setShow] = useState(false);
  const { survey, isSurveyLoading } = useSurvey();

  const toggleShow = () => setShow(!show);
  const select = (index: number) => () => setSelected(index);

  const responses =
    survey.responses?.sort(
      (a, b) =>
        new Date(b.updatedAt).valueOf() - new Date(a.updatedAt).valueOf(),
    ) ?? ([] as SurveyResponse[]);

  useEffect(() => {
    if (survey.id) {
      setSelected(responses.findIndex(({ results }) => !!results));
    }
  }, [survey.id, isSurveyLoading]);

  return (
    <div className="flex flex-col lg:flex-row">
      <div className="flex flex-col">
        <Header responses={responses} show={show} toggleShow={toggleShow} />
        <List
          responses={responses}
          select={select}
          selected={selected}
          show={show}
        />
      </div>
      <Data results={(responses[selected]?.results ?? {}) as SurveyResult} />
    </div>
  );
};

export default Responses;
