// @ts-nocheck -- TODO: Update Boulder page to use new endpoints and APIs.
import { useNavigate } from 'react-router-dom';

import { BOULDER_SIZES, ENTER } from '@/constants';
import { useGrowthPlan, useView } from '@/hooks';

import BoulderImage from '../BoulderImage';

import type { Milestone } from '@/types';
import type { FC, KeyboardEvent } from 'react';

interface BouldersProps {
  milestones: Milestone[];
}

const Boulders: FC<BouldersProps> = ({ milestones }) => {
  const { plan } = useGrowthPlan();
  const navigate = useNavigate();
  const { clientId, isOverview } = useView();

  const onClick = (milestoneId: string) => () => {
    if (isOverview) {
      navigate(`/coach/members/${clientId}/growth/${milestoneId}`);
    } else {
      navigate(`/m/growth/${plan.id}/milestone/${milestoneId}`);
    }
  };

  const onKeyDown = (id: string) => (event: KeyboardEvent<HTMLElement>) => {
    if (event.key === ENTER) {
      onClick(id)();
    }
  };

  if (!milestones.length) return null;

  return (
    <div className="mt-auto flex max-h-200 w-full max-w-max flex-col-reverse flex-wrap content-start items-center overflow-x-scroll pl-8">
      {milestones.map((milestone, index) => (
        <div
          key={`completed-boulders-${milestone.id}`}
          className={`group relative mr-40 cursor-pointer ${BOULDER_SIZES[index]}`}
          role="button"
          tabIndex={0}
          onClick={onClick(milestone.id)}
          onKeyDown={onKeyDown(milestone.id)}
        >
          <div className="absolute flex h-full w-full items-center opacity-0 transition duration-200 ease-out group-hover:opacity-100">
            <div className="shadow-li z-50 mx-auto max-w-full rounded-lg bg-black p-4 py-1 text-sm font-bold text-white">
              <p className="overflow-x-hidden text-ellipsis text-center">
                {milestone.title}
              </p>
            </div>
          </div>
          <div className={BOULDER_SIZES[index]}>
            <BoulderImage milestone={milestone} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default Boulders;
