import { useDrag, useDrop } from 'react-dnd';

import { dragStepConfig, dropStepConfig } from '@/utils';

import Row from './Row';

import type { MilestoneStep } from '@/types';
import type { FC } from 'react';

interface DraggableRowProps {
  onReorder: (stepId: string, destinationId: string) => Promise<void>;
  step: MilestoneStep;
}

const DraggableRow: FC<DraggableRowProps> = ({ onReorder, step }) => {
  const [{ isDragging }, drag] = useDrag(dragStepConfig(onReorder, step), [
    step,
  ]);
  const [{ direction, isOver }, drop] = useDrop(dropStepConfig(step), [step]);

  return (
    <Row
      key={step.order}
      ref={(node) => drag(drop(node))}
      isDragging={isDragging}
      isOver={isOver ? direction : undefined}
      step={step}
      isDraggable
    />
  );
};

export default DraggableRow;
