import { styled } from '@mui/material/styles';
import { clsx } from 'clsx';
import { useState } from 'react';

import { MAX_IN_PROGRESS_MILESTONES, ZERO } from '@/constants';
import { useGrowthPlan } from '@/hooks';
import { Button, Card, Typography } from '@/ui';

import SidebarCard from '../SidebarCard';

import MilestoneData from './MilestoneData';

import type { CardProps } from '@/ui';

import MilestoneDialog from '@/components/Growth/MilestoneDialog';

/* eslint-disable @typescript-eslint/no-magic-numbers -- No sense in using variables for CSS stylings. */
const StyledMilestoneWrapper = styled(Card)<CardProps>(({ theme }) => ({
  '&.empty': {
    boxShadow: `
    0 10px 0 -1px ${theme.palette.background.paper},
    0 10px ${theme.palette.divider},
    0 20px 0 -1px ${theme.palette.background.paper},
    0 20px ${theme.palette.divider} !important`,
    marginBottom: theme.spacing(2),
  },
}));
/* eslint-enable @typescript-eslint/no-magic-numbers */

const MilestoneList = () => {
  const {
    plan: { milestones },
  } = useGrowthPlan();
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleOpenDialog = () => setDialogOpen(true);
  const inProgressMilestones = milestones.filter(
    ({ completedAt }) => !completedAt,
  );

  const hasMaxMilestones =
    inProgressMilestones.length >= MAX_IN_PROGRESS_MILESTONES;

  return (
    <>
      <SidebarCard
        icon="emoji_flags"
        isClickable={false}
        title="Milestones"
        onChange={handleOpenDialog}
      >
        <Typography className="mb-4 block" variant="caption">
          What are short term goals you need to accomplish to achieve your one
          year vision?
        </Typography>
        <StyledMilestoneWrapper
          className={clsx('w-full px-4', {
            empty: !inProgressMilestones.length,
          })}
          role="button"
          tabIndex={0}
          variant="outlined"
        >
          {inProgressMilestones
            .slice(ZERO, MAX_IN_PROGRESS_MILESTONES)
            .map((milestone, index) => (
              <MilestoneData
                key={milestone.id}
                isLast={index === MAX_IN_PROGRESS_MILESTONES - 1}
                milestone={milestone}
              />
            ))}
          {!hasMaxMilestones && (
            <Button
              className="my-4"
              color="primary"
              variant="contained"
              fullWidth
              light
              onClick={handleOpenDialog}
            >
              Add Milestone
            </Button>
          )}
        </StyledMilestoneWrapper>

        {hasMaxMilestones && (
          <Typography className="mt-3 block" variant="caption">
            You can have up to 3 active milestones. To add a new milestone,
            first complete one of your active milestones.
          </Typography>
        )}
      </SidebarCard>
      <MilestoneDialog isOpen={dialogOpen} setOpen={setDialogOpen} />
    </>
  );
};

export default MilestoneList;
