import { useState } from 'react';

import { useCurrentUser } from '@/hooks';
import { DocumentActions } from '@/types';
import { Icon, IconButton, Menu } from '@/ui';
import { getDownloadLink } from '@/utils';

import Item from './Item';
import Modals from './Modals';

import type { Document } from '@/types';
import type { FC, MouseEvent } from 'react';

interface EllipsisProps {
  className?: string;
  document: Document;
}

const Ellipsis: FC<EllipsisProps> = ({ className, document }) => {
  const { user, isUserLoading } = useCurrentUser();
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);
  const [action, setAction] = useState(DocumentActions.None);
  const { mimeType, isTemplate } = document;

  const isOwn = document.userId === user.id;
  const isAuthor = document.authorId === user.id;

  const onMenuClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchor(event.currentTarget);
  };

  const onClose = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchor(null);
  };

  const onClick =
    (clicked: DocumentActions) => (event: MouseEvent<HTMLElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setAnchor(null);
      setAction(clicked);
    };

  const onDownload =
    (clicked: DocumentActions) => (event: MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      setAnchor(null);
      setAction(clicked);
    };

  if (isUserLoading) return null;

  let actions = [
    DocumentActions.Rename,
    DocumentActions.ShareSettings,
    DocumentActions.Duplicate,
    DocumentActions.Download,
    DocumentActions.Share,
    DocumentActions.Delete,
  ];

  if (isTemplate && !user.superAdmin) {
    actions = [DocumentActions.Download];
  }

  if (!isTemplate && !isOwn && !isAuthor) {
    actions = [DocumentActions.Download, DocumentActions.Share];
  }

  return (
    <>
      <IconButton
        className={className}
        color="primary"
        data-testid="DocumentEllipsisMenu"
        onClick={onMenuClick}
      >
        <Icon>more_vert</Icon>
      </IconButton>
      <Menu anchorEl={anchor} open={!!anchor} onClose={onClose}>
        <div className="py-1">
          {actions.map((a) => {
            if (a === DocumentActions.Download) {
              return (
                <Item
                  key={a}
                  action={a}
                  href={getDownloadLink(document.reference, mimeType)}
                  onClick={onDownload}
                />
              );
            }
            return <Item key={a} action={a} onClick={onClick} />;
          })}
        </div>
      </Menu>
      <Modals
        action={action}
        documentId={document.id}
        isTemplate={isTemplate}
        setAction={setAction}
      />
    </>
  );
};

export default Ellipsis;
