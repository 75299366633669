import { Button, Grid, TextField, Typography } from '@/ui';

import type { ChangeEvent, FC, KeyboardEvent } from 'react';

import LockCard from '@/components/Profile/Card/Lock';
import Cal from '@/components/Profile/Coach/Edit/Account/Hours/Cal';

interface EditCoachAdminSettingsProps {
  calendarUrl?: string;
  capacity?: number;
  email: string;
  isActive: boolean;
  onActivateChange: (isActivated: boolean) => void;
  onCapacityChange: (value?: number) => void;
  onRemove: () => void;
}

const EditCoachAdminSettings: FC<EditCoachAdminSettingsProps> = ({
  isActive = false,
  email,
  calendarUrl,
  capacity = 0,
  onActivateChange,
  onCapacityChange,
  onRemove,
}) => {
  const onActivate = () => onActivateChange(!isActive);
  const onCapacity = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
    onCapacityChange(parseInt(e.target.value || '0', 10));
  const onCapacityKeyUp = (e: KeyboardEvent<HTMLDivElement>) => {
    if (!/[0-9]/u.test(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <Grid spacing={4} container>
      <Grid md={5} xs={12} item>
        <Typography className="mb-2" variant="h4">
          Admin settings
        </Typography>
        <div className="mt-4 flex flex-row gap-2">
          <Button
            color={isActive ? 'error' : 'primary'}
            variant="contained"
            onClick={onActivate}
          >
            {isActive ? 'Deactivate coach' : 'Activate coach'}
          </Button>
          {!isActive && (
            <Button color="error" variant="contained" onClick={onRemove}>
              Remove coach
            </Button>
          )}
        </div>
      </Grid>
      <Grid className="mt-1 flex flex-col gap-3" md={7} xs={12} item>
        <LockCard icon="mail" title={email} />
        <Cal username={calendarUrl ?? ''} hideAvailability />
        <div className="mt-2 flex flex-col gap-4">
          <TextField
            label="Capacity"
            placeholder="Capacity"
            value={capacity}
            onChange={onCapacity}
            onKeyUp={onCapacityKeyUp}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default EditCoachAdminSettings;
