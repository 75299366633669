import clsx from 'clsx';
import { type FC } from 'react';

import { Card, CardContent, Checkbox, Icon, Typography } from '@/ui';

const OptionsCard: FC<{
  disabled?: boolean;
  icon: string;
  isChecked: boolean;
  label: string;
  onChange: (value: boolean) => void;
  shadow?: boolean;
  subtitle?: string;
}> = ({
  icon = '',
  label = '',
  onChange = undefined,
  isChecked = false,
  subtitle = '',
  shadow = false,
  disabled = false,
}) => {
  const cannotBeClicked = disabled && !isChecked;
  const onToggle = () => {
    if (cannotBeClicked) {
      return;
    }
    onChange?.(!isChecked);
  };

  return (
    <Card
      className={clsx('group relative flex flex-col', {
        'bg-sage-200': isChecked,
        'cursor-pointer hover:bg-sage-200 hover:border-sage-400':
          !cannotBeClicked,
        'hover:shadow-md': shadow && !cannotBeClicked,
      })}
      onClick={onToggle}
    >
      <CardContent>
        <div
          className={clsx('flex w-full items-start lg:flex-col', {
            'flex-col': subtitle,
          })}
        >
          <div
            className={clsx('flex h-12 min-h-6 w-12 min-w-8 rounded-full', {
              'bg-cement-300': !isChecked,
              'bg-sage-500': isChecked,
              'group-hover:bg-sage-500': !cannotBeClicked,
            })}
          >
            <Icon className="m-auto !text-2xl text-white">{icon}</Icon>
          </div>
          <div className="my-auto flex flex-col p-2 lg:px-0 lg:py-8">
            <Typography variant="subtitle1">{label}</Typography>
            <Typography variant="body2">{subtitle}</Typography>
          </div>
          <div className="absolute right-2 top-2">
            <Checkbox
              checked={isChecked}
              className="rounded-full text-lg"
              disabled={cannotBeClicked}
              id={`${label}-${subtitle}-options-card`}
              onChange={onToggle}
            />
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default OptionsCard;
