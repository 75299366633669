import { FIVE, FOUR, ONE, SEVEN, SIX, THREE, TWO, ZERO } from '@/constants';

import BoulderImage from '@/components/Growth/BoulderImage';

// This entire file is just a commodity to create the
// little rocks that appear on the cards as they need specific
// size and positioning and colors by design

export const ZeroMilestones = () => (
  <div className="my-auto mr-4 h-10">
    <BoulderImage
      milestone={{
        completeSteps: ZERO,
        id: '',
        totalSteps: ZERO,
      }}
    />
  </div>
);

export const OneMilestone = () => (
  <div className="my-auto mr-5 h-8">
    <BoulderImage
      milestone={{
        completeSteps: TWO,
        id: '52',
        totalSteps: THREE,
      }}
    />
  </div>
);

export const TwoMilestones = () => (
  <div className="relative my-auto mr-1 h-11 w-14">
    <div className="absolute mx-2 my-auto h-8">
      <BoulderImage
        milestone={{
          completeSteps: FIVE,
          id: '22',
          totalSteps: 8,
        }}
      />
    </div>
    <div className="absolute bottom-0 right-0 mt-auto h-4">
      <BoulderImage
        milestone={{
          completeSteps: TWO,
          id: '11',
          totalSteps: FOUR,
        }}
      />
    </div>
  </div>
);

export const ThreeMilestones = () => (
  <div className="relative my-auto mr-1 h-11 w-14">
    <div className="absolute left-1.5 mx-2 my-auto h-4">
      <BoulderImage
        milestone={{
          completeSteps: TWO,
          id: '33',
          totalSteps: SIX,
        }}
      />
    </div>
    <div className="absolute bottom-0 left-0 mx-2 my-auto h-6">
      <BoulderImage
        milestone={{
          completeSteps: FIVE,
          id: '22',
          totalSteps: SEVEN,
        }}
      />
    </div>
    <div className="absolute bottom-4 right-0 mx-2 my-auto h-3">
      <BoulderImage
        milestone={{
          completeSteps: ONE,
          id: '44',
          totalSteps: TWO,
        }}
      />
    </div>
  </div>
);
