import { useState } from 'react';

import { updateUser } from '@/api';
import { SMALL_BREAKPOINT } from '@/constants';
import { useCurrentUser } from '@/hooks';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  TextField,
  Typography,
  useMediaQuery,
} from '@/ui';

import { isEmailAddressRepeted } from '../utils';

import type { ChangeEvent, FC } from 'react';

interface LinkGoogleAccountModaProps {
  hideHeader?: boolean;
  nextStepOnGoogleAccountLink: () => void;
  nextStepOnLinkGoogleAccountClick: () => void;
}

enum LinkAccountFlow {
  hasGoogleAccount = 0,
  NoGoogleAccount = 1,
}

const LinkGoogleAccountModal: FC<LinkGoogleAccountModaProps> = ({
  hideHeader,
  nextStepOnGoogleAccountLink,
  nextStepOnLinkGoogleAccountClick,
}) => {
  const { user, isUserLoading } = useCurrentUser();
  const [saving, setSaving] = useState(false);
  const [flow, setFlow] = useState(LinkAccountFlow.hasGoogleAccount);
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const isSmall = useMediaQuery(SMALL_BREAKPOINT);

  const handleDontHaveGoogleAccountClick = () =>
    setFlow(LinkAccountFlow.NoGoogleAccount);
  const handleAlreadyHaveGoogleAccountClick = () =>
    setFlow(LinkAccountFlow.hasGoogleAccount);

  const handleSetupGoogleAccountClick = () => {
    window.open('https://accounts.google.com/signup', '_blank', 'noreferrer');
    nextStepOnGoogleAccountLink();
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };
  const handleOnLinkGoogleAccountClick = async () => {
    try {
      setSaving(true);
      setError('');
      await updateUser(user.id, { documentsEmail: email });
      nextStepOnLinkGoogleAccountClick();
    } catch (err) {
      if (isEmailAddressRepeted(err)) {
        setError(
          'That email address is already in use. Please enter another email address.',
        );
      } else {
        setError(
          'This email address could not be verified as a Google Account. Please enter another email address and try again.',
        );
      }
    } finally {
      setSaving(false);
    }
  };

  if (isUserLoading) {
    return null;
  }

  return (
    <Dialog PaperProps={{ className: 'max-w-2xl' }} open>
      {!hideHeader && (
        <DialogTitle className="px-8 pb-0 pt-4 md:px-12 md:pt-8">
          <Typography variant="overline">Step 2 of 2</Typography>
        </DialogTitle>
      )}
      <DialogContent className="px-8 pb-8 md:px-12 md:pb-12">
        <Typography className="mb-4" variant="h5">
          Enable Documents collaboration with your coach by adding a Google
          Account
        </Typography>
        <Typography>
          We noticed your email address isn’t connected to Google. In order to
          create, save, and share Documents with your coach, you must have a
          Google-associated email address.
        </Typography>
        <hr className="my-6 opacity-30" />
        {flow === LinkAccountFlow.hasGoogleAccount ? (
          <>
            <Typography className="mb-4" variant="subtitle1">
              Already have a Google account?
            </Typography>
            <Typography className="mb-4">
              Enter the email address below. This email address will be used for
              Documents which will connect with your Google Drive.
            </Typography>
            <TextField
              error={!!error}
              helperText={error}
              label="Enter your Google email address"
              value={email}
              fullWidth
              onChange={handleEmailChange}
            />
          </>
        ) : (
          <>
            <Typography className="mb-4" variant="subtitle1">
              Don’t already have a Google account?
            </Typography>
            <Typography>
              Using{' '}
              <Typography className="inline" variant="subtitle1">
                {user.email}
              </Typography>
              , please set up a Google Account so you can gain access to
              Documents. All of your documents – along with available templates
              – are powered by Google. Your own Google Drive is required for
              working on and saving your documents.
            </Typography>
          </>
        )}
      </DialogContent>
      <DialogActions className="flex-col gap-2 px-8 sm:flex-row md:px-12">
        {flow === LinkAccountFlow.hasGoogleAccount ? (
          <>
            <Button
              fullWidth={isSmall}
              loading={saving}
              variant="outlined"
              onClick={handleDontHaveGoogleAccountClick}
            >
              Don’t have a Google account?
            </Button>
            <Button
              className="ml-0"
              color="primary"
              fullWidth={isSmall}
              loading={saving}
              variant="contained"
              onClick={handleOnLinkGoogleAccountClick}
            >
              Link Google account
            </Button>
          </>
        ) : (
          <>
            <Button
              fullWidth={isSmall}
              loading={saving}
              variant="outlined"
              onClick={handleAlreadyHaveGoogleAccountClick}
            >
              I already have a Google account
            </Button>
            <Button
              className="ml-0"
              color="primary"
              endIcon={<Icon>open_in_new</Icon>}
              fullWidth={isSmall}
              loading={saving}
              variant="contained"
              onClick={handleSetupGoogleAccountClick}
            >
              Set up Google Account
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default LinkGoogleAccountModal;
