import mentoApi from './mentoApi';

import type { User } from '@stytch/vanilla-js';

export const login = ({
  emails,
  name,
  providers,
  user_id,
}: User): Promise<Response> =>
  mentoApi.post('/login', {
    avatarUrl: providers[0]?.profile_picture_url ?? '',
    email: emails[0]?.email,
    sub: user_id,
    ...name, // first_name, last_name
  });

export const logout = (): Promise<Response> => mentoApi.delete('/logout');
