import { useState } from 'react';

import { useCurrentUser } from '@/hooks';

import ConfirmModal from '../Common/Confirm';
import LinkGoogleAccountModal from '../Common/LinkGoogleAccount';

import EmailUpdatedModal from './Modal';
import { EmailUpdatedSteps } from './types';

const EmailUpdated = () => {
  const { refetchUser } = useCurrentUser();

  const [step, setStep] = useState(EmailUpdatedSteps.Welcome);
  const nextStepOnLinkGoogleAccountClick = () =>
    setStep(EmailUpdatedSteps.Done);
  const nextStepOnGoogleAccountLink = () => setStep(EmailUpdatedSteps.Confirm);
  const confirmModalNextStep = async () => {
    setStep(EmailUpdatedSteps.Done);
    await refetchUser();
  };
  const confirmModalPreviousStep = () =>
    setStep(EmailUpdatedSteps.LinkGoogleAccount);
  switch (step) {
    case EmailUpdatedSteps.Welcome:
      return <EmailUpdatedModal setStep={setStep} />;
    case EmailUpdatedSteps.LinkGoogleAccount:
      return (
        <LinkGoogleAccountModal
          nextStepOnGoogleAccountLink={nextStepOnGoogleAccountLink}
          nextStepOnLinkGoogleAccountClick={nextStepOnLinkGoogleAccountClick}
          hideHeader
        />
      );
    case EmailUpdatedSteps.Confirm:
      return (
        <ConfirmModal
          nextStep={confirmModalNextStep}
          previousStep={confirmModalPreviousStep}
        />
      );
    case EmailUpdatedSteps.Done:
      return null;
    default:
      return null;
  }
};

export default EmailUpdated;
