import { Card, CardContent, Skeleton, Table, TableLoading } from '@/ui';

const DocumentsLoading = () => (
  <Card>
    <CardContent>
      <Skeleton className="mb-2" width="25%" />
      <Table>
        <TableLoading rows={5} />
      </Table>
    </CardContent>
  </Card>
);

export default DocumentsLoading;
