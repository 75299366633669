import { useState } from 'react';

import { useCurrentUser } from '@/hooks';

import ConfirmModal from '../Common/Confirm';
import LinkGoogleAccountModal from '../Common/LinkGoogleAccount';

import CongratulationsModal from './Steps/Congratulations';
import TimezoneModal from './Steps/Timezone';
import WelcomeModal from './Steps/Welcome';
import { NotAssociatedSteps } from './types';

const NotAssociated = () => {
  const { refetchUser } = useCurrentUser();

  const [step, setStep] = useState(NotAssociatedSteps.Welcome);
  const sendToDone = async () => {
    setStep(NotAssociatedSteps.Done);
    await refetchUser();
  };
  const nextStepOnLinkGoogleAccountClick = () =>
    setStep(NotAssociatedSteps.Congrats);
  const nextStepOnGoogleAccountLink = () => setStep(NotAssociatedSteps.Confirm);
  const confirmModalNextStep = () => setStep(NotAssociatedSteps.Congrats);
  const confirmModalPreviousStep = () =>
    setStep(NotAssociatedSteps.LinkGoogleAccount);

  switch (step) {
    case NotAssociatedSteps.Welcome:
      return <WelcomeModal setStep={setStep} />;
    case NotAssociatedSteps.Timezone:
      return <TimezoneModal setStep={setStep} />;
    case NotAssociatedSteps.LinkGoogleAccount:
      return (
        <LinkGoogleAccountModal
          nextStepOnGoogleAccountLink={nextStepOnGoogleAccountLink}
          nextStepOnLinkGoogleAccountClick={nextStepOnLinkGoogleAccountClick}
        />
      );
    case NotAssociatedSteps.Confirm:
      return (
        <ConfirmModal
          nextStep={confirmModalNextStep}
          previousStep={confirmModalPreviousStep}
        />
      );
    case NotAssociatedSteps.Congrats:
      return <CongratulationsModal setStep={sendToDone} />;
    case NotAssociatedSteps.Done:
      return null;
    default:
      return null;
  }
};

export default NotAssociated;
