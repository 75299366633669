import { MESSAGE_TYPES, MessageRole } from '@/types';

import type { MessageThreadMessage } from '@/types';

export const getMessageType = (
  message: MessageThreadMessage,
): MESSAGE_TYPES => {
  if (message.role === MessageRole.USER) {
    return MESSAGE_TYPES.MESSAGE;
  }

  if (!message.items) {
    return MESSAGE_TYPES.TEXT;
  }
  if (message.items) {
    return MESSAGE_TYPES.LIST;
  }
  return MESSAGE_TYPES.MESSAGE;
};
