import { Typography } from '@/ui';

import type { FC } from 'react';

interface RowProps {
  body: string;
  header: string;
  src: string;
}

const Row: FC<RowProps> = ({ body, header, src }) => (
  <div className="mb-6 flex flex-1 flex-col items-center gap-2">
    <img alt={header} src={src} width="200" />
    <Typography variant="h5">{header}</Typography>
    <Typography className="w-3/4 text-center" variant="body1">
      {body}
    </Typography>
  </div>
);

export default Row;
