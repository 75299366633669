import { captureException, captureMessage } from '@sentry/react';
import { useState } from 'react';

import { shareSurvey } from '@/api';
import { useSurvey } from '@/hooks';
import { Button, Dialog, DialogActions, DialogContent, Typography } from '@/ui';

import type { FC } from 'react';

interface ShareProps {
  isOpen: boolean;
  onClose: () => void;
}

const Share: FC<ShareProps> = ({ isOpen, onClose }) => {
  const [loading, setLoading] = useState(false);
  const { survey, isSurveyLoading, refetchSurvey } = useSurvey();

  const onShare = async () => {
    try {
      setLoading(true);
      await shareSurvey(survey.id);
      await refetchSurvey();
      onClose();
    } catch (error) {
      captureMessage(`Error sharing survey ${survey.id}`);
      captureException(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog className="w-120 !px-12 !py-9" open={isOpen}>
      <DialogContent className="mb-16 flex flex-col">
        <Typography className="mb-8" variant="h6">
          Before you share, please:
        </Typography>
        <div>
          <Typography className="text-cement-600" variant="body1">
            Follow our guidelines to help your member make the most out of the
            results:
          </Typography>
          <br />
          <ul className="list-disc pl-4">
            <li>
              <Typography variant="body2">
                Share this summary at the beginning of a debriefing session.
              </Typography>
            </li>
            <br />
            <li>
              <Typography variant="body2">
                Avoid letting members interpret the results alone—Walk members
                through the result.
              </Typography>
            </li>
            <br />
            <li>
              <Typography variant="body2">
                Keep respondents' names to yourself, we promised them anonymity.
              </Typography>
            </li>
          </ul>
        </div>
      </DialogContent>

      <DialogActions>
        <Button disabled={loading} variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button
          className="!ml-4"
          loading={isSurveyLoading || loading}
          variant="contained"
          onClick={onShare}
        >
          Share
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Share;
