import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';

import { Avatar, Card, Typography } from '@/ui';

import Company from './Company';
import Footer from './Footer';
import LastLogin from './LastLogin';
import Location from './Location';
import PaymentPlan from './PaymentPlan';
import Status from './Status';
import TimeZone from './TimeZone';

import type { User } from '@/types';
import type { FC } from 'react';

interface ClientCardProps {
  disabled?: boolean;
  hideFooter?: boolean;
  hidePlan?: boolean;
  hideStatus?: boolean;
  navigatePath?: string;
  user: User;
}

const ClientCard: FC<ClientCardProps> = ({
  user,
  disabled = false,
  hidePlan = false,
  hideStatus = false,
  hideFooter = false,
  navigatePath = '/coach/members',
}) => {
  const navigate = useNavigate();
  const onClick = () => {
    if (disabled) return;
    navigate(`${navigatePath}/${user.id}`);
  };

  return (
    <Card
      className={clsx(
        'body-sm flex  flex-col overflow-hidden bg-white pb-4 text-center lg:drop-shadow',
        {
          'cursor-pointer': !disabled,
        },
      )}
      onClick={onClick}
    >
      {!hideStatus && <Status {...user} />}
      <Avatar
        className="mx-auto my-4"
        firstName={user.firstName}
        lastName={user.lastName}
        size="l"
        src={user.avatarUrl || ''}
      />
      <div>
        <Typography variant="subtitle1">{user.fullName}</Typography>
        <Typography className="mb-2 block" variant="caption">
          {user.email}
        </Typography>
        <LastLogin {...user} />
        {user.profile?.company && <Company {...user.profile} />}
        <br />
        {!hidePlan && <PaymentPlan user={user} />}
        {user.profile && (
          <>
            <TimeZone {...user.profile} />
            <Location {...user.profile} />
          </>
        )}
      </div>
      {!hideFooter && <Footer {...user} />}
    </Card>
  );
};

export default ClientCard;
