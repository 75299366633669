import MuiCardActionArea from '@mui/material/CardActionArea';
import { clsx } from 'clsx';

import type { CardActionAreaProps } from '@mui/material/CardActionArea';
import type { FC } from 'react';

const CardActionArea: FC<CardActionAreaProps> = ({ className, ...rest }) => {
  const classes = clsx('mnt-card-action-area', className);

  return <MuiCardActionArea {...rest} className={classes} />;
};
CardActionArea.displayName = 'CardActionArea';

export default CardActionArea;
export type { CardActionAreaProps };
