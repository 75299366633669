import { RELOAD_CAL_MODAL } from '@/constants';
import { Button, Dialog, Icon } from '@/ui';

import Cal from './embed';

import type { Session } from '@/types';
import type { FC } from 'react';

interface CancelRecurringSessionProps {
  close?: () => void;
  session: Session;
}

const CancelRecurringSession: FC<CancelRecurringSessionProps> = ({
  session,
  close = undefined,
}) => {
  const onClose = () => {
    if (!close) {
      setTimeout(() => window.location.reload(), RELOAD_CAL_MODAL);
    } else {
      close();
    }
  };

  return (
    <Dialog fullScreen open>
      <Button className="absolute right-4 top-4 z-10" onClick={onClose}>
        <Icon>close</Icon>
      </Button>
      <Cal calLink={`cancellation-all/${session.bookId}`} />
    </Dialog>
  );
};

export default CancelRecurringSession;
