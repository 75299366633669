export enum EmailTypes {
  ALL = '*',
  SEND_ADDED_CLIENT_TO_COACH = 'sendAddedClientToCoach',
  SEND_ADDED_NOTE_TO_CLIENT = 'sendAddedNoteToClient',
  SEND_ADDED_TOPICS_NOTIFICATION_TO_CLIENT = 'sendAddedTopicsNotificationToClient',
  SEND_ADDED_TOPICS_NOTIFICATION_TO_COACH = 'sendAddedTopicsNotificationToCoach',
  SEND_BOULDER_ADDED_TO_CLIENT = 'sendBoulderAddedToClient',
  SEND_COACH_ASSIGNED_TO_CLIENT = 'sendCoachAssignedToClient',
  SEND_COMMENT_NOTIFICATION_TO_CLIENT = 'sendCommentNotificationToClient',
  SEND_COMMENT_NOTIFICATION_TO_COACH = 'sendCommentNotificationToCoach',
  SEND_DOCUMENT_ADDED_TO_CLIENT = 'sendDocumentAddedToClient',
  SEND_FEEDBACK_RECEIVED = 'sendFeedbackReceived',
  SEND_MISSED_MESSAGE_TO_CLIENT = 'sendMissedMessageToClient',
  SEND_MISSED_MESSAGE_TO_COACH = 'sendMissedMessageToCoach',
  SEND_MULTIPLE_COACHES_ASSIGNED_TO_COACH = 'sendMultipleCoachesAssignedToCoach',
  SEND_PROMPT_ADDED_TO_CLIENT = 'sendPromptAddedToClient',
  SEND_REFLECTION_SHARED_TO_COACH = 'sendReflectionSharedToCoach',
  SEND_SESSION_AND_TOPICS_REMINDER_TO_CLIENT = 'sendSessionAndTopicsReminderToClient',
  SEND_SESSION_FEEDBACK_TO_CLIENT = 'sendSessionFeedbackToClient',
  SEND_SESSION_NOTIFICATION_TO_CLIENT = 'sendSessionNotificationToClient',
  SEND_STEPS_DUE_NOTIFICATION_TO_CLIENT = 'sendStepsDueNotificationToClient',
  SEND_STEP_ADDED_TO_CLIENT = 'sendStepAddedToClient',
  SEND_SURVEY_COMPLETED_TO_COACH = 'sendSurveyCompletedToCoach',
  SEND_SURVEY_TO_RECIPIENT = 'sendSurveyToRecipient',
  SEND_TASKS_DUE_NOTIFICATION_TO_COACH = 'sendTasksDueNotificationToCoach',
}

export const SESSION_EMAIL_SECONDARY_BODY =
  ' <p style="color:#5c5c5c"><b>Can\'t make the session?</b> Please reschedule or cancel on Mento 48 hours before the session start time. Your coach will wait for up to 10 minutes before it\'s considered a missed session. <a style="color:#5c5c5c" href="https://mentoteam.notion.site/Mento-Rescheduling-Cancelation-Policy-ea6ed8fa23fc41a8a4598070ef42fb53">Mento Rescheduling & Cancelation Policy</a>.</p>';
