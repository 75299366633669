import { useNavigate } from 'react-router-dom';

import { Icon, ListItemIcon, MenuItem, Typography } from '@/ui';

import type { FC } from 'react';

interface SideBarAvatarMenuItemProps {
  icon: string;
  label: string;
  onClick?: () => void;
  route?: string;
}

const SideBarAvatarMenuItem: FC<SideBarAvatarMenuItemProps> = ({
  icon = '',
  label = '',
  route = '',
  onClick = undefined,
}) => {
  const navigation = useNavigate();
  const onPreClick = () => {
    onClick?.();

    if (route) {
      navigation(route);
    }
  };

  return (
    <MenuItem className="flex text-cement-500" onClick={onPreClick}>
      <ListItemIcon>
        <Icon className="text-cement-500">{icon}</Icon>
      </ListItemIcon>
      <Typography variant="subtitle1">{label}</Typography>
    </MenuItem>
  );
};

export default SideBarAvatarMenuItem;
