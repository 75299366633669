import { SMALL_VERTICAL_LINE } from '@/constants';

import type { FC } from 'react';

interface VerticalLineProps {
  className?: string;
  height?: number;
}

const VerticalLine: FC<VerticalLineProps> = ({
  className = '',
  height = SMALL_VERTICAL_LINE,
}) => (
  <svg
    className={`my-1 ml-6 ${className}`}
    fill="none"
    height={height}
    viewBox={`0 0 2 ${height}`}
    width="2"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      stroke="#262626"
      strokeDasharray="1,5"
      strokeLinecap="round"
      strokeWidth="2"
      x1="1"
      x2="1"
      y1={height}
      y2="1"
    />
  </svg>
);

export default VerticalLine;
