export const JOB_TITLES = [
  'Account Executive',
  'Account Manager',
  'Accountant',
  'Accounting Analyst',
  'Accounting Director',
  'Administrative Assistant',
  'Artificial Intelligence Engineer',
  'Associate Product Manager',
  'Benefits Manager',
  'Brand Manager',
  'Brand Partnerships',
  'Brand Strategist',
  'Business Analyst',
  'Business Manager',
  'Business Operations',
  'CCO—Chief Customer Officer',
  'CDO—Chief Data Officer',
  'CEO—Chief Executive Officer',
  'CFO—Chief Financial Officer',
  'Chief Executive Officer',
  'Chief of Staff',
  'Chief People Officer',
  'CHRO—Chief Human Resources Officer',
  'CIO—Chief Information Officer',
  'Civil Engineer',
  'CMO—Chief Marketing Officer',
  'Coach',
  'Communications Associate',
  'Communications Director',
  'Communications Manager',
  'Community Manager',
  'Content Marketing Manager',
  'Content Strategist',
  'Controller',
  'COO—Chief Operating Officer',
  'Copywriter',
  'CPO—Chief Product Officer',
  'Creator',
  'CTO—Chief Technology Officer',
  'Customer Success Manager',
  'Customer Success',
  'Data Analyst',
  'Data Scientist',
  'DevOps Engineer',
  'Digital Marketing Manager',
  'Direct Salesperson',
  'Director of Inside Sales',
  'Electrical Engineer',
  'Engineer',
  'Events Coordinator',
  'Events Manager',
  'Finance Director',
  'Finance Manager',
  'Financial Analyst',
  'Financial Planner',
  'Financial Services Representative',
  'Founder',
  'Growth Marketer',
  'Head of Communications',
  'Head of Product',
  'Human Resources',
  'Information Security Analyst',
  'Investor',
  'Journalist',
  'Marketing Consultant',
  'Marketing Director',
  'Marketing Manager',
  'Marketing Research',
  'Mechanical Engineer',
  'Media Buyer',
  'Network Administrator',
  'Office Manager',
  'Onboarding Specialist',
  'Operations Analyst',
  'Operations Associate',
  'Operations Coordinator',
  'Operations Director',
  'Operations Manager',
  'Outside Sales Manager',
  'Partner Manager',
  'Product Design Manager',
  'Product Designer',
  'Product Manager',
  'Product Marketing Manager',
  'Production Engineer',
  'Program Manager',
  'Project Manager',
  'Public Relations',
  'Quality Engineer',
  'Researcher',
  'Sales Analyst',
  'Sales Development Representative',
  'Sales Engineer',
  'Sales Manager',
  'Social Media Marketing',
  'Social Worker',
  'Software Engineer',
  'SQL Developer',
  'Strategy & Operations Manager',
  'Strategy & Operations',
  'Student',
  'Teacher',
  'Technical Program Manager',
  'Technical Support ',
  'Technical Support Manager',
  'UX/UI Designer',
  'Venture Associate',
  'Vice President of Marketing',
  'Vice President, Operations',
  'Visual Designer',
  'VP, Design',
  'VP, Finance',
  'VP, Partnerships',
  'VP, Product',
  'Web Developer',
  'Unemployed',
];

export const JOB_INDUSTRIES = [
  'Account Management',
  'Accounting',
  'Analytics',
  'Brand Design',
  'Brand Marketing',
  'Business Development',
  'Business Operations',
  'Chief of Staff',
  'Community Building',
  'Consulting',
  'Content Design',
  'Copywriting',
  'Creative Direction & Strategy',
  'Credit & Capital Markets',
  'Customer Success',
  'Design Operations',
  'Engineering',
  'Entrepreneursip',
  'Executive Leadership',
  'Finance',
  'Financial Services',
  'Founder',
  'Growth',
  'Human Resources',
  'Information Technology',
  'Instructor',
  'Legal & Compliance',
  'Marketing',
  'Mergers & Acquisition',
  'Office Management',
  'Operations',
  'Partnerships',
  'People Operations',
  'Product Design',
  'Product Management',
  'Product Marketing',
  'Project Managment',
  'Public Relations',
  'Publishing',
  'Real Estate',
  'Recruiting',
  'Sales',
  'Student',
  'Teacher',
  'Technical Program Management',
  'User Research',
  'Venture Capital & Investing',
];

export const JOB_EXPERIENCE = [
  'I have no experience',
  '<1-5 years',
  '6-10 years',
  '11-15 years',
  '15+ years',
];

export const PRONOUNS = ['she/her', 'he/him', 'they/them', 'ze/hir'];

export const CAREER_GOALS = [
  {
    icon: 'self_improvement',
    value: 'Improve my mental health at work',
  },
  {
    icon: 'airline_stops',
    value: 'Level up in my current role',
  },
  {
    icon: 'magic_button',
    value: 'Get a promotion',
  },
  {
    icon: 'diversity_1',
    value: 'Becoming a better leader and manager',
  },
  {
    icon: 'potted_plant',
    value: 'Grow and learn new skills',
  },
  {
    icon: 'alt_route',
    value: 'Transition to a new role or field',
  },
  {
    icon: 'work',
    value: 'Find a new job',
  },
  {
    icon: 'domain',
    value: 'Start or scale my own business',
  },
];

export const CAREER_GOALS_OB = [
  {
    icon: 'self_improvement',
    value: 'Improve my mental health at work',
  },
  {
    icon: 'airline_stops',
    value: 'Level up in my current role',
  },
  {
    icon: 'magic_button',
    value: 'Get a promotion',
  },
  {
    icon: 'diversity_1',

    value: 'Becoming a better leader and manager',
  },
  {
    icon: 'potted_plant',
    value: 'Grow and learn new skills',
  },
  {
    icon: 'alt_route',
    value: 'Transition to a new role or field',
  },
  {
    icon: 'work',
    value: 'Find a new job',
  },
  {
    icon: 'domain',
    value: 'Start or scale my own business',
  },
];

export const COACHING_STYLE_OB = [
  {
    icon: 'favorite',
    subtitle:
      'A coach who takes a holistic approach to your career and life, focusing on your mindset and career overall.',
    value: 'Mindset & Wellbeing',
  },
  {
    icon: 'filter_tilt_shift',
    subtitle:
      "An expert in their craft who's held similar roles that can help level up and guide you along your career path.",
    value: 'Functional expert',
  },
  {
    icon: 'diversity_2',
    subtitle:
      "A leader who's been there before, and can help you unlock your leadership and management potential.",
    value: 'Leadership & Management',
  },
  {
    icon: 'rowing',
    subtitle:
      'An experienced entrepreneur or founder that can help you start and grow your own company.',
    value: 'Entrepreneurship',
  },
];

export const MOTIVATIONS = [
  {
    translatesTo: 'Improving well-being',
    value: 'Improve my well-being',
  },
  {
    translatesTo: 'Making money',
    value: 'Make more money',
  },
  {
    translatesTo: 'Increasing agency and power',
    value: 'Have more control over my work',
  },
  {
    translatesTo: 'Finding work-life balance',
    value: 'Have a better work-life balance',
  },
  {
    translatesTo: 'Pursuing a passion',
    value: "Work on things I'm passionate about",
  },
];

export const CHALLENGES = [
  {
    translatesTo: 'Tackling burnout',
    value: 'I feel burnt out',
  },
  {
    translatesTo: 'Finding the right resources',
    value: "I don't have the resources I need",
  },
  {
    translatesTo: 'Getting results',
    value: "I can't get the results I want",
  },
  {
    translatesTo: 'Finding confidence',
    value: "I'm unsure of myself",
  },
  {
    translatesTo: 'Gaining experience',
    value: "I don't have the expertise I need",
  },
  {
    translatesTo: 'Getting started',
    value: "I don't know where to start",
  },
];

export const FEEDBACK = ['Comment', 'Bug/Technical', 'Coaching'];
