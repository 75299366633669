import { useNavigate } from 'react-router-dom';

import { Button, Icon, Paper, Typography } from '@/ui';

import type { FC } from 'react';

const InProgress: FC = () => {
  const navigate = useNavigate();
  const onBackToInsights = () => {
    navigate('/m/insights');
  };
  return (
    <Paper className="mt-6 flex flex-col rounded-2xl border border-cement-300 bg-white p-10 lg:pl-16">
      <div className="flex flex-col items-center justify-center py-14 text-center">
        <div className="grid h-16 w-16 place-items-center rounded-full bg-bloom-500 p-2">
          <Icon className="text-white">check</Icon>
        </div>
        <div className="max-w-2xl pt-9">
          <Typography className="text-cement-700" variant="h4">
            Sent! Congrats on taking this step
          </Typography>
          <Typography className="py-6 text-cement-500" variant="body1">
            We'll update you as they respond. It usually takes for about a week
            to complete the survey. We will follow up with coworkers to make
            sure they respond. <br />
            <br /> You'll be able to see their responses in a summary in a
            debriefing conversation with your coach, where you'll discuss
            turning the feedback into actions.
          </Typography>
        </div>
        <Button
          color="secondary"
          variant="contained"
          onClick={onBackToInsights}
        >
          Back to insights
        </Button>
      </div>
    </Paper>
  );
};

export default InProgress;
