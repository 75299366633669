import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';

import { getCoachProfiles } from '@/api';
import { useView } from '@/hooks';
import { Button, Skeleton, Typography } from '@/ui';

import CoachesList from './List';

const MemberCoaches = () => {
  const { selectedUser, clientId } = useView();
  const [modalOpen, setModalOpen] = useState(false);
  const openAddModal = () => setModalOpen(true);
  const closeAddModal = () => setModalOpen(false);

  const { data = [], isLoading } = useQuery(
    ['active-coaches'],
    () => getCoachProfiles(),
    {
      enabled: !!clientId,
    },
  );

  if (isLoading)
    return (
      <div className="flex-1 bg-sage-100 p-0 sm:p-4 md:p-8">
        <div className="flex justify-center">
          <Skeleton height={200} width={600} />
        </div>
      </div>
    );

  return (
    <div className="flex-1 bg-sage-100 p-0 sm:p-4 md:p-8">
      <div className="container mx-auto max-w-screen-lg p-4">
        <div className="flex justify-between">
          <Typography variant="h4">Coaches</Typography>
          <Button variant="outlined" onClick={openAddModal}>
            Add coach
          </Button>
        </div>

        <CoachesList
          activeCoaches={data}
          client={selectedUser}
          closeModal={closeAddModal}
          modalOpen={modalOpen}
        />
      </div>
    </div>
  );
};

export default MemberCoaches;
