import Header from '@/components/Insights/Header';
import Responses from '@/components/Insights/Responses';

const ResponsesPage = () => (
  <div className="flex w-full flex-col lg:m-6 xl:mx-auto xl:w-280">
    <Header />
    <Responses />
  </div>
);

export default ResponsesPage;
