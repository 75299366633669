import { useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { SessionAction, TWO } from '@/constants';
import { useView } from '@/hooks';
import { NoteOwnerType, SessionStatus, SessionWarningAction } from '@/types';
import { Button, Icon, Link, Paper, Typography } from '@/ui';
import { isInNumberOfDays, track } from '@/utils';

import Header from './Header';
import SessionWarning from './SessionWarning';

import type { Session, User } from '@/types';
import type { FC } from 'react';

import CancelSession from '@/components/Cal/cancelSession';
import RescheduleSession from '@/components/Cal/rescheduleSession';
import Notes from '@/components/Notes/Notes';

interface Props {
  hideMenu?: boolean;
  isCoachViewing: boolean;
  loading?: boolean;
  onViewProfile?: () => void;
  otherUser: User;
  session: Session;
}

const Detail: FC<Props> = ({
  session,
  otherUser,
  isCoachViewing,
  loading = false,
  hideMenu = false,
  onViewProfile = undefined,
}) => {
  const { isCoach } = useView();
  const queryClient = useQueryClient();
  const [openCancel, setOpenCancel] = useState<Session | null>();
  const [openReschedule, setOpenReschedule] = useState<Session | null>();
  const [warningAction, setWarningAction] =
    useState<SessionWarningAction | null>(null);
  const hasLocation = !!session.location;
  const isDoneOrCancelled =
    session.status === SessionStatus.DONE ||
    session.status === SessionStatus.CANCELLED;

  const [searchParams] = useSearchParams();

  const onCancelSession = () => {
    if (isInNumberOfDays(session.startedAt, TWO)) {
      track('Shown Cancel Speedbump', {
        bookId: session.bookId,
        isCoach,
        sessionId: session.id,
      });
      setWarningAction(SessionWarningAction.CANCEL);
      return;
    }
    track('Clicked Cancel Session', {
      bookId: session.bookId,
      sessionId: session.id,
    });
    setOpenCancel(session);
  };

  const onReschedule = () => {
    if (isInNumberOfDays(session.startedAt, TWO)) {
      track('Shown Reschedule Speedbump', {
        bookId: session.bookId,
        isCoach,
        sessionId: session.id,
      });
      setWarningAction(SessionWarningAction.RESCHEDULE);
      return;
    }
    track('Click Reschedule Session', {
      bookId: session.bookId,
      sessionId: session.id,
    });
    setOpenReschedule(session);
  };
  useEffect(() => {
    if (session.id && searchParams.has('action')) {
      const action = searchParams.get('action');
      if (action === SessionAction.RESCHEDULE) {
        onReschedule();
      } else if (action === SessionAction.CANCEL) {
        onCancelSession();
      }
    }
  }, [searchParams, session.id]);
  const onCloseModal = () => {
    queryClient.invalidateQueries(['session', session.id]);
    setOpenCancel(null);
    setOpenReschedule(null);
  };

  const onCloseWarning = () => setWarningAction(null);

  return (
    <>
      <Paper className="my-6 flex gap-4 px-8 pb-12 pt-8" variant="outlined">
        <div className="!border-sunny-dark flex w-full border bg-white">
          <div className="flex flex-1 flex-col">
            <Header
              hideMenu={hideMenu}
              isCoachViewing={isCoachViewing}
              loading={loading}
              session={session}
              user={otherUser}
              onCancelSession={onCancelSession}
              onReschedule={onReschedule}
              onViewProfile={onViewProfile}
            />
          </div>
        </div>
      </Paper>

      <Notes
        limit={3}
        owneableId={session.id}
        owneableType={NoteOwnerType.SESSION}
      />

      {!isDoneOrCancelled && (
        <Paper
          className="mt-6 flex flex-col gap-8 px-8 pb-12 pt-8 md:flex-row"
          variant="outlined"
        >
          <div className="flex-1">
            <Typography className="mb-4 select-none font-bold" variant="h5">
              Session details
            </Typography>
            <Typography variant="subtitle1">Need to make a change?</Typography>
            <Typography variant="body1">
              Please reschedule or cancel 48 hours before the session start
              time. <br />
              <a
                className="text-sage-500 underline"
                href="https://mentoteam.notion.site/Mento-Rescheduling-Cancelation-Policy-ea6ed8fa23fc41a8a4598070ef42fb53"
                rel="noopener noreferrer"
                target="_blank"
              >
                Rescheduling & Cancelation Policy.
              </a>
            </Typography>
          </div>
          <div
            className={clsx('flex  basis-7/12 flex-col gap-4', {
              'justify-between': hasLocation,
              'justify-end': !hasLocation,
            })}
          >
            {session.location ? (
              <div className="flex items-center rounded-xl bg-sage-200 p-4">
                <Icon>videocam</Icon>

                <div className="flex flex-col pl-2">
                  <Typography>Session Location</Typography>
                  <Link
                    className="text-xs text-cement-400"
                    href={session.location}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {session.location}
                  </Link>
                </div>
              </div>
            ) : null}
            <div className="flex justify-end gap-4">
              <Button variant="outlined" onClick={onReschedule}>
                Reschedule
              </Button>
              <Button variant="outlined" onClick={onCancelSession}>
                Cancel
              </Button>
            </div>
          </div>
        </Paper>
      )}
      {!!warningAction && (
        <SessionWarning
          action={warningAction}
          close={onCloseWarning}
          session={session}
          onCancel={setOpenCancel}
          onReschedule={setOpenReschedule}
        />
      )}
      {!!openCancel && (
        <CancelSession close={onCloseModal} session={openCancel} />
      )}
      {!!openReschedule && (
        <RescheduleSession close={onCloseModal} session={openReschedule} />
      )}
    </>
  );
};

export default Detail;
