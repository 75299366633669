import { ONE, RELATIONSHIP_OPTIONS } from '@/constants';
import { Avatar, Divider, MenuItem, TextField } from '@/ui';
import { isValidEmail } from '@/utils';

import type { Recipient } from '@/types';
import type { ChangeEvent, FC } from 'react';

interface RecipientsProps {
  maximum: number;
  recipients: Recipient[];
  setRecipients: (recipients: Recipient[]) => void;
}

const Recipients: FC<RecipientsProps> = ({
  maximum,
  setRecipients,
  recipients,
}) => {
  const LAST_RECIPIENT_INDEX = -1;
  const canAddRecipient = (newRecipients: Recipient[]) =>
    newRecipients.at(LAST_RECIPIENT_INDEX)?.name &&
    newRecipients.length < maximum;

  const onChange =
    (index: number, field: string) => (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      const newRecipients = [...recipients];
      newRecipients[index] = { ...recipients[index], [field]: value };

      if (canAddRecipient(newRecipients)) {
        newRecipients.push({ email: '', name: '', relationship: '' });
      }
      setRecipients(newRecipients);
    };

  return (
    <div className="flex w-full flex-col divide-y pb-2">
      {recipients.map(({ name, email, relationship }, index) => (
        // eslint-disable-next-line react/no-array-index-key -- All other properties of this object may be empty/undefined while user types.
        <div key={`survey-recipient-${index}`}>
          <div className="flex w-full flex-col justify-between gap-4 py-4 lg:flex-row">
            <div className="grid place-items-center">
              {name ? (
                <Avatar src="https://mento-space.nyc3.cdn.digitaloceanspaces.com/assets/avatar_insights.svg" />
              ) : (
                <Avatar src="https://mento-space.nyc3.cdn.digitaloceanspaces.com/assets/avatar_incomplete_insights.svg" />
              )}
            </div>
            <TextField
              className="flex-1"
              label="Name"
              value={name}
              variant="outlined"
              onChange={onChange(index, 'name')}
            />
            <TextField
              className="flex-1"
              error={isValidEmail(email ?? '')}
              label="Email"
              value={email}
              variant="outlined"
              onChange={onChange(index, 'email')}
            />
            <TextField
              className="flex-1"
              label="Relationship"
              placeholder="Relationship"
              value={relationship}
              select
              onChange={onChange(index, 'relationship')}
            >
              {RELATIONSHIP_OPTIONS.map(({ label, value }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </TextField>
          </div>

          {index !== recipients.length - ONE && <Divider />}
        </div>
      ))}
    </div>
  );
};

export default Recipients;
