import mentoApi from './mentoApi';

import type { SessionFeedback } from '@/types';

export const createFeedback = async (
  data: Pick<SessionFeedback, 'category' | 'content' | 'sessionId'>,
) => (await mentoApi.post('/session_feedback', data)).data;

export const updateFeedback = async (
  id: string,
  data: Pick<SessionFeedback, 'category' | 'content'>,
) => (await mentoApi.put(`/session_feedback/${id}`, data)).data;

export const sendAppFeedback = async (
  text: string,
  typeOfFeedback: string,
): Promise<void> =>
  (await mentoApi.post('/feedback', { body: text, type: typeOfFeedback })).data;
