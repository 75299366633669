import { useState } from 'react';
import {
  Link as RouterLink,
  useLocation,
  useSearchParams,
} from 'react-router-dom';

import { ONE } from '@/constants';
import { useGrowthPlanSteps } from '@/hooks';
import { Button, Icon, Link, Pagination, Typography } from '@/ui';

import Steps from './Steps';

import type { ChangeEvent, FC } from 'react';

import StepDialog from '@/components/Growth/StepDialog';
import Empty from '@/components/Growth/Steps/Empty';

interface StepListProps {
  showCompleted?: boolean;
}

const StepList: FC<StepListProps> = ({ showCompleted }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();
  const page = parseInt(searchParams.get('page') || '1', 10);
  const { steps, total, pages } = useGrowthPlanSteps({
    complete: showCompleted,
    page,
  });

  const onClickAdd = () => setIsOpen(true);
  const hasSteps = !!steps.length;

  const handlePageChange = (_event: ChangeEvent<unknown>, newPage: number) => {
    setSearchParams({ page: `${newPage}` });
  };

  return (
    <>
      <div className="mb-8 flex justify-between align-middle">
        <Typography variant="h5">
          {showCompleted ? (
            <Link
              className="flex items-center text-cement-500 no-underline hover:underline"
              component={RouterLink}
              to={pathname.replace('/completedSteps', '')}
            >
              <Icon className="-ml-8 mr-2">chevron_left</Icon>
              {total} Completed steps 🙌
            </Link>
          ) : (
            'Up next'
          )}
        </Typography>

        {!showCompleted ? (
          <Button
            color="primary"
            size="large"
            variant="contained"
            onClick={onClickAdd}
          >
            Add step
          </Button>
        ) : null}
      </div>
      {!hasSteps ? (
        <Empty />
      ) : (
        steps.map((step) => <Steps key={step.id} {...step} />)
      )}
      {pages > ONE ? (
        <Pagination count={pages} page={page} onChange={handlePageChange} />
      ) : null}
      <StepDialog isOpen={isOpen} setOpen={setIsOpen} />
    </>
  );
};

export default StepList;
