import { formatDistanceToNow } from 'date-fns';

import { TableCell, TableRow } from '@/ui';
import { getIconSrcFromMimeType, toDocumentUrl } from '@/utils';

import type { Document } from '@/types';
import type { FC } from 'react';

import Ellipsis from '@/components/Document/Ellipsis';

interface TableProps {
  documents: Document[];
}

const Rows: FC<TableProps> = ({ documents }) => {
  const onClick = (doc: Document) => () => {
    const url = toDocumentUrl(doc.mimeType, doc.reference);
    window.open(url, '_blank');
  };

  return (
    <>
      {documents.map((doc) => (
        <TableRow
          key={`table-view-${doc.name}-${doc.id}`}
          className="cursor-pointer hover:bg-sage-400"
          onClick={onClick(doc)}
        >
          <TableCell>
            <img
              alt={doc.name}
              className="mr-2"
              height={18}
              src={getIconSrcFromMimeType(doc.mimeType)}
              width={18}
            />

            {doc.name}
          </TableCell>
          <TableCell>
            <div className="flex items-center justify-between ">
              {formatDistanceToNow(new Date(doc.updatedAt), {
                addSuffix: true,
              })}
              <Ellipsis document={doc} />
            </div>
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};

export default Rows;
