import clsx from 'clsx';

import { Chip } from '@/ui';

import type { UserProfile } from '@/types';
import type { FC } from 'react';

const Company: FC<UserProfile> = ({ company }) => (
  <Chip
    className={clsx('mb-2 rounded', {
      'bg-sage-300': !!company,
    })}
    label={company}
  />
);

export default Company;
