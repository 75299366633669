import { FIVE, FOUR, ONE, SIX, THREE, TWO, ZERO } from '@/constants';
import { BoulderColor } from '@/types';

const hashedIndex = (id: string) =>
  id
    .substring(id.length - FIVE, id.length - ONE)
    .split('')
    .reduce(
      (previous: number, current: string) =>
        previous + current.charCodeAt(ZERO),
      ZERO,
    );

// TODO: Make `getBoulderColor` return more useful data.
export const getBoulderColor = (id = ''): BoulderColor => {
  const index = hashedIndex(id);
  switch (index % SIX) {
    case ONE:
      return BoulderColor.SUNNY;
    case TWO:
      return BoulderColor.OCEAN;
    case THREE:
      return BoulderColor.POPPY;
    case FOUR:
      return BoulderColor.BLOOM;
    case FIVE:
      return BoulderColor.SKY;
    default:
      return BoulderColor.SAGE;
  }
};
