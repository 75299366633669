import { Card, CardContent, Icon, Typography } from '@/ui';

import type { FC } from 'react';

interface MissionAndVisionProps {
  vision: string;
}

const MissionAndVision: FC<MissionAndVisionProps> = ({ vision = '' }) => (
  <Card elevation={0}>
    <CardContent className="border-2 border-b-sage-300 bg-sage-200">
      <Typography
        className="mb-2 flex items-center gap-2 text-cement-500"
        variant="subtitle1"
      >
        <Icon>auto_awesome</Icon>
        Mission & Vision
      </Typography>
      <Typography className="text-cement-500" variant="body2">
        {vision}
      </Typography>
    </CardContent>
  </Card>
);

export default MissionAndVision;
