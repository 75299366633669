import { useQuery } from '@tanstack/react-query';

import { getMilestoneSuggestedSteps } from '@/api';
import { QUERY_KEY_MILESTONE_SUGGESTED_STEPS } from '@/constants';

export const useGrowthPlanSuggestedSteps = (growthPlanId: string) => {
  const {
    data: suggestions = [],
    isLoading,
    refetch,
  } = useQuery({
    enabled: !!growthPlanId,
    queryFn: () => getMilestoneSuggestedSteps(growthPlanId),
    queryKey: [QUERY_KEY_MILESTONE_SUGGESTED_STEPS, growthPlanId],
  });

  return {
    isSuggestedStepsLoading: isLoading,
    refetchAllSuggestions: refetch,
    suggestions,
  };
};
