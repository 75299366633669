import { useNavigate } from 'react-router-dom';

import { useGrowthPlan } from '@/hooks';
import { Button, Card, CardContent, Typography } from '@/ui';

import type { User } from '@/types';
import type { FC } from 'react';

import BoulderCard from '@/components/Growth/BoulderCard';
import Empty from '@/components/Growth/Overview/Empty';

const Milestones: FC<{
  user: User;
}> = ({ user }) => {
  const navigate = useNavigate();
  const { plan, isPlanLoading } = useGrowthPlan();

  const handleBtnClick = () => {
    navigate(`/coach/members/${user.id}/growth`, { replace: true });
  };

  if (isPlanLoading || !plan.milestones.length) return <Empty client={user} />;

  const incompleteMilestones = plan.milestones.filter(
    (milestone) => !milestone.completedAt,
  );
  return (
    <Card>
      <CardContent>
        <Typography className="mb-2" variant="subtitle1">
          Growth Plan
        </Typography>
        <div>
          {incompleteMilestones.map((milestone) => (
            <BoulderCard key={milestone.id} milestone={milestone} />
          ))}
        </div>
        <Button
          className="mt-4 w-full"
          color="primary"
          variant="outlined"
          onClick={handleBtnClick}
        >
          See plan
        </Button>
      </CardContent>
    </Card>
  );
};

export default Milestones;
