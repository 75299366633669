import { useQuery } from '@tanstack/react-query';

import { getFunctionDisplay } from '@/api';

import type { FunctionDisplay } from '@/types';

export const useGetFunctionDisplay = (id: string) =>
  useQuery<FunctionDisplay>(
    ['functionDisplay', id],
    () => getFunctionDisplay(id),
    {
      enabled: !!id,
    },
  );
