import { Button, Card, CardContent, Icon, Typography } from '@/ui';

import type { FC } from 'react';

interface EmptyGoalsCardProps {
  openTeamGoals: () => void;
}

const EmptyGoalsCard: FC<EmptyGoalsCardProps> = ({ openTeamGoals }) => (
  <Card className="basis-96 self-baseline">
    <CardContent>
      <div className="mb-4 grid h-12 w-12 place-items-center rounded-full bg-sunny-400">
        <Icon className="!text-2xl">emoji_flags</Icon>
      </div>

      <Typography className="mb-2" variant="h5">
        Set team goals
      </Typography>
      <Typography className="mb-4" variant="body2">
        Use Mento to define goals visible to the whole team. These could be
        things you are all working to improve or be company business goals the
        team is currently focused on.
      </Typography>

      <Button className="w-full" variant="outlined" onClick={openTeamGoals}>
        Add goals
      </Button>
    </CardContent>
  </Card>
);

export default EmptyGoalsCard;
