import clsx from 'clsx';

import { MINDSET_IMAGE_URL } from '@/constants';
import { Button, Typography } from '@/ui';

import type { Mindset } from '@/types';
import type { FC } from 'react';

interface MindSetCardProps {
  isSelected: boolean;
  mindset: Mindset;
  onClick: () => void;
}

const Item: FC<MindSetCardProps> = ({
  mindset,
  isSelected = false,
  onClick,
}) => (
  <Button
    className={clsx(
      'flex h-28 w-72 items-center justify-center rounded-xl bg-cover bg-center bg-no-repeat',
      {
        'border-solid border-4 border-white/90': isSelected,
      },
    )}
    style={{
      backgroundImage: `url("${MINDSET_IMAGE_URL}${mindset}.jpg")`,
    }}
    onClick={onClick}
  >
    <Typography className="capitalize text-white" variant="h5">
      {mindset}
    </Typography>
  </Button>
);

export default Item;
