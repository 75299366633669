import useSharedCardActions from './useSharedCardActions';

import type { GeneratorFunction } from './types';

const useCareerCheckinActions = (): GeneratorFunction => {
  const { handleClick } = useSharedCardActions();

  return ({ card }) => {
    const label = 'Start';
    return [
      {
        label,
        onClick: () => {
          handleClick({ card, label });
          window.open('https://mento-team.typeform.com/MentoPulseCheck');
        },
      },
    ];
  };
};

export default useCareerCheckinActions;
