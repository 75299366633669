import { useState } from 'react';

import { CardType } from '@/types';

import useAiWorkflowActions from './useAiWorkflowActions';
import useCareerCheckinActions from './useCareerCheckinActions';
import useCreateProfileActions from './useCreateProfileActions';
import useCreateTeamActions from './useCreateTeamActions';
import useEventCardActions from './useEventCardActions';
import useGetMatchedActions from './useGetMatchedActions';
import useGrowthPlanActions from './useGrowthPlanActions';
import useLearnAboutActions from './useLearnAboutActions';
import useMatchingCoachActions from './useMatchingCoachActions';
import useMeetCoachActions from './useMeetCoachActions';
import useNextSessionActions from './useNextSessionActions';
import usePeerInsightActions from './usePeerInsightActions';
import usePeerInsightAwarenessActions from './usePeerInsightAwarenessActions';
import usePreviousSessionActions from './usePreviousSessionActions';

import type { GeneratorFunction } from './types';
import type { HomeCardPropsWithoutCallbacks } from '@/types';

type UseHomeCardActions = {
  card: HomeCardPropsWithoutCallbacks;
};

export const useHomeCardActions = ({ card }: UseHomeCardActions) => {
  const [isScheduleOpen, setIsScheduleOpen] = useState<boolean>(false);

  const onOpenSchedule = () => setIsScheduleOpen(true);
  const onCloseSchedule = () => setIsScheduleOpen(false);

  const actionsMap: { [key in CardType]?: GeneratorFunction } = {
    [CardType.EVENT]: useEventCardActions(),
    [CardType.MATCHING_COACH]: useMatchingCoachActions(),
    [CardType.MEET_COACH]: useMeetCoachActions(),
    [CardType.PREVIOUS_SESSION]: usePreviousSessionActions(),
    [CardType.NEXT_SESSION]: useNextSessionActions(),
    [CardType.CAREER_CHECKIN]: useCareerCheckinActions(),
    [CardType.PEER_INSIGHT_AWARENESS]: usePeerInsightAwarenessActions(),
    [CardType.PEER_INSIGHT]: usePeerInsightActions(),
    [CardType.LEARN_ABOUT]: useLearnAboutActions(),
    [CardType.CREATE_PROFILE]: useCreateProfileActions(),
    [CardType.ADD_PROFILE_IMAGE]: useCreateProfileActions(),
    [CardType.AI_WORKFLOW]: useAiWorkflowActions(),
    [CardType.GROWTH_PLAN]: useGrowthPlanActions(),
    [CardType.CREATE_TEAM]: useCreateTeamActions(),
    [CardType.GET_MATCHED]: useGetMatchedActions(),
  };

  const actions: HomeCardPropsWithoutCallbacks['actions'] =
    actionsMap[card.type] !== undefined
      ? (actionsMap[card.type] as GeneratorFunction)({ card, onOpenSchedule })
      : [];

  return { actions, isScheduleOpen, onCloseSchedule };
};
