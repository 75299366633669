import { ONE, ZERO } from '@/constants';
import { useGetDocuments, useView } from '@/hooks';
import { Pagination } from '@/ui';

import List from './List';

import type { DocumentViewState } from '@/types';
import type { ChangeEvent, Dispatch, FC, SetStateAction } from 'react';

import LoadingDocuments from '@/components/Documents/Loading';

type MineProps = {
  page: number;
  search: string;
  setPage: Dispatch<SetStateAction<number>>;
  view: DocumentViewState;
};

const Mine: FC<MineProps> = ({ page, setPage, search, view }) => {
  const { selectedUser, isViewLoading } = useView();

  const {
    data: documents = { items: [], pages: ZERO },
    isLoading: isDocumentsLoading,
  } = useGetDocuments({
    page,
    search,
    userId: selectedUser.id,
  });

  const onPageChange = (_: ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  if (isViewLoading) return null;

  return (
    <div className="container mx-auto pb-6">
      <div className="mt-4 flex w-full flex-1 flex-col">
        {documents.items.length !== ZERO && (
          <>
            {isDocumentsLoading ? (
              <LoadingDocuments />
            ) : (
              <List documents={documents.items} view={view} />
            )}

            {documents.pages > ONE && (
              <Pagination
                count={documents.pages}
                page={page}
                onChange={onPageChange}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Mine;
