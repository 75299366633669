import axios from 'axios';
import applyCaseMiddleware from 'axios-case-converter';

const options = {
  preservedKeys: (input: string) => {
    const uuidRegex =
      /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/iu;
    return (
      uuidRegex.test(input) ||
      input === 'Strengths' ||
      input === 'Growth Opportunities'
    );
  },
};

const mentoApi = applyCaseMiddleware(
  axios.create({
    baseURL: import.meta.env.VITE_BASE_API_URL,
    headers: {
      Accept: 'application/json',
    },
  }),
  options,
);

export default mentoApi;
