import { useQueryClient } from '@tanstack/react-query';
import { formatDistanceToNow } from 'date-fns';
import { type FC, useState } from 'react';

import { QUERY_KEY_GROWTH_PLANS, QUERY_KEY_MILESTONES } from '@/constants';
import { useUpdateMilestone } from '@/hooks';
import { Avatar, Button, Typography } from '@/ui';
import { distanceFrom, getStepData } from '@/utils';

import type { Milestone } from '@/types';

import StepDialog from '@/components/Growth/StepDialog';

interface HeaderProps {
  color: string;
  colorDark: string;
  milestone: Milestone;
}

const Header: FC<HeaderProps> = ({ milestone, color, colorDark }) => {
  const [stepOpen, setStepOpen] = useState(false);
  const queryClient = useQueryClient();

  const textColor =
    color === 'sunny' ? `text-${color}-600` : `text-${color}-500`;

  const { allStepsComplete, completeSteps, totalSteps } = getStepData(
    milestone.steps,
  );
  const milestoneId = milestone.id;

  const updateMilestone = useUpdateMilestone();
  const openStep = () => setStepOpen(true);

  const isMilestoneComplete =
    allStepsComplete && !!totalSteps && !!milestone.completedAt;
  // Done is all steps done, but not marked as complete
  const isMilestoneDone =
    allStepsComplete && !!totalSteps && !milestone.completedAt;

  const onComplete = () => {
    updateMilestone.mutate(
      {
        ...milestone,
        isComplete: !milestone.completedAt,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([QUERY_KEY_GROWTH_PLANS]);
          queryClient.invalidateQueries([QUERY_KEY_MILESTONES]);
        },
      },
    );
  };

  const { author } = milestone;
  const createdDate = formatDistanceToNow(new Date(milestone.createdAt), {
    addSuffix: true,
  });

  const noSteps = !totalSteps;
  let subText = noSteps
    ? 'No steps, yet'
    : `${completeSteps.length} of ${totalSteps} steps completed`;

  if (isMilestoneComplete && !!milestone.completedAt) {
    const completedDate = distanceFrom(new Date(milestone.completedAt));
    subText = `Completed ${completedDate}`;
  }

  return (
    <>
      <div className="ml-0 mt-6 sm:ml-9 sm:mt-14 ">
        <Typography variant="h4">{milestone.title}</Typography>
        <Typography className="whitespace-pre-wrap" variant="body1">
          {milestone.description}
        </Typography>
        <div className="mt-4 flex items-center">
          <Avatar
            firstName={author.firstName}
            lastName={author.lastName}
            size="s"
            src={author.avatarUrl}
          />
          <Typography variant="caption">
            &nbsp;Created&nbsp;{createdDate}
          </Typography>
        </div>
        <Typography className={`mt-10 ${textColor}`} variant="subtitle1">
          {subText}
        </Typography>
        {noSteps && (
          <>
            <Typography className="mt-2" color="text.secondary" variant="body2">
              Steps are actions that bring you closer to completing your
              milestone.
            </Typography>
            <Typography
              className="inline-block underline hover:cursor-pointer"
              color="text.secondary"
              variant="body2"
              onClick={openStep}
            >
              Add a step
            </Typography>
            <Typography
              className="inline-block"
              color="text.secondary"
              variant="body2"
            >
              &nbsp;to get started.
            </Typography>
          </>
        )}

        {isMilestoneDone && (
          <Button
            className={`my-4 text-white ${colorDark}`}
            onClick={onComplete}
          >
            Mark as complete
          </Button>
        )}
      </div>
      <StepDialog
        isOpen={stepOpen}
        milestoneId={milestoneId}
        setOpen={setStepOpen}
        step={{ milestoneId }}
      />
    </>
  );
};

export default Header;
