import { useMediaQuery } from '@mui/material';
import { formatDistanceToNow } from 'date-fns';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useCurrentUser, useView } from '@/hooks';
import { Button, Chip, Icon, Paper, Typography } from '@/ui';

import type { Document } from '@/types';
import type { FC, MouseEvent } from 'react';

import CreateDialog from '@/components/Document/Create/Dialog';

const Item: FC<Document> = ({
  draft,
  id,
  thumbnail,
  name,
  updatedAt,
  reference,
  mimeType,
}) => {
  const { isClient, selectedUser } = useView();
  const { user } = useCurrentUser();
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const mobile = useMediaQuery('(max-width:600px)');
  const navigate = useNavigate();
  const onClick = (templateId: string) => () => {
    if (isClient) {
      navigate(`/documents/templates/${templateId}`);
    } else {
      navigate(`/coach/templates/${templateId}`);
    }
  };

  const onUseTemplate = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setIsCreateOpen(true);
  };
  const onCloseUseTemplate = () => setIsCreateOpen(false);

  const canUseTemplate = selectedUser.hasMaxDocuments || user.hasMaxDocuments;

  return (
    <li className="mx-auto w-36">
      <Paper
        className="group col-span-1 mb-1 flex h-46 w-36 cursor-pointer flex-col rounded-lg bg-white hover:bg-hoverWhite"
        style={{ backgroundImage: `url(${thumbnail})` }}
        onClick={onClick(id)}
      >
        <div className="invisible flex h-full flex-col justify-center px-2 group-hover:visible">
          <Button
            className="mb-2"
            color="primary"
            disabled={canUseTemplate}
            size="small"
            startIcon={<Icon>edit</Icon>}
            variant="contained"
            onClick={onUseTemplate}
          >
            <span className="text-xs">Use template</span>
          </Button>
          <Button
            color="primary"
            size="small"
            startIcon={<Icon>visibility</Icon>}
            variant="contained"
            light
            onClick={onClick(id)}
          >
            <span className="text-xs">Preview</span>
          </Button>
        </div>
        {draft && (
          <Chip className="mb-1 ml-1 w-20" color="info" label="Draft" />
        )}
      </Paper>
      <Typography
        className="truncate"
        variant={mobile ? 'subtitle2' : 'subtitle1'}
      >
        {name}
      </Typography>
      {user.superAdmin && (
        <Typography className="overflow-hidden text-ellipsis" variant="caption">
          Edited{' '}
          {formatDistanceToNow(new Date(updatedAt), {
            addSuffix: true,
          }).replace('about ', '')}
        </Typography>
      )}

      <CreateDialog
        close={onCloseUseTemplate}
        draft={draft}
        isOpen={isCreateOpen}
        isTemplate={false}
        mimeType={mimeType}
        name={name}
        reference={reference}
      />
    </li>
  );
};

export default Item;
