import { forwardRef } from 'react';

import { FormControlLabel, Icon, Switch, Typography } from '@/ui';

import type { ChangeEvent } from 'react';

interface ToggleCardProps {
  checked?: boolean;
  icon?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  title: string;
}

const ToggleCard = forwardRef<HTMLInputElement, ToggleCardProps>(
  ({ title, icon, ...rest }, ref) => (
    <FormControlLabel
      className="ml-0 w-full cursor-pointer rounded-xl bg-sage-100 p-2"
      control={<Switch ref={ref} color="primary" {...rest} />}
      label={
        <div className="flex flex-1">
          {!!icon && <Icon className="mx-2">{icon}</Icon>}
          <Typography variant="subtitle2">{title}</Typography>
        </div>
      }
      labelPlacement="start"
      // eslint-disable-next-line react/forbid-component-props -- No other way to style child components of FormControlLabel.
      sx={{
        '.MuiFormControlLabel-label': {
          flex: '1',
        },
      }}
    />
  ),
);
ToggleCard.displayName = 'ToggleCard';

export default ToggleCard;
