import { styled } from '@mui/material';
import MuiTable from '@mui/material/Table';
import { clsx } from 'clsx';

import type { TableProps } from '@mui/material/Table';
import type { FC } from 'react';

/* eslint-disable sort-keys -- CSS selectors shouldn't be alphabetically sorted. */
const StyledTable = styled(MuiTable)<TableProps>(({ theme }) => ({
  borderCollapse: 'separate',
  borderColor: theme.palette.divider,
  borderRadius: theme.shape.borderRadius,
  borderStyle: 'solid',
  borderWidth: 1,
  overflow: 'hidden',

  '.MuiTableBody-root .MuiTableRow-root:last-child .MuiTableCell-root': {
    borderBottom: 0,
  },
}));
/* eslint-enable sort-keys */

const Table: FC<TableProps> = ({ className, ...rest }) => {
  const classes = clsx('mnt-table', className);

  return <StyledTable {...rest} className={classes} />;
};
Table.displayName = 'Table';

export default Table;
export type { TableProps };
