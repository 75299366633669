import type { User } from '@/types';
import type { FC } from 'react';

import Focus from '@/components/Profile/Client/Growth/Focus';

interface ClientFocusProps {
  client: User;
}

const Growth: FC<ClientFocusProps> = ({ client }) => (
  <section className="space-y-4">
    <Focus
      icon="cloud"
      subtitle={client.userAssesment?.dreamJobs.join(', ') ?? ''}
      title="Dream Job"
    />
    <Focus
      icon="emoji_flags"
      subtitle={client.userAssesment?.careerGoals.join(', ') ?? ''}
      title="Career Goals"
    />
    <Focus
      icon="exit_to_app"
      subtitle={client.userAssesment?.coachingNeeds.join(', ') ?? ''}
      title="Need in Coaching"
    />
    <Focus
      icon="auto_awesome"
      subtitle={client.userAssesment?.motivations.join(', ') ?? ''}
      title="Motivations"
    />
    <Focus
      icon="cyclone"
      subtitle={client.userAssesment?.challenges.join(', ') ?? ''}
      title="Challenges"
    />
  </section>
);

export default Growth;
