import {
  differenceInHours,
  format,
  formatDistanceToNow,
  isToday,
} from 'date-fns';

import { HOURS_IN_DAY, ZERO } from '@/constants';
import { CardType, SurveyResponseStatus, SurveyStatus } from '@/types';

import type {
  ClientEvent,
  Event,
  EventStatus,
  HomeCardPropsWithoutCallbacks,
  SessionDashboard,
  Survey,
  User,
} from '@/types';
import type { ButtonProps } from '@/ui';

export const getStatus = (event: Event) => {
  const now = new Date();
  let label: EventStatus = 'published';

  if (!event.startedAt) {
    label = 'draft';
  }

  if (event.endedAt && now > new Date(event.endedAt)) {
    label = 'expired';
  }

  return label;
};

export const getEventCardProps = (
  event: Event,
): HomeCardPropsWithoutCallbacks => {
  const actions = [];

  if (event.ctaLabel) {
    actions.push({
      buttonProps: {
        color: (event.isDarkTheme
          ? 'white'
          : 'primary') as ButtonProps['color'],
        ...(event.ctaLink && { href: event.ctaLink }),
      },
      label: event.ctaLabel,
    });
  }

  return {
    actions,
    background: !event.isImageInline ? event.imageUrl : undefined,
    children: event.body,
    id: event.id,
    isDarkTheme: event.isDarkTheme,
    isDismissable: event.isDismissable,
    label: event.label || '',
    status: getStatus(event),
    title: event.title,
    type: CardType.EVENT,
  };
};

export const getMatchingCoachCardProps = (): HomeCardPropsWithoutCallbacks => ({
  background: 1,
  children: { message: "We're on it-this could take 2 to 3 days" },
  id: CardType.MATCHING_COACH,
  label: 'Coaching',
  title: 'Matching you with the right coach',
  type: CardType.MATCHING_COACH,
});

export const getCreateTeamCardProps = (): HomeCardPropsWithoutCallbacks => ({
  background: 4,
  children:
    'Support your teams growth like never before with Mento’s coaching tools.',
  className: 'bg-bloom-600 bg-none',
  id: CardType.CREATE_TEAM,
  isDarkTheme: true,
  label: 'Mento for Teams',
  title: 'Create your first team',
  type: CardType.CREATE_TEAM,
});

export const getAiWorkflowCardProps = (): HomeCardPropsWithoutCallbacks => ({
  background: 13,
  id: CardType.AI_WORKFLOW,
  isDarkTheme: true,
  label: 'AI Coach Beta',
  title: 'Work through a problem, big or small, anytime.',
  type: CardType.AI_WORKFLOW,
});

export const getMatchedWithCoachCardProps =
  (): HomeCardPropsWithoutCallbacks => ({
    background: 4,
    children:
      'Unlock your growth with personalized 1-on-1 coaching from industry leaders.',
    className: 'bg-none',
    id: CardType.GET_MATCHED,
    imageUrl: '',
    label: 'Mento Coaching',
    title: 'Get matched with a Coach',
    type: CardType.GET_MATCHED,
  });

export const getCoachSessionsCardProps = ({
  user,
  sessionsData,
}: {
  sessionsData: SessionDashboard;
  user: User;
}): HomeCardPropsWithoutCallbacks[] => {
  const result: HomeCardPropsWithoutCallbacks[] = [];

  // No sessions cards
  sessionsData.noSessions.forEach((id) => {
    const coach = user.coaches.find((c) => c.id === id);
    if (!coach) {
      return;
    }

    result.push({
      background: 1,
      children: {
        avatarUrl: coach.avatarUrl,
        firstName: coach.fullName || coach.firstName,
        lastName: coach.lastName as string,
        message: `Nice to meet you ${user.firstName}! Tap the button to find time for our first session.`,
      },
      coach,
      id: `${CardType.MEET_COACH}-${coach.firstName || coach.firstName}`,
      label: 'Coaching',
      title: `Meet your Mento Coach, ${coach.firstName || coach.firstName}`,
      type: CardType.MEET_COACH,
      user,
    });
  });

  // No upcoming sessions cards
  sessionsData.noUpcoming.forEach((id) => {
    const coach = user.coaches.find((c) => c.id === id);
    if (!coach) {
      return;
    }

    result.push({
      background: 1,
      children: {
        avatarUrl: coach.avatarUrl,
        firstName: coach.fullName || coach.firstName,
        lastName: coach.lastName as string,
        message: `Hey ${user.firstName}. Tap the schedule button to schedule our next session.`,
      },
      coach,
      id: `${CardType.PREVIOUS_SESSION}-${user.firstName}-${coach.firstName}`,
      label: 'Coaching',
      title: `No upcoming sessions with ${coach.firstName}`,
      type: CardType.PREVIOUS_SESSION,
      user,
    });
  });

  // Sessions cards
  Object.entries(sessionsData.upcoming).forEach(([sessionCoachId, session]) => {
    const coach = user.coaches.find(
      ({ id: coachId }) => coachId === sessionCoachId,
    );
    if (!coach) {
      return;
    }

    const startedAt = new Date(session.startedAt);
    const isSessionToday = isToday(startedAt);
    const distance = differenceInHours(startedAt, new Date());

    let title = '';

    if (isSessionToday) {
      title = `Today at ${format(startedAt, 'h:mmaaa')} with ${
        coach.firstName || ''
      }`;
    } else if (distance <= HOURS_IN_DAY) {
      title = 'Your session is tomorrow';
    } else {
      title = `Next session in ${formatDistanceToNow(startedAt)}`;
    }

    if (session.isChemistryCall) {
      result.push({
        background: 12,
        coach,
        id: `${CardType.NEXT_SESSION}-${coach.firstName}`,
        isSessionToday,
        label: 'Coaching',
        session,
        title: title.replace(
          /(.*?)(at|in|is)\s(\w+)/u,
          'Chemistry call $2 $3 ',
        ),
        type: CardType.NEXT_SESSION,
        user,
      });
    } else {
      result.push({
        background: 2,
        coach,
        id: `${CardType.NEXT_SESSION}-${coach.firstName}`,
        isSessionToday,
        label: 'Coaching',
        session,
        title,
        type: CardType.NEXT_SESSION,
      });
    }
  });

  return result;
};

const getPeerInsightAwarenessCard = (
  event: ClientEvent,
): HomeCardPropsWithoutCallbacks => ({
  background: 7,
  children:
    'Turn Strengths and Opportunities feedback from your peers into action with your coach.',
  id: event.key,
  isDarkTheme: true,
  label: 'Insights',
  title: 'Get insights from peers to shape your growth',
  type: event.key,
});

const getPeerInsightCard = (
  event: ClientEvent,
  peerInsight: Survey,
): HomeCardPropsWithoutCallbacks | undefined => {
  const responsesTotal = peerInsight.responses?.length ?? ZERO;
  const responses =
    peerInsight.responses?.filter(
      (response) => response.status === SurveyResponseStatus.COMPLETED,
    ) || [];
  let children = 'Unknown State';
  let title = 'Peer Insights';
  let isDismissable = false;

  if (event.key === CardType.PEER_INSIGHT) {
    if (peerInsight.status === SurveyStatus.DRAFT) {
      title = 'Pick up where you left off';
      children = responsesTotal
        ? `You added ${responsesTotal} peers. Next, send them a short survey.`
        : "Add people you'd like to get";
      isDismissable = true;
    } else if (peerInsight.status === SurveyStatus.SHARED) {
      title = 'Insights are ready!';
      children = 'Your coach shared your peer insights with you.';
      isDismissable = true;
    } else {
      title = responses.length
        ? `${responses.length} of ${responsesTotal} peers responded`
        : 'Waiting on responses';
      children = responses.length
        ? 'Your coach will walk you through your insights in a future session.'
        : "We'll keep you updated as responses come in.";
    }

    return {
      /* eslint-disable-next-line @typescript-eslint/no-magic-numbers -- index numbers. */
      background: peerInsight.status === SurveyStatus.SHARED ? 11 : 10,
      children,
      dismissedTill: event.dismissedTill,
      id: event.key,
      isDismissable,
      label: 'Peer Insights',
      peerInsight,
      title,
      type: event.key,
    };
  }
  return undefined;
};

export const getClientEventsCardsProps = (
  events: ClientEvent[],
  peerInsight: Survey | null,
  user: User,
): HomeCardPropsWithoutCallbacks[] => {
  const order = [
    CardType.CAREER_CHECKIN,
    CardType.PEER_INSIGHT_AWARENESS,
    CardType.PEER_INSIGHT,
    CardType.LEARN_ABOUT,
    CardType.CREATE_PROFILE,
    CardType.GROWTH_PLAN,
  ];

  // HORRIBLE HACK INCOMING. Explanation after it.
  const fakeClientEvents: ClientEvent[] = [
    ...(events.find((e) => e.key === CardType.CAREER_CHECKIN)
      ? []
      : [{ key: CardType.CAREER_CHECKIN } as ClientEvent]),
    ...(events.find((e) => e.key === CardType.LEARN_ABOUT)
      ? []
      : [{ key: CardType.LEARN_ABOUT } as ClientEvent]),
    ...(events.find((e) => e.key === CardType.CREATE_PROFILE)
      ? []
      : [{ key: CardType.CREATE_PROFILE } as ClientEvent]),
    ...(events.find((e) => e.key === CardType.GROWTH_PLAN)
      ? []
      : [{ key: CardType.GROWTH_PLAN } as ClientEvent]),
    ...(events.find((e) => e.key === CardType.PEER_INSIGHT_AWARENESS)
      ? []
      : [{ key: CardType.PEER_INSIGHT_AWARENESS } as ClientEvent]),
    ...(events.find((e) => e.key === CardType.PEER_INSIGHT)
      ? []
      : [{ key: CardType.PEER_INSIGHT } as ClientEvent]),
  ];
  const clientEventsCopy = [...events, ...fakeClientEvents];
  // So... since the card refactor we've figured out that first-time
  // users don't get the `clientEvents` generated on the backend
  // and end up with a completely empty dashboard.
  //
  // The real fix is to fix the backend to generate these clientEvents.
  // However, for now, we'll be faking these events on the frontend if
  // the clientEvents are empty.
  //
  // Once this is fixed, we can replace the below line and
  // delete the clientEventsCopy const.
  //
  // const sorted = [...events].sort(
  const sorted = clientEventsCopy.sort(
    (a, b) => order.indexOf(a.key) - order.indexOf(b.key),
  );
  const now = new Date().getTime();
  const isNotDismissed = (dismissedTill: ClientEvent['dismissedTill']) =>
    !dismissedTill || now > new Date(dismissedTill as string).getTime();

  return sorted.reduce<HomeCardPropsWithoutCallbacks[]>((aggr, event) => {
    const isViewed = !!event.viewedAt;
    const shouldShow = (key: CardType): CardType | undefined => {
      let show = false;

      switch (key) {
        case CardType.CAREER_CHECKIN:
          show = !isViewed && isNotDismissed(event.dismissedTill);
          break;
        case CardType.PEER_INSIGHT_AWARENESS:
          show = !peerInsight;
          break;
        case CardType.PEER_INSIGHT:
          show = !!peerInsight && isNotDismissed(event.dismissedTill);
          break;
        case CardType.LEARN_ABOUT:
          show = !isViewed;
          break;
        case CardType.CREATE_PROFILE:
          show = !isViewed && isNotDismissed(event.dismissedTill);
          break;
        case CardType.ADD_PROFILE_IMAGE:
          show = !user.avatarUrl && isNotDismissed(event.dismissedTill);
          break;
        case CardType.GROWTH_PLAN:
          show = isNotDismissed(event.dismissedTill);
          break;
        default:
          show = false;
      }

      return show ? key : undefined;
    };
    let card;

    switch (shouldShow(event.key)) {
      case CardType.CAREER_CHECKIN:
        aggr.push({
          background: 9,
          children:
            "Complete this survey before your first 1:1 with your coach to give them a comprehensive view on how you're doing at work and in your career",
          dismissedTill: event.dismissedTill,
          id: event.key,
          isDismissable: true,
          label: 'Pulse Check',
          title: `${
            event.dismissedTill ? '[time again]' : ''
          } Prep for your first coaching session`,
          type: event.key,
        });
        break;
      case CardType.PEER_INSIGHT_AWARENESS:
        aggr.push(getPeerInsightAwarenessCard(event));
        break;
      case CardType.PEER_INSIGHT:
        if (peerInsight) {
          card = getPeerInsightCard(event, peerInsight);
          if (card) {
            aggr.push(card);
          }
        }
        break;
      case CardType.LEARN_ABOUT:
        aggr.push({
          background: 8,
          children:
            'Learn about our coaching approach, what to expect next, and how to use Mento.',
          id: event.key,
          label: 'Get started',
          title: 'Learn about Mento',
          type: event.key,
        });
        break;
      case CardType.CREATE_PROFILE:
        aggr.push({
          background: 4,
          children:
            'The more info you provide, the better your Coach is set up to support your growth.',
          dismissedTill: event.dismissedTill,
          id: event.key,
          isDismissable: true,
          label: 'Get started',
          title: 'Create your profile',
          type: event.key,
        });
        break;
      case CardType.ADD_PROFILE_IMAGE:
        aggr.push({
          background: 4,
          children: '',
          dismissedTill: event.dismissedTill,
          id: event.key,
          isDismissable: true,
          label: 'Get started',
          title: 'Add profile photo',
          type: event.key,
          user,
        });
        break;
      case CardType.GROWTH_PLAN:
        aggr.push({
          background: 5,
          children: '',
          id: event.key,
          label: 'Growth plan',
          title: '',
          type: event.key,
        });
        break;
      default:
        break;
    }
    return aggr;
  }, []);
};
