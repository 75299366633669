import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Document from '@/components/Document';

// TODO: remove the navigation fix once templates use new BE
// Axios interceptors will take care of 404 taking you up one level
const Template = () => {
  const { templateId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (templateId === 'reflections') {
      navigate('..', { replace: true });
    }
  }, [templateId]);

  if (templateId === 'reflections') {
    return null;
  }

  return <Document />;
};

export default Template;
