import { captureException } from '@sentry/react';
import { getTimeZones } from '@vvo/tzdb';
import { useState } from 'react';

import { updateUserProfile } from '@/api';
import { useCurrentUser } from '@/hooks';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
  Typography,
} from '@/ui';

import { NotAssociatedSteps } from '../types';

import type { ChangeEvent, FC } from 'react';

interface TimezoneModalProps {
  hideTitle?: boolean;
  setStep: (val: NotAssociatedSteps) => void;
}

const TimezoneModal: FC<TimezoneModalProps> = ({
  hideTitle = false,
  setStep,
}) => {
  const { user, isUserLoading, refetchUser } = useCurrentUser();
  const [saving, setSaving] = useState(false);
  const [timezone, setTimezone] = useState(
    user.profile?.timezone ||
      Intl.DateTimeFormat().resolvedOptions().timeZone ||
      '',
  );

  const onClick = async () => {
    setSaving(true);
    try {
      await updateUserProfile(user.id, { timezone });
      await refetchUser();
      setStep(NotAssociatedSteps.LinkGoogleAccount);
    } catch (error) {
      captureException(error);
    } finally {
      setSaving(false);
    }
  };

  const onTimezoneChange = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => setTimezone(e.target.value);

  const options = getTimeZones().map((t) => {
    const [time] = t.currentTimeFormat.split(' ');

    return {
      label: `(GMT${time}) ${t.mainCities[0]}`,
      value: t.name,
    };
  });

  if (isUserLoading) {
    return null;
  }

  return (
    <Dialog open>
      {!hideTitle && (
        <DialogTitle className="px-8 pb-0 pt-4 md:px-12 md:pt-8">
          <Typography variant="overline">Step 1 of 2</Typography>
        </DialogTitle>
      )}
      <DialogContent className="px-8 pb-8 md:px-12 md:pb-12">
        <Typography className="mb-4" variant="h5">
          What is your default timezone?
        </Typography>
        <Typography className="mb-4">
          Mento will use this to communicate times correctly for sessions,
          community events, and support scheduling requests.
        </Typography>
        <TextField
          helperText="Note: you can always update your timezone later."
          label="Default timezone"
          placeholder="Default timezone"
          value={timezone}
          fullWidth
          select
          onChange={onTimezoneChange}
        >
          {options.map(({ label, value }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          disabled={!timezone}
          loading={saving}
          variant="contained"
          onClick={onClick}
        >
          Set timezone
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TimezoneModal;
