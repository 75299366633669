import { type FC, type MouseEvent, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { AppContext } from '@/context';
import { Button, Icon } from '@/ui';

import type { User } from '@/types';

const Footer: FC<User> = ({ id, cometChatId }) => {
  const navigate = useNavigate();
  const { setChatId } = useContext(AppContext);

  const onClickView = (event: MouseEvent) => {
    event.stopPropagation();
    navigate(`/coach/members/${id}`);
  };

  const onChatView = (event: MouseEvent) => {
    event.stopPropagation();
    setChatId(cometChatId);
  };

  return (
    <div className="mt-auto flex gap-1 px-4 pt-4">
      <Button className="w-full" variant="outlined" onClick={onClickView}>
        View
      </Button>
      <Button
        className="min-w-min px-2.5"
        variant="contained"
        onClick={onChatView}
      >
        <Icon>chat_bubble</Icon>
      </Button>
    </div>
  );
};

export default Footer;
