import { useQuery } from '@tanstack/react-query';

import { getUsersEngagement } from '@/api';

export const useCompanyEngagement = (companyId: string) => {
  const {
    data: engagement,
    isLoading: isEngagementLoading,
    refetch: refetchEngagement,
    error: engagementError,
  } = useQuery(
    ['usersEngagement', companyId],
    () => getUsersEngagement(companyId),
    {
      // Add user.super_admin check to enabeld
      enabled: !!companyId,
    },
  );

  return {
    engagement,
    engagementError,
    isEngagementLoading,
    refetchEngagement,
  };
};
