import { ENTER, ONE } from '@/constants';
import { Icon } from '@/ui';

import type { FC, KeyboardEvent } from 'react';

interface NavigationProps {
  back: () => void;
  next: () => void;
  page: number;
  pages: number;
}

const Navigation: FC<NavigationProps> = ({ back, next, page, pages }) => {
  const onKeyDownBack = (event: KeyboardEvent) => {
    if (event.key === ENTER) {
      back();
    }
  };

  const onKeyDownNext = (event: KeyboardEvent) => {
    if (event.key === ENTER) {
      next();
    }
  };

  return (
    <div className="mt-auto flex justify-end">
      {page > -ONE && (
        <div
          className="mr-4 grid !h-12 !w-14 !max-w-none cursor-pointer place-items-center rounded-full bg-bloom-200"
          role="button"
          tabIndex={0}
          onClick={back}
          onKeyDown={onKeyDownBack}
        >
          <Icon color="info">chevron_left</Icon>
        </div>
      )}
      {page < pages && (
        <div
          className="mr-4 grid !h-12 !w-14 !max-w-none cursor-pointer place-items-center rounded-full bg-bloom-200"
          role="button"
          tabIndex={0}
          onClick={next}
          onKeyDown={onKeyDownNext}
        >
          <Icon color="info">chevron_right</Icon>
        </div>
      )}
    </div>
  );
};

export default Navigation;
