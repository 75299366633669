import { Search } from '@/ui';

import Filters from './Filters';
import Sort from './Sort';

import type { SortValues } from '@/types';
import type { SelectChangeEvent } from '@/ui';
import type { FC } from 'react';

interface HeaderProps {
  filter: string;
  isAdmin: boolean;
  search: string;
  setFilter: (value: string) => void;
  setSearch: (value: string) => void;
  setSort: (event: SelectChangeEvent<SortValues>) => void;
  sort: SortValues;
}

const Header: FC<HeaderProps> = (props) => (
  <div className="flex flex-col">
    <div className="my-4 flex w-full flex-col gap-4 md:flex-row">
      <Search {...props} className="w-full" timeout={1000} />
      <Sort {...props} />
    </div>
    <Filters {...props} />
  </div>
);

export default Header;
