import { useParams } from 'react-router';

import { useCompanyEngagement, useCompanyStats } from '@/hooks';
import { Skeleton } from '@/ui';
import { validStatsPercentage } from '@/utils';

import Stat from './Stat';

const GeneralStats = () => {
  const { companyId = '' } = useParams();
  // TODO: Bring back 30 day platform engagement to be added on super-admin only view of stats
  const { companyStats, iscompanyStatsLoading } = useCompanyStats(companyId);
  const { engagement, isEngagementLoading } = useCompanyEngagement(companyId);

  if (iscompanyStatsLoading || isEngagementLoading) {
    return (
      <Skeleton
        animation="wave"
        className="mt-8 h-50 w-full rounded-xl"
        variant="rectangular"
      />
    );
  }
  if (!engagement || !companyStats) return null;
  const {
    next_30Days: next30Days,
    last_30Days: last30Days,
    clientsWithCoach,
  } = engagement;

  const { sessionsCompleted, hoursCompleted } = companyStats;

  const upcomingSessionsPercentage = validStatsPercentage(
    next30Days,
    clientsWithCoach,
  );
  const metACoachPercentage = validStatsPercentage(
    last30Days,
    clientsWithCoach,
  );
  return (
    <div className="grid space-y-2 md:grid-cols-2 md:gap-6">
      <Stat
        icon="today"
        stat={`${upcomingSessionsPercentage}%`}
        subtitle="Have upcoming sessions"
      />
      <Stat
        icon="group"
        stat={sessionsCompleted}
        subtitle="Coaching session completed"
      />
      <Stat
        icon="published_with_changes"
        stat={`${metACoachPercentage}%`}
        subtitle="Met a coach in the last 30 days"
      />
      <Stat icon="pace" stat={hoursCompleted} subtitle="Hours of coaching" />
    </div>
  );
};

export default GeneralStats;
