import { useQuery } from '@tanstack/react-query';

import { getSessionCount } from '@/api';
import { ONE, TWO } from '@/constants';
import { useCurrentUser } from '@/hooks';
import { Paper, Typography } from '@/ui';

import type { FC } from 'react';

const MeetCoach: FC = () => {
  const { user } = useCurrentUser();
  const { data = [], isLoading } = useQuery(
    ['session-count'],
    () => getSessionCount(user.id),
    {
      enabled: !!user.id,
    },
  );

  const coachesWithSessions = data.map((c: [string, number]) => c[0]);
  const coaches = user.coaches.filter(
    ({ id }) => !coachesWithSessions.some((c2: string) => c2 === id),
  );

  if (!coaches.length || isLoading) return null;

  let title = 'your coach';
  let subtext = 'a coach';
  let subtext2 =
    'Next, schedule your bi-weekly session and get ready for your first session. Good luck!';
  if (coaches.length === ONE) {
    title = `${coaches[0]?.firstName}`;
    subtext = 'a coach';
  } else if (coaches.length === TWO) {
    title = `${coaches[0]?.firstName} and ${coaches[1]?.firstName}`;
    subtext = '2 coaches';
    subtext2 =
      "Next, schedule a meeting with them to get to know each other better. We'll reach out to see how it went.";
  } else if (coaches.length > TWO) {
    const [last, ...rest] = coaches.reverse();
    title = `${rest.map((c) => c.firstName).join(', ')} and ${last?.firstName}`;
    subtext = `${coaches.length} coaches`;
    subtext2 =
      "Next, schedule a meeting with them to get to know each other better. We'll reach out to see how it went.";
  }

  return (
    <Paper
      className="mb-4 mt-10 flex w-full items-center justify-between bg-white px-10 py-6"
      variant="outlined"
    >
      <div className="flex flex-col p-0 px-2">
        <Typography className=" mb-2 select-none px-4 font-bold" variant="h5">
          Meet {title}
        </Typography>
        <Typography className="mb-2 max-w-lg select-none px-4 py-2 text-cement-400">
          We matched you with {subtext} we think can help you reach your goals.
          &nbsp;
          {subtext2}
        </Typography>
      </div>
      <img
        alt="meet"
        className="hidden sm:block"
        height="145"
        src="https://mento-space.nyc3.digitaloceanspaces.com/assets/sessions-meet.svg"
        width="127"
      />
    </Paper>
  );
};

export default MeetCoach;
