import { TWO } from '@/constants';

import Pills from './Pills';

import type { FC } from 'react';

interface RowProps {
  choices?: string[];
  index: number;
  name: string;
  subtext: string;
  text?: string;
}

const Row: FC<RowProps> = ({
  choices = [],
  index,
  name,
  subtext,
  text = '',
}) => {
  const getColor = (colorIndex: number) =>
    colorIndex % TWO ? 'sage' : 'bloom';

  return (
    <>
      <div className="title text-cement-600">{name}</div>
      <div className={`text- mb-6${getColor(index)}-600 title-sm`}>
        {subtext}
      </div>
      <div className="mb-8 rounded-2xl border border-cement-300 p-6">
        <Pills choices={choices} color={getColor(index)} />
        <div className="body text-cement-600">&quot;{text}&quot;</div>
      </div>
    </>
  );
};

export default Row;
