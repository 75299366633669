import { useFlags } from '@happykit/flags/client';
import { FlagBagProvider } from '@happykit/flags/context';
import { useStytchUser } from '@stytch/react';
import { useMemo } from 'react';

import type { FeatureFlags } from '@/types';
import type { ReactNode } from 'react';

export interface FeatureFlagProviderProps {
  readonly children: ReactNode;
}

export const FeatureFlagProvider = ({ children }: FeatureFlagProviderProps) => {
  const { user } = useStytchUser();

  const config = useMemo(() => {
    if (user?.emails[0]?.email) {
      return {
        user: {
          key: user.emails[0]?.email || '',
        },
      };
    }

    return {};
  }, [user?.emails[0]?.email]);

  const value = useFlags<FeatureFlags>(config);

  return <FlagBagProvider value={value}>{children}</FlagBagProvider>;
};
