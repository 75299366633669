import { ONE, ZERO } from '@/constants';
import { useGetDocuments, useView } from '@/hooks';
import { Pagination } from '@/ui';

import List from './List';

import type { DocumentViewState } from '@/types';
import type { ChangeEvent, Dispatch, FC, SetStateAction } from 'react';

import LoadingDocuments from '@/components/Documents/Loading';

type SharedWithMeProps = {
  page: number;
  search: string;
  setPage: Dispatch<SetStateAction<number>>;
  view: DocumentViewState;
};

const SharedWithMe: FC<SharedWithMeProps> = ({
  page,
  setPage,
  search,
  view,
}) => {
  const { selectedUser, isViewLoading } = useView();

  const {
    data: sharedWithMe = { items: [], pages: ZERO },
    isLoading: isSharedLoading,
  } = useGetDocuments({
    page,
    search,
    sharedWithMe: true,
    userId: selectedUser.id,
  });

  const onPageChange = (_: ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  if (isViewLoading) return null;

  return (
    <div className="container mx-auto px-8 pb-6 md:px-0">
      <div className="mt-4 flex w-full flex-1 flex-col">
        {sharedWithMe.items.length !== ZERO && (
          <>
            {isSharedLoading ? (
              <LoadingDocuments />
            ) : (
              <List documents={sharedWithMe.items} view={view} />
            )}

            {sharedWithMe.pages > ONE && (
              <Pagination
                count={sharedWithMe.pages}
                page={page}
                onChange={onPageChange}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SharedWithMe;
