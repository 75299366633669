import { Card, CardContent } from '@mui/material';
import { useParams } from 'react-router-dom';

import { ZERO } from '@/constants';
import { useCurrentUser, useGetGrowthPlans, useView } from '@/hooks';
import { Skeleton, Typography } from '@/ui';

import EmptyMilestoneCard from './EmptyMilestoneCard';
import MilestoneCard from './MilestoneCard';

import type { GrowthPlan } from '@/types';

const GrowthOverview = () => {
  const { teamId = '' } = useParams();
  const { isUserLoading: isCurrentUserLoading } = useCurrentUser();
  const { selectedUser, isViewLoading } = useView();

  const { data: growthPlan = [], isLoading } = useGetGrowthPlans(
    { teamId, userId: selectedUser.id },
    { enabled: !!selectedUser.id },
  );

  if (isViewLoading || isCurrentUserLoading || isLoading)
    return <Skeleton height={300} width="100%" />;

  const teamGrowthPlan = growthPlan[0] as GrowthPlan;

  const inProgressMilestones = teamGrowthPlan.milestones.filter(
    ({ completeSteps, totalSteps }) =>
      completeSteps !== totalSteps || totalSteps === ZERO,
  );

  return (
    <Card>
      <CardContent className="flex flex-col p-6">
        <Typography variant="h5">Growth Plan</Typography>
        <div className="flex w-full flex-1 flex-row gap-8 pt-4">
          {inProgressMilestones.length === ZERO ? (
            <EmptyMilestoneCard growthPlan={teamGrowthPlan} />
          ) : (
            inProgressMilestones.map((milestone) => (
              <MilestoneCard
                key={`team-overview-milestone-${milestone.id}`}
                growthPlan={teamGrowthPlan}
                milestone={milestone}
              />
            ))
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default GrowthOverview;
