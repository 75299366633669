import { useQuery } from '@tanstack/react-query';

import { getUser, getUsers } from '@/api';
import { QUERY_KEY_MEMBERS_MINIMAL, QUERY_KEY_USERS } from '@/constants';

import type { UserApiOptions } from '@/api';
import type { PaginatedResponse, User } from '@/types';
import type { UseQueryOptions } from '@tanstack/react-query';

export const useGetUsers = (
  params: UserApiOptions,
  options?: UseQueryOptions,
) =>
  useQuery<PaginatedResponse<User>>({
    enabled: options ? options.enabled : true,
    queryFn: () => getUsers(params),
    queryKey: [QUERY_KEY_MEMBERS_MINIMAL, params],
  });

export const useGetUser = (id: string, options?: UseQueryOptions) =>
  useQuery<User>({
    enabled: options ? options.enabled : true,
    queryFn: () => getUser(id),
    queryKey: [QUERY_KEY_USERS, id],
  });
