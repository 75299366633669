import type { MilestoneStep } from '@/types';

export const getStepData = (steps = [] as MilestoneStep[]) => {
  const completeSteps = steps.filter(({ completedAt }) => !!completedAt);
  const incompleteSteps = steps.filter(({ completedAt }) => !completedAt);
  const totalSteps = steps.length;
  const allStepsComplete = !!totalSteps && totalSteps === completeSteps.length;

  return {
    allStepsComplete,
    completeSteps,
    incompleteSteps,
    totalSteps,
  };
};
