import { useState } from 'react';

import { bulkUpdateUserEmployment, updateUser, updateUserProfile } from '@/api';
import { Button, Dialog, DialogActions, DialogContent, Divider } from '@/ui';
import { isValidLinkedin } from '@/utils';

import Profile from './Profile';
import Roles from './Roles';
import Styles from './Styles';

import type { CoachProfile, User, UserEmployment, UserProfile } from '@/types';
import type { FC } from 'react';

import { updateCoachProfile } from '@/api/coachProfile';
import Experience from '@/components/Profile/Coach/Edit/Coach/Experience';
import Bio from '@/components/Profile/Edit/Bio';

interface EditCoachProps {
  coach: User;
  onClose: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- we don't actually use the result
  refetchCoach: () => Promise<any>;
}

const EditCoach: FC<EditCoachProps> = ({
  coach: currentCoach,
  onClose,
  refetchCoach,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [coach, setCoach] = useState(currentCoach);
  const [profile, setProfile] = useState(
    currentCoach.profile ?? ({} as UserProfile),
  );
  const [coachProfile, setCoachProfile] = useState(
    currentCoach.coachProfile ?? ({} as CoachProfile),
  );
  const [employments, setEmployments] = useState(
    currentCoach.userEmployments ?? [],
  );
  const onChange = (changed: User) => setCoach(changed);
  const onProfileChange = (changed: UserProfile) => setProfile(changed);
  const onCoachProfileChange = (changed: CoachProfile) =>
    setCoachProfile(changed);
  const onCompanyChange = (changed: UserEmployment[]) =>
    setEmployments(changed);
  const onCancel = () => onClose();

  const onSave = async () => {
    setIsSaving(true);

    const [firstName, ...rest] = coach.fullName.split(' ');
    const lastName = rest.join(' ');
    const coachToUpdate = {
      ...coach,
      firstName,
      lastName,
    };
    await Promise.allSettled([
      updateUser(coach.id, { ...coachToUpdate }),
      updateUserProfile(coach.id, { ...profile }),
      updateCoachProfile(coach.id, { ...coachProfile }),
      bulkUpdateUserEmployment([...employments]),
    ]);
    await refetchCoach();

    setIsSaving(false);
    onClose();
  };

  const isDisabled = !!profile.linkedIn && !isValidLinkedin(profile.linkedIn);

  return (
    <Dialog maxWidth="md" fullWidth open>
      <DialogContent>
        <Profile
          coach={coach}
          coachProfile={coachProfile}
          profile={profile}
          onChange={onChange}
          onCoachProfileChange={onCoachProfileChange}
          onProfileChange={onProfileChange}
        />
        <Divider className="my-8" />
        <Bio profile={profile} onProfileChange={onProfileChange} />
        <Divider className="my-8" />
        <Experience
          coachProfile={coachProfile}
          employments={employments}
          onCoachProfileChange={onCoachProfileChange}
          onCompanyChange={onCompanyChange}
        />
        <Divider className="my-8" />
        <Roles
          coachProfile={coachProfile}
          onCoachProfileChange={onCoachProfileChange}
        />
        <Divider className="my-8" />
        <Styles
          coachProfile={coachProfile}
          onCoachProfileChange={onCoachProfileChange}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={isDisabled}
          loading={isSaving}
          variant="contained"
          onClick={onSave}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditCoach;
