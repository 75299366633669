import { useNavigate } from 'react-router-dom';

import { SurveyData } from '@/constants';
import { useView } from '@/hooks';
import { Button, Icon, Typography } from '@/ui';
import { track } from '@/utils';

import type { FC } from 'react';

interface EmptyProps {
  id: string;
  name: string;
}

const Empty: FC<EmptyProps> = ({ name, id }) => {
  const navigate = useNavigate();
  const { coachData, clientData, icon } = SurveyData;
  const { clientId } = useView();
  const info = clientId ? coachData : clientData;
  const { title, description, button, mediaUrl } = info;
  const onClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    track('Clicked Survey', {
      surveyTemplate: name,
    });
    if (clientId) {
      window.open(
        'https://mentoteam.notion.site/Coaching-Guide-to-Mento-Insights-6d6341e80c5749ff8ac0a598aca6d655',
        '_blank',
      );
    } else {
      navigate(`./${id}`);
    }
  };
  const onLearnMore = (e: React.MouseEvent) => {
    e.stopPropagation();
    window.open('https://mento.co/peer-insights', '_ blank');
  };
  return (
    <div className="flex flex-col md:flex-row">
      <div className="flex flex-1 flex-col">
        <div className="md:pb-8">
          <div className="mb-6 flex h-18 w-18 self-start rounded-full bg-bloom-500">
            <Icon className="m-auto" fontSize="medium" htmlColor="white">
              {icon}
            </Icon>
          </div>
          <Typography className="pr-8 text-cement-600" variant="h4">
            {title}
          </Typography>
          <Typography
            className="pb-5 pr-16 pt-2 text-cement-400"
            variant="body1"
          >
            {description}
          </Typography>
        </div>
        <div className="mt-auto flex">
          <Button
            className="mr-2 px-8"
            color="secondary"
            variant="contained"
            onClick={onClick}
          >
            {button}
          </Button>
          {!clientId && (
            <Button
              className="mr-2 border-black px-8 text-black"
              variant="outlined"
              onClick={onLearnMore}
            >
              Learn more
            </Button>
          )}
        </div>
      </div>
      <div className="flex flex-1">
        {clientId ? (
          <img alt="insights" className="m-auto h-auto w-full" src={mediaUrl} />
        ) : (
          <video
            className="h-full w-full object-cover"
            src={mediaUrl}
            controls
          />
        )}
      </div>
    </div>
  );
};

export default Empty;
