import { CAREER_GOALS, CHALLENGES, JOB_TITLES, MOTIVATIONS } from '@/constants';
import { CreatableSelect, Grid, Typography } from '@/ui';

import type { Client, UserAssesment } from '@/types';
import type { FC } from 'react';

interface EditClientGrowthProps {
  assesment: UserAssesment;
  onAssessmentChange: (updated: UserAssesment) => void;
}

const EditClientGrowth: FC<EditClientGrowthProps> = ({
  assesment,
  onAssessmentChange,
}) => {
  const onUpdate = (changes: Partial<Client>) =>
    onAssessmentChange({ ...assesment, ...changes });

  const onGoalsChange = (value: string[]) => onUpdate({ careerGoals: value });
  const onMotivationsChange = (value: string[]) =>
    onUpdate({ motivations: value });

  const onChallengesChange = (value: string[]) =>
    onUpdate({ challenges: value });
  const onDreamJobsChange = (value: string[]) => onUpdate({ dreamJobs: value });

  return (
    <Grid spacing={4} container>
      <Grid md={5} xs={12} item>
        <Typography className="mb-2" variant="h4">
          Growth focus & dream job
        </Typography>
      </Grid>
      <Grid className="mt-1 flex flex-col gap-3" md={7} xs={12} item>
        <CreatableSelect
          label="Career Goals"
          options={CAREER_GOALS.map((cg) => cg.value)}
          value={assesment.careerGoals}
          multiple
          onChange={onGoalsChange}
        />
        <CreatableSelect
          label="Motivations"
          options={MOTIVATIONS.map((cg) => cg.value)}
          value={assesment.motivations}
          multiple
          onChange={onMotivationsChange}
        />
        <CreatableSelect
          label="Challenges"
          options={CHALLENGES.map((cg) => cg.value)}
          value={assesment.challenges}
          multiple
          onChange={onChallengesChange}
        />
        <CreatableSelect
          label="Dream job"
          options={JOB_TITLES}
          value={assesment.dreamJobs}
          multiple
          onChange={onDreamJobsChange}
        />
      </Grid>
    </Grid>
  );
};

export default EditClientGrowth;
