import Case from 'case';
import clsx from 'clsx';

import { useCurrentUser } from '@/hooks';
import { type Session, SessionFeedbackType, SessionStatus } from '@/types';
import { Button } from '@/ui';
import { getFeedbackStyles } from '@/utils';

import type { FC } from 'react';

interface FeedbackProps {
  handleOnFeedback: (e: React.MouseEvent) => void;
  session: Session;
}

const Feedback: FC<FeedbackProps> = ({ session, handleOnFeedback }) => {
  const { user } = useCurrentUser();

  const showFeedback =
    (user.superAdmin || user.id !== session.coachId) &&
    session.status === SessionStatus.DONE;

  if (!session.feedback || !showFeedback) return null;

  const sessionCategory = session.feedback.category;
  const styles = getFeedbackStyles(session.feedback.category);

  return (
    <div className="group">
      <Button
        className={`w-6 min-w-0 ${styles.feedbackIcon}`}
        variant="contained"
        onClick={handleOnFeedback}
      >
        <img alt="feedback" className="h-6 w-6" src={styles.feedbackIcon} />
      </Button>
      <div
        className={clsx(
          'invisible relative -bottom-2 drop-shadow group-hover:visible',
          {
            '-left-2': sessionCategory === SessionFeedbackType.MIXED,
            '-left-4':
              sessionCategory === SessionFeedbackType.NEGATIVE ||
              sessionCategory === SessionFeedbackType.POSITIVE,
          },
        )}
      >
        <div className="shadow-li absolute z-50 rounded-lg bg-black px-3 py-1 text-sm text-white">
          {Case.title(sessionCategory)}
        </div>
      </div>
    </div>
  );
};
export default Feedback;
