import { useState } from 'react';

import { Table, TableContainer } from '@/ui';

import List from './List';
import Header from './List/Header';

const Members = () => {
  const [sortField, setSortField] = useState('name');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

  const handleSort = (field: string) => {
    if (field === sortField) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  return (
    <div className="flex flex-1 flex-col pt-4">
      <TableContainer>
        <Table aria-label="companies table">
          <Header
            sortDirection={sortDirection}
            sortField={sortField}
            onSort={handleSort}
          />
          <List sortDirection={sortDirection} sortField={sortField} />
        </Table>
      </TableContainer>
    </div>
  );
};

export default Members;
