import { type FC, useContext, useEffect, useState } from 'react';

import { updateTeam } from '@/api';
import { ONE, THREE, ZERO } from '@/constants';
import { AppContext } from '@/context';
import { AppMessageSeverities } from '@/types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Icon,
  TextField,
  Typography,
} from '@/ui';

import type { Team } from '@/types/teams';

import { useTeam } from '@/hooks/useTeam';

const MAX_GOALS = THREE;
interface GoalsModalProps {
  onClose: () => void;
  team: Team;
}

const validateGoals = (goals: string[]) => {
  // empty goal, we always show at least 1 input. Allow it to be saved as cleared
  if (goals.length === ONE) {
    return true;
  }
  return goals.every((item) => item.trim().length > ZERO);
};

// remove empty string arrays from goals
const cleanGoals = (goals: string[]) =>
  goals.map((item) => item.trim()).filter(Boolean);

const GoalsModal: FC<GoalsModalProps> = ({ team, onClose }) => {
  const { setAppMessage } = useContext(AppContext);
  const { refetchTeam } = useTeam(team.id);
  const [goals, setGoals] = useState([...team.goals]);
  const [saving, setSaving] = useState(false);

  const handleAddGoalClick = () => {
    setGoals((old) => [...old, '']);
  };

  const handleGoalChange =
    (index: number) => (e: { target: { value: string } }) => {
      const clone = [...goals];
      clone[index] = e.target.value;
      setGoals(clone);
    };

  const handleSaveClick = async () => {
    try {
      setSaving(true);
      const areValid = validateGoals(goals);
      if (!areValid) {
        setAppMessage({
          message: 'Please fill all goals',
          open: true,
          severity: AppMessageSeverities.Error,
        });
        return;
      }
      await updateTeam(team.id, { ...team, goals: cleanGoals(goals) });
      await refetchTeam();
      setAppMessage({
        message: 'Goals saved',
        open: true,
        severity: AppMessageSeverities.Success,
      });
      onClose();
    } catch (error) {
      setAppMessage({
        message: 'Error updating goals',
        open: true,
        severity: AppMessageSeverities.Error,
      });
      console.error('Error updating goals', error);
    } finally {
      setSaving(false);
    }
  };

  const handleDeleteClick = (index: number) => () => {
    setGoals((old) => old.filter((_, i) => i !== index));
  };

  useEffect(() => {
    if (!goals.length) {
      setGoals(['']);
    }
  }, [goals]);

  return (
    <Dialog maxWidth="md" fullWidth open onClose={onClose}>
      <DialogContent>
        <Grid spacing={4} container>
          <Grid md={5} xs={12} item>
            <Typography className="mb-2" variant="h4">
              Team goals
            </Typography>
            <Typography className="mb-1" variant="body1">
              Set team goals that will be visible to all team members.
            </Typography>
          </Grid>
          <Grid className="mt-4 flex flex-col gap-3" md={7} xs={12} item>
            {goals.map((item, index) => (
              <div
                // eslint-disable-next-line react/no-array-index-key -- Use index as key since these are plain strings.
                key={`goal-${index}`}
                className="flex items-center justify-between gap-2"
              >
                <TextField
                  inputProps={{ maxLength: 200 }}
                  label="Team goal"
                  value={item}
                  fullWidth
                  onChange={handleGoalChange(index)}
                />
                <Icon
                  className="cursor-pointer"
                  onClick={handleDeleteClick(index)}
                >
                  trash
                </Icon>
              </div>
            ))}

            <div className="flex justify-between">
              <Typography variant="body1">Up to 3 goals</Typography>

              <Button
                disabled={goals.length === MAX_GOALS}
                variant="outlined"
                onClick={handleAddGoalClick}
              >
                Add goal
              </Button>
            </div>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button disabled={saving} variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button loading={saving} variant="contained" onClick={handleSaveClick}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GoalsModal;
