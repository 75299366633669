import { Card, CardContent, Skeleton } from '@/ui';

const ClientCardLoading = () => (
  <Card>
    <CardContent className="flex flex-col items-center">
      <Skeleton className="my-4" height={80} variant="circular" width={80} />
      <Skeleton width="33%" />
      <Skeleton className="mb-40" width="55%" />
    </CardContent>
  </Card>
);

export default ClientCardLoading;
