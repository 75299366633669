import { useNavigate } from 'react-router';

import useSharedCardActions from './useSharedCardActions';

import type { GeneratorFunction } from './types';

const useGetMatchedActions = (): GeneratorFunction => {
  const navigate = useNavigate();
  const { handleClick } = useSharedCardActions();

  return ({ card }) => {
    const label = 'Get matched';
    return [
      {
        buttonProps: { color: 'secondary', variant: 'contained' },
        label,
        onClick: () => {
          handleClick({ card, label });
          navigate('/get-started');
        },
      },
    ];
  };
};

export default useGetMatchedActions;
