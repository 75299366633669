import type { Function } from './function';

export interface Assistant {
  // Timestamp
  createdAt: string;
  functions?: Function[];
  id: string;
  model: string;
  name: string;
  prompt: string;
  updatedAt: string; // Timestamp
}

export enum AssistantModels {
  gpt3 = 'gpt3',
  gpt4 = 'gpt4',
  gpt4_latest = 'gpt4_latest',
  gpt4_turbo = 'gpt4_turbo',
}
