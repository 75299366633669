import { useContext, useEffect, useState } from 'react';

import { removeCoachAssignment } from '@/api';
import { ZERO } from '@/constants';
import { AppContext } from '@/context';
import { useView } from '@/hooks';
import { AppMessageSeverities, type CoachProfile, type User } from '@/types';
import { Paper, Typography } from '@/ui';

import AddCoachModal from '../Add';
import RemoveCoachModal from '../Remove';

import Row from './Row';

import type { FC } from 'react';

interface CoachesListProps {
  activeCoaches: CoachProfile[];
  client: User;
  closeModal: () => void;
  modalOpen: boolean;
}

const filterAvailable = (active: CoachProfile[], coaches: User[]) =>
  active.filter(
    (coach) => coaches.findIndex(({ id }) => id === coach.user.id) < ZERO,
  );

const CoachesList: FC<CoachesListProps> = ({
  activeCoaches = [],
  client,
  closeModal,
  modalOpen,
}) => {
  const { coaches } = client;
  const { refetchSelectedUser, selectedUser } = useView();
  const { setAppMessage } = useContext(AppContext);
  const [processing, setProcessing] = useState(false);
  const [removing, setRemoving] = useState('');
  const [available, setAvailable] = useState(
    filterAvailable(activeCoaches, coaches),
  );
  const onRemove = async () => {
    try {
      setProcessing(true);
      await removeCoachAssignment(removing);
      await refetchSelectedUser();
      setProcessing(false);
      setRemoving('');
      setAppMessage({
        message: 'Association removed',
        open: true,
        severity: AppMessageSeverities.Success,
      });
    } catch (e) {
      setProcessing(false);
      console.error(e);
    }
  };

  const handleRemoveClick = (coachId: string) => {
    if (!selectedUser.coachAssignments) {
      setAppMessage({
        message: 'Something went wrong',
        open: true,
        severity: AppMessageSeverities.Error,
      });
      return;
    }
    const assignment = selectedUser.coachAssignments.find(
      (x) => x.coachId === coachId,
    );
    if (!assignment) {
      setAppMessage({
        message: 'Something went wrong',
        open: true,
        severity: AppMessageSeverities.Error,
      });
      return;
    }
    setRemoving(assignment.id);
  };

  useEffect(() => {
    setAvailable(filterAvailable(activeCoaches, coaches));
  }, [activeCoaches, coaches]);

  return (
    <>
      <Paper className="mt-4" variant="outlined">
        {coaches.length === ZERO ? (
          <Typography className="m-6">No Coaches Found</Typography>
        ) : (
          coaches.map((coach) => (
            <Row
              key={coach.id}
              coach={coach}
              processing={false}
              removeClick={() => {
                handleRemoveClick(coach.id);
              }}
            />
          ))
        )}
      </Paper>
      <AddCoachModal
        client={client}
        close={closeModal}
        coaches={available}
        isOpen={modalOpen}
      />
      <RemoveCoachModal
        close={() => {
          setRemoving('');
        }}
        isOpen={!!removing}
        processing={processing}
        onRemove={onRemove}
      />
    </>
  );
};

export default CoachesList;
