import { Card, CardContent, Divider, Skeleton } from '@/ui';

const NotesLoading = () => (
  <Card>
    <CardContent>
      <Skeleton className="mb-4" width="18%" />

      <div className="mb-4 flex align-middle">
        <Skeleton
          className="mr-4 shrink-0"
          height={24}
          variant="circular"
          width={24}
        />
        <Skeleton height={18} variant="text" width="33%" />
      </div>
      <Skeleton height={18} variant="text" width="93%" />
      <Skeleton height={18} variant="text" width="63%" />

      <Divider className="-mx-6 my-6" />

      <div className="mb-4 flex align-middle">
        <Skeleton
          className="mr-4 shrink-0"
          height={24}
          variant="circular"
          width={24}
        />
        <Skeleton height={18} variant="text" width="33%" />
      </div>
      <Skeleton height={18} variant="text" width="93%" />
      <Skeleton height={18} variant="text" width="63%" />

      <Divider className="-mx-6 my-6" />

      <div className="mb-4 flex align-middle">
        <Skeleton
          className="mr-4 shrink-0"
          height={24}
          variant="circular"
          width={24}
        />
        <Skeleton height={18} variant="text" width="33%" />
      </div>
      <Skeleton height={18} variant="text" width="93%" />
      <Skeleton height={18} variant="text" width="63%" />
    </CardContent>
  </Card>
);

export default NotesLoading;
