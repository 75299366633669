import MuiBadge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import { clsx } from 'clsx';

import type { BadgeProps as MuiBadgeProps } from '@mui/material/Badge';
import type { FC } from 'react';

interface BadgeProps extends MuiBadgeProps {
  color: Exclude<MuiBadgeProps['color'], 'default'>;
}

/* eslint-disable sort-keys -- CSS selectors shouldn't be alphabetically sorted. */
const StyledBadge = styled(MuiBadge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    outlineColor: theme.palette.common.white,
    outlineStyle: 'solid',
    outlineWidth: '2px',

    '&.MuiBadge-anchorOriginBottomRight': {
      margin: '0 -2px -2px 0',
    },
  },
}));
/* eslint-enable sort-keys */

const Badge: FC<BadgeProps> = ({ className, color = 'primary', ...rest }) => {
  const classes = clsx('mnt-badge', className);

  return <StyledBadge {...rest} className={classes} color={color} />;
};

export default Badge;
export type { BadgeProps };
