import { useCurrentUser, useView } from '@/hooks';
import {
  BottomBarButton,
  BottomBarMenuItem,
  SideBarButton,
  Skeleton,
} from '@/ui';

import type { User } from '@/types';

// Not put in utils to avoid having JSX outside of components
const buildMenu = (user: User, isClient: boolean) => {
  const menu = [
    <SideBarButton
      key="Home"
      icon="home"
      route={user.isCoach ? '/coach' : '/m'}
      title="Home"
    />,
    <SideBarButton
      key="Sessions"
      icon="calendar_today"
      route="/sessions/upcoming"
      title="Sessions"
    />,
    <SideBarButton
      key="member-growth"
      icon="landscape"
      route="/m/growth"
      title="Growth Plan"
    />,
    <SideBarButton
      key="member-insights"
      icon="insights"
      route="/m/insights"
      title="Insights"
    />,
    <SideBarButton
      key="member-documents"
      icon="article"
      route="/documents"
      title="Documents"
    />,
  ];

  if (user.isCoach) {
    menu.push(
      <SideBarButton
        key="coach-members"
        icon="group"
        route="/coach/members"
        title="Members"
      />,
    );

    menu.push(
      <SideBarButton
        key="app-switcher"
        icon="switch_account"
        route={isClient ? '/coach' : '/m'}
        title={isClient ? 'Coach app' : 'Client app'}
      />,
    );
  }

  if (user.superAdmin) {
    menu.push(
      <SideBarButton
        key="coach-admin"
        icon="admin_panel_settings"
        route="/coach/manage"
        title="Manage"
      />,
    );
  }

  return menu;
};

const buildMoreBottomMenu = (user: User) => {
  const { isCoach, superAdmin } = user;
  const menu = [
    <BottomBarMenuItem
      key="member-insights"
      icon="insights"
      label="Insights"
      route="/m/insights"
    />,
    <BottomBarMenuItem
      key="member-documents"
      icon="article"
      label="Documents"
      route="/documents"
    />,
  ];

  if (isCoach) {
    menu.push(
      <BottomBarMenuItem
        key="coach-templates"
        icon="article"
        label="Templates"
        route="/coach/templates"
      />,
    );
  }

  if (superAdmin) {
    menu.push(
      <BottomBarMenuItem
        key="coach-manage"
        icon="admin_panel_settings"
        label="Manage"
        route="/coach/manage"
      />,
    );
  }

  return menu;
};

const buildBottomMenu = (user: User) => {
  const { isCoach } = user;
  const home = !isCoach ? '/m' : '/coach';

  const bottomMenu = [
    <BottomBarButton key="mobile-home" icon="home" label="Home" route={home} />,
    <BottomBarButton
      key="mobile-sessions"
      icon="calendar_today"
      label="Sessions"
      route="/sessions/upcoming"
    />,
  ];

  if (isCoach) {
    bottomMenu.push(
      <BottomBarButton
        key="coach-members"
        icon="group"
        label="Members"
        route={`${home}/members`}
      />,
    );
  } else {
    bottomMenu.push(
      <BottomBarButton
        key="client-growth"
        icon="landscape"
        label="Growth"
        route={`${home}/growth`}
      />,
    );
  }

  return bottomMenu;
};

export const useLayout = () => {
  const { user, isUserLoading } = useCurrentUser();
  const { isClient, isViewLoading } = useView();
  if (isUserLoading || isViewLoading) {
    return {
      bottomMenu: <Skeleton />,
      menu: null,
      moreBottomMenu: <Skeleton />,
    };
  }

  const menu = buildMenu(user, isClient);
  const bottomMenu = buildBottomMenu(user);
  const moreBottomMenu = buildMoreBottomMenu(user);

  const homePath = isClient ? '/m' : '/coach';
  return { bottomMenu, homePath, menu, moreBottomMenu };
};

export default useLayout;
