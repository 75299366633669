import { useCurrentUser } from '@/hooks';
import { SurveyStatus } from '@/types';
import { Paper } from '@/ui';

import Empty from './Empty';
import Started from './Started';

import type { Survey } from '@/types';
import type { FC } from 'react';

interface CardProps {
  coach?: string;
  icon: string;
  id: string;
  name: string;
  survey?: Survey;
}

const Template: FC<CardProps> = ({ coach = '', icon, id, name, survey }) => {
  const isDraft = survey?.status === SurveyStatus.DRAFT;
  const { user } = useCurrentUser();

  const empty = !survey || isDraft;
  const hasCoach = !!coach || user.superAdmin;

  return (
    <Paper
      className="mt-10 flex w-full flex-col gap-8 rounded-xl border bg-white p-6"
      role="button"
      tabIndex={0}
    >
      {empty || !hasCoach ? (
        <Empty id={id} name={name} />
      ) : (
        <Started
          coach={coach}
          icon={icon}
          id={id}
          name={name}
          participants={survey.responses ?? []}
          status={survey.status}
        />
      )}
    </Paper>
  );
};
export default Template;
