import { useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { ZERO } from '@/constants';
import { useCompany, useCompanyMembers } from '@/hooks';
import { Tab, Tabs, Typography } from '@/ui';

import type { SyntheticEvent } from 'react';

import Info from '@/components/Company/Info';
import Stats from '@/components/Company/Stats';

const Company = () => {
  const { companyId = '' } = useParams();

  const navigate = useNavigate();
  const [tab, setTab] = useState(ZERO);

  const { companyData, isCompanyLoading } = useCompany(companyId);

  const { data: upcoming = [], isLoading: upcomingLoading } =
    useCompanyMembers(companyId);

  const { data: past = [], isLoading: pastLoading } = useCompanyMembers(
    companyId,
    false,
  );

  const onClick = (path: string) => () => {
    navigate(path);
  };

  const onChange = (_: SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  if (isCompanyLoading || !companyData) {
    return null;
  }

  const isCountsLoading = pastLoading || upcomingLoading;

  return (
    <div className="flex-1 gap-0 lg:flex">
      <Info company={companyData} />
      <div className="w-full bg-white px-12">
        <Stats />
        <Typography className="pt-10" variant="h4">
          Members
        </Typography>
        <Tabs value={tab} onChange={onChange}>
          <Tab
            label={`Current members ${
              !isCountsLoading ? `(${upcoming.length})` : ''
            }`}
            onClick={onClick(`/coach/manage/companies/${companyId}/current`)}
          />
          <Tab
            label={`Past members ${!isCountsLoading ? `(${past.length})` : ''}`}
            onClick={onClick(`/coach/manage/companies/${companyId}/past`)}
          />
        </Tabs>
        <div className="w-full pb-4">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Company;
