import { useCurrentUser, useFeatureLocked, useView } from '@/hooks';
import { PageHeaderLayout } from '@/ui';

import LockedFeature from '@/components/LockedStates';
import Loader from '@/pages/Growth/Loader';

const Growth = () => {
  const { user, isUserLoading } = useCurrentUser();
  const { isViewLoading } = useView();
  const { isFeatureLocked } = useFeatureLocked(user);

  // TODO: Loading state
  if (isUserLoading || isViewLoading) return null;

  if (isFeatureLocked) {
    return (
      <div className="px-32 py-8">
        <PageHeaderLayout title="Growth Plan" />
        <LockedFeature title="growth plan" />
      </div>
    );
  }

  return <Loader />;
};

export default Growth;
