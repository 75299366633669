import { TableBody, TableEmpty, TableLoading } from '@/ui';

import Row from './Row';

import type { User } from '@/types';
import type { FC } from 'react';

interface ListProps {
  coaches: User[];
  headers: string[];
  isLoading: boolean;
}

const List: FC<ListProps> = ({
  coaches = [],
  isLoading = false,
  headers = [],
}) => {
  if (isLoading) {
    return <TableLoading columns={headers.length} />;
  }
  if (!coaches.length) {
    return (
      <TableEmpty columns={headers.length} message="No coaches found ...." />
    );
  }

  return (
    <TableBody>
      {coaches.map((coach) => (
        <Row key={`${coach.id}-coach-management`} {...coach} />
      ))}
    </TableBody>
  );
};

export default List;
