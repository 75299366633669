import { clsx } from 'clsx';
import { useState } from 'react';
import { useNavigate } from 'react-router';

import {
  Avatar,
  Card,
  CardActionArea,
  CardContent,
  Icon,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from '@/ui';
import { toDateString } from '@/utils';

import DeleteConfirmation from './DeleteConfirmation';
import coachAiAvatar from './coach-ai-avatar.png';

import type { MessageThread } from '@/types';
import type { FC, MouseEvent } from 'react';

type SessionCardProps = {
  className?: string;
  messageThread: MessageThread;
};

const SessionCard: FC<SessionCardProps> = ({ className, messageThread }) => {
  const navigate = useNavigate();
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const classes = clsx('coach-ai-session-card mb-4', className);

  const onClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    navigate(`/m/workflow/${messageThread.id}`);
  };

  const onMenuClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchor(event.currentTarget);
  };

  const onMenuClose = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchor(null);
  };

  const onClose = () => setIsOpen(false);

  const onDelete = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setIsOpen(true);
  };

  return (
    <Card className={classes}>
      <DeleteConfirmation
        isOpen={isOpen}
        messageThreadId={messageThread.id}
        onClose={onClose}
      />
      <CardActionArea component="a" onClick={onClick}>
        <CardContent className="flex items-center justify-between leading-4">
          <Avatar src={coachAiAvatar} />

          <div className="mx-4 grow">
            <Typography variant="subtitle1">
              {messageThread.title || 'Coaching Session'}
            </Typography>
            <Typography color="text.secondary" variant="caption">
              {toDateString(new Date(messageThread.createdAt))}
            </Typography>
          </div>

          <IconButton color="primary" onClick={onMenuClick}>
            <Icon>more_horiz</Icon>
          </IconButton>
          <Menu anchorEl={anchor} open={!!anchor} onClose={onMenuClose}>
            <MenuItem onClick={onDelete}>
              <ListItemIcon>
                <Icon>trash</Icon>
                &nbsp; Delete
              </ListItemIcon>
            </MenuItem>
          </Menu>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default SessionCard;
