import { Button, Dialog, DialogActions, DialogContent, Typography } from '@/ui';

import type { FC } from 'react';

interface RemoveCoachModalProps {
  close: () => void;
  isOpen: boolean;
  onRemove: () => Promise<void>;
  processing: boolean;
}

const RemoveCoachModal: FC<RemoveCoachModalProps> = ({
  close,
  isOpen,
  onRemove,
  processing,
}) => (
  <Dialog open={isOpen} onClose={close}>
    <DialogContent className="p-10">
      <Typography variant="h5">Remove Coach</Typography>
      <div className="mt-4">
        <Typography variant="body1">
          Are you sure you want to remove this coach?
        </Typography>
      </div>
    </DialogContent>
    <DialogActions className="px-10 py-4">
      <Button disabled={processing} variant="contained" onClick={close}>
        Cancel
      </Button>
      <Button
        className="ml-3"
        color="warning"
        loading={processing}
        variant="contained"
        onClick={onRemove}
      >
        Remove
      </Button>
    </DialogActions>
  </Dialog>
);

export default RemoveCoachModal;
