import { Skeleton } from '@/ui';

const SessionCardSekeleton = () => (
  <div className="flex items-center p-6">
    <Skeleton height={56} variant="circular" width={56} />
    <div className="ml-6">
      <Skeleton height={20} variant="rectangular" width={100} />
      <Skeleton
        className="  mt-2"
        height={14}
        variant="rectangular"
        width={140}
      />
    </div>
  </div>
);

export default SessionCardSekeleton;
