import { styled } from '@mui/material';
import MuiCardHeader from '@mui/material/CardHeader';
import { clsx } from 'clsx';

import type { CardHeaderProps } from '@mui/material/CardHeader';
import type { FC } from 'react';

/* eslint-disable @typescript-eslint/no-magic-numbers -- CSS values make little sense as constants. */
const StyledHeader = styled(MuiCardHeader)<CardHeaderProps>(({ theme }) => ({
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
}));
/* eslint-enable @typescript-eslint/no-magic-numbers */

const CardHeader: FC<CardHeaderProps> = ({ className, ...rest }) => {
  const classes = clsx('mnt-card-header', className);

  return <StyledHeader {...rest} className={classes} />;
};
CardHeader.displayName = 'CardHeader';

export default CardHeader;
export type { CardHeaderProps };
