import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { useNavigate } from 'react-router-dom';

import { Icon } from '@/ui';

import type { FC } from 'react';

interface BottomBarButtonProps {
  icon: string;
  label: string;
  route: string;
}

const BottomBarButton: FC<BottomBarButtonProps> = ({
  icon: pendingIcon,
  route,
  label,
}) => {
  const navigation = useNavigate();

  const onClick = () => navigation(route);

  return (
    <BottomNavigationAction
      icon={<Icon>{pendingIcon}</Icon>}
      label={label}
      onClick={onClick}
    />
  );
};

export default BottomBarButton;
