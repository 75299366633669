import { useState } from 'react';

import { MAX_BOULDER_STEPS } from '@/constants';
import { Icon } from '@/ui';

import type { Milestone } from '@/types';
import type { FC } from 'react';

import ActionButton from '@/components/Growth/Milestone/Steps/ActionButton';
import MilestoneDialog from '@/components/Growth/MilestoneDialog';
import StepDialog from '@/components/Growth/StepDialog';

interface ActionsProps {
  milestone: Milestone;
}

const Actions: FC<ActionsProps> = ({ milestone }) => {
  const [milestoneOpen, setMilestoneOpen] = useState(false);
  const [stepOpen, setStepOpen] = useState(false);
  const milestoneId = milestone.id;

  const hasMaxBoulderSteps = milestone.steps.length >= MAX_BOULDER_STEPS;

  const openMilestone = () => setMilestoneOpen(true);
  const openStep = () => setStepOpen(true);

  if (milestone.completedAt) return null;

  return (
    <>
      <div className="flex justify-end gap-2">
        <ActionButton
          disabled={hasMaxBoulderSteps}
          milestoneId={milestone.id}
          onClick={openStep}
        >
          Add step
        </ActionButton>
        <div className="hidden sm:block">
          <ActionButton milestoneId={milestone.id} onClick={openMilestone}>
            <Icon>edit</Icon>
          </ActionButton>
        </div>
      </div>
      <MilestoneDialog
        isOpen={milestoneOpen}
        milestone={milestone}
        setOpen={setMilestoneOpen}
      />
      <StepDialog
        isOpen={stepOpen}
        milestoneId={milestoneId}
        setOpen={setStepOpen}
        step={{ milestoneId }}
      />
    </>
  );
};

export default Actions;
