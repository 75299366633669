import { Icon, IconButton, Typography } from '@/ui';

import type { FC } from 'react';

interface MobileProps {
  open: () => void;
}

const Mobile: FC<MobileProps> = ({ open }) => (
  <div className="mb-2.5 mt-6 flex justify-between gap-4 border-t border-cement-300 px-4 lg:hidden">
    <div className="mt-1 flex w-54 grow flex-col gap-2">
      <Typography variant="subtitle1">Need help deciding?</Typography>
      <Typography variant="body2">
        Schedule a call to learn more about Mento and the right plan for you.
      </Typography>
    </div>
    <IconButton
      className="self-center"
      size="large"
      variant="outlined"
      onClick={open}
    >
      <Icon>calendar_add_on</Icon>
    </IconButton>
  </div>
);

export default Mobile;
