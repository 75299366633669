import { captureMessage } from '@sentry/react';

import type { Coach, User } from '@/types';

// TODO: Remove this, it makes no sense to make this differentiation
export const currentUserQueryKeyName = (isClient: boolean) =>
  `current-user-${isClient ? 'client' : 'coach'}`;

// Utility function to look up which user in a list of coaches corresponds to a legacy
// coach object.
export const oldCoachToNew = (coach: Coach, coaches: User[] = []) =>
  coaches.find((user) => user.sub === coach.sub);

export const withCoachUser = (
  coach: Coach,
  coachUsers: User[] | undefined,
  callback: (coachUser: User) => void,
) => {
  const coachUser = oldCoachToNew(coach, coachUsers);
  if (coachUser) {
    callback(coachUser);
  } else {
    captureMessage(
      'Failed to map from old coach to new on sessions page',
      'error',
    );
  }
};
