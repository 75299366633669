import { useState } from 'react';

import { Button, Typography } from '@/ui';

import NewTeam from '@/components/Teams/NewTeam';

const Empty = () => {
  const [open, setOpen] = useState(false);

  const handleOnNewTeamClick = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  return (
    <div className="flex h-full flex-col items-center justify-center gap-5">
      <div className="px-4 md:w-7/12">
        <img
          alt="empty teams"
          className="w-full"
          src="https://mento-space.nyc3.cdn.digitaloceanspaces.com/assets/empty-teams.png"
        />
      </div>
      <Typography className="text-center text-cement-500" variant="h4">
        Support your team like never before
      </Typography>
      <Typography
        className="mx-auto px-4 text-center md:w-1/2 lg:w-1/3"
        color="text.secondary"
        variant="body1"
      >
        Discover how your team is truly feeling, identify their potential, and
        help them grow with Mento's coaching tools.
      </Typography>
      <Button
        color="primary"
        variant="contained"
        onClick={handleOnNewTeamClick}
      >
        Create a team
      </Button>
      {open && <NewTeam onClose={handleCloseModal} />}
    </div>
  );
};
export default Empty;
