import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { SESSION_FEEDBACK_TYPES } from '@/constants';
import { useView } from '@/hooks';
import { SessionFeedbackType } from '@/types';
import { Button, Dialog, TextareaAutosize, Typography } from '@/ui';
import { track } from '@/utils';

import FeedbackComplete from './Complete';
import FeedbackIcon from './Icon';

import type { Session, SessionFeedback } from '@/types';
import type { FC } from 'react';

interface Props {
  onClose: () => void;
  onCloseAndSchedule?: () => void;
  onUpdate: (response: SessionFeedback) => void;
  session: Session;
  showMaybeLayer?: boolean;
  skipShowSubmitted?: boolean;
}

const Feedback: FC<Props> = ({
  onClose,
  onCloseAndSchedule,
  onUpdate,
  session,
  showMaybeLayer = false,
  skipShowSubmitted = false,
}) => {
  const [search] = useSearchParams();
  const [pendingFeedback, setPendingFeedback] = useState<SessionFeedback>(
    session.feedback || ({} as SessionFeedback),
  );
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  // if its being shown in the overview, show it as read only
  const { isOverview: readOnly } = useView();
  const isModalInvalid =
    loading ||
    (pendingFeedback.category !== SessionFeedbackType.POSITIVE &&
      !pendingFeedback.content);

  const onIconClick = (feedback: SessionFeedbackType) => () => {
    if (readOnly) return;

    setPendingFeedback({
      ...pendingFeedback,
      category: feedback,
    });
  };
  const onShare = async () => {
    setLoading(true);
    await onUpdate({
      ...pendingFeedback,
      sessionId: session.id,
    });

    track('Clicked Add Feedback', {
      clientId: session.userId,
      coachId: session.coachId,
      feedback: pendingFeedback,
      sessionId: session.id,
    });

    // Edgecase: The mid-level Chemistry call feedback shouldn't have a secondary modal. Once they hit "share", simply close close out the modal.
    if (
      session.isChemistryCall &&
      pendingFeedback.category === SessionFeedbackType.MIXED
    ) {
      onClose();
    } else if (!skipShowSubmitted) {
      setSubmitted(true);
    } else {
      onClose();
    }
    setLoading(false);
  };

  const onLater = () => {
    setLoading(true);
    track('Clicked Maybe Later', {
      clientId: session.userId,
      coachId: session.coachId,
      sessionId: session.id,
    });
    onClose();
    setLoading(false);
  };

  const genereateSubtitleText = (type: SessionFeedbackType) => {
    switch (type) {
      case SessionFeedbackType.POSITIVE:
        return 'What made this a good match for you?';
      case SessionFeedbackType.MIXED:
        return `At Mento we value feedback. Could you share any feedback for ${session.coach.fullName} or Mento that would be valuable?`;
      case SessionFeedbackType.NEGATIVE:
        return `Please share any feedback for us on the match or ${session.coach.fullName}.`;
      default:
        return '';
    }
  };

  useEffect(() => {
    if (search.get('type')) {
      const mappedValue = search.get('type') as string;
      if (mappedValue.toUpperCase() in SessionFeedbackType) {
        setPendingFeedback((old) => ({
          ...old,
          category: mappedValue as SessionFeedbackType,
        }));
      }
    }
  }, []);

  return (
    <Dialog open onClose={!showMaybeLayer ? onClose : undefined}>
      <div className="p-6">
        {submitted ? (
          <FeedbackComplete
            pendingFeedback={pendingFeedback}
            session={session}
            onClose={onClose}
            onCloseAndSchedule={onCloseAndSchedule}
          />
        ) : (
          <div className="flex flex-col gap-2 text-center">
            <Typography className="headline mb-5 text-cement-500 " variant="h4">
              {`How was your ${session.isChemistryCall ? 'Chemistry Call' : 'session'} with ${session.coach.fullName || 'Your coach'}`}
            </Typography>
            <div className="flex flex-col gap-2 px-8 md:px-16">
              <FeedbackIcon
                feedbackType={SessionFeedbackType.POSITIVE}
                selected={pendingFeedback.category}
                session={session}
                onClick={onIconClick(SessionFeedbackType.POSITIVE)}
              />
              <FeedbackIcon
                feedbackType={SessionFeedbackType.MIXED}
                selected={pendingFeedback.category}
                session={session}
                onClick={onIconClick(SessionFeedbackType.MIXED)}
              />
              <FeedbackIcon
                feedbackType={SessionFeedbackType.NEGATIVE}
                selected={pendingFeedback.category}
                session={session}
                onClick={onIconClick(SessionFeedbackType.NEGATIVE)}
              />
            </div>
            <Typography className="body mt-4 select-none px-4 !font-bold text-cement-400">
              {SESSION_FEEDBACK_TYPES[pendingFeedback.category]?.title}
            </Typography>
            <Typography className="body -mt-2 mb-2 select-none px-4 pb-2 text-cement-400">
              {session.isChemistryCall
                ? genereateSubtitleText(pendingFeedback.category)
                : SESSION_FEEDBACK_TYPES[pendingFeedback.category]?.subtitle}
            </Typography>
            <TextareaAutosize
              className="w-full rounded-2xl border border-cement-300 bg-white p-4 font-sans"
              disabled={readOnly}
              minRows={3}
              style={{ resize: 'vertical' }}
              value={pendingFeedback.content || ''}
              onChange={(e) =>
                setPendingFeedback({
                  ...pendingFeedback,
                  content: e.target.value,
                })
              }
            />
            {!readOnly && (
              <div className="mt-6 flex items-center justify-center gap-2">
                {showMaybeLayer ? (
                  <Button
                    className="shrink-0"
                    color="primary"
                    disabled={loading}
                    variant="outlined"
                    onClick={onLater}
                  >
                    Maybe later
                  </Button>
                ) : null}
                <Button
                  className="w-full sm:w-40"
                  color="primary"
                  disabled={isModalInvalid}
                  loading={loading}
                  variant="contained"
                  onClick={onShare}
                >
                  Share
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default Feedback;
