import { useCurrentUser } from '@/hooks';
import { Grid, Typography } from '@/ui';

import Cal from './Cal';
import CalOnboarding from './CalOnboarding';

const CoachingHours = () => {
  const { user } = useCurrentUser();
  const username = user.coachProfile?.calendarUrl;

  return (
    <Grid spacing={4} container>
      <Grid md={5} xs={12} item>
        <Typography className="mb-6" variant="h4">
          Coaching hours
        </Typography>
        <Typography className="mb-4" variant="body1">
          This will be used by Members to schedule regular coaching sessions.
          You can also link other calendars to your Mento Google Calendar to
          help avoid conflicts.
        </Typography>
      </Grid>
      <Grid className="mt-1 flex flex-col gap-3" md={7} xs={12} item>
        {username ? (
          <Cal username={username} />
        ) : (
          <CalOnboarding email={user.email} />
        )}
      </Grid>
    </Grid>
  );
};

export default CoachingHours;
