import { captureException } from '@sentry/react';

import { zapIds } from '@/constants';
import { Env } from '@/types';

import type { ZapTypes } from '@/types';

// TODO: Should be an env variable
const ZAPIER_URL = 'https://hooks.zapier.com/hooks/catch/8583043/';

const notifySlack = async (type: ZapTypes, urlData: string) => {
  const env = import.meta.env.VITE_APP_ENV as Env;

  if (env !== Env.Local) {
    try {
      await fetch(`${ZAPIER_URL}${zapIds[type][env]}${urlData}`);
    } catch (e) {
      captureException(e);
    }
  }
};

export { notifySlack };
