import { useNavigate } from 'react-router';

import useSharedCardActions from './useSharedCardActions';

import type { GeneratorFunction } from './types';

const useAiWorkflowActions = (): GeneratorFunction => {
  const { handleClick } = useSharedCardActions();
  const navigate = useNavigate();

  return ({ card }) => {
    const label = 'Start';
    return [
      {
        buttonProps: {
          color: 'white',
        },
        label,
        onClick: () => {
          handleClick({ card, label });
          navigate('/m/workflow/');
        },
      },
    ];
  };
};

export default useAiWorkflowActions;
