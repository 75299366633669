import { useFlagBag } from '@happykit/flags/context';

import { Button, Icon, Typography } from '@/ui';

import type { FeatureFlags, Milestone } from '@/types';
import type { FC, MouseEvent } from 'react';

interface MilestoneDataCTAProps {
  color: string;
  done: boolean;
  handleOpenDialog: (event: MouseEvent) => void;
  milestone: Milestone;
}
const MilestoneDataCTA: FC<MilestoneDataCTAProps> = ({
  milestone,
  color,
  done,
  handleOpenDialog,
}) => {
  const { flags } = useFlagBag<FeatureFlags>();

  const noSteps = !milestone.totalSteps;
  if (noSteps && flags?.growth_suggestions) {
    return (
      <Typography
        className={`mb-0.5 ${color} flex items-center`}
        variant="body2"
      >
        <Icon className="mr-1" fontSize="small">
          magic_button
        </Icon>
        3 suggested steps
      </Typography>
    );
  }

  if (noSteps)
    return (
      <Button
        color="info"
        size="small"
        variant="contained"
        light
        onClick={handleOpenDialog}
      >
        Add step
      </Button>
    );
  return (
    <Typography className={color} variant="body2">
      {done
        ? 'All steps complete!'
        : `${milestone.completeSteps} of ${milestone.totalSteps} complete`}
    </Typography>
  );
};

export default MilestoneDataCTA;
