import { Link } from '@mui/material';
import { getTimeZones } from '@vvo/tzdb';
import { useEffect, useState } from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input';

import { ZERO } from '@/constants';
import { Grid, MenuItem, TextField, Typography } from '@/ui';

import type { ChangeEvent, FC } from 'react';

import LockCard from '@/components/Profile/Card/Lock';

interface EditClientAccountDetailsProps {
  email: string;
  onPhoneChange: (value?: string) => void;
  onTimezoneChange: (value: string) => void;
  phone?: string;
  timezone?: string;
}

const EditClientAccountDetails: FC<EditClientAccountDetailsProps> = ({
  email,
  phone = '',
  timezone = '',
  onTimezoneChange,
  onPhoneChange,
}) => {
  const [timezones, setTimezones] = useState<
    { label: string; value: string }[]
  >([]);

  useEffect(() => {
    setTimezones(
      getTimeZones()
        .filter((f) => f.continentCode === 'NA' || f.continentCode === 'EU')
        .map((t) => {
          const [time] = t.currentTimeFormat.split(' ');

          return {
            label: `(GMT${time}) ${t.mainCities[0]}`,
            value: t.name,
          };
        }),
    );
  }, []);

  const isPhoneInvalid =
    !isValidPhoneNumber(phone || '', 'US') && (phone || '').length > ZERO;
  const message = isPhoneInvalid
    ? 'Phone is invalid'
    : '*Required to use SMS notifications';

  const onTimezone = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
    onTimezoneChange(e.target.value);
  const onPhone = (e: ChangeEvent<HTMLInputElement>) =>
    onPhoneChange(e.target.value);

  return (
    <Grid spacing={4} container>
      <Grid md={5} xs={12} item>
        <Typography className="mb-2" variant="h4">
          Account details
        </Typography>
        <Typography className="mb-1" variant="body1">
          For questions about your membership, contact{' '}
          <Link
            className="text-sage-600"
            href="mailto:concierge@mento.co?subject=Mento Coaching Questions"
          >
            concierge@mento.co
          </Link>{' '}
          or view our{' '}
          <Link className="text-sage-600 hover:underline" href="/m/support">
            frequently asked questions.
          </Link>
        </Typography>
      </Grid>
      <Grid className="mt-1 flex flex-col gap-3" md={7} xs={12} item>
        <LockCard icon="mail" title={email} />
        <div className="mt-2 flex flex-col gap-4">
          <TextField
            label="Default timezone"
            placeholder="Default timezone"
            value={timezone}
            select
            onChange={onTimezone}
          >
            {timezones.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            error={isPhoneInvalid}
            helperText={message}
            label="Phone"
            placeholder="Phone"
            value={phone || ''}
            onChange={onPhone}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default EditClientAccountDetails;
