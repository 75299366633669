import MuiDialogContent from '@mui/material/DialogContent';
import { styled } from '@mui/material/styles';

import type { DialogContentProps } from '@mui/material/DialogContent';
import type { FC } from 'react';

const StyledDialogContent = styled(MuiDialogContent)<DialogContentProps>(
  () => ({
    padding: '3rem',
  }),
);

const DialogContent: FC<DialogContentProps> = ({ className, ...rest }) => (
  <StyledDialogContent {...rest} className={className} />
);
DialogContent.displayName = 'DialogContent';

export default DialogContent;
export type { DialogContentProps };
