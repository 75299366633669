import { sortOptions } from '@/constants';
import { FormControl, InputLabel, MenuItem, Select } from '@/ui';

import type { SortValues } from '@/types';
import type { SelectChangeEvent } from '@/ui';
import type { FC } from 'react';

interface SortProps {
  setSort: (event: SelectChangeEvent<SortValues>) => void;
  sort: SortValues;
}

const Sort: FC<SortProps> = ({ sort, setSort }) => (
  <FormControl className="min-w-64">
    <InputLabel id="sort-by-select-label">Sort by</InputLabel>
    <Select
      id="sort-by-select"
      label="Sort by"
      labelId="sort-by-select-label"
      value={sort}
      onChange={setSort}
    >
      {sortOptions.map(({ label, value }) => (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

export default Sort;
