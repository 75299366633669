import { PlanTypes, type Price } from '@/types';

export const PRICES: Array<Price> = [
  {
    amount: 300,
    id: {
      production: 'price_1MyMuSDl97h2uSSXwtE12YEv',
      test: 'price_1MyNP4Dl97h2uSSX94EAJhPc',
    },
    pill: 'Director-Level and below',
    title: 'Mento Coaching',
    type: PlanTypes.classic,
    feature:
      'Access to all coaches, <strong>excluding</strong> Executive Level Coaches',
    additionalFeatures: [
      'Two <strong>45-minute</strong> Coaching Sessions per month',
      'Email and chat communication with your coach',
      'Dedicated Concierge',
      'Access to Mento Tools: Growth Plan and Peer Insights',
    ],
  },
  {
    amount: 1000,
    id: {
      production: 'price_1OJ7tlDl97h2uSSXccDpmNo8',
      test: 'price_1PJcvPDl97h2uSSXYruKBFhh',
    },
    pill: 'Founders & Executives',
    title: 'Mento Executive Coaching',
    type: PlanTypes.exec,
    feature:
      '<strong>Exclusive Access to Coaches</strong> who are <strong>founders and C-Suite executives</strong>',
    additionalFeatures: [
      'Two <strong>60-minute</strong> Coaching Sessions per month',
      '<strong>Unlimited</strong> chat and email support between sessions; <strong/>ad hoc coaching</strong> sessions when needed',
      'Dedicated Concierge',
      'Access to Mento Tools: Growth Plan and Peer Insights',
      'Access to Tripartites & 360s',
    ],
  },
];

export const ANUAL_PRICES: Array<Price> = [
  {
    amount: 250,
    id: {
      production: 'price_1OP7qRDl97h2uSSXAVOyWFxT',
      test: 'price_1MyNPlDl97h2uSSX3LERBcpo',
    },
    pill: 'Director-Level and below',
    sub: 'Billed annually',
    title: 'Mento Coaching',
    type: PlanTypes.classic,
    feature:
      'Access to all coaches, <strong>excluding</strong> Executive Level Coaches',
    additionalFeatures: [
      'Two <strong>45-minute</strong> Coaching Sessions per month',
      'Email and chat communication with your coach',
      'Dedicated Concierge',
      'Access to Mento Tools: Growth Plan and Peer Insights',
    ],
  },
  {
    amount: 875,
    id: {
      production: 'price_1PMb4BDl97h2uSSXNgBxe0TQ',
      test: 'price_1PJcw6Dl97h2uSSX0gNHxwvZ',
    },
    pill: 'Founders & Executives',
    sub: 'Billed annually',
    title: 'Mento Executive Coaching',
    type: PlanTypes.exec,
    feature:
      '<strong>Exclusive Access to Coaches</strong> who are <strong>founders and C-Suite executives</strong>',
    additionalFeatures: [
      'Two <strong>60-minute</strong> Coaching Sessions per month',
      '<strong>Unlimited</strong> chat and email support between sessions; <strong/>ad hoc coaching sessions</strong> when needed',
      'Dedicated Concierge',
      'Access to Mento Tools: Growth Plan and Peer Insights',
      'Access to Tripartites & 360s',
    ],
  },
];
