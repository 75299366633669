export interface Metadata {
  discount: string;
  sub: string;
}
export enum PlanTypes {
  classic,
  exec,
}

export interface Price {
  additionalFeatures: string[];
  amount: number;
  feature: string;
  frequency?: string;
  id: { production: string; test: string };
  metadata?: Metadata;
  nickname?: string;
  pill: string;
  sub?: string;
  title: string;
  type: PlanTypes;
  unit_amount_decimal?: number;
}
