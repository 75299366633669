import { useNavigate } from 'react-router';

import useSharedCardActions from './useSharedCardActions';

import type { GeneratorFunction } from './types';

const useCreateTeamActions = (): GeneratorFunction => {
  const navigate = useNavigate();
  const { handleClick } = useSharedCardActions();

  return ({ card }) => {
    const label = 'Start team';
    return [
      {
        buttonProps: { color: 'info', variant: 'contained' },
        label,
        onClick: () => {
          handleClick({ card, label });
          navigate('/teams');
        },
      },
    ];
  };
};

export default useCreateTeamActions;
