import { Button } from '@/ui';
import { isDateToday } from '@/utils';

import type { Session, User } from '@/types';
import type { FC } from 'react';

interface ChemistryCallInfoProps {
  isCoachViewing: boolean;
  session: Session;
  user: User;
}

const ChemistryCallInfo: FC<ChemistryCallInfoProps> = ({
  session,
  user,
  isCoachViewing,
}) => {
  if (!session.isChemistryCall) {
    return null;
  }

  return (
    <div className="flex w-full gap-2 md:w-auto">
      {!isDateToday(session.startedAt) && (
        <Button
          className="w-full md:w-auto"
          href={
            isCoachViewing
              ? `/coach/members/${user.id}`
              : `/m/profiles-coach/${user.id}`
          }
          variant="outlined"
        >
          Profile
        </Button>
      )}
      <Button
        className="w-full md:w-auto"
        href="https://mentoteam.notion.site/Mento-Chemistry-Call-FAQ-02720c0978814c43bc2f0d2f1e77e21c"
        rel="noopener noreferrer"
        target="_blank"
        variant="outlined"
      >
        Learn More
      </Button>
    </div>
  );
};
export default ChemistryCallInfo;
