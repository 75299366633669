import { DocMimeType, DocumentActions, DocumentViewState } from '@/types';

import type { DocumentDisplayType, IDictionary } from '@/types';

export const TEMPLATE_LIMIT = 12;
export const DOCUMENT_LIMIT = 40;
export const FILE_SIZE_LIMIT = 5242880;
export const NUM_RECENTLY_UPDATED_DOCUMENTS_TO_SHOW = 3;

export const DocumentDisplay: IDictionary<DocumentDisplayType> = {
  [DocMimeType.DOCUMENT]: {
    icon: 'doc',
    label: 'Document',
    shortLabel: 'Docs',
  },
  [DocMimeType.SPREADSHEET]: {
    icon: 'sheet',
    label: 'Spreadsheet',
    shortLabel: 'Sheets',
  },
  [DocMimeType.PRESENTATION]: {
    icon: 'slide',
    label: 'Presentation',
    shortLabel: 'slides',
  },
  [DocMimeType.PDF]: {
    icon: 'pdf',
    label: 'PDF',
    shortLabel: 'pdf',
  },
};

export const DocumentActionIcons: IDictionary<string> = {
  [DocumentActions.Rename]: 'edit',
  [DocumentActions.Private]: 'visibility',
  [DocumentActions.Duplicate]: 'content_copy',
  [DocumentActions.Download]: 'download',
  [DocumentActions.Share]: 'share',
  [DocumentActions.Delete]: 'trash',
  [DocumentActions.ShareSettings]: 'visibility_lock',
};

export const BASE_DOCS_URL = 'https://docs.google.com';
export const BASE_DRIVE_URL = 'https://drive.google.com';

export const FILE_ERRORS: IDictionary<string> = {
  'file-invalid-type': 'File type is not supported.',
  'file-too-large': 'File is larger than 5mb.',
  'too-many-files': 'More than 1 file was selected.',
};

export const DocumentViewIcons: IDictionary<string> = {
  [DocumentViewState.GRID]: 'view_list',
  [DocumentViewState.LIST]: 'grid_view',
};
