import { DOCUMENT_LIMIT } from '@/constants';
import {
  Avatar,
  Divider,
  Icon,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@/ui';

import type { User } from '@/types';

const ShareInputMaxDocumentsMembers = ({
  members = [],
  disableOnlyMe,
}: {
  disableOnlyMe?: boolean;
  members: User[];
}) => {
  if (!members.length && !disableOnlyMe) {
    return null;
  }

  return (
    <>
      <Divider className="mb-4" />
      <Typography className="mx-4 mb-1" variant="subtitle1">
        The following users have reached their {DOCUMENT_LIMIT} document limit:
      </Typography>
      <List>
        {members.map((member) => (
          <ListItem key={member.id} className="opacity-50">
            <Avatar
              className="mr-2"
              firstName={member.firstName}
              lastName={member.lastName}
              size="s"
              src={member.avatarUrl}
            />
            <ListItemText primary={member.fullName} />
          </ListItem>
        ))}
        {disableOnlyMe && (
          <ListItem className="opacity-50">
            <Icon className="mr-2">visibility_lock</Icon>
            <ListItemText primary="Only me" />
          </ListItem>
        )}
      </List>
    </>
  );
};

export default ShareInputMaxDocumentsMembers;
