import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import { getCompanies } from '@/api';
import { COMPANY_HEADERS, ONE, ZERO } from '@/constants';
import {
  Button,
  PageHeaderLayout,
  Pagination,
  Search,
  Table,
  TableContainer,
} from '@/ui';

import type { ChangeEvent } from 'react';

import List from '@/components/Companies/List';
import Header from '@/components/Companies/List/Header';
import Modal from '@/components/Companies/New/modal';

const Companies = () => {
  const [page, setPage] = useState<number>(ONE);
  const [search, setSearch] = useState('');
  const [newCompany, setNewCompany] = useState(false);

  const { data, isLoading } = useQuery(['companies', search, page], () =>
    getCompanies(search, page),
  );

  useEffect(() => {
    setPage(ONE);
  }, [search]);

  const onAddCompany = () => setNewCompany(true);
  const onCloseAddCompany = () => setNewCompany(false);

  const onPageChange = (_: ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return (
    <div className="w-full p-4">
      <PageHeaderLayout title="Companies">
        <Button variant="contained" onClick={onAddCompany}>
          Add company
        </Button>
      </PageHeaderLayout>

      <Search
        className="mt-4 w-full md:w-1/3"
        search={search}
        setSearch={setSearch}
      />

      <div className="flex flex-1 flex-col pt-4">
        <TableContainer>
          <Table aria-label="companies table">
            <Header headers={COMPANY_HEADERS} />
            <List
              companies={data?.items || []}
              headers={COMPANY_HEADERS}
              isLoading={isLoading}
            />
          </Table>
        </TableContainer>
        <Pagination
          count={data?.pages ?? ZERO}
          page={page}
          onChange={onPageChange}
        />
      </div>
      {newCompany && <Modal onClose={onCloseAddCompany} />}
    </div>
  );
};

export default Companies;
