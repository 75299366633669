import { useState } from 'react';

import { SessionListType } from '@/types';
import { ClickAwayListener, Icon, IconButton, Menu } from '@/ui';

import NextMenuItems from './Next';
import PastMenuItems from './Past';
import UpcomingMenuItems from './Upcoming';

import type { Session } from '@/types';
import type { FC } from 'react';

interface SessionCardMenuProps {
  handleOpenMenuOptions: (e: React.MouseEvent) => void;
  loading?: boolean;
  onCancel?: (val: Session) => void;
  onFeedback?: (session: Session) => void;
  onMessage?: (e: React.MouseEvent<HTMLElement>) => void;
  onReschedule?: (val: Session) => void;
  onViewProfile?: (e: React.MouseEvent<HTMLElement>) => void;
  session?: Session;
  type?: SessionListType;
}

const SessionCardMenu: FC<SessionCardMenuProps> = ({
  handleOpenMenuOptions,
  loading,
  onCancel,
  onFeedback,
  onMessage,
  onReschedule,
  onViewProfile,
  session,
  type,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenuClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    handleOpenMenuOptions(e);
    setAnchorEl(e.currentTarget);
  };

  const handleOnClose = (e: Event | React.SyntheticEvent) => {
    e.stopPropagation();
    setAnchorEl(null);
  };
  if (!type || !session || loading) return null;

  const mappedComponent = {
    [SessionListType.Past]: (
      <PastMenuItems
        handleOnClose={handleOnClose}
        session={session}
        onFeedback={onFeedback}
        onMessage={onMessage}
        onViewProfile={onViewProfile}
      />
    ),
    [SessionListType.Upcoming]: (
      <UpcomingMenuItems
        handleOnClose={handleOnClose}
        session={session}
        onCancel={onCancel}
        onMessage={onMessage}
        onReschedule={onReschedule}
        onViewProfile={onViewProfile}
      />
    ),
    [SessionListType.Next]: (
      <NextMenuItems
        handleOnClose={handleOnClose}
        session={session}
        onCancel={onCancel}
        onReschedule={onReschedule}
      />
    ),
  };

  return (
    <>
      <IconButton className="self-baseline" onClick={handleMenuClick}>
        <Icon>more_horiz</Icon>
      </IconButton>
      <ClickAwayListener onClickAway={handleOnClose}>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'bottom',
          }}
          open={open}
          transformOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}
          onClick={handleOnClose}
          onClose={handleOnClose}
        >
          {mappedComponent[type]}
        </Menu>
      </ClickAwayListener>
    </>
  );
};

export default SessionCardMenu;
