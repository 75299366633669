import { track } from '@/utils';

import mentoApi from './mentoApi';

import type { MilestoneStep, MilestoneSteps } from '@/types';

export interface GetMilestoneStepsParams {
  complete?: boolean;
  growthPlanId?: string;
  page?: number;
}
export const getMilestoneSteps = async (
  params: GetMilestoneStepsParams,
): Promise<MilestoneSteps> => (await mentoApi.get('/steps', { params })).data;

export interface GetMilestoneStepParams {
  id: string;
}
export const getMilestoneStep = async (
  params: GetMilestoneStepParams,
): Promise<MilestoneStep> => (await mentoApi.get(`/steps/${params.id}`)).data;

export const postMilestoneStep = async (
  milestoneStep: Partial<MilestoneStep>,
): Promise<MilestoneStep> => {
  track('Create Step');
  return (await mentoApi.post('/steps', milestoneStep)).data;
};

export const patchMilestoneStep = async ({
  id,
  ...milestoneStep
}: Partial<MilestoneStep> & {
  isComplete?: boolean;
}): Promise<MilestoneStep> => {
  if (milestoneStep.isComplete) {
    track('Complete Step');
  } else {
    track('Update Step');
  }
  return (await mentoApi.patch(`/steps/${id}`, { step: milestoneStep })).data;
};

export interface ReorderMilestoneStepParams {
  destination: string;
  origin: string;
}
export const reorderMilestoneSteps = async (
  params: ReorderMilestoneStepParams,
) => {
  track('Reorder Steps');
  return (await mentoApi.patch('/steps/reorder', params)).data;
};

export const deleteMilestoneStep = async ({ id }: { id: string }) => {
  track('Delete Step');
  return mentoApi.delete(`/steps/${id}`);
};
