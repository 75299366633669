import clsx from 'clsx';
import { forwardRef } from 'react';

import { Icon } from '@/ui';

import type { DragDirections, MilestoneStep } from '@/types';

import Step from '@/components/Growth/Dashboard/StepList/Step';

interface RowProps {
  isDraggable?: boolean;
  isDragging?: boolean;
  isOver?: DragDirections;
  step: MilestoneStep;
}

const Row = forwardRef<HTMLDivElement, RowProps>(
  ({ isDraggable = false, isDragging = false, isOver, step }, ref) => (
    <div
      ref={ref}
      className={clsx('group flex justify-center border-0 border-solid', {
        'border-b-sage-500 border-b': !isDragging && isOver === 'DOWN',
        'border-t-sage-500 border-t': !isDragging && isOver === 'UP',
        'opacity-40': isDragging,
        'select-none': isDraggable,
      })}
    >
      {isDraggable && (
        <div className="hidden p-2 opacity-0 lg:grid group-hover:lg:opacity-100">
          <Icon>reorder</Icon>
        </div>
      )}
      <Step step={step} />
    </div>
  ),
);

Row.displayName = 'Row';

export default Row;
