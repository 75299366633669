import { clsx } from 'clsx';
import { forwardRef, useEffect, useState } from 'react';

import Avatar from '../Avatar';
import Checkbox from '../Checkbox';
import Icon from '../Icon';
import ListItemText from '../ListItemText';
import { MenuItem } from '../Menu/';
import Select from '../Select';

import type { SelectProps } from '../Select';
import type { User } from '@/types';
import type { SelectChangeEvent } from '@mui/material';
import type { ReactNode } from 'react';

interface SelectMultipleUsersProps extends SelectProps {
  defaultValue?: string[];
  disableOnlyMe?: boolean;
  extraMarkup?: ReactNode;
  values: Partial<User>[];
}

const SelectMultipleUsers = forwardRef<
  HTMLInputElement,
  SelectMultipleUsersProps
>(
  (
    {
      className,
      defaultValue,
      disableOnlyMe,
      extraMarkup,
      values,
      onChange,
      ...rest
    },
    ref,
  ) => {
    const classes = clsx('mnt-select-multiple', className);
    const [current, setCurrent] = useState<string[]>(defaultValue || []);
    const ONLY_ME = '';

    const handleChange = (
      event: SelectChangeEvent<string[]>,
      child: ReactNode,
    ) => {
      const { target } = event;

      // Handling ONLY_ME select
      if (target.value === ONLY_ME || target.value.includes(ONLY_ME)) {
        setCurrent([]);

        if (onChange) {
          onChange(
            {
              ...event,
              target: { ...target, value: [] },
            } as SelectChangeEvent<string[]>,
            child,
          );
        }
        return;
      }

      // Normal flow
      setCurrent(
        typeof target.value === 'string'
          ? target.value.split(',')
          : target.value,
      );

      if (onChange) {
        onChange(event, child);
      }
    };

    const renderValue = (selected: string[]) => {
      if (!selected.length && disableOnlyMe) {
        return '';
      }
      if (!selected.length) {
        return 'Only me';
      }

      // TODO: @ENRIQUE-RAMIREZ: FIND A BETTER WAY TO ABSTRACT THIS OR UNIT TEST THIS!!!
      return !selected.length
        ? 'Only me'
        : selected
            .reduce<string[]>((aggr, email, index) => {
              const PEOPLE_TO_SHOW = 2;
              if (index + 1 <= PEOPLE_TO_SHOW) {
                aggr.push(
                  values.find((value) => email === value.email)
                    ?.fullName as string,
                );
              } else {
                /* eslint-disable-next-line no-param-reassign -- This is what reducers are for! */
                aggr[PEOPLE_TO_SHOW] = `+${index + 1 - PEOPLE_TO_SHOW}`;
              }

              return aggr;
            }, [])
            .join(', ');
    };

    useEffect(() => {
      if (defaultValue?.length) {
        setCurrent(defaultValue);
      }
    }, [defaultValue?.length]);

    return (
      <Select
        className={classes}
        {...rest}
        ref={ref}
        renderValue={renderValue}
        value={current}
        displayEmpty
        multiple
        onChange={handleChange}
      >
        {values.map((user) => (
          <MenuItem key={user.id} value={user.email}>
            <Avatar
              firstName={user.firstName}
              lastName={user.lastName}
              size="s"
              src={user.avatarUrl}
              sx={{ mr: 1 }}
            />
            <ListItemText primary={user.fullName} />
            <Checkbox checked={current.includes(user.email as string)} />
          </MenuItem>
        ))}
        {!disableOnlyMe && (
          <MenuItem value={ONLY_ME}>
            <Icon sx={{ mr: 1 }}>visibility_lock</Icon>
            <ListItemText primary="Only me" />
            <Checkbox checked={current.length === 0} />
          </MenuItem>
        )}
        {extraMarkup}
      </Select>
    );
  },
);
SelectMultipleUsers.displayName = 'SelectMultipleUsers';

export default SelectMultipleUsers;
export type { SelectMultipleUsersProps };
