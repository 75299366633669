import { useState } from 'react';

import { createFeedback, updateFeedback } from '@/api';
import { ONE } from '@/constants';
import { useCurrentUser, useSessions, useView } from '@/hooks';
import { SessionListType, SessionStatus } from '@/types';
import { Pagination } from '@/ui';
import { groupByDate, track } from '@/utils';

import SessionList from '../List';

import type { Session, SessionFeedback } from '@/types';
import type { ChangeEvent, FC } from 'react';

import Feedback from '@/components/Feedback';

const Past: FC = () => {
  const { user } = useCurrentUser();
  const { selectedUser } = useView();
  const [page, setPage] = useState<number>(ONE);
  const concerningUserId = selectedUser.id || user.id;
  const [openFeedback, setOpenFeedback] = useState<Session | null>();

  const {
    data: sessions = {
      items: {} as Record<string, Session[]>,
      pages: 1,
      total: 1,
    },
    isLoading,
    refetch,
  } = useSessions({
    order: 'desc',
    page,
    statuses: [SessionStatus.DONE],
    userId: concerningUserId,
  });
  const pastSessions = groupByDate(sessions.items);

  const handleOnFeedback = (session: Session) => {
    track('Clicked Provide Feedback', {
      sessionId: session.id,
    });
    setOpenFeedback({ ...session });
  };

  const onUpdate = async (feedback: SessionFeedback) => {
    try {
      if (feedback.id) {
        await updateFeedback(feedback.id, {
          category: feedback.category,
          content: feedback.content,
        });
      } else {
        await createFeedback({
          category: feedback.category,
          content: feedback.content,
          sessionId: feedback.sessionId,
        });
      }
      refetch();
    } catch (error) {
      console.error(error);
    }
  };

  const onClose = () => setOpenFeedback(null);

  const onPageChange = (_: ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return (
    <>
      <SessionList
        loading={isLoading}
        sessions={pastSessions}
        type={SessionListType.Past}
        onFeedback={handleOnFeedback}
      />
      <Pagination count={sessions.pages} page={page} onChange={onPageChange} />
      {!!openFeedback && (
        <Feedback
          session={openFeedback}
          skipShowSubmitted
          onClose={onClose}
          onUpdate={onUpdate}
        />
      )}
    </>
  );
};

export default Past;
