import { useMutation, useQuery } from '@tanstack/react-query';

import { createNewNote, deleteNewNote, editNewNote, getNewNotes } from '@/api';

export const useNotes = (owneableId: string, page = 1, limit = 10) => {
  const {
    data,
    isLoading: isNotesLoading,
    refetch: refetchNotes,
  } = useQuery(
    ['notes', limit, page, owneableId],
    () => getNewNotes(owneableId, page, limit),
    {},
  );

  return {
    data,
    isNotesLoading,
    refetchNotes,
  };
};

export const useCreateNote = () => useMutation({ mutationFn: createNewNote });
export const useEditNote = () => useMutation({ mutationFn: editNewNote });
export const useDeleteNote = () => useMutation({ mutationFn: deleteNewNote });
