import { styled } from '@mui/material';
import MuiTabs from '@mui/material/Tabs';
import { clsx } from 'clsx';

import type { TabsProps } from '@mui/material/Tabs';
import type { FC } from 'react';

/* eslint-disable sort-keys -- CSS selectors shouldn't be alphabetically sorted. */
const StyledTabs = styled(MuiTabs)<TabsProps>(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,

  '&.MuiTabs-vertical': {
    borderBottom: 0,
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));
/* eslint-enable sort-keys */

const Tabs: FC<TabsProps> = ({ className, ...rest }) => {
  const classes = clsx('mnt-tabs', className);

  return <StyledTabs {...rest} className={classes} />;
};
Tabs.displayName = 'Tabs';

export default Tabs;
export type { TabsProps };
