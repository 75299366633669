import { Icon, Typography } from '@/ui';

import type { FC } from 'react';

const Info: FC = () => (
  <>
    <Typography variant="h4">Select your membership</Typography>
    <Typography variant="subtitle1">Both plans include:</Typography>
    <ul className="my-0 space-y-1 px-0 pt-2">
      <Typography className="flex text-cement-400" variant="body1">
        <Icon className="mr-2 !text-bloom-500">check</Icon>
        Access to top experts & leaders in your field
      </Typography>
      <Typography className="flex text-cement-400" variant="body1">
        <Icon className="mr-2 !text-bloom-500">check</Icon>
        Software tools to help you grow & stay on track
      </Typography>
      <Typography className="flex text-cement-400" variant="body1">
        <Icon className="mr-2 !text-bloom-500">check</Icon>
        Community events & workshops
      </Typography>
    </ul>
  </>
);

export default Info;
