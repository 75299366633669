import { useState } from 'react';
import { useController } from 'react-hook-form';

import { UploadType } from '@/types';
import { Avatar, Button, Grid, TextField, Typography } from '@/ui';

import type { CompanyForm } from '@/types';
import type { FC } from 'react';
import type { Control } from 'react-hook-form';

import UploadImage from '@/components/Upload';

interface CompanyProfileProps {
  companyId: string;
  control: Control<CompanyForm>;
}

const CompanyProfile: FC<CompanyProfileProps> = ({ companyId, control }) => {
  const [uploadingPhoto, setUploadingPhoto] = useState(false);

  const { field: name, fieldState: nameState } = useController({
    control,
    name: 'name',
  });
  const { field: location, fieldState: locationState } = useController({
    control,
    name: 'location',
  });
  const { field: industry, fieldState: industryState } = useController({
    control,
    name: 'industry',
  });
  const { field: pocName, fieldState: pocNameState } = useController({
    control,
    name: 'pocName',
  });
  const { field: pocEmail, fieldState: pocEmailState } = useController({
    control,
    name: 'pocEmail',
  });
  const { field: website, fieldState: websiteState } = useController({
    control,
    name: 'website',
  });
  const { field: linkedin, fieldState: linkedinState } = useController({
    control,
    name: 'linkedin',
  });
  const { field: avatarUrl } = useController({ control, name: 'avatarUrl' });
  const onUpload = () => setUploadingPhoto(true);
  const onUploadClose = (url?: string) => {
    if (url) {
      avatarUrl.onChange(url);
    }
    setUploadingPhoto(false);
  };

  return (
    <Grid spacing={4} container>
      <Grid md={4} xs={12} item>
        <Typography className="mb-6" variant="h4">
          Company info
        </Typography>
        <Typography className="mb-4" variant="body1">
          Basic company information and domains to use for connecting members to
          this company.
        </Typography>
        <Avatar
          className="my-4"
          icon="domain"
          iconClassName="text-5xl"
          size="xl"
          src={avatarUrl.value}
        />
        <Button className="mt-4" variant="contained" onClick={onUpload}>
          Upload image
        </Button>
      </Grid>
      <Grid className="flex flex-col gap-4 md:mt-6" md={8} xs={12} item>
        <TextField
          {...name}
          className="w-full"
          error={nameState.invalid}
          helperText={nameState.error?.message}
          label="Name"
          variant="outlined"
        />
        <TextField
          {...location}
          className="w-full"
          error={locationState.invalid}
          helperText={locationState.error?.message}
          label="Location"
          variant="outlined"
        />
        <TextField
          {...industry}
          className="w-full"
          error={industryState.invalid}
          helperText={industryState.error?.message}
          label="Industry"
          variant="outlined"
        />
        <TextField
          {...pocName}
          className="w-full"
          error={pocNameState.invalid}
          helperText={pocNameState.error?.message}
          label="POC name"
          variant="outlined"
        />
        <TextField
          {...pocEmail}
          className="w-full"
          error={pocEmailState.invalid}
          helperText={pocEmailState.error?.message}
          label="POC email"
          variant="outlined"
        />
        <TextField
          {...website}
          className="w-full"
          error={websiteState.invalid}
          helperText={websiteState.error?.message}
          label="Website"
          variant="outlined"
        />
        <TextField
          {...linkedin}
          className="w-full"
          error={linkedinState.invalid}
          helperText={linkedinState.error?.message}
          label="LinkedIn"
          variant="outlined"
        />
      </Grid>
      {uploadingPhoto && (
        <UploadImage
          filename={companyId}
          type={UploadType.COMPANY}
          onClose={onUploadClose}
        />
      )}
    </Grid>
  );
};

export default CompanyProfile;
