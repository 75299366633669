type Message = {
  ignoreData?: boolean; // If true, don't send data to Mixpanel.
  message: string;
};

type MixpanelMessages = Record<
  string,
  {
    delete?: Message;
    get?: Message;
    patch?: Message;
    post?: Message;
    put?: Message;
  }
>;

export const mixpanelMessages: MixpanelMessages = {
  '/boulders': {
    post: {
      message: 'Create Boulder',
    },
  },
  '/boulders/:boulderId': {
    delete: {
      message: 'Delete Boulder',
    },
    patch: {
      message: 'Update Boulder',
    },
  },
  '/coach_profiles': {
    delete: {
      message: 'Delete Coach profile',
    },
    get: {
      message: 'Get Coach profile',
    },
    post: {
      message: 'Create Coach profile',
    },
    put: {
      message: 'Update Coach profile',
    },
  },
  '/companies/:id': {
    delete: {
      message: 'Delete company',
    },
  },
  '/documents': {
    post: {
      message: 'Create document',
    },
  },
  '/documents/:documentId': {
    delete: {
      message: 'Delete document',
    },
    patch: {
      message: 'Update document',
    },
  },
  '/documents/upload': {
    post: {
      message: 'Uploaded document',
    },
  },
  '/message_thread': {
    post: {
      message: 'Create message thread',
    },
  },
  '/message_thread/:id': {
    delete: {
      message: 'Delete message thread',
    },
  },
  '/message_thread/:id/messages': {
    get: {
      message: 'Get message thread message',
    },
    post: {
      message: 'create message thread message',
    },
  },
  '/messages/:messageId': {
    delete: {
      message: 'Delete workflow message',
    },
    put: {
      message: 'Update workflow message',
    },
  },
  '/messages/:messageId/confirm': {
    post: {
      message: 'Confirm stage summary',
    },
  },
  '/milestones/:milestoneId/refresh_step': {
    patch: {
      message: 'User refreshed suggested milestone step',
    },
  },
  '/milestones/:milestoneId/suggestions': {
    get: {
      message: 'Generate suggested milestone steps',
    },
  },
  '/notes': {
    get: {
      message: 'Get note',
    },
    post: {
      message: 'Create note',
    },
  },
  '/notes/:id': {
    delete: {
      message: 'Delete note',
    },
    put: {
      message: 'Update note',
    },
  },
  '/team_members': {
    delete: {
      message: 'Deleted team member',
    },
    get: {
      message: 'Get team members',
    },
    patch: {
      message: 'Team members updated',
    },
    post: {
      message: 'Add team members',
    },
  },
  '/teams': {
    get: {
      message: 'Get teams',
    },
    patch: {
      message: 'Team updated.',
    },
  },
  '/user_notifications': {
    patch: {
      message: 'Update user notifications',
    },
  },
};
