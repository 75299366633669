import clsx from 'clsx';

import { Icon } from '@/ui';

import type { FC } from 'react';

import Card from '@/components/Profile/Card';

const CAL_URL = import.meta.env.VITE_CAL_URL;

interface CalProps {
  hideAvailability?: boolean;
  username?: string;
}

const Cal: FC<CalProps> = ({ hideAvailability, username }) => (
  <>
    {!hideAvailability && (
      <Card icon="calendar_month" title="Coaching hours availability">
        <a
          className="button-label flex items-center gap-2 text-sage-600"
          href={`${CAL_URL}/availability`}
          rel="noreferrer"
          target="_blank"
        >
          View and edit
          <Icon>open_in_new</Icon>
        </a>
      </Card>
    )}
    <Card
      className={clsx({
        '!bg-poppy-100': !username,
      })}
      icon="public"
      title={username ? `${CAL_URL}/${username}` : 'Scheduling not setup'}
    >
      {!!username && (
        <a
          className="button-label flex items-center gap-2 text-sage-600"
          href={`${CAL_URL}/${username}`}
          rel="noreferrer"
          target="_blank"
        >
          View
          <Icon>open_in_new</Icon>
        </a>
      )}
    </Card>
  </>
);

export default Cal;
