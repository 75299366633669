import { useState } from 'react';

import { ONE, TEAMS_PER_PAGE, ZERO } from '@/constants';
import { useTeams } from '@/hooks';
import { Button, PageHeaderLayout, Pagination } from '@/ui';

import Empty from '../Empty';
import NewTeam from '../NewTeam';

import Card from './Card';
import Loading from './Loading';

import type { Team } from '@/types';
import type { ChangeEvent } from 'react';

const List = () => {
  const [page, setPage] = useState(ONE);
  const [open, setOpen] = useState(false);

  const { data = { pages: 1, teams: [] as Team[], total: 0 }, isTeamsLoading } =
    useTeams(page, TEAMS_PER_PAGE, true);

  if (isTeamsLoading) {
    return <Loading />;
  }
  if (data.teams.length === ZERO) return <Empty />;

  const handleCloseModal = () => setOpen(!open);
  const onPageChange = (_: ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return (
    <div className="w-full px-32 py-4">
      <PageHeaderLayout title="Teams">
        <Button variant="contained" onClick={handleCloseModal}>
          New Team
        </Button>
      </PageHeaderLayout>

      <div className="grid gap-4 pt-4 md:grid-cols-2 lg:grid-cols-3">
        {data.teams.map((team: Team) => (
          <Card key={`${team.id}-team-management`} id={team.id} />
        ))}
      </div>
      {data.total > TEAMS_PER_PAGE && (
        <Pagination count={data.pages} page={page} onChange={onPageChange} />
      )}

      {open && <NewTeam onClose={handleCloseModal} />}
    </div>
  );
};

export default List;
