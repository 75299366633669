import { useLocation, useNavigate } from 'react-router';

export const useNavigateUp = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const navigateUp = () => {
    // Paths considered root.
    // We won't go up these paths.
    const rootPaths = ['m', 'coach', 'teams', 'get-started'];
    const SECOND_TO_LAST = -2;
    const LENGTH_WITH_PARENT_PATH = 2;

    const paths = location.pathname.split('/').filter(Boolean);

    // Get the current location's parent path.
    const parentPath =
      paths.length > LENGTH_WITH_PARENT_PATH // If we have a multiple paths (e.g. '/m/sessions/upcoming')...
        ? paths.at(SECOND_TO_LAST) // ... get second to last (e.g. 'sessions')
        : paths[0]; // ... else, get the path itself. (e.g. 'm')

    if (!rootPaths.includes(parentPath as string)) {
      navigate('..');
    }
  };

  return navigateUp;
};
