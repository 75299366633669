import SessionsLayout from '@/components/Layouts/Sessions';
import Upcoming from '@/components/Sessions/Upcoming';

const UpcomingSessions = () => (
  <SessionsLayout>
    <Upcoming />
  </SessionsLayout>
);

export default UpcomingSessions;
