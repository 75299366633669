import { useNavigate } from 'react-router';

import { SurveyStatus } from '@/types';

import useSharedCardActions from './useSharedCardActions';

import type { GeneratorFunction } from './types';

const usePeerInsightActions = (): GeneratorFunction => {
  const { handleClick } = useSharedCardActions();
  const navigate = useNavigate();

  return ({ card }) => {
    if (card.peerInsight) {
      if (card.peerInsight.status === SurveyStatus.DRAFT) {
        return [
          {
            label: 'Continue',
            onClick: () => {
              handleClick({ card, label: 'Continue', shouldUpdate: false });
              navigate(`/m/insights/${card.peerInsight?.id}`);
            },
          },
        ];
      }
      if (card.peerInsight.status === SurveyStatus.SHARED) {
        return [
          {
            label: 'View Peer Insights',
            onClick: () => {
              handleClick({ card, label: 'View Peer Insights' });
              navigate(`/m/insights/${card.peerInsight!.surveyTemplateId}`);
            },
          },
        ];
      }
      return [
        {
          label: 'View status',
          onClick: () => {
            handleClick({ card, label: 'View Status' });
            navigate('/m/insights');
          },
        },
      ];
    }

    return [];
  };
};

export default usePeerInsightActions;
