import { useEffect } from 'react';

import { Button, Typography } from '@/ui';

import CoachingGoal from './goal';

import type { FC } from 'react';

interface CoachingGoalsProps {
  goals: string[];
  onGoalsChange: (goals: string[]) => void;
}

const DELETE_COUNT = 1;
const MAX_GOALS = 3;
const CoachingGoals: FC<CoachingGoalsProps> = ({
  goals = [''],
  onGoalsChange,
}) => {
  useEffect(() => {
    if (!goals.length) {
      onGoalsChange(['']);
    }
  }, [goals, onGoalsChange]);

  const onChange = (value: string, index: number) => {
    const clone = [...goals];
    clone[index] = value;

    onGoalsChange(clone);
  };

  const onRemove = (index: number) => {
    const clone = [...goals];
    clone.splice(index, DELETE_COUNT);

    onGoalsChange(clone);
  };

  const onAdd = () => {
    const clone = [...goals, ''];
    onGoalsChange(clone);
  };

  return (
    <div className="flex flex-col gap-2">
      {goals.map((goal, index) => (
        <CoachingGoal
          // eslint-disable-next-line react/no-array-index-key -- We're iterating an array of strings.
          key={`coaching-goal-${index}`}
          goal={goal}
          onGoalChange={(value: string) => onChange(value, index)}
          onRemove={() => onRemove(index)}
        />
      ))}
      <div className="flex justify-between">
        <Typography variant="body1">Up to 3 goals</Typography>
        <Button
          disabled={goals.length >= MAX_GOALS}
          variant="contained"
          onClick={onAdd}
        >
          Add a goal
        </Button>
      </div>
    </div>
  );
};

export default CoachingGoals;
