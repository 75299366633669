import { captureException } from '@sentry/react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { createCompany } from '@/api';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@/ui';

import type { ChangeEvent, FC } from 'react';

interface ModalProps {
  onClose: () => void;
}

const Modal: FC<ModalProps> = ({ onClose }) => {
  const navigate = useNavigate();
  const [name, setName] = useState('');

  const onNameChange = (e: ChangeEvent<HTMLInputElement>) =>
    setName(e.target.value);
  const onCreate = async () => {
    try {
      const company = await createCompany(name.trim());
      navigate(`/coach/manage/companies/${company.id}`);
    } catch (e) {
      captureException(e);
    }
  };

  return (
    <Dialog
      aria-describedby="modal-description"
      aria-labelledby="modal-title"
      open
      onClose={onClose}
    >
      <DialogTitle>New Company</DialogTitle>
      <DialogContent>
        <DialogContentText className="mb-4">
          Create a new company.
        </DialogContentText>
        <TextField
          label="Name"
          value={name}
          variant="outlined"
          onChange={onNameChange}
        />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button
          color="secondary"
          disabled={!name.trim().length}
          variant="contained"
          onClick={onCreate}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Modal;
