import { TableBody, TableEmpty, TableLoading } from '@/ui';

import Row from './Row';

import type { Assistant } from '@/types';
import type { FC } from 'react';

interface ListProps {
  assistants: Assistant[];
  headers: string[];
  isLoading: boolean;
  onDeleteClick: (data: Assistant) => void;
}

const List: FC<ListProps> = ({
  assistants = [],
  isLoading = false,
  headers = [],
  onDeleteClick,
}) => {
  if (isLoading) {
    return <TableLoading columns={headers.length} />;
  }
  if (!assistants.length) {
    return (
      <TableEmpty columns={headers.length} message="No assisants found ...." />
    );
  }

  return (
    <TableBody>
      {assistants.map((assistant) => (
        <Row
          key={`${assistant.id}-assistant-management`}
          assistant={assistant}
          onDeleteClick={onDeleteClick}
        />
      ))}
    </TableBody>
  );
};

export default List;
