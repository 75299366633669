import { useController } from 'react-hook-form';

import { Grid, TextField, Typography } from '@/ui';

import CoachingGoals from './CoachingGoals';

import type { Company, CompanyForm } from '@/types';
import type { FC } from 'react';
import type { Control } from 'react-hook-form';

interface VisionAndGoalsProps {
  company: Company;
  control: Control<CompanyForm>;
  onCompanyChange: (updated: Company) => void;
}

const VisionAndGoals: FC<VisionAndGoalsProps> = ({
  company,
  control,
  onCompanyChange,
}) => {
  const onChange = (changes: Partial<Company>) =>
    onCompanyChange({ ...company, ...changes });

  const { field: culture, fieldState: cultureState } = useController({
    control,
    name: 'culture',
  });
  const { field: vision, fieldState: visionState } = useController({
    control,
    name: 'vision',
  });
  const onGoalsChange = (goals: string[]) => onChange({ goals });

  return (
    <Grid spacing={4} container>
      <Grid md={4} xs={12} item>
        <Typography className="mb-6" variant="h4">
          About & coaching goals
        </Typography>
        <Typography className="mb-4" variant="body1">
          This information will be available for coaches on member profiles to
          help them know more about the company they are helping support.
        </Typography>
      </Grid>
      <Grid className="mt-4 flex flex-col gap-4" md={8} xs={12} item>
        <TextField
          {...vision}
          className="w-full"
          error={visionState.invalid}
          helperText={visionState.error?.message}
          label="Mission & vision"
          rows={3}
          variant="outlined"
          multiline
        />
        <TextField
          {...culture}
          className="w-full"
          error={cultureState.invalid}
          helperText={cultureState.error?.message}
          label="Additional information"
          rows={3}
          variant="outlined"
          multiline
        />
        <CoachingGoals goals={company.goals} onGoalsChange={onGoalsChange} />
      </Grid>
    </Grid>
  );
};

export default VisionAndGoals;
