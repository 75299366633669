import { track } from '@/utils';

import { useCreateClientEvent, useUpdateClientEvent } from '../events';
import { useCurrentUser } from '../useCurrentUser';

import type { HomeCardPropsWithoutCallbacks } from '@/types';

const useSharedCardActions = () => {
  const updateClientEvent = useUpdateClientEvent();
  const createClientEvent = useCreateClientEvent();
  const { user } = useCurrentUser();
  const handleClick = ({
    shouldUpdate = true,
    label = '',
    type = 'primary',
    card,
  }: {
    card: HomeCardPropsWithoutCallbacks;
    label: string;
    shouldUpdate?: boolean;
    type?: 'primary' | 'secondary';
  }) => {
    track('Clicked CTA of action card', {
      clientId: user.clientId,
      cta: type,
      ctaText: label,
      label: card.label,
      title: card.title,
    });
    if (shouldUpdate) {
      if (card.id) {
        updateClientEvent.mutate({
          id: card.id,
          key: card.type,
          viewedAt: new Date().toISOString(),
        });
      } else {
        createClientEvent.mutate({
          key: card.type,
          viewedAt: new Date().toISOString(),
        });
      }
    }
  };

  return {
    handleClick,
  };
};

export default useSharedCardActions;
