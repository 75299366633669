import { formatISO } from 'date-fns';
import { useState } from 'react';

import {
  useCurrentUser,
  useDeleteEvent,
  useGetAllEvents,
  useUpdateEvent,
} from '@/hooks';
import { ActionCard, Button, PageHeaderLayout } from '@/ui';
import { getEventCardProps } from '@/utils';

import type { Event } from '@/types';

import EditEventDialog from '@/components/Manage/EditEventDialog';

const Cards = () => {
  useCurrentUser();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [currentEvent, setCurrentEvent] = useState<Event>();

  const { data: events, refetch } = useGetAllEvents();
  const deleteEvent = useDeleteEvent();
  const updateEvent = useUpdateEvent();

  const openEditDialog = () => setIsEditOpen(true);
  const closeEditDialog = () => {
    setCurrentEvent(undefined);
    setIsEditOpen(false);
  };
  const handleEdit = (event: Event) => () => {
    setCurrentEvent(event);
    setIsEditOpen(true);
  };
  const handleDelete = (event: Event) => () => {
    deleteEvent.mutate(event.id, { onSettled: () => refetch() });
  };
  const handlePublish = (event: Event) => () => {
    updateEvent.mutate(
      { id: event.id, startedAt: formatISO(new Date()) },
      { onSettled: () => refetch() },
    );
  };
  const handleUnpublish = (event: Event) => () => {
    updateEvent.mutate(
      { id: event.id, startedAt: null },
      { onSettled: () => refetch() },
    );
  };

  return (
    <div className="container mx-auto max-w-screen-lg p-4">
      <PageHeaderLayout title="Action Cards">
        <Button variant="contained" onClick={openEditDialog}>
          Create
        </Button>
      </PageHeaderLayout>

      <div className="mt-4 flex flex-row flex-wrap justify-center gap-4 overflow-x-auto pb-4 sm:justify-start">
        {events?.map((event) => {
          const props = getEventCardProps(event);

          return (
            <ActionCard
              key={event.id}
              cardMedia={
                event.isImageInline ? (
                  <img alt="" src={event.imageUrl} />
                ) : undefined
              }
              onDelete={handleDelete(event)}
              onEdit={handleEdit(event)}
              onPublish={
                // eslint-disable-next-line react/prop-types -- Eslint is going crazy here. Not sure why it's validating proptypes.
                props.status === 'draft' ? handlePublish(event) : undefined
              }
              onUnpublish={
                // eslint-disable-next-line react/prop-types -- Eslint is going crazy here. Not sure why it's validating proptypes.
                props.status === 'published'
                  ? handleUnpublish(event)
                  : undefined
              }
              {...props}
            />
          );
        })}
      </div>

      {isEditOpen && (
        <EditEventDialog
          currentEvent={currentEvent}
          isOpen={isEditOpen}
          onClose={closeEditDialog}
        />
      )}
    </div>
  );
};

export default Cards;
