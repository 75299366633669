import clsx from 'clsx';

import { BoulderColor } from '@/types';
import { Button } from '@/ui';
import { getBoulderColor } from '@/utils';

import type { ButtonProps } from '@/ui';
import type { FC, JSX, ReactNode } from 'react';

interface ActionAddStepButtonProps {
  children: ReactNode;
  disabled?: boolean;
  milestoneId: string;
  onClick: () => void;
}

const ActionButton: FC<ActionAddStepButtonProps & ButtonProps> = ({
  milestoneId,
  disabled = false,
  onClick,
  children,
  ...rest
}) => {
  const color = getBoulderColor(milestoneId);

  let buttonColor: 'inherit' | 'primary' | 'secondary' | 'info';
  let overrideBgColor;
  switch (color) {
    case BoulderColor.SAGE:
      buttonColor = 'primary';
      break;
    case BoulderColor.SUNNY:
      buttonColor = 'secondary';
      break;
    case BoulderColor.BLOOM:
      buttonColor = 'info';
      break;
    default:
      buttonColor = 'inherit';
      overrideBgColor = `cursor-pointer min-w-0 bg-${color}-500 hover:bg-${color}-600 text-white`;
      break;
  }

  return (
    <Button
      className={clsx(overrideBgColor, {
        '!bg-cement-200 !text-cement-300 cursor-default':
          buttonColor === 'inherit' && disabled,
        'px-3': (children as JSX.Element).type?.name === 'Icon', // If Icon we reduce padding to make it more square
      })}
      color={buttonColor}
      disabled={disabled}
      variant="contained"
      onClick={onClick}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default ActionButton;
