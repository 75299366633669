import { styled } from '@mui/material/styles';
import { clsx } from 'clsx';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { ONE_HUNDRED } from '@/constants';
import { useGrowthPlan } from '@/hooks';
import { Card, Icon, LinearProgress, Link, Typography } from '@/ui';

import type { FC } from 'react';

const Progress = styled(LinearProgress)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  height: 8,
}));

const StepsOverview: FC = () => {
  const { plan } = useGrowthPlan();
  const { pathname } = useLocation();
  const isEmpty = !plan.totalSteps;

  return (
    <Card
      className={clsx('box-border w-92 p-5', {
        'text-cement-200': isEmpty,
      })}
      variant="outlined"
    >
      <div className="block align-middle sm:mb-6 sm:flex">
        <Icon>hiking</Icon>
        <Typography className="hidden sm:block" variant="subtitle1">
          {isEmpty ? (
            'Completed Steps'
          ) : (
            <Link
              className="flex items-center text-cement-500 no-underline hover:underline"
              component={RouterLink}
              to={`${pathname}/completedSteps`}
            >
              {plan.completeSteps} of {plan.totalSteps} completed
              <Icon fontSize="small">chevron_right</Icon>
            </Link>
          )}
        </Typography>
        <Typography className="block sm:hidden" variant="caption">
          {plan.completeSteps} of {plan.totalSteps} Steps completed
        </Typography>
      </div>
      <Progress
        className="hidden sm:block"
        color={!plan.totalSteps ? 'gray' : 'primary'}
        value={
          !plan.totalSteps
            ? plan.totalSteps
            : (plan.completeSteps / plan.totalSteps) * ONE_HUNDRED
        }
        variant="determinate"
      />
    </Card>
  );
};

export default StepsOverview;
