import { captureException } from '@sentry/react';
import { useContext } from 'react';
import { useController, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';

import {
  createAssistantFunction,
  deleteFunctionDisplay,
  updateAssistantFunction,
} from '@/api';
import { AppContext } from '@/context';
import { useGetAssistant } from '@/hooks';
import { AppMessageSeverities, type Function as FunctionType } from '@/types';
import {
  Button,
  Icon,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  Skeleton,
  TextField,
  Typography,
} from '@/ui';

import { useGetFunction } from '@/hooks/useGetFunction';

const Function = () => {
  const { functionId = '', assistantId = '' } = useParams();
  const { refetch } = useGetAssistant(assistantId);
  const {
    data = {} as FunctionType,
    isLoading,
    refetch: refetchFn,
  } = useGetFunction(functionId);
  const { setAppMessage } = useContext(AppContext);
  const navigate = useNavigate();
  const onBack = () => navigate(`/coach/manage/assistants/${assistantId}`);

  const {
    control,
    formState: { isValid, isSubmitting },
    handleSubmit,
  } = useForm({
    defaultValues: {
      description: data.description || '',
      functionName: data.name || '',
      parameters: JSON.stringify(data.parameters) || '{}',
    },
    values: {
      ...data,
      functionName: data.name,
      parameters: JSON.stringify(data.parameters),
    },
  });

  const { field: functionName, fieldState: functionNameState } = useController({
    control,
    name: 'functionName',
    rules: { required: true },
  });
  const { field: description, fieldState: descriptionState } = useController({
    control,
    name: 'description',
    rules: { required: true },
  });

  const { field: parameters, fieldState: parametersState } = useController({
    control,
    name: 'parameters',
    rules: { required: true },
  });
  const onSave = () => {
    handleSubmit(async (formData) => {
      try {
        if (functionId === '') {
          await createAssistantFunction({
            ...formData,
            assistantId,
            name: formData.functionName,
          });
        } else {
          await updateAssistantFunction({
            ...formData,
            assistantId,
            id: functionId,
            name: formData.functionName,
          });
          refetchFn();
        }
        refetch();
        setAppMessage({
          message: 'Changes saved',
          open: true,
          severity: AppMessageSeverities.Success,
        });
      } catch (e) {
        captureException(e);
        setAppMessage({
          message: 'Error saving assistant',
          open: true,
          severity: AppMessageSeverities.Error,
        });
      }
    })();
  };

  const onDisplayDeleteClick = (id: string) => async () => {
    try {
      await deleteFunctionDisplay(id);
      refetchFn();
    } catch (e) {
      captureException(e);
      setAppMessage({
        message: 'Error deleting function',
        open: true,
        severity: AppMessageSeverities.Error,
      });
    }
  };

  const onDisplayClick = (id: string) => () => {
    navigate(
      `/coach/manage/assistants/${assistantId}/function/${functionId}/display/${id}`,
    );
  };

  const onAddFunctionDisplayClick = () =>
    navigate(
      `/coach/manage/assistants/${assistantId}/function/${functionId}/display`,
    );
  if (!!functionId && isLoading) {
    return (
      <div className="max-w-screen-sm p-6">
        <div className="flex flex-col gap-4">
          <Typography variant="h4">Function</Typography>
          <Skeleton height={50} />
          <Skeleton height={50} />
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-screen-sm p-6">
      <div className="flex flex-col gap-4">
        <Typography variant="h4">Function</Typography>

        <TextField
          error={functionNameState.invalid}
          helperText="Required"
          label="Name"
          variant="outlined"
          {...functionName}
        />
        <TextField
          error={descriptionState.invalid}
          helperText="Required"
          label="Description"
          maxRows={2}
          variant="outlined"
          multiline
          {...description}
        />

        <TextField
          error={parametersState.invalid}
          helperText="JSON object Required"
          label="Parameters"
          maxRows={10}
          minRows={4}
          variant="outlined"
          multiline
          {...parameters}
        />

        {data.id && (
          <>
            <Typography variant="subtitle1">Function Display</Typography>
            {data.functionDisplay ? (
              <ListItem
                secondaryAction={
                  <IconButton
                    onClick={onDisplayDeleteClick(data.functionDisplay.id)}
                  >
                    <Icon>trash</Icon>
                  </IconButton>
                }
                disableGutters
              >
                <ListItemButton
                  onClick={onDisplayClick(data.functionDisplay.id)}
                >
                  <ListItemText>
                    Color: {data.functionDisplay.color} Icon:{' '}
                    {data.functionDisplay.icon}
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            ) : (
              <div>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={onAddFunctionDisplayClick}
                >
                  Add function display
                </Button>
              </div>
            )}
          </>
        )}

        <div className="flex gap-2">
          <Button
            className="self-baseline"
            color="primary"
            variant="outlined"
            onClick={onBack}
          >
            Back
          </Button>
          <Button
            className="self-baseline"
            color="primary"
            disabled={!isValid}
            loading={isSubmitting}
            variant="contained"
            onClick={onSave}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};
export default Function;
