import { useQuery } from '@tanstack/react-query';

import { getSurveyTemplates } from '@/api';
import { useCurrentUser, useSurveys, useView } from '@/hooks';

import Card from './Card';

import type { Survey, SurveyTemplate } from '@/types';

const TemplateCards = () => {
  const { user } = useCurrentUser();
  const { selectedUser, isOverview } = useView();

  const client = isOverview ? selectedUser : user;

  const { data: templates } = useQuery(
    ['surveyTemplates', client.sub],
    async (): Promise<SurveyTemplate[]> =>
      getSurveyTemplates(isOverview ? client.sub : undefined),
    { enabled: !!client.id, refetchOnMount: 'always' },
  );

  const { data: surveys } = useSurveys();

  if (!client.id || !templates || !surveys) return null;

  const coachName = client.coaches[0]?.fullName ?? '';
  const surveyLookup = surveys.reduce((acc: Record<string, Survey>, survey) => {
    acc[survey.surveyTemplateId] = survey;
    return acc;
  }, {});

  return (
    <div className="flex flex-col space-y-4">
      {templates.map((template) => (
        <Card
          key={`survey-template-card-${template.id}`}
          coach={coachName}
          survey={surveyLookup[template.id]}
          {...template}
        />
      ))}
    </div>
  );
};

export default TemplateCards;
