import { Button, DialogActions } from '@/ui';

import type { FC } from 'react';

interface FooterProps {
  noFiles: boolean;
  onClick: () => void;
  onClose: () => void;
  processing: boolean;
}

const Footer: FC<FooterProps> = ({ noFiles, onClick, onClose, processing }) => (
  <DialogActions>
    <Button disabled={processing} variant="outlined" onClick={onClose}>
      Cancel
    </Button>
    <Button
      className="ml-3"
      disabled={processing || noFiles}
      variant="contained"
      onClick={onClick}
    >
      Upload
    </Button>
  </DialogActions>
);

export default Footer;
