import { styled } from '@mui/material';
import { clsx } from 'clsx';

import Avatar from '../Avatar';
import Typography from '../Typography';

import mentoAvatar from './assets/mento-avatar.png';

import type { FC } from 'react';

interface ChatBubbleProps {
  avatarUrl?: string | null;
  className?: string;
  firstName?: string;
  lastName?: string;
  message: string;
}

const Wrapper = styled('div')(() => ({
  alignItems: 'end',
  display: 'flex',
  marginTop: 'auto',
}));

/* eslint-disable @typescript-eslint/no-magic-numbers -- CSS values make little sense as constants. */
const Message = styled(Typography)(({ theme }) => ({
  background: theme.palette.primary.tonal,
  borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0`,
  color: theme.palette.text.primary,
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  padding: theme.spacing(1),
}));
/* eslint-enable @typescript-eslint/no-magic-numbers */

const ChatBubble: FC<ChatBubbleProps> = ({
  className,
  firstName,
  lastName,
  message,
  avatarUrl,
  ...rest
}) => {
  const classes = clsx('mnt-chat-bubble', className);

  return (
    <Wrapper className={classes} {...rest}>
      <Avatar
        firstName={firstName}
        lastName={lastName}
        size="s"
        src={avatarUrl || mentoAvatar}
      />
      <Message variant="caption">{message}</Message>
    </Wrapper>
  );
};
ChatBubble.displayName = 'ChatBubble';

export default ChatBubble;
export type { ChatBubbleProps };
