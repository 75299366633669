import { format } from 'date-fns';

import { CardType } from '@/types';
import { ChatBubble, Typography } from '@/ui';

import ChemistryCallContent from './ChemistryCallCard/ChemistryCallContent';

import type { HomeCardPropsWithoutCallbacks, User } from '@/types';
import type { ChatBubbleProps } from '@/ui';
import type { FC } from 'react';

import GrowthPlanCard from '@/components/Home/Cards/GrowthPlanCard/GrowthPlanCard';

interface HomeCardContentProps {
  card: HomeCardPropsWithoutCallbacks;
}

const HomeCardContent: FC<HomeCardContentProps> = ({ card }) => {
  const startedAt = new Date(card.session?.startedAt as string);
  const endedAt = new Date(card.session?.endedAt as string);

  switch (card.type) {
    case CardType.GROWTH_PLAN:
      return <GrowthPlanCard />;
    case CardType.NEXT_SESSION:
      return (
        <>
          <Typography className="font-bold" variant="body2">
            {card.session?.title}
          </Typography>
          {!card.isSessionToday && (
            <div className="mb-auto">
              <Typography variant="body2">
                {(card.coach as User).fullName}
              </Typography>
              <Typography variant="body2">
                {format(startedAt, 'EEEE, MMMM do')}
              </Typography>
              <Typography variant="body2">
                {format(startedAt, 'h:mm')}-{format(endedAt, 'h:mmaaa')}
              </Typography>
            </div>
          )}

          <ChatBubble
            avatarUrl={(card.coach as User).avatarUrl}
            className="mt-auto"
            firstName={card.coach?.firstName || (card.coach as User).fullName}
            message="Any notes for next session?"
          />
          <ChemistryCallContent card={card} />
        </>
      );
    default:
      if (card.children && (card.children as ChatBubbleProps).message) {
        return <ChatBubble {...(card.children as ChatBubbleProps)} />;
      }

      if (typeof card.children === 'string') {
        return <Typography variant="body2">{card.children}</Typography>;
      }

      return null;
  }
};

export default HomeCardContent;
