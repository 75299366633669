import { useNavigate } from 'react-router-dom';

import { Avatar, Button } from '@/ui';

import type { User } from '@/types';
import type { FC } from 'react';

import MemberSession from '@/components/Profile/Coach/Edit/Admin/Members/memberSession';

interface MemberProps {
  coachId: string;
  member: User;
  setPending: (member: User) => void;
}

const Member: FC<MemberProps> = ({ coachId, member, setPending }) => {
  const navigate = useNavigate();
  const onClick = () => navigate(`/coach/members/${member.id}`);
  const onRemoveClick = () => setPending(member);

  return (
    <div className="flex justify-between">
      <div className="flex items-center gap-4">
        <Avatar
          className="cursor-pointer"
          firstName={member.firstName}
          lastName={member.lastName}
          size="l"
          src={member.avatarUrl ?? ''}
          onClick={onClick}
        />
        <div className="flex flex-col">
          <span className="title">{member.fullName}</span>
          {!member.isActive && (
            <span className="title-sm text-poppy-600">(inactive)</span>
          )}
          <MemberSession coachId={coachId} member={member} />
        </div>
      </div>
      <div className="flex items-center">
        <Button
          color="error"
          size="small"
          variant="contained"
          onClick={onRemoveClick}
        >
          Remove
        </Button>
      </div>
    </div>
  );
};

export default Member;
