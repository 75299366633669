import { Icon, Link } from '@/ui';

import type { User } from '@/types';
import type { FC } from 'react';

interface Props {
  coach: User;
}
const CoachCalInfo: FC<Props> = ({ coach }) => {
  const calUsername = coach.coachProfile?.calendarUrl ?? '';

  return (
    <div className="flex justify-end gap-4">
      {!!calUsername && (
        <Link
          className="button-label flex items-center justify-center gap-1 text-sage-600 hover:text-sage-500"
          href={`${import.meta.env.VITE_CAL_URL}/${calUsername}`}
          rel="noreferrer"
          target="_blank"
        >
          <Icon>open_in_new</Icon>
          View public page
        </Link>
      )}
      <Link
        className="button-label flex items-center justify-center gap-1 text-sage-600 hover:text-sage-500"
        href={`${import.meta.env.VITE_CAL_URL}/bookings/upcoming`}
        rel="noreferrer"
        target="_blank"
      >
        <Icon>edit_calendar</Icon>
        Manage all bookings
      </Link>
    </div>
  );
};

export default CoachCalInfo;
