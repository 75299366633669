import { useQueryClient } from '@tanstack/react-query';
import { type FC, useContext, useState } from 'react';
import { useParams } from 'react-router';

import {
  QUERY_KEY_GROWTH_PLANS,
  QUERY_KEY_MILESTONE_STEPS,
  QUERY_KEY_MILESTONES,
} from '@/constants';
import { AppContext } from '@/context';
import {
  useCreateMilestoneStep,
  useCurrentUser,
  useGrowthPlan,
  useGrowthPlanSteps,
  useRefreshSuggestedMilestoneStep,
} from '@/hooks';
import { AppMessageSeverities } from '@/types';
import {
  Button,
  CircularProgress,
  Icon,
  IconButton,
  Skeleton,
  Typography,
} from '@/ui';
import { noop } from '@/utils';

interface SuggestionProps {
  color: string;
  hasSuggestionRefreshing?: boolean;
  index: number;
  loading?: boolean;
  milestoneId: string;
  setHasSuggestionRefreshing?: (value: boolean) => void;
  suggestion: string;
}

const Suggestion: FC<SuggestionProps> = ({
  color,
  index,
  loading,
  milestoneId,
  suggestion,
  hasSuggestionRefreshing,
  setHasSuggestionRefreshing = noop,
}) => {
  const [suggestionText, setSuggestionText] = useState(suggestion);
  const [failedSuggestion, setFailedSuggestion] = useState(false);
  const { setAppMessage } = useContext(AppContext);
  const createMilestoneStep = useCreateMilestoneStep();
  const refreshSuggestedMilestoneStep = useRefreshSuggestedMilestoneStep();
  const { teamId = '', id: userId } = useParams();
  const { user } = useCurrentUser();

  const { plan, refetch } = useGrowthPlan();
  const { refetch: refetchSteps } = useGrowthPlanSteps();
  const queryClient = useQueryClient();
  const selectedUser = userId ?? user.id;

  const refreshSuggestion = () =>
    refreshSuggestedMilestoneStep.mutate(
      { index, milestoneId },
      {
        onError: () => {
          setAppMessage({
            message: 'Sorry, the suggestion could not be generated',
            open: true,
            severity: AppMessageSeverities.Error,
          });
          setFailedSuggestion(true);
          setHasSuggestionRefreshing(false);
        },
        onSuccess: (response) => {
          setSuggestionText(response[0] ?? '');
          setHasSuggestionRefreshing(false);
        },
      },
    );

  const onSuccess = () => {
    refetch();
    refetchSteps();
    queryClient.invalidateQueries([
      QUERY_KEY_GROWTH_PLANS,
      { teamId, userId: selectedUser },
    ]);
    queryClient.invalidateQueries([QUERY_KEY_MILESTONES, milestoneId]);
    queryClient.invalidateQueries([QUERY_KEY_MILESTONE_STEPS]);
    refreshSuggestion();
  };

  const handleAddClick = () => {
    setHasSuggestionRefreshing(true);
    createMilestoneStep.mutate(
      {
        growthPlanId: plan.id,
        milestoneId,
        suggested: true,
        title: suggestionText,
      },
      {
        onSuccess,
      },
    );
  };

  const handleRefreshClick = () => {
    setFailedSuggestion(false);
    setHasSuggestionRefreshing(true);
    refreshSuggestion();
  };

  const textColor =
    color === 'sunny' ? `text-${color}-600` : `text-${color}-500`;
  const bgColor = `bg-${color}-300`;
  const borderColor = `border-${color}-300`;

  if (failedSuggestion) {
    return (
      <div
        className={`flex w-full items-center border-0 border-b border-solid ${borderColor} gap-2 pb-2 pl-1 last:border-b-0 last:pb-0`}
      >
        <Typography className="ml-2 flex-1" variant="body1">
          Sorry, the suggestion could not be generated. Please try again.
        </Typography>
        <IconButton
          disabled={hasSuggestionRefreshing}
          onClick={handleRefreshClick}
        >
          <Icon>refresh</Icon>
        </IconButton>
      </div>
    );
  }

  if (loading || refreshSuggestedMilestoneStep.isLoading)
    return (
      <div
        className={`flex w-full items-center border-0 border-b border-solid ${borderColor} gap-2 pb-2 pl-1 last:border-b-0 last:pb-0`}
      >
        <CircularProgress size={24} />
        <Skeleton className="ml-2" height={42} width="40%" />
      </div>
    );

  return (
    <div
      className={`flex w-full items-center border-0 border-b border-solid ${borderColor} gap-2 pb-2 pl-1 last:border-b-0 last:pb-0`}
    >
      <Icon className={`rounded ${bgColor} ${textColor} h-4.5 w-4.5`}>
        magic_button
      </Icon>
      <Typography className="ml-2 flex-1" variant="body1">
        {suggestionText}
      </Typography>
      <IconButton
        disabled={hasSuggestionRefreshing}
        size="small"
        onClick={handleRefreshClick}
      >
        <Icon>refresh</Icon>
      </IconButton>
      <Button
        disabled={hasSuggestionRefreshing}
        size="small"
        variant="outlined"
        onClick={handleAddClick}
      >
        Add
      </Button>
    </div>
  );
};
export default Suggestion;
