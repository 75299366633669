import clsx from 'clsx';

import { useCurrentUser, useView } from '@/hooks';

const Toolkit = () => {
  const { isClient } = useView();
  const { user, isUserLoading } = useCurrentUser();

  if (isUserLoading || !user.id) {
    return null;
  }

  return (
    <div
      className={clsx('flex flex-1 flex-col', {
        'bg-sage-100': !isClient,
        'bg-sunny-100': isClient,
      })}
    >
      <iframe
        className="flex-1 border-0"
        sandbox="allow-same-origin"
        src="https://coachresources.super.site/"
        title="Toolkit"
      />
    </div>
  );
};

export default Toolkit;
