import { isAxiosError } from 'axios';

import {
  HTTP_BAD_REQUEST,
  HTTP_INTERNAL_SERVER_ERROR,
  HTTP_NOT_FOUND,
} from '@/constants';

export const retryHandler = (retryCount: number, error: unknown) => {
  const MAX_RETRIES = 3;

  // Don't retry more than MAX_RETRIES
  if (retryCount > MAX_RETRIES) {
    return false;
  }

  let shouldRetry = false;

  // Retry if error is within 400 range
  if (isAxiosError(error)) {
    const status = error.response?.status as number;

    // Retry if Network error or if error is a 4xx,
    // except for HTTP_NOT_FOUND.
    shouldRetry =
      error.message === 'Network Error' ||
      (status <= HTTP_BAD_REQUEST &&
        status > HTTP_INTERNAL_SERVER_ERROR &&
        status !== HTTP_NOT_FOUND);
  }

  return shouldRetry;
};
