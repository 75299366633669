import { useQuery } from '@tanstack/react-query';

import { getDocuments } from '@/api';
import { QUERY_KEY_TEMPLATES } from '@/constants';

import type { DocumentsApiOptions } from '@/api';
import type { Document } from '@/types';
import type { UseQueryOptions } from '@tanstack/react-query';

export const useGetTemplates = (
  params: DocumentsApiOptions,
  options?: UseQueryOptions,
) =>
  useQuery<{ items: Document[]; pages: number; total: number }, Error>({
    enabled: options ? options.enabled : true,
    placeholderData: { items: [], pages: 0, total: 0 },
    queryFn: () => getDocuments({ ...params, template: true }),
    queryKey: [QUERY_KEY_TEMPLATES, params],
  });
