// These come from mui, I don't know if MUI exports them somehow
// if it does, we should use that instead
export enum DialogReasons {
  BACKDROP = 'backdropClick',
  ESCAPE = 'escapeKeyDown',
}

export enum PaymentStatus {
  ACTIVE = 'active',
  CANCELED = 'canceled',
  INCOMPLETE = 'incomplete',
  INCOMPLETE_EXPIRED = 'incomplete_expired',
  PAST_DUE = 'past_due',
  PAUSED = 'paused',
  TRAILING = 'trialing',
  UNPAID = 'unpaid',
}

export enum DragDirections {
  DOWN = 'DOWN',
  UP = 'UP',
}

export enum DragTypes {
  STEP = 'STEP',
}

export type SortDirection = 'desc' | 'asc';

export interface IDictionary<T = string> {
  [index: string]: T;
}

export interface IDictionaryOfDictionary<T = string> {
  [index: string]: IDictionary<T>;
}

export interface Avatar {
  firstName: string;
  lastName: string;
  src: string;
}

export interface PaginatedResponse<T> {
  current: number;
  hasNextPage: boolean;
  items: T[];
  next: number;
  pages: number;
  previous: number;
  total: number;
}

export interface MatchRouterParams extends Object {
  email?: string;
  token?: string;
}

export enum AppMessageSeverities {
  Error = 'error',
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
}

export interface AppMessage {
  message: string;
  open: boolean;
  severity: AppMessageSeverities;
}

export enum Env {
  Local = 'local',
  Production = 'production',
  Staging = 'stage',
}
