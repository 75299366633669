import { JOB_TITLES } from '@/constants';
import { Button, CreatableSelect, Icon, TextField } from '@/ui';

import type { UserEmployment } from '@/types';
import type { ChangeEvent, FC } from 'react';

interface PastCompaniesProps {
  company: UserEmployment;
  onChange: (updated: UserEmployment) => void;
  onRemove: () => void;
}

const PastCompany: FC<PastCompaniesProps> = ({
  company,
  onChange,
  onRemove,
}) => {
  const onUpdate = (changes: Partial<UserEmployment>) =>
    onChange({ ...company, ...changes });

  const onTitleChange = (value: string) => {
    onUpdate({ title: value });
  };
  const onNameChange = (e: ChangeEvent<HTMLInputElement>) =>
    onUpdate({ companyName: e.target.value });

  return (
    <div className="flex flex-wrap gap-3">
      <div className="w-full">
        <CreatableSelect
          label="Title"
          options={JOB_TITLES}
          placeholder="Title"
          value={company.title}
          onChange={onTitleChange}
        />
      </div>
      <div className="flex w-full justify-between gap-2">
        <TextField
          className="w-full"
          label="Company"
          value={company.companyName}
          onChange={onNameChange}
        />
        <Button color="error" variant="contained" onClick={onRemove}>
          <Icon>trash</Icon>
        </Button>
      </div>
    </div>
  );
};

export default PastCompany;
