import { captureException } from '@sentry/react';
import { type FC, useContext, useState } from 'react';
import { useController, useForm } from 'react-hook-form';

import { createAssistant } from '@/api';
import { AppContext } from '@/context';
import { AppMessageSeverities, AssistantModels } from '@/types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@/ui';

import type { Assistant } from '@/types';

export interface ModalProps {
  assistant: Assistant;
  onClose: () => void;
  refetch: () => void;
}

const Modal: FC<ModalProps> = ({ onClose, refetch, assistant }) => {
  const [loading, setLoading] = useState(false);
  const { setAppMessage } = useContext(AppContext);
  const {
    control,
    formState: { isValid },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: {
      assistantName: assistant.name || '',
      model: assistant.model || '',
      prompt: assistant.prompt || '',
    },
    mode: 'onBlur',
    values: { ...assistant, assistantName: assistant.name },
  });

  const { field: assistantName, fieldState: assistantNameState } =
    useController({
      control,
      name: 'assistantName',
      rules: { required: true },
    });
  const { field: model, fieldState: modelState } = useController({
    control,
    name: 'model',
    rules: { required: true },
  });
  const { field: prompt, fieldState: promptState } = useController({
    control,
    name: 'prompt',
    rules: { required: true },
  });

  const onSave = () => {
    handleSubmit(async (data) => {
      setLoading(true);
      try {
        await createAssistant({ ...data, name: data.assistantName });
        reset();
        refetch();
        onClose();
      } catch (e) {
        captureException(e);
        setAppMessage({
          message: 'Error saving assistant',
          open: true,
          severity: AppMessageSeverities.Error,
        });
      } finally {
        setLoading(false);
      }
    })();
  };

  return (
    <Dialog
      aria-describedby="modal-description"
      aria-labelledby="modal-title"
      maxWidth="md"
      fullWidth
      open
      onClose={onClose}
    >
      <DialogContent>
        <Grid spacing={4} container>
          <Grid md={5} xs={12} item>
            <Typography variant="h4">Create Assistant</Typography>
          </Grid>
          <Grid className="flex flex-col gap-4" md={7} xs={12} item>
            <TextField
              error={assistantNameState.invalid}
              helperText="Required"
              label="Name"
              variant="outlined"
              {...assistantName}
            />
            <TextField
              error={promptState.invalid}
              helperText="Required"
              label="Prompt"
              maxRows={5}
              variant="outlined"
              multiline
              {...prompt}
            />
            <TextField
              error={modelState.invalid}
              helperText="Required"
              label="Model"
              variant="outlined"
              select
              {...model}
            >
              {Object.keys(AssistantModels).map((mod: string) => (
                <MenuItem key={mod} value={mod}>
                  {mod}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          disabled={loading}
          variant="outlined"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={!isValid}
          loading={loading}
          variant="contained"
          onClick={onSave}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default Modal;
