import { useCurrentUser, useView } from '@/hooks';
import { noop } from '@/utils';

import TimezoneModal from './Associated/Timezone';
import EmailUpdated from './EmailUpdated';
import NotAssociated from './NotAssociated';
import ClassicTimezoneModal from './NotAssociated/Steps/Timezone';

const OnboardingFlow = () => {
  const { user } = useCurrentUser();
  const { isClient } = useView();
  const userHasTimezoneSet = !!user.profile?.timezone;
  const userHasDocumentsEmail = !!user.documentsEmail;
  const userHasEmailChanged = user.emailUpdated;

  const showTimezoneModal =
    !userHasTimezoneSet &&
    isClient &&
    userHasDocumentsEmail &&
    !userHasEmailChanged;
  const showNoGoogleAccOnboardingFlow = !userHasDocumentsEmail && isClient;
  const showEmailUpdatedFlow =
    userHasEmailChanged && userHasDocumentsEmail && isClient;

  // Show classic timezone for coaches, they only see that modal.
  if (!userHasTimezoneSet && !isClient)
    return <ClassicTimezoneModal setStep={noop} hideTitle />;

  // User is new, but has a gmail account, we only ask for timezone
  if (showTimezoneModal) return <TimezoneModal />;

  // User might be new, but doesn't have a gmail account, we ask for tz and gmail acc
  if (showNoGoogleAccOnboardingFlow) return <NotAssociated />;

  // User is NOT new, but changed its email.
  if (showEmailUpdatedFlow) return <EmailUpdated />;

  return null;
};

export default OnboardingFlow;
