import { styled } from '@mui/material';
import MuiTableBody from '@mui/material/TableBody';
import { clsx } from 'clsx';

import type { TableBodyProps } from '@mui/material/TableBody';
import type { FC } from 'react';

const StyledTableBody = styled(MuiTableBody)<TableBodyProps>(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

const TableBody: FC<TableBodyProps> = ({ className, ...rest }) => {
  const classes = clsx('mnt-table-body', className);

  return <StyledTableBody {...rest} className={classes} />;
};
TableBody.displayName = 'TableBody';

export default TableBody;
export type { TableBodyProps };
