import type { Note } from './note';
import type { User } from './user';

export enum SessionType {
  Upcoming,
  Past,
}

export enum SessionListType {
  Next = 'Next',
  Past = 'Past',
  Upcoming = 'Upcoming',
}

export enum SessionStatus {
  ACTIVE = 'active',
  CANCELLED = 'cancelled',
  DONE = 'done',
  RESCHEDULED = 'rescheduled',
}

export enum SessionFeedbackType {
  MIXED = 'mixed',
  NEGATIVE = 'negative',
  POSITIVE = 'positive',
}

export enum SessionWarningAction {
  CANCEL = 'CANCEL',
  RESCHEDULE = 'RESCHEDULE',
}

export interface SessionFeedbackParams {
  background: string;
  border: string;
  chemistryCallSubtitle?: string;
  img: string;
  name: string;
  statsGraphColor: string;
  subtitle: string;
  title: string;
  type: SessionFeedbackType;
}

export interface Session {
  bookId: string;
  coach: User;
  coachId: string;
  createdAt: string;
  endedAt: string;
  feedback?: SessionFeedback;
  id: string;
  isChemistryCall: boolean;
  location?: string;
  notes?: Note[];
  recurringId: string;
  slug: string;
  startedAt: string;
  status: string;
  title: string;
  updatedAt?: Date;
  user: User;
  userId: string;
}

export interface SessionFeedback {
  category: SessionFeedbackType;
  content: string;
  createdAt: Date;
  id: string;
  sessionId: string;
  updatedAt: Date;
  userId: string;
}

export interface SessionDashboard {
  noSessions: string[];
  noUpcoming: string[];
  upcoming: Record<string, Session>;
}

export interface SessionCount {
  done: number;
  next?: string;
  pending: number;
}
