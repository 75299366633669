import { useQueryClient } from '@tanstack/react-query';
import { useController, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';

import {
  DocumentDisplay,
  QUERY_KEY_DOCUMENTS,
  QUERY_KEY_TEMPLATES,
} from '@/constants';
import { useCreateDocument, useCurrentUser, useView } from '@/hooks';
import { DocMimeType } from '@/types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  Typography,
} from '@/ui';
import { toDocumentUrl, track } from '@/utils';

import type { Document } from '@/types';
import type { FC } from 'react';

import ShareInput from '@/components/Document/Share/Input';

interface CreateDialogProps {
  close: () => void;
  draft?: boolean;
  isOpen: boolean;
  isTemplate: boolean;
  mimeType?: DocMimeType;
  name?: string;
  reference?: string;
}

const CreateDialog: FC<CreateDialogProps> = ({
  close,
  isOpen,
  mimeType = DocMimeType.DOCUMENT,
  isTemplate,
  reference,
  name,
  draft = false,
}) => {
  const queryClient = useQueryClient();
  const {
    label = '',
    icon = '',
    shortLabel = '',
  } = DocumentDisplay[mimeType] ?? {};
  const createDocument = useCreateDocument();
  const navigate = useNavigate();
  const { user } = useCurrentUser();
  const { selectedUser } = useView();

  // We need to avoid the name `name` for fields within forms since hubspot will rename things on its end
  const {
    control,
    formState: { isValid },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: {
      documentName: name ?? '',
      sharedWith:
        selectedUser.id && selectedUser.id !== user.id
          ? [selectedUser.email]
          : [],
    },
  });
  const { field: nameField, fieldState: titleState } = useController({
    control,
    name: 'documentName',
    rules: { required: true },
  });
  const { field: sharedWith } = useController({
    control,
    name: 'sharedWith',
  });

  const handleClose = () => {
    reset();
    close();
  };

  const onSubmit = handleSubmit((data) => {
    const onSuccess = (document?: Document | null) => {
      queryClient.invalidateQueries(['current-user-client']);
      queryClient.invalidateQueries(['current-user-coach']);
      queryClient.invalidateQueries(['selectedUser']);

      if (!document) {
        return;
      }

      track(`Created ${mimeType}${isTemplate ? ' template' : ''}`);
      if (isTemplate) {
        queryClient.invalidateQueries([QUERY_KEY_TEMPLATES]);
        navigate(`/documents/templates/${document.id}`);
        return;
      }
      queryClient.invalidateQueries([QUERY_KEY_DOCUMENTS]);
      const url = toDocumentUrl(mimeType, document.reference);
      // Open right in this tab because opening in a new tab after waiting
      // on the form submit causes issues with browser popup blockers and
      // sometimes makes the google doc be view only
      window.location.href = url;
      handleClose();
    };

    if (isTemplate) {
      createDocument.mutate(
        {
          mimeType,
          name: data.documentName,
          sharedWith: data.sharedWith,
          template: true,
        },
        { onSuccess },
      );
    } else {
      createDocument.mutate(
        {
          mimeType,
          name: data.documentName,
          reference,
          sharedWith: data.sharedWith,
        },
        { onSuccess },
      );
    }
  });
  const title = reference
    ? `Start ${label}`
    : `Create a ${label} ${isTemplate ? 'Template' : ''}`;

  return (
    <Dialog maxWidth="md" open={isOpen} fullWidth onClose={handleClose}>
      <DialogContent>
        <Grid container>
          <Grid md={6} xs={12} item>
            <Typography className="mb-8" variant="h4">
              {title}
            </Typography>
            <Typography
              className="mr-16 mt-12 hidden md:block"
              color="text.disabled"
              variant="body2"
            >
              Note: pop-ups from Mento must be enabled in order to create and
              edit documents
            </Typography>
          </Grid>
          <Grid md={6} xs={12} item>
            <form id="create-document-form" onSubmit={onSubmit}>
              <TextField
                className="mb-4 w-full"
                error={titleState.invalid}
                label="Document name"
                {...nameField}
              />
              {!draft && (
                <ShareInput
                  className="mb-4 w-full"
                  sharedWith={
                    selectedUser.id && selectedUser.id !== user.id
                      ? [{ userId: selectedUser.id }]
                      : []
                  }
                  {...sharedWith}
                />
              )}
            </form>
          </Grid>
        </Grid>
        <Typography
          className="mt-4 md:hidden"
          color="text.disabled"
          variant="body2"
        >
          Note: pop-ups from Mento must be enabled in order to create and edit
          documents
        </Typography>
      </DialogContent>
      <DialogActions className="flex flex-col-reverse items-stretch sm:flex-row sm:items-end">
        <Button
          disabled={createDocument.isLoading}
          variant="outlined"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          className="mb-4 ml-0 sm:mb-0 sm:ml-2"
          color="primary"
          disabled={!isValid}
          form="create-document-form"
          loading={createDocument.isLoading}
          type="submit"
          variant="contained"
          light
        >
          <img
            alt=""
            src={`https://mento-space.nyc3.cdn.digitaloceanspaces.com/icons/${icon}.svg`}
            width={24}
          />
          &nbsp;Open in {shortLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateDialog;
