import { captureException } from '@sentry/react';
import { useEffect } from 'react';
import { matchPath, useNavigate } from 'react-router';

import {
  HTTP_FORBIDDEN,
  HTTP_NOT_FOUND,
  HTTP_UNAUTHORIZED,
  mixpanelMessages,
  ONE,
} from '@/constants';
import { type AppMessage, AppMessageSeverities } from '@/types';
import { getPath, track } from '@/utils';

import { useNavigateUp } from './useNavigateUp';

import type { AxiosError } from 'axios';
import type { Dispatch, SetStateAction } from 'react';

import mentoApi from '@/api/mentoApi';

export const useApiClientInterceptors = (
  setAppMessage: Dispatch<SetStateAction<AppMessage>>,
  setInterceptorsSetup: Dispatch<SetStateAction<boolean>>,
) => {
  const navigate = useNavigate();
  const navigateUp = useNavigateUp();
  const messagePaths = Object.keys(mixpanelMessages);

  // Request interceptor
  useEffect(() => {
    const requestInterceptor = mentoApi.interceptors.request.use(
      (config) => {
        if (!config.url) {
          return config;
        }
        // First, we clean up our URL. We want to remove {host} and
        // query params from it. Some sample input/outputs:
        //
        //   api/teams?is_active=true         =>   /teams
        //   client/api/boulders/someIdHere   =>   /boulders/someIdHere
        //   coach/api/boulders               =>   /boulders
        //
        const splitUrl = config.url.split('/api'); // Split '/api'
        const cleanUrl =
          splitUrl.length > ONE
            ? (splitUrl[1] as string)
            : (splitUrl[0] as string); // Logic to get path based on if config.url had a baseURL or not.
        const cleanPath = cleanUrl.split('?')[0] as string; // Remove url params
        // Next, we want to look in our mixpanelMessages constant's
        // keys and see if we have messages for this particular URL.
        // We iterate over all keys in our const and use
        // react-router's matchPath to see if we have a match.
        // You may be worried by performance, don't be. Even if our
        // message list gets in the thousands, this shouldn't take
        // too long and will stop on the first match.
        // Some samples of URLs we want to match:
        //
        //   /boulders/:id      should match   /boulders/123894031289
        //   /teams             should match   /teams
        //   /foo/:id/bar/:id   should match   /foo/312839/bar/423194
        //
        const currentPath = messagePaths.find((path) =>
          matchPath(path, cleanPath),
        );
        // After getting a match, we look into the messages to see
        // if this particular verb (POST/GET/PUT/...) has a message.
        const message =
          currentPath &&
          mixpanelMessages[currentPath]?.[config.method as 'get'];

        // If it does, send it over to mixpanel along with the data,
        // unless the message has ignoreData set.
        if (message) {
          track(message.message, message.ignoreData ? undefined : config.data);
        }

        return config;
      },
      (error) => Promise.reject(error),
    );

    return () => {
      mentoApi.interceptors.request.eject(requestInterceptor);
    };
  }, []);

  // Response interceptor
  useEffect(() => {
    const errorInterceptor = mentoApi.interceptors.response.use(
      (response) => response,
      (error: AxiosError) => {
        captureException(error);

        if (error.response?.status === HTTP_NOT_FOUND) {
          setAppMessage({
            message: "Sorry, we couldn't find what you were looking for!",
            open: true,
            severity: AppMessageSeverities.Error,
          });
          navigateUp();
        }

        if (error.response?.status === HTTP_FORBIDDEN) {
          setAppMessage({
            message: "Sorry, you don't have access to that resource!",
            open: true,
            severity: AppMessageSeverities.Error,
          });
          navigateUp();
        }

        if (error.response?.status === HTTP_UNAUTHORIZED) {
          // Using this because useLocation was returning the previous path
          const path = getPath();

          navigate(`/signin${path ? `?redirect=${path}` : ''}`);
        }

        return Promise.reject(error);
      },
    );

    // We care mostly about the Response interceptor, the Request one
    // only sets up mixpanel messages, so if it is not done, it is not an issue
    setInterceptorsSetup(true);
    return () => {
      mentoApi.interceptors.response.eject(errorInterceptor);
    };
  }, []);
};
