import { Button, Typography } from '@/ui';

import type { FC } from 'react';

interface LockedFeatureProps {
  title: string;
}

const LockedFeature: FC<LockedFeatureProps> = ({ title }) => (
  <div className="m-auto flex  h-full max-w-200 flex-col items-center justify-center space-y-4 p-20">
    <img
      alt="sign-up"
      className="w-full"
      src="https://mento-space.nyc3.cdn.digitaloceanspaces.com/assets/locked-state-image.png"
    />
    <Typography variant="caption">Locked</Typography>
    <Typography className="px-20 text-center" variant="h4">
      Sign-up for Mento Coaching to access {title}
    </Typography>
    <Button
      className="px-16"
      color="secondary"
      href="/get-started"
      variant="contained"
    >
      Start
    </Button>
  </div>
);

export default LockedFeature;
