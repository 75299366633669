import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@/ui';

import type { FC } from 'react';

interface ConfirmArchiveProps {
  onArchive: () => void;
  onClose: () => void;
  saving: boolean;
}
const ConfirmArchive: FC<ConfirmArchiveProps> = ({
  onArchive,
  onClose,
  saving,
}) => (
  <Dialog open onClose={onClose}>
    <DialogTitle>Archive this team?</DialogTitle>
    <DialogContent>
      <Typography>
        When you archive a team none of the team members will be able to access
        it.
      </Typography>
      <Typography>
        You'll be able to unarchive the team in the future.
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button disabled={saving} variant="outlined" onClick={onClose}>
        Cancel
      </Button>
      <Button
        color="warning"
        loading={saving}
        variant="contained"
        onClick={onArchive}
      >
        Archive team
      </Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmArchive;
