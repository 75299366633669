import { useCurrentUser, useView } from '@/hooks';
import { Skeleton } from '@/ui';

import Desktop from './Desktop';
import Mobile from './Mobile';

import type { User } from '@/types';

export interface ActionProps {
  coaches: User[];
}

const Coaches = () => {
  const { isClient } = useView();
  const { user, isUserLoading } = useCurrentUser();

  if (!isClient || !user.coaches.length) return null;
  if (isUserLoading) return <Skeleton />;

  return (
    <>
      <Desktop coaches={user.coaches} />
      <Mobile coaches={user.coaches} />
    </>
  );
};

export default Coaches;
