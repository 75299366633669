import MuiAvatarGroup from '@mui/material/AvatarGroup';
import { styled } from '@mui/material/styles';
import { clsx } from 'clsx';

import { avatarSizeMap } from '../Avatar';

import type { AvatarSizes } from '../Avatar';
import type { AvatarGroupProps as MuiAvatarGroupProps } from '@mui/material/AvatarGroup';
import type { FC } from 'react';

interface AvatarGroupProps extends MuiAvatarGroupProps {
  size?: AvatarSizes;
}

/* eslint-disable sort-keys -- CSS selectors shouldn't be alphabetically sorted. */
const StyledAvatarGroup = styled(MuiAvatarGroup)<AvatarGroupProps>(
  ({ size, theme }) => ({
    '&.MuiAvatarGroup-sizeXS': {
      '.MuiAvatar-root': {
        borderWidth: 1,
      },
    },
    '&.MuiAvatarGroup-sizeS': {
      '.MuiAvatar-root': {
        borderWidth: 1,
      },
    },
    '.MuiAvatar-root': {
      fontSize: theme.typography.pxToRem(
        avatarSizeMap[size as AvatarSizes].fontSize as number,
      ),
      height: theme.typography.pxToRem(
        avatarSizeMap[size as AvatarSizes].size as number,
      ),
      width: theme.typography.pxToRem(
        avatarSizeMap[size as AvatarSizes].size as number,
      ),
    },
    '.MuiSvgIcon-root': {
      fontSize: theme.typography.pxToRem(
        avatarSizeMap[size as AvatarSizes].fontSize,
      ),
    },
  }),
);
/* eslint-enable sort-keys */

const AvatarGroup: FC<AvatarGroupProps> = ({
  className,
  size = 'm',
  ...rest
}) => {
  const classes = clsx(
    'mnt-avatar-group',
    `MuiAvatarGroup-size${size.toUpperCase()}`,
    className,
  );

  return <StyledAvatarGroup {...rest} className={classes} size={size} />;
};
AvatarGroup.displayName = 'AvatarGroup';

export default AvatarGroup;
export type { AvatarGroupProps };
