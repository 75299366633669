import { ZERO } from '@/constants';
import { BoulderColor } from '@/types';

import theme from '../ui/theme';

import type {
  GrowthOnboardingStepData,
  IDictionaryOfDictionary,
} from '@/types';

export const SMALL_VERTICAL_LINE = 20;

// TODO: Split into Growth, Boulder, Step
export const BOULDER_DARK_COLOR = '#69B7F1';
export const BOULDER_LIGHT_COLOR = '#C1E5FF';

export const BOULDER_COLORS: IDictionaryOfDictionary = {
  [BOULDER_DARK_COLOR]: {
    [BoulderColor.BLOOM]: theme.extendedColors.bloom[500] as string,
    [BoulderColor.SUNNY]: theme.extendedColors.sunny[500] as string,
    [BoulderColor.POPPY]: theme.extendedColors.poppy[500] as string,
    [BoulderColor.SAGE]: theme.extendedColors.sage[500] as string,
    [BoulderColor.OCEAN]: theme.extendedColors.ocean[500] as string,
    [BoulderColor.SKY]: theme.extendedColors.sky[500] as string,
  },
  [BOULDER_LIGHT_COLOR]: {
    [BoulderColor.BLOOM]: theme.extendedColors.bloom[300] as string,
    [BoulderColor.SUNNY]: theme.extendedColors.sunny[300] as string,
    [BoulderColor.POPPY]: theme.extendedColors.poppy[300] as string,
    [BoulderColor.SAGE]: theme.extendedColors.sage[300] as string,
    [BoulderColor.OCEAN]: theme.extendedColors.ocean[300] as string,
    [BoulderColor.SKY]: theme.extendedColors.sky[300] as string,
  },
};

const BOULDER_TINY = 'w-24';
const BOULDER_SMALL = 'w-36';
const BOULDER_MEDIUM = 'w-52';
const BOULDER_LARGE = 'w-72';

export const BOULDER_SIZES = [
  BOULDER_LARGE,
  BOULDER_SMALL,
  BOULDER_MEDIUM,
  BOULDER_TINY,
  BOULDER_SMALL,

  BOULDER_MEDIUM,
  BOULDER_TINY,
  BOULDER_SMALL,
  BOULDER_LARGE,

  BOULDER_SMALL,
  BOULDER_TINY,
  BOULDER_LARGE,
  BOULDER_SMALL,
  BOULDER_MEDIUM,
];

// North Star and One Year Vision
export const GROWTH_FIELD_LIMIT = 250;
export const GROWTH_BOULDER_LIMIT = 80;
export const MAX_BOULDER_STEPS = 8;
export const MAX_IN_PROGRESS_MILESTONES = 3;

export const GROWTH_STEP_ICONS: [string, string, string, string] = [
  'auto_awesome',
  'insights',
  'landscape',
  'hiking',
];

// Have to do it this way to keep TS happy instead of iterating
export const GROWTH_STEP_NAVIGATION = [
  { icon: GROWTH_STEP_ICONS[0], label: 'Imagine your North Star' },
  { icon: GROWTH_STEP_ICONS[1], label: 'Define your Vision' },
  { icon: GROWTH_STEP_ICONS[2], label: 'Create your first Milestone' },
  { icon: GROWTH_STEP_ICONS[3], label: 'Add a Step to get there' },
];

// Don't like having to do the icon like that, but TS complains about length otherwise
export const GROWTH_STEP_DATA: [
  GrowthOnboardingStepData,
  GrowthOnboardingStepData,
  GrowthOnboardingStepData,
  GrowthOnboardingStepData,
] = [
  {
    body: 'Your north star represents your career vision. Where do you see yourself in 3-5 years from now?',
    icon: GROWTH_STEP_ICONS[0],
    label: 'North star',
    limit: GROWTH_FIELD_LIMIT,
    title: 'Set your north star',
  },
  {
    body: 'Where do you see yourself professionally one year from now? Your one year vision should get you closer to reaching your north star.',
    icon: GROWTH_STEP_ICONS[1],
    label: 'One year vision',
    limit: GROWTH_FIELD_LIMIT,
    title: 'One year vision',
  },
  {
    body: 'Milestones are 1-3 month goals that get you closer to your one-year vision. You can have up to three milestones at a time to focus your growth plan.',
    icon: GROWTH_STEP_ICONS[2],
    label: 'Boulder',
    limit: GROWTH_BOULDER_LIMIT,
    title: 'Create a milestone',
  },
  {
    body: 'Steps help you make progress on your milestones and one year vision. Each milestone can support up to eight steps to keep it focused.',
    icon: GROWTH_STEP_ICONS[3],
    label: 'Step',
    limit: ZERO,
    title: 'Add a step',
  },
];

export const NORTH_STAR_DIALOG = {
  body: 'Your north star represents your career vision. Where do you see yourself in 3-5 years from now?',
  icon: 'auto_awesome',
  limit: GROWTH_FIELD_LIMIT,
  title: 'North star',
};

export const ONE_YEAR_DIALOG = {
  body: 'Where do you see yourself professionally one year from now? Your one year vision should get you closer to reaching your north star.',
  icon: 'insights',
  limit: GROWTH_FIELD_LIMIT,
  title: 'One year vision',
};
