import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import clsx from 'clsx';
import { useLocation, useNavigate } from 'react-router-dom';

import { Icon } from '@/ui';

import type { FC } from 'react';

interface SideBarButtonProps {
  icon: string;
  route: string;
  title: string;
}

const SideBarButton: FC<SideBarButtonProps> = ({
  icon = '',
  route = '/',
  title = '',
}) => {
  const navigation = useNavigate();
  const location = useLocation();
  const onClick = () => navigation(route);

  let isActive = false;
  let isHome = false;
  if (title === 'Home' && location.pathname === route) {
    isActive = true;
    isHome = true;
  } else if (location.pathname.startsWith(route) && title !== 'Home') {
    isActive = true;
  }
  return (
    <Tooltip placement="right" title={title}>
      <IconButton
        className={clsx('rounded-2xl text-cement-500 hover:text-black', {
          '!text-black': isActive && isHome,
          'bg-sage-300': isActive,
          'hover:bg-white/90 focus-visible:bg-white/90': !isActive,
        })}
        onClick={onClick}
      >
        <Icon>{icon}</Icon>
      </IconButton>
    </Tooltip>
  );
};

export default SideBarButton;
