import { track } from '@/utils';

import mentoApi from './mentoApi';

import type { Milestone, MilestoneSteps } from '@/types';

export const getMilestones = async (): Promise<Awaited<MilestoneSteps>> =>
  (await mentoApi.get('/milestones')).data;

export interface GetMilestoneParams {
  id: string;
}
export const getMilestone = async (
  params: GetMilestoneParams,
): Promise<Awaited<Milestone>> =>
  (await mentoApi.get(`/milestones/${params.id}`)).data;

export const patchMilestone = async ({
  id,
  ...milestone
}: Partial<Milestone> & { isComplete?: boolean }): Promise<Milestone> => {
  if (milestone.isComplete) {
    track('Complete Milestone');
  } else {
    track('Update Milestone');
  }
  return (await mentoApi.patch(`/milestones/${id}`, { milestone })).data;
};

export const postMilestone = async (
  milestone: Partial<Milestone>,
): Promise<Milestone> => {
  track('Create Milestone');
  return (await mentoApi.post('/milestones', milestone)).data;
};

export const deleteMilestone = async (id: string): Promise<void> => {
  track('Delete Milestone');
  return (await mentoApi.delete(`/milestones/${id}`)).data;
};

export const getMilestoneSuggestedSteps = async (
  milestoneId: string,
): Promise<Array<string>> =>
  (await mentoApi.get(`/milestones/${milestoneId}/suggestions`)).data;

export const refreshMilestoneSuggestedStep = async ({
  index,
  milestoneId,
}: {
  index: number;
  milestoneId: string;
}): Promise<Array<string>> =>
  (
    await mentoApi.patch(`/milestones/${milestoneId}/refresh_step`, {
      milestone: { index },
    })
  ).data;
