import { createTheme } from '@mui/material/styles';

import grotesqueBold from '../fonts/basis-grotesque-bold.woff';
import grotesqueMedium from '../fonts/basis-grotesque-medium.woff';
import grotesqueRegular from '../fonts/basis-grotesque-regular.woff';

import {
  bloom,
  cement,
  common,
  fire,
  ocean,
  poppy,
  sage,
  sky,
  sunny,
} from './colors';

import type { Color } from '@mui/material';
import type { TypographyVariant } from '@mui/material/styles';

type ExtendedColors = {
  common: {
    black: string;
    white: string;
  };
} & Record<
  | 'bloom'
  | 'cement'
  | 'common'
  | 'fire'
  | 'ocean'
  | 'poppy'
  | 'sage'
  | 'sky'
  | 'sunny',
  Partial<Color>
>;

declare module '@mui/material/styles' {
  interface Theme {
    extendedColors: ExtendedColors;
  }

  interface ThemeOptions {
    extendedColors: ExtendedColors;
  }

  interface Palette {
    gray: Palette['primary'];
    white: Palette['primary'];
  }

  interface PaletteOptions {
    gray?: PaletteOptions['primary'];
    white?: PaletteOptions['primary'];
  }

  interface PaletteColor {
    tonal?: string;
  }

  interface SimplePaletteColorOptions {
    tonal?: string;
  }

  interface TypeBackground {
    bloom?: string;
    disabled?: string;
    ocean?: string;
    sage?: string;
    sunny?: string;
  }

  interface TypographyVariants {
    bodylg: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    bodylg?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    bodylg: true;
  }
}

export type ExtendedTypographyVariant = TypographyVariant | 'bodylg';

const basisName = 'Basis Grotesque';
const getRootEl = () => document.getElementById('main');

/* eslint-disable sort-keys -- This object is ordered by hierarchy rather than alphabetically. */
const theme = createTheme({
  extendedColors: {
    bloom,
    cement,
    common,
    fire,
    ocean,
    poppy,
    sage,
    sky,
    sunny,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
      @font-face {
        font-family: ${basisName};
        font-weight: 700;
        src: url(${grotesqueBold}) format('woff2');
      }

      @font-face {
        font-family: ${basisName};
        font-weight: 500;
        src: url(${grotesqueMedium}) format('woff2');
      }

      @font-face {
        font-family: ${basisName};
        font-weight: 400;
        src: url(${grotesqueRegular}) format('woff2');
      }
      `,
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiPopover: {
      defaultProps: {
        container: getRootEl,
      },
    },
    MuiPopper: {
      defaultProps: {
        container: getRootEl,
      },
    },
    MuiDialog: {
      defaultProps: {
        container: getRootEl,
      },
    },
    MuiModal: {
      defaultProps: {
        container: getRootEl,
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          bodylg: 'span',
        },
      },
    },
  },
  palette: {
    mode: 'light',
    action: {
      hoverOpacity: 0.4,
    },
    background: {
      default: common.white,
      paper: common.white,
      disabled: cement[200],
      sage: sage[100],
      sunny: sunny[100],
      bloom: bloom[100],
      ocean: ocean[100],
    },
    divider: cement[200],
    primary: {
      main: sage[500],
      dark: sage[600],
      light: sage[400],
      tonal: sage[200],
      contrastText: common.white,
    },
    secondary: {
      main: sunny[500],
      dark: sunny[600],
      light: sunny[400],
      tonal: sunny[200],
      contrastText: cement[500],
    },
    error: {
      main: fire[500],
      dark: fire[600],
      light: fire[400],
      tonal: fire[200],
      contrastText: common.white,
    },
    warning: {
      main: fire[500],
      dark: fire[600],
      light: fire[400],
      tonal: fire[200],
      contrastText: common.white,
    },
    info: {
      main: bloom[500],
      dark: bloom[600],
      light: bloom[300],
      tonal: bloom[200],
      contrastText: common.white,
    },
    success: {
      main: sage[500],
      dark: sage[600],
      light: sage[400],
      tonal: sage[200],
      contrastText: common.white,
    },
    text: {
      primary: cement[500],
      secondary: cement[400],
      disabled: cement[300],
    },
    gray: {
      main: cement[200],
      dark: cement[500],
      light: cement[100],
      contrastText: cement[500],
    },
    white: {
      main: common.white,
      dark: common.white,
      light: common.white,
      contrastText: cement[500],
    },
  },
  shadows: [
    'none',
    '0px 2px 1px -1px rgba(0, 0, 0, 0.10), 0px 1px 1px 0px rgba(0, 0, 0, 0.10), 0px 1px 4px 0px rgba(0, 0, 0, 0.10)',
    '0px 3px 1px -2px rgba(0, 0, 0, 0.10), 0px 2px 2px 0px rgba(0, 0, 0, 0.10), 0px 1px 5px 0px rgba(0, 0, 0, 0.10)',
    '0px 3px 3px -2px rgba(0, 0, 0, 0.10), 0px 3px 4px 0px rgba(0, 0, 0, 0.10), 0px 1px 8px 0px rgba(0, 0, 0, 0.10)',
    '0px 2px 4px -1px rgba(0, 0, 0, 0.10), 0px 4px 5px 0px rgba(0, 0, 0, 0.10), 0px 1px 10px 0px rgba(0, 0, 0, 0.10)',
    '0px 3px 5px -1px rgba(0, 0, 0, 0.10), 0px 5px 8px 0px rgba(0, 0, 0, 0.10), 0px 1px 14px 0px rgba(0, 0, 0, 0.10)',
    '0px 3px 5px -1px rgba(0, 0, 0, 0.10), 0px 6px 10px 0px rgba(0, 0, 0, 0.10), 0px 1px 18px 0px rgba(0, 0, 0, 0.10)',
    '0px 4px 5px -2px rgba(0, 0, 0, 0.10), 0px 7px 10px 1px rgba(0, 0, 0, 0.10), 0px 2px 16px 1px rgba(0, 0, 0, 0.10)',
    '0px 5px 5px -3px rgba(0, 0, 0, 0.20), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
    '0px 5px 6px -3px rgba(0, 0, 0, 0.20), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12)',
    '0px 6px 6px -3px rgba(0, 0, 0, 0.20), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12)',
    '0px 6px 7px -4px rgba(0, 0, 0, 0.20), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12)',
    '0px 7px 8px -4px rgba(0, 0, 0, 0.20), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12)',
    '0px 7px 8px -4px rgba(0, 0, 0, 0.20), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12)',
    '0px 7px 9px -4px rgba(0, 0, 0, 0.20), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12)',
    '0px 8px 9px -5px rgba(0, 0, 0, 0.20), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12)',
    '0px 8px 10px -5px rgba(0, 0, 0, 0.20), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12)',
    '0px 8px 11px -5px rgba(0, 0, 0, 0.20), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12)',
    '0px 9px 11px -5px rgba(0, 0, 0, 0.20), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12)',
    '0px 9px 12px -6px rgba(0, 0, 0, 0.20), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12)',
    '0px 10px 13px -6px rgba(0, 0, 0, 0.20), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12)',
    '0px 10px 13px -6px rgba(0, 0, 0, 0.20), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12)',
    '0px 10px 14px -6px rgba(0, 0, 0, 0.20), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12)',
    '0px 11px 14px -7px rgba(0, 0, 0, 0.20), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12)',
    '0px 11px 15px -7px rgba(0, 0, 0, 0.20), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)',
  ],
  shape: {
    borderRadius: 12,
  },
  typography: {
    bodylg: {
      fontWeight: 400,
      fontSize: '19px',
      letterSpacing: '0px',
      lineHeight: 1.45,
    },
    body1: {
      fontWeight: 400,
      letterSpacing: '0px',
    },
    body2: {
      fontWeight: 400,
      letterSpacing: '0px',
    },
    button: {
      textTransform: 'none',
    },
    caption: {
      fontWeight: 500,
      lineHeight: 1.3345,
    },
    fontFamily: [basisName, 'sans-serif'].join(','),
    h1: {
      fontWeight: 700,
      letterSpacing: '-1.5px',
    },
    h2: {
      fontWeight: 700,
      letterSpacing: '-0.5px',
    },
    h3: {
      fontWeight: 700,
      letterSpacing: '-0.5px',
    },
    h4: {
      fontSize: '2rem',
      fontWeight: 700,
      lineHeight: 1.2,
    },
    h5: {
      fontWeight: 700,
    },
    h6: {
      fontWeight: 400,
      letterSpacing: '0.15px',
    },
    overline: {
      fontWeight: 400,
      letterSpacing: '1px',
    },
    subtitle1: {
      fontWeight: 700,
      letterSpacing: '0.15px',
      lineHeight: 1.5,
    },
    subtitle2: {
      fontWeight: 700,
      letterSpacing: '0.1px',
      lineHeight: 1.43,
    },
  },
});
/* eslint-enable sort-keys */

export default theme;
