import { useQuery } from '@tanstack/react-query';

import { getCompanyStats } from '@/api';

import type { CompanyStats } from '@/types';
import type { AxiosError } from 'axios';

export const useCompanyStats = (companyId: string) => {
  const {
    data: companyStats,
    isLoading: iscompanyStatsLoading,
    error,
    refetch: refetchCompanyStats,
  } = useQuery<CompanyStats, AxiosError>(
    ['companyStats', companyId],
    () => getCompanyStats(companyId),
    {
      // Add user.super_admin check to enabeld
      enabled: !!companyId,
    },
  );

  return {
    companyStats,
    error,
    iscompanyStatsLoading,
    refetchCompanyStats,
  };
};
