import { useNavigate } from 'react-router';

import { CardType } from '@/types';

import useSharedCardActions from './useSharedCardActions';

import type { GeneratorFunction } from './types';

const useCreateProfileActions = (): GeneratorFunction => {
  const { handleClick } = useSharedCardActions();
  const navigate = useNavigate();

  return ({ card }) => {
    const label =
      card.type === CardType.CREATE_PROFILE ? 'Set up profile' : 'Add photo';
    return [
      {
        label,
        onClick: () => {
          handleClick({ card, label });
          navigate('/m/profile');
        },
      },
    ];
  };
};

export default useCreateProfileActions;
