import { useEffect, useState } from 'react';

import { ONE, ZERO } from '@/constants';
import { useCurrentUser, useSurvey, useSurveyTemplate } from '@/hooks';
import { SurveyStatus } from '@/types';

import Viewer from '../Viewer';

import SurveyBuilder from './SurveyBuilder';
import Tabs from './Tabs';

import type { FC } from 'react';

// This view is only seen by client, not by coach
const Survey: FC = () => {
  const [activeTab, setActiveTab] = useState(ZERO);

  const { isUserLoading } = useCurrentUser();
  const { survey, isSurveyLoading, refetchSurvey } = useSurvey();
  const { surveyTemplate, isSurveyTemplateLoading, refetchSurveyTemplate } =
    useSurveyTemplate();

  const isLoading = isUserLoading || isSurveyLoading || isSurveyTemplateLoading;

  useEffect(() => {
    refetchSurvey();
    refetchSurveyTemplate();
  }, []);

  if (isLoading) {
    return null;
  }

  const isExample = activeTab === ONE;

  const showViewer =
    isExample ||
    survey.status === SurveyStatus.COACH_REVIEWING ||
    survey.status === SurveyStatus.SHARED;

  const showHeader = !showViewer || isExample;

  return (
    <>
      {showHeader && (
        <>
          <h1 className="headline m-0 text-cement-600">
            {surveyTemplate.name}
          </h1>
          <Tabs setTab={setActiveTab} tab={activeTab} />
        </>
      )}
      {showViewer ? (
        <div className="pt-4">
          <Viewer isExample={isExample} />
        </div>
      ) : (
        <SurveyBuilder survey={survey} template={surveyTemplate} />
      )}
    </>
  );
};

export default Survey;
