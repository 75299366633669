import CoachInfoLoading from './CoachInfoLoading';
import InformationLoading from './InformationLoading';
import PlanDetailsLoading from './PlanDetails/PlanDetailsLoading';
import DocumentsLoading from './RecentlyUpdatedDocuments/DocumentsLoading';
import SessionsLoading from './Sessions/SessionsLoading';

const MemberLoading = () => (
  <div className="w-full items-center">
    <div className="cement-300 flex w-full flex-1 flex-col">
      <div className="flex-1 bg-sage-100 p-0 md:p-2">
        <div className="container mx-auto max-w-screen-lg p-4">
          <div className="grid grid-cols-1 gap-4 lg:grid-cols-3 ">
            <div className="space-y-6 lg:col-span-2">
              <SessionsLoading />
              <DocumentsLoading />
            </div>
            <div className="space-y-6 lg:col-span-1">
              <InformationLoading />
              <PlanDetailsLoading />
              <CoachInfoLoading />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default MemberLoading;
