import MuiSkeleton from '@mui/material/Skeleton';
import clsx from 'clsx';

import type { SkeletonProps } from '@mui/material/Skeleton';
import type { FC } from 'react';

const Skeleton: FC<SkeletonProps> = ({ className, ...rest }) => {
  const classes = clsx('mnt-skeleton', className);

  return <MuiSkeleton {...rest} className={classes} />;
};

export default Skeleton;
export type { SkeletonProps };
