import { useState } from 'react';

import { Icon, Popover, Typography } from '@/ui';

import type { FC } from 'react';

const Info: FC = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <div
        aria-haspopup="true"
        aria-owns={open ? 'mouse-over-popover' : undefined}
        className="ml-1"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <Icon>info </Icon>
      </div>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
        className="pointer-events-none"
        id="mouse-over-popover"
        open={open}
        transformOrigin={{
          horizontal: 'left',
          vertical: 'top',
        }}
        disableRestoreFocus
        onClose={handlePopoverClose}
      >
        <div>
          <Typography>
            Only you can view non-anonymized responses. <br /> We promised peers
            their responses will remain anonymous, <br /> so please don't share
            their personal details with your member.
          </Typography>
        </div>
      </Popover>
    </>
  );
};

export default Info;
