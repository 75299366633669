import { TWO } from '@/constants';
import { SurveyChoiceText } from '@/types';

export const isValidEmail = (email: string) => {
  if (email) {
    return !/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/u.test(email);
  }
  return false;
};

export const getChoiceText = (total: number, current: number) => {
  if (!current) return SurveyChoiceText.NO_ONE;
  if (total === current) return SurveyChoiceText.EVERYONE;
  if (total / TWO <= current) return SurveyChoiceText.MOST;
  return SurveyChoiceText.SOME;
};
