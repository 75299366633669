import { useEffect, useState } from 'react';

import { updateUser } from '@/api';
import { MINDSET_IMAGE_URL } from '@/constants';
import { useCurrentUser } from '@/hooks';
import { Mindset as MindsetEnum } from '@/types';
import { Button, Drawer, Skeleton, Typography } from '@/ui';
import { track } from '@/utils';

import Item from './Item';

const Mindset = () => {
  const { user, isUserLoading, refetchUser } = useCurrentUser();
  const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useState(MindsetEnum.reflective);

  useEffect(() => {
    setName(user.mindset);
  }, [user]);

  const onButtonClick = () => {
    track('Open Mindset Background', { clientId: user.id });
    setIsOpen(true);
  };

  const onItemClick = (mindset: MindsetEnum) => async () => {
    track('Selected Mindset', { clientId: user.id, mindset });
    setIsOpen(false);
    await updateUser(user.id, { mindset });
    await refetchUser();
    setName(user.mindset);
  };

  const onDrawerClose = () => {
    track('Dismissed Mindset Background', { clientId: user.id });
    setIsOpen(false);
  };

  if (isUserLoading || !user.id) {
    return <Skeleton />;
  }

  return (
    <>
      <Button
        className="hidden w-fit shrink-0 cursor-pointer items-center gap-2 rounded-full py-2 pl-2 pr-4 transition ease-in-out hover:bg-cement-600/30 md:flex"
        onClick={onButtonClick}
      >
        <img
          alt="mindset"
          className="h-10 w-10"
          src={`${MINDSET_IMAGE_URL}mindset.svg`}
        />
        <div className="flex flex-col text-left text-white">
          <Typography className="cursor-pointer" variant="caption">
            Mindset
          </Typography>
          <Typography className="cursor-pointer capitalize" variant="subtitle1">
            {name}
          </Typography>
        </div>
      </Button>
      <Drawer anchor="right" open={isOpen} onClose={onDrawerClose}>
        <div className="bg-opacity/60 flex flex-1 flex-col gap-4 overflow-auto bg-cement-600 p-4">
          {Object.keys(MindsetEnum).map((key) => (
            <Item
              key={`mindset-selectable-item-${key}`}
              isSelected={key === user.mindset}
              mindset={key as MindsetEnum}
              onClick={onItemClick(key as MindsetEnum)}
            />
          ))}
        </div>
      </Drawer>
    </>
  );
};

export default Mindset;
