import MuiTab from '@mui/material/Tab';
import { clsx } from 'clsx';

import type { TabProps } from '@mui/material/Tab';
import type { FC } from 'react';

const Tab: FC<TabProps> = ({ className, ...rest }) => {
  const classes = clsx('mnt-tab', className);

  return <MuiTab {...rest} className={classes} />;
};
Tab.displayName = 'Tab';

export default Tab;
export type { TabProps };
