import { JOB_TITLES } from '@/constants';
import { CreatableSelect, Grid, Typography } from '@/ui';

import type { CoachProfile } from '@/types';
import type { FC } from 'react';

interface RolesProps {
  coachProfile: CoachProfile;
  onCoachProfileChange: (updated: CoachProfile) => void;
}

const Roles: FC<RolesProps> = ({ coachProfile, onCoachProfileChange }) => {
  const onRolesChange = (value: string[]) => {
    onCoachProfileChange({ ...coachProfile, role: value as [] });
  };

  return (
    <Grid spacing={4} container>
      <Grid md={5} xs={12} item>
        <Typography className="mb-2" variant="h4">
          Roles & dream jobs you can support
        </Typography>
        <Typography className="mb-1" variant="body1">
          This information will be used for matching and not publicly shared.
          Please be comprehensive.
        </Typography>
      </Grid>
      <Grid className="mt-4 flex flex-col gap-3" md={7} xs={12} item>
        <CreatableSelect
          helperText="Choose all that apply"
          label="Roles"
          options={JOB_TITLES}
          value={coachProfile.role}
          multiple
          onChange={onRolesChange}
        />
      </Grid>
    </Grid>
  );
};

export default Roles;
