import { Icon, TextField } from '@/ui';

import type { ChangeEvent, FC } from 'react';

interface CoachingGoalProps {
  goal: string;
  onGoalChange: (value: string) => void;
  onRemove: () => void;
}

const CoachingGoal: FC<CoachingGoalProps> = ({
  goal = '',
  onGoalChange,
  onRemove,
}) => {
  const onChange = (e: ChangeEvent<HTMLInputElement>) =>
    onGoalChange(e.target.value);

  return (
    <div className="flex items-center justify-between gap-2">
      <TextField
        className="w-full"
        label="Coaching goal"
        value={goal}
        variant="outlined"
        onChange={onChange}
      />
      <Icon className="cursor-pointer" onClick={onRemove}>
        trash
      </Icon>
    </div>
  );
};

export default CoachingGoal;
