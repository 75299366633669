import clsx from 'clsx';

import { Card, CardContent, Icon, Typography } from '@/ui';

import type { FC } from 'react';

interface CalCardProps {
  className?: string;
  username?: string;
}

const CAL_URL = import.meta.env.VITE_CAL_URL;

const CalCard: FC<CalCardProps> = ({ username = '', className = '' }) => {
  const title = username ? `${CAL_URL}/${username}` : 'Scheduling not setup';

  return (
    <Card
      className={clsx(
        'w-full',
        {
          'bg-poppy-200 text-poppy-600': !username,
          'bg-sage-200 text-sage-600': !!username,
        },
        className,
      )}
      elevation={0}
    >
      <CardContent className="flex items-center justify-between px-3 py-2">
        <div className="flex gap-2">
          <Icon>public</Icon>
          <Typography variant="body2">{title}</Typography>
        </div>
        {!!username && (
          <Typography variant="body2">
            <a
              className="flex items-center"
              href={`${CAL_URL}/${username}`}
              rel="noreferrer"
              target="_blank"
            >
              <Icon>open_in_new</Icon>
            </a>
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default CalCard;
