import MuiTableRow from '@mui/material/TableRow';
import { clsx } from 'clsx';

import type { TableRowProps } from '@mui/material/TableRow';
import type { FC } from 'react';

const TableRow: FC<TableRowProps> = ({ className, ...rest }) => {
  const classes = clsx('mnt-table-row', className);

  return <MuiTableRow {...rest} className={classes} />;
};
TableRow.displayName = 'TableRow';

export default TableRow;
export type { TableRowProps };
