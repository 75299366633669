import {
  useConfirmMessageThreadMessage,
  useGeMessageThreadMessages,
} from '@/hooks';
import { MESSAGE_TYPES, MessageRole } from '@/types';
import { getMessageType } from '@/utils';

import Bubble from './Bubble';
import ListSummary from './ListSummary';
import TextSummary from './TextSummary';
import MessageExtendable from './_MessageExtendable';

import type { MessageThreadMessage, User } from '@/types';
import type { FC } from 'react';

type MessageProps = {
  currentUser?: User;
  message: MessageThreadMessage;
};

const Message: FC<MessageProps> = ({ currentUser, message }) => {
  const messageType = getMessageType(message);
  const { refetch } = useGeMessageThreadMessages({
    messageThreadId: message.messageThreadId,
  });
  const confirmMessageThreadMessage = useConfirmMessageThreadMessage();

  const onConfirm = (
    confirmed: boolean,
    messageId: string,
    buttonLabel: string,
  ) => {
    confirmMessageThreadMessage.mutate(
      {
        confirmed,
        content: buttonLabel,
        messageId,
      },
      {
        onSuccess: () => refetch(),
      },
    );
  };

  if (messageType === MESSAGE_TYPES.TEXT) {
    return (
      <div className="mx-auto max-w-full md:max-w-156">
        <Bubble currentUser={currentUser} message={message} />
        <TextSummary key={message.id} message={message} onConfirm={onConfirm} />
        {message.extendable.title && (
          <MessageExtendable extendable={message.extendable} />
        )}
      </div>
    );
  }
  if (messageType === MESSAGE_TYPES.LIST) {
    return (
      <div className="mx-auto max-w-full md:max-w-156">
        <Bubble currentUser={currentUser} message={message} />
        <ListSummary key={message.id} message={message} onConfirm={onConfirm} />
        {message.extendable.title && (
          <MessageExtendable extendable={message.extendable} />
        )}
      </div>
    );
  }

  return (
    <div className="mx-auto max-w-full md:max-w-156">
      <Bubble
        key={message.id}
        currentUser={currentUser}
        isUser={message.role !== MessageRole.ASSISTANT}
        message={message}
      />
      {message.extendable.title && (
        <MessageExtendable extendable={message.extendable} />
      )}
    </div>
  );
};

export default Message;
