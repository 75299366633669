import { styled } from '@mui/material';
import MuiCardContent from '@mui/material/CardContent';
import { clsx } from 'clsx';

import type { CardContentProps } from '@mui/material/CardContent';
import type { FC } from 'react';

/* eslint-disable sort-keys,@typescript-eslint/no-magic-numbers -- CSS selectors shouldn't be alphabetically sorted. CSS values make little sense as constants. */
const StyledCardContent = styled(MuiCardContent)<CardContentProps>(
  ({ theme }) => ({
    padding: theme.spacing(3),
    '+ .MuiCardActions-root': {
      marginTop: theme.spacing(-1),
      paddingTop: 0,
    },
  }),
);
/* eslint-enable sort-keys,@typescript-eslint/no-magic-numbers */

const CardContent: FC<CardContentProps> = ({ className, ...rest }) => {
  const classes = clsx('mnt-card-content', className);

  return <StyledCardContent {...rest} className={classes} />;
};
CardContent.displayName = 'CardContent';

export default CardContent;
export type { CardContentProps };
