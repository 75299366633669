import BusinessPlan from './businessPlan';
import DirectPlan from './directPlan';

import type { User } from '@/types';
import type { FC } from 'react';

interface PlanDetailsProps {
  client: User;
}

const PlanDetails: FC<PlanDetailsProps> = ({ client }) => {
  const isBusinessPlanType = client.isBusinessPaid;
  const hasNoPlan = !client.isPaid;

  if (hasNoPlan) return null;

  return isBusinessPlanType ? (
    <BusinessPlan client={client} />
  ) : (
    <DirectPlan client={client} />
  );
};

export default PlanDetails;
