import { CircularProgress } from '@mui/material';

// TODO: Rename to match file structure
const LoadingCover = () => (
  <div className="flex h-screen flex-1 items-center justify-center bg-sage-100 px-6">
    <div className="flex flex-col items-center px-6">
      <img
        alt="logo"
        className="col-start-2 mx-auto"
        height="150"
        src="https://mento-space.nyc3.digitaloceanspaces.com/logo.svg"
        width="200"
      />
      <CircularProgress />
    </div>
  </div>
);

export default LoadingCover;
