import { useState } from 'react';

import { Button } from '@/ui';
import { track } from '@/utils';

import Modal from './Modal';

import type { FC } from 'react';

interface UploadProps {
  maxDocumentsReached?: boolean;
}

const Upload: FC<UploadProps> = ({ maxDocumentsReached = false }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onOpen = () => {
    track('Clicked Upload File');
    setIsOpen(true);
  };

  const onClose = () => setIsOpen(false);

  return (
    <>
      <Button
        className="w-full md:w-fit"
        disabled={maxDocumentsReached}
        variant="outlined"
        onClick={onOpen}
      >
        Upload
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default Upload;
