import { DocumentViewIcons } from '@/constants';
import { DocumentViewState } from '@/types';
import { Icon, IconButton } from '@/ui';

import type { FC } from 'react';

interface ViewToggleProps {
  setView: (v: DocumentViewState) => void;
  view: DocumentViewState;
}

const ViewToggle: FC<ViewToggleProps> = ({ setView, view }) => {
  const toggleView = () => {
    if (view === DocumentViewState.GRID) {
      setView(DocumentViewState.LIST);
    } else {
      setView(DocumentViewState.GRID);
    }
  };

  return (
    <IconButton className="ml-auto hidden md:inline-flex" onClick={toggleView}>
      <Icon>{DocumentViewIcons[view] ?? ''}</Icon>
    </IconButton>
  );
};

export default ViewToggle;
