import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { createAiChatThread } from '@/api';
import { useCurrentUser } from '@/hooks';
import { Button, TextField } from '@/ui';

import type { ChangeEvent } from 'react';

/*
 * Very very prototype entry point for admins only to interact with an AI-based chat bot that layers in
 * Mento-specific context. Treat this as throwaway code -- it really only serves right now to show a form
 * to select an assistant to play with. Eventually we will add more useful functionality and context,
 * but chances are it won't be on this page.
 *
 * Note: This is left deliberately ugly and sparse so that the fidelity of the UI does not fool our stakeholders
 * into thinking the backend is more mature than it is.
 */
const CoachAiPlayground = () => {
  const [assistantId, setAssistantId] = useState('');
  const navigate = useNavigate();

  const { user } = useCurrentUser();
  const [userId, setUserId] = useState(user.id);

  const onAssistantIdChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAssistantId(e.target.value || '');
  };
  const onUserIdChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUserId(e.target.value || '');
  };

  useEffect(() => {
    if (user.id && !user.superAdmin) {
      navigate('/');
    }
  }, [user.id, user.superAdmin]);

  const startChat = async (event: React.FormEvent) => {
    event.preventDefault();
    // create a thread and then navigate to it
    const thread = await createAiChatThread({ assistantId, userId });
    navigate(thread.id);
  };
  return (
    <div className="w-full p-4">
      <div className="flex-1 flex-col md:flex">
        <h2>Mento CoachAI Playground</h2>
        <p>Warning: Super prototype for playing around. Major rough edges.</p>
        <form onSubmit={startChat}>
          <TextField
            className="mb-4 w-full"
            label="Assistant ID"
            value={assistantId}
            onChange={onAssistantIdChange}
          />
          {/* TODO: Make this a typeahead select or something else nice */}
          <TextField
            className="mb-4 w-full"
            label="UserId (defaults to your user id)"
            value={userId}
            onChange={onUserIdChange}
          />
          <Button type="submit">Start Chat</Button>
        </form>
      </div>
    </div>
  );
};

export default CoachAiPlayground;
