import { isToday } from 'date-fns';

import { SessionStatus } from '@/types';
import { Button, Icon } from '@/ui';

import type { Session } from '@/types';
import type { FC } from 'react';

interface JoinButtonProps {
  session: Session;
}

const JoinButton: FC<JoinButtonProps> = ({ session = undefined }) => {
  const isDoneOrCancelled =
    session?.status === SessionStatus.DONE ||
    session?.status === SessionStatus.CANCELLED;

  const isValidLocation = !!session?.location?.startsWith('https:');
  const isSessionToday =
    session?.startedAt && isToday(new Date(session.startedAt));

  const openSession = () => {
    window.open(session?.location as string);
  };

  if (isDoneOrCancelled || !isValidLocation || !isSessionToday) return null;

  const startIcon = <Icon>videocam</Icon>;
  if (!session.location) return null;
  return (
    <Button
      className="w-full md:mt-0 md:w-auto"
      color="primary"
      startIcon={startIcon}
      variant="contained"
      onClick={openSession}
    >
      Join session
    </Button>
  );
};

export default JoinButton;
