import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { ENTER } from '@/constants';
import { AppContext } from '@/context';
import { Avatar, Icon, IconButton, Typography } from '@/ui';
import { track } from '@/utils';

import type { User } from '@/types';
import type { FC, KeyboardEvent } from 'react';

const Actions: FC<User> = (coach) => {
  const navigate = useNavigate();
  const { setChatId } = useContext(AppContext);

  const { avatarUrl, cometChatId, id, firstName, fullName, lastName } = coach;

  // TODO: Add track for further abstraction?
  const createCallback =
    (callback: Function) => (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      event.preventDefault();
      callback();
    };

  const onMessage = () => {
    track('Open Chat Bubble', { recipient: id });
    setChatId(cometChatId);
  };

  const onProfile = () => {
    track('Clicked Coach Profile', {
      coachId: id,
    });
    navigate(`/m/profiles-coach/${id}`);
  };

  const onSchedule = () => {
    track('Clicked Coach Schedule', { coachId: id });
    navigate('/sessions');
  };

  const onCoaching = () => {
    track('Clicked Coach', {
      coachId: id,
    });
    navigate('/sessions/upcoming');
  };

  const onKeyDown = (event: KeyboardEvent<HTMLElement>) => {
    if (event.key === ENTER) {
      onCoaching();
    }
  };

  return (
    <div
      className="group flex w-fit cursor-pointer items-center gap-2 rounded-full px-3.5 py-2 transition ease-in-out hover:bg-cement-600/30"
      role="button"
      tabIndex={0}
      onClick={onCoaching}
      onKeyDown={onKeyDown}
    >
      <Avatar
        className="shrink-0"
        firstName={firstName}
        lastName={lastName}
        src={avatarUrl}
      />
      <div className="flex flex-col text-left text-white">
        <Typography variant="caption">Your coach</Typography>
        <Typography variant="subtitle1">{fullName}</Typography>
      </div>
      <div className="hidden items-center justify-center gap-1 text-white md:group-hover:flex">
        <IconButton color="white" onClick={createCallback(onMessage)}>
          <Icon>chat_bubble</Icon>
        </IconButton>
        <IconButton color="white" onClick={createCallback(onProfile)}>
          <Icon>account_circle</Icon>
        </IconButton>
        <IconButton color="white" onClick={createCallback(onSchedule)}>
          <Icon>calendar_today</Icon>
        </IconButton>
      </div>
    </div>
  );
};

export default Actions;
