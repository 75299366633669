import { Icon, ListItem, Typography } from '@/ui';

import type { FC } from 'react';

interface InstructionProps {
  body: string;
  icon: string;
  title: string;
}

const Instruction: FC<InstructionProps> = ({ body, title, icon }) => (
  <ListItem className="flex flex-col px-0 py-6 md:flex-row md:space-x-6">
    <div className="m-0 grid place-items-center self-start rounded-full bg-bloom-500 p-2">
      <Icon className="text-white">{icon}</Icon>
    </div>
    <div className="grid">
      <Typography className="text-cement-600" variant="h6">
        {title}
      </Typography>
      <Typography
        className="text-cement-600"
        dangerouslySetInnerHTML={{ __html: body }}
      />
    </div>
  </ListItem>
);

export default Instruction;
