import { TEN } from '@/constants';
import { useView } from '@/hooks';
import { NoteOwnerType } from '@/types';
import { Typography } from '@/ui';

import Notes from '@/components/Notes/Notes';

const TeamMemberNotes = () => {
  const { selectedUser, isViewLoading } = useView();

  if (isViewLoading || !selectedUser.id) return null;

  return (
    <div className="p-8">
      <Typography variant="h4">Private notes</Typography>
      <div className="flex w-full flex-1 flex-col pt-4">
        <Notes
          limit={TEN}
          owneableId={selectedUser.id}
          owneableType={NoteOwnerType.USER}
        />
      </div>
    </div>
  );
};

export default TeamMemberNotes;
