import { STYLE_OPTIONS } from '@/constants';
import { Grid, Typography } from '@/ui';

import type { CoachProfile } from '@/types';
import type { FC } from 'react';

import Style from '@/components/Profile/Coach/Styles/Style';

interface CoachingStyleProps {
  coachProfile: CoachProfile;
  onCoachProfileChange: (updated: CoachProfile) => void;
}

const CoachingStyle: FC<CoachingStyleProps> = ({
  coachProfile,
  onCoachProfileChange,
}) => {
  const onStyleClick = (selected: boolean, key: string) => {
    let updatedStyles = coachProfile.styles.length
      ? [...coachProfile.styles]
      : [];

    if (!selected) {
      updatedStyles.push(key);
    } else {
      updatedStyles = updatedStyles.filter((s) => s !== key);
    }
    onCoachProfileChange({
      ...coachProfile,
      styles: updatedStyles,
    });
  };

  return (
    <Grid spacing={4} container>
      <Grid md={5} xs={12} item>
        <Typography className="mb-2" variant="h4">
          Coaching style
        </Typography>
        <Typography className="mb-1" variant="body1">
          Select up to two of your coaching strengths. This will be visible to
          Members to help them select a coach whose strengths align with their
          goals.
        </Typography>
      </Grid>
      <Grid className="mt-1 flex flex-col gap-3" md={7} xs={12} item>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
          {Object.keys(STYLE_OPTIONS).map((key) => (
            <Style
              key={`coach-style-${key}`}
              isSelected={coachProfile.styles.includes(key)}
              styleKey={key}
              onClick={(selected) => onStyleClick(selected, key)}
            />
          ))}
        </div>
      </Grid>
    </Grid>
  );
};

export default CoachingStyle;
