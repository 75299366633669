import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import {
  Avatar,
  Button,
  Divider,
  Menu,
  SideBarAvatarMenuItem,
  Typography,
} from '@/ui';

import type { User } from '@/types';
import type { FC, SetStateAction } from 'react';

interface SideBarAvatarMenuProps {
  onAccountSettings: () => void;
  onFeedBack: () => void;
  onSignOut: () => void;
  user: User;
}

const SideBarAvatarMenu: FC<SideBarAvatarMenuProps> = ({
  user = null,
  onSignOut,
  onFeedBack,
  onAccountSettings,
}) => {
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const isClient = !location.pathname.includes('coach');
  const path = isClient ? '/m' : '/coach';

  const onOpen = (event: {
    currentTarget: SetStateAction<HTMLElement | null>;
  }) => {
    setAnchorEl(event.currentTarget);
  };
  const onClose = () => {
    setAnchorEl(null);
  };

  if (!user) return null;

  return (
    <>
      <Avatar
        className="cursor-pointer ring-2 ring-white"
        firstName={user.firstName}
        lastName={user.lastName}
        loading={!user}
        src={user.avatarUrl ?? ''}
        onClick={onOpen}
      />
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        className="ml-4"
        id="avatar-menu"
        open={!!anchorEl}
        transformOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
        onClose={onClose}
      >
        <div className="flex w-64 flex-col items-center border-0 border-b border-solid border-cement-100 pb-4 pt-2">
          <Avatar
            firstName={user.firstName}
            lastName={user.lastName}
            size="l"
            src={user.avatarUrl ?? ''}
          />
          <Typography variant="subtitle1">{user.firstName}</Typography>
          <Typography variant="body2">{user.email}</Typography>
          <Link className="mt-4" to={`${path}/profile`}>
            <Button color="secondary" variant="contained" onClick={onClose}>
              View or edit profile
            </Button>
          </Link>
        </div>
        <SideBarAvatarMenuItem
          icon="settings"
          label="Account preferences"
          onClick={onAccountSettings}
        />
        <SideBarAvatarMenuItem
          icon="feedback"
          label="Share feedback"
          onClick={onFeedBack}
        />
        <SideBarAvatarMenuItem
          icon="assignment"
          label="Welcome guide"
          route={`${path}/welcome`}
          onClick={onClose}
        />
        {!isClient && (
          <SideBarAvatarMenuItem
            icon="home_repair_service"
            label="Coach toolkit"
            route={`${path}/toolkit`}
            onClick={onClose}
          />
        )}
        {isClient && (
          <SideBarAvatarMenuItem
            icon="support"
            label="FAQs"
            route={`${path}/support`}
            onClick={onClose}
          />
        )}
        <Divider />
        <SideBarAvatarMenuItem
          icon="logout"
          label="Sign out"
          onClick={onSignOut}
        />
      </Menu>
    </>
  );
};

export default SideBarAvatarMenu;
