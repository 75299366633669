import { THREE, ZERO } from '@/constants';
import { Skeleton } from '@/ui';

const Loading = () => (
  <div className="grid grid-cols-3 gap-4 px-32 pt-32">
    {Array(THREE)
      .fill(ZERO)
      .map((_element, index) => (
        <Skeleton
          /* eslint-disable-next-line react/no-array-index-key -- Dumb component. Doesn't matter as this array will never change. */
          key={index}
          animation="wave"
          className="rounded-xl"
          height={187}
          variant="rectangular"
        />
      ))}
  </div>
);

export default Loading;
