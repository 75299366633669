import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useCurrentUser } from '@/hooks';

const Root = () => {
  const { user, isUserLoading } = useCurrentUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (isUserLoading) return;

    if (user.isCoach) navigate('/coach', { replace: true });
    else navigate('/m', { replace: true });
  }, [user.id, isUserLoading]);

  return null;
};

export default Root;
