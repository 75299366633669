import { ZapTypes } from '@/types/zapier';

export const zapIds = {
  [ZapTypes.BUSINESS_STARTED_QUIZ]: {
    production: 'barukpv',
    stage: '3wpiwl7',
  },
  [ZapTypes.USER_MATCHING_PREFERENCE]: {
    production: 'beznkkj',
    stage: '3wpsmb5',
  },
  [ZapTypes.USER_PAID_MEMBERSHIP]: {
    production: 'b9h6uph',
    stage: '3wpsru5',
  },
  [ZapTypes.USER_SCHEDULED_MATCHING_CALL_WITH_MENTO]: {
    production: 'beznd2m',
    stage: '3wpsfmy',
  },
  [ZapTypes.USER_STARTED_QUIZ]: {
    production: 'baruul9',
    stage: '3wpsyt8',
  },
};
