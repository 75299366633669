import { useQuery } from '@tanstack/react-query';

import { getCompany, getCompanyMembers, getCompanySessions } from '@/api';
import { QUERY_KEY_COMPANY } from '@/constants';

import type { Company } from '@/types';

// TODO: Make this consistent with other hooks
export const useCompany = (companyId: string) => {
  const {
    data: companyData,
    isLoading: isCompanyLoading,
    refetch: refetchCompany,
  } = useQuery<Company>(
    [QUERY_KEY_COMPANY, companyId],
    () => getCompany(companyId),
    {
      enabled: !!companyId,
    },
  );

  return {
    companyData,
    isCompanyLoading,
    refetchCompany,
  };
};

export const useCompanyMembers = (companyId: string, isActive = true) =>
  useQuery({
    enabled: !!companyId,
    queryFn: () => getCompanyMembers(companyId, isActive),
    queryKey: [
      QUERY_KEY_COMPANY,
      companyId,
      isActive ? 'current' : 'past',
      'members',
    ],
  });

export const useCompanySessions = (companyId: string) =>
  useQuery({
    enabled: !!companyId,
    queryFn: () => getCompanySessions(companyId),
    queryKey: [QUERY_KEY_COMPANY, companyId, 'sessions'],
  });
