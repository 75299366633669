import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { ONE, ZERO } from '@/constants';
import { useCurrentUser, useGetGrowthPlans, useSelectedUserId } from '@/hooks';
import { Tab, Tabs, Typography } from '@/ui';

import type { GrowthPlan } from '@/types';
import type { SyntheticEvent } from 'react';

import Dashboard from '@/components/Growth/Dashboard';

const Loader = () => {
  const { user } = useCurrentUser();
  const selectedUserId = useSelectedUserId();
  const { growthPlanId = '', teamId = '', id: memberId = '' } = useParams();
  const { data: plans, isLoading: isPlansLoading } = useGetGrowthPlans({
    userId: memberId || selectedUserId || user.id,
  });
  const [selectedPlan, setSelectedPlan] = useState<GrowthPlan>();
  const [tab, setTab] = useState(ZERO);
  const navigate = useNavigate();

  // check if there is an id on the url route, and pick that plan to show
  useEffect(() => {
    if (growthPlanId !== '' && plans) {
      const found = plans.findIndex(({ id }) => id === growthPlanId);
      setSelectedPlan(plans[found]);
      setTab(found);
    } else {
      setSelectedPlan(plans?.[0]);
    }
  }, [growthPlanId, plans]);

  const onClick = (path: string) => () => {
    navigate(path);
  };

  const onChange = (_: SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  if (isPlansLoading || !selectedPlan) return null;

  return !selectedUserId && plans?.length && plans.length > ONE ? (
    <div className="bg-white">
      <Typography className="ml-8 pb-4 pt-10" variant="h4">
        Growth Plan
      </Typography>
      {/* if there's a teamid on the params, this means its from mento for teams view, so hide tabs  */}
      {!teamId && (
        <Tabs
          value={tab}
          variant="scrollable"
          allowScrollButtonsMobile
          scrollButtons
          onChange={onChange}
        >
          {plans.map((plan) => (
            <Tab
              key={plan.id}
              label={plan.team?.name ?? 'Career Coaching'}
              onClick={onClick(`/m/growth/${plan.id}`)}
            />
          ))}
        </Tabs>
      )}
      <Dashboard />
    </div>
  ) : (
    <Dashboard />
  );
};

export default Loader;
