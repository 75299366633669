import { Button, Dialog, DialogActions, DialogContent, Typography } from '@/ui';

import type { FC } from 'react';

interface CongratulationsModalProps {
  setStep: () => void;
}
const CongratulationsModal: FC<CongratulationsModalProps> = ({ setStep }) => (
  <Dialog open>
    <DialogContent className="px-8 pb-8 md:px-12 md:pb-12">
      <Typography className="mb-4" variant="h5">
        Congratulations, you’re ready to begin your journey! 🎉
      </Typography>
      <Typography className="mb-4">
        Please hang tight while we match you with the right coach, which
        typically takes about 2-3 days. In the meantime, take some time to get
        acquainted with the platform.
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button variant="outlined" onClick={setStep}>
        Close
      </Button>
    </DialogActions>
  </Dialog>
);
export default CongratulationsModal;
