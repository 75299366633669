import clsx from 'clsx';

import { STYLE_OPTIONS } from '@/constants';
import { Card, CardContent, Icon, Typography } from '@/ui';

import type { CoachStyle } from '@/types';
import type { FC, ReactNode } from 'react';

const Style: FC<{
  isSelected?: boolean;
  onClick?: (selected: boolean) => void;
  styleKey: string;
}> = ({ styleKey = '', isSelected = false, onClick = undefined }) => {
  const option = STYLE_OPTIONS[styleKey] || ({} as CoachStyle);

  const title = option.title || '';
  const description = option.description || '';

  let icon: ReactNode;
  switch (styleKey) {
    case 'WELLBEING':
      icon = <Icon>favorite</Icon>;
      break;
    case 'FUNCTIONAL':
      icon = <Icon>filter_tilt_shift</Icon>;
      break;
    case 'MANAGEMENT':
      icon = <Icon>person</Icon>;
      break;
    case 'ENTREPRENEUR':
      icon = <Icon>rowing</Icon>;
      break;
    default:
      return null;
  }

  const onSelected = () => onClick?.(isSelected);
  const hasOnClick = Boolean(onClick);

  return (
    <Card
      className={clsx('group', {
        'bg-cement-200 text-cement-400': hasOnClick && !isSelected,
        'bg-sage-500 text-white':
          !hasOnClick || (Boolean(onClick) && isSelected), // TODO: Refactor this logic. It's just... weird.
        'hover:bg-sage-400 hover:text-sage-600': onClick,
      })}
      elevation={0}
      onClick={onSelected}
    >
      <CardContent>
        <div className="flex justify-between">
          {Boolean(icon) && (
            <div
              className={clsx(
                'mb-4 flex h-12 w-12 items-center justify-center rounded-full',
                {
                  'bg-cement-300 text-white': hasOnClick && !isSelected,
                  'bg-sage-600': !hasOnClick || true,
                  'group-hover:bg-sage-500 group-hover:border-0 group-hover:text-white':
                    onClick,
                },
              )}
            >
              {icon}
            </div>
          )}
          <div
            className={clsx(
              '-mr-2 -mt-2 mb-4 flex h-6 w-6 items-center justify-center rounded-full',
              {
                'bg-white': isSelected,
                'border-2 border-cement-400': !isSelected,
              },
            )}
          >
            {isSelected ? <Icon className=" text-sage-600">check</Icon> : null}
          </div>
        </div>
        <Typography variant="subtitle1">{title}</Typography>
        <Typography variant="body2">{description}</Typography>
      </CardContent>
    </Card>
  );
};

export default Style;
