import { useQueryClient } from '@tanstack/react-query';

import { QUERY_KEY_TEMPLATES } from '@/constants';
import { useDocument, usePatchDocument } from '@/hooks';
import { Button } from '@/ui';

const Publish = () => {
  const queryClient = useQueryClient();
  const { currentDocument, isDocumentLoading, refetchDocument } =
    useDocument(true);

  const invalidate = async () => {
    await refetchDocument();
    await queryClient.invalidateQueries([QUERY_KEY_TEMPLATES]);
  };

  const patchDocument = usePatchDocument();

  const onClick = () => {
    patchDocument.mutate(
      { draft: false, id: currentDocument.id },
      { onSuccess: invalidate },
    );
  };

  if (isDocumentLoading || !currentDocument.draft) return null;

  return (
    <Button variant="outlined" onClick={onClick}>
      Publish Draft
    </Button>
  );
};

export default Publish;
