import { useNavigate } from 'react-router-dom';

import { DEFAULT_STARTERS, ENTER, STARTERS_DISPLAYED, ZERO } from '@/constants';
import { useCreateMessageThread, useCurrentUser } from '@/hooks';
import { WORKFLOW_CATEGORY } from '@/types';
import { Typography } from '@/ui';
import { track } from '@/utils';

import type { MessageThread } from '@/types';
import type { KeyboardEvent } from 'react';

const Starters = () => {
  const createMessageThread = useCreateMessageThread();
  const navigate = useNavigate();
  const { user } = useCurrentUser();

  const starters = user.starters
    .concat(DEFAULT_STARTERS)
    .slice(ZERO, STARTERS_DISPLAYED);

  const onClick = (content: string, order: number) => () => {
    const onSuccess = (thread: MessageThread) => {
      track('Conversation starter clicked', { content, order });
      navigate(`/m/workflow/${thread.id}`);
    };

    createMessageThread.mutate(
      {
        content,
        workflow: WORKFLOW_CATEGORY.GROW,
      },
      { onSuccess },
    );
  };

  const onKeyDown =
    (content: string, index: number) => (event: KeyboardEvent<HTMLElement>) => {
      if (event.key === ENTER) {
        onClick(content, index)();
      }
    };

  const navigateToWorkflows = () => navigate('/m/workflow');

  return (
    <div className="mt-4 flex gap-2">
      <div className="flex gap-2 text-sage-200 opacity-65">
        {starters.map((text, index) => (
          <div
            key={`dashboard-starters-${text}`}
            className="w-1/3 cursor-pointer rounded-xl border border-solid border-cement-200 bg-cement-500 p-3 text-sage-200"
            role="button"
            tabIndex={0}
            onClick={onClick(text, index)}
            onKeyDown={onKeyDown(text, index)}
          >
            <Typography className="line-clamp-2" variant="caption">
              {text}
            </Typography>
          </div>
        ))}
      </div>
      <Typography
        className="min-w-20 cursor-pointer rounded-xl border border-solid border-cement-200 bg-cement-500 p-3 text-sage-200 opacity-65"
        variant="caption"
        onClick={navigateToWorkflows}
      >
        View past chats -&gt;
      </Typography>
    </div>
  );
};

export default Starters;
