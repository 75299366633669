import { useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { TAB_OPTIONS, ZERO } from '@/constants';
import { useCurrentUser, useView } from '@/hooks';
import { Avatar, Icon, IconButton, Tab, Tabs, Typography } from '@/ui';
import { formatTimezone } from '@/utils';

import type { SyntheticEvent } from 'react';

import EditClient from '@/components/Profile/Client/Edit/Client';
import ClientHeaderSkeleton from '@/pages/Member/ClientHeaderSkeleton';

const Member = () => {
  const [isEditing, setIsEditing] = useState(false);
  const { clientId, selectedUser: client, isViewLoading } = useView();
  const { user } = useCurrentUser();

  const navigate = useNavigate();
  const location = useLocation();

  const selectedIndex = Math.max(
    ZERO,
    TAB_OPTIONS.findIndex(({ route }) => {
      if (!route) return false;
      return location.pathname.includes(route);
    }),
  );

  const onChangeTab = (_: SyntheticEvent, value: number) => {
    const option = TAB_OPTIONS[value];

    if (option) {
      navigate(`/coach/members/${clientId}/${option.route}`);
    }
  };

  const onEdit = () => setIsEditing(true);
  const onCloseEdit = () => setIsEditing(false);

  if (isViewLoading) {
    return <ClientHeaderSkeleton />;
  }

  const timezone = formatTimezone(client.profile?.timezone ?? '');

  return (
    <>
      <div className="flex w-full flex-col border-0 border-b border-solid border-b-cement-200 bg-white pt-6">
        <div className="container mx-auto flex max-w-screen-lg px-2 lg:px-0">
          <div className="flex w-full p-4">
            <Avatar
              firstName={client.firstName}
              lastName={client.lastName}
              size="l"
              src={client.avatarUrl ?? ''}
            />
            <div className="flex w-full items-center justify-between">
              <div className="flex flex-col justify-center p-0 px-2">
                <div className="flex items-baseline">
                  <Typography className="mx-4" variant="h4">
                    {client.fullName}
                  </Typography>
                  {!!client.profile?.pronouns && (
                    <Typography color="text.secondary" variant="subtitle2">
                      {client.profile.pronouns}
                    </Typography>
                  )}
                  {user.superAdmin && (
                    <IconButton
                      className="ml-2"
                      size="medium"
                      variant="contained"
                      light
                      onClick={onEdit}
                    >
                      <Icon>edit</Icon>
                    </IconButton>
                  )}
                </div>
                <Typography className="mx-4 text-cement-400">
                  {!!client.profile?.title && `${client.profile.title}, `}
                  {client.currentCompany?.name ?? client.profile?.company}
                </Typography>
              </div>
              {!!timezone && (
                <Typography
                  className="hidden items-center gap-2 align-middle md:flex"
                  color="text.secondary"
                  variant="body1"
                >
                  <Icon>schedule</Icon>
                  {timezone}
                </Typography>
              )}
            </div>
          </div>
        </div>
        <div className="container mx-auto flex max-w-screen-lg items-start">
          <Tabs
            className="border-0"
            value={selectedIndex}
            variant="scrollable"
            allowScrollButtonsMobile
            scrollButtons
            onChange={onChangeTab}
          >
            {TAB_OPTIONS.map(({ name }) => (
              <Tab key={`client-tabs-${name}`} color="cement" label={name} />
            ))}
          </Tabs>
        </div>
      </div>
      {isEditing && <EditClient client={client} onClose={onCloseEdit} />}
      <Outlet />
    </>
  );
};

export default Member;
