import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';

import { Icon, MenuItem, Typography } from '@/ui';

import type { FC } from 'react';

interface BottomBarMenuItemProps {
  icon: string;
  label: string;
  onClick?: () => void;
  route?: string;
}

const BottomBarMenuItem: FC<BottomBarMenuItemProps> = ({
  icon = '',
  label = '',
  route = '',
  onClick = undefined,
}) => {
  const navigation = useNavigate();
  const onPreClick = () => {
    onClick?.();

    if (route) {
      navigation(route);
    }
  };

  return (
    <MenuItem
      className="flex items-center justify-center text-cement-500"
      onClick={onPreClick}
    >
      <div className={clsx('flex flex-col items-center rounded-2xl p-2')}>
        <Icon>{icon}</Icon>
        <Typography className="px-1 text-center" variant="subtitle2">
          {label}
        </Typography>
      </div>
    </MenuItem>
  );
};

export default BottomBarMenuItem;
