import { Card, Typography } from '@/ui';

import emptySteps from './empty-steps.png';

import type { FC } from 'react';

const Empty: FC = () => (
  <Card
    className="flex flex-col items-center justify-center p-20 align-middle"
    variant="outlined"
  >
    <img alt="" className="mb-8 basis-0" src={emptySteps} />
    <Typography
      className="max-w-76 text-center"
      color="text.secondary"
      variant="caption"
    >
      Steps are actions that bring you closer to completing your milestones and
      achieving your vision.
    </Typography>
  </Card>
);

export default Empty;
