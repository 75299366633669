import { captureException } from '@sentry/react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { createCoach } from '@/api';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  Typography,
} from '@/ui';

import type { ChangeEvent, FC } from 'react';

interface ModalProps {
  onClose: () => void;
}

// TODO: Use react-hook-form to simplify
const Modal: FC<ModalProps> = ({ onClose }) => {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const onFirstNameChange = (e: ChangeEvent<HTMLInputElement>) =>
    setFirstName(e.target.value.trim());
  const onLastNameChange = (e: ChangeEvent<HTMLInputElement>) =>
    setLastName(e.target.value);
  const onEmailChange = (e: ChangeEvent<HTMLInputElement>) =>
    setEmail(e.target.value);

  const onCreate = async () => {
    try {
      setLoading(true);
      const coach = await createCoach({ email, firstName, lastName });
      navigate(`/coach/manage/coaches/${coach.userId}`);
    } catch (e) {
      captureException(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      aria-describedby="modal-description"
      aria-labelledby="modal-title"
      maxWidth="md"
      fullWidth
      open
      onClose={onClose}
    >
      <DialogContent>
        <Grid spacing={4} container>
          <Grid md={5} xs={12} item>
            <Typography variant="h4">Create a new coach</Typography>
          </Grid>
          <Grid className="flex flex-col gap-4" md={7} xs={12} item>
            <TextField
              label="First name"
              value={firstName}
              variant="outlined"
              onChange={onFirstNameChange}
            />
            <TextField
              label="Last name"
              value={lastName}
              variant="outlined"
              onChange={onLastNameChange}
            />
            <TextField
              label="Email"
              value={email}
              variant="outlined"
              onChange={onEmailChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          disabled={loading}
          variant="outlined"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          color="secondary"
          disabled={!firstName.length && !email.length}
          loading={loading}
          variant="contained"
          onClick={onCreate}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Modal;
