export interface FunctionDisplay {
  color: FunctionDisplayColors;
  functionId: string;
  icon: string;
  id: string;
  messageType: string;
  title: string;
}

export enum FunctionDisplayColors {
  bloom = 'bloom',
  cement = 'cement',
  fire = 'fire',
  ocean = 'ocean',
  poppy = 'poppy',
  sage = 'sage',
  sky = 'sky',
  sunny = 'sunny',
}
