import { useQuery } from '@tanstack/react-query';

import { getSubscriptionDetails } from '@/api';

export const useGetSubscriptionDetails = (subId: string | undefined) =>
  useQuery<unknown, Error>({
    enabled: !!subId,
    // only triggers if enabled. to be enabled it requires an id.
    queryFn: () => getSubscriptionDetails(subId as string),
    queryKey: ['subscription-details', subId],
  });
