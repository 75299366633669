import type { Client } from './client';
import type { Coach } from './coach';
import type { Step } from './step';

export interface Boulder {
  client?: Client;
  clientId?: string;
  coach?: Coach;
  coachId?: string;
  completedAt: string; // Timestamp
  createdAt: string; // Timestamp
  description?: string;
  id: string;
  isComplete: boolean;
  order: number;
  steps?: Step[];
  title: string;
  updatedAt: string; // Timestamp
}

export enum BoulderColor {
  BLOOM = 'bloom',
  OCEAN = 'ocean',
  POPPY = 'poppy',
  SAGE = 'sage',
  SKY = 'sky',
  SUNNY = 'sunny',
}
