import MuiTypography from '@mui/material/Typography';
import { clsx } from 'clsx';

import type { TypographyProps } from '@mui/material/Typography';
import type { FC } from 'react';

const Typography: FC<TypographyProps> = ({ className, ...rest }) => {
  const classes = clsx('mnt-typography', className);

  return <MuiTypography {...rest} className={classes} />;
};
Typography.displayName = 'Typography';

export default Typography;
export type { TypographyProps };
