import { FAQS } from '@/constants';
import { Typography } from '@/ui';

import Row from './Row';

import type { FC } from 'react';

const Faqs: FC = () => (
  <>
    <Typography className="my-8 text-center" variant="h3">
      Frequently asked questions
    </Typography>
    <div className="mx-auto flex w-full max-w-screen-md flex-col">
      {FAQS.map((faq) => (
        <Row key={`membership-faq-${faq.header}`} {...faq} />
      ))}
    </div>
  </>
);

export default Faqs;
