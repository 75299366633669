import { useQueryClient } from '@tanstack/react-query';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { createTeam } from '@/api';
import { ZERO } from '@/constants';
import { AppContext } from '@/context';
import { AppMessageSeverities } from '@/types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@/ui';

import type { FC, SetStateAction } from 'react';

interface NewTeamProps {
  onClose: () => void;
}

const NewTeam: FC<NewTeamProps> = ({ onClose }) => {
  const navigate = useNavigate();
  const { setAppMessage } = useContext(AppContext);
  const [name, setName] = useState('');
  const [saving, setSaving] = useState(false);
  const queryClient = useQueryClient();

  const handleInputChange = (e: {
    target: { value: SetStateAction<string> };
  }) => {
    setName(e.target.value);
  };

  const handleOnCreateClick = async () => {
    try {
      setSaving(true);
      const data = await createTeam(name, true);
      setName('');
      setSaving(false);
      onClose();
      await queryClient.invalidateQueries(['teams']);
      navigate(`/teams/${data.id}`);
    } catch (error) {
      setAppMessage({
        message: 'Error creating a team',
        open: true,
        severity: AppMessageSeverities.Error,
      });
      console.error('Error creating a time!!', error);
    } finally {
      setSaving(false);
    }
  };

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>Create a team</DialogTitle>
      <DialogContent>
        <Typography>
          Here's to a beginning of a beautiful collaboration
        </Typography>
        <TextField
          className="mt-6"
          inputProps={{ maxLength: 80 }}
          label="Team name"
          placeholder="Team name"
          value={name}
          fullWidth
          onChange={handleInputChange}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={saving} variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={name.length === ZERO}
          loading={saving}
          variant="contained"
          onClick={handleOnCreateClick}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default NewTeam;
