import { useCurrentUser } from '@/hooks';
import { Link, Typography } from '@/ui';

const MembershipExpired = () => {
  const { user, isUserLoading } = useCurrentUser();

  if (isUserLoading) {
    return null;
  }

  return (
    <main className="flex w-full flex-1 flex-col items-center justify-center">
      <div className="p-8">
        <div className="flex shrink-0 justify-center">
          <img
            alt="logo"
            height="48"
            src="https://mento-space.nyc3.digitaloceanspaces.com/logo.svg"
            width="180"
          />
        </div>
        <div className="py-8">
          <div className="text-center">
            <Typography
              className="mt-2 text-4xl tracking-tight text-gray-900"
              variant="h5"
            >
              Subscription is unpaid or deactivated!
            </Typography>
            <Typography className="mt-2 text-gray-500">
              Please contact support at
            </Typography>
            <div className="mt-4">
              <Link
                className="cursor-pointer pt-1 text-sm text-black hover:font-bold"
                href={`mailto:concierge@mento.co?subject=${user.email} account issues!`}
              >
                concierge@mento.co
              </Link>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default MembershipExpired;
