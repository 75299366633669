import mentoApi from './mentoApi';

import type { FunctionDisplay } from '@/types';

export const createFunctionDisplay = async (data: Partial<FunctionDisplay>) =>
  (await mentoApi.post('/function_display', data)).data;

export const updateFunctionDisplay = async (data: Partial<FunctionDisplay>) => {
  const { id, ...rest } = data;
  return (await mentoApi.put(`/function_display/${id}`, { ...rest })).data;
};

export const getFunctionDisplay = async (id: string) =>
  (await mentoApi.get(`/function_display/${id}`)).data;

export const deleteFunctionDisplay = async (id: string) =>
  (await mentoApi.delete(`/function_display/${id}`)).data;
