import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useCurrentUser, useDocument, useView } from '@/hooks';
import { Button, Icon, IconButton, Paper, Typography } from '@/ui';
import { toDocumentUrl } from '@/utils';

import EditButton from './Edit';
import Ellipsis from './Ellipsis';
import Iframe from './Iframe';
import Publish from './Publish';

import CreateDialog from '@/components/Document/Create/Dialog';

const Document = () => {
  const [errorLoadingDocument, setErrorLoadingDocument] = useState(false);
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const navigate = useNavigate();
  const { user, isUserLoading } = useCurrentUser();
  const { clientId, isCoach } = useView();

  const { currentDocument, isDocumentLoading } = useDocument(true);

  const onBack = () => {
    if (clientId) navigate(`/coach/members/${clientId}/documents`);
    else if (isCoach) navigate('/coach/documents/templates');
    else navigate('/documents/templates');
  };

  const onUseTemplate = () => setIsCreateOpen(true);
  const onCloseUseTemplate = () => setIsCreateOpen(false);

  if (isUserLoading || isDocumentLoading) return null;

  const url = toDocumentUrl(
    currentDocument.mimeType,
    currentDocument.reference,
    true,
  );

  return (
    <>
      <div className="bg-white p-4">
        <div className="flex w-full flex-1 items-center justify-between">
          <div className="flex flex-1 gap-2">
            <IconButton color="primary" onClick={onBack}>
              <Icon>chevron_left</Icon>
            </IconButton>
            <Typography
              className="max-w-84 text-ellipsis sm:max-w-100 md:max-w-180"
              variant="h5"
              noWrap
            >
              {currentDocument.name}
            </Typography>
          </div>
          {!errorLoadingDocument && (
            <div className="mt-2 hidden justify-end gap-4 md:mt-0 md:flex">
              <Publish />
              {!currentDocument.draft && (
                <Button
                  disabled={user.hasMaxDocuments}
                  startIcon={<Icon>edit</Icon>}
                  variant="contained"
                  onClick={onUseTemplate}
                >
                  Use template
                </Button>
              )}
              {user.superAdmin && (
                <EditButton
                  referenceMimeType={currentDocument.mimeType}
                  url={toDocumentUrl(
                    currentDocument.mimeType,
                    currentDocument.reference,
                  )}
                />
              )}
              <Ellipsis document={currentDocument} />
            </div>
          )}
        </div>
      </div>
      <Iframe
        setErrorLoadingDocument={setErrorLoadingDocument}
        src={url}
        title={currentDocument.name}
      />
      {!errorLoadingDocument && (
        <Paper
          className="fixed inset-x-0 bottom-0 mb-16 flex h-18 items-center justify-between gap-4 p-4 px-8 sm:pl-24 md:hidden"
          elevation={0}
        >
          <div className="flex gap-4 pr-4">
            <Publish />
            {user.superAdmin && (
              <EditButton
                referenceMimeType={currentDocument.mimeType}
                url={toDocumentUrl(
                  currentDocument.mimeType,
                  currentDocument.reference,
                )}
              />
            )}
            {!currentDocument.draft && (
              <Button
                disabled={user.hasMaxDocuments}
                startIcon={<Icon>edit</Icon>}
                variant="contained"
                onClick={onUseTemplate}
              >
                Use template
              </Button>
            )}
          </div>
          <Ellipsis className="mr-14" document={currentDocument} />
        </Paper>
      )}
      <CreateDialog
        close={onCloseUseTemplate}
        draft={currentDocument.draft}
        isOpen={isCreateOpen}
        isTemplate={false}
        mimeType={currentDocument.mimeType}
        name={currentDocument.name}
        reference={currentDocument.reference}
      />
    </>
  );
};

export default Document;
