import { captureException } from '@sentry/react';
import { useState } from 'react';

import { updateUser } from '@/api';
import { SMALL_BREAKPOINT } from '@/constants';
import { useCurrentUser } from '@/hooks';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  useMediaQuery,
} from '@/ui';

import { isEmailAddressRepeted } from '../utils';

import type { ChangeEvent, FC } from 'react';

interface ConfirmModalProps {
  nextStep: () => void;
  previousStep: () => void;
}

const ConfirmModal: FC<ConfirmModalProps> = ({ nextStep, previousStep }) => {
  const { user } = useCurrentUser();
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState('');
  const [email, setEmail] = useState('');
  const [usedEmail, setUsedEmail] = useState('');
  const isSmall = useMediaQuery(SMALL_BREAKPOINT);

  const onClick = async () => {
    try {
      const emailToUse = email.trim() !== '' ? email : user.email;
      setUsedEmail(emailToUse);
      setError('');
      setSaving(true);
      await updateUser(user.id, { documentsEmail: emailToUse });
      nextStep();
    } catch (err) {
      if (isEmailAddressRepeted(err)) {
        setError('is already in use. Please enter another email address.');
      } else {
        setError(
          'could not be verified as a Google Account. Please try verifying again or go back and retry the step.',
        );
      }
      captureException(error);
    } finally {
      setSaving(false);
    }
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleBackClick = () => previousStep();

  return (
    <Dialog open>
      <DialogTitle className="px-8 pb-0 pt-4 md:px-12 md:pt-8">
        <Typography variant="overline">Confirm</Typography>
      </DialogTitle>
      <DialogContent className="px-8 pb-8 md:px-12 md:pb-12">
        <Typography className="mb-4" variant="h5">
          Did you connect {user.email} with a Google Account?
        </Typography>
        <Typography className="mb-4">
          If you did not use{' '}
          <Typography className="inline" variant="subtitle1">
            {user.email}
          </Typography>
          , please enter the associated email address below.
        </Typography>
        <TextField
          label="Enter your Google email address"
          value={email}
          fullWidth
          onChange={handleEmailChange}
        />
        {!!error && (
          <Typography className="mt-4 text-fire-500">
            <Typography className="inline" variant="subtitle1">
              {usedEmail}
            </Typography>
            &nbsp;{error}
          </Typography>
        )}
      </DialogContent>
      <DialogActions className="flex-col gap-2 px-8 sm:flex-row md:px-12">
        <Button
          fullWidth={isSmall}
          loading={saving}
          variant="outlined"
          onClick={handleBackClick}
        >
          Back
        </Button>
        <Button
          color="primary"
          fullWidth={isSmall}
          loading={saving}
          variant="contained"
          onClick={onClick}
        >
          Yes, it’s connected with Google
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmModal;
