import { type FC, useState } from 'react';

import { MAX_BOULDER_STEPS } from '@/constants';
import { useGrowthPlanSuggestedSteps } from '@/hooks';
import { Typography } from '@/ui';
import { getStepData } from '@/utils';

import Suggestion from '../Suggestion/Suggestion';

import type { Milestone } from '@/types';

interface SuggestionsProps {
  color: string;
  milestone: Milestone;
}

const Suggestions: FC<SuggestionsProps> = ({ milestone, color }) => {
  const [hasSuggestionRefreshing, setHasSuggestionRefreshing] = useState(false);

  const textColor =
    color === 'sunny' ? `text-${color}-600` : `text-${color}-500`;
  const { allStepsComplete, totalSteps } = getStepData(milestone.steps);

  const isMilestoneComplete =
    allStepsComplete && !!totalSteps && !!milestone.completedAt;

  const hasMaxBoulderSteps = milestone.steps.length >= MAX_BOULDER_STEPS;

  const { isSuggestedStepsLoading, suggestions } = useGrowthPlanSuggestedSteps(
    milestone.id,
  );

  if ((isMilestoneComplete && !!milestone.completedAt) || hasMaxBoulderSteps) {
    return null;
  }
  return (
    <div className="ml-0 sm:ml-9">
      <Typography className={`mt-4 ${textColor}`} variant="subtitle1">
        Suggested steps
      </Typography>
      <Typography className="mt-2" color="text.secondary" variant="body2">
        Based on your profile and milestone title. Add a suggestion to your plan
        or refresh
      </Typography>
      <Typography color="text.secondary" variant="body2">
        to see more suggestions - you can always edit after adding.
      </Typography>

      <div className="mt-6 flex flex-col items-center justify-between gap-2 ">
        {isSuggestedStepsLoading ? (
          <>
            <Suggestion
              color={color}
              index={0}
              milestoneId=""
              suggestion=""
              loading
            />
            <Suggestion
              color={color}
              index={1}
              milestoneId=""
              suggestion=""
              loading
            />
            <Suggestion
              color={color}
              index={2}
              milestoneId=""
              suggestion=""
              loading
            />
          </>
        ) : (
          <>
            {suggestions.map((suggestion, index) => (
              <Suggestion
                // eslint-disable-next-line react/no-array-index-key -- We're iterating an array of strings.
                key={`${index}-${suggestion}`}
                color={color}
                hasSuggestionRefreshing={hasSuggestionRefreshing}
                index={index}
                milestoneId={milestone.id}
                setHasSuggestionRefreshing={setHasSuggestionRefreshing}
                suggestion={suggestion}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default Suggestions;
