import clsx from 'clsx';

import type { StepData } from '@/types';
import type { FC } from 'react';

interface NavbarProps {
  current: number;
  stepData: StepData[];
}

const Navbar: FC<NavbarProps> = ({ current, stepData }) => (
  <div className="hidden grid-cols-4 gap-2 md:grid">
    {stepData.map(({ name }, index) => (
      <div
        key={`survey-builder-step-${name}`}
        className={clsx({
          'opacity-40': index > current,
        })}
      >
        <span className="body-sm select-none pb-1">{name}</span>
        <div className="h-1 w-full rounded-xl bg-black" />
      </div>
    ))}
  </div>
);

export default Navbar;
