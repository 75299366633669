import { useEffect, useRef } from 'react';
import { PopupModal } from 'react-calendly';

import { MEMBERSHIP_SELECT_URL } from '@/constants';

import type { FC } from 'react';

interface ModalProps {
  close: () => void;
  email: string;
  name: string;
  open: boolean;
}

const Modal: FC<ModalProps> = ({ close, email, name, open }) => {
  const rootElement = useRef<HTMLElement | null>(null);

  useEffect(() => {
    rootElement.current = document.getElementById('main');
  }, []);

  return (
    <PopupModal
      open={open}
      prefill={{ email, name }}
      rootElement={rootElement.current as HTMLElement}
      url={MEMBERSHIP_SELECT_URL}
      onModalClose={close}
    />
  );
};

export default Modal;
