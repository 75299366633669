import { OAuthProviders, OneTapPositions, Products } from '@stytch/vanilla-js';

import { MINUTES_IN_HALF_HOUR } from './datetime';

import type { IDictionary } from '@/types';
import type { StytchLoginConfig } from '@stytch/vanilla-js';

// 62 days in minutes
export const SESSION_DURATION = 89280;

export const STYTCH_STYLES = {
  buttons: {
    primary: {
      backgroundColor: '#637A6F',
      textColor: '#FFF',
      width: '100%',
    },
  },
  colors: {
    error: '#BC4636',
    primary: '#262626',
    secondary: '#262626',
    success: '#637A6F',
  },
  fontFamily: 'Basis Grotesque',
  logo: {
    logoImageUrl: 'https://mento-space.nyc3.digitaloceanspaces.com/logo.svg',
  },
};

export const STYTCH_CONFIG = (redirect: string | null): StytchLoginConfig => {
  const redirectUrl = `${import.meta.env.VITE_STYTCH_AUTHENTICATE_URL}${
    redirect ? `?redirect=${redirect}` : ''
  }`;

  // For some reason using the redirect in the reset url doesn't set all of the
  // proper cookies. I have no idea why and haven't been able to debug it -
  // configuring in stytch dashboard, making sure config is consistent, and everything
  // else I've tried had no effect.
  // There are some issues with the reset flow, like doing redirects, it might be due to
  // Stytch's component itself
  const resetUrl = import.meta.env.VITE_STYTCH_RESET_URL;

  return {
    emailMagicLinksOptions: {
      loginExpirationMinutes: MINUTES_IN_HALF_HOUR,
      loginRedirectURL: redirectUrl,
      signupExpirationMinutes: MINUTES_IN_HALF_HOUR,
      signupRedirectURL: redirectUrl,
    },
    oauthOptions: {
      loginRedirectURL: redirectUrl,
      providers: [
        {
          one_tap: true,
          position: OneTapPositions.embedded,
          type: OAuthProviders.Google,
        },
      ],
      signupRedirectURL: redirectUrl,
    },
    passwordOptions: {
      loginExpirationMinutes: MINUTES_IN_HALF_HOUR,
      loginRedirectURL: redirectUrl,
      resetPasswordExpirationMinutes: MINUTES_IN_HALF_HOUR,
      resetPasswordRedirectURL: resetUrl,
    },
    // Order in array determines order in UI
    products: [Products.oauth, Products.emailMagicLinks, Products.passwords],
  };
};

export const TOKEN_TO_API: IDictionary = {
  magic_links: 'magicLinks',
  oauth: 'oauth',
  password_authenticate: 'session',
  password_create: 'session',
  reset_password: 'session',
};

export const AUTH_ERROR_MESSAGES: IDictionary = {
  failed: 'Failed to authenticate, please try again',
  misconfigured: 'Misconfigured Stytch',
  unable_to_auth_magic_link:
    'Magic link could not be authenticated because it was either already used or expired',
  unauthorized_credentials: 'Unauthorized credentials, please try again',
};
