import mentoApi from './mentoApi';

import type {
  MessageThread,
  MessageThreadMessage,
  PaginatedResponse,
} from '@/types';

export type MessageThreadApiOptions = {
  limit?: number;
  page?: number;
  userId?: string;
};

export const getMessageThread = async (
  params?: MessageThreadApiOptions,
): Promise<Awaited<PaginatedResponse<Record<string, MessageThread[]>>>> =>
  (await mentoApi.get('/message_thread', { params })).data;

export const postMessageThread = async (
  messageThread: Partial<MessageThread> & { workflow?: string },
): Promise<MessageThread> =>
  (await mentoApi.post('/message_thread', messageThread)).data;

export const deleteMessageThread = async (params: {
  messageThreadId: string;
}): Promise<void> =>
  (await mentoApi.delete(`/message_thread/${params.messageThreadId}`)).data;

export const getMessageThreadMessages = async (params: {
  limit?: number;
  messageThreadId: string;
  page?: number;
}): Promise<Awaited<PaginatedResponse<MessageThreadMessage>>> =>
  (await mentoApi.get('/messages', { params })).data;

export const postMessageThreadMessage = async (params: {
  content: string;
  messageThreadId: string;
}): Promise<Awaited<MessageThreadMessage>> =>
  (await mentoApi.post('/messages', params)).data;

export const confirmMessageThreadMessage = async (params: {
  confirmed: boolean;
  content: string;
  messageId: string;
}): Promise<Awaited<{}>> =>
  (await mentoApi.post(`/messages/${params.messageId}/confirm`, params)).data;

export const putMessageThreadMessage = async (params: {
  content: string;
  messageId: string;
}): Promise<Awaited<MessageThreadMessage>> =>
  (await mentoApi.put(`/messages/${params.messageId}`, params)).data;

export const deleteMessageThreadMessage = async (id: string) =>
  (await mentoApi.delete(`/messages/${id}`)).data;
