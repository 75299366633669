import { Typography } from '@/ui';
import { getGMTTimeZone } from '@/utils';

import type { UserProfile } from '@/types';
import type { FC } from 'react';

const TimeZone: FC<UserProfile> = ({ timezone }) => {
  const gmtTimeZone = getGMTTimeZone(timezone ?? '');

  return (
    <Typography className="block text-cement-400" variant="caption">
      {gmtTimeZone}
    </Typography>
  );
};

export default TimeZone;
