import { useQuery } from '@tanstack/react-query';

import { getTeam } from '@/api';

import type { Team } from '@/types/teams';
import type { AxiosError } from 'axios';

type UseTeamReturnType = {
  isTeamLoading: boolean;
  refetchTeam: () => void;
  team: Team;
  teamError: AxiosError | null;
};

export const useTeam = (
  id: string,
  action?: string | null,
): UseTeamReturnType => {
  const {
    data: team = {} as Team,
    isLoading: isTeamLoading,
    refetch: refetchTeam,
    error: teamError,
  } = useQuery<Team, AxiosError>(['team', id], () => getTeam(id), {
    enabled: !!id && !action,
    retry: false,
  });

  return {
    isTeamLoading,
    refetchTeam,
    team,
    teamError,
  };
};
