import { useQueryClient } from '@tanstack/react-query';
import { useContext, useState } from 'react';

import { addTeamMembers } from '@/api';
import { ONE, ZERO } from '@/constants';
import { AppContext } from '@/context';
import { AppMessageSeverities } from '@/types';
import {
  Autocomplete,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@/ui';

import type { Team } from '@/types/teams';
import type { FC, SyntheticEvent } from 'react';

import { useTeam } from '@/hooks/useTeam';

const EMAIL_LENGTH_THRESHOLD = 256;
const validateEmail = (email: string) =>
  email.length < EMAIL_LENGTH_THRESHOLD &&
  /^[^@]+@[^@]{2,}\.[^@]{2,}$/u.test(email);

interface AddMembersProps {
  onClose: () => void;
  team: Team;
}

const AddMembers: FC<AddMembersProps> = ({ team, onClose }) => {
  const queryClient = useQueryClient();
  const { refetchTeam } = useTeam(team.id);
  const { setAppMessage } = useContext(AppContext);
  const [emails, setEmails] = useState<string[]>([]);
  const [saving, setSaving] = useState(false);

  const handleOnAddClick = async () => {
    try {
      setSaving(true);
      await addTeamMembers(team.id, emails);
      await refetchTeam();
      queryClient.invalidateQueries(['teams']);
      onClose();
    } catch (error) {
      setAppMessage({
        message: 'Error adding members to team',
        open: true,
        severity: AppMessageSeverities.Error,
      });
      console.error('Error adding members to team!!', error);
    } finally {
      setSaving(false);
    }
  };

  const handleOnEmailChange = (
    _event: SyntheticEvent<Element, Event>,
    value: string[],
  ) => {
    if (value.length === ZERO) {
      setEmails([]);
      return;
    }

    const lastItem = value[value.length - ONE];
    const isEmail = validateEmail(lastItem as string);
    if (isEmail) {
      setEmails(value);
    } else {
      setAppMessage({
        message: 'Please enter a valid email address',
        open: true,
        severity: AppMessageSeverities.Error,
      });
    }
  };
  return (
    <Dialog maxWidth="sm" fullWidth open onClose={onClose}>
      <DialogTitle>Invite people to {team.name}</DialogTitle>
      <DialogContent>
        <Autocomplete
          className="mt-2"
          defaultValue={emails}
          options={[]}
          renderInput={(params) => (
            <TextField
              {...params}
              helperText="Add emails by pressing enter"
              label="Email"
              placeholder="Email"
            />
          )}
          renderTags={(value: readonly string[]) =>
            value.map((option: string) => <Chip key={option} label={option} />)
          }
          value={emails}
          freeSolo
          multiple
          onChange={handleOnEmailChange}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={saving} variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={emails.length === ZERO}
          loading={saving}
          variant="contained"
          onClick={handleOnAddClick}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default AddMembers;
