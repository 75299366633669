import { TableBody, TableCell, TableRow } from '@/ui';

import type { FC } from 'react';

interface EmptyProps {
  columns: number;
}

const Empty: FC<EmptyProps> = ({ columns }) => (
  <TableBody>
    <TableRow>
      <TableCell className="h-48 text-center" colSpan={columns}>
        No members found ...
      </TableCell>
    </TableRow>
  </TableBody>
);

export default Empty;
