import { useQueryClient } from '@tanstack/react-query';

import { QUERY_KEY_WORKFLOWS } from '@/constants';
import { useDeleteMessageThread } from '@/hooks';
import { Button, Dialog, DialogActions, DialogContent, Typography } from '@/ui';

import type { FC } from 'react';

type DeleteConfirmationProps = {
  isOpen: boolean;
  messageThreadId: string;
  onClose: () => void;
};

const DeleteConfirmation: FC<DeleteConfirmationProps> = ({
  isOpen = false,
  onClose,
  messageThreadId,
}) => {
  const deleteMsgThread = useDeleteMessageThread();
  const queryClient = useQueryClient();

  const onDelete = () => {
    deleteMsgThread.mutate(
      { messageThreadId },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: [QUERY_KEY_WORKFLOWS] });
        },
      },
    );
    onClose();
  };

  return (
    <Dialog maxWidth="sm" open={isOpen} onClose={onClose}>
      <DialogContent>
        <Typography variant="body1">
          Are you sure you want to delete this session?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={deleteMsgThread.isLoading}
          variant="outlined"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          className="ml-3"
          disabled={deleteMsgThread.isLoading}
          variant="contained"
          onClick={onDelete}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmation;
