import MuiCheckbox from '@mui/material/Checkbox';
import { clsx } from 'clsx';

import type { CheckboxProps } from '@mui/material/Checkbox';
import type { FC } from 'react';

const Checkbox: FC<CheckboxProps> = ({ className, ...rest }) => {
  const classes = clsx('mnt-checkbox', className);

  return <MuiCheckbox {...rest} className={classes} />;
};
Checkbox.displayName = 'Checkbox';

export default Checkbox;
export type { CheckboxProps };
