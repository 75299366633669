/**
 * IMPORTANT:
 * This file is purposefuly miss-named. It should be `clientEvent.ts`.
 * However, this name fails to load on vite for some reason.
 *
 * For whatever reason, any file prefixed with the word `client` fails
 * to load, so this is a weird thing I don't have time to check.
 *
 * Just know not to rename this to `clientEvent.ts`.
 * */
import mentoApi from './mentoApi';

import type { ClientEvent } from '@/types';

export const createClientEvent = async (clientEvent: Partial<ClientEvent>) =>
  (await mentoApi.post('/client_events', { clientEvent })).data;

export const updateClientEvent = async (clientEvent: Partial<ClientEvent>) =>
  (await mentoApi.put(`/client_events/${clientEvent.id}`, { clientEvent }))
    .data;

export const getClientEvents = async () =>
  (await mentoApi.get('/client_events')).data;
