import { Card, CardActions, CardContent, Icon, Typography } from '@/ui';

import Create from '@/components/Document/Create';
import Upload from '@/components/Documents/Upload';

const Empty = () => (
  <Card>
    <CardContent>
      <div className="mb-4 grid h-12 w-12 place-items-center rounded-full bg-sage-200">
        <Icon className="!text-2xl text-sage-600">article</Icon>
      </div>
      <Typography className="mb-2" variant="h5">
        There are no documents shared yet.
      </Typography>
      <Typography className="mb-5" variant="body2">
        Start by creating a new document or uploading an existing one.
      </Typography>
    </CardContent>
    <CardActions className="pb-6">
      <Create />
      <Upload />
    </CardActions>
  </Card>
);

export default Empty;
