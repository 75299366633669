import { SurveyResponseRelationship } from '@/types';

import type { StepData } from '@/types';

export const INSIGHTS_TABS = [
  {
    description: 'Get started',
    title: 'New Survey',
  },
  {
    description: 'See example results',
    title: 'Example Insights',
  },
];

export const RELATIONSHIP_OPTIONS = [
  { label: 'My Manager', value: SurveyResponseRelationship.MY_MANAGER },
  {
    label: 'My Direct Report',
    value: SurveyResponseRelationship.MY_DIRECT_REPORT,
  },
  { label: 'Team Member', value: SurveyResponseRelationship.TEAM_MEMBER },
  { label: 'Co-worker', value: SurveyResponseRelationship.CO_WORKER },
  {
    label: 'Previous Co-worker',
    value: SurveyResponseRelationship.PREVIOUS_CO_WORKER,
  },
  { label: 'Other', value: SurveyResponseRelationship.OTHER },
];

export const SurveyData = {
  clientData: {
    button: 'Get Started',
    description:
      "Grow your career with peer feedback—turn anonymous feedback from your coworkers into action with your coach's help.",
    mediaUrl:
      'https://mento-space.nyc3.cdn.digitaloceanspaces.com/assets/peer-insights-demo.mp4',
    title: 'Peer Insights',
  },
  coachData: {
    button: 'Learn more',
    description:
      "New! Coach better by seeing feedback from your clients' coworkers. Great for new members, members that don't get formal feedback, want a promotion, or just finished a milestone and want to learn from the experience.",
    mediaUrl:
      'https://mento-space.nyc3.cdn.digitaloceanspaces.com/assets/peer-feedback-insights-coach.png',
    title: "Supercharge your clients' growth with Peer Insights",
  },
  icon: '3p',
};

export const PEER_STEP_DATA: [StepData, StepData, StepData] = [
  {
    name: 'Get started',
    subtitle: 'How it works',
    title: 'Grow your career with peer feedback',
  },
  {
    name: 'Add recipients',
    subtitle:
      "Add at least 4 recipients you'd like feedback from. That should be big enough to not be able to guess who said what as feedback will be anonymous.",
    title: 'Add Recipients',
  },
  {
    buttonIcon: 'send',
    layout: 'lg:flex-row flex-col',
    name: 'Send survey',
    subtitle:
      "Here's the email introducing the survey to your coworkers. Your coworker's first name is automatically added. You can edit the email to personalize it.",
    title: 'Review before sending',
  },
];

export const SURVEY_INITIAL_STEP = 0;
export const SURVEY_ADD_RECIPIENTS_STEP = 1;
export const SURVEY_EMAIL_STEP = 2;
export const TYPEFORM_CHOICES_TYPE = 'choices';
