import { Card, CardMedia, Typography } from '@/ui';

import type { FC } from 'react';

const Empty: FC = () => (
  <div className="pt-14">
    <Typography
      className="mb-6 flex items-center gap-2 text-cement-500"
      variant="h4"
    >
      Mento Engagement
    </Typography>
    <Card className="flex h-56 w-full flex-col items-center justify-center gap-3 border border-solid border-cement-200 p-4 text-center shadow-none">
      <CardMedia
        className="w-38 md:w-74"
        component="img"
        image="https://mento-space.nyc3.cdn.digitaloceanspaces.com/assets/mento_engagement.png"
      />
      <Typography
        className="w-auto text-cement-400 opacity-100 md:w-116"
        variant="body2"
      >
        Engagement metrics will appear here once team members have been matched
        with a coach and active on Mento for 30 days.
      </Typography>
    </Card>
  </div>
);

export default Empty;
