import { useState } from 'react';

import { updateUser, updateUserProfile } from '@/api';
import { useCurrentUser } from '@/hooks';
import { Button, Dialog, DialogActions, DialogContent, Divider } from '@/ui';
import { isValidLinkedin } from '@/utils';

import Growth from './Growth';
import Profile from './Profile';

import type { User, UserAssesment, UserProfile } from '@/types';
import type { FC } from 'react';

import { updateUserAssesment } from '@/api/userAssesment';
import Bio from '@/components/Profile/Edit/Bio';

interface EditClientProps {
  client: User;
  onClose: () => void;
}

const EditClient: FC<EditClientProps> = ({
  client: currentClient,
  onClose,
}) => {
  const { refetchUser } = useCurrentUser();

  const [isSaving, setIsSaving] = useState(false);
  const [client, setClient] = useState(currentClient);
  const [profile, setProfile] = useState(
    currentClient.profile ?? ({} as UserProfile),
  );
  const [assesment, setAssesment] = useState(
    currentClient.userAssesment ?? ({} as UserAssesment),
  );

  const onChange = (changed: User) => setClient(changed);
  const onProfileChange = (changed: UserProfile) => setProfile(changed);

  const onAssessmentChange = (changed: UserAssesment) => setAssesment(changed);

  const onCancel = () => onClose();
  const onSave = async () => {
    setIsSaving(true);

    const [firstName, ...rest] = client.fullName.split(' ');
    const lastName = rest.join(' ');
    const userToUpdate = {
      ...client,
      firstName,
      lastName,
    };

    await Promise.allSettled([
      updateUser(client.id, { ...userToUpdate }),
      updateUserProfile(client.id, { ...profile }),
      updateUserAssesment(client.id, { ...assesment }),
    ]);
    await refetchUser();
    setIsSaving(false);
    onClose();
  };

  const linkedIn = profile.linkedIn || '';

  const isDisabled = !!linkedIn && !isValidLinkedin(linkedIn);

  return (
    <Dialog maxWidth="md" fullWidth open>
      <DialogContent>
        <Profile
          client={client}
          profile={profile}
          onChange={onChange}
          onProfileChange={onProfileChange}
        />

        <Divider className="my-8" />
        <Bio profile={profile} onProfileChange={onProfileChange} />
        <Divider className="my-8" />
        <Growth assesment={assesment} onAssessmentChange={onAssessmentChange} />
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={isDisabled}
          loading={isSaving}
          variant="contained"
          onClick={onSave}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditClient;
