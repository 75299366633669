import { Skeleton } from '@mui/material';

import { FOUR } from '@/constants';

const Loading = () => (
  <div>
    <ul className="mx-auto grid grid-cols-1 gap-4 pl-0 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
      {Array.from(new Array(FOUR).keys()).map((n) => (
        <Skeleton
          key={`documents-loading-${n}`}
          className="mx-auto"
          component="li"
          height={248}
          variant="rectangular"
          width={288}
        />
      ))}
    </ul>
  </div>
);

export default Loading;
