import { Skeleton } from '@mui/material';

import { SIX } from '@/constants';

const Loading = () => (
  <div>
    <ul className="mx-auto grid grid-cols-2 gap-4 pl-0 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6">
      {Array.from(new Array(SIX).keys()).map((n) => (
        <Skeleton
          key={`documents-loading-${n}`}
          className="mx-auto"
          component="li"
          height={184}
          variant="rectangular"
          width={144}
        />
      ))}
    </ul>
  </div>
);

export default Loading;
