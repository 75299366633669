import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { createFeedback, getSession, updateFeedback } from '@/api';
import { useCurrentUser } from '@/hooks';
import { type Session, type SessionFeedback, type User } from '@/types';
import { Button, Icon, Paper, Typography } from '@/ui';

import Detail from '../../components/Session';
import SessionCardSekeleton from '../../components/Sessions/Card/Skeleton';

import ScheduleSession from '@/components/Cal/scheduleSession';
import Feedback from '@/components/Feedback';
import { track } from '@/utils/tracking';

const SessionPage = () => {
  const { user: currentUser, isUserLoading: isCurrentUserLoading } =
    useCurrentUser();
  const navigate = useNavigate();

  const { memberSessionId, sessionId } = useParams();
  const [searchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const id = (memberSessionId ?? sessionId ?? '') as string;
  const feedbackType = searchParams.get('type') ?? '';
  const [openFeedback, setOpenFeedback] = useState<Session | null>();
  const [openSchedule, setOpenSchedule] = useState<User | null>(null);

  const {
    data = {} as Session,
    error,
    isLoading,
  } = useQuery(['session', id], () => getSession(id), {
    enabled: id !== '',
  });
  const isCoachViewing = currentUser.id === data.coachId;
  const concerningUser = isCoachViewing ? data.user : data.coach;

  const onViewProfile = () => {
    if (!isCoachViewing) {
      navigate(`/m/profiles-coach/${concerningUser.id}`);
    } else {
      navigate(`/coach/members/${concerningUser.id}`);
    }
  };

  const onGoBack = () => {
    let backUrl;
    if (memberSessionId && !isLoading) {
      backUrl = `/coach/members/${currentUser.id}/sessions`;
    } else {
      backUrl = '/sessions';
    }
    navigate(backUrl);
  };

  const onUpdate = async (feedback: SessionFeedback) => {
    try {
      if (feedback.id) {
        await updateFeedback(feedback.id, {
          category: feedback.category,
          content: feedback.content,
        });
      } else {
        await createFeedback({
          category: feedback.category,
          content: feedback.content,
          sessionId: feedback.sessionId,
        });
      }
      await Promise.all([
        queryClient.invalidateQueries(['session', sessionId]),
        queryClient.invalidateQueries(['past-sessions', currentUser.id]),
      ]);
    } catch (err) {
      console.error(err);
    }
  };

  const onClose = () => {
    track('Clicked Dimsiss Feedback', {
      sessionId: openFeedback?.id,
    });
    setOpenFeedback(null);
  };

  const onCloseAndSchedule = () => {
    if (openFeedback) {
      const coachFullProfile = currentUser.coaches.find(
        (c) => c.id === openFeedback.coach.id,
      );
      if (coachFullProfile) {
        setOpenSchedule(coachFullProfile);
      }
      setOpenFeedback(null);
    }
  };

  const onCloseSchedule = () => setOpenSchedule(null);

  useEffect(() => {
    document.title = 'Mento | Coaching sessions';
    return () => {
      document.title = 'Mento';
    };
  }, []);

  useEffect(() => {
    if (!feedbackType || isLoading || isCurrentUserLoading) return;
    setOpenFeedback({ ...data });
  }, [isLoading, feedbackType, isCurrentUserLoading]);

  if (isLoading)
    return (
      <div className="min-h-screen overflow-x-hidden">
        <div className="container mx-auto flex max-w-screen-md flex-1 flex-col p-4">
          <Paper
            className="mt-6 flex gap-4 px-8 pb-12 pt-8 "
            variant="outlined"
          >
            <SessionCardSekeleton />
          </Paper>
        </div>
      </div>
    );

  if (error)
    return <Typography>There was an error retrieving this session </Typography>;

  return (
    <div className="min-h-screen overflow-x-hidden">
      <div className="container mx-auto flex max-w-screen-md flex-1 flex-col px-2 py-6 md:px-0">
        <div className="headline flex items-center py-5">
          <Button
            startIcon={<Icon>chevron_left</Icon>}
            variant="text"
            onClick={onGoBack}
          >
            <Typography className="title pl-1 ">Sessions</Typography>
          </Button>
        </div>
        <Detail
          isCoachViewing={isCoachViewing}
          loading={isLoading}
          otherUser={concerningUser}
          session={data}
          hideMenu
          onViewProfile={onViewProfile}
        />
      </div>
      {!!openFeedback && (
        <Feedback
          session={openFeedback}
          showMaybeLayer
          onClose={onClose}
          onCloseAndSchedule={onCloseAndSchedule}
          onUpdate={onUpdate}
        />
      )}
      {openSchedule && (
        <ScheduleSession
          client={currentUser}
          close={onCloseSchedule}
          coach={openSchedule}
        />
      )}
    </div>
  );
};

export default SessionPage;
