import { useState } from 'react';

import { ONE, ZERO } from '@/constants';
import { Button, Icon } from '@/ui';

import type { StepData } from '@/types';
import type { FC } from 'react';

interface FooterProps {
  setStep: (index: number) => void;
  step: number;
  stepData: StepData[];
  valid: boolean;
}
const asyncNoop = () => Promise.resolve();

const Footer: FC<FooterProps> = ({ setStep, step, stepData, valid }) => {
  const [saving, setSaving] = useState(false);

  const {
    button = 'Continue',
    buttonIcon: icon,
    callback = asyncNoop,
  } = stepData[step] ?? {};

  const onBack = () => setStep(Math.max(ZERO, step - ONE));
  const onSubmit = async () => {
    setSaving(true);
    await callback();
    setStep(step + ONE);
    setSaving(false);
  };

  return (
    <div className="ml-auto">
      <div className="flex gap-3">
        <Button variant="outlined" onClick={onBack}>
          Back
        </Button>
        <Button
          disabled={!valid || saving}
          variant="contained"
          onClick={onSubmit}
        >
          {!!icon && <Icon>{icon}</Icon>}
          <span>{button}</span>
        </Button>
      </div>
    </div>
  );
};

export default Footer;
