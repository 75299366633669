import mentoApi from './mentoApi';

import type { CoachProfile, User } from '@/types';

export const createCoach = async (user: Partial<User>): Promise<CoachProfile> =>
  (await mentoApi.post('/coach_profiles', { ...user })).data;

export const deleteCoachProfile = async (id: string) =>
  mentoApi.delete(`/coach_profiles/${id}`);

export const updateCoachProfile = async (
  id: string,
  coachProfile: Partial<CoachProfile>,
) => {
  const { userId, ...rest } = coachProfile;
  return (
    await mentoApi.put(`/coach_profiles/${id}`, {
      coachProfile: rest,
    })
  ).data;
};

export const getCoachProfiles = async () =>
  (await mentoApi.get('/coach_profiles')).data;
