import clsx from 'clsx';

import { ONE, VISIBLE_COMPANY_HEADERS } from '@/constants';
import { TableCell, TableHead, TableRow, Typography } from '@/ui';

import type { FC } from 'react';

interface HeaderProps {
  headers: string[];
}

const Header: FC<HeaderProps> = ({ headers = [] }) => (
  <TableHead>
    <TableRow>
      {headers.map((header, index) => (
        <TableCell
          key={`${header}-companies-manage-table`}
          className={clsx('md:table-cell', {
            hidden:
              index > VISIBLE_COMPANY_HEADERS && index !== headers.length - ONE,
          })}
        >
          <Typography variant="subtitle2">{header}</Typography>
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
);

export default Header;
