import { type FC, useState } from 'react';

import { ANUAL_PRICES, PRICES } from '@/constants';
import { Typography } from '@/ui';

import CallCard from './CallCard';
import Card from './Card';
import Frequency from './Frequency';
import Info from './Info';

import type { User } from '@/types';

interface PricesProps {
  user: User;
}

const MembershipSelectComponent: FC<PricesProps> = ({ user }) => {
  const [isAnnual, setIsAnnual] = useState(true);

  const toggle = () => setIsAnnual(!isAnnual);
  const plans = isAnnual ? ANUAL_PRICES : PRICES;
  return (
    <div className="-mb-56 bg-sunny-200 md:mt-4">
      <div className="mx-auto text-center text-black ">
        <Typography variant="h4">Ready to grow with Mento?</Typography>
      </div>
      <div className="relative mx-4 mt-14 overflow-x-scroll rounded-lg bg-white p-6 text-black shadow-md lg:mx-auto lg:max-w-7xl lg:px-10 lg:py-8">
        <div className="flex flex-col gap-4">
          <div className="hidden justify-end lg:flex">
            <Frequency isAnnual={isAnnual} toggle={toggle} />
          </div>
          <div className="flex flex-col lg:flex-row">
            <div className="flex grow flex-col gap-2.5 px-4 py-0 lg:p-0">
              <Info />
              <div className="flex justify-center lg:hidden">
                <Frequency isAnnual={isAnnual} toggle={toggle} />
              </div>
              <CallCard email={user.email} name={user.fullName} desktop />
            </div>

            {plans.map((price) => (
              <Card
                key={`membership-price-${price.amount}`}
                price={price}
                user={user}
              />
            ))}
          </div>
        </div>
        <CallCard email={user.email} name={user.fullName} />
      </div>
    </div>
  );
};

export default MembershipSelectComponent;
