import mentoApi from './mentoApi';

import type { UserProfile } from '@/types';

export const updateUserProfile = async (
  id: string,
  userProfile: Partial<UserProfile>,
) => {
  const result = await mentoApi.put(`/user_profiles/${id}`, { userProfile });
  return result.data;
};
