import { Button, Card, CardContent, Icon, Link, Typography } from '@/ui';

import type { FC } from 'react';

const CAL_URL = import.meta.env.VITE_CAL_URL;
const CAL_LOGIN_URL = `${CAL_URL}/auth/login`;

interface CalOnboardingProps {
  email: string;
}

const CalOnboarding: FC<CalOnboardingProps> = ({ email }) => (
  <>
    <Card className="w-full bg-poppy-200 p-3" elevation={0}>
      <CardContent className="flex items-center gap-2">
        <Icon className="text-poppy-600">error</Icon>
        <Typography className="text-cement-600" variant="caption">
          Members can't schedule a session with you.
        </Typography>
      </CardContent>
    </Card>
    <Typography className="text-cement-600" variant="subtitle2">
      Use your {email} account to connect:
    </Typography>
    <div>
      <Link
        className="cursor-pointer text-white no-underline"
        href={CAL_LOGIN_URL}
        rel="noreferrer"
        target="_blank"
      >
        <Button color="primary" variant="contained">
          Connect with Cal.com
        </Button>
      </Link>
    </div>
  </>
);

export default CalOnboarding;
