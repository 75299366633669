import { useEffect, useState } from 'react';

import type { FC } from 'react';

const appID = import.meta.env.VITE_PUBLIC_COMETCHAT_APP_ID;
const appRegion = import.meta.env.VITE_PUBLIC_COMETCHAT_REGION;
const authKey = import.meta.env.VITE_PUBLIC_COMETCHAT_AUTH_KEY;
const widgetID = import.meta.env.VITE_PUBLIC_COMETCHAT_WIDGET_ID;

type WidgetProps = {
  chatId?: string;
  hideChat: boolean;
  sessionId: string;
  setChatId: (val: string) => void;
};

/*
 * This widget is more of a hook, and allows us to control the lifecycle and visibility of the comet chat
 * widget.
 *
 * NOTE(manuel, 2024-06-18) Future robustnses
 * This has some cometchat internals specific code (iterating over the potential toplevel iframe
 * wrappers), and it might be worth considering using the opensource build of their widget which would
 * be entirely under our control: https://github.com/cometchat/cometchat-widget-web
 */
const Widget: FC<WidgetProps> = ({
  hideChat,
  chatId = '',
  sessionId,
  setChatId,
}) => {
  const [loaded, setLoaded] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const hideChatButton = (hideChat_: boolean) => {
    // NOTE: This is looking into the internals of the CometChatWidget and hides the chat open widget.
    // It looks like cometchat creates new toplevel iframe parents with the same id,
    // even if one already exists but has display: none.
    const cometchatWidgets = document.querySelectorAll('#cometchat__widget');

    cometchatWidgets.forEach((cometchatWidget) => {
      if (cometchatWidget instanceof HTMLElement) {
        if (hideChat_) {
          // eslint-disable-next-line no-param-reassign -- We explicitly want to modify the HTML level element here.
          cometchatWidget.style.display = 'none';
        } else {
          // eslint-disable-next-line no-param-reassign -- We explicitly want to modify the HTML level element here.
          cometchatWidget.style.display = 'block';
        }
      }
    });
  };

  const init = async (uid: string) => {
    // If for whatever reason `window.CometChatWidget` is undefined, we'll be breaking
    // our whole app. Better stort circuit just in case.
    if (!window.CometChatWidget || !uid) {
      return;
    }

    await window.CometChatWidget.init({
      appID,
      appRegion,
      authKey,
    });
    await window.CometChatWidget.login({ uid });

    setLoaded(true);

    await window.CometChatWidget.launch({
      defaultType: 'user',
      docked: 'true',
      height: '520px',
      roundedCorners: 'true',
      // NOTE(manuel, 2024-06-18) I'm not sure this even those something anymore on the cometchat side.
      // Removing it doesn't change much. But, it's still in their docs so I'm leaving it in.
      target: '#cometchat',
      widgetID,
      width: '400px',
    });

    // Hide chat button if necessary, since this wouldn't get retriggered on login because hideChat wouldn't change
    // and trigger the effect.
    hideChatButton(hideChat);

    window.CometChatWidget.on('closeChat', () => {
      setIsOpen(false);
      setChatId('');
    });

    window.CometChatWidget.on('openChat', () => {
      setIsOpen(true);
    });
  };

  const logoutAndInit = async (newSessionId: string) => {
    try {
      window.CometChatWidget.openOrCloseChat(false);
      await window.CometChatWidget.logout();
      await window.CometChatWidget.login({ uid: newSessionId });
      // Hide chat button if necessary, since we won't get an explicit trigger on hideChat changes
      hideChatButton(hideChat);
    } catch (error) {
      console.error('Error switching user session!', error);
    }
  };

  useEffect(() => {
    (async () => {
      if (loaded) {
        // NOTE(manuel, 2024-06-18) I think this is to handle logging errors on initialization, when sessionId might be null,
        // as well as just a general error handling strategy.
        await logoutAndInit(sessionId);
      } else {
        await init(sessionId);
      }
    })();
  }, [sessionId]);

  useEffect(() => {
    // Shortcircuit if not `loaded` or no `chatId` is present
    if (!loaded || !chatId) {
      return;
    }

    window.CometChatWidget.chatWithUser(chatId);

    if (!isOpen) {
      window.CometChatWidget.openOrCloseChat(true);
    }
    hideChatButton(hideChat);
  }, [loaded, chatId, isOpen]);

  // make sure we hide the widget is asked to from the parent
  useEffect(() => {
    hideChatButton(hideChat);
  }, [hideChat]);

  return <div id="cometchat" />;
};

export default Widget;
