import { TableCell, TableHead, TableRow, Typography } from '@/ui';

const Header = () => (
  <TableHead className="bg-sage-100">
    <TableRow>
      <TableCell>
        <Typography variant="subtitle2">Name</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="subtitle2">Updated</Typography>
      </TableCell>
    </TableRow>
  </TableHead>
);

export default Header;
