import { clsx } from 'clsx';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { useCurrentGrowthBaseUrl } from '@/hooks';
import { BoulderColor } from '@/types';
import { Link, Typography } from '@/ui';
import { getBoulderColor } from '@/utils';

import BoulderImage from '../BoulderImage';

import MilestoneDataCTA from './MilestoneDataCTA';

import type { Milestone } from '@/types';
import type { FC, MouseEvent } from 'react';

import StepDialog from '@/components/Growth/StepDialog';

interface MilestoneDataProps {
  isLast?: boolean;
  milestone: Milestone;
}
const MilestoneData: FC<MilestoneDataProps> = ({ milestone, isLast }) => {
  const [isOpen, setIsOpen] = useState(false);
  const growthPath = useCurrentGrowthBaseUrl();
  const done = milestone.totalSteps === milestone.completeSteps;
  const color = getBoulderColor(milestone.id);
  const typographyClasses = clsx({
    'text-bloom-500': color === BoulderColor.BLOOM,
    'text-ocean-500': color === BoulderColor.OCEAN,
    'text-poppy-500': color === BoulderColor.POPPY,
    'text-sage-500': color === BoulderColor.SAGE,
    'text-sky-500': color === BoulderColor.SKY,
    'text-sunny-500': color === BoulderColor.SUNNY,
  });
  const classes = clsx(
    '-mx-4 flex h-21 items-center gap-4 border-0 px-4 text-cement-500 no-underline',
    {
      'border-b border-solid border-b-cement-200': !isLast,
    },
  );

  const handleOpenDialog = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setIsOpen(true);
  };

  const milestoneLink = `${growthPath}/milestone/${milestone.id}`;

  return (
    <>
      <Link className={classes} component={RouterLink} to={milestoneLink}>
        <div className="h-15 w-15">
          <BoulderImage milestone={milestone} />
        </div>
        <div className="flex w-full flex-col">
          <div>
            <Typography
              className="line-clamp-2 overflow-hidden text-ellipsis"
              variant="subtitle2"
            >
              {milestone.title}
            </Typography>
          </div>
          <MilestoneDataCTA
            color={typographyClasses}
            done={done}
            handleOpenDialog={handleOpenDialog}
            milestone={milestone}
          />
        </div>
      </Link>
      <StepDialog
        isOpen={isOpen}
        milestoneId={milestone.id}
        setOpen={setIsOpen}
      />
    </>
  );
};

export default MilestoneData;
