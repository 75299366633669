import type { User } from './user';

export interface Team {
  createdAt: Date;
  currentUserPermission: TeamPermission;
  currentUserStatus: TeamStatus;
  goals: string[];
  id: string;
  isActive: boolean;
  name: string;
  teamMembers: TeamMember[];
  updatedAt: Date;
}

export interface TeamMember {
  createdAt: string; // timestamp
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  permission: TeamPermission;
  profileUrl: string;
  status: TeamStatus;
  teamId: string;
  updatedAt: string; // timestamp
  user: Partial<User>;
  userId: string;
}

export enum TeamPermission {
  admin = 'admin',
  member = 'member',
}

export enum TeamStatus {
  accepted = 'accepted',
  declined = 'declined',
  left = 'left',
  pending = 'pending',
  removed = 'removed',
}

// This interface should probably extend from a more generic ApiOptions interface
export interface TeamsApiOptions {
  isActive?: boolean;
  limit?: Number;
  page?: Number;
  sortBy?: string;
}
