import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ONE, ZERO } from '@/constants';
import { useCurrentUser, useGetTemplates, useView } from '@/hooks';
import { Icon, IconButton, Pagination, Typography } from '@/ui';

import Grid from './Grid';
import Header from './Header';

import type { ChangeEvent } from 'react';

const Templates = () => {
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(ONE);
  const { user } = useCurrentUser();
  const { isCoach } = useView();
  const navigate = useNavigate();

  const { data = { items: [], pages: ZERO }, isLoading } = useGetTemplates({
    page,
    search,
    template: true,
  });

  const updateSearch = (value: string) => {
    setSearch(value);
    setPage(ONE);
  };

  const onPageChange = (_: ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const onBack = () => {
    if (isCoach) navigate('/coach/documents');
    else navigate('/documents');
  };

  return (
    <div className="container mx-auto max-w-screen-lg p-4">
      <div className="my-7 flex flex-1 gap-2">
        <IconButton color="primary" onClick={onBack}>
          <Icon>chevron_left</Icon>
        </IconButton>
        <Typography
          className="max-w-84 sm:max-w-100 md:max-w-180 lg:max-w-250"
          variant="h4"
          noWrap
        >
          Templates
        </Typography>
      </div>

      <Header
        isSuperAdmin={user.superAdmin}
        search={search}
        setSearch={updateSearch}
      />

      <div className="mt-4 flex flex-1 flex-col pb-4">
        <Grid documents={data.items} isLoading={isLoading} />
        <Pagination count={data.pages} page={page} onChange={onPageChange} />
      </div>
    </div>
  );
};

export default Templates;
