// TODO: All the components can get data on their own
import { useState } from 'react';

import { useCurrentUser, useView } from '@/hooks';

import Banner from '@/components/Profile/Banner';
import Bio from '@/components/Profile/Bio';
import ClientDetails from '@/components/Profile/Client/Details';
import EditClientAccount from '@/components/Profile/Client/Edit/Account';
import EditClient from '@/components/Profile/Client/Edit/Client';
import Growth from '@/components/Profile/Client/Growth';
import CoachDetails from '@/components/Profile/Coach/Details';
import EditCoachAccount from '@/components/Profile/Coach/Edit/Account';
import EditCoach from '@/components/Profile/Coach/Edit/Coach';
import Experience from '@/components/Profile/Coach/Experience';
import Styles from '@/components/Profile/Coach/Styles';

// TODO: Remove Client VS Coach split - they are almost the same
// Needs to merge into this:
// src/pages/Manage/Coach.tsx
// src/pages/Coach/Profile/index.tsx
// src/pages/Member/Profile/index.tsx
interface ProfileProps {
  isClient?: boolean;
}

const Profile = (props: ProfileProps) => {
  const { user: currentUser, refetchUser: refetchCurrentUser } =
    useCurrentUser();
  const { isClient } = props;
  const { selectedUser, isViewLoading, refetchSelectedUser } = useView();

  const user = selectedUser.id ? selectedUser : currentUser;
  const refetchUser = selectedUser.id
    ? refetchSelectedUser
    : refetchCurrentUser;

  const [isEditing, setIsEditing] = useState(false);
  const [isEditingDetails, setIsEditingDetails] = useState(false);

  // TODO: should we expose editing to admins?
  const canEdit = currentUser.id === user.id;

  const onEdit = canEdit ? () => setIsEditing(true) : undefined;
  const onClose = () => setIsEditing(false);
  const onEditDetails = canEdit ? () => setIsEditingDetails(true) : undefined;
  const onCloseDetails = () => setIsEditingDetails(false);

  if (isViewLoading || !user.id) {
    // TODO: actually have a nice loading screen...
    return null;
  }

  // TODO: Details, EditAccount and Edit<user> can be made more general to not require the extra logic
  return (
    <div className="flex-1 flex-col bg-sunny-100 md:flex">
      <div className="w-full bg-sage-500 pl-2 pt-4 lg:pl-8 lg:pt-0">
        <Banner user={user} onEdit={onEdit} />
      </div>
      <div className="block px-6 py-4 lg:container md:px-8 lg:mt-8 lg:flex lg:flex-1 lg:flex-row-reverse lg:px-12">
        <div className="space-y-8 px-0 lg:flex-1 lg:px-10">
          <Bio user={user} />
          {isClient ? (
            <Growth client={user} />
          ) : (
            <>
              <Experience
                coach={user}
                industries={user.coachProfile?.industries ?? []}
              />
              <Styles styles={user.coachProfile?.styles ?? []} />
            </>
          )}
        </div>
        <div className="-mx-8 -mb-4 mt-12 bg-sage-200 px-8 py-4 lg:mx-0 lg:mt-6 lg:h-64 lg:w-64 lg:bg-transparent lg:px-0 lg:pb-6">
          {isClient ? (
            <ClientDetails client={user} onEdit={onEditDetails} />
          ) : (
            <CoachDetails coach={user} onEditAccount={onEditDetails} />
          )}
        </div>
      </div>

      {isEditingDetails && isClient && (
        <EditClientAccount client={user} onClose={onCloseDetails} />
      )}
      {isEditingDetails && !isClient && (
        <EditCoachAccount coach={user} onClose={onCloseDetails} />
      )}
      {isEditing && isClient && <EditClient client={user} onClose={onClose} />}
      {isEditing && !isClient && (
        <EditCoach coach={user} refetchCoach={refetchUser} onClose={onClose} />
      )}
    </div>
  );
};

export default Profile;
