import { ZERO } from '@/constants';
import { Avatar, Chip, Icon, Typography } from '@/ui';

import type { Company } from '@/types';
import type { FC } from 'react';

const About: FC<{
  company: Company;
}> = ({ company }) => {
  let pocInfo = company.pocName || '';

  if (company.pocName && company.pocEmail) {
    pocInfo += `, ${company.pocEmail}`;
  } else if (company.pocEmail) {
    pocInfo += company.pocEmail;
  }

  return (
    <div className="flex flex-col items-center justify-center gap-2">
      <Avatar
        icon="domain"
        iconClassName="text-5xl"
        size="xl"
        src={company.avatarUrl}
      />
      <Typography variant="h4">{company.name}</Typography>
      <div className="mb-4 flex gap-2">
        <Icon>groups</Icon>
        <Typography variant="body1">
          {company.usersCount || ZERO} members
        </Typography>
      </div>
      {!!company.domains.length && (
        <Chip color="info" label={company.domains.join(', ')} />
      )}
      {!!pocInfo.length && (
        <Chip color="default" icon={<Icon>account_box</Icon>} label={pocInfo} />
      )}
    </div>
  );
};

export default About;
