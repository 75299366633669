export const FAQS = [
  {
    body: "Coaching is about shaping your future in a way that gives you purpose and joy. Mento's coaches are dedicated to helping you reach meaningful, long-term, sustainable growth through 1-on-1 sessions every two weeks. Each session is a space for you to practice new skills and co-create a plan for growth. From optimizing the day-to-day of your work to planning your long-term goals, Mento's blended coaching-mentorship model means you no longer need to go about your career development alone. To learn more about what a career coach does and how working with one can help you create an action plan to reach your goals, schedule a chat with us. We'll be happy to tell you more and gauge whether Mento is a good fit for your needs.",
    header: 'What is coaching',
  },
  {
    body: "Our matching experience is carefully designed to make sure you are matched with the best coach to help you reach your unique goals. First, we take the time to get to know you, your experience, goals, and style. You'll answer some questions for us, and you can also meet with our team. We'll share a few coaching profiles we think you'll love and pair you with the coach of your choice. Many of our members initially meet with two coaches before they make a final decision of who they'd like to work with long-term. You feeling great about working with your coach is our top priority.",
    header: 'How will I get matched with my coach?',
  },
  {
    body: 'Yes, you can easily switch between coaches by reaching out to our team. By joining Mento, you get access to a tight-knit coaching team with different expertise and experiences. Our careers, needs, and goals are dynamic. Your coaching should be, too.',
    header: 'Am I able to switch coaches?',
  },
  {
    body: "Mento provides you with consistent, meaningful support that helps you reach your potential. To do that, you'll have 45-minute 1-on-1 coaching sessions every two weeks. You'll also collaborate with your coach directly on the Mento platform, which makes it easy to set goals, stay accountable, and message your coach between sessions. Each month, we'll host members-only workshops and events to further augment your growth. But that's not all. We're continuously working to add more value to your membership by training our coaches, adding features to Mento's platform, hosting more events, and leveling up your entire coaching experience.",
    header: 'What do I get as a Mento member?',
  },
  {
    body: 'We have two plans: annual and monthly. For annual plans, you will be charged one-time annually. For monthly plans, you will be billed monthly. If you decide to cancel, you will not be charged again once your plan ends.',
    header: 'When will I be charged? ',
  },
  {
    body: "We don't currently offer a free trial, but we do offer you a free consultation with our coaching team who can help you determine if Mento is the right fit for you and discuss your coaching options before you pay. We do this because our coaches time is valuable and in high-demand.",
    header: 'Is there a free trial?',
  },
  {
    body: "Many companies will pay for (or partially cover) your coaching costs, either through a L&D/Growth stipend or simply by asking. We've developed a template you can send to your HR team or manager to confirm an expense plan. Send us an email at <a href='mailto:concierge@mento.co' style='text-decoration: underline;'>concierge@mento.co</a> and we'll be happy to help.",
    header: 'Can my company cover my membership fee?',
  },
];

export const LOGOS = [
  {
    alt: 'spotify',
    src: 'https://mento-space.nyc3.digitaloceanspaces.com/assets/company-logos/img_logo_spotify.png',
  },
  {
    alt: 'linkedIn',
    hidden: true,
    src: 'https://mento-space.nyc3.digitaloceanspaces.com/assets/company-logos/img_logo_linkedin.png',
  },
  {
    alt: 'flatiron',
    hidden: true,
    src: 'https://mento-space.nyc3.digitaloceanspaces.com/assets/company-logos/img_logo_flatiron.png',
  },
  {
    alt: 'thread up',
    src: 'https://mento-space.nyc3.digitaloceanspaces.com/assets/company-logos/img_logo_threadup.png',
  },
  {
    alt: 'figma',
    src: 'https://mento-space.nyc3.digitaloceanspaces.com/assets/company-logos/img_logo_figma.png',
  },
  {
    alt: 'loom',
    src: 'https://mento-space.nyc3.digitaloceanspaces.com/assets/company-logos/img_logo_loom.png',
  },
  {
    alt: 'supabase',
    src: 'https://mento-space.nyc3.digitaloceanspaces.com/assets/company-logos/img_logo_supa.png',
  },
  {
    alt: 'google',
    src: 'https://mento-space.nyc3.digitaloceanspaces.com/assets/company-logos/img_logo_google.png',
  },
  {
    alt: 'pogo',
    hidden: true,
    src: 'https://mento-space.nyc3.digitaloceanspaces.com/assets/company-logos/img_logo_pogo.png',
  },
  {
    alt: 'grammarly',
    hidden: true,
    src: 'https://mento-space.nyc3.digitaloceanspaces.com/assets/company-logos/img_logo_grammarly.png',
  },
  {
    alt: 'team shares',
    hidden: true,
    src: 'https://mento-space.nyc3.digitaloceanspaces.com/assets/company-logos/img_logo_teamshares.png',
  },
  {
    alt: 'shippo',
    hidden: true,
    src: 'https://mento-space.nyc3.digitaloceanspaces.com/assets/company-logos/img_logo_shippo.png',
  },
  {
    alt: 'bowery',
    hidden: true,
    src: 'https://mento-space.nyc3.digitaloceanspaces.com/assets/company-logos/img_logo_bowert.png',
  },
  {
    alt: 'netflix',
    src: 'https://mento-space.nyc3.digitaloceanspaces.com/assets/company-logos/img_logo_netflix.png',
  },
];

export const QUOTES = [
  {
    job: 'Product Manager at American Express',
    name: 'Ian Pulice',
    quote:
      "Mento changed my career. It's incredible to have a coach and community to have honest conversations with and help me think about my career more critically. I feel more focused, clean and calm.",
    src: 'https://mento-space.nyc3.digitaloceanspaces.com/assets/img_linkedin_ian.png',
  },
  {
    job: 'Software Engineer at Google',
    name: 'Bo Kim',
    quote:
      "I felt stuck in my current engineer role and didn't know what to do. My coach helped me to dig into my internal desires and think about my long term goals. Afterwards, he showed me multiple paths that could reach my goal and encouraged me to try them out. With advice and learnings from him, I was able to get an engineering manager role. I'm growing again!",
    src: 'https://mento-space.nyc3.digitaloceanspaces.com/assets/img_linkedin_bo.png',
  },
  {
    job: 'Operations at Sydecar',
    name: 'Amelia Stucke',
    quote:
      'My Mento Coach taught me that we have limited memory and finite emotional bandwidth, so stocking the shelves with bitterness or regret takes away from growth and knowledge. Their advice has helped me let go of frustration and concentrate on what truly matters.',
    src: 'https://mento-space.nyc3.digitaloceanspaces.com/assets/img_linkedin_amelia.png',
  },
];

export const SAUCES = [
  {
    body: "Our coaches are experts in their fields and work at the world's top companies. You'll grow your career with someone who's been where you want to go.",
    header: 'Industry top professionals',
    src: 'https://mento-space.nyc3.digitaloceanspaces.com/assets/img_sauce_industry.svg',
  },
  {
    body: 'Build your custom growth plan around your unique goals and challenges. Partner with your coach to reach your potential and your North Star.',
    header: 'Coaching tailored to you',
    src: 'https://mento-space.nyc3.digitaloceanspaces.com/assets/img_sauce_coaching.svg',
  },
  {
    body: "Your coach is there to give you support and mentorship when you need it most. They're your hype person, thought partner, and safe space.",
    header: 'Work through challenges together',
    src: 'https://mento-space.nyc3.digitaloceanspaces.com/assets/img_sauce_work.svg',
  },
  {
    body: "We're on fire for helping our community reach their potential. Coaches receive continuous training, and members enjoy high-touch support to grow their careers.",
    header: 'Community, not a marketplace',
    src: 'https://mento-space.nyc3.digitaloceanspaces.com/assets/img_sauce_community.svg',
  },
];

export const WORKS = [
  {
    body: "Hand-matched with industry experts who've been there, done that.",
    header: 'Get the right Coach',
    src: 'https://mento-space.nyc3.digitaloceanspaces.com/assets/img_how_meet-1.png',
  },
  {
    body: '1-on-1 coaching sessions focused on your career growth and challenges.',
    header: 'Meet every 2 weeks',
    src: 'https://mento-space.nyc3.digitaloceanspaces.com/assets/img_how_meet.png',
  },
  {
    body: 'Clear goals and steps to achieve the next level in your career.',
    header: 'Tailored growth plan',
    src: 'https://mento-space.nyc3.digitaloceanspaces.com/assets/img_how_plan.png',
  },
];

export const MEMBERSHIP_SELECT_URL =
  'https://calendly.com/mento-cxteam/discovery-call';

export const SCHEDULE_SESSION_URL =
  'https://calendly.com/mento-cxteam/match-call';
