import { Link } from 'react-router-dom';

import { ZERO } from '@/constants';
import { Typography } from '@/ui';
import { toDateTimeString } from '@/utils';

import type { FC } from 'react';

interface SessionColumnProps {
  done?: number;
  next?: string;
  pastSessionsUrl: string;
  pending?: number;
  timezone?: string;
  upcomingSessionsUrl: string;
}

const SessionColumn: FC<SessionColumnProps> = ({
  done,
  next,
  pending,
  timezone = 'America/Los_Angeles',
  upcomingSessionsUrl,
  pastSessionsUrl,
}) => (
  <div>
    <Link style={{ textDecoration: 'none' }} to={upcomingSessionsUrl}>
      <Typography
        className="flex items-center justify-between gap-2"
        color={!pending ? 'error.main' : 'text.primary'}
        variant="subtitle2"
      >
        {next
          ? toDateTimeString(new Date(next), timezone, 'short', 'short')
          : 'No upcoming sessions'}
      </Typography>
    </Link>

    <Typography
      className="flex items-center justify-between gap-2"
      color="text.secondary"
      variant="caption"
    >
      <Link
        color="text.secondary"
        style={{ display: 'flex', textDecoration: 'none' }}
        to={upcomingSessionsUrl}
      >
        <Typography
          className="text-nowrap"
          color="text.secondary"
          variant="caption"
        >
          {pending ?? ZERO} upcoming
        </Typography>
      </Link>
      •
      <Link
        color="text.secondary"
        style={{ display: 'flex', textDecoration: 'none' }}
        to={pastSessionsUrl}
      >
        <Typography
          className="text-nowrap"
          color="text.secondary"
          variant="caption"
        >
          {done ?? ZERO} completed
        </Typography>
      </Link>
    </Typography>
  </div>
);

export default SessionColumn;
