import { useNavigate } from 'react-router-dom';

import { Card } from '@/ui';
import { onKeyDown } from '@/utils';

import type { GrowthPlan, Milestone } from '@/types';
import type { FC } from 'react';

import MilestoneData from '@/components/Growth/Dashboard/MilestoneData';

interface MilestoneCardProps {
  growthPlan: GrowthPlan;
  milestone: Milestone;
}

const MilestoneCard: FC<MilestoneCardProps> = ({ growthPlan, milestone }) => {
  const navigate = useNavigate();

  const navigateToBoulder = (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(`../growth/${growthPlan.id}/milestone/${milestone.id}`);
  };

  return (
    <Card
      className="cursor-pointer px-4 pt-2"
      onClick={navigateToBoulder}
      onKeyDown={onKeyDown(navigateToBoulder)}
    >
      <MilestoneData milestone={milestone} />
    </Card>
  );
};

export default MilestoneCard;
