import { useNavigate, useParams } from 'react-router-dom';

import { FIVE } from '@/constants';
import { useView } from '@/hooks';
import { NoteOwnerType } from '@/types';
import { Skeleton, Typography } from '@/ui';

import GrowthOverview from '../Growth';

import Notes from '@/components/Notes/Notes';
import Information from '@/pages/Member/Information';

const TeamMemberOverview = () => {
  const { selectedUser, isViewLoading } = useView();
  const { teamId = '' } = useParams();
  const navigate = useNavigate();

  const seeAllNotes = () => {
    navigate(`/teams/${teamId}/member/${selectedUser.id}/notes`);
  };

  return (
    <div className="p-8">
      <Typography variant="h4">Overview</Typography>
      <div className="flex items-baseline gap-6 pt-4">
        <div className="flex flex-1 flex-col gap-6">
          <div className="w-full">
            <GrowthOverview />
          </div>
          <div className="w-full">
            <Notes
              limit={FIVE}
              owneableId={selectedUser.id}
              owneableType={NoteOwnerType.USER}
              seeAllNotes={seeAllNotes}
              showPagination={false}
              title="Private Notes"
            />
          </div>
        </div>
        <div className="flex basis-72">
          {isViewLoading ? (
            <Skeleton height={300} width="100%" />
          ) : (
            <Information client={selectedUser} hideActions />
          )}
          {/* TODO: add growth plan card and coach card (once we are done with the unification) */}
        </div>
      </div>
    </div>
  );
};

export default TeamMemberOverview;
