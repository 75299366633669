import { Button, Card, Chip, Icon, Typography } from '@/ui';

import type { FC } from 'react';

interface OptionCardProps {
  buttonText: string;
  disabled?: boolean;
  icon: string;
  onClick: () => void;
  recommended?: boolean;
  subtitle: string;
  title: string;
}

const OptionCard: FC<OptionCardProps> = ({
  icon,
  title,
  subtitle,
  buttonText,
  recommended = false,
  disabled = false,
  onClick,
}) => (
  <Card className="flex items-baseline justify-between p-6 md:flex-col md:items-center md:text-center">
    <div className="hidden h-16 w-16 rounded-full bg-sunny-700 md:flex">
      <Icon className="!m-auto !text-2xl">{icon}</Icon>
    </div>
    <div className="flex flex-1 flex-col gap-2 p-2 md:px-0 md:py-8">
      <Typography variant="subtitle1">{title}</Typography>
      {recommended && (
        <Chip
          className="self-start md:self-center"
          color="info"
          label="Recommended"
          size="small"
        />
      )}
      <Typography className="min-h-16 w-48" variant="body1">
        {subtitle}
      </Typography>
    </div>
    <Button
      className="ml-auto md:ml-0 md:h-auto md:w-full"
      disabled={disabled}
      variant="contained"
      onClick={onClick}
    >
      {buttonText}
    </Button>
  </Card>
);

export default OptionCard;
