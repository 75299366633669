import { ENTER } from '@/constants';

import type { KeyboardEvent } from 'react';

export const onKeyDown =
  (callback: Function) => (event: KeyboardEvent<HTMLElement>) => {
    if (event.key === ENTER) {
      callback();
    }
  };

// Trims a <separator> separated string of items
// Removes empty strings
export const trimList = (list: string, separator = ',') =>
  list
    .split(separator)
    .map((value) => value.trim())
    .filter((value) => !!value);

export const sleep = (delay: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
