import { useCallback, useRef } from 'react';
import { useDropzone } from 'react-dropzone';

import { FILE_ERRORS, FILE_SIZE_LIMIT, ONE } from '@/constants';
import { Icon, Typography } from '@/ui';

import Files from './Files';

import type { FC } from 'react';

interface ModalProps {
  preparedFile: File;
  processing: boolean;
  setPreparedFile: (f: File[]) => void;
}

const Drop: FC<ModalProps> = ({
  preparedFile,
  processing,
  setPreparedFile,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const onDrop = useCallback((acceptedFile: File[]) => {
    setPreparedFile(acceptedFile);
  }, []);

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    accept: {
      'application/msword': ['.doc'],
      'application/pdf': ['.pdf'],
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.ms-powerpoint': ['.ppt'],
      'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        ['.pptx'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
        '.xlsx',
      ],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        ['.docx'],
      'text/csv': ['.csv'],
      'text/plain': ['.text'],
    },
    maxFiles: ONE,
    maxSize: FILE_SIZE_LIMIT,
    onDrop,
  });

  const onClick = () => inputRef.current?.click();

  const getError = () => {
    const error = fileRejections[0]?.errors[0];
    if (!error) return null;
    return FILE_ERRORS[error.code];
  };

  return (
    <div
      {...getRootProps({
        className: 'dropzone bg-ocean-200 rounded-md',
      })}
    >
      <input ref={inputRef} {...getInputProps()} type="file" />
      {!preparedFile.name ? (
        <div className="mt-5 flex flex-col items-center py-20">
          <Icon className="text-5xl text-cement-300">cloud_upload</Icon>
          <Typography variant="subtitle1">
            Drag and drop files here, or
          </Typography>
          <Typography
            className="mb-4 cursor-pointer text-sage-500 underline"
            variant="subtitle1"
            onClick={onClick}
          >
            browse
          </Typography>
          <Typography className="text-center text-cement-400" variant="caption">
            Accepted file types include: .xlsx, .xls, .doc, .docx, .ppt, .pptx,
            .csv, .txt and .pdf.
            <br /> Limit 1 file to upload and max size 5mb
          </Typography>
          <Typography className="text-poppy-400" variant="caption">
            {getError()}
          </Typography>
        </div>
      ) : (
        <Files
          preparedFile={preparedFile}
          processing={processing}
          onClick={onClick}
        />
      )}
    </div>
  );
};

export default Drop;
