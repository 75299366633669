// TODO: Event is too generic and overlaps other things
// We should update to notifications or something similar
import mentoApi from './mentoApi';

import type { Event } from '@/types';

// Get Events
export const getEvents = async (all: boolean = false) =>
  (await mentoApi.get(`/events?all=${all}`)).data;

export const postEvent = async (event: Event): Promise<Event | null> =>
  (await mentoApi.post('/events/', { event })).data;

// Patch Event
export type PatchEventParams = {
  event: Partial<Event> & { dismissedTill?: string };
};
export const patchEvent = async (
  event: Partial<Event> & { dismissedTill?: string },
): Promise<Event | null> =>
  (await mentoApi.patch(`/events/${event.id}`, { event })).data;

export const deleteEvent = async (eventId: string): Promise<string | null> =>
  (await mentoApi.delete(`/events/${eventId}`)).data;
