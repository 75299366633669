import {
  BOULDER_COLORS,
  BOULDER_DARK_COLOR,
  BOULDER_LIGHT_COLOR,
  MAX_BOULDER_STEPS,
  ONE,
  ZERO,
} from '@/constants';
import { getBoulderColor } from '@/utils';

import BoulderPaths from './boulderPaths';

import type { Milestone } from '@/types';
import type { FC, SVGProps } from 'react';

interface BoulderImageProps extends SVGProps<SVGPathElement> {
  milestone: Partial<Milestone>;
}

const BoulderImage: FC<BoulderImageProps> = ({ milestone, ...rest }) => {
  const totalSteps = milestone.totalSteps ?? ZERO;
  const completeSteps = milestone.completeSteps ?? ZERO;
  const {
    fullPath = '',
    paths = [],
    viewBox = '0 0 80 80',
  } = BoulderPaths[Math.max(ZERO, Math.min(MAX_BOULDER_STEPS, totalSteps))] ??
  {};

  const color = getBoulderColor(milestone.id);
  const lightColor =
    BOULDER_COLORS[BOULDER_LIGHT_COLOR]?.[color] ?? BOULDER_LIGHT_COLOR;
  const darkColor =
    BOULDER_COLORS[BOULDER_DARK_COLOR]?.[color] ?? BOULDER_DARK_COLOR;

  const svgProps = {
    fill: 'none',
    height: '100%',
    viewBox,
    width: '100%',
    xmlns: 'http://www.w3.org/2000/svg',
  };

  const lastPath = paths.length - ONE;

  // Fill from last to first so it looks better
  const fillColor = (current: number) =>
    lastPath - current < completeSteps ? darkColor : lightColor;

  return (
    <svg {...svgProps}>
      {paths.map((path, index) => (
        <path
          key={`boulder-${path}`}
          d={path}
          fill={fillColor(index)}
          {...rest}
        />
      ))}
      {milestone.totalSteps === milestone.completeSteps && (
        <path d={fullPath} fill={fillColor(paths.length)} {...rest} />
      )}
    </svg>
  );
};

export default BoulderImage;
