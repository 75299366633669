import clsx from 'clsx';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { updateUser } from '@/api';
import { Button, Card, Dialog, DialogActions, Typography } from '@/ui';

import type { User } from '@/types';
import type { FC } from 'react';

const AdminOnly: FC<{
  client: User;
}> = ({ client }) => {
  const navigate = useNavigate();

  const [processing, setProcessing] = useState(false);
  const [showDeactivate, setShowDeactivate] = useState(false);

  const onDeactivate = async () => {
    try {
      setProcessing(true);
      await updateUser(client.id, { isActive: false });
      setProcessing(false);
      setShowDeactivate(false);
      window.location.reload();
    } catch (e) {
      setProcessing(false);
      console.error(e);
    }
  };

  const onActivate = async () => {
    try {
      setProcessing(true);
      await updateUser(client.id, { isActive: true });
      setProcessing(false);
      setShowDeactivate(false);
      window.location.reload();
    } catch (e) {
      setProcessing(false);
      console.error(e);
    }
  };

  return (
    <Card className="overflow-hidden bg-white">
      <div
        className={clsx(
          'flex h-6 items-center justify-center bg-red-200 p-4 text-xs',
        )}
      >
        Admin only
      </div>

      <div className="space-y-2 p-6">
        {client.isActive && (
          <Button
            className="w-full"
            color="error"
            variant="contained"
            onClick={() => setShowDeactivate(true)}
          >
            Deactivate Member
          </Button>
        )}
        {!client.isActive && (
          <Button className="w-full" variant="contained" onClick={onActivate}>
            Reactivate Member
          </Button>
        )}
        <Button
          className="w-full"
          variant="outlined"
          onClick={() => navigate(`/coach/members/${client.id}/coaches`)}
        >
          Update Coaches
        </Button>
      </div>

      <Dialog open={showDeactivate}>
        <Typography className="px-4 py-2" variant="h5">
          Deactivate account
        </Typography>
        <Typography className="px-4 py-2 text-gray-500">
          Are you sure you want to deactivate this account? All coaches will be
          removed and user will be deactivated. Any subscriptions will also be
          canceled and money time will be prorated back.
        </Typography>
        <DialogActions className="mt-2">
          <Button
            loading={processing}
            variant="outlined"
            onClick={() => setShowDeactivate(false)}
          >
            Cancel
          </Button>
          <Button
            className="ml-3"
            color="warning"
            loading={processing}
            variant="contained"
            onClick={onDeactivate}
          >
            Deactivate
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default AdminOnly;
