import clsx from 'clsx';

import { Icon, Typography } from '@/ui';

type PlanFeatureProps = {
  exec: boolean;
  feature: string;
};

const PlanFeature = ({ feature, exec }: PlanFeatureProps) => (
  <span className="flex">
    <Icon
      className={clsx('mr-2 ', {
        '!text-sage-500': !exec,
        '!text-sky-500': exec,
      })}
    >
      check
    </Icon>
    <Typography dangerouslySetInnerHTML={{ __html: feature }} variant="body1" />
  </span>
);

export default PlanFeature;
