import {
  type Session,
  type SessionFeedback,
  SessionFeedbackType,
} from '@/types';
import { Button, Typography } from '@/ui';

import type { FC } from 'react';

interface Props {
  onClose: () => void;
  onCloseAndSchedule?: () => void;
  pendingFeedback: SessionFeedback;
  session: Session;
}

const FeedbackComplete: FC<Props> = ({
  session,
  pendingFeedback,
  onClose,
  onCloseAndSchedule,
}) => {
  if (
    session.isChemistryCall &&
    pendingFeedback.category === SessionFeedbackType.NEGATIVE
  ) {
    return (
      <div className="flex flex-col gap-2 text-center">
        <Typography
          className="headline-sm md:headline mb-5 text-cement-500"
          variant="h5"
        >
          Thank you for sharing your feedback.
        </Typography>
        <div>
          <Typography className="select-none">
            If you would like an additional coach option,
          </Typography>
          <Typography className="mb-2 select-none font-bold">
            please email concierge@mento.co
          </Typography>
        </div>
        <div>
          <Button variant="outlined" onClick={onClose}>
            Close
          </Button>
        </div>
      </div>
    );
  }
  if (
    session.isChemistryCall &&
    pendingFeedback.category === SessionFeedbackType.POSITIVE
  ) {
    return (
      <div className="flex flex-col gap-2 text-center">
        <Typography
          className="headline-sm md:headline mb-5 text-cement-500"
          variant="h5"
        >
          Thank you for sharing your feedback!
        </Typography>
        <div>
          <Typography className="mb-2 select-none">
            {`If you would like to proceed with ${session.coach.fullName} as your coach, get started by `}
            <Typography className="inline select-none font-bold">
              booking your biweekly coaching sessions.
            </Typography>
          </Typography>
        </div>
        <div className="flex justify-center gap-2">
          <Button variant="outlined" onClick={onClose}>
            Close
          </Button>
          <Button variant="contained" onClick={onCloseAndSchedule}>
            Schedule recurring sessions
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-2 text-center">
      <img
        alt="feedback"
        className="mx-auto block"
        src="https://mento-space.nyc3.cdn.digitaloceanspaces.com/assets/img-shaking-hands@2x.png"
        width="200"
      />
      <Typography
        className="headline-sm md:headline mb-5 text-cement-500"
        variant="h5"
      >
        Thank you for your feedback!
      </Typography>
      <Typography className="mb-2 select-none px-12 py-2 text-cement-400">
        You can also always contact our team at concierge@mento.co for support.
      </Typography>
      <div>
        <Button onClick={onClose}>Finished!</Button>
      </div>
    </div>
  );
};

export default FeedbackComplete;
