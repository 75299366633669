import { Typography } from '@/ui';
import { toMonthString } from '@/utils';

import type { User } from '@/types';
import type { FC } from 'react';

const LastLogin: FC<User> = ({ lastLogin }) => {
  const lastLoginAt = lastLogin ? toMonthString(new Date(lastLogin)) : '';

  return (
    <Typography className="mb-5 block text-cement-400" variant="caption">
      {!!lastLogin && `Last online ${lastLoginAt}`}
    </Typography>
  );
};

export default LastLogin;
