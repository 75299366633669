import { WORKS } from '@/constants';
import { Typography } from '@/ui';

import Row from './Row';

import type { FC } from 'react';

const HowItoWorks: FC = () => (
  <div className="flex flex-col items-center">
    <Typography className="my-8" variant="h3">
      How it works
    </Typography>
    <div className="mt-4 flex max-w-screen-lg flex-col md:flex-row">
      {WORKS.map((content) => (
        <Row key={`membership-works-${content.src}`} {...content} />
      ))}
    </div>
  </div>
);

export default HowItoWorks;
