import { useParams } from 'react-router';

import { DAYS_FOR_COMPANY_STATS, ONE } from '@/constants';
import { useCompany } from '@/hooks';
import { Divider, Skeleton, Typography } from '@/ui';

// import AdminStats from './AdminStats';
import Empty from './Empty';
import GeneralStats from './GeneralStats';

import Feedback from '@/components/Company/Feedback/Index';

const Stats = () => {
  const { companyId = '' } = useParams();
  const { companyData, isCompanyLoading } = useCompany(companyId);

  if (isCompanyLoading) {
    return (
      <Skeleton
        animation="wave"
        className="mt-8 h-50 w-full rounded-xl"
        variant="rectangular"
      />
    );
  }

  if (!companyData) return null;

  // if company is less than 30 days old, we show an empty state
  const available = new Date();
  available.setDate(available.getDate() - DAYS_FOR_COMPANY_STATS);

  if (available <= new Date(companyData.createdAt)) return <Empty />;

  const monthAgo = new Date();
  monthAgo.setMonth(monthAgo.getMonth() - ONE);

  const isOldEnough = new Date(companyData.createdAt) <= monthAgo;

  if (!isOldEnough) {
    return <Empty />;
  }
  return (
    <div className="flex flex-col pt-14">
      <Typography
        className="mb-2 flex items-center gap-2 text-cement-500"
        variant="h4"
      >
        Mento Engagement
      </Typography>
      <Typography className="mb-6" variant="body1">
        Metrics are based on employees who completed onboarding and have a Coach
        assigned
      </Typography>
      <div>
        <div className="flex h-full flex-col md:flex-row md:space-x-6">
          <GeneralStats />
          <Divider className="hidden h-50 lg:block" orientation="vertical" />
          <Feedback />
        </div>
      </div>
    </div>
  );
};

export default Stats;
