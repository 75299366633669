import { useState } from 'react';

import { createCoachAssignment } from '@/api';
import { useView } from '@/hooks';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Icon,
  Switch,
  TextField,
  Typography,
} from '@/ui';

import type { CoachOption, CoachProfile, User } from '@/types';
import type { FC, SyntheticEvent } from 'react';

interface AddCoachModalProps {
  client: User;
  close: () => void;
  coaches: CoachProfile[];
  isOpen: boolean;
}

// TODO: Most of these components should live in components, not in pages
const AddCoachModal: FC<AddCoachModalProps> = ({
  client,
  close,
  coaches,
  isOpen,
}) => {
  const { refetchSelectedUser } = useView();
  const [processing, setProcessing] = useState(false);
  const [sendEmail, setSendEmail] = useState(true);
  const [coachId, setCoachId] = useState<string>('');

  const onChange = (
    _event: SyntheticEvent<Element, Event>,
    value: CoachOption | null,
  ) => {
    if (value?.id) {
      setCoachId(value.id);
    }
  };

  const options = coaches
    .map(({ user }) => {
      const { fullName, email, id } = user;
      return { email, id, label: fullName };
    })
    // TODO: BE should probably return already sorted
    .sort((a, b) => a.label.localeCompare(b.label));
  const sliderClick = () => setSendEmail(!sendEmail);

  const reset = () => {
    setSendEmail(true);
    setProcessing(false);
    setCoachId('');
  };

  const closeModal = () => {
    close();
    reset();
  };

  const onAdd = async () => {
    try {
      setProcessing(true);
      await createCoachAssignment({
        coachId,
        coacheeId: client.id,
        sendEmail,
      });
      await refetchSelectedUser();
      closeModal();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Dialog maxWidth="md" open={isOpen} fullWidth onClose={closeModal}>
      <DialogContent>
        <Grid spacing={4} container>
          <Grid md={5} xs={12} item>
            <Typography className="mb-4" variant="h4">
              Add coach
            </Typography>
            <Typography className="mb-2" variant="body1">
              Select the coach to assign to {client.fullName}.
            </Typography>
          </Grid>
          <Grid className="flex flex-col gap-4" md={7} xs={12} item>
            <Autocomplete
              getOptionLabel={(option) => `${option.label} (${option.email})`}
              options={options}
              renderInput={(params) => <TextField {...params} label="Coach" />}
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  <Typography color="text.primary" variant="body1">
                    {option.label}
                  </Typography>
                  <Typography
                    className="ml-1"
                    color="text.disabled"
                    variant="caption"
                  >
                    ({option.email})
                  </Typography>
                </Box>
              )}
              onChange={onChange}
            />
          </Grid>
          <Grid md={5} xs={12} item>
            <Typography className="mb-4" variant="h4">
              Send email
            </Typography>
            <Typography className="mb-2" variant="body1">
              If this option is selected, both Coach and Member will receive
              introductory email notifications.
            </Typography>
          </Grid>
          <Grid className="flex flex-col gap-4" md={7} xs={12} item>
            <div className="flex max-h-14 items-center justify-between rounded-xl bg-sage-100 p-2  ">
              <div className="flex gap-2">
                <Icon>email</Icon>
                <Typography variant="body1">Send introductory email</Typography>
              </div>

              <Switch checked={sendEmail} onClick={sliderClick} />
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button loading={processing} variant="outlined" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          disabled={!coachId}
          loading={processing}
          variant="contained"
          onClick={onAdd}
        >
          Add coach
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddCoachModal;
