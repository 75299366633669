import { useNavigate } from 'react-router';

import useSharedCardActions from './useSharedCardActions';

import type { GeneratorFunction } from './types';

const useLearnAboutActions = (): GeneratorFunction => {
  const { handleClick } = useSharedCardActions();
  const navigate = useNavigate();

  return ({ card }) => {
    const label = 'Read';
    return [
      {
        label,
        onClick: () => {
          handleClick({ card, label });
          navigate('/m/welcome');
        },
      },
    ];
  };
};

export default useLearnAboutActions;
