import { clsx } from 'clsx';
import { forwardRef } from 'react';

import { useCurrentUser, useGetUsers } from '@/hooks';
import { FormControl, InputLabel, SelectMultipleUsers } from '@/ui';

import MaxDocumentMembers from './InputMaxDocumentMembers';

import type { SelectMultipleUsersProps } from '@/ui';

interface ShareInputProps
  extends Omit<SelectMultipleUsersProps, 'values' | 'label'> {
  sharedWith?: Array<{ userId: string }>;
}

const ShareInput = forwardRef<HTMLInputElement, ShareInputProps>(
  ({ className, sharedWith = [], ...rest }, ref) => {
    const { user } = useCurrentUser();
    const classes = clsx(className, 'document-share-input');

    // We need to fetch all templates and members here
    // due to pagination in the "root" page
    // and not being available in the Member overview
    const { data: members = { items: [] }, isLoading } =
      // TODO: Get rid of the need to load all the users and make this component able to
      // handle pagination
      useGetUsers({ limit: 1000, sortBy: 'first_name', view: 'share' });

    // Don't display input if users are loading.
    if (isLoading) {
      return null;
    }

    const defaultValue = sharedWith.length
      ? sharedWith.map(
          ({ userId }) =>
            members.items.find((coach) => coach.id === userId)?.email,
        )
      : [];

    return (
      <FormControl fullWidth>
        <InputLabel id="document-share-input" shrink>
          Share with
        </InputLabel>
        <SelectMultipleUsers
          {...rest}
          ref={ref}
          className={classes}
          defaultValue={defaultValue as string[]}
          disableOnlyMe={user.hasMaxDocuments}
          extraMarkup={
            <MaxDocumentMembers
              disableOnlyMe={user.hasMaxDocuments}
              members={members.items.filter(
                ({ hasMaxDocuments }) => hasMaxDocuments,
              )}
            />
          }
          label="Share with"
          labelId="document-share-input"
          values={members.items.filter(
            ({ hasMaxDocuments }) => !hasMaxDocuments,
          )}
          notched
        />
      </FormControl>
    );
  },
);

ShareInput.displayName = 'DocumentShareInput';

export default ShareInput;
