import { useNavigate } from 'react-router-dom';

import { THREE } from '@/constants';
import { useCurrentUser, useView } from '@/hooks';
import { NoteOwnerType } from '@/types';

import AdminOnly from './AdminOnly';
import Growth from './Growth';
import Information from './Information';
import MemberLoading from './MemberLoading';
import PlanDetails from './PlanDetails';
import RecentlyUpdatedDocuments from './RecentlyUpdatedDocuments';
import SessionsOverview from './Sessions/Overview';
import CoachInfo from './coachInfo';

import Notes from '@/components/Notes/Notes';

// TODO: pages should be simple, layout should take care of a good chunk, should just focus on loading components
// TODO: Components should fetch their data for the most part
// TODO: Page should only have Page components, the rest should live in `@/components`
const Member = () => {
  const { user } = useCurrentUser();
  const { selectedUser, isViewLoading } = useView();
  const navigate = useNavigate();

  const seeAllNotes = () => {
    navigate(`/coach/members/${selectedUser.id}/notes`);
  };
  if (isViewLoading || !selectedUser.id) {
    return <MemberLoading />;
  }

  return (
    <div className="w-full items-center">
      <div className="cement-300 flex w-full flex-1 flex-col">
        <div className="flex-1 bg-sage-100 p-0 md:p-2">
          <div className="container mx-auto max-w-screen-lg p-4">
            <div className="grid grid-cols-1 gap-4 lg:grid-cols-3 ">
              <div className="space-y-6 lg:col-span-2">
                <SessionsOverview user={selectedUser} />
                <RecentlyUpdatedDocuments />
                <div className="hidden lg:block">
                  <Notes
                    limit={THREE}
                    owneableId={selectedUser.id}
                    owneableType={NoteOwnerType.USER}
                    seeAllNotes={seeAllNotes}
                    showPagination={false}
                    title="Private Notes"
                  />
                  <div className="-mx-6 mb-6 h-full divide-y divide-cement-300 border-t border-cement-300" />
                </div>
              </div>
              <div className="space-y-6 lg:col-span-1">
                {user.superAdmin && <AdminOnly client={selectedUser} />}
                <Information
                  client={selectedUser}
                  onProfile={() => navigate('././././profile')}
                />
                <PlanDetails client={selectedUser} />
                <div className="hidden space-y-6 lg:block">
                  <CoachInfo
                    coaches={selectedUser.coaches}
                    relationships={selectedUser.coachAssignments}
                  />
                  <Growth user={selectedUser} />
                </div>
              </div>
              <div className="space-y-6 lg:hidden">
                <CoachInfo
                  coaches={selectedUser.coaches}
                  relationships={selectedUser.coachAssignments ?? []}
                />
                <Growth user={selectedUser} />
                <Notes
                  limit={THREE}
                  owneableId={selectedUser.id}
                  owneableType={NoteOwnerType.USER}
                  seeAllNotes={seeAllNotes}
                  showPagination={false}
                  title="Private Notes"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Member;
