import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
  addMessageToThread,
  getAiChatThread,
  getResponsesFromThread,
} from '@/api';
import { QUERY_KEY_THREADS } from '@/constants';

import type { Thread, ThreadMessage } from '@/types';

export const useGetThread = (threadId: string) =>
  useQuery<Thread, Error>({
    enabled: !!threadId,
    queryFn: () => getAiChatThread(threadId),
    queryKey: [QUERY_KEY_THREADS, threadId],
  });

export const useAddThreadMessage = (threadId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: addMessageToThread.bind(null, threadId),
    onSuccess: (data) => {
      const key = [QUERY_KEY_THREADS, threadId];
      const thread: Thread | undefined = queryClient.getQueryData(key);
      if (thread) {
        const messages = [...thread.messages, data];
        queryClient.setQueryData(key, { ...thread, messages });
      } else {
        // if we can't update inline, force a refetch
        queryClient.refetchQueries({ queryKey: key });
      }
    },
  });
};

// TODO: Lot of shared code in the onSuccess between this and addMessage. Can we consolidate?
export const useGetThreadResponses = (threadId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: getResponsesFromThread.bind(null, threadId),
    onSuccess: (data: ThreadMessage[]) => {
      const key = [QUERY_KEY_THREADS, threadId];
      const thread: Thread | undefined = queryClient.getQueryData(key);
      if (thread) {
        const messages = [...thread.messages, ...data];
        queryClient.setQueryData(key, { ...thread, messages });
      } else {
        // if we can't update inline, force a refetch
        queryClient.refetchQueries({ queryKey: key });
      }
    },
  });
};
