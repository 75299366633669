import { useStytch } from '@stytch/react';
import { useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { login } from '@/api';
import {
  AUTH_ERROR_MESSAGES,
  SESSION_DURATION,
  TOKEN_TO_API,
} from '@/constants';
import { Typography } from '@/ui';
import { setCookie } from '@/utils';

import type { StytchError, StytchResponse } from '@/types';

const Authenticate = () => {
  const stytch = useStytch();
  const [search] = useSearchParams();
  const navigate = useNavigate();

  const stytchTokenType = search.get('stytch_token_type') ?? '';
  const token = search.get('token') ?? '';
  const redirect = search.get('redirect');

  const requestInFlight = useRef(false);

  // We use a ref to prevent double hitting stytch's api on mount
  // in dev, because doing so was preventing the magic link
  // login from working in dev. We use a ref instead of setState because
  // refs are synchronous where setState is async and was not reliably
  // preventing the second api call.
  useEffect(() => {
    if (stytchTokenType && !requestInFlight.current) {
      requestInFlight.current = true;
      (async () => {
        try {
          const options = { session_duration_minutes: SESSION_DURATION };
          const api = TOKEN_TO_API[stytchTokenType];

          if (!api) {
            throw new Error(`Unsupported Token type ${stytchTokenType}`);
          }

          let response: StytchResponse;
          if (api === 'session') {
            response = await stytch.session.authenticate(options);
            // Stytch is not always reliably setting these cookies,
            // but we depend on them. So if we get them back in the response,
            // set them ourselves.
            const { session_jwt: sessionJwt, session_token: sessionToken } =
              response;
            const dateStr = response.session.expires_at;
            const date = dateStr ? new Date(dateStr) : null;
            if (sessionJwt) {
              setCookie('stytch_session_jwt', sessionJwt, date);
            }
            if (sessionToken) {
              setCookie('stytch_session', sessionToken, date);
            }
          } else {
            // @ts-ignore TS does not allow functions to be accessed by index
            response = await stytch[api].authenticate(token, options);
          }
          await login(response.user);

          navigate(redirect ?? '/');
        } catch (error) {
          const { error_type: errorType = '', message } = error as StytchError;
          const value =
            message ??
            AUTH_ERROR_MESSAGES[errorType] ??
            `${AUTH_ERROR_MESSAGES.misconfigured}${stytchTokenType}`;

          navigate(
            `/signin?error=${value}${redirect ? `&redirect=${redirect}` : ''}`,
          );
        } finally {
          requestInFlight.current = false;
        }
      })();
    }
  }, []);

  return (
    <div className="flex h-screen flex-1 items-center justify-center bg-sage-100 px-6">
      <div className="px-6">
        <img
          alt="logo"
          className="col-start-2 mx-auto"
          height="150"
          src="https://mento-space.nyc3.digitaloceanspaces.com/logo.svg"
          width="200"
        />
        <Typography className="animate-bounce text-center" variant="subtitle2">
          Loading . . .
        </Typography>
      </div>
    </div>
  );
};

export default Authenticate;
