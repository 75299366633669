import { useController } from 'react-hook-form';

import { Grid, TextField, Typography } from '@/ui';

import type { CompanyForm } from '@/types';
import type { FC } from 'react';
import type { Control } from 'react-hook-form';

interface CompanyAccessProps {
  control: Control<CompanyForm>;
}
const CompanyAccess: FC<CompanyAccessProps> = ({ control }) => {
  const { field: domains, fieldState: domainState } = useController({
    control,
    name: 'domains',
  });
  const { field: emails, fieldState: emailState } = useController({
    control,
    name: 'emails',
  });
  const { field: disallowedEmails, fieldState: disallowedEmailState } =
    useController({
      control,
      name: 'disallowedEmails',
    });
  return (
    <Grid spacing={4} container>
      <Grid md={4} xs={12} item>
        <Typography className="mb-6" variant="h4">
          Employee access
        </Typography>
        <Typography className="mb-6" variant="body1">
          Anyone with the listed domain email will be able to access Mento. You
          can also only allow or block specific emails within the domain or
          outside the domain.
        </Typography>
      </Grid>
      <Grid className="mt-1 flex flex-col gap-3" md={8} xs={12} item>
        <TextField
          {...domains}
          className="w-full"
          error={domainState.invalid}
          helperText={domainState.error?.message}
          label="Domains"
          rows={3}
          variant="outlined"
          multiline
        />
        <TextField
          {...emails}
          className="w-full"
          error={emailState.invalid}
          helperText={emailState.error?.message}
          label="Allow list of emails"
          rows={3}
          variant="outlined"
          multiline
        />
        <TextField
          {...disallowedEmails}
          className="w-full"
          error={disallowedEmailState.invalid}
          helperText={disallowedEmailState.error?.message}
          label="disallow list of emails"
          rows={3}
          variant="outlined"
          multiline
        />
      </Grid>
    </Grid>
  );
};
export default CompanyAccess;
