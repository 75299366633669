import { useState } from 'react';

import { ONE } from '@/constants';
import { Button } from '@/ui';

import type { UserEmployment } from '@/types';
import type { FC } from 'react';

import PastCompany from '@/components/Profile/Coach/Edit/Coach/Experience/PastCompanies/PastCompany';

interface PastCompaniesProps {
  onChange: (updated: UserEmployment[]) => void;
  pastCompanies?: UserEmployment[];
}

const PastCompanies: FC<PastCompaniesProps> = ({
  pastCompanies = [],
  onChange,
}) => {
  const [companies, setCompanies] = useState<UserEmployment[]>(
    pastCompanies.length ? JSON.parse(JSON.stringify(pastCompanies)) : [],
  );

  const onCompanyChange = (changed: UserEmployment, index: number) => {
    const updated = companies;
    updated[index] = changed;

    setCompanies(updated);
    onChange(updated);
  };
  const onCompanyRemove = (index: number) => {
    const updated: UserEmployment[] = companies.length ? [...companies] : [];
    updated.splice(index, ONE);

    setCompanies(updated);
    onChange(updated);
  };
  const onCompanyNew = () => {
    const company = {
      companyName: '',
      startedAt: new Date().toISOString(),
      title: '',
    } as UserEmployment;
    const updated = companies.length ? [...companies, company] : [company];

    setCompanies(updated);
    onChange(updated);
  };

  return (
    <div className="col-span-3 flex flex-col gap-6">
      {companies.map((company, index) => (
        <PastCompany
          key={`coach-company-${company.id || index}`}
          company={company}
          onChange={(updated) => onCompanyChange(updated, index)}
          onRemove={() => onCompanyRemove(index)}
        />
      ))}
      <div>
        <Button color="primary" variant="contained" onClick={onCompanyNew}>
          Add company
        </Button>
      </div>
    </div>
  );
};

export default PastCompanies;
