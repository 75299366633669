import { Grid, TextField, Typography } from '@/ui';
import { isValidLinkedin } from '@/utils';

import type { UserProfile } from '@/types';
import type { ChangeEvent, FC } from 'react';

interface EditCoachBioProps {
  onProfileChange: (updated: UserProfile) => void;
  profile: UserProfile;
}

const EditCoachBio: FC<EditCoachBioProps> = ({ profile, onProfileChange }) => {
  const onProfileUpdate = (changes: Partial<UserProfile>) =>
    onProfileChange({ ...profile, ...changes });

  const onEditCoachBioChange = (e: ChangeEvent<HTMLInputElement>) =>
    onProfileUpdate({ bio: e.target.value });
  const onLinkedInChange = (e: ChangeEvent<HTMLInputElement>) =>
    onProfileUpdate({
      linkedIn: e.target.value,
    });

  const linkedInMessage =
    !!profile.linkedIn && !isValidLinkedin(profile.linkedIn)
      ? 'LinkedIn url must start with https://linkedin.com/in/ or https://www.linkedin.com/in/'
      : '';

  return (
    <Grid spacing={4} container>
      <Grid md={5} xs={12} item>
        <Typography className="mb-2" variant="h4">
          Bio
        </Typography>
        <Typography className="mb-1" variant="body1">
          Tip: Dive into a few career details (e.g. your path, how many people
          you managed, insight into your company), share what brings you to
          coaching, and don&apos;t shy away from a few personal details.
        </Typography>
      </Grid>
      <Grid className="mt-1 flex flex-col gap-3" md={7} xs={12} item>
        <TextField
          label="Bio"
          rows={3}
          value={profile.bio}
          multiline
          onChange={onEditCoachBioChange}
        />
        <TextField
          error={!!linkedInMessage}
          helperText={linkedInMessage}
          label="LinkedIn"
          value={profile.linkedIn}
          onChange={onLinkedInChange}
        />
      </Grid>
    </Grid>
  );
};

export default EditCoachBio;
