import { clsx } from 'clsx';
import { useNavigate } from 'react-router';

import { Avatar, Button, Icon, Paper, Typography } from '@/ui';

import Bubble from './Bubble';

import type { User } from '@/types';
import type { FC } from 'react';

type ConversationalMilestoneProps = {
  author: User;
  dueDate: string;
  growthPlanId: string;
  title: string;
};

const ConversationalMilestone: FC<ConversationalMilestoneProps> = ({
  author,
  dueDate,
  growthPlanId,
  title,
}) => {
  const navigate = useNavigate();
  const classes = clsx(
    'conversational-milestone flex flex-col items-center justify-center gap-1 rounded-3xl px-6 py-4 md:flex-row',
  );

  const navigateToGrowthPlan = () => {
    navigate(`/m/growth/${growthPlanId}`);
  };

  return (
    <>
      <Bubble
        isUser={false}
        message={{ content: 'Step added to your Growth Plan:' }}
      />
      <Paper className={classes}>
        <Typography variant="body1">{title}</Typography>
        <div className="flex items-center justify-center gap-2">
          <Icon className="text-bloom-500" fontSize="small">
            magic_button
          </Icon>
          <Avatar
            firstName={author.firstName}
            lastName={author.lastName}
            size="xs"
            src={author.avatarUrl}
          />
          <Typography variant="caption">{dueDate}</Typography>
        </div>
      </Paper>
      <div className="mb-10 mt-6 flex items-center justify-center">
        <Button
          color="secondary"
          size="medium"
          variant="contained"
          light
          onClick={navigateToGrowthPlan}
        >
          View Growth Plan
        </Button>
      </div>
    </>
  );
};

export default ConversationalMilestone;
