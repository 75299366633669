import mentoApi from './mentoApi';

import type { Assistant } from '@/types';

export interface AssistantApiOptions {
  limit?: Number;
  page?: Number;
  search?: string;
}

export const getAssistants = async (params: AssistantApiOptions) =>
  (await mentoApi.get('/assistant', { params })).data;

export const getAssistant = async (id: string) =>
  (await mentoApi.get(`/assistant/${id}`)).data;

export const createAssistant = async (data: Partial<Assistant>) =>
  (await mentoApi.post('/assistant', data)).data;

export const updateAssistant = async (data: Partial<Assistant>) => {
  const { id, ...rest } = data;
  return (await mentoApi.put(`/assistant/${id}`, { ...rest })).data;
};

export const deleteAssistant = async (id: string) =>
  (await mentoApi.delete(`/assistant/${id}`)).data;
