import ClientCard from './ClientCard';
import Empty from './Empty';

import type { User } from '@/types';
import type { FC } from 'react';

interface ListProps {
  clients: User[];
}

const List: FC<ListProps> = ({ clients }) => {
  if (!clients.length) return <Empty />;

  return (
    <div className="grid w-full grid-cols-1 gap-4 md:grid-cols-3 lg:grid-cols-4">
      {clients.map((client) => (
        <ClientCard key={`member-list-${client.id}`} user={client} />
      ))}
    </div>
  );
};

export default List;
