import { useMutation, useQuery } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';

import { getGrowthPlan, getGrowthPlans, patchGrowthPlan } from '@/api';
import { QUERY_KEY_GROWTH_PLANS } from '@/constants';

import type { GetGrowthPlanParams, GrowthPlansApiOptions } from '@/api';
import type { GrowthPlan } from '@/types';
import type { UseQueryOptions } from '@tanstack/react-query';

const mockGrowthPlan = {
  authors: [],
  milestones: [],
  started: true,
  steps: [],
  user: {},
} as unknown as GrowthPlan;

export const useGetGrowthPlans = (
  params: GrowthPlansApiOptions,
  options?: UseQueryOptions,
) =>
  useQuery<GrowthPlan[], Error>({
    enabled: options ? options.enabled : true,
    placeholderData: [mockGrowthPlan],
    queryFn: () => getGrowthPlans(params),
    queryKey: [QUERY_KEY_GROWTH_PLANS, params],
  });

export const useGetGrowthPlan = (
  params: GetGrowthPlanParams,
  options?: UseQueryOptions,
) =>
  useQuery<GrowthPlan, Error>({
    enabled: options ? options.enabled : true,
    placeholderData: mockGrowthPlan,
    queryFn: () => getGrowthPlan(params),
    queryKey: [QUERY_KEY_GROWTH_PLANS, params.id],
  });

export const useUpdateGrowthPlan = () =>
  useMutation({ mutationFn: patchGrowthPlan });

// Use to get the right base url from the many many places a growth plan lives.
// Probably should be extended to work on a milestone, and made to be less hacky in approach,
// but this lets us reliably link to milestones from member page, inside any plan, and completed places
export const useCurrentGrowthBaseUrl = () => {
  const { pathname } = useLocation();
  const path = pathname
    .replace('/completedSteps', '') // strip out completedSteps
    .replace('/completed', '') // strip out completed
    .replace(/\/$/u, ''); // strip out trailing slasha

  if (path.includes('growth')) {
    return path;
  }
  return `${path}/growth`;
};
