import MuiMenu from '@mui/material/Menu';
import { clsx } from 'clsx';

import type { MenuProps } from '@mui/material/Menu';
import type { FC } from 'react';

const Menu: FC<MenuProps> = ({ className, ...rest }) => {
  const classes = clsx('mnt-menu', className);

  return <MuiMenu {...rest} className={classes} />;
};
Menu.displayName = 'Menu';

export default Menu;
export type { MenuProps };
