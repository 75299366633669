// this is a copy of the enum value that we have in the Rails BE
import type { IDictionary } from '@/types/common';

export enum NotificationOptions {
  Documents = 'documents',
  Email = 'email',
  GrowthPlan = 'growthPlan',
  Notes = 'notes',
  OfflineMessage = 'offlineMessage',
  Sms = 'sms',
}

export interface UserNotifications extends IDictionary<boolean> {}
