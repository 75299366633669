import mentoApi from './mentoApi';

export const updateTeamMembership = async (id: string, status: string) => {
  await mentoApi.put(`team_members/${id}`, {
    status,
  });
};

export const deleteTeamMembership = async (id: string) => {
  await mentoApi.delete(`team_members/${id}`);
};

export const addTeamMembers = async (teamId: string, emails: string[]) => {
  const result = await mentoApi.post('team_members', {
    emails,
    teamId,
  });
  return result.data;
};
