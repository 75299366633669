import { styled } from '@mui/material';
import MuiCard from '@mui/material/Card';
import { clsx } from 'clsx';
import { Children } from 'react';

import type { CardProps } from '@mui/material/Card';
import type { FC, ReactElement } from 'react';

const StyledCard = styled(MuiCard)<CardProps>(({ theme }) => ({
  '&.mnt-card-action:hover': {
    boxShadow: theme.shadows[4],
  },
  '.MuiCard-root': {
    boxShadow: theme.shadows[0],
  },

  '.MuiCardActionArea-focusHighlight': {
    background: 'none',
  },
}));

const Card: FC<CardProps> = ({ className, ...rest }) => {
  const hasActionArea = Children.toArray(rest.children).some(
    // @ts-ignore -- Some react TS shenanigans I don't have time to fix.
    (child) => (child as ReactElement).type.displayName === 'CardActionArea',
  );
  const classes = clsx('mnt-card', className, {
    'mnt-card-action': hasActionArea,
  });

  return <StyledCard {...rest} className={classes} />;
};
Card.displayName = 'Card';

export default Card;
export type { CardProps };
