import { StatusColorMap, StatusLabelMap } from '@/constants';
import { Typography } from '@/ui';

import type { User } from '@/types';
import type { FC } from 'react';

const Status: FC<User> = ({
  hasCoaches = false,
  isActive,
  isBusinessPaid,
  isPaid,
}) => {
  let key = 'All';

  const notPaid = !isBusinessPaid && !isPaid;

  if (!isActive) key = 'Inactive';
  else if (notPaid) key = 'NotPaid';
  else if (!hasCoaches) key = 'NoCoach';

  return (
    <Typography
      className={`flex items-center justify-center ${StatusColorMap[key]} h-8`}
      variant="caption"
    >
      {StatusLabelMap[key]}
    </Typography>
  );
};

export default Status;
