import { Icon, Typography } from '@/ui';

import type { FC } from 'react';

interface StatProps {
  icon: string;
  stat: string | number;
  subtitle: string;
}

const Stat: FC<StatProps> = ({ stat, subtitle, icon }) => (
  <div className="flex flex-row">
    <Icon className="mt-2 text-3xl text-black">{icon}</Icon>
    <div className="flex flex-col pl-2">
      <Typography className="text-black" variant="h5">
        {stat}
      </Typography>
      <Typography className="text-black" variant="body1">
        {subtitle}
      </Typography>
    </div>
  </div>
);

export default Stat;
