import { clsx } from 'clsx';

import Skeleton from '../Skeleton';

import TableBody from './TableBody';
import TableCell from './TableCell';
import TableRow from './TableRow';

import type { TableBodyProps } from '@mui/material/TableBody';
import type { FC } from 'react';

interface TableLoadingProps extends TableBodyProps {
  columns?: number;
  rows?: number;
}

const TableLoading: FC<TableLoadingProps> = ({
  className,
  columns = 1,
  rows = 5,
  ...rest
}) => {
  const classes = clsx('mnt-table-loading', className);

  return (
    <TableBody {...rest} className={classes}>
      {[...Array(rows)].map((_el, rowindex) => (
        /* eslint-disable react/no-array-index-key -- Dummy content. Doesn't matter. */
        <TableRow key={rowindex}>
          {[...Array(columns)].map((_element, colindex) => (
            <TableCell key={colindex}>
              <Skeleton />
            </TableCell>
          ))}
        </TableRow>
        /* eslint-enable react/no-array-index-key */
      ))}
    </TableBody>
  );
};
TableLoading.displayName = 'TableLoading';

export default TableLoading;
export type { TableLoadingProps };
