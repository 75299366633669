import { Typography } from '@/ui';

import type { FC } from 'react';

interface FilesProps {
  onClick: () => void;
  preparedFile: File;
  processing: boolean;
}

const Files: FC<FilesProps> = ({ onClick, preparedFile, processing }) => {
  if (processing) {
    return (
      <div className="align-center my-5 flex h-full items-center justify-center py-20">
        <Typography variant="subtitle1">Uploading ...</Typography>
      </div>
    );
  }

  return (
    <div className="align-center my-5 flex flex-col items-center justify-center py-20">
      <Typography variant="h5">{preparedFile.name}</Typography>
      <Typography
        className="mb-4 cursor-pointer text-sage-500 underline"
        variant="caption"
        onClick={onClick}
      >
        Choose a different file
      </Typography>
    </div>
  );
};

export default Files;
