import clsx from 'clsx';
import { useState } from 'react';

import { useCurrentUser, useDeleteNote, useModal } from '@/hooks';
import {
  Avatar,
  ConfirmModal,
  Icon,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@/ui';
import { shortDistanceFrom } from '@/utils';

import Edit from './Edit';

import type { Note as NoteType } from '@/types';
import type { FC, MouseEvent } from 'react';

interface NoteProps {
  hasHover?: boolean;
  note: NoteType;
  refetchNotes: () => void;
}

const Note: FC<NoteProps> = ({ hasHover = false, note, refetchNotes }) => {
  const { author } = note;
  const { user } = useCurrentUser();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const {
    isOpen: isDeleteModalOpen,
    closeModal: closeDeleteModal,
    openModal: openDeleteModal,
  } = useModal();
  const {
    isOpen: isEditModalOpen,
    closeModal: closeEditModal,
    openModal: openEditModal,
  } = useModal();
  const deleteNote = useDeleteNote();

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    closeDeleteModal();
    closeEditModal();
    setAnchorEl(null);
  };

  const canEditNote = user.id === note.authorId || user.superAdmin;
  const canDeleteNote = user.id === note.authorId || user.superAdmin;

  const handleDelete = () => {
    if (!canDeleteNote) {
      return;
    }
    deleteNote.mutate(
      {
        id: note.id,
      },
      {
        onSettled: () => {
          refetchNotes();
        },
      },
    );
  };

  return (
    <li
      className={clsx('group flex justify-between p-6', {
        'hover:bg-sage-100': hasHover,
      })}
    >
      <div className="w-full">
        <div className="mb-5 flex items-center gap-2">
          <Avatar
            firstName={author.firstName}
            lastName={author.lastName}
            size="s"
            src={author.avatarUrl}
          />
          <div className="items-center gap-1 text-xs text-cement-400 md:flex">
            <Typography variant="subtitle2">
              {shortDistanceFrom(new Date(note.createdAt), true)}
            </Typography>
          </div>

          <div className="ml-auto w-4">
            <IconButton
              aria-controls={open ? 'long-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              aria-label="more"
              id="long-button"
              onClick={handleClick}
            >
              <Icon>more_vert</Icon>
            </IconButton>

            <Menu
              anchorEl={anchorEl}
              anchorOrigin={{
                horizontal: 'right',
                vertical: 'bottom',
              }}
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button',
              }}
              open={open}
              transformOrigin={{
                horizontal: 'right',
                vertical: 'top',
              }}
              onClose={handleClose}
            >
              {canDeleteNote && (
                <MenuItem className="flex gap-2" onClick={openDeleteModal}>
                  <Icon>trash</Icon>
                  Delete note
                </MenuItem>
              )}

              {canEditNote && (
                <MenuItem className="flex gap-2" onClick={openEditModal}>
                  <Icon>edit</Icon>
                  Edit note
                </MenuItem>
              )}

              <ConfirmModal
                maxWidth="sm"
                open={isDeleteModalOpen}
                title="Delete"
                hideClose
                onClose={closeDeleteModal}
                onConfirm={handleDelete}
              >
                Are you sure you want to delete the private note?
              </ConfirmModal>

              <Edit
                close={closeEditModal}
                isOpen={isEditModalOpen}
                note={note}
                refetchNotes={refetchNotes}
              />
            </Menu>
          </div>
        </div>
        <Typography className="whitespace-pre-wrap md:mt-1" variant="body2">
          {note.value}
        </Typography>
      </div>
    </li>
  );
};

export default Note;
