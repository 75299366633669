import { useState } from 'react';

import { type Session, SessionListType } from '@/types';
import { Divider, Typography } from '@/ui';

import type { FC } from 'react';

import CancelSession from '@/components/Cal/cancelSession';
import RescheduleSession from '@/components/Cal/rescheduleSession';
import { SessionCard } from '@/components/Sessions/Card';

interface SectionProps {
  hideCalendarOptions?: boolean; // no point showing reschedule or cancel on past sessions
  session: Session;
  title: string;
}

const Card: FC<SectionProps> = ({ title, session, hideCalendarOptions }) => {
  const [openCancel, setOpenCancel] = useState<Session | null>();
  const [openReschedule, setOpenReschedule] = useState<Session | null>();

  const onCloseCancelSession = () => setOpenCancel(null);
  const onCloseRescheduleSession = () => setOpenReschedule(null);
  const onReschedule = () => setOpenReschedule(session);
  const onCancel = () => setOpenCancel(session);

  return (
    <>
      <Typography className="mb-2" variant="subtitle1">
        {title}
      </Typography>
      <SessionCard
        session={session}
        type={SessionListType.Upcoming}
        isEmbedded
        onCancel={!hideCalendarOptions ? onCancel : undefined}
        onClickPath={`././sessions/${session.id}`}
        onReschedule={!hideCalendarOptions ? onReschedule : undefined}
      />
      {!!openCancel && (
        <>
          <Divider />
          <CancelSession close={onCloseCancelSession} session={openCancel} />
        </>
      )}
      {!!openReschedule && (
        <RescheduleSession
          close={onCloseRescheduleSession}
          session={openReschedule}
        />
      )}
    </>
  );
};

export default Card;
