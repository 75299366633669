import { FilterValues, SortValues } from '@/types';

import type { IDictionary } from '@/types';

export const sortOptions = [
  { label: 'Most Recent', value: SortValues.MOST_RECENT },
  { label: 'Name A to Z', value: SortValues.NAME_ASC },
  { label: 'Name Z to A', value: SortValues.NAME_DESC },
];

export const StatusColorMap: IDictionary = {
  [FilterValues.All]: 'bg-sage-400',
  [FilterValues.Paid]: 'bg-sage-400',
  [FilterValues.NotPaid]: 'bg-poppy-400',
  [FilterValues.NoCoach]: 'bg-sunny-400',
  [FilterValues.HasSessions]: 'bg-bloom-400',
  [FilterValues.Inactive]: 'bg-sky-300',
};

export const StatusLabelMap: IDictionary = {
  [FilterValues.All]: 'Assigned',
  [FilterValues.Paid]: 'Paid',
  [FilterValues.NotPaid]: 'Not paid',
  [FilterValues.NoCoach]: 'No coach',
  [FilterValues.HasSessions]: 'Has sessions',
  [FilterValues.Inactive]: 'Inactive',
};

export const clientStatusData = [
  {
    color: StatusColorMap[FilterValues.All],
    label: 'All',
    value: '',
  },
  {
    color: StatusColorMap[FilterValues.Paid],
    label: StatusLabelMap[FilterValues.Paid],
    value: FilterValues.Paid,
  },
  {
    color: StatusColorMap[FilterValues.NotPaid],
    label: StatusLabelMap[FilterValues.NotPaid],
    value: FilterValues.NotPaid,
  },
  {
    color: StatusColorMap[FilterValues.NoCoach],
    label: StatusLabelMap[FilterValues.NoCoach],
    value: FilterValues.NoCoach,
  },
  {
    color: StatusColorMap[FilterValues.HasSessions],
    label: StatusLabelMap[FilterValues.HasSessions],
    value: FilterValues.HasSessions,
  },
  {
    color: StatusColorMap[FilterValues.Inactive],
    label: StatusLabelMap[FilterValues.Inactive],
    value: FilterValues.Inactive,
  },
];
