import { memo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Card, CardContent, Icon, Typography } from '@/ui';

import type { User } from '@/types';
import type { FC } from 'react';

const EmptyBoulders: FC<{ client: User }> = ({ client }) => {
  const navigate = useNavigate();

  const handleStartClick = () => {
    navigate(`/coach/members/${client.id}/growth`);
  };

  return (
    <Card>
      <CardContent>
        <div className="mb-4 grid h-12 w-12 place-items-center rounded-full bg-sage-200">
          <Icon className="!text-2xl text-sage-600">landscape</Icon>
        </div>

        <Typography className="mb-2" variant="h5">
          No milestones in the works
        </Typography>
        <Typography className="mb-4" variant="body2">
          You can co-create the growth plan by adding steps, milestones, and
          work through things together.
        </Typography>

        <Button
          className="w-full"
          variant="outlined"
          onClick={handleStartClick}
        >
          Start
        </Button>
      </CardContent>
    </Card>
  );
};

export default memo(EmptyBoulders);
