import { NotificationOptions } from '@/types';
import { Button, Icon, Typography } from '@/ui';
import { formatMonthAndYear, formatPhoneNumber } from '@/utils';

import type { User } from '@/types';
import type { FC } from 'react';

interface ClientDetailsProps {
  client: User;
  onEdit?: () => void;
}

const ClientDetails: FC<ClientDetailsProps> = ({
  client,
  onEdit = undefined,
}) => {
  const notifyEmail = !!client.userNotifications?.[NotificationOptions.Email];
  const notifySms = !!client.userNotifications?.[NotificationOptions.Sms];
  let notifications = 'Off';
  if (notifyEmail && !notifySms) {
    notifications = 'Email';
  } else if (notifySms && notifyEmail) {
    notifications = 'SMS & Email';
  } else if (notifySms) {
    notifications = 'SMS';
  }

  let account = '';
  if (client.isBusinessPaid) {
    account = `${client.profile?.company || 'Business'} subscription`;
  } else if (client.userPayment?.status === 'active') {
    account = 'DTC';
  }

  return (
    <section className="space-y-4">
      <div className="mb-4 flex items-center justify-between">
        <Typography variant="subtitle1">Account Details</Typography>
        {!!onEdit && (
          <Button
            color="primary"
            size="small"
            variant="contained"
            onClick={onEdit}
          >
            Manage
          </Button>
        )}
      </div>
      <Typography
        className="flex items-center gap-2 text-sage-600"
        variant="subtitle2"
      >
        <Icon className="text-sage-600">mail</Icon>
        {client.email}
      </Typography>
      {!!client.profile?.phone && (
        <Typography
          className="flex items-center gap-2 text-sage-600"
          variant="subtitle2"
        >
          <Icon className="text-sage-600">call</Icon>
          {formatPhoneNumber(client.profile.phone)}
        </Typography>
      )}
      <Typography
        className="flex items-center gap-2 text-sage-600"
        variant="subtitle2"
      >
        <Icon className="text-sage-600">notifications</Icon>
        {notifications}
      </Typography>
      <Typography
        className="flex items-center gap-2 text-sage-600"
        variant="subtitle2"
      >
        <Icon className="text-sage-600">account_circle</Icon>
        {account}
      </Typography>

      {client.createdAt && (
        <Typography
          className="flex items-center gap-2 text-sage-600"
          variant="subtitle2"
        >
          <Icon className="text-sage-600">device_reset</Icon>
          Member since {formatMonthAndYear(new Date(client.createdAt))}
        </Typography>
      )}
    </section>
  );
};

export default ClientDetails;
