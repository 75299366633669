import { captureException, captureMessage } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { getCurrentUser } from '@/api';
import { useView } from '@/hooks';
import { currentUserQueryKeyName } from '@/utils';

import type { User } from '@/types';

export const useCurrentUser = (setup = true) => {
  const { pathname } = useLocation();
  const { isClient } = useView();
  const enabled =
    !pathname.includes('signin') &&
    !pathname.includes('reset') &&
    !pathname.includes('authenticate') &&
    setup;

  const {
    data: user = {} as User,
    error,
    isLoading,
    refetch: refetchUser,
  } = useQuery([currentUserQueryKeyName(isClient)], () => getCurrentUser(), {
    enabled,
  });

  useEffect(() => {
    if (error) {
      captureMessage('useCurrentUser received error', 'error');
      captureException(error);
    }
  }, [isLoading, user, isClient]);

  return {
    // This way invalid users will show up as "loading" instead of broken
    isUserLoading: isLoading || !user.id,
    refetchUser,
    user,
    userEnabled: enabled,
  };
};
