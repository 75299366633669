import { Fade } from '@mui/material';
import { forwardRef } from 'react';

import Button from '../Button';
import Dialog from '../Dialog/Dialog';
import DialogActions from '../Dialog/DialogActions';
import DialogContent from '../Dialog/DialogContent';
import DialogTitle from '../Dialog/DialogTitle';
import Icon from '../Icon';

import type { DialogProps } from '../Dialog/Dialog';
import type { TransitionProps } from '@mui/material/transitions';
import type { ReactElement, ReactNode, Ref } from 'react';

const Transition = forwardRef(
  (
    props: TransitionProps & {
      children: ReactElement;
    },
    ref: Ref<unknown>,
  ) => <Fade ref={ref} timeout={500} in {...props} />,
);
Transition.displayName = 'Slide';

interface ConfirmModalProps extends Omit<DialogProps, 'title' | 'onClose'> {
  className?: string;
  classNameActions?: string;
  classNameContent?: string;
  classNameTitle?: string;
  hideClose?: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title?: ReactNode;
}

const ConfirmModal = ({
  children,
  className = '',
  classNameActions = '',
  classNameContent = '',
  classNameTitle = '',
  fullScreen,
  fullWidth = true,
  hideClose = false,
  maxWidth = 'md',
  onClose,
  onConfirm,
  open,
  scroll = 'body',
  title = '',
}: ConfirmModalProps) => (
  <Dialog
    classes={{ paper: className }}
    fullScreen={fullScreen}
    fullWidth={fullWidth}
    maxWidth={maxWidth}
    open={open}
    scroll={scroll}
    TransitionComponent={Transition}
    onClose={onClose}
  >
    {!hideClose && (
      <Button
        color="primary"
        sx={{
          height: 40,
          minWidth: 40,
          p: '5px',
          position: 'absolute',
          /* eslint-disable @typescript-eslint/no-magic-numbers -- CSS values make little sense as constants. */
          right: title ? 20 : 10,
          top: title ? 20 : 10,
          /* eslint-enable @typescript-eslint/no-magic-numbers */
          width: 40,
        }}
        variant="contained"
        onClick={onClose}
      >
        <Icon style={{ fontSize: 20, lineHeight: 1 }}>close</Icon>
      </Button>
    )}

    {!!title && <DialogTitle className={classNameTitle}>{title}</DialogTitle>}
    <DialogContent className={classNameContent}>{children}</DialogContent>
    <DialogActions className={classNameActions}>
      <Button color="gray" variant="outlined" onClick={onClose}>
        Cancel
      </Button>
      <Button color="warning" variant="contained" onClick={onConfirm}>
        Delete
      </Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmModal;
export type { ConfirmModalProps };
