import { useView } from '@/hooks';
import { Button, Typography } from '@/ui';

import Today from './Today';

const Session = () => {
  const { isClient } = useView();

  if (isClient) return null;

  return (
    <>
      <div className="flex items-center justify-between ">
        <Typography className="mb-4 mt-6 text-white" variant="h5">
          Next session, today
        </Typography>
        <Button
          color="white"
          href="/sessions/upcoming"
          size="small"
          variant="outlined"
        >
          See all sessions
        </Button>
      </div>
      <Today />
    </>
  );
};

export default Session;
