const sunny = {
  100: '#FEFBF7',
  200: '#FBF5EC',
  300: '#FFEBB2',
  400: '#FDE288',
  500: '#FFCF27',
  600: '#E1B30F',
} as const;

const sage = {
  100: '#F6F8F6',
  200: '#EDF2F0',
  300: '#D7E1DB',
  400: '#BDCCBE',
  500: '#637A6F',
  600: '#505A54',
} as const;

const ocean = {
  100: '#F8F9FA',
  200: '#F1F3F4',
  300: '#D9DFE2',
  400: '#B8C4CA',
  500: '#4D6F80',
  600: '#495D67',
} as const;

const poppy = {
  100: '#FFF5F2',
  200: '#FFE6DF',
  300: '#FFCDBF',
  400: '#FFA991',
  500: '#FF8462',
  600: '#C65232',
} as const;

const cement = {
  100: '#F4F4F4',
  200: '#E3E3E3',
  300: '#A4A4A4',
  400: '#5C5C5C',
  500: '#262626',
  600: '#060606',
} as const;

const bloom = {
  100: '#FFF9FF',
  200: '#FFF2FE',
  300: '#FCE4FB',
  400: '#DDA2D9',
  500: '#A4599F',
  600: '#804D7C',
} as const;

const sky = {
  100: '#F3F8FF',
  200: '#DCE9FF',
  300: '#B0C7F3',
  400: '#96B0E9',
  500: '#5674B7',
  600: '#344B81',
} as const;

const fire = {
  100: '#FEF8F6',
  200: '#FAE6E1',
  300: '#F7C8BA',
  400: '#E88A7D',
  500: '#BC4636',
  600: '#A33D1D',
} as const;

const common = {
  black: '#000000',
  white: '#FFFFFF',
} as const;

export { bloom, cement, common, fire, ocean, poppy, sage, sky, sunny };
