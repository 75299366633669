import { Card, CardContent, Icon, Typography } from '@/ui';

import type { UserEmployment } from '@/types';
import type { FC } from 'react';

interface CompanyProps {
  company: UserEmployment;
}

const Company: FC<CompanyProps> = ({ company }) => (
  <Card className="w-full bg-sage-500 text-white" elevation={0}>
    <CardContent className="flex w-full flex-row gap-4 p-4">
      <div className="flex h-12 w-12 items-center justify-center rounded-full bg-sage-600">
        <Icon>domain</Icon>
      </div>
      <Typography color="white" variant="body1">
        {company.title} at {company.companyName}
      </Typography>
    </CardContent>
  </Card>
);

export default Company;
