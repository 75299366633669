import { isToday } from 'date-fns';
import { useState } from 'react';

import { ONE } from '@/constants';
import { useCurrentUser, useSessions } from '@/hooks';
import { type Session, SessionListType, SessionStatus } from '@/types';
import { Card, Icon, Skeleton, Typography } from '@/ui';
import { track } from '@/utils';

import CancelSession from '@/components/Cal/cancelSession';
import RescheduleSession from '@/components/Cal/rescheduleSession';
import { SessionCard } from '@/components/Sessions/Card';

const Today = () => {
  const { user } = useCurrentUser();
  const [openCancel, setOpenCancel] = useState<Session | null>();
  const [openReschedule, setOpenReschedule] = useState<Session | null>();
  const {
    data: sessions = {
      items: {} as Record<string, Session[]>,
      pages: ONE,
      total: ONE,
    },
    isLoading,
    refetch,
  } = useSessions({
    limit: ONE,
    page: ONE,
    statuses: [SessionStatus.ACTIVE, SessionStatus.RESCHEDULED],
    userId: user.id,
  });

  const nextSession = Object.values<Session[]>(sessions.items)[0];
  const session =
    nextSession?.length &&
    nextSession[0]?.startedAt &&
    isToday(new Date(nextSession[0].startedAt))
      ? nextSession[0]
      : null;

  if (isLoading) {
    return <Skeleton animation="wave" className="h-40" variant="rectangular" />;
  }

  if (!session) {
    return (
      <Card className="flex h-40 w-full flex-col items-center justify-center gap-3 overflow-hidden bg-black/20 p-4 text-center lg:drop-shadow">
        <Icon className="text-5xl	text-white">calendar_today</Icon>
        <Typography className="text-white opacity-100">
          No sessions scheduled today
        </Typography>
      </Card>
    );
  }

  const handleOnReschedule = (val: Session) => {
    track('Clicked Reschedule Session', {
      bookId: val.bookId,
      sessionId: val.id,
    });
    setOpenReschedule(session);
  };

  const handleOnCancel = (val: Session) => {
    track('Clicked Cancel Session', {
      bookId: val.bookId,
      sessionId: val.id,
    });
    setOpenCancel(session);
  };

  const onCloseModal = () => {
    refetch();
    setOpenCancel(null);
    setOpenReschedule(null);
  };

  return (
    <>
      <SessionCard
        session={session}
        type={SessionListType.Upcoming}
        onCancel={handleOnCancel}
        onClickPath={`/sessions/${session.id}`}
        onReschedule={handleOnReschedule}
      />
      {!!openCancel && (
        <CancelSession close={onCloseModal} session={openCancel} />
      )}
      {!!openReschedule && (
        <RescheduleSession close={onCloseModal} session={openReschedule} />
      )}
    </>
  );
};

export default Today;
