import { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useLocation } from 'react-router-dom';

import { ONE } from '@/constants';
import {
  useCurrentUser,
  useGrowthPlan,
  useGrowthPlans,
  useUpdateGrowthPlan,
} from '@/hooks';
import { Typography } from '@/ui';
import { track } from '@/utils';

import Authors from './Authors';
import FirstTimeDialog from './FirstTimeDialog';
import MilestoneList from './MilestoneList';
import MilestonesOverview from './MilestonesOverview';
import StepList from './StepList';
import StepsOverview from './StepsOverview';
import bg from './bg.png';

import Data from '@/components/Growth/Data';
import VerticalLine from '@/components/Growth/VerticalLine';

const Dashboard = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { pathname } = useLocation();
  const { plan, isPlanLoading, refetch: refetchPlan } = useGrowthPlan();
  const { plans, refetch: refetchPlans } = useGrowthPlans();
  const { user } = useCurrentUser();
  const updateGrowthPlan = useUpdateGrowthPlan();

  const showCompleted = pathname.split('/').pop() === 'completedSteps';

  const handleDialogClose = () => {
    updateGrowthPlan.mutate(
      { id: plan.id, started: true },
      {
        onSuccess: () => {
          refetchPlan();
          refetchPlans();
        },
      },
    );
    setIsDialogOpen(false);
  };

  useEffect(() => {
    track('View Growth Plan');
  }, []);

  useEffect(() => {
    if (!plan.started && plan.user.id === user.id) {
      setIsDialogOpen(true);
    }
  }, [plan]);

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- TODO: change be. This fixes sentry issue for now. Needs BE changes.
  if (isPlanLoading || !plan) {
    return null;
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <div
        className="flex flex-col bg-white bg-contain bg-right-top bg-no-repeat lg:flex-row"
        style={{ backgroundImage: `url(${bg})` }}
      >
        <div className="col-span-1  flex flex-col bg-sunny-100 px-4 py-12 lg:min-h-screen lg:min-w-94 lg:max-w-94 lg:px-8">
          {plans && plans.length === ONE ? (
            <Typography className="mb-9" variant="h4">
              Growth Plan
            </Typography>
          ) : null}
          <Authors />
          <Data />
          <VerticalLine className="h-4" />
          <MilestoneList />
        </div>
        <div className="box-border flex flex-col ">
          <section
            className="flex-1 px-4 pt-6 lg:mt-2 lg:p-12"
            role="complementary"
          >
            {!showCompleted ? (
              <>
                <Typography className="mb-6" variant="h5">
                  Just getting started, {plan.user.firstName}!
                </Typography>

                <div className="mb-10 flex justify-between gap-5">
                  <StepsOverview />
                  <MilestonesOverview />
                </div>
              </>
            ) : null}

            <StepList showCompleted={showCompleted} />
          </section>
        </div>
      </div>
      <FirstTimeDialog isOpen={isDialogOpen} onClose={handleDialogClose} />
    </DndProvider>
  );
};

export default Dashboard;
