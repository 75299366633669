import mentoApi from './mentoApi';

import type { Note } from '@/types';

export const getNewNotes = async (
  owneableId: string,
  page: number,
  limit = 10,
) =>
  (
    await mentoApi.get(
      `notes?owneable_id=${owneableId}&page=${page}&limit=${limit}`,
    )
  ).data;

export const createNewNote = async (note: Partial<Note>): Promise<Note> =>
  (await mentoApi.post('/notes', { note })).data;

export const editNewNote = async (note: Partial<Note>): Promise<Note> =>
  (await mentoApi.put(`/notes/${note.id}`, { note })).data;

export const deleteNewNote = async (note: Partial<Note>) =>
  (await mentoApi.delete(`/notes/${note.id}`)).data;
