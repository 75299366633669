import { captureException } from '@sentry/react';
import clsx from 'clsx';
import { type FC, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { updateTeamMembership } from '@/api';
import { AppContext } from '@/context';
import { useCurrentUser, useTeam } from '@/hooks';
import { AppMessageSeverities, TeamStatus } from '@/types';
import { Avatar, AvatarGroup, Button, Card as MuiCard, Typography } from '@/ui';

interface TeamProps {
  id: string;
}

const Card: FC<TeamProps> = ({ id }) => {
  const { user } = useCurrentUser();
  const { setAppMessage } = useContext(AppContext);
  const navigate = useNavigate();
  const { team, isTeamLoading, refetchTeam } = useTeam(id);

  if (isTeamLoading) return null;

  const { name: teamName, teamMembers, currentUserStatus } = team;
  const isPending = currentUserStatus === 'pending';

  const onClick = () => navigate(`/teams/${id}`);

  const handleOnAcceptClick = async () => {
    const teamMemberId = teamMembers.find(
      (teamMember) => teamMember.user.email === user.email,
    )?.id;
    if (!teamMemberId) return;
    try {
      await updateTeamMembership(teamMemberId, TeamStatus.accepted);
      refetchTeam();
      navigate(`/teams/${id}`);
    } catch (error) {
      setAppMessage({
        message: 'Error accepting invite',
        open: true,
        severity: AppMessageSeverities.Error,
      });
      captureException(error);
    }
  };

  return (
    <MuiCard
      className={clsx('cursor-pointer rounded-xl p-6 hover:shadow-md', {
        'bg-bloom-200': isPending,
      })}
      onClick={onClick}
    >
      <div>
        <Typography variant="h5">
          {isPending && <span className="text-bloom-600">Invited to the </span>}
          {teamName}
        </Typography>
      </div>
      <div className="mt-2">
        <Typography color="text.secondary" variant="body2">
          {teamMembers.length} teammates
        </Typography>
      </div>
      <div className="flex justify-between pt-4">
        <AvatarGroup max={4} size="m">
          {teamMembers.map((teamMember) => (
            <Avatar
              key={`team-member-avatar-${teamMember.id}`}
              firstName={teamMember.user.firstName}
              sizes="small"
              src={teamMember.user.avatarUrl}
            />
          ))}
        </AvatarGroup>
        {isPending && (
          <Button
            color="info"
            variant="contained"
            onClick={handleOnAcceptClick}
          >
            Join
          </Button>
        )}
      </div>
    </MuiCard>
  );
};

export default Card;
