import { ONE } from '@/constants';
import { useCurrentUser, useView } from '@/hooks';
import { SurveyStatus } from '@/types';
import { Avatar, Button, Icon, Typography } from '@/ui';

import type { Avatar as AvatarType, User } from '@/types';
import type { FC } from 'react';

interface StartProps {
  completedAt: string;
  isExample: boolean;
  name: string;
  next: () => void;
  results: number;
  status: SurveyStatus;
  title: string;
}

const Start: FC<StartProps> = ({
  completedAt,
  isExample,
  name,
  next,
  results,
  status,
  title,
}) => {
  // TODO: Check that this actually has coaches, if not
  // change it to get the coaches
  const { user } = useCurrentUser();
  const { isClient, selectedUser } = useView();
  const actualUser = isClient ? user : selectedUser;
  const username = !isExample ? actualUser.firstName : 'Example Mento member';

  let avatar: AvatarType | undefined;

  if (status !== SurveyStatus.SHARED && !isExample && isClient) {
    const coach = actualUser.coaches[0] as User | undefined;

    // TODO: Re-define the coach/client type once user exists
    // with that, we avoid this "partial" sort of logic that leads
    // to us having to add defaults in many places that should not be
    // needed
    if (coach?.id) {
      avatar = {
        firstName: coach.firstName,
        lastName: coach.lastName,
        src: coach.avatarUrl ?? '',
      };
    }
  }

  const content = avatar ? (
    <div className="flex items-center gap-2 rounded-full bg-bloom-500 p-2">
      <Avatar size="s" {...avatar} />
      <Typography variant="body2">
        Review with your coach {avatar.firstName} during your next session
      </Typography>
    </div>
  ) : (
    <Button
      color="white"
      endIcon={<Icon>arrow_forward</Icon>}
      variant="contained"
      onClick={next}
    >
      Get Started
    </Button>
  );

  return (
    <div className="mb-9 flex h-full flex-col items-center justify-center gap-4 text-white">
      <Typography variant="h2">{name}</Typography>
      <Typography className="text-bloom-200" variant="subtitle1">
        <span>
          {username}&apos;s {title} Summary
        </span>
        <span>
          &nbsp;&bull;&nbsp;
          {results} response{results !== ONE ? 's' : ''}
        </span>
        {!!completedAt && (
          <span>
            &nbsp;&bull;&nbsp;
            {new Date(completedAt).toLocaleDateString('en', {
              month: 'long',
              year: 'numeric',
            })}
          </span>
        )}
      </Typography>

      {!!results && content}
    </div>
  );
};

export default Start;
