import { ONE } from '@/constants';
import { Divider, List } from '@/ui';

import Instruction from './Instruction';

import type { SurveyStep } from '@/types';
import type { FC } from 'react';

interface InstructionsProps {
  steps: SurveyStep[];
}

const Instructions: FC<InstructionsProps> = ({ steps }) => (
  <List className="flex flex-col divide-y">
    {steps.map((step, index) => (
      <div key={`builder-steps-${step.id}`}>
        <Instruction {...step} />
        {index !== steps.length - ONE && <Divider />}
      </div>
    ))}
  </List>
);

export default Instructions;
