import Case from 'case';
import { format } from 'date-fns';

import { THOUSAND } from '@/constants';
import { UserPaymentStatus } from '@/types';

import type { User } from '@/types';

export const getPlanDetails = (user: User, planDetails: unknown = {}) => {
  const planName = user.userPayment?.plan || 'No Plan';
  const status = user.userPayment?.status || '';

  if (status === UserPaymentStatus.canceled) {
    const info = (planDetails as { canceledAt?: number }).canceledAt;
    const secondaryInfo = info
      ? ` on: ${format(new Date(info * THOUSAND), 'MM/dd/yyyy')}`
      : '';
    return {
      planName: Case.title(planName),
      secondaryInfo,
      status: Case.title(status),
    };
  }

  // status=paused is only available for subscriptions with trials.
  // check paymentsubscription.pause_collection.behavior object instead
  const hasPause = (planDetails as { pauseCollection?: { behavior?: string } })
    .pauseCollection?.behavior;
  if (hasPause) {
    const info = (planDetails as { pauseCollection: { resumesAt?: number } })
      .pauseCollection.resumesAt;
    const secondaryInfo = info
      ? ` Resumes at: ${format(new Date(info * THOUSAND), 'MM/dd/yyyy')}`
      : 'No resume date';
    return {
      planName: Case.title(planName),
      secondaryInfo,
      status: 'Paused',
    };
  }

  let secondaryInfo = '';
  const nextBillingDate = (planDetails as { currentPeriodEnd?: number })
    .currentPeriodEnd;
  if (planName && nextBillingDate) {
    secondaryInfo = `Renews: ${format(
      new Date(nextBillingDate * THOUSAND),
      'MM/dd/yyyy',
    )}`;
  }

  return {
    planName: Case.title(planName),
    secondaryInfo,
    status: Case.title(status),
  };
};
