import { captureException } from '@sentry/react';
import { useContext, useEffect, useState } from 'react';

import { COACHING_STYLE_OB, TWO, ZERO } from '@/constants';
import { useCurrentUser } from '@/hooks';
import { Button, Typography } from '@/ui';
import { track } from '@/utils';

import type { CoachingStyleType } from '@/types';

import { updateUserAssesment } from '@/api/userAssesment';
import OptionsCard from '@/components/GetStarted/OptionCard';
import { GetStartedContext } from '@/components/GetStarted/context';

// TODO: there's a bunch of common things in these components
// we can probably abstract most of it away
const CoachingStyle = () => {
  const { user, refetchUser } = useCurrentUser();
  const { nextStep } = useContext(GetStartedContext);

  const [saving, setSaving] = useState(false);
  const [selected, setSelected] = useState<string[]>(
    user.userAssesment?.coachingNeeds ?? [],
  );

  useEffect(() => {
    track('ob_coaching');
  }, []);

  useEffect(() => {
    if (user.userAssesment?.coachingNeeds) {
      const found = COACHING_STYLE_OB.filter(
        (o) =>
          (user.userAssesment?.coachingNeeds || []).indexOf(o.value) >= ZERO,
      );

      setSelected(found.map((f) => f.value));
    }
  }, [user.userAssesment?.coachingNeeds]);

  const handleOnChange = (value: boolean, option: CoachingStyleType) => {
    if (value) {
      const newVal = [...selected, option.value];
      setSelected(newVal);
    } else {
      setSelected(selected.filter((s) => s !== option.value));
    }
  };

  const onContinue = async () => {
    try {
      setSaving(true);

      await updateUserAssesment(user.id, {
        coachingNeeds: [...selected],
        complete: true,
      });

      await refetchUser();
      track('ob_coaching_style_cont');
      nextStep();
    } catch (e) {
      captureException(e);
    } finally {
      setSaving(false);
    }
  };

  return (
    <main className="bg-gradient-dark-sunny flex flex-1 flex-col overflow-auto">
      <div className="flex flex-col pb-20 md:pb-0">
        <div className="container mx-auto p-4 text-center md:mt-4">
          <Typography className="mb-5" variant="h4">
            What are you looking for most from a&nbsp;
            <span className="bg-gradient-to-br from-poppy-500 to-bloom-500 bg-clip-text text-transparent">
              coach?
            </span>
          </Typography>
          <Typography className="mx-auto mb-8 max-w-100" variant="body1">
            Select up to 2
          </Typography>
          <div className="container mx-auto pt-2 md:max-w-150">
            <div className="mx-auto grid w-full gap-4 text-left md:mt-4 lg:grid-cols-2">
              {COACHING_STYLE_OB.map((option) => (
                <OptionsCard
                  key={option.value}
                  disabled={saving || selected.length === TWO}
                  icon={option.icon}
                  isChecked={selected.indexOf(option.value) >= ZERO}
                  label={option.value}
                  subtitle={option.subtitle}
                  shadow
                  onChange={(value) => {
                    handleOnChange(value, option);
                  }}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="fixed bottom-0 mt-auto w-full px-6 pb-4 text-center text-sm md:static md:mb-6 md:mt-0 md:py-6">
        <Button
          className="w-full min-w-72 md:w-72"
          color="primary"
          loading={saving}
          variant="contained"
          onClick={onContinue}
        >
          Continue
        </Button>
      </div>
    </main>
  );
};

export default CoachingStyle;
