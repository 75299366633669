import { useNavigate } from 'react-router-dom';

import { useCreateMessageThread } from '@/hooks';
import { WORKFLOW_CATEGORY } from '@/types';
import { TextFieldWithButton } from '@/ui';

import Starters from './Starters';

import type { MessageThread } from '@/types';

const Workflows = () => {
  const createMessageThread = useCreateMessageThread();
  const navigate = useNavigate();

  const onSubmit = (content: string) => {
    const onSuccess = (data: MessageThread) => {
      navigate(`/m/workflow/${data.id}`);
    };

    createMessageThread.mutate(
      {
        content,
        workflow: WORKFLOW_CATEGORY.GROW,
      },
      { onSuccess },
    );
  };

  return (
    <div className="mb-6">
      <div className="w-full pr-4 md:w-182 md:pr-0">
        <TextFieldWithButton
          buttonIcon="arrow_upward"
          className="w-full rounded-xl bg-white"
          disabled={createMessageThread.isLoading}
          placeholder="What do you want to work on today? Let's create a plan."
          onSubmit={onSubmit}
        />
        <Starters />
      </div>
    </div>
  );
};

export default Workflows;
