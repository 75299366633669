import { ONE, ZERO } from '@/constants';
import { DragDirections, DragTypes } from '@/types';

import type { Boulder, DroppedStep, MilestoneStep } from '@/types';
import type { DragSourceMonitor, DropTargetMonitor } from 'react-dnd';

// TODO: Move this to design system? Design needs to define Drag & Drop stylings.
const DRAG_OPACITY = 0.2;
export const dragStepConfig =
  (
    onReorder: (origin: string, destination: string) => Promise<void>,
    step: MilestoneStep,
  ) =>
  () => ({
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
      opacity: monitor.isDragging() ? DRAG_OPACITY : ONE,
    }),
    end: (item: { id: string }, monitor: DragSourceMonitor) => {
      const dropped = monitor.getDropResult();
      if (!dropped || !item.id) return;

      const droppedStep = dropped as DroppedStep;

      onReorder(item.id, droppedStep.id);
    },
    item: { ...step },
    type: DragTypes.STEP,
  });

const getDirection = (monitor: DropTargetMonitor) =>
  (monitor.getDifferenceFromInitialOffset()?.y ?? ZERO) > ZERO
    ? DragDirections.DOWN
    : DragDirections.UP;

export const dropBoulderConfig = (boulder: Boulder) => () => ({
  accept: DragTypes.STEP,
  collect: (monitor: DropTargetMonitor) => ({
    direction: getDirection(monitor),
    isOver: monitor.isOver({ shallow: true }) && monitor.canDrop(),
    step: false,
  }),
  drop: (_: unknown, monitor: DropTargetMonitor) => {
    if (!monitor.didDrop()) {
      return { ...boulder };
    }
    return null;
  },
});

export const dropStepConfig = (step: MilestoneStep) => () => ({
  accept: DragTypes.STEP,
  collect: (monitor: DropTargetMonitor) => ({
    direction: getDirection(monitor),
    isOver: monitor.isOver({ shallow: true }),
  }),
  drop: (_: unknown, monitor: DropTargetMonitor) => {
    if (!monitor.didDrop()) {
      return { ...step, isStep: true };
    }
    return null;
  },
});

export const reorder = (
  stepId: string,
  destinationId: string,
  steps: MilestoneStep[],
) => {
  const origin = steps.findIndex((s) => s.id === stepId);
  const destination = steps.findIndex((s) => s.id === destinationId);

  let reordered = [...steps];
  if (origin >= ZERO && destination >= ZERO) {
    const originItem = steps[origin] as MilestoneStep;
    reordered.splice(origin, ONE);
    reordered.splice(destination, ZERO, originItem);
    reordered = reordered.map((step, order) => ({ ...step, order }));
  }

  return reordered;
};
