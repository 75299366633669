import { useCurrentUser, useView } from '@/hooks';
import { Card, Icon, Skeleton, Typography } from '@/ui';

import ClientCard from '@/pages/Members/List/ClientCard';

const Coachees = () => {
  const { user, isUserLoading } = useCurrentUser();
  const { isClient } = useView();

  if (isClient) return null;

  if (isUserLoading) {
    return (
      <>
        <Typography className="mb-4 mt-6 text-white" variant="h5">
          Members
        </Typography>
        <div className="grid w-full grid-cols-1 gap-4 md:grid-cols-3 lg:grid-cols-4">
          <Skeleton
            animation="wave"
            className="h-96 w-80"
            variant="rectangular"
          />
          <Skeleton
            animation="wave"
            className="h-96 w-80"
            variant="rectangular"
          />
        </div>
      </>
    );
  }

  if (!user.coachees.length) {
    return (
      <>
        <Typography className="mb-4 mt-6 text-white" variant="h5">
          Members
        </Typography>
        <Card className="flex h-96 w-80 flex-col items-center justify-center gap-3 overflow-hidden bg-black/20 p-4 text-center lg:drop-shadow">
          <Icon className="text-5xl text-white">group</Icon>
          <Typography className="text-white opacity-100">
            You currently don't have any Members assigned
          </Typography>
        </Card>
      </>
    );
  }

  return (
    <>
      <Typography className="mb-4 mt-6 text-white" variant="h5">
        Members
      </Typography>
      <div className="grid w-full grid-cols-1 gap-4 md:grid-cols-3 lg:grid-cols-4">
        {user.coachees.map((coachee) => (
          <ClientCard
            key={`member-list-${coachee.id}`}
            user={coachee}
            hideStatus
          />
        ))}
      </div>
    </>
  );
};

export default Coachees;
