import { type FC, useEffect } from 'react';
import { useController, useForm } from 'react-hook-form';

import { ZERO } from '@/constants';
import {
  Button,
  DialogActions,
  DialogContent,
  Dialog as GenericDialog,
  Icon,
  TextField,
  Typography,
} from '@/ui';

interface DialogProps {
  body: string;
  callback: (value: string) => void;
  icon: string;
  initialValue: string;
  isOpen: boolean;
  limit: number;
  setOpen: (value: boolean) => void;
  title: string;
}

const Dialog: FC<DialogProps> = ({
  body,
  callback,
  icon,
  initialValue = '',
  isOpen,
  limit,
  setOpen,
  title,
}) => {
  const {
    control,
    formState: { isValid },
    handleSubmit,
    reset,
    watch,
  } = useForm({
    defaultValues: {
      text: initialValue,
    },
  });
  const { field, fieldState } = useController({
    control,
    name: 'text',
    rules: { maxLength: limit, required: true },
  });
  const currentValue = watch();

  const getLengthHelperText = (max: number, current?: number) =>
    `${max - (current || ZERO)} characters left`;

  const handleClose = () => {
    reset();
    setOpen(false);
  };
  const onSubmit = handleSubmit(({ text }) => {
    callback(text);
  });

  useEffect(() => {
    reset({ text: initialValue });
  }, [initialValue]);

  return (
    <GenericDialog maxWidth="md" open={isOpen} fullWidth>
      <DialogContent>
        <form
          className="flex flex-col md:grid md:grid-cols-5"
          id="patch-growth-form"
          onSubmit={onSubmit}
        >
          <div className="col-span-2 mb-4 md:mb-0">
            <div className="flex h-12 w-12 items-center justify-center rounded-full bg-sage-600 text-white">
              <Icon>{icon}</Icon>
            </div>
            <Typography className="my-4" variant="h4">
              {title}
            </Typography>
            <Typography variant="body1">{body}</Typography>
          </div>
          <div className="col-span-3 md:ml-10">
            <TextField
              className="w-full"
              error={fieldState.invalid}
              helperText={getLengthHelperText(limit, currentValue.text.length)}
              inputProps={{ maxLength: limit }}
              label={title}
              rows={10}
              multiline
              {...field}
            />
          </div>
        </form>
      </DialogContent>
      <DialogActions>
        <div className="ml-4 flex items-end justify-end gap-4">
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            disabled={!isValid}
            form="patch-growth-form"
            type="submit"
            variant="contained"
          >
            Save
          </Button>
        </div>
      </DialogActions>
    </GenericDialog>
  );
};

export default Dialog;
