import ClientCardLoading from './List/ClientCard/ClientCardLoading';

const MembersLoading = () => {
  const NUMBER = 16;

  return (
    <div className="grid w-full grid-cols-1 gap-4 md:grid-cols-3 lg:grid-cols-4">
      {[...Array(NUMBER)].map((_element, index) => (
        /* eslint-disable-next-line react/no-array-index-key -- Dumb component. Doesn't matter as this array will never change. */
        <ClientCardLoading key={index} />
      ))}
    </div>
  );
};

export default MembersLoading;
