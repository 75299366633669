import { useContext, useEffect, useState } from 'react';

import { CAREER_GOALS_OB, ZERO } from '@/constants';
import { useCurrentUser } from '@/hooks';
import { Button, Typography } from '@/ui';
import { track } from '@/utils';

import { updateUserAssesment } from '@/api/userAssesment';
import OptionsCard from '@/components/GetStarted/OptionCard';
import { GetStartedContext } from '@/components/GetStarted/context';

const CareerGoal = () => {
  const { user, refetchUser } = useCurrentUser();
  const { nextStep } = useContext(GetStartedContext);

  const [saving, setSaving] = useState(false);
  const [selected, setSelected] = useState<string[]>(
    user.userAssesment?.careerGoals ?? [],
  );

  useEffect(() => {
    track('ob_goal');
  }, []);

  useEffect(() => {
    if (user.userAssesment?.careerGoals.length) {
      const found = CAREER_GOALS_OB.filter(
        (o) => (user.userAssesment?.careerGoals || []).indexOf(o.value) >= ZERO,
      );

      setSelected(found.map((f) => f.value));
    }
  }, [user.userAssesment?.careerGoals]);

  const onContinue = async () => {
    try {
      setSaving(true);

      await updateUserAssesment(user.id, {
        careerGoals: [...selected],
      });
      await refetchUser();
      track('ob_goal_cont');
      nextStep();
    } catch (e) {
      console.error(e);
    } finally {
      setSaving(false);
    }
  };

  return (
    <main className="bg-gradient-dark-sunny flex flex-1 flex-col overflow-auto">
      <div className="flex flex-col pb-20 md:pb-0">
        <div className="container mx-auto p-4 text-center md:mt-4">
          <Typography className="mb-5" variant="h4">
            What is your most immediate&nbsp;
            <span className="bg-gradient-to-br from-poppy-500 to-bloom-500 bg-clip-text text-transparent">
              career goal?
            </span>
          </Typography>
          <Typography className="mx-auto mb-8 max-w-100" variant="body1">
            Select all that apply
          </Typography>
          <div className="container mx-auto pt-2 md:max-w-200">
            <div className="mx-auto grid w-full gap-4 text-left md:mt-4 lg:grid-cols-4">
              {CAREER_GOALS_OB.map((option) => (
                <OptionsCard
                  key={option.value}
                  disabled={saving}
                  icon={option.icon}
                  isChecked={selected.indexOf(option.value) >= ZERO}
                  label={option.value}
                  shadow
                  onChange={(value) => {
                    if (value) {
                      setSelected([...selected, option.value]);
                    } else {
                      setSelected(selected.filter((s) => s !== option.value));
                    }
                  }}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="fixed bottom-0 mt-auto w-full px-6 pb-4 text-center text-sm md:static md:mb-6 md:mt-0 md:py-6">
        <Button
          className="w-full min-w-72 md:w-72"
          color="primary"
          loading={saving}
          variant="contained"
          onClick={onContinue}
        >
          Continue
        </Button>
      </div>
    </main>
  );
};

export default CareerGoal;
