import MuiMenuItem from '@mui/material/MenuItem';
import { clsx } from 'clsx';

import type { MenuItemProps } from '@mui/material/MenuItem';
import type { FC } from 'react';

const MenuItem: FC<MenuItemProps> = ({ className, ...rest }) => {
  const classes = clsx('mnt-menu-item', className);

  return <MuiMenuItem {...rest} className={classes} />;
};
MenuItem.displayName = 'MenuItem';

export default MenuItem;
export type { MenuItemProps };
