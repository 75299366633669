import SessionsLayout from '@/components/Layouts/Sessions';
import Past from '@/components/Sessions/Past';

const PastSessions = () => (
  <SessionsLayout>
    <Past />
  </SessionsLayout>
);

export default PastSessions;
