import { clsx } from 'clsx';
import { useState } from 'react';

import { ONE, ZERO } from '@/constants';
import { useGetMessageThreads } from '@/hooks';
import { CircularProgress, Pagination, Typography } from '@/ui';

import SessionCard from './SessionCard';

import type { MessageThread } from '@/types';
import type { ChangeEvent, FC } from 'react';

type WorkflowsSessionHistoryProps = {
  className?: string;
};

const WorkflowsSessionHistory: FC<WorkflowsSessionHistoryProps> = ({
  className,
}) => {
  const [page, setPage] = useState(ONE);
  const { data: messageThreads = { items: [], pages: ZERO }, isLoading } =
    useGetMessageThreads({
      page,
    });
  const classes = clsx('ai-session-history pt-8', className, {
    'text-center': isLoading,
  });

  const onPageChange = (_: ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  if (isLoading) {
    return (
      <section className={classes}>
        <CircularProgress />
      </section>
    );
  }

  const getDate = (date: string) => {
    const [month, year] = date.split('_');
    return new Date(
      parseInt(year as string, 10),
      parseInt(month as string, 10) - ONE,
    ).toLocaleString('en', {
      month: 'long',
      year: 'numeric',
    });
  };

  return (
    <section className={classes}>
      {Object.entries(messageThreads.items).map(([date, items]) => (
        <section key={date}>
          <Typography className="mb-4 mt-12" variant="subtitle1">
            {getDate(date)}
          </Typography>

          {(items as unknown as MessageThread[]).map((messageThread) => (
            <SessionCard key={messageThread.id} messageThread={messageThread} />
          ))}
        </section>
      ))}

      {messageThreads.pages > ONE && (
        <Pagination
          count={messageThreads.pages}
          page={page}
          onChange={onPageChange}
        />
      )}
    </section>
  );
};

export default WorkflowsSessionHistory;
