import { useState } from 'react';

import Viewer from '../Viewer';

import Header from './Header';
import Share from './Share';

const Summary = () => {
  const [isOpen, setIsOpen] = useState(false);
  const openShareModal = () => setIsOpen(true);
  const closeShareModal = () => setIsOpen(false);

  return (
    <>
      <Header onShare={openShareModal} />
      <Viewer />
      <Share isOpen={isOpen} onClose={closeShareModal} />
    </>
  );
};

export default Summary;
