import { clsx } from 'clsx';

import TableBody from './TableBody';
import TableCell from './TableCell';
import TableRow from './TableRow';

import type { TableBodyProps } from '@mui/material/TableBody';
import type { FC } from 'react';

interface TableEmptyProps extends TableBodyProps {
  columns: number;
  message: string;
}

const TableEmpty: FC<TableEmptyProps> = ({
  className,
  columns = 0,
  message = '',
  ...rest
}) => {
  const classes = clsx('mnt-table', className);

  return (
    <TableBody {...rest} className={classes}>
      <TableRow>
        <TableCell className="h-48 text-center" colSpan={columns}>
          {message}
        </TableCell>
      </TableRow>
    </TableBody>
  );
};
TableEmpty.displayName = 'TableEmpty';

export default TableEmpty;
export type { TableEmptyProps };
