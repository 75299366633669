import { CardType, SurveyStatus } from '@/types';
import { Avatar } from '@/ui';

import aiSparkle from '../../../ui/components/ActionCard/assets/ai-sparkle.png';
import createProfile from '../../../ui/components/ActionCard/assets/create-profile.png';
import createTeam from '../../../ui/components/ActionCard/assets/create-team.png';
import getMatched from '../../../ui/components/ActionCard/assets/get-matched.png';
import peerInsightsDemo from '../../../ui/components/ActionCard/assets/peer-insights-icons.png';

import type { HomeCardPropsWithoutCallbacks } from '@/types';
import type { FC } from 'react';

interface HomeCardMediaProps {
  card: HomeCardPropsWithoutCallbacks;
}

// TODO: have a map that returns the props for an img object based on the Enum we are using
const HomeCardMedia: FC<HomeCardMediaProps> = ({ card }) => {
  switch (card.type) {
    case CardType.EVENT:
      return card.isImageInline ? <img alt="" src={card.imageUrl} /> : null;
    case CardType.PEER_INSIGHT:
      if (card.peerInsight?.status === SurveyStatus.SHARED) {
        return <img alt="peer-insights" src={peerInsightsDemo} />;
      }
      break;
    case CardType.CREATE_PROFILE:
      return (
        <img
          alt="Create profile"
          height={120}
          src={createProfile}
          width={208}
        />
      );
    case CardType.AI_WORKFLOW:
      return <img alt="AI Workflows" src={aiSparkle} />;
    case CardType.ADD_PROFILE_IMAGE:
      return (
        <Avatar
          className="h-28 w-28 border-4 border-sunny-400 bg-sunny-500 text-6xl font-medium"
          firstName={card.user?.firstName || card.user?.fullName}
          size="l"
        />
      );
    case CardType.CREATE_TEAM:
      return (
        <img alt="create team" height={170} src={createTeam} width={238} />
      );
    case CardType.GET_MATCHED:
      return <img alt="get matched" className="scale-110" src={getMatched} />;
    default:
      return null;
  }

  return null;
};

export default HomeCardMedia;
