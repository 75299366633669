import clsx from 'clsx';

import {
  COMPANY_MEMBER_HEADERS,
  ONE,
  VISIBLE_COMPANY_MEMBER_HEADERS,
} from '@/constants';
import { TableCell, TableHead, TableRow, Typography } from '@/ui';

interface HeaderProps {
  onSort: (field: string) => void;
  sortDirection: 'asc' | 'desc';
  sortField: string;
}

const Header: React.FC<HeaderProps> = ({
  onSort,
  sortField,
  sortDirection,
}: HeaderProps) => (
  <TableHead>
    <TableRow>
      {COMPANY_MEMBER_HEADERS.map((header, index) => {
        const lowerCaseHeader = header.toLowerCase().replaceAll(' ', '');
        const isSorted = sortField === lowerCaseHeader;

        return (
          <TableCell
            key={`${header}-company-members-table`}
            className={clsx('cursor-pointer md:table-cell', {
              hidden:
                index > VISIBLE_COMPANY_MEMBER_HEADERS &&
                index !== COMPANY_MEMBER_HEADERS.length - ONE,
            })}
            onClick={() => onSort(lowerCaseHeader)}
          >
            <Typography variant="subtitle2">
              {header}
              {isSorted && (sortDirection === 'asc' ? ' ▲' : ' ▼')}
            </Typography>
          </TableCell>
        );
      })}
    </TableRow>
  </TableHead>
);

export default Header;
