import { Box } from '@mui/material';
import PaginationMui from '@mui/material/Pagination';
import React from 'react';

import type { PaginationProps } from '@mui/material/Pagination';

const Pagination: React.FunctionComponent<PaginationProps> = ({
  className = '',
  style = {},
  onChange,
  page,
  count,
}) => (
  <Box
    aria-label="pagination"
    className={className}
    role="navigation"
    style={style}
    sx={{
      display: 'flex',
      hidden: 'print',
      justifyContent: 'center',
      paddingTop: '1rem',
      width: '100%',
    }}
  >
    <PaginationMui count={count} page={page} size="large" onChange={onChange} />
  </Box>
);

export default Pagination;
export type { PaginationProps };
