export const MONTHS_IN_YEAR = 12;

export const DAYS_IN_MONTH = 30;
export const DAYS_IN_YEAR = 365;

export const HOURS_IN_DAY = 24;
export const HOURS_IN_AM_PM = 12;

export const MINUTES_IN_HOUR = 60;
export const MINUTES_IN_HALF_HOUR = 30;

export const SECONDS_IN_MINUTE = 60;

export const MILLISECONDS_IN_SECOND = 1000;
export const MILLISECONDS_IN_MINUTE = 60000;
export const MILLISECONDS_IN_FIVE_MINUTES = 300000;
export const MILLISECONDS_IN_HOUR = 3600000;

export const MAX_DATE = 8640000000000000;
export const DAYS_FOR_COMPANY_STATS = 30;
