import MuiDialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';

import type { DialogTitleProps } from '@mui/material/DialogTitle';
import type { FC } from 'react';

const StyledDialogTitle = styled(MuiDialogTitle)<DialogTitleProps>(() => ({
  padding: '3rem 3rem 2rem 3rem',
}));

const DialogTitle: FC<DialogTitleProps> = ({ className, ...rest }) => (
  <StyledDialogTitle {...rest} className={className} />
);
DialogTitle.displayName = 'DialogTitle';

export default DialogTitle;
export type { DialogTitleProps };
