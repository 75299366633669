import { clsx } from 'clsx';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { ONE, ZERO } from '@/constants';
import { useGrowthPlan } from '@/hooks';
import { Card, Icon, Link, theme, Typography } from '@/ui';

import type { Milestone } from '@/types';
import type { FC } from 'react';

import BoulderImage from '@/components/Growth/BoulderImage';

const MilestonesOverview: FC = () => {
  const { plan } = useGrowthPlan();
  const { pathname } = useLocation();

  const completedMilestones = plan.milestones.reduce(
    (aggr, milestone) => (milestone.completedAt ? aggr + ONE : aggr),
    ZERO,
  );
  const isEmpty = !plan.milestones.length;
  const subtitle = isEmpty
    ? 'Completed Milestones'
    : `${completedMilestones} of ${plan.milestones.length} Milestones completed`;

  /* eslint-disable react/no-array-index-key,@typescript-eslint/no-magic-numbers -- Dumb component. */
  const Empty = () =>
    [...Array(8)].map((_element, index) => (
      <div key={index} className="h-6 w-6">
        <BoulderImage
          fill={theme.extendedColors.cement[100]}
          milestone={
            {
              completeSteps: index + ONE,
              steps: [
                ...Array(index + ONE)
                  .fill(null)
                  .map(() => ({ completedAt: 'today' })),
              ],
              totalSteps: index + ONE,
            } as unknown as Milestone
          }
        />
      </div>
    ));
  /* eslint-enable react/no-array-index-key,@typescript-eslint/no-magic-numbers */

  return (
    <Card
      className={clsx('box-border w-92 p-5', { 'text-cement-200': isEmpty })}
      variant="outlined"
    >
      <div className="block align-middle sm:mb-4 sm:flex">
        <Icon className="mr-1">emoji_flags</Icon>
        <Typography className="hidden sm:block" variant="subtitle1">
          {subtitle}
        </Typography>
        <Typography className="block sm:hidden" variant="caption">
          {subtitle}
        </Typography>
      </div>

      <div className="hidden gap-2 sm:flex">
        {isEmpty ? (
          <Empty />
        ) : (
          <>
            {plan.milestones.map((milestone) => (
              <Link
                key={milestone.id}
                className="h-6 w-6"
                component={RouterLink}
                to={`${pathname}/milestone/${milestone.id}`}
              >
                <BoulderImage milestone={milestone} />
              </Link>
            ))}
            <div className="h-6 w-6">
              <Link component={RouterLink} to={`${pathname}/completed`}>
                <Icon>chevron_right</Icon>
              </Link>
            </div>
          </>
        )}
      </div>
    </Card>
  );
};

export default MilestonesOverview;
