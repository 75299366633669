import { captureException } from '@sentry/react';
import { useQueryClient } from '@tanstack/react-query';
import { type FC, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { deleteTeamMembership, updateTeam } from '@/api';
import { ONE, TEAMS_PER_PAGE } from '@/constants';
import { AppContext } from '@/context';
import { useCurrentUser, useTeams } from '@/hooks';
import { AppMessageSeverities, type Team } from '@/types';
import { TeamPermission } from '@/types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@/ui';

import ConfirmArchive from '../ConfirmArchive';
import ConfirmRemoveTeamMember from '../ConfirmRemoveTeamMember';
import EditTeamMember from '../EditTeamMember';

import type { TeamMember } from '@/types/teams';

import { useTeam } from '@/hooks/useTeam';

interface EditTeamProps {
  onClose: () => void;
  team: Team;
}
const EditTeam: FC<EditTeamProps> = ({ team, onClose }) => {
  const navigate = useNavigate();
  const { setAppMessage } = useContext(AppContext);
  const { user } = useCurrentUser();
  // TO-DO: if we are using useTeam make sure we retrieve team from useTeam instead of getting team as a prop
  const { refetchTeam } = useTeam(team.id);
  const queryClient = useQueryClient();

  const [teamToEdit, setTeamToEdit] = useState(team);
  const [saving, setSaving] = useState(false);
  const [memberToDelete, setMemberToDelete] = useState<TeamMember | null>(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const { refetchTeams } = useTeams(ONE, TEAMS_PER_PAGE, true);
  const isInvalid = teamToEdit.name === '';

  const canRemove = team.currentUserPermission === TeamPermission.admin;
  const handleArchiveClick = () => {
    setShowConfirm(true);
  };

  const handleMemberToDeleteClick = (member: TeamMember) => () =>
    setMemberToDelete(member);

  const handleNameChange = (e: { target: { value: string } }) => {
    setTeamToEdit((old) => ({ ...old, name: e.target.value }));
  };

  const onCloseConfirm = () => {
    setShowConfirm(false);
  };

  const handleCancelRemoveTeamMember = () => {
    setMemberToDelete(null);
  };

  const handleRemoveTeamMember = async () => {
    try {
      if (!memberToDelete) {
        setAppMessage({
          message: 'Error removing team member',
          open: true,
          severity: AppMessageSeverities.Error,
        });
        return;
      }

      setSaving(true);
      await deleteTeamMembership(memberToDelete.id);
      setAppMessage({
        message: 'Member removed',
        open: true,
        severity: AppMessageSeverities.Success,
      });
      handleCancelRemoveTeamMember();
      if (memberToDelete.userId === user.id) {
        await queryClient.invalidateQueries(['teams']);
        navigate('/teams');
      }
    } catch (error) {
      setAppMessage({
        message: 'Error removing team member',
        open: true,
        severity: AppMessageSeverities.Error,
      });
      captureException(error);
      console.error('Error removing team member', error);
    } finally {
      setSaving(false);
    }
  };

  const onArchive = async () => {
    try {
      setSaving(true);
      await updateTeam(team.id, { ...team, isActive: false });
      await refetchTeams();
      setShowConfirm(false);
      navigate('/teams');
    } catch (error) {
      setAppMessage({
        message: 'Error archiving team',
        open: true,
        severity: AppMessageSeverities.Error,
      });
      console.error('Error archiving team', error);
    } finally {
      setSaving(false);
    }
  };

  const handleSaveClick = async () => {
    try {
      setSaving(true);
      await updateTeam(team.id, { ...team, name: teamToEdit.name });
      refetchTeam();
      onClose();
    } catch (error) {
      setAppMessage({
        message: 'Error updating team',
        open: true,
        severity: AppMessageSeverities.Error,
      });
      console.error('Error updating team', error);
    } finally {
      setSaving(false);
    }
  };

  return (
    <Dialog maxWidth="md" fullWidth open onClose={onClose}>
      <DialogContent>
        <Grid spacing={4} container>
          <Grid md={5} xs={12} item>
            <Typography className="mb-2" variant="h4">
              About
            </Typography>
            <Typography className="mb-1" variant="body1">
              Basic information about the team.
            </Typography>
          </Grid>
          <Grid className="mt-4 flex flex-col gap-3" md={7} xs={12} item>
            <TextField
              inputProps={{ maxLength: 80 }}
              label="Team name"
              value={teamToEdit.name}
              onChange={handleNameChange}
            />
            {/* todo: add back when // if field exists! */}
            {/* <TextField
              label="Description"
              placeholder="Add team description"
              rows={3}
              value={team.description}
              multiline
            /> */}
          </Grid>
        </Grid>
        <Divider className="my-8" />

        <Grid spacing={4} container>
          <Grid md={5} xs={12} item>
            <Typography className="mb-2" variant="h4">
              Teammates
            </Typography>
          </Grid>
          <Grid className="flex flex-col gap-3" md={7} xs={12} item>
            {team.teamMembers.map((member) => (
              <EditTeamMember
                key={member.id}
                canRemove={canRemove}
                member={member}
                memberToDelete={handleMemberToDeleteClick}
              />
            ))}
          </Grid>
        </Grid>
        {canRemove && (
          <>
            <Divider className="my-8" />
            <Grid spacing={4} container>
              <Grid md={5} xs={12} item>
                <Typography className="mb-2" variant="h4">
                  Settings
                </Typography>
                <Typography className="mb-1" variant="body1">
                  This action cannot be undone at this time.
                </Typography>
              </Grid>
              <Grid className="mt-4 flex flex-col gap-3" md={7} xs={12} item>
                <Typography className="mb-1" variant="body1">
                  Danger zone
                </Typography>
                <Button
                  className="self-baseline"
                  color="warning"
                  variant="contained"
                  onClick={handleArchiveClick}
                >
                  Delete team for everyone
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button disabled={saving} variant="contained" onClick={onClose}>
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={isInvalid}
          loading={saving}
          variant="outlined"
          onClick={handleSaveClick}
        >
          Save
        </Button>
      </DialogActions>
      {showConfirm && (
        <ConfirmArchive
          saving={saving}
          onArchive={onArchive}
          onClose={onCloseConfirm}
        />
      )}
      {memberToDelete && (
        <ConfirmRemoveTeamMember
          member={memberToDelete}
          saving={saving}
          team={team}
          onClose={handleCancelRemoveTeamMember}
          onRemove={handleRemoveTeamMember}
        />
      )}
    </Dialog>
  );
};

export default EditTeam;
