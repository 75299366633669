import { useFlagBag } from '@happykit/flags/context';
import { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useNavigate } from 'react-router';

import { reorderMilestoneSteps } from '@/api';
import { ONE } from '@/constants';
import { useGetMilestone } from '@/hooks';
import { Icon, IconButton, Typography } from '@/ui';
import { getBoulderColor, reorder } from '@/utils';

import Actions from './Actions';
import Dropdown from './Dropdown';
import Header from './Header';
import Suggestions from './Suggestions/Suggestions';

import type { FeatureFlags, Milestone, MilestoneStep } from '@/types';
import type { FC } from 'react';

import DraggableRow from '@/components/Growth/Steps/DraggableRow';

interface StepsProps {
  milestone: Milestone;
}

const Steps: FC<StepsProps> = ({ milestone }) => {
  const navigate = useNavigate();
  const { flags } = useFlagBag<FeatureFlags>();
  const [currentSteps, setCurrentSteps] = useState<MilestoneStep[]>(
    milestone.steps,
  );
  const { refetch: refetchMilestone } = useGetMilestone({ id: milestone.id });
  const isSuggestionsEnabled = !!flags?.growth_suggestions;

  useEffect(() => {
    setCurrentSteps(milestone.steps);
  }, [milestone]);

  const onReorder = async (stepId: string, destinationId: string) => {
    const reordered = reorder(stepId, destinationId, currentSteps);
    setCurrentSteps(reordered);

    try {
      await reorderMilestoneSteps({
        destination: destinationId,
        origin: stepId,
      });
      await refetchMilestone();
    } catch (error) {
      console.error(error);
    }
  };

  const onBack = () => navigate(-ONE);

  const color = getBoulderColor(milestone.id);
  const bgColor = `bg-${color}-200`;
  const colorDark = `bg-${color}-500`;

  return (
    <DndProvider backend={HTML5Backend}>
      <div className={`w-full flex-1 p-4 md:min-h-screen lg:p-12 ${bgColor}`}>
        <IconButton className="visible sm:hidden" onClick={onBack}>
          <Icon>arrow_back</Icon>
        </IconButton>
        <div className="absolute right-4 top-4 flex items-center justify-between gap-2 lg:static">
          <div className="ml-auto flex items-center justify-between gap-2">
            <Actions milestone={milestone} />
            <Dropdown milestone={milestone} />
          </div>
        </div>
        <Header color={color} colorDark={colorDark} milestone={milestone} />
        <div className="flex flex-col md:ml-0">
          {currentSteps.map((step) => (
            <DraggableRow
              key={`boulder-steps-${step.id}`}
              step={{ ...step, milestoneId: milestone.id }}
              onReorder={onReorder}
            />
          ))}

          {isSuggestionsEnabled && (
            <Suggestions color={color} milestone={milestone} />
          )}

          <div className="mt-4 py-6 md:py-1 lg:pl-9">
            <div className="ml-1 mt-3.5 flex items-center gap-2 text-cement-400">
              <Icon>tips_and_updates</Icon>
              <Typography variant="caption">
                Each milestone can have a max of 8 steps.
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </DndProvider>
  );
};

export default Steps;
