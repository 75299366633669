export const VISIBLE_ASSISTANT_HEADERS = 2;

export const ASSISTANT_HEADERS = ['Name', 'Prompt', 'Model', 'Updated', ''];

export const DEFAULT_STARTERS = [
  'I want to find more time to think of new ideas',
  'I want to prep for my upcoming coaching session',
  'I want to find common ground with a colleague',
];

export const STARTERS_DISPLAYED = 3;
