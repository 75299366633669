import { Card, CardContent, Icon, List, Typography } from '@/ui';

import type { FC } from 'react';

import Goal from '@/components/Company/Info/Goals/Goal';

interface CompanyGoalsProps {
  goals: string[];
}

const CompanyGoals: FC<CompanyGoalsProps> = ({ goals = [] }) => (
  <Card elevation={0}>
    <CardContent className="border-2 border-b-sage-300 bg-sage-200 pb-2">
      <Typography
        className="flex items-center gap-2 text-cement-500"
        variant="subtitle1"
      >
        <Icon>emoji_flags</Icon>
        Company goals
      </Typography>
      <List>
        {goals.map((goal, index) => (
          <Goal
            key={`company-goal-${goal}`}
            goal={goal}
            index={index}
            total={goals.length}
          />
        ))}
      </List>
    </CardContent>
  </Card>
);

export default CompanyGoals;
