import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { DOCUMENT_LIMIT, ONE, SIX, ZERO } from '@/constants';
import { useCurrentUser, useGetDocuments, useView } from '@/hooks';
import { DocumentListState, DocumentViewState } from '@/types';
import {
  Alert,
  Button,
  Divider,
  Icon,
  PageHeaderLayout,
  Search,
  Tab,
  Tabs,
  Typography,
} from '@/ui';

import OnboardingFlow from '../Home/OnboardingFlow';

import Upload from './Upload';

import type { SyntheticEvent } from 'react';

import Create from '@/components/Document/Create';
import Empty from '@/components/Documents/Empty';
import Mine from '@/components/Documents/Mine';
import SharedWithMe from '@/components/Documents/SharedWithMe';
import ViewToggle from '@/components/Documents/ViewToggle';
import LockedFeature from '@/components/LockedStates';
import TemplatesGrid from '@/components/Templates/Grid';
import { useFeatureLocked } from '@/hooks/useFeatureLocked';

const Documents = () => {
  const { selectedUser, isViewLoading, isClient } = useView();
  const navigate = useNavigate();
  const { user } = useCurrentUser();
  const [documentsList, setDocumentsList] = useState(DocumentListState.MINE);
  const [view, setView] = useState(DocumentViewState.GRID);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(ONE);
  const { isFeatureLocked } = useFeatureLocked(user);

  const { data: documents = { items: [], pages: ZERO } } = useGetDocuments({
    userId: selectedUser.id,
  });

  const { data: sharedWithMe = { items: [], pages: ZERO } } = useGetDocuments({
    sharedWithMe: true,
    userId: selectedUser.id,
  });

  const {
    data: templatesData = { items: [], pages: ZERO },
    isLoading: templatesIsLoading,
  } = useGetDocuments({
    template: true,
    userId: selectedUser.id,
  });

  const viewAllTemplates = () => {
    if (isClient) {
      navigate('/documents/templates');
    } else {
      navigate('/coach/templates');
    }
  };

  const handleChange = (
    _: SyntheticEvent<Element, Event>,
    newValue: number,
  ) => {
    if (newValue === DocumentListState.MINE) {
      setDocumentsList(DocumentListState.MINE);
    } else {
      setDocumentsList(DocumentListState.SHARED_WITH_ME);
    }
  };

  const updateSearch = (value: string) => {
    setSearch(value);
    setPage(ONE);
  };

  const maxDocumentsReached =
    selectedUser.hasMaxDocuments || user.hasMaxDocuments;

  if (isViewLoading) return null;

  if (isFeatureLocked) {
    return (
      <div className="flex-1 px-4 md:p-8">
        <div className="container mx-auto max-w-screen-lg px-8 pb-6 md:px-0">
          <PageHeaderLayout title="Documents" />
          <div className="pt-10">
            <LockedFeature title="documents" />
          </div>
        </div>
      </div>
    );
  }

  // Tabs are hidden in member overview where selectedUser.id will be defined
  const showTabs =
    !selectedUser.id &&
    documents.items.length + sharedWithMe.items.length !== ZERO;

  return (
    <div className="container mx-auto max-w-screen-lg p-4">
      <PageHeaderLayout title="Documents">
        <Upload maxDocumentsReached={maxDocumentsReached} />
        <Create maxDocumentsReached={maxDocumentsReached} />
      </PageHeaderLayout>
      {maxDocumentsReached && (
        <Alert
          className="mb-8 w-full"
          icon={<Icon style={{ color: '#a33c1d' }}>warning</Icon>}
          severity="error"
        >
          <div className="flex w-full">
            <div className="flex-1 pr-8">
              <Typography className="mb-2" color="#A33D1D" variant="subtitle1">
                You have reached your {DOCUMENT_LIMIT} document limit
              </Typography>
              <Typography color="#A33D1D" variant="body1">
                You can only have {DOCUMENT_LIMIT} documents at any given time.
                In order to create more, please download and delete older
                documents.
              </Typography>
            </div>
          </div>
        </Alert>
      )}
      {documents.items.length === ZERO && <Empty />}
      <Divider className="!mt-8 md:!mt-0" />
      <div className="mx-auto pb-6 md:px-0">
        <div className="flex w-full flex-1 flex-col">
          <div className="mt-4 flex w-full items-center justify-between">
            <Typography variant="subtitle1">
              {documents.items.length === ZERO ? 'Or start ' : 'Start '} with a
              template
            </Typography>
            <Button
              className="px-3"
              endIcon={<Icon>chevron_right</Icon>}
              size="small"
              onClick={viewAllTemplates}
            >
              See all
            </Button>
          </div>

          <TemplatesGrid
            documents={templatesData.items.slice(ZERO, SIX)}
            isLoading={templatesIsLoading}
          />

          <Divider className="mb-8 mt-4" />

          {documents.items.length !== ZERO && (
            <div className="mb-8 flex w-full flex-col justify-between md:flex-row md:items-center">
              <Typography className="mb-4" variant="h5">
                Recently Updated
              </Typography>
              <div>
                <Search
                  className="mr-4 w-full md:w-92 lg:w-112"
                  search={search}
                  setSearch={updateSearch}
                  size="small"
                />
                <ViewToggle setView={setView} view={view} />
              </div>
            </div>
          )}

          {showTabs && (
            <Tabs
              aria-label="documents tabs"
              value={documentsList}
              onChange={handleChange}
            >
              <Tab label="My documents" />
              <Tab label="Shared with me" />
            </Tabs>
          )}

          {documentsList === DocumentListState.MINE && (
            <Mine page={page} search={search} setPage={setPage} view={view} />
          )}
          {documentsList === DocumentListState.SHARED_WITH_ME && (
            <SharedWithMe
              page={page}
              search={search}
              setPage={setPage}
              view={view}
            />
          )}
        </div>
      </div>
      <OnboardingFlow />
    </div>
  );
};

export default Documents;
