import { NotificationOptions, type UserNotifications } from '@/types';
import { Grid, Typography } from '@/ui';

import type { FC } from 'react';

import ToggleCard from '@/components/Profile/Card/Toggle';

interface EditClientNotificationsProps {
  onPreferencesChange: (updated: UserNotifications) => void;
  preferences: UserNotifications;
}

const EditClientNotifications: FC<EditClientNotificationsProps> = ({
  preferences,
  onPreferencesChange,
}) => {
  const onNotificationChange = (key: NotificationOptions) => () => {
    const newPreferences = { ...preferences };
    // get and replace the item with the one changed
    newPreferences[key] = !newPreferences[key];
    onPreferencesChange({ ...newPreferences });
  };

  const onMailChange = onNotificationChange(NotificationOptions.Email);
  const onSmsChange = onNotificationChange(NotificationOptions.Sms);
  const onGrowthPlanChange = onNotificationChange(
    NotificationOptions.GrowthPlan,
  );
  const onDocumentChange = onNotificationChange(NotificationOptions.Documents);
  const onOfflineMessagesChange = onNotificationChange(
    NotificationOptions.OfflineMessage,
  );

  return (
    <Grid spacing={4} container>
      <Grid md={5} xs={12} item>
        <Typography className="mb-2" variant="h4">
          Notification settings
        </Typography>
      </Grid>
      <Grid className="mt-1 flex flex-col gap-3" md={7} xs={12} item>
        <Typography variant="subtitle1">
          Choose how you get notifications
        </Typography>
        <ToggleCard
          icon="mark_email_unread"
          isSelected={preferences[NotificationOptions.Email]}
          title="Mail"
          onClick={onMailChange}
        />
        <ToggleCard
          icon="sms"
          isSelected={preferences[NotificationOptions.Sms]}
          title="SMS"
          onClick={onSmsChange}
        />
        <Typography variant="subtitle1">
          Choose the messages you'd like to receive
        </Typography>
        <ToggleCard
          icon="chat_bubble"
          isSelected={preferences[NotificationOptions.OfflineMessage]}
          title="Offline messages"
          onClick={onOfflineMessagesChange}
        />
        <ToggleCard
          icon="landscape"
          isSelected={preferences[NotificationOptions.GrowthPlan]}
          title="Growth plan updates"
          onClick={onGrowthPlanChange}
        />
        <ToggleCard
          icon="note"
          isSelected={preferences[NotificationOptions.Documents]}
          title="Document added by coach"
          onClick={onDocumentChange}
        />
      </Grid>
    </Grid>
  );
};

export default EditClientNotifications;
