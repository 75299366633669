import { styled } from '@mui/material';
import MuiTableHead from '@mui/material/TableHead';
import { clsx } from 'clsx';

import type { TableHeadProps } from '@mui/material/TableHead';
import type { FC } from 'react';

const StyledTableHead = styled(MuiTableHead)<TableHeadProps>(({ theme }) => ({
  backgroundColor: theme.palette.background.sage,
}));

const TableHead: FC<TableHeadProps> = ({ className, ...rest }) => {
  const classes = clsx('mnt-table-head', className);

  return <StyledTableHead {...rest} className={classes} />;
};
TableHead.displayName = 'TableHead';

export default TableHead;
export type { TableHeadProps };
