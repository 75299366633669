import { useQuery } from '@tanstack/react-query';

import { getTeams } from '@/api';

export const useTeams = (page = 1, limit = 12, isActive = false) => {
  const {
    data,
    isLoading: isTeamsLoading,
    refetch: refetchTeams,
  } = useQuery(
    ['teams', page, limit, isActive],
    () => getTeams({ isActive, limit, page }),
    {},
  );

  return {
    data,
    isTeamsLoading,
    refetchTeams,
  };
};
