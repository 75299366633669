import mentoApi from './mentoApi';

import type { UserAssesment } from '@/types';

export const updateUserAssesment = async (
  id: string,
  userAssesment: Partial<UserAssesment>,
) => {
  const result = await mentoApi.put(`/user_assesments/${id}`, {
    userAssesment,
  });
  return result.data;
};
