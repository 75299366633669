import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useCurrentUser, useView } from '@/hooks';
import { SessionType } from '@/types';
import { Tab, Tabs } from '@/ui';

import type { FC, ReactNode, SyntheticEvent } from 'react';

import CoachCalInfo from '@/components/Cal/CoachInfo';
import LockedFeature from '@/components/LockedStates';
import { useFeatureLocked } from '@/hooks/useFeatureLocked';
import PageHeaderLayout from '@/ui/components/PageHeaderLayout';

interface SessionsLayoutProps {
  children: ReactNode;
}

const SessionsLayout: FC<SessionsLayoutProps> = ({ children }) => {
  const { user, isUserLoading } = useCurrentUser();
  const { isOverview } = useView();
  const location = useLocation();
  const navigate = useNavigate();
  const { isFeatureLocked } = useFeatureLocked(user);

  const sessionType = location.pathname.includes('upcoming')
    ? SessionType.Upcoming
    : SessionType.Past;

  const handleChange = (
    _: SyntheticEvent<Element, Event>,
    newValue: number,
  ) => {
    if (newValue === SessionType.Upcoming) {
      navigate(isOverview ? '../sessions/upcoming' : '/sessions/upcoming');
    } else {
      navigate(isOverview ? '../sessions/past' : '/sessions/past');
    }
  };

  useEffect(() => {
    document.title = 'Mento | Coaching sessions';
  }, []);

  const isInMembers = location.pathname.includes('/coach/members');

  return (
    <div className="flex-1 overflow-x-hidden">
      <div className="container mx-auto flex h-full max-w-screen-lg flex-1 flex-col px-2 py-8">
        {!isInMembers && (
          <PageHeaderLayout title="Coaching Sessions">
            {!isUserLoading && !isOverview && user.isCoach && (
              <CoachCalInfo coach={user} />
            )}
          </PageHeaderLayout>
        )}
        {isFeatureLocked ? (
          <LockedFeature title="sessions" />
        ) : (
          <>
            <Tabs
              aria-label="basic tabs example"
              value={sessionType}
              onChange={handleChange}
            >
              <Tab label="Upcoming" />
              <Tab label="Past" />
            </Tabs>
            {children}
          </>
        )}
      </div>
    </div>
  );
};

export default SessionsLayout;
