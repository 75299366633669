import clsx from 'clsx';

import { Typography } from '@/ui';
import { formatShortDayMonthDate, toDateTimeRangeString } from '@/utils';

import type { Session } from '@/types';
import type { FC } from 'react';

type SessionCardInfoProps = {
  loading?: boolean;
  name?: string;
  session?: Session;
};

const SessionCardInfo: FC<SessionCardInfoProps> = ({
  session = {} as Session,
  loading = false,
  name = '',
}) => {
  const { startedAt, endedAt } = session;
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const hourRange = toDateTimeRangeString(
    new Date(startedAt),
    new Date(endedAt),
    timeZone,
    true,
  );
  const startedAtDate = formatShortDayMonthDate(startedAt);

  return (
    <div className="flex w-full flex-col justify-center pb-2 md:pb-0">
      <Typography
        className={clsx('my-2 md:my-0', {
          loading,
        })}
        variant="subtitle1"
      >
        {session.title}
      </Typography>
      <div className="flex">
        <Typography
          className={clsx('my-0 text-cement-400', {
            'loading-sm-long': loading,
          })}
          variant="body2"
        >
          {name} &middot; {startedAtDate} &middot; {hourRange}
        </Typography>
      </div>
    </div>
  );
};

export default SessionCardInfo;
