import { captureException } from '@sentry/react';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

import { deleteCompany, updateCompany } from '@/api';
import { useModal } from '@/hooks';
import { Button, ConfirmModal, Icon, Menu, MenuItem } from '@/ui';

import type { FC } from 'react';

interface MenuOptionsProps {
  companyId: string;
  isActive: boolean;
  usersCount: number;
}

const MenuOptions: FC<MenuOptionsProps> = ({
  isActive = false,
  companyId,
  usersCount,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const queryClient = useQueryClient();
  const {
    isOpen: isDeleteModalOpen,
    closeModal: closeDeleteModal,
    openModal: openDeleteModal,
  } = useModal();

  const handleMenuClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    setAnchorEl(e.currentTarget);
  };

  const onToggleActive = async (event: React.MouseEvent<HTMLLIElement>) => {
    event.stopPropagation();
    setAnchorEl(null);

    try {
      await updateCompany(companyId, { isActive: !isActive });
      await queryClient.invalidateQueries(['companies']);
    } catch (e) {
      captureException(e);
    }
  };

  const onDeleteCompany = async () => {
    try {
      await deleteCompany(companyId);
      await queryClient.invalidateQueries(['companies']);
    } catch (e) {
      captureException(e);
    }
    closeDeleteModal();
  };

  return (
    <>
      <ConfirmModal
        maxWidth="sm"
        open={isDeleteModalOpen}
        title="Delete"
        hideClose
        onClose={closeDeleteModal}
        onConfirm={onDeleteCompany}
      >
        Are you sure you want to delete this company?
      </ConfirmModal>

      <Button onClick={handleMenuClick}>
        <Icon>more_horiz</Icon>
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={onToggleActive}>
          <div className="flex items-center gap-2">
            {isActive ? <Icon>close</Icon> : <Icon>check</Icon>}
            {isActive ? 'Deactivate' : 'Reactivate'}
          </div>
        </MenuItem>

        {!usersCount && (
          <MenuItem onClick={openDeleteModal}>
            <div className="flex items-center gap-2">
              <Icon>trash</Icon>
              Delete
            </div>
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default MenuOptions;
