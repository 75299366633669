import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useSurvey } from '@/hooks';
import { ResponsesTabs } from '@/types';
import { Icon, Link, Skeleton, Tab, Tabs, Typography } from '@/ui';

import type { SyntheticEvent } from 'react';

// Only used for coach view
// Not refactored into a single header with client
// due to client needing Template or Survey
// And not wanting to add more complexity
const Header = () => {
  const { id = '' } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { survey, isSurveyLoading } = useSurvey();

  const activeTab = pathname.includes('responses')
    ? ResponsesTabs.IndividualResponses
    : ResponsesTabs.Summary;

  const handleChange = (
    _: SyntheticEvent<Element, Event>,
    newValue: number,
  ) => {
    if (newValue === ResponsesTabs.Summary) {
      const destiny = pathname.includes('responses')
        ? pathname.split('/responses')[0] ?? ''
        : pathname;
      navigate(destiny);
      return;
    }
    navigate(`${pathname}/responses`);
  };

  return (
    <>
      <Link
        className="flex no-underline"
        color="text.primary"
        href={`/coach/members/${id}/insights`}
      >
        <Icon>chevron_left</Icon>Insights
      </Link>
      {isSurveyLoading ? (
        <Skeleton />
      ) : (
        <Typography className="mt-2 text-cement-600 " variant="h4">
          {survey.title}
        </Typography>
      )}
      <Tabs
        aria-label="responses-tab"
        value={activeTab}
        onChange={handleChange}
      >
        <Tab label="Summary" value={0} />
        <Tab label="Individual Responses" value={1} />
      </Tabs>
    </>
  );
};

export default Header;
