import { QUOTES } from '@/constants';

import Row from './Row';

import type { FC } from 'react';

const Quotes: FC = () => (
  <div className="mx-auto -mb-28 flex w-full max-w-screen-lg flex-wrap overflow-x-scroll p-5 pl-3 md:pl-5 md:pt-24 lg:gap-4">
    {QUOTES.map((quote) => (
      <Row key={`membership-quote-${quote.name}`} {...quote} />
    ))}
  </div>
);

export default Quotes;
