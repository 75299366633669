import { Button, Dialog, DialogActions, DialogContent, Typography } from '@/ui';

import bg from './bg.png';

import type { FC } from 'react';

interface FirstTimeProps {
  isOpen: boolean;
  onClose: () => void;
}

const FirstTime: FC<FirstTimeProps> = ({ isOpen, onClose }) => (
  <Dialog
    open={isOpen}
    PaperProps={{
      style: {
        backgroundImage: `url(${bg})`,
        backgroundPosition: 'bottom',
        backgroundSize: 'cover',
        minHeight: '479px',
        width: '559px',
      },
    }}
    onClose={onClose}
  >
    <DialogContent>
      <Typography variant="overline">GROWTH PLAN</Typography>
      <Typography className="mb-6 mr-8" variant="h3">
        A place to dream, plan, and grow.
      </Typography>
      <Typography className="mb-5 mr-14" variant="body1">
        Partner with your Mento Coach to create your career growth plan: Find
        your guiding north star, see where you want to be in a year, and
        identify the milestones and steps to get you there.
      </Typography>
    </DialogContent>
    <DialogActions className="mb-8 border-0">
      <Button size="large" variant="contained" onClick={onClose}>
        See my plan
      </Button>
    </DialogActions>
  </Dialog>
);

export default FirstTime;
