import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ONE, QUERY_KEY_GROWTH_PLANS, QUERY_KEY_MILESTONES } from '@/constants';
import { useDeleteMilestone, useUpdateMilestone } from '@/hooks';
import {
  Icon,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@/ui';

import type { Milestone } from '@/types';
import type { FC, MouseEvent } from 'react';

import MilestoneDialog from '@/components/Growth/MilestoneDialog';

interface DropdownProps {
  milestone: Milestone;
}

const Dropdown: FC<DropdownProps> = ({ milestone }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);

  const onCloseMenu = () => setAnchor(null);
  const onClick = (event: MouseEvent<HTMLButtonElement>) =>
    setAnchor(event.currentTarget);

  const updateMilestone = useUpdateMilestone();
  const deleteMilestone = useDeleteMilestone();

  const onEdit = () => {
    setOpen(true);
    onCloseMenu();
  };

  const onToggleComplete = () => {
    updateMilestone.mutate(
      { id: milestone.id, isComplete: !milestone.completedAt },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([QUERY_KEY_GROWTH_PLANS]);
          queryClient.invalidateQueries([QUERY_KEY_MILESTONES]);
          onCloseMenu();
        },
      },
    );
  };

  const onDelete = () => {
    deleteMilestone.mutate(milestone.id, {
      onSuccess: () => {
        navigate(-ONE);
        queryClient.invalidateQueries([QUERY_KEY_GROWTH_PLANS]);
      },
    });
  };

  const markCompleteText = `Mark ${
    milestone.completedAt ? 'not ' : ''
  }complete`;

  return (
    <>
      <IconButton onClick={onClick}>
        <Icon>more_vert</Icon>
      </IconButton>
      <Menu anchorEl={anchor} open={!!anchor} onClose={onCloseMenu}>
        {!!milestone.completedAt && (
          <MenuItem onClick={onEdit}>
            <ListItemIcon>
              <Icon>edit</Icon>
            </ListItemIcon>
            <ListItemText>Edit</ListItemText>
          </MenuItem>
        )}
        {!!milestone.steps.length && (
          <MenuItem onClick={onToggleComplete}>
            <ListItemIcon>
              <Icon>check</Icon>
            </ListItemIcon>
            <ListItemText>{markCompleteText}</ListItemText>
          </MenuItem>
        )}
        <MenuItem onClick={onDelete}>
          <ListItemIcon>
            <Icon>trash</Icon>
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
      <MilestoneDialog isOpen={open} milestone={milestone} setOpen={setOpen} />
    </>
  );
};

export default Dropdown;
