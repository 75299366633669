import { Skeleton } from '@/ui';

const ClientHeaderSkeleton = () => (
  <div className="flex w-full flex-col border-0 border-b border-solid border-b-cement-200 bg-white pt-6">
    <div className="container mx-auto flex max-w-screen-lg px-2 lg:px-0">
      <div className="flex w-full p-4">
        <Skeleton
          className="shrink-0"
          height={80}
          variant="circular"
          width={80}
        />
        <div className="flex w-full items-center justify-between">
          <div className="flex flex-1 flex-col justify-center p-0 pl-6">
            <div className="flex items-center">
              <Skeleton height={38} width="25%" />
            </div>
            <Skeleton height={24} width="25%" />
            <Skeleton height={20} width="5%" />
          </div>
          <Skeleton height={24} width="18%" />
        </div>
      </div>
    </div>
    <div className="container mx-auto flex max-w-screen-lg items-start gap-4">
      <Skeleton className="mx-3 my-3.5" height={20} width="5%" />
      <Skeleton className="mx-3 my-3.5" height={20} width="5%" />
      <Skeleton className="mx-3 my-3.5" height={20} width="5%" />
      <Skeleton className="mx-3 my-3.5" height={20} width="5%" />
      <Skeleton className="mx-3 my-3.5" height={20} width="5%" />
      <Skeleton className="mx-3 my-3.5" height={20} width="5%" />
      <Skeleton className="mx-3 my-3.5" height={20} width="5%" />
      <Skeleton className="mx-3 my-3.5" height={20} width="5%" />
    </div>
  </div>
);

export default ClientHeaderSkeleton;
