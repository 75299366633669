import { clsx } from 'clsx';

import { Typography } from '@/ui';

import type { MessageThreadMessage, User } from '@/types';
import type { FC } from 'react';

import Menu from '@/components/CoachAI/CoachAISession/Menu';

type BubbleProps = {
  currentUser?: User;
  isUser?: boolean;
  message: Partial<MessageThreadMessage>;
};

const Bubble: FC<BubbleProps> = ({ currentUser, isUser, message }) => {
  const classes = clsx('workflow-bubble my-5 rounded-md py-4', {
    'ml-auto max-w-100 bg-sunny-200': isUser,
    'mr-auto max-w-155': !isUser,
  });

  return (
    <div className="flex">
      <div className={classes}>
        <Typography
          className="mx-4 hidden whitespace-pre-wrap md:block"
          variant="bodylg"
        >
          {message.content}
        </Typography>
        <Typography
          className="mx-4 block whitespace-pre-wrap md:hidden"
          variant="body2"
        >
          {message.content}
        </Typography>
      </div>
      {currentUser?.superAdmin && (
        <div className="mb-5 mt-auto  pb-4">
          <Menu message={message} />
        </div>
      )}
    </div>
  );
};

export default Bubble;
