import { Link } from 'react-router-dom';

import { useCurrentUser } from '@/hooks';
import { PageHeaderLayout } from '@/ui';

// TODO: Manage pages should be simple, no extra components needed
const Manage = () => {
  useCurrentUser();

  return (
    <div className="container mx-auto max-w-screen-lg p-4">
      <PageHeaderLayout title="Manage" />

      <div className="mt-4 flex flex-col gap-2">
        <Link className="text-cement-400 no-underline" to="cards">
          Cards
        </Link>
        <Link className="text-cement-400 no-underline" to="coaches">
          Coaches
        </Link>
        <Link className="text-cement-400 no-underline" to="companies">
          Companies
        </Link>
        <Link className="text-cement-400 no-underline" to="notifications">
          Notifications
        </Link>
        <Link className="text-cement-400 no-underline" to="templates">
          Templates
        </Link>
        <Link className="text-cement-400 no-underline" to="coach-ai-playground">
          AI Playground
        </Link>
        <Link className="text-cement-400 no-underline" to="assistants">
          Assistants
        </Link>
      </div>
    </div>
  );
};

export default Manage;
