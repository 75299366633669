import type { IDictionary } from './common';

export enum DocumentListState {
  MINE,
  SHARED_WITH_ME,
}

export enum DocumentViewState {
  GRID = 'GRID',
  LIST = 'LIST',
}

export enum DocumentIconSources {
  DOC = '/icons/doc.svg',
  SHEET = '/icons/sheet.svg',
  TEMPLATE = 'content_copy',
}

export enum DocMimeType {
  CSV = 'text/csv',
  DOC = 'application/msword',
  DOCUMENT = 'application/vnd.google-apps.document',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  PDF = 'application/pdf',
  PPT = 'application/vnd.ms-powerpoint',
  PPTX = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  PRESENTATION = 'application/vnd.google-apps.presentation',
  SPREADSHEET = 'application/vnd.google-apps.spreadsheet',
  TEXT = 'text/plain',
  UNKNOWN = 'application/vnd.google-apps.unknown',
  XLS = 'application/vnd.ms-excel',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}

export enum DocumentActions {
  Delete = 'Delete',
  Download = 'Download',
  Duplicate = 'Duplicate',
  None = '',
  Private = 'Make Private',
  Rename = 'Rename',
  Share = 'Share URL',
  ShareSettings = 'Share settings',
}

export interface Document {
  authorId: string;
  draft: boolean;
  id: string;
  isTemplate: boolean;
  mimeType: DocMimeType;
  name: string;
  originId?: string;
  reference: string;
  sharedDocuments: Array<{ userId: string }>;
  thumbnail: string;
  updatedAt: string;
  userId: string;
}

export interface DocumentUploadResponse {
  id: string;
  mimeType: DocMimeType;
  name: string;
}

export interface DocumentDisplayType {
  icon: string;
  label: string;
  shortLabel: string;
}

export interface PresignedDocument {
  fields: IDictionary;
  url: string;
}
