import { getHours, isToday } from 'date-fns';

import { EIGHTEEN, HOURS_IN_AM_PM, HOURS_IN_DAY, SIX, ZERO } from '@/constants';

import type { User } from '@/types';

export const getWelcomeMessage = (user: User, teamsFlag?: boolean): string => {
  const isInFlagAndHasNoTeams = teamsFlag && !user.hasTeams;
  if (isInFlagAndHasNoTeams) return 'Welcome to Mento';

  const { firstName = '', createdAt = '' } = user;
  const createdAtDate = new Date(createdAt);
  const now = new Date();
  const hours = getHours(now);

  let message = '';
  if (createdAt && isToday(createdAtDate)) {
    message = `Welcome, ${firstName}`;
  } else if (hours >= SIX && hours < HOURS_IN_AM_PM) {
    message = `Good morning, ${firstName}`;
  } else if (hours >= HOURS_IN_AM_PM && hours < EIGHTEEN) {
    message = `Good afternoon, ${firstName}`;
  } else if (
    (hours >= EIGHTEEN && hours < HOURS_IN_DAY) ||
    (hours >= ZERO && hours < SIX)
  ) {
    message = `Good night, ${firstName}`;
  }

  return message;
};
