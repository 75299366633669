import { useNavigate } from 'react-router-dom';

import { Avatar, TableCell, TableRow, Typography } from '@/ui';
import { toDateTimeString } from '@/utils';

import type { User } from '@/types';
import type { FC } from 'react';

import CalCard from '@/components/Coaches/List/CalRow';

const Row: FC<User> = ({
  id,
  isActive,
  coachProfile,
  firstName,
  fullName,
  lastName,
  email = '',
  updatedAt,
  avatarUrl = '',
}) => {
  const navigate = useNavigate();

  const onClick = () => navigate(`/coach/manage/coaches/${id}`);

  return (
    <TableRow className="hover:bg-sage-100" onClick={onClick}>
      <TableCell>
        <div className="flex items-center gap-2">
          <Avatar firstName={firstName} lastName={lastName} src={avatarUrl} />
          <Typography variant="subtitle2">
            <Typography variant="subtitle2">{fullName}</Typography>
          </Typography>
        </div>
      </TableCell>
      <TableCell>{coachProfile?.focus}</TableCell>
      <TableCell>{email}</TableCell>
      <TableCell>{isActive ? 'Active' : 'Inactive'}</TableCell>
      <TableCell>{coachProfile?.capacity}</TableCell>
      <TableCell className="hidden md:table-cell">
        <CalCard username={coachProfile?.calendarUrl ?? ''} />
      </TableCell>
      <TableCell className="hidden text-black md:table-cell">
        {toDateTimeString(new Date(updatedAt))}
      </TableCell>
    </TableRow>
  );
};

export default Row;
