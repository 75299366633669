import { Chip } from '@/ui';

import type { User } from '@/types';
import type { FC } from 'react';

interface PaymentPlanProps {
  user: User;
}

const PaymentPlan: FC<PaymentPlanProps> = ({ user }) => {
  let planName;
  if (user.isBusinessPaid) {
    planName = 'Business Plan';
  } else if (user.isPaid) {
    planName = 'Direct to Consumer';
  } else {
    planName = 'No Plan';
  }

  return <Chip className="rounded bg-sunny-200" label={planName} />;
};

export default PaymentPlan;
