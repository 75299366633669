import { SurveyStatus } from '@/types';

import mentoApi from './mentoApi';

import type { Recipient, Survey, SurveyTemplate } from '@/types';

export const getSurveyTemplate = async (
  templateId: string,
  clientSub?: string,
): Promise<SurveyTemplate> => {
  const params = clientSub ? { clientSub } : {};
  return (await mentoApi.get(`/survey_templates/${templateId}`, { params }))
    .data;
};

export const getSurveyTemplates = async (
  clientSub?: string,
): Promise<SurveyTemplate[]> => {
  const params = clientSub ? { clientSub } : {};
  return (await mentoApi.get('/survey_templates', { params })).data;
};

export const getSurveys = async (
  templateId?: string,
  clientSub?: string,
): Promise<Survey[]> => {
  const params: Record<string, string | undefined> = {};
  if (templateId) params.templateId = templateId;
  if (clientSub) params.clientSub = clientSub;
  return (await mentoApi.get('/surveys', { params })).data;
};

export const createSurvey = async (
  surveyTemplateId: string,
  assignedParticipants: Recipient[],
): Promise<Survey> =>
  (await mentoApi.post('/surveys', { assignedParticipants, surveyTemplateId }))
    .data;

export const sendSurvey = async (surveyId: string, emailTemplate: string) =>
  (await mentoApi.post(`/surveys/${surveyId}/send_emails`, { emailTemplate }))
    .data;

export const shareSurvey = async (surveyId: string) =>
  (
    await mentoApi.patch(`/surveys/${surveyId}`, {
      status: SurveyStatus.SHARED,
    })
  ).data;

export const updateParticipants = async (
  surveyId: string,
  assignedParticipants: Recipient[],
) =>
  (await mentoApi.patch(`/surveys/${surveyId}`, { assignedParticipants })).data;
