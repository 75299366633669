import { useState } from 'react';

import { useEditNote } from '@/hooks';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
} from '@/ui';

import type { Note as NoteType } from '@/types';
import type { ChangeEvent, FC } from 'react';

const Edit: FC<{
  close: () => void;
  isOpen: boolean;
  note: NoteType;
  refetchNotes: () => void;
}> = ({ close, isOpen, note, refetchNotes }) => {
  const [newContent, setNewContent] = useState(note.value);
  const [saving, setSaving] = useState(false);
  const editNote = useEditNote();

  const onChange = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setNewContent(value);
  };

  const onEditNote = () => {
    setSaving(true);
    editNote.mutate(
      {
        id: note.id,
        value: newContent,
      },
      {
        onSettled: () => {
          setNewContent('');
          setSaving(false);
          refetchNotes();
          close();
        },
      },
    );
  };

  return (
    <Dialog maxWidth="md" open={isOpen} scroll="paper" fullWidth>
      <DialogContent>
        <Typography className="mb-4" variant="subtitle1">
          Edit Note
        </Typography>

        <div className="flex h-full flex-col gap-4">
          <TextField
            label="Edit note"
            minRows={5}
            value={newContent || ''}
            multiline
            onChange={onChange}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="outlined" onClick={close}>
          Cancel
        </Button>
        <Button
          className="w-full md:w-32"
          disabled={!newContent.trim()}
          loading={saving}
          variant="contained"
          onClick={onEditNote}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Edit;
