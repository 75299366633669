import { formatDistanceToNow } from 'date-fns';

import { Typography } from '@/ui';

import type { FC } from 'react';

interface FooterProps {
  createdAt: Date;
}

const Footer: FC<FooterProps> = ({ createdAt }) => (
  <div className="mt-auto w-full bg-black px-12 py-4 lg:py-10">
    <Typography variant="subtitle1">
      Started your career journey with Mento
    </Typography>
    <Typography variant="body2">
      {formatDistanceToNow(new Date(createdAt))}
    </Typography>
  </div>
);
export default Footer;
