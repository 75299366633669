import { styled } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';

import { ENTER, ONE, ZERO } from '@/constants';
import { SideBarAvatarMenu } from '@/ui';

import type { User } from '@/types';
import type { DrawerProps } from '@mui/material/Drawer';
import type { FC, KeyboardEvent, ReactNode } from 'react';

type SidebarProps = {
  hideSideBar?: boolean;
  isClient: boolean;
  isOnHomepage: boolean;
  menu: ReactNode;
  onAccountSettings: () => void;
  onFeedBack: () => void;
  onSignOut: () => void;
  user: User;
} & DrawerProps;

const DrawerHeader = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  // necessary for content to be below app bar
  padding: theme.spacing(ZERO, ONE),
  ...theme.mixins.toolbar,
}));

const SideBar: FC<SidebarProps> = ({
  hideSideBar = false,
  menu,
  user,
  onAccountSettings,
  onFeedBack,
  onSignOut,
  isClient,
  isOnHomepage = false,
}) => {
  const navigate = useNavigate();
  const path = isClient ? '/m' : '/coach';

  if (hideSideBar) {
    return null;
  }

  const onHome = () => navigate(path);
  const onKeyDown = (event: KeyboardEvent) => {
    if (event.key === ENTER) {
      onHome();
    }
  };

  return (
    <Drawer
      className="hidden sm:flex"
      PaperProps={{
        className: clsx({
          'bg-sage-200': !isClient,
          'bg-sunny-200': isClient,
          'bg-transparent': isOnHomepage,
          'border-r-transparent': isOnHomepage,
        }),
      }}
      variant="permanent"
      open
    >
      <DrawerHeader>
        <img
          alt="Mento"
          className="cursor-pointer"
          height="38"
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role -- TODO: Improve accessibility.
          role="button"
          src="https://mento-space.nyc3.digitaloceanspaces.com/logo-sm.svg"
          tabIndex={0}
          width="32"
          onClick={onHome}
          onKeyDown={onKeyDown}
        />
      </DrawerHeader>
      <div className="h-full w-18">
        <div className="flex h-full flex-1 flex-col items-center justify-between pb-4">
          <div
            className={clsx('flex flex-col items-center gap-4 ', {
              '[&>button]:text-white': isOnHomepage,
            })}
          >
            {menu}
          </div>
          <SideBarAvatarMenu
            user={user}
            onAccountSettings={onAccountSettings}
            onFeedBack={onFeedBack}
            onSignOut={onSignOut}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default SideBar;
export type { SidebarProps };
