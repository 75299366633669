import { StytchPasswordReset } from '@stytch/react';
import { Products } from '@stytch/vanilla-js';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { STYTCH_CONFIG, STYTCH_STYLES } from '@/constants';

import type { StytchEvent } from '@stytch/vanilla-js';

const Reset = () => {
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const redirect = search.get('redirect');

  const config = STYTCH_CONFIG(redirect);
  config.products = [Products.passwords];

  const onEvent = (event: StytchEvent) => {
    if (event.type === 'PASSWORD_RESET_BY_EMAIL') {
      navigate(`/authenticate?${search.toString()}`, { replace: true });
    }
  };

  const token = search.get('token');
  return (
    <div
      className="h-screen w-screen"
      style={{
        backgroundImage:
          'url("https://mento-space.nyc3.cdn.digitaloceanspaces.com/signin-member-bg.png")',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <div className="w-full flex-1 md:flex md:h-full">
        <div className="flex w-full flex-1 items-center justify-center">
          {token ? (
            <StytchPasswordReset
              callbacks={{
                onEvent,
              }}
              config={config}
              passwordResetToken={token}
              styles={STYTCH_STYLES}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Reset;
