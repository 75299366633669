import { useNavigate } from 'react-router-dom';

import type { FC } from 'react';

type HeaderProps = {
  backButton: React.ReactNode;
};

const Header: FC<HeaderProps> = ({ backButton }) => {
  const navigate = useNavigate();

  const goHome = () => {
    navigate('/m');
  };

  return (
    <div className="to-sunny-0 absolute flex w-full justify-between bg-gradient-to-b from-sunny-100 px-10 py-4">
      <img
        alt="Mento"
        className="cursor-pointer"
        height="38"
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role -- TODO: Improve accessibility.
        role="button"
        src="https://mento-space.nyc3.digitaloceanspaces.com/logo-sm.svg"
        tabIndex={0}
        width="32"
        onClick={goHome}
        onKeyDown={goHome}
      />
      {backButton}
    </div>
  );
};

export default Header;
