import { format } from 'date-fns';
import { useEffect, useState } from 'react';

import { ONE, ZERO } from '@/constants';

import { useGetMilestoneSteps } from './milestoneSteps';
import { useGrowthPlan } from './useGrowthPlans';

import type { MilestoneStep, MilestoneStepsApiResponse } from '@/types';

interface UseGrowthPlanStepsParams {
  complete?: boolean;
  page?: number;
}

export const useGrowthPlanSteps = (options?: UseGrowthPlanStepsParams) => {
  const { plan, refetch: refetchGrowthPlan } = useGrowthPlan();
  const {
    data,
    isLoading,
    refetch: refetchSteps,
  } = useGetMilestoneSteps(
    { complete: options?.complete, growthPlanId: plan.id, page: options?.page },
    { enabled: !!plan.id },
  );
  const [result, setResult] = useState<
    { id: string; steps: MilestoneStep[]; title: string }[]
  >([]);
  const [total, setTotal] = useState<number>(ZERO);

  useEffect(() => {
    const tmp = [];

    if (!data) {
      return;
    }

    if (data.own?.steps.length) {
      tmp.push({
        author: data.own.author,
        id: data.own.author.id,
        steps: data.own.steps,
        title: 'Steps you added',
      });
    }

    if (data.other?.length) {
      data.other.forEach((step) => {
        tmp.push({
          author: step.author,
          id: step.author.id,
          steps: step.steps,
          title: `Steps ${step.author.firstName} added`,
        });
      });
    }

    if (!data.items && plan.milestones.length) {
      plan.milestones.forEach((milestone) =>
        tmp.push({
          id: milestone.id,
          steps: milestone.steps,
          title: milestone.title,
          totalSteps: milestone.totalSteps,
        }),
      );
    }

    if (data.items) {
      Object.keys(data.items).forEach((date) => {
        const [month, year] = date.split('_');
        tmp.push({
          id: date,
          steps: (data.items as Record<string, MilestoneStepsApiResponse>)[
            date
          ],
          title: format(
            new Date(
              parseInt(year as string, 10),
              parseInt(month as string, 10) - ONE,
            ),
            'LLLL yyyy',
          ),
        });
      });
    }

    if (data.total) {
      setTotal(data.total);
    } else {
      setTotal(tmp.length);
    }

    setResult(tmp);
  }, [plan, data]);

  const handleRefetch = () => {
    refetchGrowthPlan();
    refetchSteps();
  };

  return {
    isStepsLoading: isLoading,
    pages: data?.pages || ONE,
    refetch: handleRefetch,
    steps: result,
    total,
  };
};
